import template from "./dependents-list.html"

(function ($, ko) {
    ko.components.register('dependents-list', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new DependentListModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function DependentListModel(params, updateHeight) {
        this.profile = params.profile();
        this.displayHeader = ko.observable(true);

        if (params.displayHeader !== undefined) {
            this.displayHeader(params.displayHeader);
        }

        if (ko.isObservable(params.updateDependentList)) {
            params.updateDependentList.subscribe(function (needLoad) {
                if (needLoad) {
                    params.updateDependentList(false);
                    this.load();
                }
            }.bind(this));
        }

        this.hasPermissionsToViewDependents = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateDependentDemographicInformation);
        this.hasPermissionsToAddDependent = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionAllowParticipantAddDependent);

        this.dependentsRequest = new $.cdh.get.profile.dependents();

        this.onEditCallback = function () {
            this.load();
        }.bind(this);

        this.onRemoveCallback = function () {
            this.load();
        }.bind(this);

        this.load = function () {
            if (this.hasPermissionsToViewDependents) {
                this.dependentsRequest.load();
            }
        }.bind(this);

        this.dependentsRequest.state.subscribe(updateHeight);

        this.load();
    }

})(jQuery, ko);