(function ($) {
    $.extend(true, $, {
        cdh: {
            models: {
                MarkAsIneligible: function (model,OnSuccessCallback) {
                    this.clickCancel = function () {
                        this.close();
                    }

                    this.clickSubmit = function () {                   
                        //markAsIneligible.load().done(function () {
                        model.OnSuccessCallback();
                        this.close();
                    //});  
                    }.bind(this);  

                    this.setClose = function (closeFunc) {
                        this.close = closeFunc;
                    }.bind(this);
                }
            }
        }
    });
}(jQuery))