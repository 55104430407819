import template from "./smart-registration-pick-method.html"

(function ($, ko) {
    ko.components.register('smart-registration-pick-method', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new PickMethodModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
    
    function PickMethodModel(params, updateHeight) {
        const self = this;

        self.sendCodeRequest = new $.cdh.post.smartRegistration.sendCode();
        
        self.maskedEmail = params.model.maskedData.email;
        self.maskedMobileNumber = params.model.maskedData.mobileNumber;
        self.maskedPhone = params.model.maskedData.phone;
        self.empeOtpViaSmsOnly = params.model.maskedData.empeOtpViaSmsOnly;

        self.isExistEmail = ko.pureComputed(function () {
            return $.cdh.helper.isValueSet(self.maskedEmail());
        });

        self.isExistMobileNumber = ko.pureComputed(function () {
            return $.cdh.helper.isValueSet(self.maskedMobileNumber());
        });

        self.isExistPhone = ko.pureComputed(function () {
            return $.cdh.helper.isValueSet(self.maskedPhone());
        });   
        
        self.isLoading = ko.pureComputed(function() {
           return self.sendCodeRequest.isLoading(); 
        });
        
        self.isErrorShowed = ko.observable(false);

        self.back = params.model.onBack;
        self.cancel = params.model.onCancel;
        self.noCodeReceived = params.model.onNoCodeReceived;
        
        self.sendToEmail = function () {
            sendCode($.cdh.enums.registrationSendCodeMethod.email);
        };

        self.sendSmsToMobileNumber = function () {
            sendCode($.cdh.enums.registrationSendCodeMethod.smsToMobileNumber);
        };

        self.sendSmsToPhone= function () {
            sendCode($.cdh.enums.registrationSendCodeMethod.smsToPhone);
        };

        function sendCode(method) {
            self.isErrorShowed(true);
            self.sendCodeRequest.load({method: method}).done(function (data) {
                params.model.onNext(data ? { Success: true, Method: method } : null);
            }).fail(function (xhr) {
                if(xhr.responseJSON && xhr.responseJSON.data && xhr.responseJSON.data.Forbidden) {
                    params.model.onNext({ Success: false });
                } else {
                    self.isErrorShowed(true);
                    updateHeight();
                }
            });
        }
        
        params.model.clear = function () {
            self.isErrorShowed(false);
            self.sendCodeRequest.setData(null);
        };
    }
    
})(jQuery, ko);
