(function ($, ko) {
    var requestReimbursementTypeModel = function (onSuccess, updateHeight, expense, fromClaimList) {

        this.onSuccess = onSuccess;
        this.updateHeight = updateHeight;
        this.expense = expense;
        this.fromClaimList = fromClaimList;
        this.anyBillPayEnabledOnly = ko.observable(false);
        this.anyManualClaimsEnabledOnly = ko.observable(false);

        this.anyBillPayEnabled = ko.observable(false);
        this.anyManualClaimsEnabled = ko.observable(false);
        this.hasBothAccountTypes = ko.observable(false);

        this.allAccounts = $.cdh.accounts.initAccounts(function () {
            var anyBillPayEnabled = $.cdh.accounts.anyBillPayEnabledCurrent();
            var anyManualClaimsEnabled = $.cdh.accounts.anyManualClaimsEnabled();
            var anyHsa = $.cdh.accounts.anyHsa();
            var anyNonHsa = $.cdh.accounts.anyNonHsa();
            this.anyBillPayEnabled(anyBillPayEnabled);
            this.anyManualClaimsEnabled(anyManualClaimsEnabled);
            this.hasBothAccountTypes(anyHsa && anyNonHsa);

            if (anyBillPayEnabled && !anyManualClaimsEnabled) {
                this.anyBillPayEnabledOnly(true);
            }

            if (!anyBillPayEnabled && anyManualClaimsEnabled) {
                this.anyManualClaimsEnabledOnly(true);
            }
            
        }.bind(this));

        this.accounts = ko.pureComputed(function () {
            return $.grep(this.allAccounts.data(), function (a) {
                return a.ManualClaimsEnabled;
            }.bind(this));
        }.bind(this));

        this.selectedAccount = ko.observable();
        this.accounts.subscribe(function (acc) {
            if (acc != null && acc.length > 0) {
                this.selectedAccount(acc[0]);
            }
        }, this);

        this.preRender = function () {
            this.allAccounts.loadIfNoData();
        }.bind(this);

        this.setClose = function (closer) {
            this.close = closer;
        }.bind(this);

        this.submitPaymentBuilder = function () {
            return new $.cdh.models.PaymentsAddPopup(0, this.onSuccess, this.expense, $.noop, this.fromClaimList);
        }.bind(this);

        this.submitClaimBuilder = function () {
            var result = new $.cdh.models.SubmitClaimWidgetModel(this.onSuccess, this.updateHeight, this.expense, this.selectedAccount());
            if (this.selectedAccount())
                result.forAccountId = this.selectedAccount().AccountId;
            return result;

        }.bind(this);

        this.displayAccount = ko.pureComputed(function () {
            var a = this.selectedAccount();
            return a != null ? a.AccountName : '';
        }, this);

        this.selectAccount = function (a) {
            this.selectedAccount(a);
        }.bind(this);
    };


    $.extend(true, $, {
        cdh: {
            models: {
                RequestReimbursementType: requestReimbursementTypeModel
            }
        }
    });
})(jQuery, ko);