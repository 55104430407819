import template from "./paginator.html"

(function ($, ko) {
    ko.components.register('paginator', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new Paginator(params, widgetInfo.updateHeight);
            }
        }
    });

    function Paginator(params, updateHeight) {
        const self = this;

        self.currentPageIndex = ko.observable(params.model.pageNo() ? params.model.pageNo() : 1);
        self.lastPageIndex = ko.observable(params.model.totalPages() ? params.model.totalPages() : 1);
        self.showAllAllowed = params.model.showAllAllowed || false;
        
        self.showAllVisible = ko.pureComputed(function () {
            return self.showAllAllowed && self.lastPageIndex() !== 1;
        });
        
        self.onChanged = params.model.onChanged;
        
        self.isNextAvailable = ko.pureComputed(function () {
            return self.lastPageIndex() > self.currentPageIndex();
        });
        
        self.isPrevAvailable = ko.pureComputed(function () {
           return  self.currentPageIndex() > 1;
        });

        self.nextPage = function () {
            if (self.isNextAvailable()) {
                setPageNo(self.currentPageIndex() + 1);
                document.body.scrollTop = 0; 
                document.documentElement.scrollTop = 0;
            }
        };

        self.prevPage = function () {
            if (self.isPrevAvailable()) {
                setPageNo(self.currentPageIndex() - 1)
                document.body.scrollTop = 0; 
                document.documentElement.scrollTop = 0;
            }
        };
        
        params.model.showAll = function (callback) {
            self.currentPageIndex(1);
            if (self.onChanged != null) {
                self.onChanged(1, 1000000, callback);
                updateHeight();
            }
        };

        self.showAll = function () {
            params.model.showAll();
        };
        
        function setPageNo(value) {
            self.currentPageIndex(value);
            params.model.pageNo(value);

            if (self.onChanged != null) {
                self.onChanged(value);
                updateHeight();
            }
        }

        params.model.pageNo.subscribe(function (pageNo) {
            self.currentPageIndex(pageNo);
            updateHeight();
        });
        
        params.model.totalPages.subscribe(function(totalPages) {
            self.lastPageIndex(totalPages); 
        });
    }

})(jQuery, ko);