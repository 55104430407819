import template from './product-look-up-item.html';

(function ($, ko) {

    function ProductLookUpItem(params, updateHeight) {

        this.storeProductResult = params.model.model;
    }
    ko.components.register('product-look-up-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ProductLookUpItem(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
})(jQuery, ko);