(function (module, ko) {

    module.ListViewModelBase = function (specifics, updateHeight) {
        var self = this;
        
        self.emptyListText = specifics.messages("empty");

        self.updateHeight = updateHeight;
        self.gridItems = ko.observableArray([]);
    }

})(window.docDelivery, window.ko);