import {DataServiceBase} from "../DataServiceBase";

(function ($, ko) {

    class ParticipantService extends DataServiceBase {
        constructor() {
            super('/SmartAccount/Participant');
        }

        getDetailsAsync() {
            return this.getAsync(null, 'Details');
        };
    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                ParticipantService: ParticipantService
            }
        }
    });

})(jQuery, ko);