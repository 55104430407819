(function($) {

    function afterIframeRendered(renderedNodesArray) {
        var $iframe = $(renderedNodesArray);
        
        $iframe.load(function () {
            var innerDoc = this.contentWindow.document;
            const innerDocHtml = innerDoc.querySelector('html');

            var head = innerDoc.head;
            var link = innerDoc.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = '/Styles/external-fixes.css';
            head.appendChild(link);

            //apply custom css from parent page
            if (parent) {
                $.each(parent.document.querySelectorAll("link[rel=stylesheet]"), function(i, link) {
                    if (link.href.indexOf("CustomCss") > 0) {
                        var newLink = innerDoc.createElement("link");
                        newLink.rel = link.rel;
                        newLink.href = link.href;
                        head.appendChild(newLink);
                    }
                });
            }

            var prevHeight = 0;
            function updateSingle() {
                var height = $(innerDocHtml).outerHeight();
                if (!height || height === prevHeight) {
                    return;
                }

                prevHeight = height;
                $iframe.height(height); // 130 - additional padding for dropdowns required?
            }

            // timeouts: http://173.255.243.51/jtrac/app/item/WCP-1104/
            var timeouts = [];
            $(innerDoc.body).find('select').mousedown(function () {
                for (var i = 0; i < timeouts.length; i++) { clearTimeout(timeouts[i]); }
                timeouts = [];
            });

            function updateIframe() {
                timeouts.push(setTimeout(updateSingle, 0));
                timeouts.push(setTimeout(updateSingle, 300));
                timeouts.push(setTimeout(updateSingle, 1000));
                timeouts.push(setTimeout(updateSingle, 3000));
            }
            updateIframe();

            $(innerDoc.body).find('input,select').change(updateIframe);
            $(innerDoc.body).find('input,button,.tooltip').click(function() {
                if ($(this).hasClass('reset')) {
                    return;
                }
                updateIframe();
            });

            function linkClick(elem) {
                if (typeof elem.attr('href') != 'undefined' && elem.attr('href')[0] != '#') {
                    $(innerDoc.head).append('<base target="_parent"/>');
                }
                else {
                    updateIframe();
                }
            }
            function linkKeydown(elem, e) {
                if (e.keyCode !== 13 && e.keyCode !== 32) {
                    return;
                }
                linkClick(elem);
            }

            const linksCommon = $(innerDoc.body).find('a, ' +
                '.pcsSurvey .header ul#tabs_list > li, ' +
                '.pcsSurvey .buttonsPanel .prev, ' +
                '.pcsSurvey .buttonsPanel .next');
            linksCommon.mousedown(function() {
                linkClick($(this));
            });
            linksCommon.keydown(function(e) {
                linkKeydown($(this), e);
            });

            let timer;
            window.addEventListener('resize', function () {
                clearTimeout(timer);
                timer = setTimeout(updateSingle, 200);
            });

            // Removed gridstack re-rendering for hsa-tax-savings component.
            if ($iframe.closest('hsa-tax-savings')) {
                try {
                    $('.grid-stack').data('gridstack').opts.minWidth = 0;
                } catch (e) { }
            }

            // scroll iframe to top when user clicks prev/next buttons
            $(innerDoc.body).find('.buttonsPanel').find('.buttons').click(function() {
                if ($(this).hasClass('reset')) {
                    return;
                }
                $.cdh.helper.scrollPageToIframeTop($iframe);
            });


            innerDoc.body.addEventListener('keydown', function (event) {
                const $el = $(event.target);
                const $dropdown = $el.closest('.dropdown').eq(0);
                const $button = $dropdown.find('button.dropdown-toggle').eq(0);
                if ($dropdown.hasClass('show') && (event.keyCode == 38 || event.keyCode == 40)) {
                    const $items = $dropdown.find('.dropdown-menu a');                    
                    const $currentItem = $dropdown.find('.dropdown-menu a[tabindex="0"]');
                    const currentIndex = $currentItem.length ? $currentItem.parent().index() : null;
                    const nextIndex = getNextIndex($dropdown, currentIndex);
                    setActiveDropdownElement($dropdown, nextIndex);
                }
                if ($dropdown.hasClass('show') && (event.keyCode == 13 || event.keyCode == 32) && $el.closest('a').length) {
                    $el[0].click();
                    window.setTimeout(function () {
                        $button[0].focus();
                    }, 100);
                }
            });

            function getNextIndex(dropdown, currentIndex) {
                const $items = $(dropdown).find('.dropdown-menu a');
                let nextIndex = 0;
                if (currentIndex !== null) {
                    if (event.keyCode == 38) {
                        nextIndex = currentIndex === 0 ? ($items.length - 1) : (currentIndex - 1);
                    } else if (event.keyCode == 40) {
                        nextIndex = currentIndex === ($items.length - 1) ? 0 : (currentIndex + 1);
                    }
                }
                
                let $nextElement = $items.eq(nextIndex);
                while ($nextElement[0].style.display === 'none') {
                    if (event.keyCode == 38) {
                        nextIndex = nextIndex === 0 ? ($items.length - 1) : (nextIndex - 1);
                    } else if (event.keyCode == 40) {
                        nextIndex = nextIndex === ($items.length - 1) ? 0 : (nextIndex + 1);
                    }
                    $nextElement = $items.eq(nextIndex);
                    console.log(nextIndex);
                }

                return nextIndex;
            }

            function setActiveDropdownElement(dropdown, nextIndex) {
                const $items = $(dropdown).find('.dropdown-menu a');
                let $nextElement = $items.eq(nextIndex);
                $items.attr('tabindex', '-1');
                $nextElement.attr('tabindex', '0');
                $nextElement.focus();
            }

            // scroll to top on the page start (added for scroll after clicking on the reset btn).
            $.cdh.helper.scrollPageToTop();
        });
    }

    $.cdh.registerHtmlWidget('cba-tutorial', afterIframeRendered);
    $.cdh.registerHtmlWidget('contribution-planning', afterIframeRendered);
    $.cdh.registerHtmlWidget('dca-tutorial', afterIframeRendered);
    $.cdh.registerHtmlWidget('fsa-tax-savings', afterIframeRendered);
    $.cdh.registerHtmlWidget('fsa-tutorial', afterIframeRendered);
    $.cdh.registerHtmlWidget('hra-tutorial', afterIframeRendered);
    $.cdh.registerHtmlWidget('hsa-contribution-limits', afterIframeRendered);
    $.cdh.registerHtmlWidget('hsa-tax-savings', afterIframeRendered);
    $.cdh.registerHtmlWidget('hsa-tutorial', afterIframeRendered);
    $.cdh.registerHtmlWidget('lpfsa-tutorial', afterIframeRendered);
    $.cdh.registerHtmlWidget('short-term-savings', afterIframeRendered);
    $.cdh.registerHtmlWidget('long-term-savings', afterIframeRendered);
    $.cdh.registerHtmlWidget('tax-savings', afterIframeRendered);
    $.cdh.registerHtmlWidget('whichplan', afterIframeRendered);
    $.cdh.registerHtmlWidget('custom-page', afterIframeRendered);
    $.cdh.registerHtmlWidget("e-claims-landing", afterIframeRendered);
})(jQuery, ko);