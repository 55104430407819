(function ($, ko) {
    $.extend(true, $, {
        cdh: {
            models: {
                ActivateModelPopup: function (model, submitFunc) {
                    this.model = model;

                    this.setClose = function (closeFunc) {
                        this.close = closeFunc;
                    }.bind(this);

                    this.clickCancel = function () {
                        this.close();
                    }

                    this.clickSubmit = function () {
                        submitFunc(this.model, this.close);
                    }
                }
            }
        }
    });
}(jQuery, ko));