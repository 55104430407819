(function($) {
    $.extend(true, $, {
        cdh: {
            models : {
                profileDeleteAccountModel: function (onDeletedCallback) {
                    
                    var deleteMethod = new $.cdh.post.profile.deleteAccountCredentials();
                  
                    this.clickCancel = function () {
                        this.close();
                    }
                  
                    this.clickSubmit = function () {
                        if (confirm($.cdh.languageConstant.getConstant("alerts.certain-to-proceed")) === true) {
                            deleteMethod.load().done(function () {
                                onDeletedCallback();
                                document.forms['logout-form'].submit();
                            });
                        }                       
                    }.bind(this);

                    this.setClose = function (closeFunc) {
                        this.close = closeFunc;
                    }.bind(this);
                }
            }
        }
    });
}(jQuery))