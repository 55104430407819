import {DataServiceBase} from "../DataServiceBase";

export class NotificationsService extends DataServiceBase {
    constructor() {
        super('/Notifications');
    }
    
    getCountAsync() {
        return super.getAsync(null, 'Count');
    }

    getListAsync() {         
        return super.getAsync(null, 'List');
    };
}