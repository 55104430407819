import template from "./dependent-editor-popup.html"

(function ($, ko) {
    ko.components.register('dependent-editor-popup', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new DependentEditorPopup(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    const steps = {
        Details: 1,
        Accounts: 2
    };

    function DependentEditorPopup(params, updateHeight) {

        const self = this;

        const model = params.model;
        const isEdit = params.isEdit;
        const onSuccessCallback = params.onSuccessCallback;
        const needFilterByAccountsOnSuccess = params.needFilterByAccountsOnSuccess === true;
        const asClaimant = params.asClaimant === true;

        const dependent = (isEdit && model.details) ? model.details : { FlexAcctIds: [] };
        
        this.updateHeight = function() {
            setTimeout(updateHeight, 600);
        };
        this.step = ko.observable(steps.Details);
        this.displayHeader = ko.observable(params.displayHeader !== undefined ? params.displayHeader : true);
        this.header = ko.pureComputed(function () {
            if (self.step() === steps.Accounts) return $.cdh.languageConstant.getConstant('profile.account-linking');
            return isEdit ? $.cdh.languageConstant.getConstant('profile.edit-family-member') : $.cdh.languageConstant.getConstant('profile.add-family-member-c');
        });
        

        this.canLinkToAccounts = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionAllowParticipantLinkDependentAccount);

        this.addOrEditRequest = isEdit
            ? new $.cdh.post.profile.updateDependent()
            : new $.cdh.post.profile.addDependent();

        this.verifiedSsn = ko.observable();
        this.checkDuplicateSsnRequest = new $.cdh.post.profile.checkDuplicateSsn();
        this.isSsnChecking = ko.pureComputed(function() {
            return self.checkDuplicateSsnRequest.isLoading();
        });
        this.checkSsn = function(ssn, onSuccessSsnCallback) {
            if (!isEdit && ssn && $.cdh.helper.isValueSet(ssn.trim()) && self.verifiedSsn() !== ssn) {
                self.checkDuplicateSsnRequest.load({ssn: ssn}).done(function () {
                    self.verifiedSsn(ssn);
                    onSuccessSsnCallback();
                }).fail(function() {
                    self.updateHeight();
                });
            } else {
                onSuccessSsnCallback();
            }
        };

        this.data = {
            dependent: dependent,
            dependentOrigin: Object.assign({}, dependent),
            profile: model.profile.viewModel(),
            asClaimant: asClaimant,
            enums: {
                states: model.profile.vm.states.data(),
                countries: model.profile.vm.countries.data()
            },
            isEdit: isEdit,
            isSsnChecking: this.isSsnChecking,
            onNext: function () {

            }
        };

        this.canSubmit = ko.pureComputed(function () {
            return !self.addOrEditRequest.isLoading() && !self.isSsnChecking();
        });
        
        this.nextButtonText = ko.pureComputed(function() {
            if(self.isSsnChecking()) {
                return $.cdh.languageConstant.getConstant('button.submitting')
            }
            
            if (self.step() === steps.Details && self.canLinkToAccounts) {
                return $.cdh.languageConstant.getConstant('button.next');
            }

            return $.cdh.languageConstant.getConstant('button.submit');
        });

        const closeFunc = params.closeFunc || function () {};
        
        this.onNext = function () {
            if (self.step() === steps.Details) {
                if (self.data.onNext()) {
                    if (self.canLinkToAccounts) {
                        self.checkSsn(dependent.Ssn, function() {
                            self.step(steps.Accounts);
                        });
                    } else {
                        AddOrUpdate();
                    }
                }
            } else if (self.step() === steps.Accounts) {
                if (self.data.onNext()) {
                    AddOrUpdate();
                }
            }
            self.updateHeight();
        };

        this.onBack = function () {
            self.step(steps.Details);
            self.updateHeight();
        };

        this.onCancel = function () {
            closeFunc();
        };

        function AddOrUpdate() {
            self.addOrEditRequest.load({
                dependent: dependent,
                asClaimant: asClaimant,
                needFilterByAccounts: needFilterByAccountsOnSuccess
            }).done(function (data) {
                onSuccessCallback(data);
                closeFunc();
            });
        }
        
        this.updateHeight();
    }
})(jQuery, ko);