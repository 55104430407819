import template from "./smart-registration-questions.html"

(function ($, ko) {
    ko.components.register('smart-registration-questions', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new QuestionsModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function QuestionsModel(params, updateHeight) {

        this.data = ko.observable({});
        this.username = params.username;
        this.userData = ko.observable({});
        this.data(this.userData());
        this.userInfoRequest = new $.cdh.get.registration.userInfo();
        this.confirmEmail = ko.observable('').extend({ required: true, email: true });
        this.confirmEmailValidation = new $.cdh.validation(this.confirmEmail);
        this.clickCancel = params.model.onCancel;

        this.addRequiredValidation = function (field) {
            return ko.observable(ko.unwrap(field)).extend({ required: true });
        };

        this.questionsLoader = new $.cdh.get.registration.questions(function (data) {
            var result = [];
            if (data.length > 0) {
                for (var i = 0; i < 4; i++) {
                    result.push({
                        index: (i + 1),
                        questions: ko.observableArray(data),
                        selectedQuestion: ko.observable(data[i].QuestionId),
                        UserAnswer: this.addRequiredValidation(''),
                        questionsChanged: this.questionsChanged
                    });
                }
            }
            setTimeout(function () {
                var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                if (!(isSafari && $.cdh.isMobile)) {
                    return;
                }

                var questionSelects = $('select.question-select');
                var optgroup = $('<optgroup>');
                optgroup.attr('label', '');
                questionSelects.append(optgroup);
                questionSelects[0].selectedIndex = 0;
            }, 200);
            updateHeight();
            return result;
        }.bind(this)); 

        this.validateAnswers = ko.computed(function () {
            var result = true;
            ko.utils.arrayForEach(this.questionsLoader.data(), function (question, index) {
                ko.utils.arrayForEach(this.questionsLoader.data(), function (innerQuestion, index2) {
                    if (innerQuestion.index != question.index && innerQuestion.selectedQuestion() == question.selectedQuestion())
                        result = false;
                }.bind(this));
            }.bind(this));
            return result;
        }.bind(this)).extend({
            required: true,
            equal: {
                params: true,
                message: $.cdh.languageConstant.getConstant("registration.not-choose-same-question")
            }
        });

        this.validationAnswers = new $.cdh.validation(this.validateAnswers);
        this.validationQuestions = new $.cdh.validation(this.questionsLoader.data);
        this.shouldTranslate = ko.observable(false);

        this.continueSetup = function () {
            var questions = this.data().questions.data();
            this.confirmEmail(this.data().email());
            this.validationQuestions.activate();
            this.validationAnswers.activate();
            this.confirmEmailValidation.activate();
            if (!this.validationQuestions.hasErrors() && !this.validationAnswers.hasErrors() && !this.confirmEmailValidation.hasErrors()) {
                params.model.onNext({ Success: true, Questions: questions, UserData: this.userData() });
            }
        }.bind(this);

        this.setupQuestionsStep = function (userName) {
            if (!this.questionsLoader.isLoading()) {
                this.questionsLoader.load({ userName: userName });
            }
            this.data.extend(ko.utils.extend(this.data(), {
                questions: this.questionsLoader,
                aggregatedLoadStatus: this.aggregatedLoadStatus
            }));
        }.bind(this);

        this.beginSetup = function (userName) {
            this.setupQuestionsStep(userName);

            var loadUserInfoRequest = function () {
                this.userInfoRequest.load({ userName: userName }).done(function (result) {
                    this.userData().firstName = ko.observable(result.FirstName);
                    this.userData().lastName = ko.observable(result.LastName);
                    this.userData().userName = ko.observable(userName);
                    this.userData().email = ko.observable(result.Email);
                    this.userData().mobileNumber = ko.observable(result.MobileNum);
                    this.confirmEmail(result.Email);
                    updateHeight();
                }.bind(this));
            }.bind(this);

            if (this.questionsLoader.isLoading()) {
                this.questionsLoader.data.subscribe(function () {
                    loadUserInfoRequest();
                });
            } else {
                loadUserInfoRequest();
            }

        }.bind(this);

        this.aggregatedLoadStatus = new $.cdh.models.aggregatedLoadStatus([
            this.userInfoRequest,
            this.questionsLoader
        ]);
        this.beginSetup(this.username);
    }

})(jQuery, ko);