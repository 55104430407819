(function (module, $, ko) {
    const MsgStatements = {
        msgStatementsEmpty: "Your Statements list is empty",
        msgHsaStatement: "HSA Statements",
        msgPreferences: "E-Statements Opt-In Settings",
        msgStatements: "Statements",
        lblHeaderQuestion: "How would you like to receive your Statements?",
        lblElectronicOnly: "Electronic Only",
        lblElectronicPaper: "Electronic & Paper" 
    };
    var specifics = {
        messages: function (viewName) {
            switch (viewName) {
                case "empty":
                    return MsgStatements.msgStatementsEmpty;
                case "title":
                case "list":
                    return MsgStatements.msgHsaStatement;
                case "preferences":
                    return MsgStatements.msgPreferences;
            }

            return MsgStatements.msgStatements;
        },

        routes: $.cdh.routes.statements,
        requests: {
            get: $.cdh.get.statements,
            post: $.cdh.post.statements
        },

        ListViewModel: module.ListStatementsViewModel,

        optionSettings: {
            additionalFeeText: $.cdh.customLabels.documentDeliveryPreferencesStatements,
            headerQuestion: MsgStatements.lblHeaderQuestion,
            electronic: {
                text: MsgStatements.lblElectronicOnly,
                value: $.cdh.enums.statementDeliveryOption.electronicOnly
            },
            paper: {
                text: MsgStatements.lblElectronicPaper,
                value: $.cdh.enums.statementDeliveryOption.electronicAndPaper
            }
        }
    };

    ko.components.register("statements1", {
        template: { element: "template-document-delivery" },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new module.MainViewModel(specifics, updateHeight);
            }
        }
    });

})(window.docDelivery, window.jQuery, window.ko);