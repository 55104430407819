(function (module, ko) {

    var switchPdf = function(vm) {
        vm.pdf.switch(vm.lastSavedOption() == vm.options.paper.value ? "on" : "off");
    }

    module.PreferencesViewModel = function (accountId, specifics, navigator) {
        var self = this;

        self.getOptionRequest = new specifics.requests.get.getDeliveryOption(accountId);
        self.updateOptionRequest = new specifics.requests.post.setDeliveryOption();

        self.deliveryOption = ko.observable();
        self.lastSavedOption = ko.observable();

        self.options = specifics.optionSettings;
        self.pdf = new module.PdfViewModel(accountId, specifics);

        self.PreferencesLabel = {
            lblElectronicDocumentAccess: $.cdh.languageConstant.getConstant('nonhsa.electronic-document-access'),
            lblOrderToElect: $.cdh.languageConstant.getConstant('nonhsa.order-to-elect'),
            lblPleaseOpen: $.cdh.languageConstant.getConstant('messages.please-open'),
            lblEnterPinNumber: $.cdh.languageConstant.getConstant('messages.enter-pin-number'),
            lblEnterPdfPinNumber: $.cdh.languageConstant.getConstant('nonhsa.pdf-pin')
        };

        self.showPdf = function(data, event) {
            $.cdh.helper.showPdfViwer(event.target.href);
        };

        self.clickEnter = self.clickSubmit = function() {
            if(self.submitEnabled()){
                self.submit();
            }
        }
        
        self.submitEnabled = ko.pureComputed(function () {
            if (!self.pdf.switchedOff() && !self.pdf.pinValid())
                return false;

            return self.lastSavedOption() !== self.deliveryOption() &&
                !self.updateOptionRequest.isLoading();
        });
        self.submit = function() {
            self.updateOptionRequest.load({ id: accountId, deliveryOption: this.deliveryOption() })
                .done(function(result) {
                    if (result) {
                        self.lastSavedOption(self.deliveryOption());
                        switchPdf(self);

                        module.postbox.notifySubscribers(self.deliveryOption(), module.topics.preferencesLoaded);

                        self.cancel();
                    }
                });
        };
        self.cancel = function() {
            navigator.goToList();
        };

        self.getOptionRequest.load().done(function (option) {
            self.lastSavedOption(option);
            self.deliveryOption(self.options.electronic.value);

            switchPdf(self);
            module.postbox.notifySubscribers(option, module.topics.preferencesLoaded);
        });
    }

})(window.docDelivery, window.ko);