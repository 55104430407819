import {ProfileService} from "../../../Components/Http/Profile/ProfileService";

(function ($, ko) {

    const CardReimbursementMethod = 5;


    function ConnectedCacheAvailable(profile) {
        
        if (!profile) return false;
        
        if(!$.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateDirectDepositInformation)) return false;
        
        if (profile.ReimbursementMethod === CardReimbursementMethod) return false;

        const availableMethods = profile.ReimbursementMethodList || [];
        const cardMethod = availableMethods.find(function (m) {
            return m.ReimbursementMethod === CardReimbursementMethod;
        });

        return !!cardMethod;
    }
    
    function ProfileItemBase(updateHeight){

        const self = this;
        
        self.profileService = new ProfileService();
        self.displayConnectedCash = ko.pureComputed(function () {
            return $.cdh.extensions.profile.ConnectedCacheAvailable(self.profile());
        });
        self.hasConnectedCash = ko.pureComputed(function () {
            const profile = self.profile();
            if (!profile) return false;
            return profile.ReimbursementMethod === 5;
        });
        
        self.editReimbursementModel = ko.observable(null);
        self.profile = ko.observable(null);

        function loadProfile() {
            self.profileService.getAsync().then(function (res) {
                const profile = res.data();
                self.profile(profile);
                self.editReimbursementModel(new $.cdh.models.ProfileReimbursementMethodEditPopup(profile, false, updateHeight, function (data) {
                    self.profile(null);
                    loadProfile();
                }));
            }).catch(function (err) {
                console.error(err);
            });
        }
        loadProfile();
    }

    $.extend(true, $.cdh, {
        extensions: {
            profile: {
                ProfileItemBase: ProfileItemBase,
                ConnectedCacheAvailable: ConnectedCacheAvailable
            }
        }
    });

})(jQuery, ko);