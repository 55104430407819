import template from "./medicine-cabinet.html"

(function ($, ko) {
    ko.components.register('medicine-cabinet', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new MedicineCabinet(params, widgetInfo);
            }
        }
    });

    $.extend(true, $.cdh, {
        medicineCabinet: {
            searchKey: 'medicine-cabinet-search-key-' + $.cdh.user.uniqueHash
        }
    });

    function MedicineCabinet(params, widgetInfo) {
        const self = this;

        let page = $.cdh.helper.urlParam('page');
        let ndcParam = null;
        let priceParam = null;

        if (page === 'DrugSearch') {
            ndcParam = $.cdh.helper.urlParam('ndc');
            priceParam = $.cdh.helper.urlParam('price');
        }
        
        self.participantService = new $.cdh.http.smartAccount.ParticipantService();

        self.disabled = false;
        self.showedData = ko.observable(false);
        
        self.pharmacy = null;
        self.prescriptions = null;
        self.rxCardId = null;
        self.fullName = null;
        self.email = null;
        
        self.hasPrescriptions = ko.observable(false);
        
        function load() {
            self.participantService.getDetailsAsync().then(function (result) {
                let data = result.data();

                if (data.MedicineCabinetDisabled) {
                    self.disabled = true;
                } else {
                    let details = data.Details;
                    self.pharmacy = details.Pharmacy;
                    self.prescriptions = details.Prescriptions;
                    self.rxCardId = details.RxCardId;
                    self.fullName = data.FullName;
                    self.email = data.Email;
                    
                    self.hasPrescriptions(self.prescriptions.length > 0);
                    self.showedData(true);

                    if(ndcParam) {
                        let searchedItem = self.prescriptions.find(item => item.Drug.Ndc === ndcParam && (!priceParam || item.Price === parseFloat(priceParam)));
                        if (searchedItem) {
                            searchedItem.forceDrugSearch = true;
                        }
                    }
                }

                widgetInfo.updateHeight();
            });
        }
        
        load();
    }

})(jQuery, ko);