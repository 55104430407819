import {DataServiceBase} from "../DataServiceBase";

let singleInstance;

export class WidgetsService extends DataServiceBase {
    constructor() {
        if (singleInstance) {
            return singleInstance;
        }
        
        super('/Widgets');
        this.getRewardsUrlPromise = null;
        
        singleInstance = this;
    }
    
    clearCache() {
        this.getRewardsUrlPromise = null;
    };

    async getRewardsUrlAsync() {
        if (!this.getRewardsUrlPromise) {
            this.getRewardsUrlPromise = this.getAsync(null, 'RewardsUrl');
        }
        let result = await this.getRewardsUrlPromise;
        return result.data();
    };
}