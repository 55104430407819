(function ($, ko) {
    ko.components.register('expenses-add', {
        template: { element: 'template-expenses-add' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new $.cdh.models.ExpensesAdd(updateHeight);
            }
        }
    });
})(jQuery, ko);