import template from "./opportunities-list.html";
import {ParticipantOpportunitiesService} from "../../Http/SmartAccount/OpportunitiesService";

ko.components.register('opportunities-list', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new OpportunitiesList(params, widgetInfo);
        }
    }
});

export class OpportunitiesList {
    constructor(params, widgetInfo) {
        this.widgetInfo = widgetInfo;
        this.opportunitiesService = new ParticipantOpportunitiesService();
        
        this.opportunities = ko.observableArray();
        this.count = ko.observable().publishOn($.cdh.topics.opportunities.countValue);
        this.isEmpty = ko.pureComputed(() => this.opportunities().length === 0);
        
        this.close = params.closeFunc;

        this.paginatorModel = {
            pageNo: ko.observable(),
            totalPages: ko.observable(),
            showAllAllowed: true,
            defaultPageSize: 10,
            currentPageSize: 10,
            showAll: function(callback) {},
            onChanged: (currentPage, pageSize, callback) => {
                if(pageSize) {
                    this.paginatorModel.currentPageSize = pageSize;
                }
                this.load(currentPage);
            }
        };
        
        this.load();
    }
    
    async load(currentPage) {
        this.paginatorModel.pageNo(currentPage || 1);
        
        let pageParams = {
            pageNo: this.paginatorModel.pageNo(),
            pageSize: this.paginatorModel.currentPageSize
        };
        
        let result = await this.opportunitiesService.getPageAsync(pageParams);
        let pagedList = result.data();
        
        this.opportunities(pagedList.Data);
        this.count(pagedList.Total);
        this.paginatorModel.totalPages(pagedList.TotalPages);
        
        this.widgetInfo.updateHeight();
    }
}