import template from './merchant-locator.html';

(function ($, ko) {
    function MerchantLocator(params) {
        const self = this;
        self.selectedDistance = ko.observable();
        self.zipCode = ko.observable(document.getElementById("selectZipCode").value);
        self.zipCodeValidation = new $.cdh.validation(self.zipCode);
        self.distanceOptions = ko.observableArray([
            { label: "5 miles", value: 5 },
            { label: "10 miles", value: 10 },
            { label: "15 miles", value: 15 },
            { label: "20 miles", value: 20 },
            { label: "25 miles", value: 25 },
            { label: "50 miles", value: 50 }
        ]);

        self.merchantLocatorData = ko.observableArray();
        self.merchantLocatorService = new $.cdh.http.smartAccount.MerchantLocatorService();
        self.storeLocations = ko.observableArray();
        self.enableSearchButton = ko.observable(false);

        self.handlesearchButton = function (event, newData) {
                if (newData.target.value.length > 4) {
                self.enableSearchButton(true);
                checkZipCode(newData.target.value);
            } else {
                self.enableSearchButton(false);
            }
        };
        self.searchStores = function (data, event) {
            
            self.zipCodeValidation.activate();
            if (self.zipCodeValidation.hasErrors()) {
                document.getElementById("selectZipCode").classList.add("merchant-zipCode-error");
            }
            else {
                document.getElementById("selectZipCode").classList.remove('merchant-zipCode-error');
                self.merchantLocatorService.getStoreLocations(self.selectedDistance().value, self.zipCode()).then(function (result) {
                    self.storeLocations(result.xhr.responseJSON.data);
                });
            }
        };

        self.ensureNumerical = function (data, e) {
            var keyValue = e.key;
            if (keyValue.match(/[0-9]/g)) {
                return true;
            }
            return false;
        }

        self.searchOnEnter = function (data, event) {
            if (event.key === 'Enter' && self.enableSearchButton()) {
                self.searchStores();
                return false;
            }
            return true;
        };

        function checkZipCode(val) {
            if (!(/^\d{9}$/g.test(val) || /^\d{5}(-\d{4})?$/g.test(val)))
            {
                self.enableSearchButton(false);
                if (!document.getElementById('selectZipCode_validation_message'))
                {
                    var element = document.getElementById("selectZipCode");
                    var span = document.createElement('SPAN');
                    let message = $.cdh.languageConstant.getConstant('extension-validation.5-9-digit-number');

                    span.className = ko.validation.utils.getConfigOptions(element).errorMessageClass;
                    if (!element.id) {
                        element.id = Math.random();
                    }
                    span.id = 'selectZipCode_validation_message';
                    span.setAttribute('role', 'alert');
                    var ariaDescribedby = element.getAttribute('aria-describedby') || '';
                    if (ariaDescribedby) {
                        ariaDescribedby += ' ';
                    }
                    element.setAttribute('aria-describedby', ariaDescribedby + span.id);
                    span.innerText = message;
                    span.style.marginTop = '-18px';
                    element.parentNode.insertBefore(span, element);
                }
            }
            else {
                if (document.getElementById('selectZipCode_validation_message')) {
                    document.getElementById('selectZipCode_validation_message').remove();
                }
            }
        }
    }

    ko.components.register('merchant-locator', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new MerchantLocator(params);
            }
        }
    });
})(jQuery, ko);
