import { DataServiceBase } from "../../DataServiceBase";

(function ($, ko) {
    class CostEstimateService extends DataServiceBase {
        constructor() {
            super('/SmartAccount/Vendors/FindCare');
        }

        searchAsync(procedureIds, memberZip) {
            let urlParams = {
                ProcedureIds : procedureIds,
                MemberZip : memberZip
            }
            return this.getAsync(urlParams, 'Cost-Estimate');
        }

    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                vendors: {
                    CostEstimateService: CostEstimateService
                }
            }
        }
    });
})(jQuery, ko);