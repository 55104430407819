import {DataRequestHandlerBase} from "./DataRequestHandlerBase";
import {HttpResult, HttpService} from "./HttpService";

export class DataServiceBase extends DataRequestHandlerBase {
    constructor(apiUrl) {
        super();
        this.apiUrl = apiUrl;
        this.http = new HttpService();
    }

    getAsync(data, urlSegment) {
        return this.handle(this.http.getAsync(this.buildUrl(urlSegment), data));
    };

    postAsync(data, urlSegment) {
        return this.handle(this.http.postAsync(this.buildUrl(urlSegment), data));
    };

    putAsync(data, urlSegment) {
        return this.handle(this.http.putAsync(this.buildUrl(urlSegment), data));
    };

    deleteAsync(urlSegment) {
        return this.handle(this.http.deleteAsync(this.buildUrl(urlSegment)));
    };

    buildUrl(urlSegment) {
        return this.apiUrl + (urlSegment ? '/' + urlSegment : '');
    }
}
