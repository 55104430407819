(function (ko, $) {
    var ViewModel = function (updateHeight, template) {
        
        this.isVisible = ko.observable(false);

        this.template = new $.cdh.services.templateExtractor(template);
        this.eConsentStatusRequest = new $.cdh.get.profile.eConsentStatus();

        this.eConsentStatusRequest.load().done(function (eConsentStatus) {
            if (eConsentStatus.Registered || !eConsentStatus.AllowToRegister) {
                var widget = $("easy-hsa-transfer-shortcut");
                $.cdh.helper.removeWidget(widget);
            } else {
                this.isVisible(true);
            }
            updateHeight();
        }.bind(this));

        this.isVisible.subscribe(updateHeight);
    };
    
    ko.components.register("easy-hsa-transfer-shortcut", {
        template: {element: "template-easy-hsa-transfer-shortcut"},
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new ViewModel(updateHeight, params.template);
            }
        }
    });
})(window.ko, window.jQuery);
