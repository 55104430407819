import {CustomLabels} from "../../../../Components/Common/customLabels";

ko.components.register("document-delivery-preferences", {
    template: { element: "template-delivery-preferences-shortcut" },
    viewModel: {
        createViewModel: function(params, componentInfo) {
            var updateHeight = updateContainerHeight(componentInfo.element);
            return new ViewModel(updateHeight, params.template);
        }
    }
});

var buildUrlIfNeeded = function(accountId, field) {
    if (accountId) {
        let url = "?accountId=" + accountId + "&streamline=true";
        field(url);
    }            
}

var ViewModel = function(updateHeight, template) {
    var self = this;

    self.template = new $.cdh.services.templateExtractor(template);

    self.taxFormsAccountId = ko.observable();
    self.statementsAccountId = ko.observable();
    self.feeAmt = ko.observable();
    self.isVisible = ko.observable(false);

    self.taxFormsQueryString = ko.observable();
    self.statementsQueryString = ko.observable();

    self.DeliveryPreferencesShortcutLabel = {
        lblStatements: $.cdh.languageConstant.getConstant('link.statements'),
        lblTaxForms: $.cdh.languageConstant.getConstant('link.tax-forms')
    };

    self.offerChangeTaxDelivery = ko.pureComputed(function () {
        return self.taxFormsQueryString() != null;
    });
    self.offerChangeStatementDelivery = ko.pureComputed(function () {
        return self.statementsQueryString() != null;
    });
    self.offerChangeDelivery = ko.pureComputed(function () {
        return self.offerChangeStatementDelivery() || self.offerChangeTaxDelivery();
    });


    self.offerChangeDeliveryText = ko.pureComputed(function () {
        
        if(self.feeAmt() > 0) {
            return CustomLabels.documentDeliveryPreferences.goGreenAll(self.feeAmt());
        }
        
        if(self.taxFormsAccountId() || self.statementsAccountId())
        {
            return CustomLabels.documentDeliveryPreferences.widget.goGreen();
        }
        
        return CustomLabels.documentDeliveryPreferences.widget.goneGreen();
        
    }, this);

// ReSharper disable once InconsistentNaming
    new $.cdh.get.accounts.getCurrentHsaPaper()
        .load()
        .done(function (response) {
            if (response) {
                self.taxFormsAccountId(response.TaxFormsAccountId);
                self.statementsAccountId(response.StatementsAccountId);
                buildUrlIfNeeded(response.TaxFormsAccountId, self.taxFormsQueryString);
                buildUrlIfNeeded(response.StatementsAccountId, self.statementsQueryString);
                if(response.FeeAmt > 0) {
                    self.feeAmt(response.FeeAmt);
                }
                self.isVisible(true);
                updateHeight();
            }
    });
};