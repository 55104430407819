import template from "./smart-registration-result.html"

(function ($, ko) {
    ko.components.register('smart-registration-result', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ReviewResultsModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ReviewResultsModel(params, updateHeight) {

        const userData = params.userData;
        this.firstName = userData.firstName();
        this.lastName = userData.lastName();
        this.confirmEmail = userData.email();
        this.clickCancel = params.model.onCancel;
        this.goToQuestion = () => {
            params.model.onNext({ Success: true, EditQuestions: true });
        }
        this.questions = ko.observable({});
        this.goToReviewStep = function () {
            params.model.onNext({ Success: true, EditUserData: true });
        }

        this.confirmMobileNumber = userData.mobileNumber();
        this.mobileNumberExists = ko.computed(() => {
            return this.confirmMobileNumber && this.confirmMobileNumber.trim() !== '';
        });
        this.submitSetup = function () {
             params.model.onNext({ Success: true });
        }.bind(this);
    }

})(jQuery, ko);