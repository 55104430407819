(function ($) {
    $.extend(true, $, {
        cdh: {
            models: {
                editProfileModel: function (model, submitFunc) {
                    this.anyHsa = ko.observable(false);
                    this.originalModel = model;

                    this.canSubmit = ko.pureComputed(function () {
                        return this.originalModel.hasPermissionsToEditProfile;
                    }, this);

                    this.isPhoneNumberDirty = ko.observable(false);
                    this.isMobileNumberDirty = ko.observable(false);
                    this.isEmailDirty = ko.observable(false);

                    this.preRender = function () {
                        //Copy viewModel and create new instance of profile with validations
                        model.updateProfileRequest = new $.cdh.post.profile.update();
                        this.model = new $.cdh.models.Profile(addValidations(Object.create(model.viewModel())), model.vm);

                        this.model.viewModel().Phone.subscribe(function (value) {
                            var originalPhone = this.originalModel.viewModel().Phone;
                            if (value != originalPhone)
                            {
                                if (value && originalPhone && !this.isPhoneNumberDirty() && value.length < originalPhone.length) {
                                    this.model.viewModel().Phone("");
                                }
                                this.isPhoneNumberDirty(true);
                            }
                            else {
                                this.isPhoneNumberDirty(false);
                            }
                        }.bind(this));

                        this.model.viewModel().MobileNumber.subscribe(function (value) {
                            var originalMobileNumber = this.originalModel.viewModel().MobileNumber;
                            if (value != originalMobileNumber) {
                                if (value && originalMobileNumber && !this.isMobileNumberDirty() && value.length < originalMobileNumber.length) {
                                    this.model.viewModel().MobileNumber("");
                                }
                                this.isMobileNumberDirty(true);
                            }
                            else {
                                this.isMobileNumberDirty(false);
                            }
                        }.bind(this));

                        this.model.viewModel().Email.subscribe(function (value) {
                            if (value != this.originalModel.viewModel().Email) {
                                this.isEmailDirty(true);
                            }
                            else {
                                this.isEmailDirty(false);
                            }
                        }.bind(this));

                        this.model.viewModel().Phone.extend({ phoneUS: { message: $.cdh.languageConstant.getConstant("messages.valid-phone-number-10-digits"), params: true, onlyIf: this.isPhoneNumberDirty } });
                        this.model.viewModel().MobileNumber.extend({ phoneUS: { message: $.cdh.languageConstant.getConstant("messages.valid-phone-number-10-digits"), params: true, onlyIf: this.isMobileNumberDirty } });
                        this.model.viewModel().Email.extend({
                            email: {
                                onlyIf: function () {
                                    return this.isEmailDirty()
                                }.bind(this)
                            }
                        });

                        $.cdh.accounts.initAccounts(function () {
                            this.anyHsa($.cdh.accounts.anyBillPayEnabledCurrent());

                            if (this.anyHsa()) {
                                this.model.viewModel().Address1.extend({ notPostAddress: true });
                            }
                        }.bind(this));

                        this.model.isSameAddress(model.isSameAddress());
                        this.viewModel = this.model.viewModel();                        
                        this.model.tempAddr = ko.observable(addValidations(Object.create(model.viewModel())));
                        this.validation = new $.cdh.validation(this.model.viewModel);
                    }.bind(this);

                    this.anyHsa.subscribe(function(value) {
                        setTimeout(function() {
                            document.getElementById('tooltip_home_address_wrapper').removeAttribute('title');
                        }, 200);
                    });

                    this.setClose = function (closeFunc) {
                        this.setDefaultFlags();
                        this.close = closeFunc;
                    }.bind(this);

                    this.clickCancel = function () {
                        this.setDefaultFlags();
                        this.close();
                    }

                    this.clickSubmit = function () {                        
                        this.validation.activate();
                        if (!this.validation.hasErrors() && this.canSubmit()) {
                            this.setDefaultFlags();
                            //Update original view model
                            submitFunc(model, this.model, this.close);
                        }
                    }

                    this.changeShippingAddress = function () {
                        if (this.model.isSameAddress()) {
                            this.model.viewModel().ShippingAddress1(this.model.viewModel().Address1());
                            this.model.viewModel().ShippingAddress2(this.model.viewModel().Address2());
                            this.model.viewModel().ShippingAddressCity(this.model.viewModel().City());
                            this.model.viewModel().ShippingAddressState(this.model.viewModel().State());
                            this.model.viewModel().ShippingAddressZip(this.model.viewModel().Zip());
                            this.model.viewModel().ShippingAddressCountry(this.model.viewModel().Country());
                        } else {
                            this.model.viewModel().ShippingAddress1(this.originalModel.viewModel().ShippingAddress1);
                            this.model.viewModel().ShippingAddress2(this.originalModel.viewModel().ShippingAddress2);
                            this.model.viewModel().ShippingAddressCity(this.originalModel.viewModel().City);
                            this.model.viewModel().ShippingAddressState(this.originalModel.viewModel().State);
                            this.model.viewModel().ShippingAddressZip(this.originalModel.viewModel().Zip);
                            this.model.viewModel().ShippingAddressCountry(this.originalModel.viewModel().Country);
                        }
                        return true;
                    }

               
                    function addValidations(data) {
                        data.Phone = ko.observable(ko.unwrap(data.Phone));
                        data.MobileNumber = ko.observable(ko.unwrap(data.MobileNumber));
                        data.Email = ko.observable(ko.unwrap(data.Email));
                        data.Address1 = addRequiredValidation(data.Address1);
                        data.Address2 = ko.observable(ko.unwrap(data.Address2));
                        data.City = ko.observable(ko.unwrap(data.City)).extend({ required: true, extendedSymbolSet: true });
                        data.State = addRequiredValidation(data.State);
                        data.Zip = ko.observable(ko.unwrap(data.Zip)).extend({ required: true, zipCode: true });
                        data.Country = addRequiredValidation(data.Country);
                        
                        data.ShippingAddress1 = ko.observable(ko.unwrap(data.ShippingAddress1)).extend({ required: true, specialAddress: true });
                        data.ShippingAddress2 = ko.observable(ko.unwrap(data.ShippingAddress2));

                        data.ShippingAddressCity = ko.observable(ko.unwrap(data.ShippingAddressCity)).extend({ required: true, extendedSymbolSet: true });
                        data.ShippingAddressState = ko.observable(ko.unwrap(data.ShippingAddressState)).extend({ required: true });
                        data.ShippingAddressZip = ko.observable(ko.unwrap(data.ShippingAddressZip)).extend({ required: true, zipCode: true });
                        data.ShippingAddressCountry = ko.observable(ko.unwrap(data.ShippingAddressCountry)).extend({ required: true });
                        return data;
                    }

                    function addRequiredValidation(field, msg) {
                        return ko.observable(ko.unwrap(field)).extend({ required: true });
                    }

                    this.setDefaultFlags = function () {
                        this.isPhoneNumberDirty = ko.observable(false);
                        this.isMobileNumberDirty = ko.observable(false);
                        this.isEmailDirty = ko.observable(false);
                    }
                }
            }
        }
    });
}(jQuery));