import template from "./ineligible-balance-payment-entry.html"

ko.components.register('ineligible-balance-payment-entry', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new PaymentEntry(params, widgetInfo.updateHeight);
        }
    }
});

function PaymentEntry(params, updateHeight) {
    const self = this;
    self.authorizationCustomTextRequest = new $.cdh.get.repayment.authorizationCustomText();
    self.accountActivityBalanceDueRequest = new $.cdh.get.accountActivity.getBalanceDue();
    self.submitRequest = new $.cdh.post.repayment.submit();
    self.repayRequest = new $.cdh.post.repayment.repay();

    self.amountType = {
        full: 2,
        partial: 3,
        transactions: 4
    };

    self.selectedAmountType = ko.observable(self.amountType.full);
    self.isAmountFull = ko.pureComputed(function () {
        return self.selectedAmountType() === self.amountType.full;
    });
    self.isAmountPartial = ko.pureComputed(function () {
        return self.selectedAmountType() === self.amountType.partial;
    });
    self.isAmountTransactions = ko.pureComputed(function () {
        return self.selectedAmountType() === self.amountType.transactions;
    });
    self.selectedAmountType.subscribe(function (newValue) {
        self.agree(false);
        switch (newValue) {
            case self.amountType.full:
                self.selectedActivities.removeAll();
                self.partialAmount(0);
                self.partialAmount.isModified(false);
                loadAuthorizationText(self.balanceDue());
                return;
        }
    });
    self.balanceDue = params.model.balanceDue;
    self.partialAmount = ko.observable(0).extend({
        greaterThan: 0,
        lessThanOrEqual: self.balanceDue()
    });

    self.partialAmount.subscribe(function () {
        self.agree(false);
        let amount = parseFloat(self.partialAmount() || 0);
        if (isNaN(amount)) {
            amount = 0;
        }
        loadAuthorizationText(amount);
    });

    self.activities = ko.observableArray();
    self.selectedActivities = ko.observableArray();

    self.selectedActivities.subscribe(activities => {
        if (activities.length > 0) {
            let amount = activities.sum(a => a.BalanceDue);
            loadAuthorizationText(amount);
        }
    });

    self.directDeposit = params.model.directDeposit;

    self.agree = ko.observable(false);
    self.authorizationText = ko.observable();
    
    self.tooManyActivities = ko.pureComputed( () => self.isAmountTransactions() && self.selectedActivities().length >= 10);

    self.isSubmitting = ko.pureComputed(function () {
        return self.submitRequest.isLoading() || self.repayRequest.isLoading();
    });

    self.canSubmit = ko.pureComputed(function () {
        return (self.isAmountFull() || self.partialAmount.isValid() || (self.isAmountTransactions() && self.selectedActivities().length > 0 && self.selectedActivities().length <= 10)) && self.agree() && !self.isSubmitting();
    });

    self.onSubmit = function () {
        if (self.isAmountFull() || self.isAmountPartial()) {
            let data = {
                bank: self.directDeposit().bank,
                bankAccountNumber: self.directDeposit().bankAccountNumber,
                amount: self.isAmountFull() ? self.balanceDue() : self.partialAmount()
            };
            self.submitRequest.load(data).done(confirmationText => {
                params.model.onSubmit({
                    confirmationText: confirmationText,
                    amount: data.amount
                });
            });
        } else if (self.isAmountTransactions()) {
            let dataItems = self.selectedActivities().map(item => ({
                TransactionKey: item.TransactionKey,
                SeqNumber: item.SeqNumber,
                SettlementDate: item.SettlementDate,
                Amount: item.BalanceDue
            }));
            self.repayRequest.load({Transactions: dataItems}).done(result => {
                let activities = self.selectedActivities().map(a => {
                    let item = result.Items.find(x => x.TransactionKey === a.TransactionKey && x.SettlementDate === a.SettlementDate)
                    a.IsSuccess = item.IsSuccess;
                    a.ErrorMessage = item.ErrorMessage;
                    return a;
                })
                params.model.onSubmit({
                    activities: activities,
                    amount: activities.filter(x => x.IsSuccess).sum(x => x.BalanceDue),
                    confirmationText: result.ConfirmationText
                });
            });
        }
    };

    self.onCancel = function () {
        if (params.model.closeFunc) {
            params.model.closeFunc();
        }
    };

    self.authorizationTextsCache = {};

    function loadAuthorizationText(amount) {
        if (self.authorizationTextsCache.hasOwnProperty(amount)) {
            self.authorizationText(self.authorizationTextsCache[amount]);
            return;
        }

        if (self.authorizationCustomTextRequest.isLoading()) {
            self.authorizationCustomTextRequest.cancel();
        }

        self.authorizationCustomTextRequest
            .load({bank: self.directDeposit().bank, paymentAmount: amount})
            .done(function (data) {
                self.authorizationTextsCache[amount] = data;
                self.authorizationText(data);
            });
    }

    loadAuthorizationText(self.balanceDue());

    self.accountActivityBalanceDueRequest.load().done(activities => {
        self.activities(activities);
        if (activities.length === 1) {
            self.selectedActivities(activities);
        }
    });
}