(function($, ko) {
    function beneficiaryListModel(demographic, states, flexAccountId, type, getNextId, max, updateHeight, settings) {
        
        var mapBeneficiaries = function(data) {
            return $.map(data, function(b) {
                return new $.cdh.models.Beneficiary(b, getNextId, demographic, states, 
                    null, null, max, this.add, this.remove,
                    settings, data.length);
            }.bind(this));
        }.bind(this);

        this.beneficiaries = new $.cdh.get.enrollments.beneficiaries(mapBeneficiaries);

        this.type = type;
        this.list = ko.observableArray([]);
        this.list.subscribe(updateHeight);
        this.count = ko.computed(function () {
            return this.list().length;
        }, this);

        this.reload = function () {
            this.beneficiaries
                .load({
                    flexAccountId: flexAccountId,
                    type: type
                })
                .done(function() {
                    this.list(this.beneficiaries.data());
                }.bind(this)); 
        }.bind(this);
        
        this.reload(); // initialize
        
        this.add = function(beneficiary) {
            this.list.push(beneficiary);
        }.bind(this);

        this.remove = function(internalId) {
            this.reload();
        }.bind(this);

        this.toJSList = function() {
            return $.map(this.list(), function(item) {
                    return item.toJS();
                });
        }.bind(this);

        this.update = function(list) {
            var data = $.grep(list, function(item) { return item.BeneficiaryTypeCde == type; });
            this.list(mapBeneficiaries(data));
        }.bind(this);

    }
    
    function beneficiaryListMini(demographic, states, flexAccountId, type, getNextId, max, updateHeight, settings) {
        beneficiaryListModel.call(this, demographic, states, flexAccountId, type, getNextId, max, updateHeight, settings);
        this.allocationState = ko.observable(false);
        
        this.showAllocationField = ko.pureComputed(function () {
            return this.allocationState();
        }, this);
        
        this.updateBeneficiariesRequest = new $.cdh.post.enrollments.updateBeneficiariesPercentage();
        
        this.submitAllocation = function () {
            return this.updateBeneficiariesRequest.load({list: this.toJSList()})
                .done(this.update);
        }.bind(this);
    }
    
    function beneficiaryGroupList(flexAccountId, demographic, staticModels, updateHeight, templateBen,beneficiaryListType, settings) {

        this.beneficiaryLastId = 0;
        var getNextBeneficiaryId = function() { return ++this.beneficiaryLastId; }.bind(this);

        this.total = {
            primarySum: ko.pureComputed(function() {
                var sum = 0;
                $.each(this.primaryBeneficiaryList.list(),
                    function(i, item) {
                        sum += item.viewModel.Percentage() * 1.0;
                    });
                return sum;
            }, this),
            contingentSum: ko.pureComputed(function() {
                var sum = 0;
                $.each(this.contingentBeneficiaryList.list(),
                    function(i, item) {
                        sum += item.viewModel.Percentage() * 1.0;
                    });
                return sum;
            }, this),
            maxPrimaryPercentage: ko.pureComputed(function() {
                return 100 - this.total.primarySum();
            }.bind(this)),

            maxContingentPercentage: ko.pureComputed(function() {
                return 100 - this.total.contingentSum();
            }.bind(this))
        };

        this.primaryBeneficiaryList = new beneficiaryListType(
            demographic,
            staticModels.states,
            flexAccountId,
            $.cdh.enums.beneficiaryTypeCde.primary,
            getNextBeneficiaryId,
            this.total.maxPrimaryPercentage,
            updateHeight,
            settings.configurePrimary(this)
        );

        this.contingentBeneficiaryList = new beneficiaryListType(
            demographic,
            staticModels.states,
            flexAccountId,
            $.cdh.enums.beneficiaryTypeCde.contigent,
            getNextBeneficiaryId,
            this.total.maxContingentPercentage,
            updateHeight,
            settings.configureContingent(this)
        );

        this.primaryBeneficiaryAddModel = new $.cdh.models.Beneficiary(null, getNextBeneficiaryId,
            demographic, staticModels.states, flexAccountId,
            $.cdh.enums.beneficiaryTypeCde.primary,
            this.total.maxPrimaryPercentage,
            this.primaryBeneficiaryList.add, this.primaryBeneficiaryList.remove,
            settings.configurePrimary(this));

        this.contingentBeneficiaryAddModel = new $.cdh.models.Beneficiary(null, getNextBeneficiaryId,
            demographic, staticModels.states, flexAccountId,
            $.cdh.enums.beneficiaryTypeCde.contigent,
            this.total.maxContingentPercentage,
            this.contingentBeneficiaryList.add, this.contingentBeneficiaryList.remove,
            settings.configureContingent(this));

        this.total.primarySum.extend({
            equal: {
                onlyIf: ko.pureComputed(function() {
                    return !!this.primaryBeneficiaryList.list().length;
                }, this),
                params: 100,
                message: $.cdh.languageConstant.getConstant("messages.primary-beneficiaries-percentage")
            }
        });

        this.total.contingentSum.extend({
            equal: {
                onlyIf: ko.pureComputed(function() {
                    return !!this.contingentBeneficiaryList.list().length;
                }, this),
                params: 100,
                message: $.cdh.languageConstant.getConstant("messages.contingent-beneficiaries-percentage")
            }
        });

        this.validation = new $.cdh.validation(this.total);

        this.isValid = ko.pureComputed(function() {
            if (templateBen.DisplayContBeneficiarySec.Value || templateBen.DisplayPrimBeneficiarySec.Value)
            {
                this.validation.activate();
                return this.validation.activated() ? !this.validation.hasErrors() : true;
            }
              return true; 
        }, this);
    }
    
    var defaultBeneficiaryConfiguration = function () {
        return {
            allowEditPercentage: true,
            onCreate: {}, 
            onEdit:{}
        };
    };

    $.extend(true, $, {
        cdh: {
            models: {
                BeneficiaryList: beneficiaryListModel,
                BeneficiaryListMini: beneficiaryListMini,
                BeneficiaryGroupList: function(flexAccountId, demographic, staticModels, updateHeight, templateBen) {
                    beneficiaryGroupList.call(this, flexAccountId, demographic, staticModels, updateHeight, templateBen, beneficiaryListModel,
                        {
                            configurePrimary: defaultBeneficiaryConfiguration,
                            configureContingent: defaultBeneficiaryConfiguration
                        });
                },
                BeneficiaryGroupListMini: function(flexAccountId, demographic, staticModels, updateHeight, templateBen) {
                    beneficiaryGroupList.call(this, flexAccountId, demographic, staticModels, updateHeight, templateBen, beneficiaryListMini,
                        {
                            
                            configurePrimary: function (context) {
                                var cfg =  {
                                    readonlyPercentage: true,
                                    defaultPercentage: function () {
                                        return 0;
                                    },
                                    canCopyParentAddress: true,
                                    canDelete: ko.pureComputed(function () {
                                        return this.primaryBeneficiaryList.count() > 1 ||
                                            this.contingentBeneficiaryList.count() == 0;
                                    }, context)
                                };
                                return { onCreate: cfg, onEdit: cfg, allowEditPercentage: false };
                            },
                            configureContingent: function (context) {
                                var cfg = {
                                    readonlyPercentage: true,
                                    defaultPercentage: function () {
                                        return 0;
                                    },
                                    canCopyParentAddress: true
                                }; 
                                return { onCreate: cfg, onEdit: cfg, allowEditPercentage: false }
                            }
                        }
                    );
                }
            }
        }
    });
})(jQuery, ko);