
import template from "./confirm-opt-out-high-yield.html"


    ko.components.register('confirm-opt-out-high-yield', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ConfirmOptOutHighYield(params, widgetInfo);
            }
        }
    });
    class ConfirmOptOutHighYield {
        constructor(params, widgetInfo) {
            this.updateAccountRequest = params.model.updateAccountRequest;
            this.accountId = params.model.accountId;
            this.options = params.model.options;
            this.highYieldOptIn = params.model.highYieldOptIn;

            this.close = params.closeFunc;
            this.canOptOut = ko.pureComputed(function () {
                return !this.updateAccountRequest.isLoading();
            }, this);
            widgetInfo.updateHeight();
        }
        clickCancel() {
            this.close();
        };
        optOutofHYHSA() {

            if (!this.canOptOut())
                return;

            this.updateAccountRequest.load({
                id: this.accountId,
                options: this.options,
                highYieldOptIn: false
            }).done(function () {
                this.close();
                window.location.href = $.cdh.routes.predefined.accounts.detailsHsa + '?accountId=' + this.accountId;
            }.bind(this));
        };
    }