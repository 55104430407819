import template from "./account-activity-hsa-billpay-payment-details.html"

ko.components.register('account-activity-hsa-billpay-payment-details', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new PaymentDetails(params, widgetInfo);
        }
    }
});

export class PaymentDetails {
    constructor(params, widgetInfo) {
        let model = params.model;

        this.total = model.FullAmount;
        this.payee = model.Provider;
        this.checkNumber = model.CheckNumber;
        this.denialReason = model.HsaBillPayInfo?.ErrorDescription;
        this.nextPaymentDate = model.HsaBillPayInfo?.NextPaymentDate;
        this.memo = model.HsaBillPayInfo?.Memo;

        const maxLength = 36;
        this.isMemoExpanded = ko.observable(false);

        this.isTruncated = ko.observable(this.memo && this.memo.length > maxLength);
        this.truncatedMemo = ko.observable(this.isTruncated() ? this.memo.substring(0, maxLength) + '...' : this.memo);

        this.toggleMemoText = ko.computed(() => {
            return this.isMemoExpanded() ? $.cdh.languageConstant.getConstant("field.show-less") : $.cdh.languageConstant.getConstant("field.show-more");
        });

        this.toggleMemo = () => {
            this.isMemoExpanded(!this.isMemoExpanded());
        };
    }
}