import {DataServiceBase} from "../DataServiceBase";

export class ParticipantOpportunitiesService extends DataServiceBase {
    constructor() {
        super('/SmartAccount/Participant/Opportunities');
    }

    getPageAsync(pageParams) {
        return this.getAsync(pageParams, 'GetPage');
    };

    getCountAsync() {
        return this.getAsync(null, 'Count');
    };
    
    hideAsync(opportunityId: string) {
        return this.postAsync(null, `Hide/${opportunityId}`);
    };

    markAsReadAsync(item) {
        return this.putAsync(item, `MarkAsRead`);
    }

    dismissAsync(opportunityId) {
        return this.postAsync({ Id: opportunityId, Status: 4 }, `Dismiss`);
    }
}