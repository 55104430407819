import template from "./dependent-editor-link-accounts.html"

(function ($, ko) {
    ko.components.register('dependent-editor-link-accounts', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new DependentEditorLinkAccounts(params, updateContainerHeight(componentInfo.element))
            }
        }
    });
    
    const utils = {
        disabledPlanIds: ["TRN", "PKG", "PARKING" ],
        
        accountsFilter: function (accounts) {
            const result = [];
            for (let i = 0; i < accounts.length; i++) {
                const account = accounts[i];
                if (account.AccountPlanYear !== $.cdh.enums.planYear.current && account.AccountPlanYear !== $.cdh.enums.planYear.future ) {
                    continue;
                }

                result.push(account)
            }
            return result;
        }
    };
    
    function Account(account, dependent, isEdit) {

        this.AccountId = account.AccountId;
        this.Account = account.AccountFullName;
        this.PlanStartDate = account.PlanStartDate;
        this.PlanEndDate = account.PlanEndDate;
        this.PlanId = account.PlanId;
        this.CardEligible = account.CardEligible;
        this.FlexAcctId = account.FlexAcctId;

        this.disabled = false;
        this.selected = ko.observable(dependent.FlexAcctIds.indexOf(account.FlexAcctId) >= 0);
        if (utils.disabledPlanIds.indexOf(account.PlanId.toUpperCase()) >= 0 || account.PreventDepAcctLinking) {
            this.disabled = true;
        } else if (isEdit) {
            this.disabled = dependent.FlexAcctIds.indexOf(account.FlexAcctId) >= 0;
        }
        if (!isEdit && !this.disabled && !this.selected()){
            this.selected(true);
        }
    }
    
    function DependentEditorLinkAccounts(params, updateHeight) {
        const self = this;

        const isEdit = params.model.isEdit;
        const asClaimant = params.model.asClaimant;

        this.updateHeight = function() {
            setTimeout(updateHeight, 600);
        };
        this.dependent = params.model.dependent;
        this.dependent.FlexAcctIds = this.dependent.FlexAcctIds || [];

        this.accountsRequest = new $.cdh.get.accounts.list(function (data) {
            self.accounts(utils.accountsFilter(data).map(function (acc) {
                return new Account(acc, self.dependent, isEdit);
            }));
            self.updateHeight();
        });

        this.hasPermissionsToIssueCard = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionAllowParticipantCreateDependentCard);
        this.accounts = ko.observableArray([]);
        
        this.selectedAccounts = ko.pureComputed(function () {
            return self.accounts().filter(function (account) {
                return account.selected();
            }).map(function (account) {
                return account.FlexAcctId;
            });
        });
        
        this.visibleIssueCard = ko.pureComputed(function () {
            return self.accounts().some(function (account) {
                return account.CardEligible;
            });
        });

        this.canIssueCard = ko.pureComputed(function () {
            return self.accounts().filter(function (account) {
                if(self.dependent.HasActiveCards) return false;
                return account.CardEligible && account.selected();
            }).length > 0;
        });
        
        this.canIssueCard.subscribe(function (value) {
            if(!value){
                self.issueCard(false);
            }
        });
        
        this.issueCard = ko.observable(false);

        this.validationMessage = asClaimant 
            ? $.cdh.languageConstant.getConstant("button.claimanat-validation-message1")
            : $.cdh.languageConstant.getConstant("button.claimanat-validation-message12");
        
        this.validationModel = {
            selectedCount: ko.pureComputed(function () {
                return self.selectedAccounts().length;
            }).extend({
                required: false,
                min: {params: 1, message: this.validationMessage}
            })
        };
        
        this.validation = new $.cdh.validation(this.validationModel);
        
        let accountsLengthErrorShowed = false;
        params.model.onNext = function () {
            if(asClaimant || !accountsLengthErrorShowed) {
                self.validation.activate();
                if (self.validation.hasErrors()) {
                    accountsLengthErrorShowed = true;
                    return false;
                }
            }

            self.dependent.FlexAcctIds = self.selectedAccounts();
            self.dependent.IssueDependentCard = self.issueCard();
            return true;
        };
        
        this.accountsRequest.load();
    }
})(jQuery, ko);