import { DataServiceBase } from "../../DataServiceBase";

(function ($, ko) {
    class InsurancesService extends DataServiceBase {
        constructor() {
            super('/SmartAccount/Vendors/FindCare');
        }

        searchAsync(page,pageSize,search) {
            let urlParams = {
                Page: page,
                PageSize: pageSize,
                Search: search,
            }
            return this.getAsync(urlParams, 'Search');
        }

        updateInsuranceAsync(data) {
            return this.postAsync(data, 'Post');
        }
    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                vendors: {
                    InsurancesService: InsurancesService
                }
            }
        }
    });
})(jQuery, ko);