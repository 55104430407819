(function (module, ko, $) {

    var createYearSetters = function(vm, years) {
        return $.map(years,
            function(year) {
                return {
                    text: year,
                    setter: vm.selectedYear.bind(vm.selectedYear, year)
                }
            });
    }

    module.ListFiltersStatementsViewModel = function (accountId, specifics, navigator) {
        module.ListFiltersViewModel.call(this, accountId, specifics, navigator);

        var self = this;

        self.years = ko.observableArray([]);
        self.selectedYear = ko.observable();


        self.selectedYearChanged = null;
        self.selectedYear.subscribe(function(newValue) {
            if (self.selectedYearChanged != null)
                self.selectedYearChanged(newValue);
        });
    }

    module.ListFiltersStatementsViewModel.prototype.initialize = function (years) {
        if (years.length === 0) return;

        if (years.length > 1) {

            this.years(createYearSetters(this, years));
            this.yearsVisible(true);
        }

        this.selectedYear(years[0]);
    }

})(window.docDelivery, window.ko, window.jQuery);