import template from "./dependents-item.html"

(function ($, ko) {
    ko.components.register('dependents-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new DependentItemModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    // Added to support Enrollment modules
    $.extend(true, $, {
        cdh: {
            models: {
                Dependent: function (dependent, profile, updateHeight, onEditSuccessCallback, onDeleteSuccessCallback) {
                    DependentItemModel.call(this, {
                        dependent: dependent,
                        profile: profile,
                        onEditSuccessCallback: onEditSuccessCallback,
                        onDeleteSuccessCallback: onDeleteSuccessCallback
                    }, updateHeight);
                }
            }
        }
    });

    function DependentItemModel(params, updateHeight) {
        this.profile = params.profile;
        this.onEditSuccessCallback = params.onEditSuccessCallback;

        var dependent = params.dependent;
        var onDeleteSuccessCallback = params.onDeleteSuccessCallback;

        this.data = dependent;
        if (!!dependent.DependentDetails) {
            this.details = dependent.DependentDetails;
        }
        var hasPermissionsToIssueCard = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionAllowParticipantCreateDependentCard);
        this.canIssueNewCard = ko.observable(false);

        this.hasPermissionsToEditDependent = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateProfileDemographicInformation);
        this.detailsLoaded = ko.observable(false);
        this.employerName = ko.observable(this.profile.viewModel().EmployerName);
        this.fullName = ko.computed(function () {
            return ko.unwrap(this.data.FirstName) + " " + ko.unwrap(this.data.LastName);
        }.bind(this));

        this.isOpened = ko.observable(false);

        this.detailsRequest = new $.cdh.get.profile.dependentDetails(this.data.DependentId);

        this.takeLast4 = takeLast4;

        this.hasShippingAddress = ko.pureComputed(function () {
            return this.details.ShippingCity ||
                this.details.ShippingCountry ||
                this.details.ShippingAddress1 ||
                this.details.ShippingAddress2 ||
                this.details.ShippingState ||
                this.details.ShippingZip;
        }.bind(this));

        this.editDependentModel = ko.observable({});

        this.loadDependentDetails = function () {
            this.isOpened(!this.isOpened());
            updateHeight();

            if (this.detailsRequest.isLoading() || this.detailsLoaded())
                return;

            this.detailsRequest.load()
                .done(function (result) {
                    this.details = result;
                    this.data.AllowToIssueNewCard = !!result.AllowToIssueNewCard;
                    this.canIssueNewCard(this.data.AllowToIssueNewCard);
                    this.detailsLoaded(true);
                    updateHeight();
                }.bind(this));
        }.bind(this);

        this.onCardKeyDown = function(card, e) {

            if (!e.target.classList.contains('info')) {
                return true;
            }


            if (e.keyCode === 32 || e.keyCode === 13) {
                if (!this.detailsLoaded()) {
                    this.loadDependentDetails();
                    return false;
                }
                this.isOpened(!this.isOpened());
                return false;
            }
            return true;
        };

        this.dependentTitle = ko.observable($.cdh.languageConstant.getConstant("messages.dependent"));

        this.deleteRequest = new $.cdh.post.profile.deleteDependent();

        this.deleteDependent = function () {
            $.cdh.helper.delete({
                title: $.cdh.languageConstant.getConstant("messages.remove-this-dependent", { '0': this.dependentTitle() }),
                message: $.cdh.languageConstant.getConstant("messages.remove-this-dependent-confirmation-message", { '0': this.fullName() }),
                hasCloseBtn: true,
                okCallback: function () {
                    $.cdh.helper.waitAlert($.cdh.languageConstant.getConstant("messages.dependent-removed-message", { '0': this.fullName() }));
                    this.deleteRequest
                        .load({ id: this.data.DependentId })
                        .done(function () {
                            $.cdh.helper.closePopup();
                            (onDeleteSuccessCallback || $.noop)();
                            $.cdh.helper.alert($.cdh.languageConstant.getConstant("messages.dependent-successfully-removed-message", { '0': this.fullName() }), null, null, $.cdh.languageConstant.getConstant("messages.success"));
                        }.bind(this))
                        .fail(function (resp) {
                            $.cdh.helper.closePopup();
                            $.cdh.helper.alert(resp.responseJSON.message, null, null, $.cdh.languageConstant.getConstant('messages.dependent-removal-failed'));
                        });
                }.bind(this)
            });
            
        }.bind(this);

        this.issueDependentCard = function () {
            $.cdh.helper.alert2({
                title: $.cdh.languageConstant.getConstant('profile.issue-new-debit-card-confirmation'),
                message: $.cdh.languageConstant.getConstant("profile.issue-debit-card-for", { '0': this.fullName() }),
                customClass: 'issue_card_popup',
                okCallback: function () {
                    $.cdh.helper.waitAlert($.cdh.languageConstant.getConstant("profile.issue-debit-card-wait-message", { '0': this.fullName() }));

                    var newCardRequest = new $.cdh.post.cards.new();
                    newCardRequest.load({ id: this.data.DependentId })
                        .done(function () {
                            this.canIssueNewCard(false);
                            $.cdh.helper.closePopup();
                            $.cdh.helper.alert($.cdh.languageConstant.getConstant("profile.debit-card-issued-successfully-for", { '0': this.fullName() }), null, null, $.cdh.languageConstant.getConstant("messages.success"));
                        }.bind(this))
                        .fail(function (resp) {
                            $.cdh.helper.closePopup();
                            $.cdh.helper.alert(resp.responseJSON.message, null, null, $.cdh.languageConstant.getConstant('profile.falied-to-issue-card'));
                        });
                }.bind(this),
                okText: $.cdh.languageConstant.getConstant('profile.issue-card')
            });
        }.bind(this);

        this.afterRender = function() {
            updateHeight();
        }.bind(this);

        this.detailsRequest.state.subscribe(updateHeight);

        function takeLast4(text) {
            return text ? ko.unwrap(text).slice(-4) : "";
        }
    }

})(jQuery, ko);