import template from "./claim-submit-instructions.html"

(function ($, ko) {
    ko.components.register('claim-submit-instructions', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ClaimSubmitInstructions(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ClaimSubmitInstructions(params, updateHeight){
        this.widget = params.widget;

        this.onNext = function(){
            if($.isFunction(params.onNext)){
                params.onNext();
                setTimeout(updateHeight, 600);
            }            
        }.bind(this);
    }
})(jQuery, ko);