(function ($, ko) {
    var announcementsModel = function (updateHeight) {
        this.leftAnnouncements = ko.observableArray([]);
        this.rightAnnouncements = ko.observableArray([]);
        this.announcements = new $.cdh.get.resources.announcements(function (json) {
            var data = filterByStartExpireDate(json);
            var i = 0;
            $.each(data, function (i, item) {
                item.opened = ko.observable(false);
                if (i % 2 === 0)
                    this.leftAnnouncements.push(item);
                else
                    this.rightAnnouncements.push(item);
            }.bind(this));
        }.bind(this));

        this.onToggle = function (a) {
            return function () {
                console.debug(a);
                a.opened(!a.opened());
                updateHeight();
            };
        };

        this.afterRender = function() {
            $.cdh.helper.setAllVideoIframesRatio();
        };

        function filterByStartExpireDate(announcements) {
            
            var now = new Date();
            var result = [];

            for (var i = 0; i < announcements.length; i++) {
                var item = announcements[i];

                if (new Date(item.InfoDate) > now) continue;
                if (!!item.ExpireDate && new Date(item.ExpireDate) < now) continue;

                result.push(item);
            }

            return result;
        }

        this.announcements.load();
        this.announcements.state.subscribe(updateHeight);
    };

    ko.components.register('announcements', {
        template: { element: 'template-resources-announcements' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new announcementsModel(updateHeight);
            }
        }
    });
})(jQuery, ko);
