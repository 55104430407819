import template from "./cms-custom-label-group.html"

(function ($, ko) {
    ko.components.register('cms-custom-label-group', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new CustomLabelGroup(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function CustomLabelGroup(params, updateHeight) {
        
        const model = params.model.data;
        this.groupTitle = model.GroupTitle;
        this.items = model.CustomLabels;
    }
})(jQuery, ko);