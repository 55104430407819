import template from "./notification-item-message.html"

ko.components.register('notification-item-message', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new NotificationItemMessage(params);
        }
    }
});

export class NotificationItemMessage {
    constructor(params) {
        let model = params.model;
        
        this.title = model.Title;
        
        this.isViewed = ko.observable(model.Status === $.cdh.enums.notificationStatus.read);
        this.AlertId = model.Id;
        this.AlertDateTime = model.Timestamp;
        this.details = new $.cdh.models.AlertDetailsPopup(this);
    }
}