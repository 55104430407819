import template from "./provider-details.html"

(function ($, ko) {
    function ProviderDetails(params) {
        const self = this;
        self.disabled = false;
        self.showData = ko.observable(false);
        this.providerDetails = JSON.parse(localStorage.getItem('provider-details'));
        this.locationInfo = JSON.parse(localStorage.getItem('locationInfo'));
        this.insuranceInfo = JSON.parse(localStorage.getItem('insuranceInfo'));
        this.specialityId = $.cdh.helper.urlParam('specialityId');
        this.procedureId = $.cdh.helper.urlParam('procedureId');
        this.specialityName = $.cdh.helper.urlParam('speciality');
        this.procedureName = $.cdh.helper.urlParam('procedure');
        this.lastPageNo = $.cdh.helper.urlParam('pageNo');
        this.filteredRating = $.cdh.helper.urlParam('filteredRating');
        this.filteredCost = $.cdh.helper.urlParam('filteredCost');
        this.filteredDistance = $.cdh.helper.urlParam('filteredDistance');
        this.isFilterAvailable = $.cdh.helper.urlParam('filterAvailable');
        this.backToSearchUrl = $.cdh.routes.predefined.findcare.providerList + '?zipcode=' + this.locationInfo.zipcode + '&distance=' + this.locationInfo.distance + '&pageNo=' + this.lastPageNo;
        if (this.filteredCost) {
            this.backToSearchUrl += '&filteredCost=' + this.filteredCost;
        }
        if (this.filteredRating) {
            this.backToSearchUrl += '&filteredRating=' + this.filteredRating;
        }
        if (this.filteredDistance) {
            this.backToSearchUrl += '&filteredDistance=' + this.filteredDistance;
        }
        if (this.isFilterAvailable) {
            this.backToSearchUrl += '&filterAvailable=' + this.isFilterAvailable;
        }
        if (this.specialityId?.length > 0)
            this.backToSearchUrl += '&specialityId=' + this.specialityId + '&speciality=' + this.specialityName;
            
        if (this.procedureId?.length > 0)
            this.backToSearchUrl += '&procedureId=' + this.procedureId + '&procedure=' + this.procedureName;

        self.participantService = new $.cdh.http.smartAccount.ParticipantService();
        function load() {
            self.participantService.getDetailsAsync().then(function (result) {
                let data = result.data();
                if (data.FindCareDisabled) {
                    self.disabled = true;
                }
                else {
                    self.showData(true);
                }
            });

        }
        load();
        this.insurances = [];
        this.location = [];
            self.providerDetails = JSON.parse(localStorage.getItem('provider-details'));
            self.locationInfo = JSON.parse(localStorage.getItem('locationInfo'));
            self.insuranceInfo = JSON.parse(localStorage.getItem('insuranceInfo'));
            self.insurances = [];
            self.location = [];

            self.providerDetails.Insurances.forEach(item => {
                let ins = self.insurances.find(i => item.CarrierAssociation == i.CarrierAssociation);
                if (!ins || ins == null) {
                    self.insurances.push({
                        CarrierAssociation: item.CarrierAssociation,
                        ShowAll: false,
                        DisplayName: [item.DisplayName],
                        Label: $.cdh.languageConstant.getConstant("find-care.see-all")
                    });
                } else {
                    ins["DisplayName"].push(item.DisplayName)
                }
            })

            self.toggle = function (insurance) {
                let temp = self.insurances.find(i => i.CarrierAssociation === insurance.CarrierAssociation);
                let index = self.insurances.findIndex(i => i.CarrierAssociation === insurance.CarrierAssociation);
                temp.ShowAll = !temp.ShowAll;
                let target = document.getElementById(insurance.CarrierAssociation);
                if (target != null) target.innerHTML = "";
                let tag = document.createElement("d");
                if (temp.ShowAll) {
                    insurance.DisplayName.forEach(item => {
                        let p = document.createElement("p");
                        p.className = "text  mb-4 pt-2 insurance-details";
                        p.appendChild(document.createTextNode(item));
                        tag.appendChild(p);
                    })
                    document.getElementById(insurance.CarrierAssociation + '_span').innerHTML = $.cdh.languageConstant.getConstant("find-care.see-less");
                    
                } else {
                    insurance.DisplayName.slice(0, 3).forEach(item => {
                        let p = document.createElement("p");
                        p.className = "text  mb-4 pt-2 insurance-details";
                        p.appendChild(document.createTextNode(item));
                        tag.appendChild(p);
                    })
                    document.getElementById(insurance.CarrierAssociation + '_span').innerHTML = $.cdh.languageConstant.getConstant("find-care.see-all");
                }
                if (target != null) target.appendChild(tag);

            }


            self.providerDetails.Locations.forEach((item, index) => {
                if (index < 1) return;
                let loc = self.location.find(i => item.Address == i.Address);
                if (!loc || loc == null) {
                    self.location.push({
                        Address: item.Address
                    });
                }
            })

        }

    ko.components.register('provider-details', {
            template: template,
            viewModel: {
                createViewModel: function (params, componentInfo) {
                    var updateHeight = updateContainerHeight(componentInfo.element);
                    return new ProviderDetails(updateHeight, params.template);
                }
            }
        })

})(jQuery, ko);