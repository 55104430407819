(function(module, ko) {

    module.postbox = new ko.subscribable();

    module.topics = {
        activeViewChanged: "active-view-changed",
        preferencesLoaded: "preferences-loaded",
        documentsLoaded: "documents-loaded",
        accountLoaded: "account-loaded"
    };

})(window.docDelivery = {}, window.ko);