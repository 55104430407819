(function ($, ko) {
    var contactUsModel = function (updateHeight) {

        this.modelCopy = {
        };
        this.ccEmailDirty = ko.observable(false);
        this.fromEmailDirty = ko.observable(false);

        this.configuration = {
            nameOfAdministrator: ko.observable(''),
            toEmail: ko.observable('')
        };

        this.model = {
            ccEmail: ko.observable('').extend({
                ccEmail: {
                    onlyIf: function() {
                        return this.ccEmailDirty()
                    }.bind(this)
                }
            }),
            fromEmail: ko.observable('').extend({
                required: true,
                email: {
                    onlyIf: function () {
                        return this.fromEmailDirty()
                    }.bind(this)
                }
            }),
            subject: ko.observable('').extend({ required: true }),
            body: ko.observable('').extend({ required: true })
        };

        this.phoneTel = ko.pureComputed(function () {
            if (this.configuration.phone)
                return 'tel:' + this.configuration.phone();
            return '';
        }, this);   

        this.isSent = ko.observable(false);

        this.sendEmailRequest = new $.cdh.post.communications.contactAdmin();
        this.getConfiguration = new $.cdh.get.communications.getConfiguration(function (data) {
            if (data) {
                this.configuration.nameOfAdministrator(data.AdministratorName);
                this.configuration.toEmail(data.SupportEmail);
            }
            return data;
        }.bind(this));

        this.getUser = new $.cdh.get.profile.get(function (data) {
            var email = data.Email;

            // keeping a copy of the email model
            this.modelCopy.ccEmail = email;
            this.modelCopy.fromEmail = email;

            this.model.ccEmail(email);
            this.model.fromEmail(email);
        }.bind(this));

        this.model.ccEmail.subscribe(function (value) {
            var originalccEmail = this.modelCopy.ccEmail;
            if (value != originalccEmail) {
                if (value && originalccEmail && !this.ccEmailDirty() && value.length < originalccEmail.length) {
                    this.model.ccEmail("");
                }
                this.ccEmailDirty(true);
            }
            else {
                this.ccEmailDirty(false);
            }
        }.bind(this));

        this.model.fromEmail.subscribe(function (value) {
            var originalFromEmail = this.modelCopy.fromEmail;
            if (value != originalFromEmail) {
                if (value && originalFromEmail && !this.fromEmailDirty() && value.length < originalFromEmail.length) {
                    this.model.fromEmail("");
                }
                this.fromEmailDirty(true);
            }
            else {
                this.fromEmailDirty(false);
            }
        }.bind(this));

        this.send = function () {
            if (this.sendEmailRequest.isLoading())
                return;
            this.validation.activate();
            if (!this.validation.hasErrors()) {
                this.sendEmailRequest.load({
                    EmailFrom: this.model.fromEmail(),
                    EmailCC: this.model.ccEmail().split(","),
                    EmailSubject: this.model.subject(),
                    EmailBody: this.model.body()
                }).done(function (result) {
                    this.isSent(true);
                }.bind(this));
            }
        };

        this.clear = function () {
            this.model.subject('');
            this.model.body('');
        };

        this.getConfiguration.state.subscribe(updateHeight);

        this.validation = new $.cdh.validation(this.model);

        this.getConfiguration.load();
        this.getUser.load();
    }

    ko.components.register('contact-us', {
        template: { element: 'template-contact-us' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new contactUsModel(updateHeight);
            }
        }
    });
})(jQuery, ko);
