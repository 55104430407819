import template from "./account-activity-member-claim-info.html"

(function ($, ko) {
    ko.components.register('account-activity-member-claim-info', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityMemberClaimInfo(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityMemberClaimInfo(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.model = model;
        self.denialReason = model.DenialReason;
        self.comments = '';
        self.claimant = model.Claimant;
        self.serviceStartDate = model.ServiceStartDate;
        self.serviceEndDate = model.ServiceEndDate;
        self.customDescription = model.CustomDescription;
        self.trackingNumber = model.TrackingNumber;
        self.provider = model.Description;
        self.ilaCategoryValue = model.IlaCategoryDescription;
        self.ilaCategory = ko.observable();
        if (this.ilaCategoryValue) {
            let ilaCategoryDescription = model.IlaCategoryDescription.replace(/\s*\|\s*/g, '|');
            let parts = ilaCategoryDescription.split('|');
            let formattedPairs = [];
            for (let i = 0; i < parts.length; i += 2) {
                let part1 = parts[i].trim();
                let part2 = parts[i + 1] !== undefined ? parts[i + 1].trim() : '';
                formattedPairs.push(`<tr><td>${part1}</td><td style="padding-left: 3px;">${part2}</td></tr>`);
            }
            let formattedIlaCategory = formattedPairs.join('');
            self.ilaCategory = ko.observable(formattedIlaCategory);
        }

    }

})(jQuery, ko);