import template from "./account-activity-tc-claim-details.html"

(function ($, ko) {
    ko.components.register('account-activity-tc-claim-details', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityTcClaimDetails(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityTcClaimDetails(params, updateHeight) {
        const self = this;
        let isValueSet = $.cdh.helper.isValueSet;
        
        let model = params.model;
        let adjudication = model.ClaimAdjudicationDetails;
        
        self.isAdjudicationExist = $.cdh.helper.isValueSet(adjudication);

        if(self.isAdjudicationExist) {
            self.total = model.Amount;
            self.approved = adjudication.ApprovedAmount;
            self.deductible = adjudication.DeductibleAmount;
            self.denied = adjudication.DeniedAmount;
            self.eligible = adjudication.EligibleAmount;
            self.excluded = adjudication.ExcludedAmount;
            self.lowFunds = adjudication.LowFundsAmount;
            self.offset = adjudication.OffsetAmount;
            self.onHold = adjudication.OnHoldAmount;
            self.pended = adjudication.PendedAmount;
        }

        self.denialReason = model.DenialReason;
        self.excludedReason = model.ExcludedReason;
        self.pendedReason = model.PendedReason;

        self.isReason = isValueSet(self.denialReason) || isValueSet(self.excludedReason) || isValueSet(self.pendedReason);
    }

})(jQuery, ko);