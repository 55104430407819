// ReSharper disable MisuseOfOwnerFunctionThis
(function ($, ko) {
    var HsaDisclosureModel = function (eStatement, updateHeight, template) {

        // private
        var token = $.cdh.helper.urlParam("token");
        var tpaId = $.cdh.helper.urlParam("tpaid");
        var byToken = token && token.length > 0 && tpaId && tpaId.length > 0;

        var setVisibility = function (showSections) {
            this.multiselectVisible(false);
            this.disclosureLinksVisible(false);
            this.disclosureRequestVisible(false);

            showSections();
            updateHeight();
        }.bind(this);
        var showAccount = function (account) {
            this.account = account;
            this.flexAccountKey(account.FlexAccountKey);
            setVisibility(function () {
                this.disclosureLinksVisible(true);
            }.bind(this));

            this.pdfPinRequest.load({
                employeeId: this.disclosureRequest.employeeId(),
                employerId: this.disclosureRequest.employerId(),
                accountId: this.flexAccountKey()
            }).done(function (data) {
                this.originalPdfPin(data);
            }.bind(this));

        }.bind(this);
        var showMultiSelect = function (accounts) {
            this.accounts(accounts);
            this.flexAccountKey(accounts[0].FlexAccountKey);
            setVisibility(function () {
                this.multiselectVisible(true);
            }.bind(this));

        }.bind(this);
        var processResponse = function (result) {
            this.loaded(true);

            if (result.length === 1) {
                showAccount(result[0]);
            } else {
                showMultiSelect(result);
            }

        }.bind(this);
        var enteredNameValid = function () {
            var accountFirstName = this.account.FirstName;
            var accountLastName = this.account.LastName;

            return accountFirstName === this.acknowledgeRequest.firstName() &&
                accountLastName === this.acknowledgeRequest.lastName();
        }.bind(this);

        // reset
        var resetForm = function (obj) {
            $.each(obj,
                function(i, observable) {
                    observable("");
                });
            
        }.bind(this);

        this.HsaDisclosureLabel = {
            lblYourSubmissionRecieved: $.cdh.languageConstant.getConstant('your-submission-recieved'),
            lblDisclosureRequest: $.cdh.languageConstant.getConstant('disclosure-request'),
            lblSelectAccount: $.cdh.languageConstant.getConstant('field.select-account'),
            lblButtonContinue: $.cdh.languageConstant.getConstant('link.continue'),
            lblYouOpenAllDisclosures: $.cdh.languageConstant.getConstant('messages.you-open-all-diclosures'),
            lblClickHereToOpenPdf: $.cdh.languageConstant.getConstant('field.click-here-open-pdf'),
            lblEnterPdfPin: $.cdh.languageConstant.getConstant('nonhsa.pdf-pin'),
            lblOrderToElect: $.cdh.languageConstant.getConstant('nonhsa.order-to-elect'),
            lblPleaseOpen: $.cdh.languageConstant.getConstant('messages.please-open'),
            lblEnterPinNumber: $.cdh.languageConstant.getConstant('messages.enter-pin-number'),
            lblSamplePdf: $.cdh.languageConstant.getConstant('link.simple-pdf'),
            lblElectronicDocumentAccess: $.cdh.languageConstant.getConstant('nonhsa.electronic-document-access'),
            lblElectronicPaper: $.cdh.languageConstant.getConstant('nonhsa.electronic-paper'),
            lblElectronicOnly: $.cdh.languageConstant.getConstant('nonhsa.electronic-only'),
            lblStatementMessages: $.cdh.languageConstant.getConstant('nonhsa.statement-message'),
            lblDateSigned: $.cdh.languageConstant.getConstant('nonhsa.date-signed'),
            lblConfirmLastName: $.cdh.languageConstant.getConstant('nonhsa.confirm-last-name'),
            lblLastName: $.cdh.languageConstant.getConstant('registration.last-name'),
            lblConfirmFirstName: $.cdh.languageConstant.getConstant('nonhsa.confirm-first-name'),
            lblFirstName: $.cdh.languageConstant.getConstant('registration.first-name'),
            lblPleaseEnterRequestedInfo: $.cdh.languageConstant.getConstant('messages.please-enter-requested-info')
        };

        var reset = function () {
            this.sendSuccessfully(false);
            this.multiselectVisible(false);
            this.disclosureLinksVisible(false);
            this.disclosureRequestVisible(false);
            this.flexAccountKey();
            this.agreeWithTerms(false);

            this.originalPdfPin("");
            this.deliveryOption($.cdh.enums.statementDeliveryOption.electronicOnly);

            this.needToCheckPin(eStatement && this.isElectronic());
            this.viewedLinks.removeAll();
            this.accounts.removeAll();
            
            resetForm(this.disclosureRequest);
            this.disclosureRequestValidation.deactivate();

            resetForm(this.acknowledgeRequest);
            this.acknowledgeRequestValidation.deactivate();

        }.bind(this);
        //end reset

        var initialize = function () {
            reset();

            if (byToken) {
                this.getByToken
                    .load({ token: token, tpaId: tpaId })
                    .done(function (result) {
                        this.disclosureRequest.tpaId(tpaId);
                        this.disclosureRequest.employeeId(result[0].EmployeeID);
                        this.disclosureRequest.employerId(result[0].EmprId);
                        this.disclosureRequest.lastName(result[0].LastName);

                        processResponse(result);
                    }.bind(this));
            } else {
                setVisibility(function() {
                    this.disclosureRequestVisible(true);
                }.bind(this));
            }
        }.bind(this);
        // end private


        this.template = template;
        this.templateExtractor = new $.cdh.services.templateExtractor(template);

        this.loaded = ko.observable(false);

        // requests
        this.getByToken = new $.cdh.post.hsaDisclosure.getByToken();
        this.getByRequest = new $.cdh.post.hsaDisclosure.getByRequest();
        this.acknowledge = new $.cdh.post.hsaDisclosure.acknowledge();
        this.pdfPinRequest = new $.cdh.post.hsaDisclosure.pdfPin();
        // end requests

        this.resetVisible = ko.observable(!byToken);
        this.eStatement = ko.observable(eStatement);

        this.sendSuccessfully = ko.observable();
        this.multiselectVisible = ko.observable();
        this.disclosureLinksVisible = ko.observable();
        this.disclosureRequestVisible = ko.observable();
        this.flexAccountKey = ko.observable();
        this.agreeWithTerms = ko.observable();

        this.disclosureRequest = {
            lastName: ko.observable("").extend({ required: true }),
            employeeId: ko.observable("").extend({ required: true }),
            employerId: ko.observable("").extend({ required: true }),
            tpaId: ko.observable("").extend({ required: true })
        };
        this.acknowledgeRequest = {
            firstName: ko.observable("").extend({ required: true }),
            confirmFirstName: ko.observable("").extend({ required: true }),
            lastName: ko.observable("").extend({ required: true }),
            confirmLastName: ko.observable("").extend({ required: true }),
            pdfPin: ko.observable("")
        };
        this.HsaLabels = {
            msgFirstNameNoMatch: $.cdh.languageConstant.getConstant('messages.first-name-not-match'),
            msgLastNameNoMatch: $.cdh.languageConstant.getConstant('messages.last-name-not-match')
        };
        this.disclosureRequestValidation = new $.cdh.validation(this.disclosureRequest, false, { recreateValidation: false });
        this.acknowledgeRequestValidation = new $.cdh.validation(this.acknowledgeRequest, false, { recreateValidation: false });
        this.disclosureRequestValidation.hasErrors.subscribe(function () {
            this.acknowledge.error("");
            this.getByToken.error("");
            this.getByRequest.error("");
        }.bind(this));

        this.originalPdfPin = ko.observable();
        this.deliveryOption = ko.observable();
        this.needToCheckPin = ko.observable();
        this.viewedLinks = ko.observableArray();
        this.accounts = ko.observableArray([]);

        // computed
        this.isPinValid = ko.computed(function () {

            if (eStatement && this.pdfPinRequest.isLoading())
                return false;

            return this.acknowledgeRequest.pdfPin() === this.originalPdfPin();
        }, this);
        this.pdfDownloadLink = ko.pureComputed(function () {
            var employeeId = this.disclosureRequest.employeeId();
            var employerId = this.disclosureRequest.employerId();
            var accountId = this.flexAccountKey();
            return "/HsaDisclosure/Pdf?employeeId=" + employeeId + "&employerId=" + employerId + "&accountId=" + accountId;
        }, this);
        this.accountList = ko.pureComputed(function () {
            var result = $.map(this.accounts(), function (a) {
                return {
                    text: a.PlanId + "-" + a.PlanStartDateFormat + "-" + a.PlanEndDateFormat,
                    value: a.FlexAccountKey
                }
            }.bind(this));
            return result;
        }, this);
        this.canDisclosureRequestBySubmitted = ko.pureComputed(function () {
            return !this.disclosureRequestValidation.hasErrors();
        }.bind(this));
        this.allLinksViewed = ko.pureComputed(function () {
            if (this.viewedLinks() == undefined || this.account == undefined)
                return false;

            return this.viewedLinks().length == this.account.Links.length;
        }.bind(this));
        this.isElectronic = ko.pureComputed(function () {
            return this.deliveryOption() === $.cdh.enums.statementDeliveryOption.electronicOnly;
        }, this);
        // end computed

        // extend
        this.acknowledgeRequest.pdfPin.extend({required: {
            onlyIf: function () {
                return this.needToCheckPin();
            }.bind(this)
        }});
        this.acknowledgeRequest.confirmFirstName.extend({
            equal: { params: this.acknowledgeRequest.firstName, message: this.HsaLabels.msgFirstNameNoMatch }
        });
        this.acknowledgeRequest.confirmLastName.extend({
            equal: { params: this.acknowledgeRequest.lastName, message: this.HsaLabels.msgLastNameNoMatch }
        });
        // end extend

        this.deliveryOption.subscribe(function (data) {
            this.needToCheckPin(eStatement && data === $.cdh.enums.statementDeliveryOption.electronicOnly);
            updateHeight();
        }, this);

        // event handlers
        this.clickSendDisclosureRequest = function() {
            this.disclosureRequestValidation.activate();

            if (this.disclosureRequestValidation.hasErrors()) {
                this.acknowledge.error("");
                return;
            }

            this.getByRequest
                .load(this.disclosureRequest)
                .done(function(result) {
                    processResponse(result);
                }.bind(this));
        }.bind(this);

        this.clickSendAcknowledgeRequest = function () {
            this.acknowledge.error(null);

            if (!this.allLinksViewed() || !this.agreeWithTerms()) {
                this.acknowledge
                    .error($.cdh.languageConstant.getConstant('messages.acknowledge-receipt-disclosures'));
                updateHeight();
                return;
            }

            this.acknowledgeRequestValidation.activate();

            if (this.acknowledgeRequestValidation.hasErrors()) {
                this.acknowledge
                    .error($.cdh.languageConstant.getConstant('messages.acknowledge-receipt-disclosures'));
                updateHeight();
                return;
            }

            if (this.needToCheckPin() && !this.isPinValid()) {
                this.acknowledge.error($.cdh.languageConstant.getConstant('messages.pdf-pin-not-valid'));
                updateHeight();
                return;
            }

            if (!enteredNameValid()) {
                this.acknowledge
                    .error($.cdh.languageConstant.getConstant('messages.first-last-name-not-match'));
                updateHeight();
                return;
            }

            this.acknowledge.error(null);

            this.acknowledge.load({
                DataPartnerId: this.account.DataPartnerId,
                EmployerId: this.account.EmprId,
                FlexAcctKey: this.account.FlexAccountKey,
                EmployeeId: this.account.EmployeeID,
                TpaId: this.account.TpaId,
                UseEStatement: eStatement,
                DeliveryOptions: this.deliveryOption()
                })
                .done(function () {
                    this.sendSuccessfully(true);
                }.bind(this));
        }.bind(this);

        this.clickMultiselect = function() {

            var account = $.grep(this.accounts(), function(a) {
                return a.FlexAccountKey === this.flexAccountKey();
            }.bind(this))[0];

            showAccount(account);
            updateHeight();
        }.bind(this);

        this.linkViewed = function (url) {
            return this.viewedLinks.indexOf(url) > -1;
        }.bind(this);

        this.clickViewLink = function (url) {

            if (this.viewedLinks.indexOf(url) > -1)
                return;

            this.viewedLinks.push(url);
            window.open(url, '_blank');
        }.bind(this);

        this.showPdf = function(data, event) {
            $.cdh.helper.showPdfViwer(event.target.href);
        }.bind(this);

        this.reset = function () {
            initialize();
        };
        // end event handlers

        initialize();
    };

    ko.components.register('hsa-disclosure', {
        template: { element: 'template-hsa-disclosure' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new HsaDisclosureModel(false, updateHeight, params.template);
            }
        }
    });

    ko.components.register('hsa-disclosure-estatement', {
        template: { element: 'template-hsa-disclosure' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new HsaDisclosureModel(true, updateHeight, params.template);
            }
        }
    });
})(jQuery, ko);
// ReSharper restore MisuseOfOwnerFunctionThis