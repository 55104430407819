import template from './deductible-status-area.html'

ko.components.register('deductible-status-area', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new DeductibleStatusArea(params, widgetInfo.updateHeight);
        }
    }
});

function DeductibleStatusArea(params, updateHeight) {
    const self = this;

    self.account = params.account();

    self.updateAccountDeductibleStatus = new $.cdh.post.accounts.updateAccountDeductibleStatus();
    
    let planEndDate = moment(self.account.PlanEndDate);
    let isTerminated = self.account.AcctStatusCde === $.cdh.enums.cardholderAccountStatus.terminated;
    let spendingLastDate = self.account.SpendingLastDate ? moment(self.account.SpendingLastDate) : null;
    let planYrExtDte = self.account.PlanYrExtDte ? moment(self.account.PlanYrExtDte) : null;

    self.planDeductibleModel = {
        id: 'plan',
        startDate: moment(self.account.PlanStartDate),
        endDate: isTerminated && spendingLastDate && spendingLastDate <= planEndDate ? spendingLastDate : planEndDate,
        status: self.account.DeductibleStatus,
        deductible: getDeductible(self.account.DeductibleStatus),
        metDate: self.account.DeductibleMetDate,
        canSet: self.account.EmpeCanSetDeductible,
        isChanging: ko.observable(false),
        isSubmitting: ko.pureComputed(() => self.updateAccountDeductibleStatus.isLoading()),
        submit: (model, callback) => submit(model, callback, false)
    };

    self.gracePeriodDeductibleModel = {
        id: 'gracePeriod',
        startDate: moment(self.account.PlanEndDate).add(1, 'days'),
        endDate: isTerminated ? spendingLastDate : planYrExtDte,
        status: self.account.GracePeriodDeductibleStatus,
        deductible: getDeductible(self.account.GracePeriodDeductibleStatus),
        metDate: self.account.GracePeriodDeductibleMetDte,
        canSet: self.account.EmpeCanSetDeductible,
        isChanging: ko.observable(false),
        isSubmitting: ko.pureComputed(() => self.updateAccountDeductibleStatus.isLoading()),
        submit: (model, callback) => submit(model, callback, true)
    };
    
    let currentDate = moment();
    let dateFormat = 'MM/DD/YYYY';

    self.allowGracePeriodDeductible = !self.account.IsHsa && !self.account.IsVeba && self.gracePeriodDeductibleModel.endDate && 
        self.gracePeriodDeductibleModel.endDate >= self.gracePeriodDeductibleModel.startDate &&
        currentDate > self.gracePeriodDeductibleModel.startDate &&
        (!self.account.CoverageMaxEndDate || moment(self.account.CoverageMaxEndDate) >= self.gracePeriodDeductibleModel.startDate) && planYrExtDte &&
        (isTerminated || 
            ((currentDate < self.gracePeriodDeductibleModel.endDate || currentDate.format(dateFormat) === self.gracePeriodDeductibleModel.endDate.format(dateFormat)) 
                && self.account.CarryoverAmount !== 0
                && (self.account.CarryoverAmount !== null || spendingLastDate?.format(dateFormat) === planYrExtDte?.format(dateFormat))) || 
            (currentDate > self.gracePeriodDeductibleModel.endDate && self.account.RolloverDeposits !== 0 && (self.account.RolloverDeposits !== null || spendingLastDate?.format(dateFormat) === planYrExtDte?.format(dateFormat))));
    
    self.allowDeductible = $.cdh.helper.hasFlag($.cdh.enums.mccFlags.deductible, self.account.PlanMccFlags)
        && !self.account.HideDeductible
        && !(self.planDeductibleModel.deductible.isNot && (self.gracePeriodDeductibleModel.deductible.isNot || !self.allowGracePeriodDeductible));
    
    let isAllLimited = self.planDeductibleModel.deductible.isLimited && 
        (self.gracePeriodDeductibleModel.deductible.isLimited && self.allowGracePeriodDeductible);
    
    if(isAllLimited) {
        self.allowGracePeriodDeductible = false;
        self.planDeductibleModel.endDate = self.gracePeriodDeductibleModel.endDate;
    }

    self.isLimitedTitle = self.planDeductibleModel.deductible.isLimited
        || (self.planDeductibleModel.deductible.isNot && self.allowGracePeriodDeductible && self.gracePeriodDeductibleModel.deductible.isLimited);
    
    function submit(model, callback, isGracePeriod) {
        self.updateAccountDeductibleStatus.load({
            id: params.accountId,
            options: params.options,
            isStatusMet: model.isStatusMet,
            metDate: model.metDate,
            isGracePeriod: isGracePeriod
        }).done(function () {
            if (params.updateDeductibleStatusCallback) {
                params.updateDeductibleStatusCallback();
            }
            if (callback) {
                callback();
            }
        });
    }
    
    function getDeductible(status) {
        return {
            isNot: status === 'No Deductible',
            isMet: status === 'Deductible Met',
            isNotMet: status === 'Not Met',
            isLimited: status === 'Limited'
        }
    }

    updateHeight();
}