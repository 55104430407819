(function ($, ko) {
    var $cdh = $.cdh;

    var cardModel = function (card, cardsReissue, issueNewVisible, template, onSuccessCallback) {
        $.extend(true, this, card);
        initCard(this);

        function initCard(card) {
            this.isLostAndIssue = false;
            
            card.FullName = card.FirstName + " " + card.LastName;
            card.CardStatus = ko.observable(card.CardStatus);
            card.IsAvailable = ko.computed(function() {
                return card.CardStatus() === $.cdh.enums.cardStatus.all 
                || card.CardStatus() === $.cdh.enums.cardStatus.new
                || card.CardStatus() === $.cdh.enums.cardStatus.active
                || card.CardStatus() === $.cdh.enums.cardStatus.tempInactive
            });
            card.IsNew = ko.computed(function() {
                return card.CardStatus() === $.cdh.enums.cardStatus.new
            });
            card.IsInactive = ko.computed(function() {
                return card.CardStatus() === $.cdh.enums.cardStatus.tempInactive
                    || card.CardStatus() === $.cdh.enums.cardStatus.processing                    ; 
            });
            card.IsPending = ko.computed(function () {
                return card.CardStatus() === $.cdh.enums.cardStatus.pending                    
            });
            card.isCardUnclickable = ko.computed(function () {
                return card.CardStatus() === $.cdh.enums.cardStatus.pending
                    || card.CardStatus() === $.cdh.enums.cardStatus.processing
                    || card.CardStatus() === $.cdh.enums.cardStatus.failed;
            });
            card.IsOpened = ko.observable(false);
            card.reissueCard = ko.observable(false);
            card.reissueTextEnabled = cardsReissue.Enabled;
            card.reissueText = cardsReissue.Text;
            card.markAsLost = markAsLost;
            card.activate = activate;
            card.newIssue = newIssue;
            card.getPin = getPin;
            card.openCard = openCard;
            card.cardRowKeyDown = cardRowKeyDown;

            card.pinProvider = new $.cdh.get.cards.pin(card.CardProxyNumber);

            card.lostRequest = new $.cdh.post.cards.lost();
            card.activateRequest = new $.cdh.post.cards.activate();

            card.issueNewRequest = new $.cdh.post.cards.new();

            card.details = new $.cdh.get.cards.details(card.CardProxyNumber);
            
            card.pinText = ko.observable();
            card.pinBase64 = ko.observable("");

            card.reportLostCardModel = new $.cdh.models.CardsReportLostPopup(card, card.markAsLost, allowedIssueNewCard, template);
            card.activateModel = new $.cdh.models.ActivateModelPopup(card, card.activate);

            card.setClose = function(closeFunc) {
                card.close = closeFunc;
            };
            
            card.details.load().done(function(data) {
                this.reissueCard(data.ReissueCard);
            }.bind(card));
            
            return card;
        }

        function allowedIssueNewCard(card) {
            if(!card)
                return false;

            return card.AllowIssueNewCard && issueNewVisible;
        }

        function markAsLost(card, onSuccess) {
            if (card.lostRequest.isLoading() || !card.IsAvailable())
                return;
            card.lostRequest.load({ id: card.CardProxyNumber }).done(function(result) {
                if (result) {
                    card.CardStatus($cdh.enums.cardStatus.lostStolen);
                    if (card.reportLostCardModel.IssueNew() === "yes") {
                        this.isLostAndIssue = true;
                        card.newIssue(card, onSuccess);
                    } else {
                        if (onSuccessCallback)
                            onSuccessCallback();

                        if (onSuccess) {
                            onSuccess();
                            $.cdh.helper.alert($.cdh.languageConstant.getConstant("cards.card-lost-stolen")); 
                        }
                    }
                }
            }.bind(card));
        }

        function activate(card, onSuccess) {
            if (card.activateRequest.isLoading() || !card.IsNew)
                return;
            card.activateRequest.load({ id: card.CardProxyNumber }).done(function(result) {
                if (result) {
                    card.CardStatus($cdh.enums.cardStatus.active);
                    onSuccess();
                    $.cdh.helper.alert($.cdh.languageConstant.getConstant("cards.card-activated"), null, null, $.cdh.languageConstant.getConstant("cards.cards"), null, null, null);
                }
            });
        }

        function newIssue(card, onSuccess) {
            if (card.issueNewRequest.isLoading())
                return;
            card.issueNewRequest.load({ id: card.DependentId }).done(function (result) {
                if (onSuccessCallback)
                    onSuccessCallback();
                $.cdh.helper.alert($.cdh.languageConstant.getConstant("cards.card-issue-success"));
                if (onSuccess) onSuccess();
                if (this.isLostAndIssue) $.cdh.helper.alert($.cdh.languageConstant.getConstant("cards.card-lost-stolen"));
                

            }.bind(card));
        }

        function getPin(card) {
            if (card.pinProvider.isLoading() || (card.pinBase64() && card.pinText()))
                return;
            card.pinProvider.load().done(function(result) {
                card.pinText(result.Text);
                card.pinBase64('data:image;base64,' + result.Base64);
            }.bind(this));
        }

        function openCard(card) {
            if (!card.isCardUnclickable()) {
                card.IsOpened(!card.IsOpened());
            }
        };

        function cardRowKeyDown(card, e) {
            if (!e.target.classList.contains('cards-bl_sheet_row')) {
                return true;
            }

            if (e.keyCode === 32 || e.keyCode === 13) {
                card.IsOpened(!card.IsOpened());
                return false;
            }
            return true;
        };
    };

    $.extend(true, $, {
        cdh: {
            models: {
                CardsDetails: cardModel
            }
        }
    });

})(jQuery, ko);