(function ($, ko) {

    var submitClaimModel = function(updateHeight) {
        if($.cdh.tpa.useExpensePortal) {
            document.location.href = $.cdh.routes.predefined.claims.portal;
        }
        
        this.updateHeight = updateHeight;

        this.isAccountActivity = $.cdh.tpa.useAccountActivityPage || 
            document.referrer.endsWith($.cdh.routes.predefined.accountActivity);
        this.closeUrl = this.isAccountActivity 
            ? $.cdh.routes.predefined.accountActivity 
            : $.cdh.routes.predefined.claims.pageList;

        this.anyBillPayEnabled = ko.observable(false);
        this.anyBillPayEnabledOnly = ko.observable(false);
        this.anyManualClaimsEnabled = ko.observable(false);
        this.addClaimsAllowed = ko.observable(false);
        this.anyAccountAvailable = ko.observable(false);
        this.anyManualClaimsEnabledOnly = ko.observable(false);
        this.hasBothAccountTypes = ko.observable(false);

        this.currentStepModel = ko.observable(null);

        this.accountService = $.cdh.services.accounts();

        this.accountsRequest = this.accountService.initAccounts(function () {

            var anyCurrent = this.accountService.anyCurrent();
            var anyBillPayEnabled = this.accountService.anyBillPayEnabledCurrent();
            var anyManualClaimEnabled = this.accountService.anyManualClaimsEnabled();
            var anyHsaCurrent = this.accountService.anyHsaCurrent();
            var anyNonHsaCurrent = this.accountService.anyNonHsaCurrent();
            var listAddClaimAllowedCurrent = this.accountService.listAddClaimAllowedCurrent();

            if (anyBillPayEnabled && !anyManualClaimEnabled) {
                this.anyBillPayEnabledOnly(true);
            }

            if (!anyBillPayEnabled && anyManualClaimEnabled) {
                this.anyManualClaimsEnabledOnly(true);
            }

            if (this.anyBillPayEnabledOnly()) {
                this.currentStepModel(new addPaymentStepModel(this));
            } else if (this.anyManualClaimsEnabledOnly()) {
                this.currentStepModel(new submitClaimStepModel(this));
            }

            this.anyAccountAvailable(anyCurrent);
            this.addClaimsAllowed(listAddClaimAllowedCurrent.length > 0);
            this.anyBillPayEnabled(anyBillPayEnabled);
            this.anyManualClaimsEnabled(anyManualClaimEnabled);
            this.hasBothAccountTypes(anyHsaCurrent && anyNonHsaCurrent);

        }.bind(this));

        this.clickAddPayment = function () {
            this.currentStepModel(new addPaymentStepModel(this));
        }.bind(this);

        this.clickAddClaim = function () {
            this.currentStepModel(new submitClaimStepModel(this));
        }.bind(this);

        this.isAddPaymentView = ko.pureComputed(function () {
            return this.currentStepModel() != null && this.currentStepModel().isAddPaymentView;
        }, this);

        this.isSubmitClaimView = ko.pureComputed(function () {
            return this.currentStepModel() != null && this.currentStepModel().isSubmitClaimView;
        }, this);
    };
    
    function addPaymentStepModel(model){
        this.isAddPaymentView = true;
        this.addPaymentModel = new $.cdh.models.PaymentsAddPopup(0, model.updateHeight, null, model.updateHeight);
        this.addPaymentModel.DisplayedAsPage = true;
        this.addPaymentModel.resetAfterClose = false;
        this.addPaymentModel.preRender();
        this.addPaymentModel.setCancel(function() {
            if (model.anyBillPayEnabledOnly()) {
                window.location.href = model.closeUrl;
            } else {
                model.currentStepModel(null);
            }
            model.updateHeight();
            $.cdh.helper.titlePage($.cdh.languageConstant.getConstant("field.submit-claim"));
        });

        this.addPaymentModel.setClose(function () {
            window.location.href = model.closeUrl;
        });
    }
    
    function submitClaimStepModel(model){
        this.isSubmitClaimView = true;
        this.submitClaimWidgetModel = new $.cdh.models.SubmitClaimWidgetModel(model.updateHeight, model.updateHeight);
        this.submitClaimWidgetModel.isAccountActivity = model.isAccountActivity;
        this.submitClaimWidgetModel.displayHeader(false);
        this.submitClaimWidgetModel.initialize();
        this.submitClaimWidgetModel.setClose(function () {
            if (model.anyManualClaimsEnabledOnly()) {
                window.location.href = model.closeUrl;
            } else {
                model.currentStepModel(null);
            }
            model.updateHeight();
        });
    }

    ko.components.register('claims-submit', {
        template: { element: 'template-claims-submit' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new $.cdh.models.SubmitClaimModel(updateHeight);
            }
        }
    });

    $.extend(true, $, {
        cdh: {
            models: {
                SubmitClaimModel: submitClaimModel
            }
        }
    });

})(jQuery, ko);