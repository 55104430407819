(function ($, ko) {
    var flexminderModel = function (updateHeight, template) {
        this.templateExtractor = new $.cdh.services.templateExtractor(template);
        this.IframeUrl = $.cdh.ssoAdfsEndpoint + "/idpinitiatedsignon.aspx?loginToRp=Urn:FlexMinderEmbed:SSO";
    };
    
    ko.components.register('link-your-accounts', {
       template: { element: 'template-link-your-accounts' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                setTimeout(function()  {
                    $(componentInfo.element).find('.loading-box').remove();
                }, 3000);
                return new flexminderModel(updateHeight, params.template);
            }
        }
    });

})(jQuery, ko);