import template from "./account-activity-request-reimbursement.html"

(function ($, ko) {
    ko.components.register('account-activity-request-reimbursement', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityRequestReimbursement(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityRequestReimbursement(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.isMobile = params.isMobile || false;
        self.isDirectRequest = ko.observable(false);
        self.anyHsaOnly = ko.observable(false);
        self.anyNonHsaOnly = ko.observable(false);

        self.popupModel = {
            ItemId: model.ItemId,
            EOBExtra: {
                ExternalClaimId: model.ClaimId,
                ExpenseKeys: model.ExpenseKeys
            },
            EOBType: model.EOBType,
            OriginCde: model.OriginCde,
            ServiceStartDate: model.ServiceStartDate,
            ExpenseKey: model.ExpenseKey,
            Amount: model.Amount
        };
        
        self.isEnabledPortal = $.cdh.tpa.useExpensePortal;
        self.goToPortal = () => {
            let portalParam = '';
            if (model.IsFxClaim) {
                portalParam = `fxClaimId=${model.ClaimId}`;
            } else if (model.IsTcClaim) {
                portalParam = `tcClaimId=${model.ClaimId}`;
            } else if (model.IsExpense) {
                portalParam = `expenseKey=${model.ExpenseKey}&originCde=${model.OriginCde}`;
            }

            document.location.href = $.cdh.routes.predefined.claims.portal + '?' + portalParam;
        };

        if(params.accountsLoaded()) {
            setBuilder();
        } else {
            params.accountsLoaded.subscribe(setBuilder);
        }
        
        function setBuilder() {
            setAccountsInfo();
            
            if (model.IsFxClaim) {
                self.builder = function () {
                    return new $.cdh.models.SubmitClaimWidgetModel(params.reloadListCallback, $.noop, self.popupModel);
                };
                self.template = function () {
                    return 'template-claim-widget-view';
                };

            } else if (!self.isDirectRequest()) {
                self.builder = function () {
                    return new $.cdh.models.RequestReimbursementType(params.reloadListCallback, $.noop, self.popupModel, true);
                };
                self.template = function () {
                    return 'template-claims-request-reimbursement-type';
                };

            } else {
                if (self.anyHsaOnly()) {
                    self.builder = function () {
                        return new $.cdh.models.PaymentsAddPopup(0, params.reloadListCallback, self.popupModel, $.noop, true);
                    };
                    self.template = function () {
                        return 'template-payments-add-popup';
                    };
                } else if (self.anyNonHsaOnly()) {
                    self.builder = function () {
                        return new $.cdh.models.SubmitClaimWidgetModel(params.reloadListCallback, $.noop, self.popupModel);
                    };
                    self.template = function () {
                        return 'template-claim-widget-view';
                    };
                }
            }
        }

        function setAccountsInfo() {
            let anyHsaCurrent = $.cdh.accounts.anyHsaCurrent();
            let anyNonHsaCurrent = $.cdh.accounts.anyNonHsaCurrent();

            self.anyHsaOnly(anyHsaCurrent && !anyNonHsaCurrent);
            self.anyNonHsaOnly(!anyHsaCurrent && anyNonHsaCurrent);
            self.isDirectRequest(self.anyHsaOnly() || self.anyNonHsaOnly());
        }
        
        setBuilder();
    }

})(jQuery, ko);