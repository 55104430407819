import {PageUrlModes} from "../../../Components/Common/pageUrlModes";

(function($) {
    $.extend(true, $, {
        cdh: {
            models : {
                editEmailModel: function (email, onUpdateCallback) {
                    let openParam = $.cdh.helper.urlParam('openParam');
                    this.forceAddEmail = openParam === 'addEmail';
                    this.existOpportunityBlock = ko.observable(this.forceAddEmail && PageUrlModes.isOpportunity());
                    this.firstLoading = true;
                    
                    this.newEmail = ko.observable('').extend({ email: true, required: true });

                    this.preRender = function() {
                        if(this.existOpportunityBlock) {
                            PageUrlModes.removeMode();
                            if (!this.firstLoading) {
                                this.existOpportunityBlock(false);
                            }
                        }
                        this.originalEmail = ko.observable(ko.unwrap(email));
                        this.emailValidation = new $.cdh.validation(this.newEmail);
                        this.updateRequest = new $.cdh.post.profile.updateEmail();
                        this.firstLoading = false;
                    }.bind(this);
                   

                    this.clickCancel = function () {
                        this.close();
                    }

                    this.newEmail.subscribe(function() {
                        var email = this.newEmail();
                        this.newEmail(email.trim());
                    }, this);

                    this.clickSubmit = function () {
                        if (this.newEmail) {
                            this.emailValidation.activate();
                            if (!this.emailValidation.hasErrors()) {
                                this.updateRequest.load({ email: this.newEmail() })
                                    .done(function () {
                                        this.close();
                                        if ($.isFunction(onUpdateCallback)) {
                                            onUpdateCallback(this.newEmail());
                                        }

                                        this.originalEmail(this.newEmail());
                                        this.newEmail('');
                                    }.bind(this));
                            }
                        }
                    }.bind(this);

                    this.setClose = function (closeFunc) {
                        this.close = closeFunc;
                    }.bind(this);
                }
            }
        }
    });
}(jQuery))