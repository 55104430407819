import template from "./notification-center-filter.html";

ko.components.register('notification-center-filter', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new NotificationCenterFilter(params);
        }
    }
});

export class NotificationCenterFilter {
    constructor(params) {
        this.selectedType = params.model.selectedType;
    }
}