import template from "./accounts-year-filter.html"

(function ($, ko) {
    ko.components.register('accounts-year-filter', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountsYearModel();
            }
        }
    });
    
	function AccountsYearModel() {
        const self = this;
		self.selectedYear = ko.observable().syncWith($.cdh.topics.accounts.yearFilter, true);
        self.open = ko.observable(false);

        let isTouchScreen = ('ontouchend' in window);

        self.onParentClick = function(data, event) {
            event.stopPropagation();
			self.open(!this.open());
        };

        $(window).on('click ontouchend', function() {
            self.open(false);
        });

        self.onParentMouseOver = function () {
            if (isTouchScreen) {
				return;
            }
            $(window).keydown(function (e) {
                if (e.keyCode === 27) {                 
                    self.open(false);
                }
            });
			self.open(true);
        };

        self.onParentMouseOut = function() {
            if (isTouchScreen) {
				return;
			}
			self.open(false);
        };

        self.optionKeyDown = function(data, e) {
            function getUl(elem) {
                return elem.closest('ul');
            }
            function getPrevLi(btn) {
                return btn.closest('li').prev('li');
            }
            function getNextLi(btn) {
                return btn.closest('li').next('li');
            }
            function getFirstLi(btn) {
                return getUl(btn).find('li').first();
            }
            function getLastLi(btn) {
                return getUl(btn).find('li').last();
            }
            function focusBtn(li) {
                li.find('.choice-block_drop-btn').focus();
            }

            if (e.keyCode === 38) {
                const btn = $(e.target);
                const prevLi = getPrevLi(btn);
                if (prevLi.length) {
                    focusBtn(prevLi);
                    return false;
                }
                const lastLi = getLastLi(btn);
                if (lastLi.length) {
                    focusBtn(lastLi);
                }
                return false;
            }
            if (e.keyCode === 40) {
                const btn = $(e.target);
                const nextLi = getNextLi(btn);
                if (nextLi.length) {
                    focusBtn(nextLi);
                    return false;
                }
                const firstLi = getFirstLi(btn);
                if (firstLi.length) {
                    focusBtn(firstLi);
                }
                return false;
            }
            // if (e.keyCode === 27 || (e.keyCode === 9 && $(e.target).hasClass('choice-block_drop-btn--last'))) {
            if (e.keyCode === 27) {
                self.open(false);
                return false;
            }
            if (e.keyCode === 9) {
                self.open(false);
            }
            if (e.key === " " || e.keyCode === 13 || e.keyCode === 32) {
                e.preventDefault();
                e.target.click()
            }
            return true;
        };

        self.valueKeyDown = function(data, e) {
            if (e.keyCode === 9) {
                return true;
            }
            if (e.keyCode === 32 || e.keyCode === 13) {
                self.open(true);
                $(e.target).closest('.choice-block').find('.choice-block_drop-btn').first().focus();
            }
        };
	}
})(jQuery, ko);