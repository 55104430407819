import template from "./dependent-add-edit.html"

(function ($, ko) {
    
    // Added to support Enrollment modules
    ko.components.register('dependent-add-edit-enrollment', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {

                if (params.closeFunc) {
                    params.setClose(params.closeFunc);
                }

                return params;
            }
        },
        synchronous: true
    });

    $.extend(true, $, {
        cdh: {
            models: {
                addEditEnrollmentDependentModel: function (model, updateHeight, isEdit, isPlanCompleted, template, onSuccessCallback) {

                    var hasPermissionsToEditDemographic = true;
                    if (isEdit)
                        hasPermissionsToEditDemographic = !isPlanCompleted || $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateProfileDemographicInformation);

                    AddEditDependentModel.call(this,
                        model,
                        updateHeight,
                        isEdit,
                        hasPermissionsToEditDemographic,
                        enrollmentTemplate(template),
                        function () {
                            onSuccessCallback();
                            window.setTimeout(function () {
                                $.cdh.helper.alert($.cdh.languageConstant.getConstant("messages.data-saved-success"), $.noop, $.noop, $.cdh.languageConstant.getConstant("messages.success"));
                            }, 0);
                        });
                    this.usePrimaryAddressCopyCheckbox = false;
                }
            }
        }
    });

    function AddEditDependentModel(model, updateHeight, isEdit, hasPermissionsToEditDemographic, template, onSuccessCallback) {
        this.isEdit = isEdit;
        this.hasPermissionsToEditDemographic = hasPermissionsToEditDemographic;
        this.onSuccessCallback = onSuccessCallback;
        this.profile = ko.observable(model.profile);
        var profileVM = ko.pureComputed(function () {
            return this.profile().viewModel();
        }, this);
        
        this.usePrimaryAddress = ko.observable(false);
        this.template = template;
        this.requireDOBandSsn = false;

        this.dependentFreeIdRequest = new $.cdh.get.profile.dependentFreeId();
        this.dependentLabel = this.template.dependentLabel.Value;

        this.useAutoGeneratedId = function () {
            if (!this.isEdit) {
                this.dependentFreeIdRequest.load().done(function (data) {
                    this.dependent.DependentId(data.Id);
                }.bind(this));
            }
        }.bind(this);

        this.dependentFreeIdRequest.state.subscribe(function () { updateHeight(); });

        this.preRender = function () {
            this.states = model.profile.vm.states.data();
            this.countries = model.profile.vm.countries.data();

            this.dependent = {};
            this.dependent.origin = {};
            this.dependentSsnView = ko.observable();

            this.dependent.FirstName = defaultIfUndefined(model.data.FirstName, '').extend({ required: true, dependentName: true });
            this.dependent.LastName = defaultIfUndefined(model.data.LastName, '').extend({ required: true, dependentName: true });
            this.dependent.Initial = defaultIfUndefined(model.details.Initial, '').extend({ required: false, alphanumeric: true, maxLength: 1 });
            this.dependent.DependentId = defaultIfUndefined(model.data.DependentId, '');
            this.dependent.EmployeeRelation = defaultIfUndefined(model.data.EmployeeRelation, '').extend({ required: this.template.relationship.isRequired });
            this.dependent.Gender = defaultIfUndefined(model.details.Gender, '').extend({ required: this.template.gender.isRequired });
            this.dependent.FullTimeStudent = defaultIfUndefined(model.details.FullTimeStudent, false);

            this.dependent.Phone = defaultIfUndefined(model.details.Phone, '').extend({ phoneUS: true });
            this.dependent.DateOfBirth = defaultIfUndefined(model.details.DateOfBirth, '').extend({ required: this.template.birthday.isRequired });
            this.dependent.Ssn = defaultIfUndefined(model.details.Ssn, '').extend({ required: this.template.ssn.isRequired, ssn: true });
            this.dependentSsnView(model.details.Ssn);
            this.dependent.Address1 = defaultIfUndefined(model.details.Address1, '').extend({ required: true, specialAddress: true });
            this.dependent.Address2 = defaultIfUndefined(model.details.Address2, '').extend({ specialAddress: true });

            this.dependent.City = defaultIfUndefined(model.details.City, '').extend({ required: true, extendedSymbolSet: true });
            this.dependent.State = defaultIfUndefined(model.details.State).extend({ required: true });
            this.dependent.Zip = defaultIfUndefined(model.details.Zip, '').extend({ required: true, zipCode: true });
            this.dependent.Country = defaultIfUndefined(model.details.Country, '').extend({ required: true });

            //copy to origin values
            this.dependent.origin.Address1 = ko.observable(this.dependent.Address1());
            this.dependent.origin.Address2 = ko.observable(this.dependent.Address2());
            this.dependent.origin.City = ko.observable(this.dependent.City());
            this.dependent.origin.State = ko.observable(this.dependent.State());
            this.dependent.origin.Zip = ko.observable(this.dependent.Zip());
            this.dependent.origin.Country = ko.observable(this.dependent.Country());
            this.dependent.origin.Ssn = ko.observable(this.dependent.Ssn());

            this.maxDate = ko.observable(moment().subtract(1, "days"));
            this.usePrimaryAddress(!this.isDependentType && this.isAddressesSame());
            this.dependent.IssueDependentCard = ko.observable(false);

            this.addOrEditRequest = this.isEdit
                ? new $.cdh.post.profile.updateDependent()
                : new $.cdh.post.profile.addDependent();

            this.validation = new $.cdh.validation(this.dependent);
            
            this.useAutoGeneratedId();
            
            updateHeight();
        }.bind(this);
        
        function defaultIfUndefined(value, defaultValue) {
            return value != null ? ko.observable(value) : ko.observable(defaultValue);
        }

        this.getLabel = function (templateField, defaultLabel) {
            if (!templateField.label) return defaultLabel;
            return templateField.label.Value || defaultLabel;
        };

        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.clickCancel = function () {
            this.close();
        }.bind(this);

        this.clickNextOrSubmit = function () {
            this.validation.activate();

            if (!this.validation.hasErrors()) {
                if (this.addOrEditRequest.isLoading())
                    return;
                
                this.clickSubmit();
            }
            updateHeight();
        }.bind(this);

        this.canSubmit = ko.pureComputed(function () {
            return !this.addOrEditRequest.isLoading();
        }, this);

        this.clickSubmit = function () {
            this.addOrEditRequest.load({ dependent: this.dependent })
                .done(function (claimant) {
                    if ($.isFunction(this.onSuccessCallback)) {
                        this.onSuccessCallback(this.dependent);
                    }
                    this.close();
                }.bind(this));
            updateHeight();
        }.bind(this);

        this.isAddressesSame = function () {
            return this.dependent.Address1() === profileVM().Address1 &&
                this.dependent.Address2() === profileVM().Address2 &&
                this.dependent.City() === profileVM().City &&
                this.dependent.State() === profileVM().State &&
                this.dependent.Zip() === profileVM().Zip &&
                this.dependent.Country() === profileVM().Country;
        };

        this.copyPrimaryAddress = function () {
            this.dependent.Address1(profileVM().Address1);
            this.dependent.Address2(profileVM().Address2);
            this.dependent.City(profileVM().City);
            this.dependent.State(profileVM().State);
            this.dependent.Zip(profileVM().Zip);
            this.dependent.Country(profileVM().Country);
        }.bind(this);

        this.copyAddress = function () {
            if (this.usePrimaryAddress()) {
                this.copyPrimaryAddress();
            } else {
                this.dependent.Address1(this.dependent.origin.Address1());
                this.dependent.Address2(this.dependent.origin.Address2());
                this.dependent.City(this.dependent.origin.City());
                this.dependent.State(this.dependent.origin.State());
                this.dependent.Zip(this.dependent.origin.Zip());
                this.dependent.Country(this.dependent.origin.Country());
            }
            return true;
        }.bind(this);

        this.maskSsn = function () {
            this.dependent.Ssn(this.dependentSsnView());
            if (this.dependentSsnView()) {
                var maskedSsn = this.dependentSsnView().replace(/\d(?=\d{4})/g, "*");
                this.dependentSsnView(maskedSsn);
            }
        }.bind(this);

        this.unMaskSsn = function () {
            this.dependentSsnView(this.dependent.Ssn());
        }.bind(this);

        this.afterRender = function () {
            if ($.cdh.isMobile) {
                $.cdh.helper.scrollPageToTop();
            }
        }.bind(this);

        this.deleteOptionUnknown = function (option, item) {
            if (!item || !option)
                return;

            if (item.key.toLowerCase() === $.cdh.enums.employeeRelation.unknown.toString()){
                if(!this.dependent || this.dependent.EmployeeRelation() !== $.cdh.enums.employeeRelation.unknown){
                    $(option).remove()
                }
            }
            if (item.key.toLowerCase() === $.cdh.enums.employeeRelation.estate.toString()) {
                $(option).remove(); 
            }

        }.bind(this);

        setTimeout(this.afterRender, 0);
    };

    var fieldDefaultSettings = function (isRequired) {
        this.isVisible = true;
        this.isReadonly = false;
        this.isRequired = isRequired;
    };

    var enrollmentField = function (templateField, labelField) {
        this.isVisible = !templateField.IsHidden;
        this.isReadonly = templateField.IsReadonly;
        this.isRequired = templateField.IsRequired;
        this.label = labelField;
    };

    var enrollmentTemplate = function (template) {
        var tmpl = template.Demographics;
        return {
            birthday: new enrollmentField(tmpl.DepBirthDate, tmpl.DepBirthDateLabel),
            gender: new enrollmentField(tmpl.DepGender, tmpl.DepGenderLabel),
            fullTimeStudent: new enrollmentField(tmpl.DepFullTimeStudent, tmpl.DepFullTimeStudentLabel),
            ssn: new enrollmentField(tmpl.DepSsn, tmpl.DepSsnLabel),
            relationship: new fieldDefaultSettings(true),
            dependentLabel: tmpl.DepFieldLabel
        };
    };

})(jQuery, ko);