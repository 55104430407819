import template from './provider-item.html';


(function ($, ko) {

    function ProvidersItem(params, updateHeight) {

        this.provider = params.model().model;
        this.pageNo = params.model().pageNo || $.cdh.helper.urlParam('pageNo');
        this.selectedSpecilistName = ko.observable($.cdh.helper.urlParam('speciality'));
        this.selectedProcedureName = ko.observable($.cdh.helper.urlParam('procedure'));
        this.selectedProcedureUUid = ko.observable($.cdh.helper.urlParam('procedureId'));
        this.filteredRating = $.cdh.helper.urlParam('filteredRating');
        this.filteredCost = $.cdh.helper.urlParam('filteredCost');
        this.filteredDistance = $.cdh.helper.urlParam('filteredDistance');
        this.costIndex = ko.observable();
        this.provider.RatingsAvg = Math.ceil((this.provider.RatingsAvg / 2));
        var self = this;
        if (this.selectedProcedureUUid()!=null) {
            this.ProcedureCost = ko.pureComputed(() => {
                let index = '';
                $.each(this.provider.Procedures, function (value, element) {
                    if (element.UUID == self.selectedProcedureUUid() && element.Cost != null)
                         index = element.Cost.CostIndex;
                });
                return Math.ceil(index / 2);
            });
            
        }

        
        this.names = ko.pureComputed(
            () => {
                let Name = $.cdh.languageConstant.getConstant("find-care.primary-provider");
                if (this.selectedSpecilistName()) {
                    Name = this.selectedSpecilistName();
                }
                if (this.selectedProcedureName()) {
                    Name = this.selectedProcedureName();
                }
                return Name;
            }
        );

        
        this.onClick = function () {
            localStorage.setItem('provider-details', JSON.stringify(this.provider));
            let providerDetailUrl = $.cdh.routes.predefined.findcare.providerDetails + '?pageNo=' + this.pageNo;
            let sepcialityId = $.cdh.helper.urlParam('specialityId');
            let procedureId = $.cdh.helper.urlParam('procedureId');
            let isFilterAvailable = false;
            if (this.filteredCost || this.filteredDistance || this.filteredRating) {
                isFilterAvailable = true;
                providerDetailUrl += '&filterAvailable=' + isFilterAvailable;
            }
            if (this.filteredCost) {
                providerDetailUrl += '&filteredCost=' + this.filteredCost;
            }
            if (this.filteredRating) {
                providerDetailUrl += '&filteredRating=' + this.filteredRating;
            }
            if (this.filteredDistance) {
                providerDetailUrl += '&filteredDistance=' + this.filteredDistance;
            }
            if (sepcialityId?.length > 0) {
                providerDetailUrl += '&specialityId=' + sepcialityId + '&speciality=' + this.selectedSpecilistName();
            }

            if (procedureId?.length > 0) {
                providerDetailUrl += '&procedureId=' + procedureId + '&procedure=' + this.selectedProcedureName();
            }

            window.location.href = providerDetailUrl;
        }
        updateHeight();

    }
    ko.components.register('providers-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ProvidersItem(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
})(jQuery, ko);