import template from "./account-activity-item-header.html"

(function ($, ko) {
    ko.components.register('account-activity-item-header', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityItemHeader(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityItemHeader(params, updateHeight) {
        const self = this;
        let model = params.model;
        let isValueSet = $.cdh.helper.isValueSet;
        
        self.model = model;
        self.MultipleMarking = params.MultipleMarking;
        self.accountsLoaded = params.accountsLoaded;
        self.reloadListCallback = params.reloadListCallback;
        self.color = params.color;

        self.simplifiedStatus = model.SimplifiedStatus;
        self.simplifiedStatusName = model.SimplifiedStatusName;
        
        self.typeName = model.TypeInfo.Name;
        
        self.isCardTransaction = model.IsCardTransaction;
        self.isFxClaim = model.IsFxClaim;
        self.isTcClaim = model.IsTcClaim;
        self.isMemberClaim = model.IsMemberClaim;
        self.isManualClaim = model.IsManualClaim;
        self.isExpense = model.IsExpense;
        self.isHsa = model.IsHsa;

        self.date = model.IsFxClaim ? model.SettlementDate : model.FullDate;
        self.amount = model.FullAmount;
        
        self.customDescription = model.CustomDescription;
        self.description = isValueSet(model.ReimbursementDetails) && model.ReimbursementDetails.length > 0 ? model.ReimbursementDetails[0].CustomDescription : null;
        self.isExistDescription = isValueSet(self.description);
        self.status = model.FullStatus;

        self.MarkAsPaidOptionAvailable = ko.pureComputed(() => self.canMarkAsPaid && !self.MultipleMarking);
        
        if(self.isCardTransaction) {
            self.merchantName = model.CardTransactionDetails.MerchantName;
        } else {
            self.provider = model.RenderingProvider;
            self.isExistProvider = isValueSet(self.provider);
            self.transactionDate = model.ServiceStartDate;
            self.isExistTransactionDate = isValueSet(self.transactionDate);
        }
        
        self.canUploadReceipt = model.CanUploadReceipt;
        self.canRequestReimbursement = $.cdh.employee.canRequestReimbursement() && model.CanRequestReimbursement;
        self.canMarkAsPaid = model.CanMarkAsPaid;
        
        self.isManualClaim = model.IsManualClaim;
        self.statuscode = model.StatusCode;
        self.type = model.TypeCode;
        self.clickMarkasIneligible = ko.observable(false);
        if ($.cdh.employee.hasAccessToMarkAsInelgible() && this.type == 2) {
            if (this.statuscode == 11 || this.statuscode == 12)
            {
                self.clickMarkasIneligible(true);
            }
        }
        
        updateHeight();
    }

})(jQuery, ko);