import {AccountsService} from "../../Http/Accounts/AccountsService";
import template from "./accounts-summary.html"

ko.components.register('accounts-summary', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AccountsSummaryModel(widgetInfo.updateHeight);
        }
    }
});

function AccountsSummaryModel (updateHeight) {
    const self = this;

    self.accountsService = new AccountsService();
    self.accounts = ko.observableArray();

    self.isEmpty = ko.pureComputed(function () {
        return self.accounts().length === 0;
    });
    
    self.submitClaimBuilder = function () {
        return new $.cdh.models.SubmitClaimWidgetModel(updateHeight, updateHeight);
    };

    self.selectedPlanYear = ko.observable().syncWith($.cdh.topics.accounts.yearFilter);
    
    self.selectedPlanYear.subscribe(function(value) {
        self.accountsService.getListAsync(value).then(accounts => {
            accounts.forEach(account => account.isHsa = account.AccountType === $.cdh.enums.accountType.hsa);
            accounts.sort(function (a, b) {
                if (a.ProductPartnerAcctStatus != 1 && a.ProductPartnerAcctStatus != 4 && b.ProductPartnerAcctStatus != 1 && b.ProductPartnerAcctStatus != 4) {
                    if (a.Priority < b.Priority)
                        return -1;
                    else
                        return 1;
                }
                else if (a.ProductPartnerAcctStatus == 1 || a.ProductPartnerAcctStatus == 4 && (b.ProductPartnerAcctStatus != 1 && b.ProductPartnerAcctStatus != 4)) {
                    return 1;
                }
                else if (b.ProductPartnerAcctStatus == 1 || b.ProductPartnerAcctStatus == 4 && (a.ProductPartnerAcctStatus != 1 && a.ProductPartnerAcctStatus != 4)) {
                    return -1;
                }
                else if (a.status == b.status) {
                    return a.priority - b.priority;
                }
                else {
                    if (a.ProductPartnerAcctStatus < b.ProductPartnerAcctStatus)
                        return -1;
                    else
                        return 1;
                }
            });
            
            accounts.forEach(account => account.productPartnerStatusValue = getproductPartnerStatus(account.ProductPartnerAcctStatus));

            self.accounts(accounts);
            updateHeight();
        });
    });
    
    self.selectedPlanYear($.cdh.enums.planYear.current);

    function getproductPartnerStatus(productstatus) {
        switch (productstatus) {
            case 0: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Active");
            case 3: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Dormant");
            case 4: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Closed");
            case 5:
            case 6:
            case 7:
            case 10: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Frozen");
            case 11: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Escheated");
            default: return '';
        }
    }
}