import {AccountsService} from "../../../Components/Http/Accounts/AccountsService";
import {PageUrlModes} from "../../../Components/Common/pageUrlModes";

(function($) {

    var profileReimbursementMethodEditPopup = function (profile, depositAccountOnly, updateHeight, successCallback) {
        let reimbursementMethodParam = parseInt($.cdh.helper.urlParam('reimbursementMethod'));
        this.existOpportunityBlock = ko.observable(PageUrlModes.isOpportunity());
        if(this.existOpportunityBlock) {
            PageUrlModes.removeMode();
        }
        this.allowEditReimbursementMethod = ko.observable();
        this.firstLoading = true;
        
        this.isProfile = false;
        
        this.updateHeight = updateHeight;
        this.successCallback = successCallback;
        this.profile = profile;
        if (this.profile.viewModel === undefined) {
            this.profile.viewModel = ko.observable(profile);
        }

        this.profileReimbursementMethod = profile.viewModel().ReimbursementMethod;

        this.ReimbursementMethodList = ko.observableArray();
        this.depositAccountOnly = depositAccountOnly;
        this.editBankAccount = false;
        this.showSaveButton = ko.observable(false);
        this.updateUserReimbursementMethod = ko.observable(true);
        this.bankAccountValidationState = {
            status: ko.observable($.cdh.enums.bankAccountValidationStatus.None),
            statusDescription: ko.observable(''),
            visible: ko.observable(false),
            required: ko.observable(false)
        };
        
        this.bankAccountValidationState.statusTooltipVisible = ko.pureComputed(function () {
            return this.bankAccountValidationState.status() === $.cdh.enums.bankAccountValidationStatus.pendingValidation ||
                this.bankAccountValidationState.status() === $.cdh.enums.bankAccountValidationStatus.validationRequired
        }, this);
        
        this.bankAccountSummary = ko.observable(null);
        this.bankAccountValidation = ko.observable(null);
        this.accountsService = new AccountsService();
        this.anyHsaCurrent = ko.observable();
        this.accountsService.anyHsaCurrentAsync().then(data => this.anyHsaCurrent(data));
        
        this.stateEnum = { oobStep: 10, infoStep: 20  };
        this.displayState = ko.observable(this.stateEnum.oobStep);
        
        this.oobCompleted = function () {
            this.displayState(this.stateEnum.infoStep);
        }.bind(this);

        this.oobCancelled = function () {
            this.clickCancel();
        }.bind(this);

        this.titleText = ko.pureComputed(function () {
            return $.cdh.languageConstant.getConstant('profile.reimbursement-method');
        }, this);

        this.maskSecure = maskSecure;
        
        this.preRender = function() {

            if(this.existOpportunityBlock && !this.firstLoading) {
                this.existOpportunityBlock(false);
            }
            
            this.updateReimbursementMethod = new $.cdh.post.profile.updateReimbursementMethodInfo();
            this.getUserInfo = new $.cdh.get.user.getInfo();
            this.getUserInfo.load().done(result => this.allowEditReimbursementMethod(result.AllowParticipantTransaction));

            this.setBankAccountValidationState(profile.viewModel());

            this.method = {
                ReimbursementMethod: ko.observable(profile.viewModel().ReimbursementMethod),
                Bank: ko.observable(profile.viewModel().Bank),
                BankAccountNumber: ko.observable(profile.viewModel().BankAccountNumber),
                ReBankAccountNumber: ko.observable(''),
                BankRoutingNumber: ko.observable(profile.viewModel().BankRoutingNumber),
                ReBankRoutingNumber: ko.observable(''),
                BankAccountTypeCode: ko.observable(profile.viewModel().BankAccountTypeCode),
                Agree: ko.observable(false),
                Editable: ko.observable(this.depositAccountOnly && this.editBankAccount)
            };
            
            var reimbursementMethodList = profile.viewModel().ReimbursementMethodList;
                
            if (depositAccountOnly) {
                this.ReimbursementMethodList($.grep(reimbursementMethodList,
                    function(r) {
                        return r.ReimbursementMethod == $.cdh.enums.reimbursementMethod.directDeposit;
                    }));
                this.method.ReimbursementMethod($.cdh.enums.reimbursementMethod.directDeposit);
            } else {
                this.ReimbursementMethodList(reimbursementMethodList);
            }
            
            if (this.ReimbursementMethodList().some(r => r.ReimbursementMethod === reimbursementMethodParam)) {
                this.method.ReimbursementMethod(reimbursementMethodParam);
            }

            addValidations(this.method);

            this.validation = new $.cdh.validation(this.method);
            this.validationReimbursement = new $.cdh.validation(this.method.ReimbursementMethod);

            this.method.isVisibleEdit = ko.computed(function() {
                return ko.unwrap(this.method.ReimbursementMethod) == $.cdh.enums.reimbursementMethod.directDeposit;
            }.bind(this));

            this.method.canEdit = ko.computed(function() {
                return $.cdh.employee.allowedToUpdateDirectDepositInformation();
            }.bind(this));

            this.canSubmit = ko.pureComputed(function() {
                var directDeposit = ko.unwrap(this.method.ReimbursementMethod) ==
                    $.cdh.enums.reimbursementMethod.directDeposit;
                if (directDeposit) {
                    return !this.updateReimbursementMethod.isLoading() && !this.validation.hasErrors();
                } else {
                    return !this.updateReimbursementMethod.isLoading() && !this.validationReimbursement.hasErrors();
                }
            }.bind(this));

            this.firstLoading = false;
            updateHeight();

        }.bind(this);

        this.afterRender = function () {
            $.cdh.helper.initTabsNavigation(document.getElementById('reim_tabs'), {
                changeTabindex: false,
                changeAriaSelected: false,
            });
        }.bind(this);

        this.setBankAccountValidationState = function(profileVm) {
            this.bankAccountValidationState.status(profileVm.BankAccountValidationStatus);
            this.bankAccountValidationState.statusDescription(profileVm.BankAccountValidationStatusDescription);
            this.bankAccountValidationState.visible(
                profileVm.BankAccountValidationStatus == $.cdh.enums.bankAccountValidationStatus.pendingValidation ||
                profileVm.BankAccountValidationStatus == $.cdh.enums.bankAccountValidationStatus.validationRequired ||
                profileVm.BankAccountValidationStatus == $.cdh.enums.bankAccountValidationStatus.validationFailed
            ),
            this.bankAccountValidationState.required(
                profileVm.BankAccountValidationStatus == $.cdh.enums.bankAccountValidationStatus.validationRequired                
            );
        }.bind(this);
        
        this.enabledValidationInSubmit = ko.pureComputed(function () {
            return (this.method && this.method.Editable())
                || (this.bankAccountValidationState.status() !== $.cdh.enums.bankAccountValidationStatus.pendingValidation &&
                    this.bankAccountValidationState.status() !== $.cdh.enums.bankAccountValidationStatus.active);
        }, this);
        
        this.submitText = ko.pureComputed(function () {
            if (
                this.enabledValidationInSubmit()
                || this.method.ReimbursementMethod() == $.cdh.enums.reimbursementMethod.check
                || this.method.ReimbursementMethod() == $.cdh.enums.reimbursementMethod.connectedCash || this.showSaveButton()
            ) {
                return this.updateReimbursementMethod.isLoading() ? $.cdh.languageConstant.getConstant('button.saving') : $.cdh.languageConstant.getConstant('button.save');
            } else {
                return this.updateReimbursementMethod.isLoading() ? $.cdh.languageConstant.getConstant('button.closing') : $.cdh.languageConstant.getConstant('button.close');
            }
        }, this);

        this.validationActivated = ko.observable(false);
        this.validationReimbursementActivated = ko.observable(false);

        this.resetMethod = function() {
            if (this.updateReimbursementMethod.error()) {
                this.updateReimbursementMethod.error(null);
            }

            this.method.Bank(profile.viewModel().Bank);
            this.method.BankAccountNumber(profile.viewModel().BankAccountNumber);
            this.method.ReBankAccountNumber('');
            this.method.BankRoutingNumber(profile.viewModel().BankRoutingNumber);
            this.method.ReBankRoutingNumber('');
            this.method.BankAccountTypeCode(profile.viewModel().BankAccountTypeCode);
            this.method.Editable(this.depositAccountOnly && this.editBankAccount);
        }.bind(this);

        this.onChangeReimbursementMethod = function(data) {
            this.resetMethod();
            if (data.ReimbursementMethod == $.cdh.enums.reimbursementMethod.directDeposit) {
                this.validationReimbursement.activated(false);
                this.showSaveButton(data.ReimbursementMethod != profile.viewModel().ReimbursementMethod);
                if (this.validationActivated()) {
                    this.validation.activate();
                }
            } else {
                this.validation.activated(false);
                if (this.validationReimbursementActivated()) {
                    this.validationReimbursement.activate();
                }
            }
            this.method.ReimbursementMethod(data.ReimbursementMethod);
            updateHeight();
        }.bind(this);

        this.setClose = function(closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.diactivateValidation = function() {
            this.validationActivated(false);
            this.validationReimbursementActivated = ko.observable(false);

            this.validation.activated(false);
            this.validationReimbursement.activated(false);
        }.bind(this);

        this.clickEdit = function() {
            this.method.Editable(true);
            this.method.BankRoutingNumber('');
            this.method.BankAccountNumber('');
        }.bind(this);

        this.clickCancel = function() {
            this.diactivateValidation();
            this.resetMethod();
            this.close();
        }.bind(this);
        
        this.ValidateRequiredFields = function(){
            return this.method.Bank() && this.method.BankAccountNumber() && this.method.BankRoutingNumber();
        }

        this.clickSubmit = function() {           
            
            if (!$.cdh.employee.allowedToUpdateDirectDepositInformation())
                return;

            if (this.method.ReimbursementMethod() == $.cdh.enums.reimbursementMethod.directDeposit) {
                if (!this.ValidateRequiredFields()) { 
                    this.validation.activate();
                }
                if (this.enabledValidationInSubmit() && this.method.Editable()) {
                    this.validation.activate();
                    this.validationActivated(true);
                }
            } else {
                this.validationReimbursement.activate();
                this.validationReimbursementActivated(true);
            }

            if (!(this.validation.hasErrors() || this.validationReimbursement.hasErrors())) {

                let bankAccountTypeCode = this.method.ReimbursementMethod() !== $.cdh.enums.reimbursementMethod.directDeposit || !this.method.Editable() 
                    ? this.profile.viewModel().BankAccountTypeCode : this.method.BankAccountTypeCode();
                
                var submitModel = {
                    ReimbursementMethod: this.method.ReimbursementMethod(),
                    Bank: this.method.Bank(),
                    BankAccountNumber: this.method.BankAccountNumber(),
                    BankRoutingNumber: this.method.BankRoutingNumber(),
                    BankAccountTypeCode: bankAccountTypeCode,
                    UpdateUserReimbursementMethod: this.updateUserReimbursementMethod()
                };

                if (this.method.ReimbursementMethod() == $.cdh.enums.reimbursementMethod.directDeposit) {
                    this.bankAccountSummary(new profileReimbursementMethodBankAccountSummary(this, submitModel));
                } else {
                    this.method.Editable(this.depositAccountOnly);
                    this.updateReimbursementMethod.load(submitModel)
                        .done(function (result) {
                            if (result) {
                                this.diactivateValidation();

                                this.profile.viewModel().ReimbursementMethod = this.method.ReimbursementMethod();
                                this.profile.viewModel().Bank = this.method.Bank();
                                this.profile.viewModel().BankAccountNumber = this.method.BankAccountNumber();
                                this.profile.viewModel().BankRoutingNumber = this.method.BankRoutingNumber();
                                this.profile.viewModel().BankAccountTypeCode = bankAccountTypeCode;
                                this.profile.viewModel(this.profile.viewModel());

                                if ($.isFunction(this.successCallback))
                                    this.successCallback(this.method.ReimbursementMethod());

                                this.close();
                            }
                        }.bind(this));
                }
            }
            else{
                $(window).scrollTop(0);
            }
        };

        this.clickValidate = function() {
            if (!this.bankAccountValidationState.required())
                return;

            this.setupBankAccountValidation();
        }.bind(this);

        this.setupBankAccountValidation = function () {
            this.bankAccountValidation(new $.cdh.models.ProfileReimbursementMethodBankAccountValidation(this, this.profile));
        }.bind(this);

        function addValidations(data) {
            data.ReimbursementMethod = ko.observable(ko.unwrap(data.ReimbursementMethod)).extend({
                required: true,
                min: 0,
                max: 6
            });
            data.Bank = ko.observable(ko.unwrap(data.Bank)).extend({
                required: true
            });
            data.BankAccountNumber = ko.observable(ko.unwrap(data.BankAccountNumber)).extend({
                required: true,
                betweenLength: { params: [5, 17], message: $.cdh.languageConstant.getConstant('messages.account-number-should-be-numeric') }
            });
            data.ReBankAccountNumber = ko.observable('').extend({
                required: true,
                equal: { params: data.BankAccountNumber, message: $.cdh.languageConstant.getConstant('messages.account-numbers-not-match') }
            });
            data.BankRoutingNumber = ko.observable(ko.unwrap(data.BankRoutingNumber)).extend({
                required: true,
                routingNumber: true
            });
            data.ReBankRoutingNumber = ko.observable('').extend({
                required: true,
                equal: { params: data.BankRoutingNumber, message: $.cdh.languageConstant.getConstant('messages.bank-routing-number-not-match') }
            });
            data.Agree = ko.observable(false).extend({
                checked: {}
            });
            return data;
        }        
    };

    var profileReimbursementMethodBankAccountSummary = function (model, submitModel) {

        this.submitModel = submitModel;
        this.model = model;

        this.displayValidationMessage = ko.computed(function() {
            return model.bankAccountValidationState.visible() && model.anyHsaCurrent();
        }, this);

        this.clickCancel = function () {
            model.updateReimbursementMethod.error('');
            model.bankAccountSummary(null);
        };

        this.clickOk = function () {
            model.updateReimbursementMethod.load(submitModel)
                .done(function (result) {
                    if (result) {
                        model.diactivateValidation();

                        model.profile.viewModel().ReimbursementMethod = model.method.ReimbursementMethod();
                        model.profile.viewModel().Bank = model.method.Bank();
                        model.profile.viewModel().BankAccountNumber = model.method.BankAccountNumber();
                        model.profile.viewModel().BankRoutingNumber = model.method.BankRoutingNumber();
                        model.profile.viewModel().BankAccountTypeCode = submitModel.BankAccountTypeCode;
                        model.profile.viewModel().BankAccountValidationStatus = result.BankAccountValidationStatus;
                        model.profile.viewModel().BankAccountValidationStatusDescription = result.BankAccountValidationStatusDescription;
                        model.profile.viewModel(model.profile.viewModel());
                        model.setBankAccountValidationState(model.profile.viewModel());

                        if ($.isFunction(model.successCallback))
                            model.successCallback(model.method.ReimbursementMethod());

                        model.resetMethod();
                        model.bankAccountSummary(null);
                        model.close();
                    }
                }.bind(this));
        }
    }.bind(this);

    var profileReimbursementMethodBankAccountValidation = function(model, profile) {

        this.validationModel = {
            amount1: ko.observable(0).extend({ required: true, min: -0.99, max: 0.99 }),
            amount2: ko.observable(0).extend({ required: true, min: -0.99, max: 0.99 }),
            amount3: ko.observable(0).extend({ required: true, min: -0.99, max: 0.99 })
        };

        this.amountValidation = new $.cdh.validation(this.validationModel);

        this.resultMessage = ko.observable('');
        this.sucessSubmit = ko.observable(false);

        this.submitRequest = new $.cdh.post.profile.validateBankAccount();

        this.clickSubmit = function() {

            this.resultMessage('');

            this.amountValidation.activate();
            if (this.amountValidation.hasErrors())
                return;

            if (!this.canBeSubmitted())
                return;

            this.submitRequest
                .load({
                    amount1: this.validationModel.amount1(),
                    amount2: this.validationModel.amount2(),
                    amount3: this.validationModel.amount3()
                })
                .done(function(response) {
                    this.resultMessage(response.Message);
                    this.sucessSubmit(response.SucessSubmit);
                    this.amountValidation.deactivate();

                    if (response.SucessSubmit) {
                        var employee = response.Employee;
                        profile.viewModel().BankAccountValidationStatus = employee.BankAccountValidationStatus;
                        profile.viewModel().BankAccountValidationStatusDescription =
                            employee.BankAccountValidationStatusDescription;
                        model.setBankAccountValidationState(profile.viewModel());
                    }
                }.bind(this));

        }.bind(this);

        this.canBeSubmitted = ko.pureComputed(function() {
            return !this.amountValidation.hasErrors();
        }.bind(this));

        this.clickOk = function() {
            this.clickCancel();
        }.bind(this);

        this.clickCancel = function() {
            model.bankAccountValidation(null);
        }.bind(this);
    };

    function maskSecure (text) {
        const value = ko.isObservable(text) ? text() : text;
        if (value === undefined || value.length < 4) 
            return value;
        
        var hiddenChars = 9-value.length;
        var newText = "";
        for (var i = 0; i < hiddenChars; ++i)
            newText = newText.concat("*")
            
        newText = newText.concat(value);
        return newText;
    }
    
    $.extend(true, $,
        {
            cdh: {
                models: {
                    ProfileReimbursementMethodEditPopup: profileReimbursementMethodEditPopup,
                    ProfileReimbursementMethodBankAccountSummary: profileReimbursementMethodBankAccountSummary,
                    ProfileReimbursementMethodBankAccountValidation: profileReimbursementMethodBankAccountValidation
                }
            }
        });
    }(jQuery));