import template from "./account-activity-receipts.html"

(function ($, ko) {
    ko.components.register('account-activity-receipts', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityReceipts(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityReceipts(params, updateHeight) {
        const self = this;

        self.hideTitle = params.hideTitle || false;
        self.receipts = params.model.Receipts;
        self.existReceipts = ko.pureComputed(function() {
            return self.receipts().length > 0;
        });

        self.getReceiptUrl = function (receipt) {
            return $.cdh.routes.receipts.image.get(receipt.FileKey);
        };

        self.onReceipt = function (r, event) {
            event.preventDefault();
            event.stopPropagation();
            if ($.cdh.isMobile) {
                $.cdh.helper.showFancyboxLoader();
                var url = self.getReceiptUrl(r);
                $.ajax(url)
                    .done(function (response, status, xhr) {
                        $.cdh.helper.hideFancyboxLoader();
                        var contentType = xhr.getResponseHeader('content-type');
                        if (contentType.search('pdf') !== -1) {
                            var urlText = '/Scripts/pdf-viewer-js/ViewerJS/#' + url;
                            $.cdh.helper.showPdfViwer(urlText);
                        }
                        if (contentType.search('image') !== -1) {
                            openFancybox(url, 'image');
                        }
                    });
            } else {
                window.open(self.getReceiptUrl(r));
            }
        };
        self.receiptItemKeyUp = function(data, e) {
            if (e.keyCode === 32) {
                self.onReceipt(data, e);
            }
        };
    }

})(jQuery, ko);