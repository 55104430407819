(function (module, ko, $) {
    

    module.MainViewModel = function (specifics, updateHeight) {
        var accountId = $.cdh.helper.urlParam("accountId");

        var self = this;

        self.navigator = new module.PageNavigator(specifics);
        self.navigator.viewChanged = function(name) {
            self.activeView(name);
        };

        self.documentsRequest = new specifics.requests.get.list(accountId);
        self.documentsRequest.state.subscribe(function () {
            if (!self.documentsRequest.isLoading()) {
                module.postbox.notifySubscribers(self.documentsRequest.data(), module.topics.documentsLoaded);
            }
        });

        self.activeView = ko.observable();
        
        self.listView = new specifics.ListViewModel(accountId, specifics, self.navigator, updateHeight);
        self.preferencesView = new module.PreferencesViewModel(accountId, specifics, self.navigator);

        // initialize
        self.navigator.initialize();
        self.documentsRequest.load();
    }

})(window.docDelivery, window.ko, window.jQuery);