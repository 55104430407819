import template from "./transactions-list.html"

(function ($, ko) {
    ko.components.register('transactions-list', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new TransactionsListModel(widgetInfo.updateHeight);
            }
        }
    });
    
    function TransactionsListModel(updateHeight) {
        const self = this;
        let accountIdParam = parseInt($.cdh.helper.urlParam('accountId'));
        
        self.getPageRequest = new $.cdh.get.transaction.getPage();
        self.transactions = ko.observableArray();
        self.transactionsYearsWithAccountsRequest = new $.cdh.get.transaction.getYearsWithAccounts();
        self.accountsReassociatedRequest = new $.cdh.get.accounts.listReassociated();
        
        self.aggregatedInitStatus = new $.cdh.models.aggregatedLoadStatus([
            self.accountsReassociatedRequest,
            self.transactionsYearsWithAccountsRequest
        ]);

        self.paginatorModel = {
            pageNo: ko.observable(),
            totalPages: ko.observable(),
            showAllAllowed: true,
            defaultPageSize: 10,
            currentPageSize: 10,
            showAll: function(callback) {},
            onChanged: function (currentPage, pageSize, callback) {
                if(pageSize) {
                    self.paginatorModel.currentPageSize = pageSize;
                }
                self.loadData(currentPage, callback);
            }
        };
        
        let printArea = null;
        self.selectedHistoricalAccount = ko.observable();
        self.statuses = {
            approved: 'Approved/Posted',
            pending: 'Pending/Processing',
            authorized: 'Authorized',
            denied: 'Denied'
        };
        
        self.filterModel = {
            loadData: function (model, refreshPageSize) {
                self.filterModel = model;
                if (refreshPageSize) {
                    self.paginatorModel.currentPageSize = self.paginatorModel.defaultPageSize;
                }
                self.loadData();
            },


            onExport: function (model) {
                if (!model.statuses.length) {
                    model.statuses = [self.statuses.approved, self.statuses.pending, self.statuses.authorized, self.statuses.denied];
                }
                self.filterModel = model;

                window.location.href = $.cdh.routes.transactions.export(model);
            },
            onPrint: function () {
                self.paginatorModel.showAll(function () {
                    if (printArea == null)
                        printArea = $("#trans-full-container");

                    setTimeout(function () {
                        printArea.printArea();
                    }, 0);
                })
            },
            accountsReassociated: self.accountsReassociatedRequest.data,
            transactionsYearsWithAccounts: self.transactionsYearsWithAccountsRequest.data,
            selectedHistoricalAccount: self.selectedHistoricalAccount,
            disable: self.getPageRequest.isLoading,
            hasData: ko.pureComputed(function () {
                return self.transactions().length > 0;
            })
        };
        
        self.loadData = function(currentPage, callback) {
            if (self.transactionsYearsWithAccountsRequest.isLoading()) {
                return;
            }

            self.paginatorModel.pageNo(currentPage || 1);

            let model = {
                pageParams: {
                    pageNo: self.paginatorModel.pageNo(),
                    pageSize: self.paginatorModel.currentPageSize
                },
                filter: self.filterModel
            };
            self.getPageRequest.load(model).done(function (data) {
                self.transactions(data.Data);
                self.paginatorModel.totalPages(data.TotalPages);
                updateHeight();
                
                if(callback) {
                    callback();
                }
            });
        };
        
        self.getPageRequest.state.subscribe(updateHeight);

        let yearsWithAccountsRequestData = accountIdParam ? {accountId: accountIdParam} : null;
        self.transactionsYearsWithAccountsRequest.load(yearsWithAccountsRequestData);
        self.accountsReassociatedRequest.load();
    }
})(jQuery, ko);