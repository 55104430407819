(function ($) {
    // bankAccount is CDH.ApiServices.Models.Contribution.PendingContribution
    var contributionsPendingItemModel = function (contribution, dataPartnerId, onEdit, updateHeight) {
        $.extend(true, this, contribution);
        this.isDetailsVisible = ko.observable(false);
        this.deleteRequest = new $.cdh.post.contributions.delete(contribution.ContributionId);
        this.editable = ko.observable(contribution.ContributionStatus === $.cdh.enums.pendingContributionStatus.pending);
        this.deletable = ko.observable(contribution.ContributionStatus === $.cdh.enums.pendingContributionStatus.pending);

        this.deleteClick = function () {
            if (this.deleteRequest.isLoading())
                return;
            $.cdh.helper.confirm('Delete transfer?', function () {
                this.deleteRequest.load().done(function () { onEdit() });
            }.bind(this),
            null,
            'Delete Transfer');
        }

        this.editContribution =
            new $.cdh.models.ContributionsAddEditPopup(this, dataPartnerId, onEdit);

        this.isDetailsVisible.subscribe(updateHeight);
    }

    var contributionsPendingListModel = function (updateHeight) {
        var accountId = $.cdh.helper.urlParam('accountId');
        var dataPartnerId = $.cdh.helper.urlParam('dataPartnerId');

        this.AccountId = accountId;

        this.account = ko.observable(null);
        this.accountVeba = ko.pureComputed(function () {
            if (this.account() != null && this.account().IsVeba) {
                return true;
            }
            return false;
        }, this);
        $.cdh.accounts.initAccounts(function (data) {
            var accounts = $.grep(data,
                function (a) {
                    if (a.AccountId == accountId) {
                        return true;
                    }
                    return false;
                });

            if (accounts && accounts.length > 0)
                this.account(accounts[0]);

        }.bind(this));


        this.contributions = new $.cdh.get.contribution.getPending(accountId,
            function (json) {
                var models = $.map(json, function (cnt) {
                    var model = new contributionsPendingItemModel(cnt, dataPartnerId, this.contributions.load, updateHeight);
                    model.setClose = function (closeFunc) {
                        model.close = closeFunc;
                    };
                    return model;
                }.bind(this));
                return models;
            }.bind(this));

        this.init = function () {
            this.contributions.load();
        }.bind(this);

        this.addContributionModelBuilder = function() {
            return new $.cdh.models.ContributionsAddEditPopup({ AccountId: accountId }, dataPartnerId, this.init);
        }.bind(this);

        this.updateHeight = updateHeight;
        this.contributions.state.subscribe(updateHeight);

        this.init();
    }


    ko.components.register('contributions-pending-list', {
        template: { element: 'template-contributions-pending-list' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new contributionsPendingListModel(updateHeight);
            }
        }
    });
})(jQuery);