import template from "./dependents-page.html"

(function ($, ko) {
    ko.components.register('dependents-page', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new $.cdh.models.ProfileFull(updateContainerHeight(componentInfo.element));
            }
        }
    });

})(jQuery, ko);