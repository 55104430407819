import template from "./smart-registration.html"

(function ($, ko) {
    ko.components.register('smart-registration', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new SmartRegistration(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
    
    const cookieStore = {
        get currentStep() {
           let value = $.cdh.helper.getCookie("srcs");
           return value ? parseInt(value) : null;
        },
        set currentStep(value) {
            if (value){
                $.cdh.helper.setCookie("srcs", value, 0, 24, 0, true);
            }else{
                $.cdh.helper.setCookie("srcs", value, -1, 0, 0, true);
            }
        }
    };

    const steps = {
        SearchByDetails: 1,
        AddEmployee: 2,
        PickValidationMethod: 3,
        EnterValidationCode: 4,
        CreateUser: 5,
        UnableRegistration: 6,
        SetSecurityQ: 7,
        Review: 8,
        Results: 9,
        Confirmation: 10
    };

    
    function StepModel(parent, stepValue, onNext, onNoCodeReceived) {
        const self = this;
        self.backStep = steps.SearchByDetails;
        self.clear = function() {};
        self.onNext = onNext;
        self.onNoCodeReceived = onNoCodeReceived;
        window.addEventListener('beforeunload', (event) => {
            if (parent.currentStep() <= steps.UnableRegistration) {
                event.preventDefault();
            }
            cookieStore.currentStep = steps.SearchByDetails;
        });
        if (typeof self.onNoCodeReceived === "undefined" || self.onNoCodeReceived === null) {
            self.onNoCodeReceived = function() {};
        }      
        self.onBack = function() {
            parent.currentStep(self.backStep);
            cookieStore.currentStep = self.backStep;
        };
        self.onCancel = function() {
            cookieStore.currentStep = steps.SearchByDetails;
            location.href = $.cdh.routes.authentication.handshake();
        };
        self.setAsCurrent = function(backStep) {
            if(backStep) {
                self.backStep = backStep;
            }
            self.clear();
            parent.currentStep(stepValue);
            cookieStore.currentStep = stepValue;
        };

        Object.defineProperty(this, "allowSelectEmployerByName", {
            get: () => parent.allowSelectEmployerByName
        });

        Object.defineProperty(this, "allowOTPAuthenticationInRegistration", {
            get: () => parent.allowOTPAuthenticationInRegistration
        });
    }
    
    function SmartRegistration(params, updateHeight) {
        const self = this;
        
        self.steps = steps;
        self.currentStep = ko.observable();
        self.username = ko.observable();
        self.userData = ko.observable({});
        self.enrollUserRequest = new $.cdh.post.registration.enroll();
        self.tryToLoginRequest = new $.cdh.post.smartRegistration.tryToLogin();
        self.confirmEmail = ko.observable();
        self.questions = ko.observable({});
        self.currentStep.subscribe(function() {
           updateHeight(); 
        });

        self.allowSelectEmployerByName = false;
        
        self.getContextRequest = new $.cdh.get.smartRegistration.getContextData();
        self.registrationOptionsRequest = new $.cdh.get.registration.registrationWidgetData();
        self.aggregatedStatus = new $.cdh.models.aggregatedLoadStatus([self.getContextRequest, self.registrationOptionsRequest]);
        self.isStep1 = ko.pureComputed(function() {
            return [steps.SearchByDetails, steps.AddEmployee].includes(self.currentStep());
        });
        self.isStep2 = ko.pureComputed(function() {
            return [steps.PickValidationMethod, steps.EnterValidationCode].includes(self.currentStep());
        });
        self.isStep3 = ko.pureComputed(function() {
            return self.currentStep() === steps.CreateUser;
        });
        self.isStep4 = ko.pureComputed(function () {
            return self.currentStep() === steps.SetSecurityQ;
        });
        self.isStep5 = ko.pureComputed(function () {
            return self.currentStep() === steps.Review;
        });
        self.isStep6 = ko.pureComputed(function () {
            return self.currentStep() === steps.Results;
        });

        self.smartRegisterationSteps = {
            step1: { name: 'step-1', isActive: self.isStep1, text: $.cdh.languageConstant.getConstant('registration.step.1') },
            step2: { name: 'step-2', isActive: self.isStep2, text: $.cdh.languageConstant.getConstant('registration.step.2') },
            step3: { name: 'step-3', isActive: self.isStep3, text: $.cdh.languageConstant.getConstant('registration.step.3') },
            step4: { name: 'step-4', isActive: self.isStep4, text: $.cdh.languageConstant.getConstant('registration.step.4') },
            step5: { name: 'step-5', isActive: self.isStep5, text: $.cdh.languageConstant.getConstant('registration.step.5') },
            step6: { name: 'step-6', isActive: self.isStep6, text: $.cdh.languageConstant.getConstant('registration.step.6') }
        }
        self.smartRegisterationStepsArray = Object.keys(self.smartRegisterationSteps);
        self.stepsLen = self.smartRegisterationStepsArray.length;


        self.userNameValidationArguments = ko.observable();

        self.cannotReceiveCodeClicked = false;

        self.searchemployeeResult;

        self.maskedData = {
            email: ko.observable(),
            mobileNumber: ko.observable(),
            phone: ko.observable(),
            empeOtpViaSmsOnly: ko.observable(),
            mapData: function(data) {
                self.maskedData.email(data.MaskedEmail);
                self.maskedData.mobileNumber(data.MaskedMobileNumber);
                self.maskedData.phone(data.MaskedPhone);
                self.maskedData.empeOtpViaSmsOnly(data.EmpeOtpViaSMSOnly);
            }
        };
        self.searchDetailsStep = new StepModel(self, steps.SearchByDetails, function (data) {
            self.searchemployeeResult = data.ResultType;
            let mobileCommunicationAvailable = (data.Details?.MaskedMobileNumber?.length > 0 || data.Details?.MaskedPhone?.length > 0);
            let communicationMeansAvailable = data.Details?.EmpeOtpViaSMSOnly == true ? mobileCommunicationAvailable : (mobileCommunicationAvailable || data.Details?.MaskedEmail?.length > 0);

            switch (data.ResultType) {
                case $.cdh.enums.searchEmployeeResultType.matchCard:
                case $.cdh.enums.searchEmployeeResultType.findUnique:
                    self.maskedData.mapData(data.Details);
                    if (self.allowSelectEmployerByName || self.allowOTPAuthenticationInRegistration) {
                        if (communicationMeansAvailable) {
                            self.pickMethodStep.setAsCurrent();
                        }
                        else {
                            self.unableRegistrationStep.setAsCurrent();
                        }
                    }
                    else {    
                        if (data.ResultType == $.cdh.enums.searchEmployeeResultType.findUnique) {
                            if (communicationMeansAvailable)
                                self.pickMethodStep.setAsCurrent();
                            else
                                self.addEmployeeStep.setAsCurrent();
                        }
                        else 
                            self.createUserStep.setAsCurrent(steps.AddEmployee);
                    }
                    break;
                case $.cdh.enums.searchEmployeeResultType.findUniqueWithoutContactInfo:
                    self.maskedData.mapData(data.Details);
                    self.setAddEmployeeOrError();
                    break;
                default:
                    self.addEmployeeStep.setAsCurrent();
            }
        });

        self.communicationMeansAvailable = false;
        
        self.addEmployeeStep = new StepModel(self, steps.AddEmployee, function(data) {
            if (data)
            {
                if (data.Success)
                {
                    if (self.allowSelectEmployerByName || self.allowOTPAuthenticationInRegistration)
                    {
                        let details = data.Details;
                        let mobileCommunicationAvailable = (details.MaskedMobileNumber?.length > 0 || details.MaskedPhone?.length > 0);
                        let communicationMeansAvailable = details.EmpeOtpViaSMSOnly ? mobileCommunicationAvailable : (mobileCommunicationAvailable || details.MaskedEmail?.length > 0);
                        self.communicationMeansAvailable = communicationMeansAvailable;

                        if (!communicationMeansAvailable) {
                            self.unableRegistrationStep.setAsCurrent();
                        }
                        else {
                            self.maskedData.mapData(data.Details);
                            self.pickMethodStep.setAsCurrent(steps.AddEmployee);
                        }
                    }
                    else {
                        var details = data?.Details;
                        if (details) {
                            var mobileCommunicationAvailable = (details?.MaskedMobileNumber?.length > 0 || details?.MaskedPhone?.length > 0);
                            var communicationMeansAvailable = details?.EmpeOtpViaSMSOnly ? mobileCommunicationAvailable : (mobileCommunicationAvailable || details?.MaskedEmail?.length > 0);
                            self.communicationMeansAvailable = communicationMeansAvailable;

                            if (communicationMeansAvailable && !self.cannotReceiveCodeClicked && (self.searchemployeeResult == $.cdh.enums.smartRegistrationProgressStatus.notFoundEmployee)) {
                                self.maskedData.mapData(data?.Details);
                                self.pickMethodStep.setAsCurrent(steps.AddEmployee);
                            }
                            else {
                                self.createUserStep.setAsCurrent(steps.AddEmployee);
                            }
                        }
                        else {
                            self.unableRegistrationStep.errorMessage(data.ErrorMessage);
                            self.unableRegistrationStep.setAsCurrent();
                        }
                    }
                }
                else
                {
                    self.unableRegistrationStep.errorMessage(data.ErrorMessage);
                    self.unableRegistrationStep.setAsCurrent();
                }
            }
            else
            {
                self.searchDetailsStep.setAsCurrent();
            }
        });
        
        self.pickMethodStep = new StepModel(self, steps.PickValidationMethod, function(data) {
            if (data) {
                if (data.Success) {
                    self.enterCodeStep.selectedMethod(data.Method);
                    self.enterCodeStep.setAsCurrent(steps.PickValidationMethod);
                } else {
                    self.setAddEmployeeOrError();
                }
            } else {
                self.searchDetailsStep.setAsCurrent();
            }
        }, function() {
            self.setAddEmployeeOrError();
        });
        
        self.pickMethodStep.maskedData = self.maskedData;
        
        self.enterCodeStep = new StepModel(self, steps.EnterValidationCode, function(data) {
            if (data) {
                if (data.Success) {
                    self.createUserStep.setAsCurrent();
                } else {
                    if (data.IsTicketExpired) {
                        alert("Your authentication request has timed out. Click ok to refresh this request.");
                        self.pickMethodStep.setAsCurrent();
                    } else {
                        self.setAddEmployeeOrError();
                    }
                }
            } else {
                self.searchDetailsStep.setAsCurrent();
            }
        }, function() {
            self.setAddEmployeeOrError();
        });

        self.setAddEmployeeOrError = function () {
            self.cannotReceiveCodeClicked = true;
            
            if (self.allowSelectEmployerByName || self.allowOTPAuthenticationInRegistration || (self.communicationMeansAvailable && !self.cannotReceiveCodeClicked)) {
                self.unableRegistrationStep.setAsCurrent();
            }
            else if (!self.allowSelectEmployerByName && !self.allowOTPAuthenticationInRegistration && self.communicationMeansAvailable && self.cannotReceiveCodeClicked) {
                self.createUserStep.setAsCurrent(steps.AddEmployee);
            }
            
            else {
                self.addEmployeeStep.setAsCurrent();
            }
        };
        
        self.enterCodeStep.maskedData = self.maskedData;
        self.enterCodeStep.selectedMethod = ko.observable();
        
        self.createUserStep = new StepModel(self, steps.CreateUser, function (data) {
            if (data) {
                self.username = data.username;
                self.password = data.password;
                self.SecurityQuestionStep.setAsCurrent();
            //    if (data.Success) {
            //        self.questionsSetup.setAsCurrent();
            //    } else {
            //        if (data.IsTicketExpired) {
            //            alert("Your authentication request has timed out. Click ok to refresh this request.");
            //            self.pickMethodStep.setAsCurrent();
            //        } else {
            //            self.setAddEmployeeOrError();
            //        }
            //    }
            }
            //cookieStore.currentStep = null;
            //self.createUserStep.disable();
            //location.href = $.cdh.routes.predefined.registration + '?securitysetup=true&skipintroduction=true&smartSteps=true';
            else {
                self.searchDetailsStep.setAsCurrent();
            }
        });

        self.createUserStep.maskedData = self.maskedData;

        self.SecurityQuestionStep = new StepModel(self, steps.SetSecurityQ, function (data) {
            if (data) {
                if (data.Success) {
                    self.questions = data.Questions;
                    self.userData = data.UserData;
                    self.reviewStep.setAsCurrent();
                }
            } else {
                self.searchDetailsStep.setAsCurrent();
            }
        });

        self.reviewStep = new StepModel(self, steps.Review, function (data) {
            if (data) {
                if (data.Success) {
                    self.userData.email = data.ConfirmEmail;
                    self.userData.mobileNumber = data.ConfirmMobileNumber;
                    self.resultStep.setAsCurrent();
                }
            } else {
                self.searchDetailsStep.setAsCurrent();
            }

        });

        self.resultStep = new StepModel(self, steps.Results, function (data) {
            if (data) {
                if (data.Success) {
                    if (data.EditQuestions) {
                        self.SecurityQuestionStep.setAsCurrent();
                    }
                    else if (data.EditUserData) {
                        self.reviewStep.setAsCurrent();
                    }
                    else {
                        if (self.enrollUserRequest.isLoading())
                            return;
                        self.enrollUserRequest.load({
                            ChallengeQs: $.map(self.questions,
                                function (question) {
                                    return { QuestionId: question.selectedQuestion(), UserAnswer: question.UserAnswer };
                                }),
                            Email: self.userData.email(),
                            UserId: self.userData.userName()
                        })
                            .done(function (result) {
                                if (result.IsForcePasswordChange === true) {
                                    $.cdh.helper.logoutWithRedirect($.cdh.routes.authentication.forcePasswordChange() + "?userName=" + self.userData.userName());
                                } else {
                                    self.confirmationStep.setAsCurrent();
                                }
                            }.bind(this));
                    }
                }
            } else {
                self.searchDetailsStep.setAsCurrent();
            }
        });
        self.confirmationStep = new StepModel(self, steps.Confirmation, function (data) {
            if (data) {
                if (data.Success) {
                    self.tryToLoginRequest.load({ Username: self.username, Password: self.password }).done((data) => {
                        if (data) {
                            window.location.href = $.cdh.routes.base();
                        }
                    });
                }
            }
        });

        self.createUserStep.disable = function () { };

        self.unableRegistrationStep = new StepModel(self, steps.UnableRegistration, () => {
           self.unableRegistrationStep.errorMessage(null);
            self.addEmployeeStep.clear();
            self.searchDetailsStep.setAsCurrent();
        });
        self.unableRegistrationStep.errorMessage = ko.observable();

        self.getContextRequest.load().done(function(data) {
            if (data) {

                self.maskedData.mapData(data);
                self.enterCodeStep.selectedMethod(data.SendCodeMethod);

                if(cookieStore.currentStep) {
                    self.currentStep(cookieStore.currentStep);
                }
                else {
                    switch (data.ProgressStatus) {                        
                        case $.cdh.enums.smartRegistrationProgressStatus.validatedCode:
                            self.createUserStep.setAsCurrent();
                            return;
                        case $.cdh.enums.smartRegistrationProgressStatus.addedEmployeeId:
                        case $.cdh.enums.smartRegistrationProgressStatus.notFoundEmployee:
                            self.addEmployeeStep.setAsCurrent();
                            return;
                        case $.cdh.enums.smartRegistrationProgressStatus.matchedCard:
                        case $.cdh.enums.smartRegistrationProgressStatus.foundEmployee:
                            self.pickMethodStep.setAsCurrent();
                            return;
                        case $.cdh.enums.smartRegistrationProgressStatus.sendingCode:
                        case $.cdh.enums.smartRegistrationProgressStatus.sendedCode:
                            self.enterCodeStep.setAsCurrent();
                            return;
                        default:
                            self.searchDetailsStep.setAsCurrent();
                            return;
                    }
                }
            } else {
                self.searchDetailsStep.setAsCurrent();
            }
        });

        self.registrationOptionsRequest.load().done(function (data) {
            self.allowSelectEmployerByName = data.AllowSelectEmployerByName;
            self.allowOTPAuthenticationInRegistration = data.AllowOTPAuthenticationInRegistration;
            self.userNameValidationArguments({ MaxUserIdLength: data.MaxUserIdLength, MinUserIdLength: data.MinUserIdLength });
        });
    }
    
})(jQuery, ko);
