import './investment-portal-account-selector.scss';
import template from './investment-portal-account-selector.html';
import {AccountsService} from "../../Http/Accounts/AccountsService";
import {RegisterDefaultComponent} from "../../Common/componentsHelpers";
import {ComponentBase} from "../../Common/componentBase";

const accountsService = new AccountsService();
export class InvestmentPortalAccountSelector extends ComponentBase {

    constructor() {
        super();
        this.accounts = ko.observableArray([]);
        this.init();
    }

    async init() {
        await this.apiInitCallAsync(async () => {
            const accounts = await accountsService.getListInvestmentAsync();
            for (let i = 0; i < accounts.length; i++) {
                const account = accounts[i];
                account.InvestmentUrl = this.getInvestmentUrl(account);
            }
            this.accounts(accounts);
            if (accounts.length === 1) {
                document.location.href = accounts[0].InvestmentUrl;
            }
        });
    }

    getInvestmentUrl(account) {
        if (account.InvestmentState === $.cdh.enums.investmentSolutionStates.activeDrive || account.InvestmentState === $.cdh.enums.investmentSolutionStates.inactiveDrive) {
            return `/Page/Investment/#/account/${account.AccountId}`;
        }
        return `/Page/InvestmentsForAccount?accountId=${account.AccountId}`;
    }
}

RegisterDefaultComponent('investment-account-selector', template, InvestmentPortalAccountSelector);