(function ($, ko) {

    var editUserPictureModel = function (profile, successCalback) {
        this.profile = profile;
        this.files = ko.observableArray([]);

        this.uploadErrors = ko.observableArray([]);
        this.uploadingInProgress = ko.observable(false);
        this.updateRequest = new $.cdh.post.profile.updateUserPicture();

        this.setClose = function (closer) {
            this.close = function () {
                var file = this.files()[0];
                if (file) {
                    this.files.remove(file);
                }
                closer();
            }
        }.bind(this);

        this.clickSubmit = function () {

            if (this.uploadingInProgress()) {
                return;
            }

            this.uploadErrors([]);
            this.uploadingInProgress(true);

            var finished = $.Deferred();

            var file = this.files()[0];

            var data = {
                FileName: file.fileName,
                ContentType: file.contentType,
                Base64: file.base64
            };

            this.updateRequest
                .load(data)
                .done(function (data) {
                        $.cdh.userPic.update(data);
                        finished.resolve();
                        if ($.isFunction(successCalback))
                            successCalback(data);
                    }
                    .bind(this))
                .fail(function (e) {
                    this.uploadingInProgress(false);
                }.bind(this));

            finished.done(function () {
                this.uploadingInProgress(false);
                this.close();
                if (!$.isFunction(successCalback))
                    location.reload();
            }.bind(this))
                .fail(this.uploadErrors.push.bind(this.uploadErrors,
                    "Can't upload '" + file.fileName + "'. Please try again."));;
        }.bind(this);

        this.clickRemoveImage = function (image) {
            this.files.remove(image);
        }.bind(this);
    };

    $.extend(true,
        $,
        {
            cdh: {
                models: {
                    editUserPictureModel: editUserPictureModel
                }
            }
        });
}(jQuery, ko));