(function($, ko) {
    function observableField(value, template, validation) {
        var settings = typeof(template) === 'object' ? template : null;
        var result = ko.observable(value);
        if (validation) result.extend(validation);
        if (settings) result.extend({ required: settings.IsRequired });
        if (!settings && !validation) result.extend({required: false}); 
        if (!settings) settings = {
            IsHidden: false, IsReadonly: false
        };
        result.isHidden = settings.IsHidden;
        result.isReadonly = settings.IsReadonly;
        result.label = settings.Label;
        return result;
    }

    function enrollmentDemographic(data, template, forHsa, anyHsaCompleted) {
        var settings = template.Demographics;
        this.forHsa = ko.observable(forHsa);

        this.hasPermissionsToEditEmail = true;
        if (!$.cdh.user.isAnonymousEnrollmentUser)
            this.hasPermissionsToEditEmail = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateEmailAddress);

        this.hasPermissionsToEditDemographic = true;
        if (!$.cdh.user.isAnonymousEnrollmentUser)
            this.hasPermissionsToEditDemographic = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateProfileDemographicInformation);

        this.isRequiredEmployerName = {
            required:
            {
                onlyIf: function () {
                    return settings.EmpeEmprName.IsRequired && parseInt(this.employmentStatus()) !== $.cdh.enums.employeeEmploymentStatus.unemployed &&
                        parseInt(this.employmentStatus()) !== $.cdh.enums.employeeEmploymentStatus.none;
                }.bind(this)
            }
        };
        this.isRequiredEmployerCity = {
            required:
                {
                    onlyIf: function () {
                        return settings.EmpeEmprCity.IsRequired && parseInt(this.employmentStatus()) !== $.cdh.enums.employeeEmploymentStatus.unemployed &&
                            parseInt(this.employmentStatus()) !== $.cdh.enums.employeeEmploymentStatus.none;
                    }.bind(this)
                }
        };
        this.isRequiredEmployerState = {
            required:
                {
                    onlyIf: function () {
                        return settings.EmpeEmprState.IsRequired && parseInt(this.employmentStatus()) !== $.cdh.enums.employeeEmploymentStatus.unemployed &&
                            parseInt(this.employmentStatus()) !== $.cdh.enums.employeeEmploymentStatus.none;
                    }.bind(this)
                }
        };
        this.isRequiredEmployerJobTitle = {
            required:
                {
                    onlyIf: function () {
                        return settings.EmpeEmprJobTitle.IsRequired && parseInt(this.employmentStatus()) !== $.cdh.enums.employeeEmploymentStatus.unemployed &&
                            parseInt(this.employmentStatus()) !== $.cdh.enums.employeeEmploymentStatus.none;
                    }.bind(this)
                }
        };

        this.firstName = observableField(data.FirstName, null, {required: true});
        this.lastName = observableField(data.LastName, null, {required: true});
        this.middleInitial = observableField(data.MiddleInitial, settings.EmpeInitial);
        this.birthDate = observableField(data.BirthDate, settings.EmpeBirthDate);
        this.employeeSSN = observableField(data.EmployeeSSN, settings.EmpeSsn);
        if(forHsa){
            this.employeeSSN.rules.remove(function (item) {
                return item.rule === "required";
            });
            
            this.employeeSSN.extend({required: true});
        }
        
        this.gender = observableField(data.Gender, settings.EmpeGender, { enumRequired: settings.EmpeGender.IsRequired });
        this.phone = observableField(data.Phone, settings.EmpePhone, { phoneUS: true });
        this.phone.isReadonly = !this.hasPermissionsToEditDemographic;

        this.email = observableField(data.Email, settings.EmpeEmailAddress, { email: true });
        this.email.isReadonly = !this.hasPermissionsToEditEmail;

        this.repeatEmail = observableField(data.Email,
            settings.EmpeEmailAddress,
            {
                equal: {
                    params: this.email,
                    message: $.cdh.languageConstant.getConstant('messages.email-not-match')
                }, email: true 
            });
        this.repeatEmail.isReadonly = !this.hasPermissionsToEditEmail;

        this.marital = observableField(data.MaritalStatus, settings.EmpeMaritialStatus);
        this.mothersMaidenName = observableField(data.MotherMaidenName, settings.EmpeMaidenName);
        this.driverLicenceNumber = observableField(data.DriverLicenceNumber, settings.EmpeDriverLicense);

        this.citizenStatus = observableField(data.MiscData.CitizenStatusCode, settings.EmpeCitizenship);
        this.citizenshipCountry = observableField(data.MiscData.CitizenshipCountry, settings.EmpeCitizenCountry);
        this.salary = observableField(data.MiscData.BaseSalary || 0, settings.EmpeSalary, {numeric:true, min: 0, max: 99999999999999 });
        this.employmentStatus = observableField(data.MiscData.EmploymentStatus, settings.EmpeEmploymentStatus);
        this.employerName = observableField(data.MiscData.EmployerName, settings.EmpeEmprName, this.isRequiredEmployerName);
        this.employerCity = observableField(data.MiscData.EmployerCity, settings.EmpeEmprCity, this.isRequiredEmployerCity);
        this.employerState = observableField(data.MiscData.EmployerState, settings.EmpeEmprState, this.isRequiredEmployerState);
        this.employerJobTitle = observableField(data.MiscData.JobTitle, settings.EmpeEmprJobTitle, this.isRequiredEmployerJobTitle);

        this.addressLine1 = observableField(data.AddressLine1, null, { required: true });
        this.addressLine1.isReadonly = !this.hasPermissionsToEditDemographic;

        if (this.forHsa()) {
            this.addressLine1.extend({ notPostAddress: true });
        }
        this.addressLine2 = observableField(data.AddressLine2, null, { required: false });
        this.addressLine2.isReadonly = !this.hasPermissionsToEditDemographic;

        this.city = observableField(data.City, null, { required: true });
        this.city.isReadonly = !this.hasPermissionsToEditDemographic;

        this.state = observableField(data.State, null, { required: true });
        this.state.isReadonly = !this.hasPermissionsToEditDemographic;

        this.country = observableField(data.Country, null, { required: true });
        this.country.isReadonly = !this.hasPermissionsToEditDemographic;

        this.zip = observableField(data.Zip,
            null,
            {
                zipCode: true,
                required: true
            });
        this.zip.isReadonly = !this.hasPermissionsToEditDemographic;

        this.sameAddress = observableField(
            data.ShippingAddressLine1 === data.AddressLine1 &&
            data.ShippingAddressLine2 === data.AddressLine2 &&
            data.ShippingAddressCity === data.City &&
            data.ShippingAddressState === data.State &&
            data.ShippingAddressCountry === data.Country &&
            data.ShippingAddressZip === data.Zip,
            settings.EmpeShippingAddress
        );
        this.sameAddress.isReadonly = !this.hasPermissionsToEditDemographic;


        if (this.sameAddress.isHidden && this.forHsa()){
            this.sameAddress.isHidden = false;
        }

        this.shippingRequired = ko.computed(function () {
            if (!this.hasPermissionsToEditDemographic)
                return false;

            return settings.EmpeShippingAddress.IsRequired;
        }, this);
        
        this.shippingFieldsRequired = ko.computed(function () {
            if (!this.hasPermissionsToEditDemographic)
                return false;

            return !this.sameAddress(); // Fields always required and showed like on old portal
        }, this);
        
        if (settings.EmpeShippingAddress.IsHidden) {
            this.sameAddress(true);
        }

        this.shippingAddressLine1 = observableField(data.ShippingAddressLine1, null, { required: { onlyIf: this.shippingFieldsRequired } });
        this.shippingAddressLine1.isReadonly = !this.hasPermissionsToEditDemographic;

        this.shippingAddressLine2 = observableField(data.ShippingAddressLine2, null, {required: false});
        this.shippingAddressLine2.isReadonly = !this.hasPermissionsToEditDemographic;

        this.shippingCity = observableField(data.ShippingAddressCity, null, { required: { onlyIf: this.shippingFieldsRequired } });
        this.shippingCity.isReadonly = !this.hasPermissionsToEditDemographic;

        this.shippingState = observableField(data.ShippingAddressState, null, { required: { onlyIf: this.shippingFieldsRequired } });
        this.shippingState.isReadonly = !this.hasPermissionsToEditDemographic;

        this.shippingCountry = observableField(data.ShippingAddressCountry, null, { required: { onlyIf: this.shippingFieldsRequired } });
        this.shippingCountry.isReadonly = !this.hasPermissionsToEditDemographic;

        this.shippingZip = observableField(data.ShippingAddressZip,
            null,
            {
                required: { onlyIf: this.shippingFieldsRequired },
                zipCode: true
            });
        this.shippingZip.isReadonly = !this.hasPermissionsToEditDemographic;

        this.getLabel = function(templateField, defaultName) {
            if (!templateField) return defaultName;
            return templateField.Value || defaultName;
        }

        this.toServerModel = function() {
            var model = this;
            var result = {
                TemplateKey: template.TemplateKey,
                IsProductPartner: template.IsProductPartner,
                FirstName: model.firstName(),
                LastName: model.lastName(),
                MiddleInitial: model.middleInitial(),
                BirthDate: model.birthDate(),
                EmployeeSSN: model.employeeSSN(),
                Gender: model.gender(),
                Phone: model.phone(),
                Email: model.email(),
                AddressLine1: model.addressLine1(),
                AddressLine2: model.addressLine2(),
                City: model.city(),
                State: model.state(),
                Country: model.country(),
                Zip: model.zip(),
                MaritalStatus: model.marital(),
                MotherMaidenName: model.mothersMaidenName(),
                DriverLicenceNumber: model.driverLicenceNumber(),
                ShippingAddressLine1: model.shippingAddressLine1(),
                ShippingAddressLine2: model.shippingAddressLine2(),
                ShippingAddressCity: model.shippingCity(),
                ShippingAddressState: model.shippingState(),
                ShippingAddressCountry: model.shippingCountry(),
                ShippingAddressZip: model.shippingZip(),
                LastUpdated: data.LastUpdated,
                SameShippingAddress: model.sameAddress(),
                MiscData: {
                    CitizenStatusCode: model.citizenStatus(),
                    CitizenshipCountry: model.citizenshipCountry(),
                    BaseSalary: model.salary(),
                    EmploymentStatus: model.employmentStatus(),
                    EmployerName: model.employerName(),
                    EmployerCity: model.employerCity(),
                    EmployerState: model.employerState(),
                    JobTitle: model.employerJobTitle()
                }
            };
            if (this.sameAddress()) {
                result.ShippingAddressLine1 = model.addressLine1();
                result.ShippingAddressLine2 = model.addressLine2();
                result.ShippingAddressCity = model.city();
                result.ShippingAddressState = model.state();
                result.ShippingAddressCountry = model.country();
                result.ShippingAddressZip = model.zip();
            }
            return result;
        }.bind(this);

        this.validation = new $.cdh.validation(this);

    }

    $.extend(true, $, {
        cdh: {
            models: {
                EnrollmentDemographic: enrollmentDemographic
            }
        }
    });
})(jQuery, ko);