import {DataServiceBase} from "../DataServiceBase";

let singleInstance;

export class ProfileService extends DataServiceBase {
    constructor() {
        if (singleInstance) {
            return singleInstance;
        }
        
        super('/Profile/Get');

        this.getPromise = null;

        singleInstance = this;
    }
    
    getAsync(data, urlSegment) {
        if(!this.getPromise) {
            this.getPromise = super.getAsync(data, urlSegment);
        }
        
        return this.getPromise;
    }
}