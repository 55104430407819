(function (module, ko) {

    module.PdfViewModel = function (accountId, specifics) {
        var validPin = ko.observable();

        var self = this;

        self.accountId = accountId;
        self.switchedOff = ko.observable(true);

        self.pin = ko.observable();
        self.pinValid = ko.pureComputed(function () {
            return validPin() != null && self.pin() == validPin();
        });

        self.switch = function (state) {
            self.switchedOff(state === "off");
            self.pin(null);
        }

        new specifics.requests.get.pdfpin(accountId).load().done(function(response) {
            validPin(response);
        });
    }

})(window.docDelivery, window.ko);