(function ($, ko) {
    var expenseEditTps = function(eobSummary) {
        this.eobSummary = eobSummary;
        this.expenseKeys = eobSummary.EOBExtra.ExpenseKeys;
        this.selectedExpenseIndex = ko.observable(0);
       
        var phoneParts = {
            first3: ko.observable(),
            second3: ko.observable(),
            last4: ko.observable()
        };
        this.isPaymentAddressInfoHidden = ko.observable(false);
        
        this.detailsRequest = new $.cdh.post.expenses.details();
        this.editRequest = new $.cdh.post.expenses.editTps();

        this.states = new $.cdh.get.scontent.states(function(result) {
            var states = $.map(result,
                function(value, key) {
                    return { key: key, value: value };
                });
            return states;
        });

        this.states.load();

        this.preRender = function () {
            this.expense = {
                ProviderName: ko.observable(),
                ProviderAddress1: ko.observable(),
                ProviderAddress2: ko.observable(),
                ProviderCity: ko.observable(),
                ProviderZip: ko.observable(),
                ProviderState: ko.observable(),
                ProviderPhoneParts: phoneParts,
                ProviderPhone: ko.pureComputed(function() {
                        return this.expense.ProviderPhoneParts.first3() +
                            this.expense.ProviderPhoneParts.second3() +
                            this.expense.ProviderPhoneParts.last4();
                    },
                    this),
                PaidNonReimbursable: ko.observable(),
                Billed: ko.observable(),
                Allowed: ko.observable(),
                Paid: ko.observable(),
                MyResposibility: ko.observable(),
                ReimbursedFromMyAccount: ko.observable()
            };

            this.anyPhonePartsEntered = function() {
                return this.expense.ProviderPhoneParts.first3().length > 0 ||
                    this.expense.ProviderPhoneParts.second3().length > 0 ||
                    this.expense.ProviderPhoneParts.last4().length > 0;
            }.bind(this);
            
            this.RemainingResponsibility = ko.pureComputed(function() {
                    if (this.expense.PaidNonReimbursable() == 0 && this.expense.MyResposibility() == NaN)
                        return NaN;

                    return (this.expense.MyResposibility() -
                        this.getDoubleValue(this.expense.PaidNonReimbursable()) -
                        this.expense.ReimbursedFromMyAccount()).toFixed(2);
                },
                this);
            
            this.validation = new $.cdh.validation(this.expense);
            initializeExpense();
        }.bind(this);

       var initializeExpense = function() {
            this.expenseKey = this.expenseKeys[this.selectedExpenseIndex()];
            
            this.detailsRequest.load({ expenseKey: this.expenseKey.ExpenseKey, originCde: this.expenseKey.OriginCde })
                .done(function(data) {

                    var details = data.ExpenseDetails;
                    var claimants = data.Claimants;

                    if (!details.ProviderPhone) {
                        details.ProviderPhone = "";
                    }
                
                    phoneParts.first3(details.ProviderPhone.substring(0, 3));
                    phoneParts.second3(details.ProviderPhone.substring(3, 6));
                    phoneParts.last4(details.ProviderPhone.substring(6, 10));

                    this.expense.ProviderName(details.ProviderName);
                        this.expense.ProviderAddress1(details.ProviderAddress1);
                        this.expense.ProviderAddress2(details.ProviderAddress2);
                        this.expense.ProviderCity(details.ProviderCity);
                        this.expense.ProviderZip(details.ProviderZip);
                        this.expense.ProviderState(details.ProviderState);
                        this.expense.PaidNonReimbursable(details.PaidNonReimbursable);
                        this.expense.Billed(details.Billed);
                        this.expense.Allowed(details.Allowed);
                        this.expense.Paid(details.Paid);
                        this.expense.MyResposibility(this.getDoubleValue(details.MyResposibility));
                        this.expense.ReimbursedFromMyAccount(this.getDoubleValue(details.ReimbursedFromMyAccount));
                        this.expense.ExpenseKey = details.ExpenseKey;
                        this.expense.OriginCde = details.OriginCde;
                    

                    this.expense.ProviderPhoneParts.first3.extend({
                        exactLength: { params: 3, onlyIf: this.anyPhonePartsEntered }
                    });

                    this.expense.ProviderPhoneParts.second3.extend({
                        exactLength: { params: 3, onlyIf: this.anyPhonePartsEntered }
                    });

                    this.expense.ProviderPhoneParts.last4.extend({
                        exactLength: { params: 4, onlyIf: this.anyPhonePartsEntered }
                    });

                    var invisible = false;
                    if (claimants) {
                        var foundClaimants = $.grep(claimants,
                            function (c) {
                                return c.CardholderKey === details.Claimant;
                            });
                        if (foundClaimants.length == 1) {
                            invisible = foundClaimants[0].CardholderTypeCde != 1;
                        }
                    }

                    this.isPaymentAddressInfoHidden(invisible && details.ProviderAddress1.toUpperCase().indexOf("PRIVATE") === -1);
                      
                    this.validation = new $.cdh.validation(this.expense);
                }.bind(this));
        }.bind(this);
      
        this.claimLines = $.map(this.expenseKeys, function(expense, index) {
            return { Text: index + 1, Value: index };
        });

        this.getDoubleValue = function(source) {
            var result = parseFloat(source);
            return isNaN(result) ? 0 : result;
        };

        this.selectedExpenseIndex.subscribe(initializeExpense);

        this.clickClose = function() {
            this.close();
        }.bind(this);

        this.setClose = function (closer) {
            this.close = closer;
        }.bind(this);

        this.canSubmit = ko.pureComputed(function() {
            return !this.validation.hasErrors() && !this.editRequest.isLoading();
        }.bind(this));

        this.clickSave = function() {
            this.validation.activate();
            if (this.validation.hasErrors() || this.editRequest.isLoading())
                return;

            this.editRequest.load(this.expense)
                .done(function(result) {
                    if (this.eobSummary.reloadListCallback)
                        this.eobSummary.reloadListCallback();

                    this.close();
                }.bind(this));
        }.bind(this);

        this.initialized = ko.pureComputed(function () {
            return this.detailsRequest.hasData() && this.states.hasData();
        }, this);
    }

    $.extend(true, $, {
        cdh: {
            models: {
                ExpenseEditTps: expenseEditTps
            }
        }
    });
})(jQuery, ko);
