import {DataServiceBase} from "../DataServiceBase";

let singleInstance;

export class AlertsService extends DataServiceBase {
    constructor() {
        if (singleInstance) {
            return singleInstance;
        }
        
        super('/Alerts');

        this.getCountPromise = null;

        singleInstance = this;
    }
    
    getCountAsync() {
        if(!this.getCountPromise) {
            this.getCountPromise = super.getAsync(null, 'Count');
        }
        
        return this.getCountPromise;
    }
}