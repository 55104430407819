import template from "./smart-registration-confirmation.html"

(function ($, ko) {
    ko.components.register('smart-registration-confirmation', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ConfirmationModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ConfirmationModel(params, updateHeight) {
        this.goLogin = function () {
            params.model.onNext({ Success: true })
        };
    }
})(jQuery, ko);