(function ($) {

    function bankAccountsValidationPopup(bankAccount, onSuccessValidate) {
        this.setClose = function(closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.bankAccount = bankAccount;
        this.validateRequest = new $.cdh.post.bankAccounts.validate();
        this.canSubmit = ko.pureComputed(function () {
            return !this.validateRequest.isLoading() && !this.validation.hasErrors();
        }, this);

        this.onlyOneNegativeValidator = {
            validator: function(val) {
                var negative = (this.validationModel.Amount1() < 0 ? 1 : 0) +
                    (this.validationModel.Amount2() < 0 ? 1 : 0) +
                    (this.validationModel.Amount3() < 0 ? 1 : 0);
                return negative <= 1 || val > 0;
            }.bind(this),
            message: $.cdh.languageConstant.getConstant("messages.cannot-be-negative")
        };

        this.validationModel = {
            DataPartnerId: this.bankAccount.DataPartnerId,
            Amount1: ko.observable().extend({ required: true, numeric: true, validation: this.onlyOneNegativeValidator }),
            Amount2: ko.observable().extend({ required: true, numeric: true, validation: this.onlyOneNegativeValidator }),
            Amount3: ko.observable().extend({ required: true, numeric: true, validation: this.onlyOneNegativeValidator }),
            BankAccountId: this.bankAccount.BankAccountId,
        }
        this.validation = new $.cdh.validation(this.validationModel);

        this.clickSubmit = function () {
            this.validation.activate();

            if (!this.canSubmit())
                return;

            this.validateRequest
                .load(ko.toJS(this.validationModel))
                .done(function () {
                    this.close();
                    onSuccessValidate();
                }.bind(this));
        }
    }

    $.extend(true, $, {
        cdh: {
            models: {
                BankAccountsValidationPopup: bankAccountsValidationPopup
            }
        }
    });
})(jQuery);