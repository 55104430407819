(function ($, ko) {
    var expensesAddModel = function (updateHeight) {
        this.closeUrl = document.referrer.endsWith($.cdh.routes.predefined.accountActivity)
            ? $.cdh.routes.predefined.accountActivity
            : $.cdh.routes.predefined.claims.pageList;
        
        this.updateHeight = updateHeight;
        this.title = $.cdh.languageConstant.getConstant('title.claim-future-reimbursement');
        this.allowToAddDependent = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionAllowParticipantAddDependent);

        this.currentStepModel = ko.observable(null);
        this.uploadingInProgress = ko.observable(false);
        
        this.addRequest = new $.cdh.post.expenses.add();
        
        this.widget = new $.cdh.post.expenses.submitExpenseWidgetData();
        this.widget.load({filterAccountsForClaim: false});
        this.widget.data.subscribe(function(data){
            if(data.ShouldBeRedirectedToBillPay){
                window.location.href = data.BillPayUrl;
                return;
            }
            
            var eligibilityDate = data.EligibilityDate;
            if (data.ShowEligibilityDate) {
                this.expense.ServiceMinStartDate(eligibilityDate);
                this.expense.ServiceMinEndDate(eligibilityDate);
                this.expense.ServiceStartDate.extend({
                    dateGreaterThen: {
                        params: eligibilityDate,
                        message: $.cdh.languageConstant.getConstant("messages.service-equal-eligibility")
                    }
                });
            }
            
            if(data.HasClaimants){
                this.claimants.setupList(data.Template.Claimants);
            }
            
            updateHeight();
        }, this);
        
        this.expense = {
            ServiceStartDate: ko.observable(null).extend({ required: true }),
            ServiceMinStartDate: ko.observable(null),
            ServiceEndDate: ko.observable(null).extend({ required: true }),
            ServiceMinEndDate: ko.observable(null),
            Billed: ko.observable().extend({ required: false, min: { params: 0.00, message: $.cdh.languageConstant.getConstant('messages.provider-amount-less-0') } }),
            Allowed: ko.observable().extend({required: false, min: {params: 0.00, message: $.cdh.languageConstant.getConstant('messages.insurance-amount-less-0') } }),
            Paid: ko.observable().extend({  required: false, min: {params: 0.00, message:  $.cdh.languageConstant.getConstant('messages.value-cannot-less-0') }}),
            PaidNonReimbursable: ko.observable().extend({ required: false, min: {params: 0.00, message: $.cdh.languageConstant.getConstant('messages.value-cannot-less-0') } }),
            Claimant: ko.observable().extend({ required: true }),
            ProviderName: ko.observable('').extend({ maxLength: 50 }),
            Description: ko.observable('').extend({maxLength: 255 }),
            EmpeKey: ko.pureComputed(function () {
                if(!this.widget.hasData()){
                    return null;
                }
                
                return this.widget.data().BaseCardholderKey;
            }, this)
        };
        
        this.expense.ServiceEndDate.extend({ dateGreaterThen: this.expense.ServiceStartDate });

        //contain logic to work with claimants
        this.claimants = new addClaimantStepModel(this);

        this.setTitle = function(title){
            var t = title || this.title;
            $.cdh.helper.titlePage(t);
        }.bind(this);

        this.invalidAmounts = ko.computed(function () {
            return getDoubleValue(this.expense.Allowed()).toFixed(2) < 0.001 && getDoubleValue(this.expense.Billed()).toFixed(2) < 0.001;
        }, this);

        this.AddModelLabel = {
            lblAmountMinusCovered: $.cdh.languageConstant.getConstant('messages.amount-minus-amount-covered'),
            lblCalculatedAutomatically: $.cdh.languageConstant.getConstant('messages.calculated-automatically')
        }

        this.expense.Responsibility = ko.computed(function () {
            if (typeof this.expense.Allowed() == 'undefined' && typeof this.expense.Paid() == 'undefined' && typeof this.expense.Billed() == 'undefined')
                return NaN;
            if (getDoubleValue(this.expense.Allowed()) > 0)
                return (getDoubleValue(this.expense.Allowed()) - getDoubleValue(this.expense.Paid())).toFixed(2);
            else
                return (getDoubleValue(this.expense.Billed()) - getDoubleValue(this.expense.Paid())).toFixed(2);
        }, this).extend({ min: { params: 0.01, message: $.cdh.languageConstant.getConstant('messages.responsibility-greater-0') } });

        this.Reimbursed = ko.computed(function () {
            if (!isNaN(this.expense.Responsibility()))
                return (0).toFixed(2);//???
        }, this);
        
        this.expense.RemainingResponsibility = ko.computed(function () {
            if (this.expense.PaidNonReimbursable() == 0 && this.expense.Responsibility() == NaN)
                return NaN;
            return (this.expense.Responsibility() - getDoubleValue(this.expense.PaidNonReimbursable()) - this.Reimbursed()).toFixed(2);
        }, this).extend({ min: { params: 0.01, message: $.cdh.languageConstant.getConstant('messages.remaining-responsibility-less-0') } });

        this.files = ko.observableArray([]);

        this.validation = new $.cdh.validation(this.expense);

        this.addExpense = function () {
            this.validation.activate();
            if (this.validation.hasErrors() || this.invalidAmounts () || this.addRequest.isLoading()) {
                updateHeight();
                return;
            }

            this.addRequest.load(this.expense).done(function(result) {
                    if (this.files().length > 0)
                        this.addReceipt(result);
                    else {
                        this.redirectToClaims();
                    }
                }.bind(this))
                .fail(function () {
                    this.uploadingInProgress(false);
                    updateHeight();
                }.bind(this));
        };

        this.addReceipt = function (key) {
            if (this.uploadingInProgress())
                return;
            this.uploadingInProgress(true);
            var completed = 0;
            ko.utils.arrayForEach(this.files(), function (file, index) {
                var splittedDataUrl = file.dataUrl.split(/[,:;]/);
                var fileData = {
                    FileName: file.fileName,
                    ContentType: splittedDataUrl[1],
                    Base64: splittedDataUrl[3]
                };
                new $.cdh.post.receipts.image.addToExpense()
                    .load({ file: fileData, expKey: key })
                    .done(function (result) {
                        if (++completed == this.files().length) {
                            this.redirectToClaims();
                        }
                    }.bind(this));
            }.bind(this));
        };

        this.redirectToClaims = function () {
            window.location.href = this.closeUrl;
        };

        this.clickRemoveImage = function (image, e) {
            this.files.remove(image);
        }.bind(this);
        
        this.files.subscribe(updateHeight);

        function getDoubleValue(source) {
            var result = parseFloat(source);
            return isNaN(result) ? 0 : result;
        }
        
        this.isMainView = ko.pureComputed(function(){
            return this.currentStepModel() == null;
        }, this);

        this.isAddClaimantView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isAddClaimantView;
        }, this);

        return this;
    };

    function addClaimantStepModel(model) {

        this.isAddClaimantView = true;
        this.list = ko.observableArray([]);
        this.prevSelected = ko.observable();
        this.selected = ko.observable();

        this.enabled = ko.observable(true);
        this.title = $.cdh.languageConstant.getConstant('title.enter-family-member');
        this.selectListOptionsCaption = $.cdh.languageConstant.getConstant('field.select-claimant');
        this.addNewClaimantOption = { LastName: $.cdh.languageConstant.getConstant('field.add-new-dependent') };
        this.addDependentModel = null;
        this.profileFullModel = new $.cdh.models.ProfileFull(model.updateHeight);

        //events callbacks
        this.onDependentAdded = function (claimant) {
            if(!claimant)
                return;
            
            var indexOfAddOption = this.list().indexOf(this.addNewClaimantOption);
            if(indexOfAddOption === 0){
                this.list().push(claimant);    
            }
            else{
                this.list().splice(indexOfAddOption - 1, 0, claimant);
            }
            
            this.selected(claimant);
        }.bind(this);

        this.setupList = function(dependents) {
            this.list(dependents);

            if (model.allowToAddDependent) {
                this.list.push(this.addNewClaimantOption);
            }
        }.bind(this);

        this.getDisplayName = function (dependent) {
            var displayName = dependent.LastName;
            if (!displayName) {
                displayName = dependent.FirstName;
            }
            else if (dependent.FirstName) {
                displayName = displayName + ', ' + dependent.FirstName;
            }

            return displayName;
        };

        this.add = function(dependent) {
            this.list.splice(0, 0, dependent);
        }.bind(this);

        this.clear = function() {
            this.list([]);
            this.selected(null);
            this.prevSelected(null);
        }.bind(this);

        this.selected.subscribe(function(value) {
            if (value == this.addNewClaimantOption && model.allowToAddDependent) {
                model.currentStepModel(this.withInitialization());
            }

            if (value != this.addNewClaimantOption)
                this.prevSelected(this.selected());
            
            model.expense.Claimant(value.CardholderKey);
        }.bind(this));

        this.withInitialization = function () {

            this.addDependentModel = {
                model: {
                    profile: this.profileFullModel.profile.data(),
                    data: {},
                    details: {}
                },
                isEdit: false,
                onSuccessCallback: this.onDependentAdded,
                displayHeader: false,
                asClaimant: true,
                closeFunc: function () {
                    model.setTitle();
                    model.currentStepModel(null);
                    this.selected(this.prevSelected());
                    setTimeout(model.updateHeight, 20);
                }.bind(this)
            };

            model.setTitle(this.title);
            return this;
        }.bind(this);
    }

    $.extend(true, $, {
        cdh: {
            models: {
                ExpensesAdd: expensesAddModel
            }
        }
    });
})(jQuery, ko);
