import template from "./eligible-expense-grid.html"

(function ($, ko) {
    ko.components.register('eligible-expense-grid', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new EligibleExpenseGrid(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function EligibleExpenseGrid(params, updateHeight) {
        this.updateHeight = updateHeight;
        this.expenses = params.expenses;
        this.pageSize = params.pageSize || 25;
        this.displayedDataSubscription = updateHeight();
        this.isDataLoading = params.isDataLoading;
        this.messageIfEmpty = params.messageIfEmpty;

        this.eligibleExpenseGrid = ko.pureComputed(function () {
            return showExpenses(this.expenses);
        }, this);

        var showExpenses = function (data) {
            var gridExpenses = [];

            for (var i = 0; i < data.length; i++) {
                var expense = data[i];
                expense.alterRow = i % 2 !== 0;
                expense.eligibleExpenseText = expense.ExpenseDescription ? expense.ExpenseName + " " + "(" + expense.ExpenseDescription + ")" : expense.ExpenseName;
                SetIconsAndTooltips(expense);

                gridExpenses.push(expense);
            }

            return gridExpenses;
        }.bind(this);
    }
    // helper functions below
    function SetIconsAndTooltips (expense) {
        expense.PrimaryIcon = 'eligible_icon';
        expense.AdditionalIcon = 'eligible_icon';

        expense.SkipTooltipInitialization = true;
        expense.TooltipContent = "";
        expense.TooltipContentAdditionally = "";

        if (expense.Eligible){
            expense.PrimaryIcon = "eligible_icon check";
            expense.TooltipContent = $.cdh.languageConstant.getConstant('eligible.detailed-receipt');
        }

        if (expense.EligibleWithLMN) {
            expense.PrimaryIcon = "eligible_icon attn";
            expense.AdditionalIcon = "eligible_icon doc";

            expense.TooltipContent = $.cdh.languageConstant.getConstant('eligible.with-documentation');
            expense.SkipTooltipInitialization = false;
            expense.TooltipContentAdditionally = $.cdh.languageConstant.getConstant('eligible.require-medical-statement')
        }
        if (expense.EligibleWithRx) {
            expense.PrimaryIcon = "eligible_icon attn";
            expense.AdditionalIcon = "eligible_icon rx";

            expense.SkipTooltipInitialization = false;
            expense.TooltipContent = $.cdh.languageConstant.getConstant('eligible.with-documentation');
            expense.TooltipContentAdditionally = $.cdh.languageConstant.getConstant('eligible.require-prescription-detailed-receipt')
        }
        if (expense.InEligible){
            expense.PrimaryIcon = "eligible_icon cross";
            expense.TooltipContent = $.cdh.languageConstant.getConstant('eligible.not-eligible')
        }

        if (expense.PotentiallyEligible){
            expense.PrimaryIcon = "eligible_icon quest";
            expense.TooltipContent = $.cdh.languageConstant.getConstant('eligible.potentially-eligible')
        }
    }
})(jQuery, ko);