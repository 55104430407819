import template from './urgent-care-list.html';

ko.components.register('urgent-care-list', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new UrgentCareListModel(widgetInfo.updateHeight);
        }
    }
});

export class UrgentCareListModel {
    constructor(updateHeight) {
        const self = this;

        self.participantService = new $.cdh.http.smartAccount.ParticipantService();
        self.urgentCareService = new $.cdh.http.smartAccount.vendors.UrgentCareService();
        self.disabled = false;
        self.zipCode = ko.observable($.cdh.helper.urlParam('zipcode'));
        self.distance = ko.observable(parseInt($.cdh.helper.urlParam('distance')));
        self.defaultDistance = ko.observable(parseInt($.cdh.helper.urlParam('distance')));
        self.urgentCares = ko.observableArray();

        self.searchURL = ko.pureComputed(function () {
            return $.cdh.routes.predefined.findCare.pageList + '?zipcode=' + self.zipCode() + '&distance=' + self.distance();
        });
        self.ratings = ko.observableArray([
            1, 2, 3, 4, 5
        ]);

        self.typeOfCare = ko.observable("");


        self.isProvidersEmpty = ko.observable(false);
        self.isFilterAvailable = ko.observable($.cdh.helper.urlParam('filterAvailable'));
        self.ratingFilter = ko.observable($.cdh.helper.urlParam('filteredRating'));

        self.distanceFilter = ko.observable($.cdh.helper.urlParam('filteredDistance'));
        self.currentPage = Number($.cdh.helper.urlParam('pageNo')) || 1;
        self.selectedPlan = ko.observable()
        self.carrierName = ko.observable();
        self.totalCount = ko.observable();

        self.pageStartIndex = ko.observable();
        self.pageEndIndex = ko.observable();
        self.isRatingFilterApplied = ko.observable(false);
        self.isDistanceilterApplied = ko.observable(false);




        self.distances = ko.observableArray([
            5, 10, 15, 20, 25, 50, 75, 100
        ]);
        self.costIndexes = ko.observableArray([
            1, 2, 3, 4, 5
        ]);

        if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
            this.isFilterAvailable(false);
            self.ratingFilter(null);
            self.distanceFilter(null);


            $.cdh.helper.deleteUrlParam('filteredRating');
            $.cdh.helper.deleteUrlParam('filteredDistance');
        }

        self.saveRatingFilter = () => {
            this.isFilterAvailable(true);
            this.isRatingFilterApplied(true);
            this.paginatorModel.pageNo(1);
            $.cdh.helper.setUrlParam('filteredRating', this.ratingFilter());
            self.loadData(self.currentPage);
            self.closeAllDropdowns();
        };
        self.saveDistanceFilter = () => {
            this.isFilterAvailable(true);
            this.isDistanceilterApplied(true);
            this.paginatorModel.pageNo(1);
            $.cdh.helper.setUrlParam('filteredDistance', this.distanceFilter());
            self.loadData(self.currentPage);
            self.closeAllDropdowns();
        };

        self.clear = () => {
            this.isFilterAvailable(false);
            self.ratingFilter(null);
            self.distanceFilter(null);
            
            
            $.cdh.helper.deleteUrlParam('filteredRating');
            $.cdh.helper.deleteUrlParam('filteredDistance');
            self.loadData(self.currentPage);
        }
        self.clearRatingFilter = () => {
            self.ratingFilter(null);
            $.cdh.helper.deleteUrlParam('filteredRating');
            self.loadData(self.currentPage);
            self.closeAllDropdowns();
        };
        self.clearDistanceFilter = () => {
            self.distanceFilter(null);
            $.cdh.helper.deleteUrlParam('filteredDistance');
            self.loadData(self.currentPage);
            self.closeAllDropdowns();
        };

        self.currentPlanList = ko.observableArray();

        self.updateHeight = updateHeight;



        self.displayZipCode = ko.pureComputed(
            () => self.zipCode() || $.cdh.languageConstant.getConstant("find-care.enter-zipcode")
        );
        self.closeAllDropdowns = function () {
            $('div.data-filter__item--opened').removeClass('data-filter__item--opened').removeClass('data-filter__item--opened--left');
        };
        self.dropdownMenuItemRendered = function (elOrArr, data, itemsKey, options = {}) {
            const element = Array.isArray(elOrArr) ? elOrArr.filter(el => el && el.nodeType && el.nodeType === 1)[0] : element;
            if (!element || !itemsKey || !this[itemsKey]) return;
            const $menuList = $(element).closest('ul');
            const $items = $menuList.find('> li');
            const allItems = this[itemsKey]();
            if ($items.length === allItems.length) {
                $.cdh.helper.initMenuAccesibility($menuList[0], options);
                $.cdh.helper.initMenuNavigation($menuList[0], options);
            }
        }
        self.headerText = ko.pureComputed(
            () => {
                return "Results for Urgent Care Facility near";
            }
        );


        function load() {
            self.participantService.getDetailsAsync().then(function (result) {
                let data = result.data();
                self.disabled = data.FindCareDisabled;

                if (!self.disabled)
                    self.loadData(self.currentPage);
            });
        }
        load();

        self.paginatorModel = {
            pageNo: ko.observable(),
            totalPages: ko.observable(),
            onChanged: (currentPage) => {

                self.loadData(currentPage, self.distance);
            }
        };
    }

    async loadData(currentPage) {

        this.paginatorModel.pageNo(currentPage || 1);

        $.cdh.helper.setUrlParam('pageNo', this.paginatorModel.pageNo());
        let urgentCarePageParams = {
            pageNo: this.paginatorModel.pageNo(),
            pageSize: 4,
            zipCode: this.zipCode(),
            distance: this.distance()

        }
        if (this.isFilterAvailable()) {
            urgentCarePageParams.rating = this.ratingFilter;            
            this.distanceFilter() ? urgentCarePageParams.distance = this.distanceFilter : urgentCarePageParams.distance = this.distance();
        }
        const result = await this.urgentCareService.UrgentCare(urgentCarePageParams);

        let urgentCareList = result.xhr.responseJSON.Page.Data;

        this.urgentCares(urgentCareList);
        urgentCareList.length == 0 ? this.isProvidersEmpty(true) : this.isProvidersEmpty(false);
        $.cdh.helper.deleteUrlParam('filterAvailable');
        let totalPages = result.xhr.responseJSON.Page.TotalPages;
        this.totalCount(result.xhr.responseJSON.Page.Total);
        this.pageStartIndex(((result.xhr.responseJSON.Page.Offset) * (result.xhr.responseJSON.Page.Limit)) + 1);
        this.urgentCares().length < 4 ? this.pageEndIndex(this.urgentCares().length) : this.pageEndIndex(this.pageStartIndex() + 3);
        this.paginatorModel.totalPages(totalPages);
    }

}

