(function (module, $) {
    var listKey = "list";
    var messageKey = "";
    var preferencesKey = "preferences";

    var getDefaultView = function() {
        var streamline = $.cdh.helper.urlParam("streamline");

        return streamline != null && streamline === "true" ? preferencesKey : listKey;
    };


    module.PageNavigator = function(specifics) {
        var activeView;

        var self = this;

        var updatePageTitle = function () {
            var title;
            if (activeView === listKey) {
                title = specifics.messages(activeView + messageKey);                
            } else {
                title = specifics.messages(activeView);
            }            

            $.cdh.helper.title(specifics.messages('title' + messageKey));
            $.cdh.helper.titlePage(title);            
        };


        var goTo = function (viewName) {

            activeView = viewName;

            updatePageTitle();
            
            if (self.viewChanged != null)
                self.viewChanged(activeView);            
        }
        
        self.views = {
            list: listKey,
            preferences: preferencesKey
        };
        self.viewChanged = null;

        self.goToList = function () {
            goTo(listKey);
        }
        self.goToPreferences = function () {
            goTo(preferencesKey);
        }

        self.initialize = function () {            

            module.postbox.subscribe(function (data) {
                if (data && data.IsVeba) {
                        messageKey = '-veba';
                    } else {
                        messageKey = '';
                    }

                    updatePageTitle();
                },
                self,
                module.topics.accountLoaded
            );

            goTo(getDefaultView());
        }
    };

})(window.docDelivery, window.jQuery);