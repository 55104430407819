/* Script for profile page */

(function (ko) {
    var profileFull = function (updateHeight) {
        var $cdh = $.cdh;

        let page = $.cdh.helper.urlParam('page');
        this.forceReimbursementPopup = page === 'ReimbursementMethod';
        
        this.updateDependentList = ko.observable(false);
        this.profile = new $cdh.get.profile.get(function (data) {
            return new $.cdh.models.Profile(data, this, updateHeight, this.updateDependentList, loadProfile, this.forceReimbursementPopup);
        }.bind(this));

        var loadProfile = function () {
            this.forceReimbursementPopup = false;
            this.profile.load();
        }.bind(this);

        this.states = new $cdh.get.scontent.states(function (result) {
            var states = $.map(result, function (value, key) {
                return { key: key, value: value };
            });
            return states;
        });

        this.countries = new $cdh.get.scontent.countries(function (result) {
            var countries = $.map(result, function (value, key) {
                return { key: key, value: value };
            });
            return countries;
        });

        this.profile.state.subscribe(updateHeight);

        this.profile.load();
        this.states.load();
        this.countries.load();
    };

    $.extend(true, $, {
        cdh: {
            models: {
                ProfileFull: profileFull
            }
        }
    });

    ko.components.register('profile-full', {
        template: { element: 'template-profile-full' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new profileFull(updateHeight);
            }
        }
    });
})(window.ko);
