(function ($, ko) {
    var addProviderModel = function(created, cancel) {

        this.enableValidation = ko.observable(false);

        this.model = {
            ProviderName: ko.observable('').extend({ required: { onlyIf: this.enableValidation } }),
            Addr1: ko.observable('').extend({ required: { onlyIf: this.enableValidation } }),
            Addr2: ko.observable(''),
            City: ko.observable('').extend({ required: { onlyIf: this.enableValidation } }),
            State: ko.observable('').extend({ required: { onlyIf: this.enableValidation } }),
            Zip: ko.observable('').extend({
                required: { onlyIf: this.enableValidation },
                zipCode: true
            }),
            Phone: ko.observable('').extend({ phoneUS: { onlyIf: this.enableValidation } }),
            PayProviderKey: ko.observable(-1),
            ProviderId: ko.observable('')
        };

        this.validation = new $.cdh.validation(this.model);
        this.addProviderRequest = new $.cdh.post.payments.addProvider();

        this.validation.activated.subscribe(this.enableValidation);
        
        this.states = new $.cdh.get.scontent.states(function (result) {
            var states = $.map(result, function (value, key) {
                return { key: key, value: value };
            });
            return states;
        });
        
        this.reset = function(model) {
            this.validation.activated(false);
            model = model || {};
            this.model.ProviderName(model.ProviderName || '');
            this.model.Addr1(model.Addr1 || '');
            this.model.Addr2(model.Addr2 || '');
            this.model.City(model.City || '');
            this.model.State(model.State || '');
            this.model.Zip(model.Zip || '');
            this.model.Phone(model.Phone || '');
            this.model.ProviderId(model.ProviderId || '')
        }.bind(this);

        this.payProviderReadonly = ko.observable(false);
        this.createAutoPopulatedProvider = ko.observable(false);

        this.cancelAddProvider = cancel; 

        this.saveNewProvider = function () {
            this.validation.activate();

            if (!this.canSubmitNewProvider())
                return;

            this.addProviderRequest
                .load(ko.toJS(this.model))
                .done(created);
        }.bind(this);

        this.canSubmitNewProvider = ko.pureComputed(function() {
            return !this.addProviderRequest.isLoading() &&
                !this.validation.hasErrors();
        }, this);

        this.load = function() {
            this.states.loadIfNoData();
        }.bind(this);

    }
    
    $.extend(true, $, {
        cdh: {
            models: {
                AddProviderModel: addProviderModel
            }
        }
    });
})(jQuery, ko);