import { DataServiceBase } from "../../DataServiceBase";

(function ($, ko) {

    class UrgentCareService extends DataServiceBase {
        constructor() {
            super('/SmartAccount/Vendors/FindCare');
        }

        UrgentCare(UrgentCarePageParams) {
            let SearchParams = {

                Distance: UrgentCarePageParams.distance,
                Address: UrgentCarePageParams.zipCode,             
                MinRating: UrgentCarePageParams.rating
            }
            let PageParams = {
                PageNo: UrgentCarePageParams.pageNo,
                PageSize: UrgentCarePageParams.pageSize,
            }
            let Params = {
                PageParams: PageParams,
                SearchParams: SearchParams
            }
            return this.getAsync(Params, "UrgentCare");
        };


    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                vendors: {
                    UrgentCareService: UrgentCareService
                }
            }
        }
    });

})(jQuery, ko);