import template from "./ineligible-balance.html"

(function ($, ko) {
    ko.components.register('ineligible-balance', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new IneligibleBalance(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function IneligibleBalance(params, updateHeight) {
        const self = this;
        self.detailsRequest = new $.cdh.get.repayment.details();
        self.accountActivityBalanceDueRequest = new $.cdh.get.accountActivity.getBalanceDue();
        self.profileReqest = new $.cdh.get.profile.get();
        self.repaymentRequest = new $.cdh.get.repayment.getRepayedTransactions();
        self.submitMultipleClaimForMarkingRequest = new $.cdh.post.claims.MultipleFxClaimMarkAsPaid();
        self.disableFilters = ko.pureComputed(() => self.submitMultipleClaimForMarkingRequest.isLoading());
        
        self.profile = ko.observable();
        self.onlineRepaymentAllowed = ko.observable(false);
        self.balanceDue = ko.observable();
        self.directDeposit = ko.observable();
        self.avoidOverPaymentTips = ko.observable();
        self.transactions = ko.observableArray();
        self.RepaymentTransactions = ko.observableArray();
        self.processingRepaymentTransactions = ko.observableArray();
        self.nonProcessingRepaymentTransactions = ko.observableArray();
        self.years = ko.observableArray();
        self.accountDescriptions = ko.observableArray();
        self.selectedClaimsForMarking = ko.observableArray();
        self.filterMarkAsPaid = ko.observable(false);
        self.claimForMarkingAvailable = ko.observable(false);
        self.submitEnabled = ko.observable(false);
        let removeUrlParams = false;

        self.filter = {
            simplifiedStatus: ko.observable(),
        };

        const urlParams = {
            page: $.cdh.helper.urlParam('page')
        }

        self.clearSelectedClaimsAndDisableSelect = function () {
            self.selectedClaimsForMarking([]);
            self.submitEnabled(false);
        }

        self.forceAddReceipt = ko.observable(urlParams.page === 'AddReceipt' &&
            (urlParams.claimKey || urlParams.claimId || urlParams.expenseKeys || urlParams.transactionKey || urlParams.settlementDate));

        self.cssClass = ko.observable();
        self.activityItems = ko.observableArray();
        self.accountsLoaded = ko.observable(false);
        self.expandItem = urlParams?.claimId?.length > 0 ? ko.observable(true) : ko.observable(false);
        self.loadData = function () {
            if(self.disableFilters())
            {
                return;
            }

            if (removeUrlParams) {
                $.cdh.helper.deleteUrlParam('page');
                $.cdh.helper.deleteUrlParam('claimKey');
                $.cdh.helper.deleteUrlParam('extClaimId');
                $.cdh.helper.deleteUrlParam('transactionKey');
                $.cdh.helper.deleteUrlParam('settlementDate');
                $.cdh.helper.deleteUrlParam('expenseKeys');
                $.cdh.helper.deleteUrlParam('typeCode');
                removeUrlParams = false;
            }
        }

        self.isBankExist = ko.pureComputed(function () {
            return self.directDeposit();
        });
        
        self.bankValidationEnabled = ko.pureComputed(function() {
            return self.profile() && self.profile().BankAccountValidationStatus !== $.cdh.enums.bankAccountValidationStatus.none
        });
        
        self.isBankActive = ko.pureComputed(function () {
            return self.isBankExist() && self.directDeposit().validationStatus === $.cdh.enums.bankAccountValidationStatus.active;
        });
        
        self.isBalanceDueGreaterThanZero = ko.pureComputed(function () {
            return self.balanceDue() > 0;
        });
        
        self.profileReqest.load().done(function(data){
            self.profile(data);
        });
        
        self.detailsRequest.load().done(data => {

            self.onlineRepaymentAllowed(data.OnlineRepaymentAllowed);
            self.balanceDue(data.BalanceDue);
            
            if (data.OnlineRepaymentAllowed) {

                self.avoidOverPaymentTips(data.AvoidOverPaymentTips);

                if (data.DirectDeposit) {
                    self.directDeposit({
                        bank: data.DirectDeposit.Bank,
                        bankAccountNumber: data.DirectDeposit.BankAccountNumber,
                        validationStatus: data.DirectDeposit.ValidationStatus
                    });
                }
            }
        });
        self.accountActivityBalanceDueRequest.load().done(activities => {
            activities.forEach(data => {
                data.FullAmount = data.BalanceDue;
            });
            self.transactions(activities);
            updateHeight();
        });

        self.repaymentRequest.load().done(function (data) {
            self.RepaymentTransactions(data);
            data.forEach(item => {
                item.Status = getPortalRepaymentStatus(item.Status);
                item.cssClass = getCssClassBasedOnStatus(item.Status);
                item["statusCode"] = getStatusCode(item.Status);
            });

            let prTrans = data.filter(data => data.Status == 'Processing');
            let nprTrans = data.filter(data => data.Status != 'Processing');

            prTrans.sort((a, b) => new Date(b.RequestDate) - new Date(a.RequestDate));
            nprTrans.sort((a, b) => new Date(b.RequestDate) - new Date(a.RequestDate));


            self.processingRepaymentTransactions(prTrans);
            self.nonProcessingRepaymentTransactions(nprTrans);

        });

        function getCssClassBasedOnStatus(status) {
            let cssClass = "claims-bl_sheet_row state_no-dropdown claims-bl_sheet_row-no-border ";
            if (status.indexOf("Paid") > -1)
                cssClass += "color";
            else if (status.indexOf("Processing") > -1) 
                cssClass += "color2";
            else if (status.indexOf("Failed") > -1) 
                cssClass += "color3";
            else 
                cssClass += "color5";

            return cssClass;
        }

        function getStatusCode(status){
            let statusCode = "1";
            if (status.indexOf("Paid") > -1)
                statusCode = "1"
            else if (status.indexOf("Processing") > -1) 
                statusCode = "2";
            else if (status.indexOf("Failed") > -1) 
                statusCode = "3";
            else 
                statusCode = "5";

            return statusCode;
        }

        function getPortalRepaymentStatus(status) {
            var wcpStatus;
            if ((status & $.cdh.enums.repaymentStatus.initialStepComplete || status == $.cdh.enums.repaymentStatus.new) && !(status & $.cdh.enums.repaymentStatus.finalStepComplete || status & $.cdh.enums.repaymentStatus.achfailure || status & $.cdh.enums.repaymentStatus.failureComplete))
                wcpStatus = "Processing";
            else if ((status & $.cdh.enums.repaymentStatus.finalStepComplete && status & $.cdh.enums.repaymentStatus.initialStepComplete) && !(status & $.cdh.enums.repaymentStatus.achfailure || status & $.cdh.enums.repaymentStatus.failureComplete))
                wcpStatus = "Paid";
            else if (status & $.cdh.enums.repaymentStatus.achfailure || status & $.cdh.enums.repaymentStatus.failureComplete)
                wcpStatus = "Failed";
            else
                wcpStatus = "No Action";
            return wcpStatus;
        }
    }

})(jQuery, ko);