(function ($, ko) {
    var faqModel = function(updateHeight) {
        this.mini = ko.observable(false);
        this.faq = new $.cdh.get.resources.faq();

        this.faq.load();
        this.faq.state.subscribe(updateHeight);

        this.afterRender = function () {
            var item = $(".faq-item .title");
            item.prop('onclick', null).off('click');
            item.off('keypress');
            item.click(function () {
                toggleItem(this);
            });
            item.keypress(function (event) {
                if (event.keyCode === 13 || event.keyCode === 32) {
                    toggleItem(this);
                    return false;
                }
            });
            function toggleItem(faq) {
                $(faq).attr('aria-expanded', $(faq).attr('aria-expanded') === 'false' ? 'true' : 'false');
                $(faq).parent('.faq-item').toggleClass('open');
                updateHeight();
            };

            $.cdh.helper.setAllVideoIframesRatio();
        };
    };

    ko.components.register('faq', {
        template: { element: 'template-resources-faq' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new faqModel(updateHeight);
            }
        }
    });
})(jQuery, ko);
