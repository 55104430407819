(function ($, ko) {
    var submitClaimWidgetModel = function (onSuccess, updateHeight, expense) {
        
        this.updateHeight = updateHeight;
        this.onSuccess = onSuccess;
        this.expense = expense;
        this.entry = null;
        this.displayHeader = ko.observable(true);
        
        this.title = this.expense ? $.cdh.customLabels.requestReimbursementButton : $.cdh.helper.titlePage();
        if (!this.title || this.title.length === 0)
            this.title = $.cdh.languageConstant.getConstant('title.claim-immediate-reimbursement');
        
        this.forAccountId = null;
        
        this.widget = new $.cdh.post.claims.submitClaimWidgetData();
        
        this.currentStepModel = ko.observable(null);
        
        this.initialize = function () {
            this.widget.load({
                AccountId: this.forAccountId,
                ItemId: this.expense ? this.expense.ItemId : null});
            
            this.widget.data.subscribe(function(){
                var receipt = this.widget.data().ExpenseReceipt;
                if (receipt !== null) {
                    this.widget.data().ExpenseReceipt = new $.cdh.models.fileModel("data:" + receipt.ContentType + ";base64," + receipt.Base64, receipt.FileName, receipt.FileSize);
                }
                this.currentStepModel(new entryStepModel(this));
            }, this);
        }.bind(this);

        this.setClose = function (closer) {
            this.close = closer;
        }.bind(this);

        this.isEntryHasAddClaimantView = ko.pureComputed(function(){
            return this.isEntryView() && this.currentStepModel().isEntryHasAddClaimantView();
        }, this);
        
        this.isEntryView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isEntryStepView;
        }, this);
        
        this.isValidationSelectionView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isValidationSelectionView;
        }, this);
        
        this.isConfirmationView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isConfirmationView;
        }, this);
    };
    
    var entryStepModel = function(model){
        this.isEntryStepView = true;
        this.widget = model.widget;
        this.entry = model.entry;
        this.isEntryHasAddClaimantView = ko.observable(false);
        
        this.onEntryViewChanged = function(view){
            this.isEntryHasAddClaimantView(view === "AddClaimantView");
        }.bind(this);
        
        this.onNext = function(entry){
            model.entry = entry;
            model.currentStepModel(new validationSelectionStepModel(model));
            setTimeout(model.updateHeight, 600);
        }.bind(this);
        
        this.onCancel = function(){
            model.close();
        }.bind(this);
    };
    
    var validationSelectionStepModel = function(model){
        this.isValidationSelectionView = true;
        this.widget = model.widget;
        this.entry = model.entry;
        this.name = "";
        this.data = null;
        
        this.onNext = function(name, data){
            this.name = name;
            this.data = data;
            model.currentStepModel(new confirmationStepModel(model, this));
            setTimeout(model.updateHeight, 600);
        }.bind(this);
        
        this.onCancel = function(){
            model.currentStepModel(new entryStepModel(model));
            setTimeout(model.updateHeight, 600);
        }.bind(this);
        
        if(this.widget.data().SkipValidation){
            model.currentStepModel(new confirmationStepModel(model, this));
            setTimeout(model.updateHeight, 600);
        }
    };
    
    var confirmationStepModel = function(model, validationSelected){
        this.isConfirmationView = true;
        this.widget = model.widget;
        this.entry = model.entry;
        this.expense = model.expense;
        this.onSuccess = model.onSuccess;
        this.validationSelected = validationSelected;
        this.isAccountActivity = model.isAccountActivity;
        $.cdh.helper.titlePage(model.title);
        
        this.onCancel = function(){
            if(this.widget.data().SkipValidation)
                model.currentStepModel(new entryStepModel(model));
            else
                model.currentStepModel(new validationSelectionStepModel(model));
            setTimeout(model.updateHeight, 50);
        }.bind(this);
    };

    $.extend(true, $, {
        cdh: {
            models: {
                SubmitClaimWidgetModel: submitClaimWidgetModel
            }
        }
    });
})(jQuery, ko);