import {DataServiceBase} from "../DataServiceBase";

(function ($, ko) {
    
    class PharmacyService extends DataServiceBase {
        constructor() {
            super('/SmartAccount/Participant/Pharmacy');
        }
        setAsync(pharmacy) {
            return this.postAsync(pharmacy);
        }
    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                PharmacyService: PharmacyService
            }
        }
    });
})(jQuery, ko);