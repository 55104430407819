(function($, ko) {

    function baseModel(element, value, staticModels, options, previous) {
        $.extend(true, this, element, options);

        var modelValue = ko.isObservable(value) ? value : ko.observable(ko.unwrap(value));
        modelValue = modelValue.extend({ required: this.IsRequired });
        if (this.type == $.cdh.enums.customTemplateFormatOptions.alphanumeric)
            modelValue = modelValue.extend({ alphanumeric: true });
        if (this.type == $.cdh.enums.customTemplateFormatOptions.numericOnly)
            modelValue = modelValue.extend({ numeric: true });

        this.value = modelValue;

        if (typeof this.value() !== 'string') this.value('');

        this.validation = new $.cdh.validation(this);

        this.model = ko.pureComputed(function() {
            var types = $.cdh.enums.customTemplateFormatOptions;
            switch (this.type) {
            case types.stateDropDown:
                return staticModels.states.data();
            case types.countryDropDown:
                return staticModels.countries.data();
            case types.custom:
                return $.map(this.CustomValues, function(item){ return {key: item.Key, value: item.Value}});
            }
        }, this);

        this.isDropDown = ko.pureComputed(function() {
            var types = $.cdh.enums.customTemplateFormatOptions;
            return !!(this.type & (types.stateDropDown | types.countryDropDown | types.custom));
        }, this);
        
        this.isReadonly = ko.observable(this.IsReadonly);

        this.isVisible = ko.observable(true);

        this.isTextField = ko.pureComputed(function() {
            var types = $.cdh.enums.customTemplateFormatOptions;
            return !!(this.type & (types.text | types.alphanumeric | types.numericOnly));
        }, this);
        
        this.isDateTime = ko.pureComputed(function () {
            var types = $.cdh.enums.customTemplateFormatOptions;
            return !!(this.type & types.dateTime);
        }, this);

        this.optionsCapiton = ko.pureComputed(function() {
            var types = $.cdh.enums.customTemplateFormatOptions;
            switch (this.type) {
            case types.stateDropDown:
                return $.cdh.languageConstant.getConstant("profile.select-state")
            case types.countryDropDown:
                return $.cdh.languageConstant.getConstant("profile.select-country");
            case types.custom:
                return null;
            }
        }, this);
    }

    function specialInfoModel(element, value, staticModels, options, previous) {
        this.prefix = 'sp';
        baseModel.call(this, element, value, staticModels, options, previous);

        this.allVisible = !options.main ? previous.allVisible : ko.pureComputed(function() {
            return this.value().toLowerCase() == 'yes';
        }, this);

        this.previous = options.resetVisibility ? null : previous;

        this.isVisible = ko.pureComputed(function() {
            if (this.previous) {
                var forceVisibility = this.previous.isVisible() &&
                ((this.previous.type | $.cdh.enums.customTemplateFieldOptions.yesNo)
                    ? this.previous.value().toLowerCase() == 'yes'
                    : !!this.previous.value());
                return (!this.IsHidden || forceVisibility) && this.allVisible();
            }
            return !this.IsHidden;
        }, this);
    }

    function otherInfoModel(element, value, staticModels, options, previous) {
        this.prefix = 'ot';
        options = $.extend(true, {}, options, {
            type: element.FormatOptions
        });
        baseModel.call(this, element, value, staticModels, options, previous);
         this.isVisible = ko.pureComputed(function() {
            return !this.IsHidden;
        }, this);
    }
    
    function otherInfoGroupModel(element, value, staticModels, options, previous) {
        otherInfoModel.call(element, value, staticModels, options, previous);
        
    }

    function templateInfoListModel(elements, viewModel, staticModels, settings, fieldName, model, updateHeight) {
        if (!$.isFunction(updateHeight)) throw "The updateHeight is not a function"

        function getData(index) {
            return ((settings || {}).data || [])[index] || {};
        }

        this.items = ko.observableArray([]);
        var previous = null;
        for (var i = settings.start; i <= settings.stop; i++) {
            var postfix = settings.generator ? settings.generator(i) : i;
            var modelPostfix = settings.modelPostfix || "";
            var item = elements[fieldName + postfix];
            if (item.IsHidden) continue;
            var instance = new model(item,
                viewModel[fieldName + postfix + modelPostfix],
                staticModels,
                getData(i - settings.start),
                previous);
            instance.key = fieldName + postfix;
            this.items.push(instance);
            previous = instance;

            instance.isVisible.subscribe(updateHeight);
        }

        this.toModel = function() {
            var model = {};
            for (var i = settings.start; i <= settings.stop; i++) {
                model[fieldName + i] = this.items[i - settings.start].value();
            }
            return model;
        }.bind(this);
        
        this.isEmpty = ko.pureComputed(function () {
            return this.items().length === 0;
        }, this);
    }
    
    function templateInfoListGroupModel(elements, viewModel, staticModels, settings, fieldName, model, updateHeight) {
        templateInfoListModel.call(this, elements, viewModel, staticModels, settings, fieldName, model, updateHeight);
        
        this.groupHeads = {};
        this.groupElements = {};

        this.selectedGroupKey = ko.observable(0).extend({required: true});
        
        this.viewModel = {
            selectedGroupKey: this.selectedGroupKey
        };
        
        this.validation = new $.cdh.validation(this.viewModel);
        
        this.selectedGroupKey.subscribe(function (value) {
            $.each(this.groupHeads, function (i, value) {
                value.value("");
            });
            if (this.groupHeads[value])
                this.groupHeads[value].value(this.groupHeads[value].AliasFieldValue);
        }, this);

        $.each(this.items(), function (i, item) {
            var key = settings.getGroupId(item.key);
            if (item.IsConditionallyRequired) {
                this.groupHeads[key] = item;
                this.groupElements[key] = [];
            } else if (this.groupHeads[key]) {
                this.groupElements[key].push(item);
            }
        }.bind(this));
        
        $.each(this.groupHeads, function (i, item) {
            if (item.AliasFieldValue === item.value()) {
                this.selectedGroupKey(i);
                return true;
            }
        }.bind(this));
        
        this.groupItems = ko.pureComputed(function () {
            var selectedKey = this.selectedGroupKey();
            return this.groupElements[selectedKey];
        }, this);
        this.groupItems.subscribe(updateHeight);
        
        this.nonGroupItems = ko.pureComputed(function () {
            return $.grep(this.items(), function (item) {
                var groupId = settings.getGroupId(item.key);
                return !this.groupHeads[groupId];
            }.bind(this)); 
        }, this);
        
        this.groups = ko.pureComputed(function () {
            return $.map(this.groupHeads, function(item) {
                return {
                    key: settings.getGroupId(item.key), 
                    value: item.LabelText
                };
            });
        }, this);
        
        this.hasGroups = ko.pureComputed(function () {
            return this.groups().length > 0;
        }, this);
        
        this.isValid = ko.computed(function () {
            var result = true;
            $.each(this.nonGroupItems(), function (i, value) {
                result = !value.validation.hasErrors() && result;
            }.bind(this));
            $.each(this.groupItems(), function (i, value) {
                result = !value.validation.hasErrors() && result;
            }.bind(this));
            return result && (!this.validation.hasErrors() || !this.groups());
        }.bind(this));
        
        this.activateValidation = function () {
            this.validation.activate();
            $.each(this.items(), function (i, item) {
                item.validation.activate();
            });
        }.bind(this);
    }

    function templateInfoListNdfModel(elements, viewModel, staticModels, settings, fieldName, model, updateHeight) {
        templateInfoListModel.call(this, elements, viewModel, staticModels, settings, fieldName, model, updateHeight);

        $.each(this.items(), function (i, item) {
            if (viewModel[item.key + "Type"])
                viewModel[item.key + "Type"](item.AliasFieldValue);
        }.bind(this));
    }
    
    $.extend(true, $, {
        cdh: {
            models: {
                SpecialInfoList: function(specialInfo, viewModel, staticModels, updateHeight) {
                    var types = $.cdh.enums.customTemplateFormatOptions;
                    templateInfoListModel.call(this, specialInfo, viewModel, staticModels,
                        {start:2, stop:6, data:[
                            { type: types.yesNo, main: true },
                            { type: types.text, resetVisibility: true },
                            { type: types.text },
                            { type: types.yesNo },
                            { type: types.countryDropDown },
                            ]},
                        "Asf", specialInfoModel, updateHeight)
                },
                OtherSpecialInfoList: function(otherInfo, viewModel, staticModels, updateHeight) {
                    templateInfoListModel.call(this,
                        otherInfo,
                        viewModel,
                        staticModels,
                        { start: 1, stop: 1 },
                        "Asf",
                        otherInfoModel,
                        updateHeight);
                },
                OtherInfoList: function(otherInfo, viewModel, staticModels, updateHeight) {
                    templateInfoListModel.call(this,
                        otherInfo,
                        viewModel,
                        staticModels,
                        { start: 1, stop: 20 },
                        "Am",
                        otherInfoModel,
                        updateHeight);
                },
                OtherInfoAdfList: function (otherInfo, viewModel, staticModels, updateHeight) {
                    templateInfoListGroupModel.call(this,
                        otherInfo,
                        viewModel,
                        staticModels,
                        { 
                            start: 1, 
                            stop: 30, 
                            generator: function (i) { return (Math.floor((i-1)/6)+1) + "FABCDE"[i % 6] },
                            getGroupId: function (key) { var s = key.split(/[A-z]*/); return s.length === 3 ? s[1] : -1; }
                        },
                        "Adf",
                        otherInfoModel,
                        updateHeight);
                },
                OtherInfoNdfList: function(otherInfo, viewModel, staticModels, updateHeight) {
                    templateInfoListNdfModel.call(this,
                        otherInfo,
                        viewModel,
                        staticModels,
                        { start: 1, stop: 3, modelPostfix: "Result" },
                        "Ndf",
                        otherInfoModel,
                        updateHeight);
                },
            }
        }
    });
})(jQuery, ko);