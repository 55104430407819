(function($, ko) {
    var authorizedSignerItemModel = function(dependent, profile, template, isPlanCompleted, updateHeight, onEditSuccessCallback, onDeleteSuccessCallback) {
        $.cdh.models.Dependent.call(this, dependent, profile, updateHeight, onEditSuccessCallback, onDeleteSuccessCallback);
        this.hasPermissionsToEditDependent = $.cdh.user.isAnonymousEnrollmentUser ||
            ($.cdh.employee.hasAccessToProfileDemographicInfo() && $.cdh.employee.hasAccessToDependentDemographicInfo());
        this.detailsRequest = new $.cdh.get.profile.dependentDetails(this.data.DependentId, true);
        this.editModel = new $.cdh.models.addEditEnrollmentDependentModel(this, updateHeight, true, isPlanCompleted, template, onEditSuccessCallback);
        this.loadDependentDetails();
    };

    $.extend(true, $, {
        cdh: {
            models: {
                AuthorizedSignerItem: authorizedSignerItemModel
            }
        }
    });
})(jQuery, ko);