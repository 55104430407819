import template from "./authentication-confirmation.html"

(function ($, ko) {
    ko.components.register('authentication-confirmation', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ConfirmationModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ConfirmationModel(params, updateHeight) {
        this.step = ko.observable(0);

        this.oldTitle = $('title').text();
        this.errorTitle = `Submission Error | ${this.oldTitle}`;
        this.setTitle = title => $('title').text(title);
        this.token = ko.observable();
        this.isTicketErrorShowed = ko.observable(false);
        this.hasContactInfo = ko.observable(false);

        this.IsSmsOnly = ko.observable(false);

        if ($.cdh.user.isSmsOnly) {
            this.IsSmsOnly($.cdh.user.isSmsOnly);
        }
        if (params.IsSmsOnly) {
            this.IsSmsOnly = params.IsSmsOnly;
        }
        this.ticket = ko.observable('');
        if ($.cdh.user.ticket) {
            this.ticket($.cdh.user.ticket);
        } else if(params.ticket) {
            this.ticket(params.ticket);
        }
        this.visibleCannotReceiveCode = params.visibleCannotReceiveCode;
        this.useEmployeeDetails = params.useEmployeeDetails;
        this.disableConfirm = params.disableConfirm;
        this.isLogin = params.isLogin;
        this.completeCallback = params.completeCallback;
        this.cancelCallback = params.cancelCallback;
        this.cancelReturnUrl = params.returnUrl;
        this.profile = params.profile;
        this.ticketExpiredCallback = params.ticketExpiredCallback;
        this.attemptCount = ko.observable(0);
        this.canSubmit = ko.observable(false);
        this.userStatus = params.userStatus;

        if (this.useEmployeeDetails) {
            this.userInfo = new $.cdh.get.profile.get();
        } else {
            this.userInfo = new $.cdh.post.authentication.userInfo();
        }

        this.generateCode = new $.cdh.post.authentication.generateCode();
        this.submitCode = new $.cdh.post.authentication.submitCode();

        this.currentStepModel = ko.observable(null);
        this.confirmationMethods = ko.observableArray();
        this.email = ko.observable();
        this.mobileNumber = ko.observable();
        this.phone = ko.observable();

        this.loadUserInfo = function (ticket) {
            this.userInfo.load({ ticket: ticket }).done(function (data) {
                //Validating Confirmation method has values, if it has then empty the array list               
                if (this.confirmationMethods().length > 0)
                    this.confirmationMethods.removeAll();

                if (!this.IsSmsOnly()) {
                    if (data.Email) {
                        this.confirmationMethods.push({ key: $.cdh.enums.authenticationOutOfBandType.email, value: $.cdh.languageConstant.getConstant('profile.email') });
                        this.email(data.Email);
                        this.canSubmit(true);
                    }
                }
               
                var mobileNumber = data.MobileNum || data.MobileNumber;

                if (mobileNumber) {
                    this.confirmationMethods.push({
                        key: $.cdh.enums.authenticationOutOfBandType.sms,
                        value: $.cdh.languageConstant.getConstant('profile.mobile-phone-sms')
                    });
                    this.mobileNumber(mobileNumber);
                    this.canSubmit(true);
                }

                if (data.Phone) {
                    this.confirmationMethods.push({
                        key: $.cdh.enums.authenticationOutOfBandType.smsPhone,
                        value: $.cdh.languageConstant.getConstant('profile.phone-sms')
                    });
                    this.phone(data.Phone);
                    this.canSubmit(true);
                }



                if(this.confirmationMethods ().length === 0){
                    this.currentStepModel(new profileWarningStepModel(this));
                }
                else
                    this.hasContactInfo(true);

            }.bind(this));
        }.bind(this);

        this.sendCode = function () {
            this.codeValidation.activate();
            if (!this.canContinue())
                return;

            if (this.submitCode.isLoading())
                return;

            this.setTitle(this.oldTitle);
            this.isTicketErrorShowed(true);
            this.submitCode.load({
                ticket: this.ticket(),
                type: this.method(),
                token: this.code()
            }).done(function (data) {
                if (this.isLogin) {
                    $("#submitCodeForm").submit();
                } else {
                    this.token(this.code());
                    this.completeCallback();
                }
            }.bind(this)).fail(function (xhr) {
                if (this.isTicketExpired(xhr)) {
                    this.resetTicket(xhr);
                    this.submitCode.error('');
                } else {
                    this.submitCode.error('');
                    let message = 'User account is locked out.';
                    var contactPhone = $.cdh.brandData.ContactPhone;
                    var contactEmail = $.cdh.brandData.ContactEmail;
                    let errorMessage = " ";
                    if (contactEmail == "" || contactPhone == "") {
                        errorMessage = 'Login Unsuccessful. Please contact us for further assistance ' + contactPhone + contactEmail;
                    }
                    else {
                        errorMessage = 'Login Unsuccessful. Please contact us for further assistance ' + contactPhone + " or " + contactEmail;

                    }
                    if(xhr.responseText.includes(message)){
                        this.submitCode.error(errorMessage);
 /*                       alert(errorMessage);*/
                        $.cdh.helper.logoutWithMessage(errorMessage);
                       /* window.location.replace($.cdh.routes.authentication.handshake())*/;
                    }
                    else
                        this.submitCode.error($.cdh.languageConstant.getConstant('profile.incorrect-code'));
                }
            }.bind(this));
        }.bind(this);

        this.displayOob = function () {
            this.currentStepModel(null);
            this.step(1);
            this.loadUserInfo(this.ticket());
        }.bind(this);

        this.ticketForDirectDepositRequest = new $.cdh.get.authentication.getTicketForDirectDeposit();

        this.loadTicketForDirectDeposit = () => {
            this.ticketForDirectDepositRequest.load()
                .done(ticket => {
                    if (ticket) {
                        if (ticket.IsUserBlocked) {
                            var contactPhone = $.cdh.brandData.ContactPhone;
                            var contactEmail = $.cdh.brandData.ContactEmail;
                            let errorMessage = "";
                            if (contactEmail == "" || contactPhone == "") {
                                errorMessage = 'Login Unsuccessful. Please contact us for further assistance ' + contactPhone + contactEmail;
                            }
                            else {
                                errorMessage = 'Login Unsuccessful. Please contact us for further assistance ' + contactPhone + " or " + contactEmail ;

                            }
                            $.cdh.helper.logoutWithMessage(errorMessage);
                        }
                        this.IsSmsOnly(ticket.IsSmsOnly);
                        if (!ticket.OobRequired) {
                            this.completeCallback();
                            return;
                        }
                        this.ticket(ticket.Ticket);
                        this.displayOob();
                    }
                }).fail(xhr => {
                    let message = 'User account is locked out.';
                    var contactPhone = $.cdh.brandData.ContactPhone;
                    var contactEmail = $.cdh.brandData.ContactEmail;
                    let errorMessage = "";
                    if (contactEmail == "" || contactPhone == "") {
                        errorMessage = 'Login Unsuccessful. Please contact us for further assistance ' + contactPhone + contactEmail;
                    }
                    else {
                        errorMessage = 'Login Unsuccessful. Please contact us for further assistance ' + contactPhone + " or " + contactEmail;

                    }
                    if (xhr.responseText.includes(message)) {
                        this.submitCode.error(errorMessage);
                        $.cdh.helper.logoutWithMessage(errorMessage);
                    }
                });
        };

        this.refreshTicketForLoginRequest = new $.cdh.post.authentication.refreshTicketForLogin();

        this.refreshTicketForLogin = () => {
            this.refreshTicketForLoginRequest.load({ticket: this.ticket()})
                .done(model => {
                    this.ticket(model.Ticket);
                    this.displayOob();
                });
        };

        this.isTicketExpired = (xhr) => xhr.responseJSON && xhr.responseJSON.data && xhr.responseJSON.data.IsTicketExpired;

        this.resetTicket = (xhr) => {
            if (this.isTicketExpired(xhr)) {
                if (this.ticketExpiredCallback) {
                    this.ticketExpiredCallback(xhr);
                } else {
                    this.isTicketErrorShowed(false);
                    alert("Your authentication request has timed out. Click ok to refresh this request.");
                    this.step(0);
                    if (this.isLogin) {
                        this.refreshTicketForLogin();
                    } else {
                        this.loadTicketForDirectDeposit();
                    }
                }
            } else {
                this.isTicketErrorShowed(true);
                updateHeight();
            }
        };

        if (this.ticket()) {
            this.displayOob();
        } else {
            this.loadTicketForDirectDeposit();
        }

        this.userInfo.state.subscribe(updateHeight);
        this.generateCode.state.subscribe(updateHeight);
        this.submitCode.state.subscribe(updateHeight);

        this.method = ko.observable();
        this.code = ko.observable().extend({required: true});

        this.methodLabel = ko.pureComputed(function () {
            return this.method() == $.cdh.enums.authenticationOutOfBandType.sms || $.cdh.enums.authenticationOutOfBandType.smsPhone ? 'Phone Number' : 'Email';
        }, this);

        this.isSmsMethod = ko.pureComputed(function () {
            return this.method() == $.cdh.enums.authenticationOutOfBandType.sms || this.method() == $.cdh.enums.authenticationOutOfBandType.smsPhone;
        }, this);

        this.isEmailMethod = ko.pureComputed(function () {
            return this.method() == $.cdh.enums.authenticationOutOfBandType.email;
        }, this);

        this.displayValue = ko.pureComputed(function () {
            switch (this.method()) {
                case $.cdh.enums.authenticationOutOfBandType.sms:
                    return this.mobileNumber();
                case $.cdh.enums.authenticationOutOfBandType.smsPhone:
                    return this.phone();
                case $.cdh.enums.authenticationOutOfBandType.email:
                    return this.email();
            }
        }, this);

        this.codeValidation = new $.cdh.validation(this.code);

        this.canContinue = ko.pureComputed(function () {
            return !this.codeValidation.activated() || !this.codeValidation.hasErrors();
        }, this);

        this.cancel = function () {
            if (this.cancelCallback && typeof (this.cancelCallback) === typeof (Function)) {
                this.cancelCallback();
            } else {
                window.location.href = this.cancelReturnUrl;
            }
        }.bind(this);

        this.cannotReceiveCode = function () {
            $.cdh.helper.alert2({
                title: $.cdh.languageConstant.getConstant('title.cannot-receive-code'),
                titleVisible: true,
                message: $.cdh.languageConstant.getConstant('messages.contact-administrator'),
                okVisible: true,
                okCallback: () => this.cancel(),
                cancelVisible: false
            });
        }.bind(this);

        this.generate = function () {
            this.step(2);
            this.setTitle(this.oldTitle);
            this.isTicketErrorShowed(true);
            this.generateCode.load({
                type: this.method(),
                ticket: this.ticket()
            }).done(() => {
                if (this.disableConfirm && this.completeCallback) {
                    this.completeCallback({
                        method: this.method(),
                        isSms: this.isSmsMethod(),
                        isEmail: this.isEmailMethod(),
                        email: this.email(),
                        mobileNumber: this.mobileNumber()
                    })
                }
            }).fail(function (xhr) {
                if (this.isTicketExpired(xhr)) {
                    this.resetTicket(xhr);
                    this.generateCode.error('');
                } else {
                    this.setTitle(this.errorTitle);
                    this.step(1);
                }
            }.bind(this));
        }.bind(this);

        this.regenerate = function () {
            this.step(2);
            this.setTitle(this.oldTitle);
            this.isTicketErrorShowed(true);
            this.generateCode.load({
                type: this.method(),
                ticket: this.ticket()
            }).done(function (data) {
                var settings = {
                    title:  $.cdh.languageConstant.getConstant('title.code-resent'),
                    titleVisible: true,
                    message: $.cdh.languageConstant.getConstant('messages.confirmation-code-resent'),
                    okVisible: false,
                    cancelVisible: false
                }; 

                $.cdh.helper.alert2(settings);
            }.bind(this))
                .fail(function (xhr) {
                    if (this.isTicketExpired(xhr)) {
                        this.resetTicket(xhr);
                        this.generateCode.error('');
                    } else {
                        this.setTitle(this.errorTitle);
                        this.step(1);
                    }
                }.bind(this));
        }.bind(this);

        this.isMainView = ko.pureComputed(function () {
            return this.currentStepModel() === null;
        }, this);

        this.isWarningView = ko.pureComputed(function () {
            return this.currentStepModel() != null && this.currentStepModel().isWarningView;
        }, this);
    }

    function profileWarningStepModel(model) {
        this.isWarningView = true;
        this.IsSmsOnly = model.IsSmsOnly;
        this.profile = model.profile;

        this.onContinue = function () {
            model.displayOob();
        };

        this.onCancel = function () {
            model.cancel();
        };
    }
    $(document).on('submit', '#submitCodeForm', function (event) {
        $(event.target).find("button[type='submit']").attr("disabled", true);
    });
})(jQuery, ko);