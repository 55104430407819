import template from "./opportunities-item.html"

ko.components.register('opportunities-item', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new OpportunitiesItem(params, widgetInfo);
        }
    }
});

export class OpportunitiesItem {
    constructor(params, widgetInfo) {
        let model = params.model;
        
        this.id = model.Id;
        this.description = model.Description;
        this.points = model.Points;
        this.redirectUrl = model.RedirectUrl;
        this.isExternalUrl = model.IsExternalUrl;
        this.iconCss = this.getIconCss(model.TypeId);
    }
    
    getIconCss(typeId) {
        let typePart = ""
        switch (typeId) {
            case "LongTermSavings":
            case "MaxOutContributions":
                typePart = "longterm-savings";
                break;
            case "PrescriptionSavings":
            case "SetUpMedicineCabinet":
                typePart = "prescription-savings";
                break;
            case "SwitchToDirectDeposit":
                typePart = "switch-to-dd";
                break;
            case "ChangeElectronicDelivery":
            case "SignUpForElectronicTaxForms":
            case "AddEmailAddress":
                typePart = "email";
                break;
            case "AttachInsuranceCarrier":
                typePart = "plus-only";
                break;
            case "AddMobilePhone":
            case "SignUpForAlerts":
                typePart = "phone";
                break;
            case "AddReceiptToClaim":
                typePart = "add-receipt";
                break;
            case "GeoLocation":
                typePart = "location";
                break;
            case "ProviderSearch":
                typePart = "location-add";
                break
            case "PayIneligibleBalance":
                typePart = "balance-due"
                break;
            case "SpendFsaBalance":
                typePart = "spend-fsa-balance-opportunity"
                break;
        }

        return "opportunities__icon--" + typePart;
    }
}