import template from "./pay-it-back.html"

(function ($, ko) {
    ko.components.register('pay-it-back', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new PayItBack(params, widgetInfo.updateHeight, widgetInfo.remove);
            }
        }
    });

    function PayItBack(params, updateHeight, removeWidget) {
        const self = this;

        self.template = new $.cdh.services.templateExtractor(params.template);
        
        self.title = ko.observable();
        self.content = ko.observable();
        self.isVisible = ko.observable(false);
        
        self.getThresholdRequest = new $.cdh.get.accounts.getThreshold();
        
        function setTitle() {
            var titleLabel = $.cdh.widgetLabels.payItBack.title;
            var title = self.template.getLabel(titleLabel.name, titleLabel.default);
            self.title(title);
        }
        
        function setContent(data) {
            var label = null;
            if(data.BalanceDue < data.ThresholdAmt) {
                label = $.cdh.widgetLabels.payItBack.balanceDueLessThanThreshold;
            }
            else {
                label = $.cdh.widgetLabels.payItBack.balanceDueMoreThanThreshold;
            }

            var template =  self.template.getLabel(label.name, label.default);
            template = $.cdh.helper.htmlEncode(template);
            
            var balanceDueText = $.cdh.helper.htmlFormatAmount(data.BalanceDue);
            var thresholdAmtText = $.cdh.helper.htmlFormatAmount(data.ThresholdAmt);
            var content = label.replace(template, balanceDueText, thresholdAmtText, ['&lt;','&gt;']);

            self.content(content);
        }

        self.getThresholdRequest.load().done(function(data) {
            if(data.BalanceDue > 0) {
                setTitle();
                setContent(data);
                self.isVisible(true);
                
                updateHeight();
            } else {
                removeWidget();
            }
        });
    }

})(jQuery, ko);