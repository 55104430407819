import {DataServiceBase} from "../DataServiceBase";

export class ClaimsPortalService extends DataServiceBase {
    constructor() {
        super(`${$.cdh.global.claimsPortalPath}/api`);
    }

    getSettingsAsync() {
        return this.getAsync(null, 'info/settings');
    }
}