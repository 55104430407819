import template from "./fsa-store.html";
(function ($, ko) {
    ko.components.register('fsa-store', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new FsaStore(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function FsaStore(params, updateHeight) {
        const self = this;

        self.updateHeight = updateHeight;
        
        self.bannerUrl = ko.observable();
        self.targetUrl = ko.observable();
        self.widgetVisible = ko.observable(false);

        self.bannerLoaded = function () {
            setTimeout(self.updateHeight, 600);
        };
        
        self.fsaStoreRequest = new $.cdh.get.accounts.fsaStore();
        self.fsaStoreRequest.load().done(function(data) {
            if(data.Visible) {
                self.targetUrl(data.TargetUrl);
                self.bannerUrl(data.BannerUrl);
                self.widgetVisible(true);

                self.updateHeight();
            }
        });
    }
})(jQuery, ko);