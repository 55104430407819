(function ($, ko) {
    var documentsModel = function (category, updateHeight) {
        this.documents = new $.cdh.get.resources.publicDocuments();

        this.documents.load({category: category});
        this.documents.state.subscribe(updateHeight);

        this.afterRender = function() {
            updateHeight();
        };
    };

    ko.components.register('hsa-public-forms-and-documents', {
        template: { element: 'template-resources-public-documents' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new documentsModel($.cdh.enums.documentCategory.hsa, updateHeight);
            }
        }
    });

    ko.components.register('fsa-public-forms-and-documents', {
        template: { element: 'template-resources-public-documents' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new documentsModel($.cdh.enums.documentCategory.fsa, updateHeight);
            }
        }
    });

    ko.components.register('hra-public-forms-and-documents', {
        template: { element: 'template-resources-public-documents' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new documentsModel($.cdh.enums.documentCategory.hra, updateHeight);
            }
        }
    });
})(jQuery, ko);
