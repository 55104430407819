import {CustomLabels} from "../../../Common/customLabels";
import template from "./accounts-summary-mini-item-hsa.html"
import templateNonHsa from "./accounts-summary-mini-item-non-hsa.html"


(function ($, ko) {
    ko.components.register('accounts-summary-mini-item-hsa', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new AccountsSummaryMiniItemHsa(params, widgetInfo.updateHeight, params.template);
            }
        }
    });

    ko.components.register('accounts-summary-mini-item-non-hsa', {
        template: templateNonHsa,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new AccountsSummaryMiniItemHsa(params, widgetInfo.updateHeight, params.template);
            }
        }
    });

    function AccountsSummaryMiniItemHsa(params, updateHeight, templateInfo) {
        const self = this;

        $.extend(true, self, params.account);

        if (self.AccountType == $.cdh.enums.accountType.hsa) {
            self.RequiredCipDocumentation = ko.observable(false);

            self.AvailableBalanceLabel = $.cdh.languageConstant.getConstant("AccountMiniItem.lblAvailableBal");
            self.PortfolioBalanceLabel = self.HsaAdvAccessibilityFlag ? $.cdh.languageConstant.getConstant("AccountMiniItem.lblHSAAdvance") : $.cdh.languageConstant.getConstant("AccountMiniItem.lblInvestment");

            self.hsaBalanceAvailable = self.AvailableBalance;

            //show electronic delivery proposal
            self.offerChangeStatementDelivery = ko.observable(false);
            self.offerChangeTaxDelivery = ko.observable(false);
            self.offerChangeFeeAmt = ko.observable();

            self.offerChangeDelivery = ko.pureComputed(function () {
                return self.offerChangeStatementDelivery() || self.offerChangeTaxDelivery();
            });

            self.template = new $.cdh.services.templateExtractor(templateInfo);

            self.offerChangeDeliveryText = ko.pureComputed(function () {
                let feeAmt = self.offerChangeFeeAmt();
                if (feeAmt > 0) {
                    if (self.offerChangeTaxDelivery() && self.offerChangeStatementDelivery())
                        return CustomLabels.documentDeliveryPreferences.goGreenAll(feeAmt);

                    if (!self.offerChangeTaxDelivery() && self.offerChangeStatementDelivery())
                        return CustomLabels.documentDeliveryPreferences.goGreenStatements(feeAmt);

                    if (self.offerChangeTaxDelivery() && !self.offerChangeStatementDelivery())
                        return CustomLabels.documentDeliveryPreferences.goGreenTaxForms(feeAmt);
                }

                return "";
            });

            self.hsa = new $.cdh.get.accounts.detailsHsa(self.AccountId);
            self.hsa.load().done(function (details) {
                self.offerChangeFeeAmt(details.StatementFeeAmt);

                if (details.TaxFormsDeliveryOption === $.cdh.enums.taxFormsPaperOrElectronic.paper && self.offerChangeFeeAmt() > 0)
                    self.offerChangeTaxDelivery(true);

                if (details.StatementsDeliveryOption === $.cdh.enums.statementDeliveryOption.electronicAndPaper && self.offerChangeFeeAmt() > 0)
                    self.offerChangeStatementDelivery(true);

                self.uploadDocumentQueuePopupModel = new $.cdh.models.UploadDocumentQueuePopupModel(self.FlexAcctId, updateHeight);
                self.RequiredCipDocumentation(details.RequiredCipDocumentation);

                updateHeight();
            });
        }

        else {
            $.extend(true, self, params);

            self.accountextendedDetails = ko.observable();
            self.accountIntervalType = ko.observable(false);
            self.IsFutureYearPlan = (self.AccountPlanYear === $.cdh.enums.planYear.future);

            self.PayrollHeader = ko.observable("Payroll Deposits YTD");
            self.currentInvervalPeriod = ko.observable(null);
            self.currentIntervalBalance = ko.observable(self.accountextendedDetails.AvailableBalance);
            self.currentIntervalDeposits = ko.observable(null);
            self.currentIntervalDisbursed = ko.observable(null);
            self.currentIntervalRemaining = ko.observable(null);
            self.currentSpendingLimit = ko.observable(null);

            self.extendedIntervalDetails = new $.cdh.get.accounts.intervalTracker(params.AccountId);

            var currentMonth = String($.cdh.helper.getCurrentMonth('long') + ' ' + $.cdh.helper.getCurrentYear());
            var currentQuarter = $.cdh.helper.getCurrentQuarter() + '(' + $.cdh.helper.getCurrentQuarterDates() + ')';
            var monthIntervalVal = '1';
            var quarterIntervalVal = '3';

            self.IsMonthOrQuarter = ko.observable(false);
            self.IsMonthOrQuarter = (self.SpendingLimitPeriod == monthIntervalVal || self.SpendingLimitPeriod == quarterIntervalVal);
            if (self.IsMonthOrQuarter) {
                self.extendedIntervalDetails.state.subscribe(updateHeight);

                self.extendedIntervalDetails.load().done(function (data) {
                    if (self.AccountPlanYear === $.cdh.enums.planYear.current || self.AccountPlanYear === $.cdh.enums.planYear.previous) {
                        if (data.length) {
                            if (data[data.length - 1].IntervalType == monthIntervalVal || data[data.length - 1].IntervalType == quarterIntervalVal) {
                                self.currentInvervalPeriod(data[data.length - 1].IntervalPeriod);
                                self.currentIntervalBalance(data[data.length - 1].IntervalBalance);
                                self.currentIntervalDeposits(data[data.length - 1].IntervalDeposits);
                                self.currentIntervalDisbursed(data[data.length - 1].IntervalDisbursed);
                                self.currentIntervalRemaining(data[data.length - 1].IntervalRemaining);
                                self.currentSpendingLimit(data[data.length - 1].SpendingLimit);

                                if (data[data.length - 1].IntervalType == monthIntervalVal) {
                                    self.PayrollHeader("Payroll Deposits MTD");
                                }
                                else if (data[data.length - 1].IntervalType == quarterIntervalVal) {
                                    self.PayrollHeader("Payroll Deposits QTD");
                                }
                                self.accountIntervalType(true);
                            }
                        }
                    }
                        /* The below code for future plans is for this below requirement:
                         * User Story 222061: Quarterly/Monthly Accounts page Year dropdown
                         * Given: I am a WCP user on Account Summary
                         * When: I click on the Future year 
                         * Then: Accounts details should display of Future year First Quarter/Month
                         * 
                         * Since the interval tracker details for Future DCA/FSN plans are not being sent in API response, validation for below code is not yet done.
                         * This needs to be validated once the WCA response has interval tracker details for future DCA/FSN plans
                         * 
                         * Bug 304299: [Interval Tracker]Interval tracker feature plans graph not loading for Monthly/Quarterly
                         * Displaying $--.-- for amount fields since WCA response will be empty for interval tracker details for future plans
                         * */
                    else if (self.AccountPlanYear === $.cdh.enums.planYear.future) {
                        self.currentInvervalPeriod(0);
                        self.currentIntervalBalance(0);
                        self.currentIntervalDeposits(0);
                        self.currentIntervalDisbursed(0);
                        self.currentIntervalRemaining(0);
                        self.currentSpendingLimit(0);

                        if (self.SpendingLimitPeriod == monthIntervalVal) {
                            self.PayrollHeader("Payroll Deposits MTD");
                        }
                        else if (self.SpendingLimitPeriod == quarterIntervalVal) {
                            self.PayrollHeader("Payroll Deposits QTD");
                        }
                        self.accountIntervalType(true);
                    }                    
                });
            }
        }
    }
})
    (jQuery, ko);