(function ($, ko) {
    var profileMini = function (updateHeight) {
        this.hasPermissionsToEditEmail = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateEmailAddress);
        this.hasPermissionsToUpdateDeposit = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateDirectDepositInformation);
        this.hasPermissionsToUpdateCommunication = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateParticipantCommunication);
        this.hasPermissionsToViewDeposit = $.cdh.employee.allowedToViewDirectDepositInformation();
        this.hasPermissionsToUpdateReimbursments =
            this.hasPermissionsToUpdateDeposit && this.hasPermissionsToViewDeposit;
        
        this.permissionCount = (this.hasPermissionsToEditEmail ? 1 : 0) + (this.hasPermissionsToUpdateReimbursments ? 1 : 0) + (this.hasPermissionsToUpdateCommunication ? 1 : 0);
        this.procentValue = 100 / (2 + this.permissionCount);
       
        this.pictureComplete = ko.observable(0);
        this.mobileComplete = ko.observable(0);
        this.emailComplete = ko.observable(0);
        this.reimbMethodComplete = ko.observable(0);
        this.optAlertsComplete = ko.observable(0);
        this.canShowMobileNumber = ko.observable(true);
        this.campaignPhoneNumber = ko.observable();

        this.summary = ko.pureComputed(function () {
            return this.pictureComplete() +
                this.mobileComplete() +
                (this.hasPermissionsToEditEmail ? this.emailComplete() : 0) +
                (this.hasPermissionsToUpdateReimbursments ? this.reimbMethodComplete() : 0) +
                (this.hasPermissionsToUpdateCommunication ? this.optAlertsComplete() : 0);
        }, this);
        this.isSummaryIncomplete = ko.pureComputed(function () {
            return Math.round(this.summary()) < 99;
        }, this);

        this.mobileFilled = ko.pureComputed(function () {
            return this.mobileComplete() !== this.procentValue ? 'not-filled' : '';
        }, this);

        this.emailFilled = ko.pureComputed(function () {
            return this.emailComplete() !== this.procentValue ? 'not-filled' : '';
        }, this);

        this.reimbMethodFilled = ko.pureComputed(function () {
            return this.reimbMethodComplete() !== this.procentValue ? 'not-filled' : '';
        }, this);

        this.optAlertsFilled = ko.pureComputed(function () {
            return this.optAlertsComplete() !== this.procentValue ? 'not-filled' : '';
        }, this);
        
        this.user = {
            firstName: ko.observable(''),
            lastName: ko.observable(''),
            email: ko.observable(''),
            phone: ko.observable(''),
            reimbMethod: ko.observable(0),
            optAlerts: ko.observable(0),

            fullName: ko.pureComputed(function () { return this.user.firstName() + ' ' + this.user.lastName() }, this)
        }

        this.editUserPic = new $.cdh.models.editUserPictureModel(null,
            function() {
                this.pictureComplete(this.procentValue);
            }.bind(this));

        this.reimbMethodName = ko.pureComputed(function () {
            if (this.user.reimbMethod() === 1) {
                this.reimbMethodComplete(this.procentValue);
                return $.cdh.languageConstant.getConstant("filter.check");
            }

            if (this.user.reimbMethod() === 2) {
                this.reimbMethodComplete(this.procentValue);
                return $.cdh.languageConstant.getConstant("filter.direct-deposit");
            }

            this.reimbMethodComplete(0);
        }, this);


        this.getUser = new $.cdh.get.profile.get(function (data) {
            if (data.Email !== '') {
                this.emailComplete(this.procentValue);
                this.user.email(data.Email);
            }
            this.user.firstName(data.FirstName);
            this.user.lastName(data.LastName);
            this.user.reimbMethod(data.ReimbursementMethod);
           
            this.editReimbursementModel = new $.cdh.models.ProfileReimbursementMethodEditPopup(data, false, updateHeight, function (data) {
                this.user.reimbMethod(data);
            }.bind(this));

            this.editEmailModel = new $.cdh.models.editEmailModel(this.user.email,
                function (newEmail) {
                    this.emailComplete(this.procentValue);
                    this.user.email(newEmail);
                }.bind(this));

            if (!$.cdh.userPic.isEmpty()) {
                this.pictureComplete(this.procentValue);
            }
        }.bind(this));
        this.getCampaign = new $.cdh.get.communications.getCampaignDetails(function (details) {
            if (details !== null) {
                this.canShowMobileNumber(details.CanAddNumber);
                this.campaignPhoneNumber(details.CampaignPhoneNumber);
            }
            this.mobileComplete( !details.CanAddNumber ? this.procentValue : 0);
            if (details.CanAddNumber) {
                this.mobileNumbers.load();
            }
        }.bind(this));
        this.mobileNumbers = new $.cdh.get.communications.getMobileNumbers(function (numbers) {
            var result = $.grep(numbers,
                function (number) {
                    return number.MobileStatus == $.cdh.enums.mobileStatus.optIn || number.MobileStatus == $.cdh.enums.mobileStatus.pending;
                }.bind(this));

            if (result.length > 0) {
                this.mobileComplete(this.procentValue);
                this.user.phone($.cdh.helper.maskPhoneNumber(result[0].PhoneNumber));
            }
            this.deleteRequest = new $.cdh.post.communications.deleteMobileNumber();

        }.bind(this));
        
        this.addPhoneNumberCallback = function (data) {
            if (this.user.phone() !== '') {
                this.deleteRequest
                    .load({number: this.user.phone().substring(1)});
            }

            this.mobileComplete(this.procentValue);
            this.user.phone($.cdh.helper.maskPhoneNumber('1' + data));
        }.bind(this);

        this.settings = {};
        this.settingsRequest = new $.cdh.get.communications.getSettings(function (settings) {
            var optCount = $.grep(settings,
                function (setting) {
                    return setting.DeliveryMethod === 0;
                }.bind(this));

            this.user.optAlerts(settings.length - optCount.length);
            if (this.user.optAlerts() > 0) {
                this.optAlertsComplete(this.procentValue);
            }
            $.each(settings, function (i, setting) {
                setting.DeliveryMethod = ko.observable(setting.DeliveryMethod.toString());
            });
            this.settings = settings;
        }.bind(this));

        this.optAlertsModel = new $.cdh.models.CommunicationsSettingsPopup(this.settingsRequest, updateHeight, function (data) {
            var optCount = $.grep(data,
                function (setting) {
                    return parseInt(setting.DeliveryMethod()) === 0;
                }.bind(this));

            this.user.optAlerts(data.length - optCount.length);

            if (this.user.optAlerts() === 0) {
                this.optAlertsComplete(0);
            } else {
                this.optAlertsComplete(this.procentValue);
            }
        }.bind(this));
        
       this.allLoaded = ko.pureComputed(function() {
          return !this.getUser.isLoading()
              && !this.mobileNumbers.isLoading() 
              && !this.settingsRequest.isLoading()
        }, this);

        this.afterRender = function () {
            resizeProfileMini();
        };

        this.settingsRequest.load();
        this.getUser.load();
        this.getCampaign.load();

        this.settingsRequest.state.subscribe(updateHeight);
        this.getUser.state.subscribe(updateHeight);
        this.mobileNumbers.state.subscribe(updateHeight);
    };

    ko.components.register('profile-mini', {
        template: { element: 'template-profile-mini' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new profileMini(updateHeight);
            }
        }
    });
})(jQuery, ko);