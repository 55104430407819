import template from "./communications-add-mobile-number.html";
import {PageUrlModes} from "../Common/pageUrlModes";
import {ParticipantOpportunitiesService} from "../Http/SmartAccount/OpportunitiesService";

ko.components.register('communications-add-mobile-number', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AddMobileNumber(params, componentInfo, widgetInfo)
        }
    }
});

function AddMobileNumber (params) {
    const urlParams = {
        opportunityId: $.cdh.helper.urlParam('opportunityId'),
    };
    
    this.existOpportunityBlock = PageUrlModes.isOpportunity();
    if(this.existOpportunityBlock) {
        PageUrlModes.removeMode(); 
    }
    
    this.registerRequest = new $.cdh.post.communications.registerMobileNumber();

    this.numberParts = {
        first3: ko.observable('').extend({required: true, exactLength: 3}),
        second3: ko.observable('').extend({required: true, exactLength: 3}),
        last4: ko.observable('').extend({required: true, exactLength: 4})
    }

    this.number = ko.pureComputed(function () {
        var p = this.numberParts;
        return p.first3() + p.second3() + p.last4();
    }, this);

    this.campaignPhoneNumber = params.campaignPhoneNumber;
    this.termsAccepted = ko.observable(false).extend({checked: {}});

    this.phoneValidation = new $.cdh.validation(this.numberParts);
    this.termsValidation = new $.cdh.validation(this.termsAccepted);

    this.canSubmit = ko.pureComputed(function () {
        return !this.registerRequest.isLoading() && !this.phoneValidation.hasErrors() && !this.termsValidation.hasErrors();
    }, this);

    this.clickSubmit = function () {
        this.phoneValidation.activate();
        this.termsValidation.activate();
        if (!this.canSubmit())
            return;

        this.registerRequest
            .load({number: this.number()})
            .done(function () {
                if(urlParams.opportunityId) {
                    let opportunitiesService = new ParticipantOpportunitiesService();
                    opportunitiesService.hideAsync(urlParams.opportunityId).then(() => {
                        this.close();
                        if ($.isFunction(params.onSuccess)) {
                            params.onSuccess(this.number());
                        }
                    });
                } else {
                    this.close();
                    if ($.isFunction(params.onSuccess)) {
                        params.onSuccess(this.number());
                    }
                }
            }.bind(this));
    };
    
    this.close = params.closeFunc;

}