import template from './urgent-care-item.html';


(function ($, ko) {

    function UrgentCareItem(params, updateHeight) {

        this.urgentCare = params.model().model;
        this.pageNo = params.model().pageNo || $.cdh.helper.urlParam('pageNo');        
        this.filteredRating = $.cdh.helper.urlParam('filteredRating');       
        this.filteredDistance = $.cdh.helper.urlParam('filteredDistance');      
        this.urgentCare.Rating = Math.ceil((this.urgentCare.Rating / 2));
        
        this.names = ko.pureComputed(
            () => {
                let Name = 'Urgent Care Facility';
                
                return Name;
            }
        );

        
        this.onClick = function () {
            localStorage.setItem('urgent-care-detail', JSON.stringify(this.urgentCare));
            let urgentCareDetailUrl = $.cdh.routes.predefined.findcare.urgentcareDetail + '?pageNo=' + this.pageNo;
            
            let isFilterAvailable = false;
            if ( this.filteredDistance || this.filteredRating) {
                isFilterAvailable = true;
                urgentCareDetailUrl += '&filterAvailable=' + isFilterAvailable;
            }
            
            if (this.filteredRating) {
                urgentCareDetailUrl += '&filteredRating=' + this.filteredRating;
            }
            if (this.filteredDistance) {
                urgentCareDetailUrl += '&filteredDistance=' + this.filteredDistance;
            }
           

            window.location.href = urgentCareDetailUrl;
        }
        updateHeight();

    }
    ko.components.register('urgent-care-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new UrgentCareItem(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
})(jQuery, ko);