import {AccountsService} from "../../Http/Accounts/AccountsService";
import {CustomLabels} from "../../Common/customLabels";
import {WidgetsService} from "../../Http/Widgets/WidgetsService";
import template from "./accounts-details-hsa.html"
import templateVeba from "./accounts-details-veba.html"

ko.components.register('accounts-details-hsa', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AccountHsaViewModel(widgetInfo.updateHeight, params.template, true);
        }
    }
});

ko.components.register('accounts-details-veba', {
    template: templateVeba,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AccountHsaViewModel(widgetInfo.updateHeight, params.template, false);
        }
    }
});

function AccountHsaViewModel(updateHeight, template, loadTaxDeliveryOption) {
    const self = this;
    $.cdh.extensions.profile.ProfileItemBase.call(self, updateHeight);
    let accountId = $.cdh.helper.urlParam('accountId');
    let page = $.cdh.helper.urlParam('page');

    self.paramAccountId = accountId;

    self.isPreferences = page === 'Preferences';
    self.isBillPay = page === 'BillPay';
    
    self.accountsService = new AccountsService();

    self.account = ko.observable(null);
    self.extendedDetails = new $.cdh.get.accounts.detailsHsa(accountId);

    self.rewardsUrl = ko.observable();
    self.widgetsService = new WidgetsService();
    self.widgetsService.getRewardsUrlAsync().then(msg => {
        self.rewardsUrl(msg.message);
    });
    
    self.template = new $.cdh.services.templateExtractor(template);

    self.manualClaimsEnabled = ko.observable(false);
    self.accountId = accountId;
    self.options = 0;
    self.hasInvestmentAccount = ko.observable(false);
    self.loadTaxDeliveryOption = loadTaxDeliveryOption;

    self.HsaAdvAccessibilityFlag = ko.pureComputed(function () {
        if (!self.account())
            return false;

        return self.account().HsaAdvAccessibilityFlag;
    });

    self.HsaAdvEligibility = ko.pureComputed(function () {
        if (!self.account())
            return false;

        return self.account().HsaAdvEligibility;
    });

    self.addPaymentModelBuilder = function() {
        return new $.cdh.models.PaymentsAddPopup(accountId);
    };

    self.AvailableBalanceLabel = ko.pureComputed(function () {
        return $.cdh.languageConstant.getConstant('field.cash-available-to-spend');
    });

    self.CurrentBalanceLabel = ko.pureComputed(function () {
        return "Balance";
    });

    self.PortfolioBalanceLabel = ko.pureComputed(function () {
        if (!self.account())
            return "";

        return (self.account().HsaAdvAccessibilityFlag ? $.cdh.languageConstant.getConstant('button.hsa-advance') : $.cdh.languageConstant.getConstant('field.investment-balance'));
    });

    self.TotalLabel = ko.pureComputed(function () {
        return $.cdh.languageConstant.getConstant('field.total-balance');
    });

    self.planAutoPaymentPreferenceEnabled = ko.pureComputed(function () {
        if (!self.account())
            return false;
        
        return self.account().PlanAutoPaymentPreferenceEnabled;
    });

    self.planAutoReimbursementPreferenceEnabled = ko.pureComputed(function () {
        if (!self.account())
            return false;

        return self.account().PlanAutoReimbursementPreferenceEnabled;
    });

    self.showPreference = ko.pureComputed(function () {
        const autoPaymentPrefAvailable = self.planAutoPaymentPreferenceEnabled() && $.cdh.employee.allowAutoPaymentPreference();
        const autoReimbursementPrefAvailable = self.planAutoReimbursementPreferenceEnabled() && $.cdh.employee.allowAutoReimbursementPreference();

        return autoPaymentPrefAvailable || autoReimbursementPrefAvailable;
    });

    self.preferenceModelBuilder = function () {
        return new $.cdh.models.ClaimsAutoPaymentPrefs(self);
    };

    // fields
    self.RequiredCipDocumentation = ko.observable(false);
    self.HasOnlyOneAccount = ko.observable(false);
    self.offerChangeStatementDelivery = ko.observable(false);
    self.offerChangeTaxDelivery = ko.observable(false);
    self.offerChangeFeeAmt = ko.observable();

    self.offerChangeDelivery = ko.pureComputed(function () {
        return self.offerChangeStatementDelivery() || self.offerChangeTaxDelivery();
    });

    self.changeDeliveryTaxParams = ko.pureComputed(function () {
        if (!self.account())
            return "";

        let params = "accountId=" + self.account().AccountId;
        if (self.offerChangeTaxDelivery())
            params = params + "&streamline=true";
        return params;
    });

    self.isInvestmentActive = ko.pureComputed(function() {
        if (!self.account())
            return false;
        
        return self.account().InvestmentState === $.cdh.enums.investmentSolutionStates.activeDrive ||
        self.account().InvestmentState === $.cdh.enums.investmentSolutionStates.inactiveDrive
    });
    
    self.isInterestOptionActive = () => (self.account() && (self.account().HighYieldEligibility || self.account().HighYieldOptIn == true))
    self.investmentUrl = ko.pureComputed(function () {
        
        var account = self.account();

        if (!account || account.InvestmentState === $.cdh.enums.investmentSolutionStates.none)
            return "";
        
        if (self.isInvestmentActive()) {
            return "/Page/Investment/#/account/" + account.AccountId;
        }
        
        return "/Page/InvestmentsForAccount?accountId=" + account.AccountId;
    });

    self.investmentfundListUrl = "/Page/Investment/#/account/" + accountId + "?redirectUrl=fundList&backUrl=%2FPage%2FAccountsDetailsHsa%3FaccountId%3D" + accountId;

    self.changeDeliveryStatementParams = ko.pureComputed(function () {
        if (!self.account())
            return "";

        let params = "accountId=" + self.account().AccountId;
        if (self.offerChangeStatementDelivery())
            params = params + "&streamline=true";
        return params;
    });

    self.offerChangeDeliveryText = ko.pureComputed(function () {
        let feeAmt = self.offerChangeFeeAmt();
        if (feeAmt > 0) {
            if (self.offerChangeTaxDelivery() && self.offerChangeStatementDelivery())
                return CustomLabels.documentDeliveryPreferences.goGreenAll(feeAmt);

            if (!self.offerChangeTaxDelivery() && self.offerChangeStatementDelivery())
                return CustomLabels.documentDeliveryPreferences.goGreenStatements(feeAmt);

            if (self.offerChangeTaxDelivery() && !self.offerChangeStatementDelivery())
                return CustomLabels.documentDeliveryPreferences.goGreenTaxForms(feeAmt);
        }

        return "";
    });

    self.planDescAvailable = ko.pureComputed(function () {
        if (!self.account())
            return false;

        return $.cdh.helper.isValueSet(self.account().PlanUsageDescription);
    });
    self.isPlanDescVisible = ko.observable(false);
    self.togglePlanDesc = function () {
        self.isPlanDescVisible(!self.isPlanDescVisible());
    };

    // load
    
    self.extendedDetails.load().done(function (details) {
        // RequiredCipDocumentation
        self.RequiredCipDocumentation(details.RequiredCipDocumentation);

        self.offerChangeFeeAmt(details.StatementFeeAmt);
        
        // StatementDeliveryOption
        if (details.StatementsDeliveryOption === $.cdh.enums.statementDeliveryOption.electronicAndPaper && self.offerChangeFeeAmt() > 0)
            self.offerChangeStatementDelivery(true);

        // TaxDeliveryOption
        if (self.loadTaxDeliveryOption){
            if (details.TaxFormsDeliveryOption === $.cdh.enums.taxFormsPaperOrElectronic.paper && self.offerChangeFeeAmt() > 0)
                self.offerChangeTaxDelivery(true);
        }

        updateHeight();
    });

    
    
    function loadAccount() {
        self.accountsService.countCurrentAsync().then(count => self.HasOnlyOneAccount(count === 1));

        self.accountsService.byAccountIdAsync(self.accountId).then(account => {
            if(self.isBillPay && account.BillPayEnabled && $.cdh.tpa.useExpensePortal) {
                document.location.href = $.cdh.routes.predefined.claims.portal;
            }
            
            if (account) {          
                self.option = account.AccountTypeOptions;
                self.manualClaimsEnabled(account.ManualClaimsEnabled);

                if (account.AccountTypeClass){
                    self.uploadDocumentQueuePopupModel = new $.cdh.models.UploadDocumentQueuePopupModel(account.FlexAcctId, updateHeight);
                }
            }
            self.hasInvestmentAccount(account.InvestmentAccountId !== '' && account.InvestmentAccountId !== null);

            self.productPartnerStatusValue = getproductPartnerStatus(account.ProductPartnerAcctStatus);

            self.account(account);
            
            updateHeight();
        });
    }

    self.updateDeductibleStatusCallback = function() {
        self.account(null);
        self.accountsService.clearCache();
        loadAccount();
    };

    function getproductPartnerStatus(productstatus) {
        switch (productstatus) {
            case 0: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Active");
            case 3: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Dormant");
            case 4: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Closed");
            case 5:
            case 6:
            case 7:
            case 10: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Frozen");
            case 11: return $.cdh.languageConstant.getConstant("Enum.AccountStatus.Escheated");
            default: return '';
        }
    }
    
    loadAccount();
}