import template from "./accounts-filter.html"

(function ($, ko) {
    ko.components.register('accounts-filter', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountsFilter(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountsFilter(params, updateHeight) {

	    this.updateHeight = updateHeight;
        var url = params.reloadUrl;

        this.selectedAccountId = ko.observable(params.accountId);
        this.accountId = params.accountId;
        this.account = ko.observable($.cdh.accounts.byAccountId(this.accountId));
        this.selectedAccountName = ko.observable(this.account().AccountFullName + ' $' + this.account().AvailableBalance);
        this.accountsHsa = ko.observable($.cdh.accounts.listHsa().map(a => ({
                priority: a.Priority,
                status: a.ProductPartnerAcctStatus,
                text: a.AccountFullName + ' $' + a.AvailableBalance ,
                setter: () => {
                    this.selectedAccountName(a.AccountFullName + ' $' + a.AvailableBalance);
                    this.selectedAccountId(a.AccountId)
                }
            })
        ));

        this.accountsHsa(this.accountsHsa().sort(function (a, b) {
            if (a.status != 1 && a.status != 4 && b.status != 1 && b.status != 4) {
                if (a.priority < b.priority)
                    return -1;
                else
                    return 1;
            }
            else if (a.status == 1 || a.status == 4 && (b.status != 1 && b.status != 4)) {
                return 1;
            }
            else if (b.status == 1 || b.status == 4 && (a.status != 1 && a.status != 4)) {
                return -1;
            }
            else if (a.status == b.status) {
                return a.priority - b.priority;
            }
            else {
                if (a.status < b.status)
                    return -1;
                else
                    return 1;
            }
        }));

       

        this.accountVeba = ko.pureComputed(function() {
	        return $.cdh.accounts.isVeba(this.account());
        }, this);

        this.chooseAccountTitle = ko.pureComputed(function () {
            if (this.accountVeba()) {
             
                return $.cdh.languageConstant.getConstant('filter.choose-your-account');
            }
            return $.cdh.languageConstant.getConstant('filter.choose-your-hsa-account');         
        }, this);

        this.visible = ko.pureComputed(function() {
	        return this.accountsHsa() && this.accountsHsa().length > 1;
        }, this);

        this.buttonHandler = function () {
            window.location.href = url + "?accountId=" + this.selectedAccountId()
        }
    }


})(jQuery, ko);