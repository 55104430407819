function replaceFeeAmt(message, feeAmt) {
    return message.replace(/{FeeAmt}/g,  $.cdh.helper.formatAmount(feeAmt));
}

let customLabels = {
    documentDeliveryPreferences: {
        goGreenAll: feeAmt => {
            return  replaceFeeAmt($.cdh.customLabels.documentDeliveryPreferencesGoGreenAll, feeAmt);
        },
        goGreenStatements: feeAmt => {
            return replaceFeeAmt($.cdh.customLabels.documentDeliveryPreferencesGoGreenStatements, feeAmt);
        },
        goGreenTaxForms: feeAmt => {
            return replaceFeeAmt($.cdh.customLabels.documentDeliveryPreferencesGoGreenTaxForms, feeAmt);
        },
        statements: feeAmt => {
            return replaceFeeAmt($.cdh.customLabels.documentDeliveryPreferencesStatements, feeAmt);
        },
        widget: {
            goGreen: () => {
                return $.cdh.customLabels.documentDeliveryPreferencesWidgetGoGreen;
            },
            goneGreen: () => {
                return $.cdh.customLabels.documentDeliveryPreferencesWidgetGoneGreen;
            }
        }
    }
};

module.exports.CustomLabels = customLabels;