import template from "./account-activity-adujdication.html"

ko.components.register('account-activity-adjudication', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AdjudicationInfo(params, widgetInfo);
        }
    }
});

export class AdjudicationInfo {
    constructor(params, widgetInfo) {
        let model = params.model.ClaimAdjudicationDetails;
        
        this.isExist = !!model;
        this.approved = model?.ApprovedAmount;
        this.deductible = model?.DeductibleAmount;
        this.denied = model?.DeniedAmount;
        this.eligible = model?.EligibleAmount;
        this.excluded = model?.ExcludedAmount;
        this.lowFunds = model?.LowFundsAmount;
        this.offset = model?.OffsetAmount;
        this.onHold = model?.OnHoldAmount;
        this.pended = model?.PendedAmount;
        this.excludedReason = model?.ExcludedReason;

        this.valueClass = params.valueClass;
    }
}