export class HttpResult {
    constructor(xhr) {
        this.xhr = xhr;
    }

    data() {
        if (!this.success()) return null;
        return this.responseJsonTryGet();
    }

    success() {
        return this.xhr.status === 200;
    }

    error() {
        if (this.success()) return null;
        return this.responseJsonTryGet();
    }

    responseJsonTryGet() {
        try {
            return JSON.parse(this.xhr.responseText);
        } catch (e) {
            return {
                message: this.xhr.responseText
            }
        }
    }
}

export class HttpService {
    
}

function sendAsync(url, method, data) {
    const xhr = new XMLHttpRequest();

    let promise = new Promise(function (resolve, reject) {

        url = url + (url.indexOf('?') >= 0 ? '&' : '?') + '_=' + Math.random();

        xhr.open(method, url, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        xhr.setRequestHeader("Accept-Language", $.cdh.languageConstant.getLanguage().id);
        xhr.onreadystatechange = function () {
            if (xhr.readyState !== 4) return;
            
            try {
                xhr.responseJSON = JSON.parse(xhr.responseText);
            }
            catch {}

            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(new HttpResult(xhr));
            } else if (xhr.status !== 0) {
                if (xhr.status === 503) {
                    $.cdh.global.maintenance(true);
                }
                reject(new HttpResult(xhr));
            }
        };
        if (!!data) {
            xhr.send(JSON.stringify(data));
        } else {
            xhr.send();
        }
    });

    promise.cancel = function () {
        xhr.abort();
    };

    return promise;
}
    
    
HttpService.prototype.getAsync = function (url, data) {
    let fullUrl = url;
    if (!!data) {
        fullUrl += url.indexOf('?') >= 0 ? '&' : '?';
        fullUrl += $.param(data);
       /* for (let prop in data) {
            if (data[prop] !== null && data[prop] !== undefined) {
                fullUrl += (prop + '=' + encodeURIComponent(data[prop]));
                fullUrl += '&';
            }
        }
        fullUrl = fullUrl.slice(0, -1);*/
    }
    return sendAsync(fullUrl, 'GET');
};

HttpService.prototype.postAsync = function (url, data) {
    return sendAsync(url, 'POST', data);
};

HttpService.prototype.putAsync = function (url, data) {
    return sendAsync(url, 'PUT', data);
};

HttpService.prototype.deleteAsync = function (url) {
    return sendAsync(url, 'DELETE');
};
    