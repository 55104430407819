(function (module, moment, $) {

    var transformItemInternal = function (item) {
        return {
            key: item.StatementHistKey,
            name: moment(item.StatementDate).format("MMMM D, YYYY")
        };
    };

    module.StatementsDataSource = function(accountId, specifics) {
        var items = [];

        this.setData = function(data) {
            var options = {
                downloadUrl: specifics.routes.download(),
                transformItem: transformItemInternal
            };

            $.each(data,
                function(i, obj) {
                    items.push({
                        year: obj.Year,
                        items: module.gridDataAdapter.invoke(accountId, obj.Items, options)
                    });
                });
        }
        this.getForYear = function(year) {
            var yearItems = $.grep(items, function (item) { return item.year === year; });

            return yearItems.length > 0
                ? yearItems[0].items
                : [];
        }
        this.getYears = function() {
            var result = [];

            $.each(items, function(i, obj) {
                result.push(obj.year);
            });

            return result;
        }
    }

})(window.docDelivery, window.moment, window.jQuery);