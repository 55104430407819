(function ($) {
    var alertDetailsPopupModel = function (alertInfo) {
        this.alertDetails = new $.cdh.get.alert.details(alertInfo.AlertId, function(alertInfo) {
            return rebuildTables(alertInfo);
        });
        this.alertDateTime = alertInfo.AlertDateTime;
        this.alertDetailsInvoked = false;

        this.preRender = function () {
            if (this.alertDetailsInvoked)
                return;
            this.alertDetailsInvoked = true;
            
            this.alertDetails.load().done(function () {
                if (alertInfo.isViewed())
                    return;
                new $.cdh.post.alerts.markAsViewed(alertInfo.AlertId)
                    .load()
                    .done(function () {
                        alertInfo.isViewed(true);
                        ko.postbox.publish($.cdh.topics.alerts.countRefresh, {});
                        ko.postbox.publish($.cdh.topics.notifications.countRefresh, {});
                    });
            });
        }.bind(this);
        
        this.barCodeImg = ko.pureComputed(function () {
            if(this.alertDetails.data().BarcodeBase64)
                return "data:image/png;base64," +  this.alertDetails.data().BarcodeBase64;
        }, this);

        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        function rebuildTables(alertInfo) {

            if (!alertInfo || !alertInfo.AlertBody)
                return alertInfo;
            
            // replace new lines with <br> for plain text messages
            if (!$.cdh.helper.isHtml(alertInfo.AlertBody)) {
                alertInfo.AlertBody = alertInfo.AlertBody.replace(/\n/g, '<br>');
                return alertInfo;
            }

            // // remove unnecessary attrs ("style", "<style>", etc)
            // alertInfo.AlertBody = $.cdh.helper.removeUnnecessaryAttrs(alertInfo.AlertBody);

            // replace tags
            alertInfo.AlertBody = $.cdh.helper.replaceBrs(alertInfo.AlertBody);
            alertInfo.AlertBody = $.cdh.helper.replaceEmptyTdWithWhiteSpace(alertInfo.AlertBody);
            alertInfo.AlertBody = $.cdh.helper.replaceBWithStrong(alertInfo.AlertBody);

            // exit if mobile
            // if (!($.cdh.isMobile) || $(window).width() > 1024) {
            //     return alertInfo;
            // }

            // rebuild tables
            // alertInfo.AlertBody = $.cdh.helper.rebuildAlertTables(alertInfo.AlertBody);
            alertInfo.AlertBody = $.cdh.helper.addThTags(alertInfo.AlertBody);
            alertInfo.AlertBody = $.cdh.helper.rebuildBigTables(alertInfo.AlertBody);
            alertInfo.AlertBody = $.cdh.helper.overlayMiddleTables(alertInfo.AlertBody);
            alertInfo.AlertBody = $.cdh.helper.hyphenationForLongWords(alertInfo.AlertBody);

            return alertInfo;
        }
    };

    $.extend(true, $, {
        cdh: {
            models: {
                AlertDetailsPopup: alertDetailsPopupModel
            }
        }
    });
})(jQuery);