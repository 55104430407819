(function ($, ko) {
    var reimbursementMethodMiniModel = function (updateHeight, template) {

        this.shouldBeDisplayed = ko.observable(false);
        this.model = ko.observable();
        this.isEdit = ko.observable(false);
        this.isCheck = ko.observable(false);
        this.isBankAccount = ko.observable(false);
        this.isBankAccountShouldBeValidated = ko.observable(false);
        this.profile = ko.observable();

        this.templateExtractor = new $.cdh.services.templateExtractor(template);

        this.canAddOrEdit = ko.computed(function () {
            return $.cdh.employee.allowedToUpdateDirectDepositInformation();
        }.bind(this));

        this.allowEditReimbursementMethod = ko.observable();

        this.getUserInfo = new $.cdh.get.user.getInfo();
        this.getUserInfo.load().done(result => this.allowEditReimbursementMethod(result.AllowParticipantTransaction));

        this.updateProfileRequest = new $.cdh.post.profile.update();

        this.editReimbursement = new $.cdh.get.profile.get(function (data) {
            var profile = ko.observable({ viewModel: ko.observable({}), updateProfileRequest: this.updateProfileRequest })();
            profile.viewModel(data);
            profile.hasPermissionsToEditProfile = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateProfileDemographicInformation);
            profile.hasPermissionsToEditEmail = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateEmailAddress);
            this.profile(profile);
            
            this.setBankDataFlags();

            this.model(new $.cdh.models.ProfileReimbursementMethodEditPopup(profile, false, updateHeight, this.setBankDataFlags));
            updateHeight();
        }.bind(this));
        
        this.setBankDataFlags = function() {
            var profileVm = this.profile().viewModel();
            
            this.isCheck(
                profileVm.ReimbursementMethod == $.cdh.enums.reimbursementMethod.check ||
                profileVm.ReimbursementMethod == $.cdh.enums.reimbursementMethod.externalCheck);

            this.isBankAccount(
                profileVm.ReimbursementMethod == $.cdh.enums.reimbursementMethod.directDeposit);
            
            this.isBankAccountShouldBeValidated(
                profileVm.BankAccountValidationStatus == $.cdh.enums.bankAccountValidationStatus.validationRequired ||
                profileVm.BankAccountValidationStatus == $.cdh.enums.bankAccountValidationStatus.validationFailed ||
                profileVm.BankAccountValidationStatus == $.cdh.enums.bankAccountValidationStatus.pendingValidation);

            this.isEdit(profileVm.ReimbursementMethod > 0);

            this.shouldBeDisplayed(this.isCheck() || (this.isBankAccount() &&
                (this.isBankAccountShouldBeValidated())));
        }.bind(this);

        this.updateProfile = function (profile, onSuccess) {
            if (this.updateProfileRequest.isLoading())
                return;
            this.updateProfileRequest.load({ profile: profile.viewModel() }).done(function (result) {
                profile.viewModel(ko.utils.extend(profile.viewModel(), result));
                this.isCheck(result.ReimbursementMethod == $.cdh.enums.reimbursementMethod.check || result.ReimbursementMethod == $.cdh.enums.reimbursementMethod.externalCheck);
                if (onSuccess)
                    onSuccess();
            }.bind(this));
        }.bind(this);

        this.afterRender = function() {
            updateHeight();
        }.bind(this);

        this.isCheck.subscribe(updateHeight);
        this.editReimbursement.load();
    };

    ko.components.register('profile-reimbursement-method-mini', {
        template: { element: 'template-profile-reimbursement-method-mini' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new reimbursementMethodMiniModel(updateHeight, params.template);
            }
        }
    });

    ko.components.register('reimbursement-preference', {
        template: { element: 'template-profile-reimbursement-method-edit-page' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                var miniWidgetModel = new reimbursementMethodMiniModel(updateHeight);
                miniWidgetModel.model.subscribe(function (m) {
                    if ($.isFunction(m.setClose)) {
                        m.setClose(function() { window.location = $.cdh.routes.predefined.dashboard; });
                    }
                    if ($.isFunction(m.preRender)){
                        m.preRender();
                    }
                });
                return miniWidgetModel;
            }
        }
    });
})(jQuery, ko);
