import template from "./ineligible-balance-payment-success.html"

(function ($, ko) {
    ko.components.register('ineligible-balance-payment-success', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new PaymentSuccess(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
    
    function PaymentSuccess(params, updateHeight) {
        const self = this;
        self.amount = params.model.amount;
        self.directDeposit = params.model.directDeposit;
        self.confirmationText = params.model.confirmationText;
    }
})(jQuery, ko);