import template from "./dependent-add-edit-form.html"

(function ($, ko) {
    ko.components.register('dependent-add-edit-form', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                if (params.model) {
                    params.model.afterRender = updateContainerHeight(componentInfo.element);
                }

                return params.model;
            }
        }
    });

})(jQuery, ko);