(function($, ko) {

    function mapObservable(model, regex) {
        var result = {};
        $.each(model, function(key, value) {
            if (regex.test(key)) {
                result[key] = ko.observable(value);
            }
        });
        return result;
    }

    function accountDetailsModel(data) {
        $.extend(this, mapObservable(data, /Am[0-9]*/));
        $.extend(this, mapObservable(data, /Asf[0-9]*/));
        $.extend(this, mapObservable(data, /Adf[0-9][A-F]/));
        $.extend(this, mapObservable(data, /Ndf[0-9]*/));
    }

    $.extend(true, $, {
            cdh: {
                models: {
                    EnrollmentAccountDetails: accountDetailsModel
                }
            }
        });
})(jQuery, ko);
