(function ($, ko) {
    var alertsListMiniModel = function (updateHeight, template) {

        this.templateExtractor = new $.cdh.services.templateExtractor(template);
       
        this.alerts = new $.cdh.get.alert.get(
            function(json) {
                var lineModels = $.map(json,
                    function(alert) {
                        alert.details = new $.cdh.models.AlertDetailsPopup(alert);
                        alert.isViewed = ko.observable(alert.IsViewed);
                        return alert;
                    });

                return lineModels;
            });

        this.hasNoRegisteredMobile = ko.observable(false);
        this.mobileNumbers = new $.cdh.get.communications.getMobileNumbers(function (numbers) {
            //we need mobile number only to determine visibility of "Sign Up" button
            var hasRegisteredNumber = false;
            for (var i = 0; i < numbers.length; i++) {
                if (numbers[i].MobileStatus == $.cdh.enums.mobileStatus.optIn) hasRegisteredNumber = true;
            }
            //if there is no registered and approved number in numbers list
            this.hasNoRegisteredMobile(!hasRegisteredNumber);
            //
            return numbers;
        }.bind(this));

        this.alerts.state.subscribe(updateHeight);
        this.alerts.load({recordCount: 5});

        this.mobileNumbers.state.subscribe(updateHeight);
        this.mobileNumbers.load();
    }

    ko.components.register('alerts-list-mini', {
        template: { element: 'template-alerts-list-mini' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new alertsListMiniModel(updateHeight, params.template);
            }
        }
    });
})(jQuery, ko);
