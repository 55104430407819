import template from "./electronics-document-popup.html"
(function ($, ko) {
    ko.components.register('electronics-document-popup', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ElectronicsDocumentPopup(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ElectronicsDocumentPopup(params, updateHeight) {
        var self = this;
        var accountId = params.model.accountId;
        this.close = params.closeFunc;
        this.updateHeight = updateHeight;
        if (typeof accountId === 'function') {
            accountId = accountId();
        }
       
        this.url = '/Scripts/pdf-viewer-js/ViewerJS/#/TaxForms/PdfCheck?id=' + accountId;
            
        this.clickCancel = function () {
            $(document).trigger('rejectEvent');
            this.close();
        };

        this.clickCancel = function () {
            var rejectEvent = new CustomEvent('rejectEvent', {
                detail: { accepted: false }
            });
            window.dispatchEvent(rejectEvent);
            this.close();
        };

        this.clickAccept = function () {
            var acceptEvent = new CustomEvent('acceptEvent', {
                detail: { accepted: true }
            });
            window.dispatchEvent(acceptEvent);
            this.close();
        };
        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);
    }

})(jQuery, ko);