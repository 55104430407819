import template from "./account-activity-print-claim-form.html" 
(function ($, ko) {
    ko.components.register('account-activity-print-claim-form', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityPrintClaimForm(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityPrintClaimForm(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.isMobile = params.isMobile;
        self.faxCoverUrl = $.cdh.routes.claims.downloadFaxCover({trackingNumber: model.TrackingNumber, pdf: true});
    }

})(jQuery, ko);