import template from "./account-activity-mark-as-ineligible.html"
(function ($, ko) {
    ko.components.register('account-activity-mark-as-ineligible', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityMarkAsIneligible(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityMarkAsIneligible(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.isMobile = params.isMobile || false;
        self.disable = model.Disable;
        const urlParams = {
            page: $.cdh.helper.urlParam('page')
        }
        self.markAsIneligibleForce = urlParams.page === 'MarkAsIneligible';
        self.reloadListCallback = params.reloadListCallback;
        self.markAsIneligibleAPI = new $.cdh.post.transactions.setMarkAsIneligible();
        self.markAsIneligiblePopupModel = {
            
            OnSuccessCallback: function () {
                self.markAsIneligibleAPI.load({
                    TransactionKey: model.TransactionKey,
                    SettlementDate: model.SettlementDate,
                    SequenceNumber: model.SeqNumber,
                    Status: "ineligible"
                }).done(function (data) {       
                    self.reloadListCallback();
                });
           }
            
        }
    }

})(jQuery, ko);
