export class DataRequestHandlerBase {
    constructor() {
        this.loading = ko.observable(false);
        this.error = ko.observable();
        this.completed = ko.observable(false);
        this.cancel = function () {};
    }
    
    async handle(apiRequestPromise){
        this.loading(true);
        this.error(null);
        this.completed(false);
        this.cancel = apiRequestPromise.cancel;
        
        try{
            return await apiRequestPromise;
        }catch (e) {
            this.error(e.error());
            throw e;
        }finally {
            this.loading(false);
            if (!this.error()) {
                this.completed(true);
            }
        }
    }
}