import template from "./account-activity-open-fx-claim.html"

(function ($, ko) {
    ko.components.register('account-activity-open-fx-claim', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityOpenFxClaim(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityOpenFxClaim(params, updateHeight) {
        const self = this;
        let model = params.model;
        
        self.isMobile = params.isMobile;
        self.reloadListCallback = params.reloadListCallback;
        self.detailsButtonId = 'fx-claim-details-button-' + model.ClaimId;

        self.fxClaimDetailsModel = {
            Status: model.Status,
            StatusCode: model.StatusCode,
            isAccountActivity: true,
            EOBExtra: {
                ExternalClaimId: model.ClaimId,
                Transaction: {
                    Id: model.TransactionKey,
                    SettlementDate: model.SettlementDate,
                    SeqNumber: model.SeqNumber
                }
            },
            color: "color" + params.color(),
            TrackingNumber: model.ClaimId,
            Claimant: model.Claimant,
            TransactionDate: model.Date,
            Amount: model.Amount,
            ServiceStartDate: model.ServiceStartDate,
            Provider: model.Provider,
            Actionable: ko.observable(false)
        };
    }

})(jQuery, ko);