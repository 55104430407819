import template from "./medicine-cabinet-prescription-editor-set.html"

(function ($, ko) {
    ko.components.register('medicine-cabinet-prescription-editor-set', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new PrescriptionEditorSet(params.model, widgetInfo);
            }
        }
    });

    function PrescriptionEditorSet(params, widgetInfo) {
        const self = this;

        self.prescriptionsService = new $.cdh.http.smartAccount.PrescriptionsService();

        self.isEdit = params.isEdit;
        
        self.model = {
            price: ko.observable().extend({ required: true, greaterThan: 0 }),
            days: ko.observable().extend({ required: true, greaterThan: 0 })
        }
        
        self.validation = new $.cdh.validation(self.model);
        
        self.canSubmit = ko.observable(true);

        self.disable = ko.pureComputed(function () {
            return !self.canSubmit();
        });
        
        self.onBack = function() {
            params.onBack();
        }
        
        self.onSubmit = function () {
            if (!self.canSubmit()) {
                return;
            }

            self.validation.activate();
            if (self.validation.hasErrors()) return false;

            self.canSubmit(false);

            let prescription = params.prescription();
            prescription.Days = self.model.days();
            prescription.Price = self.model.price();
            
            let submitRequest;
            if (self.isEdit()) {
                submitRequest = self.prescriptionsService.updateAsync(prescription);
            } else {
                submitRequest = self.prescriptionsService.addAsync(prescription);
            }

            submitRequest.then(function (result) {
                params.onSubmit();
            }).finally(function () {
                self.canSubmit(true);
            });
        };

        self.onCancel = function () {
            params.closeFunc();
        };
        
        if(params.prescription() != null) {
            setData(params.prescription());
        }

        params.prescription.subscribe(setData);
        
        function setData(prescription) {
            if(prescription.Price > 0) {
                self.model.price(prescription.Price);
            }
            if(prescription.Days > 0) {
                self.model.days(prescription.Days);
            }
        }
    }

})(jQuery, ko);