import template from "./medicine-cabinet-pharmacy-item.html"

(function ($, ko) {

    ko.components.register('medicine-cabinet-pharmacy-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new PharmacyItem(params, widgetInfo)
            }
        }
    });

    function PharmacyItem(params, widgetInfo) {
        const self = this;

        self.pharmacy = params.pharmacy;
        self.isSubmit = params.isSubmit;
        self.submitClick = function() {
            params.submitClick(self.pharmacy);
        };
        self.disable = params.disableSubmit;
    }
})(jQuery, ko);