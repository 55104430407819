(function ($) {
    var termsOfUsePopup = function (onAgreeWithTerms) {

        this.termsOfUseRequest = new $.cdh.get.registration.termsOfUse();
        this.termsOfUseRequest.load();

        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.clickSubmit = function () {
            this.close();
            onAgreeWithTerms();
        }
    };


    $.extend(true, $, {
        cdh: {
            models: {
                TermsOfUsePopup: termsOfUsePopup
            }
        }
    });
})(jQuery);