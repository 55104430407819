import template from "./funding-calculator.html"
import {SmartAccountBaseViewModel} from "../SmartAccountBaseViewModel";
import {ConditionsService} from "../../Http/SmartAccount/Vendors/ConditionsService";
import {DependentsService} from "../../Http/Profile/DependentsService";
import {ParticipantConditionsService} from "../../Http/SmartAccount/ConditionsService";
import {LongTermSavingsService} from "../../Http/SmartAccount/LongTermSavingsService";
import {ProfileService} from "../../Http/Profile/ProfileService";

ko.components.register('funding-calculator', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new FundingCalculator(params, componentInfo, widgetInfo)
        }
    }
});

function prepareDependents(dependents, profile) {

    dependents = dependents.orderBy(function (d) {
        return d.FullName;
    })

    dependents.unshift({
        DependentId: null,
        FullName: profile.FirstName + " " + profile.LastName
    });

    return dependents;
}

function calcYearEstimates(savingsData) {

    const currentHsaBalance = savingsData.HsaBalanceEstimates.sum(function (e) {
        return e.HsaBalance;
    }).toFixed(2);

    const oneYearHsaEstimates = savingsData.HsaBalanceEstimates.sum(function (e) {
        return e.OneYearEstimate;
    }).toFixed(2);

    const oneYearCostEstimates = savingsData.CostEstimates.sum(function (e) {
        return e.OneYearEstimate;
    }).toFixed(2);

    return {
        CurrentHsaBalance: +currentHsaBalance,
        ExpectedMedicalCostsForYear: +oneYearCostEstimates,
        LeftoverForRetirement: oneYearHsaEstimates - oneYearCostEstimates
    };
}

function calcSavings(data) {
    const balance = {
        OneYear: 0,
        FiveYears: 0,
        TenYears: 0,
        LifeTime: 0
    };

    for (let i = 0; i < data.HsaBalanceEstimates.length; i++) {

        const itm = data.HsaBalanceEstimates[i];

        balance.OneYear += itm.OneYearEstimate;
        balance.FiveYears += itm.FiveYearEstimate;
        balance.TenYears += itm.TenYearEstimate;
        balance.LifeTime += itm.LifetimeEstimate;
    }

    const costs = {
        OneYear: 0,
        FiveYears: 0,
        TenYears: 0,
        LifeTime: 0
    }

    for (let i = 0; i < data.CostEstimates.length; i++) {

        const itm = data.CostEstimates[i];

        costs.OneYear += itm.OneYearEstimate;
        costs.FiveYears += itm.FiveYearEstimate;
        costs.TenYears += itm.TenYearEstimate;
        costs.LifeTime += itm.LifetimeEstimate;
    }

    return {
        HsaBalance: balance,
        Costs: costs
    };
}

class ProfileViewModel {
    constructor(data) {
        this.viewModel = ko.observable(data);
        this.vm = {
            states: {
                data: function () {
                    return $.cdh.states;
                }
            },
            countries: {
                data: function () {
                    return $.cdh.countries;
                }
            }
        }
    }
}

export class FundingCalculator extends SmartAccountBaseViewModel {
    constructor(params, componentInfo, widgetInfo) {
        super(params, componentInfo, widgetInfo);
        
        this.dependents = ko.observableArray([]);
        this.dependentsConditions = ko.observableArray([]);
        this.conditions = ko.observableArray([]);
        this.longTermSavings = ko.observable(null);
        this.profile = ko.observable(null);
        this.yearEstimates = ko.observable(null);

        this.conditionsService = new ConditionsService();
        this.dependentsService = new DependentsService();
        this.dependentsConditionsService = new ParticipantConditionsService();
        this.fundingCalculatorService = new LongTermSavingsService();
        this.profileService = new ProfileService();

        this.hasPermissionsToAddDependent = true;
        this.refreshCallback = () => { this.init(); };
        this.init();
    }

    async init() {
        this.loading(true);
        try {
            const result = await Promise.all([this.dependentsService.getAsync(),
                this.dependentsConditionsService.getAsync(),
                this.conditionsService.getAsync(),
                this.fundingCalculatorService.getAsync(),
                this.profileService.getAsync()]);

            const profile = result[4].data();

            this.dependents(prepareDependents(result[0].data(), profile));
            this.dependentsConditions(result[1].data());
            this.conditions(result[2].data());

            const savingsData = result[3].data();

            this.longTermSavings(calcSavings(savingsData));
            this.yearEstimates(calcYearEstimates(savingsData));

            this.profile(new ProfileViewModel(profile));

            this.widgetInfo.updateHeight();
            
        } catch (e) {
            this.error(e.error());
        } finally {
            this.loading(false);
        }
    }
}