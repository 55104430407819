import template from "./smart-registration-search-details.html"

(function ($, ko) {
    ko.components.register('smart-registration-search-details', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new SmartRegistrationSearchDetails(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function SmartRegistrationSearchDetails(params, updateHeight) {
        const self = this;

        self.searchEmployeeRequest = new $.cdh.post.smartRegistration.searchEmployee();        

        self.enableDebitCard = ko.observable(false);
        self.isCardValidation = ko.observable(false);
        self.isCardNumberInvalid = ko.observable(false);
        self.isErrorMsgDisplayedForCard = ko.observable(false);
        
        self.model = {
            firstName: ko.observable().extend({required: true, maxLength: 50}),
            lastName: ko.observable().extend({required: true, maxLength: 50}),
            zipCode: ko.observable().extend({ required: true, zipCode: true }),
            cardNumber: ko.observable('').extend({
                required: {
                    onlyIf: function () {
                        return self.enableDebitCard();
                    }
                },
                cardType: true
            })
        };

        document.getElementById('togglePassword').addEventListener('click', function () {
            const passwordField = document.getElementById('debit_card');
            const eyeIcon = this.querySelector('img');

            if (passwordField.type === 'password') {
                passwordField.type = 'text';
                eyeIcon.src = '/Images/icons/eye-open.svg'; // Change to closed eye icon
            } else {
                passwordField.type = 'password';
                eyeIcon.src = '/Images/icons/eye-closed.svg'; // Change to open eye icon
            }
        });

        // Computed observable to format card number
        self.formattedCardNumber = function (newValue) {
                // Get raw value and remove non-digit characters
            var rawValue = newValue.model.cardNumber().replace(/\D/g, '');
                // Format the value with spaces
            var formattedValue = rawValue.replace(/(.{4})/g, '$1 ').trim();

            self.model.cardNumber(formattedValue);
        }

        self.ensureNumberical = function (data, e) {
            var keyValue = e.key;
            if (keyValue.match(/[0-9]/g)) {
                return true;
            }
            return false;
        }
        
        self.validation = new $.cdh.validation(self.model);

        self.model.cardNumber.subscribe(function (newValue) {
            if (self.model.cardNumber() !== '') {
                self.isCardNumberInvalid(!ko.validation.rules.cardType.validator(self.model.cardNumber()));
                self.isCardNumberInvalid() === true ? self.isErrorMsgDisplayedForCard(true) : self.isErrorMsgDisplayedForCard(false);
            }
            self.isCardValidation(false);
        });

        self.enableDebitCard.subscribe(function(newValue) {
            if (!newValue) {
                self.model.cardNumber('');
                self.isCardNumberInvalid(false);
            } else {
                if (self.isLoading() || self.validation.hasErrors() || self.isCardValidation() || self.isCardNumberInvalid()) {
                    if (self.model.cardNumber() == '') {
                        self.isErrorMsgDisplayedForCard(true);
                    }
                }
            }
        });
        
        self.isLoading = ko.pureComputed(function() {
           return self.searchEmployeeRequest.isLoading();
        });
        
        self.submit = function() {
            if (self.isLoading())
                return;
            
            self.validation.activate();
            
            if(!self.validation.hasErrors()) {
                if(!self.enableDebitCard()) {
                    self.sendData();
                } else {
                    self.isCardValidation(true);
                    setTimeout(function() {
                        document.getElementById('reg_submit_card_btn').focus();
                    }, 10);
                }
            } else {
                if (self.enableDebitCard() && self.model.cardNumber() == '') {
                    self.isErrorMsgDisplayedForCard(true);
                } 
            }
        };
        
        self.validateCard = function() {
            self.sendData().always(function () {
                self.isCardValidation(false);
            });
        };
        
        self.sendData = function() {
            return self.searchEmployeeRequest.load(self.model).done(function (result) {
                params.model.onNext(result);
            }).fail(function() { updateHeight(); });
        };
        
        self.cancel = params.model.onCancel;
        
        params.model.clear = function() {
            self.enableDebitCard(false);
            self.isCardValidation(false);
            self.model.firstName(null);
            self.model.lastName(null);
            self.model.zipCode(null);
            self.model.cardNumber('');
            
            self.validation.deactivate(true);

            self.searchEmployeeRequest.setData(null);
        };
    }

})(jQuery, ko);