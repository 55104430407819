import {SmartAccountBaseViewModel} from "../../SmartAccountBaseViewModel";
import template from "./funding-calculator-family-member.html";

ko.components.register('funding-calculator-family-member', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new FundingCalculatorFamilyMember(params, componentInfo, widgetInfo)
        }
    }
});

export class FundingCalculatorFamilyMember extends SmartAccountBaseViewModel {
    constructor(params, componentInfo, widgetInfo) {
        super(params, componentInfo, widgetInfo);

        this.dependent = params.dependent;
        this.conditions = params.conditions();
        this.expand = ko.observable(false);
        this.employeeRelation = ko.pureComputed(() => {
            switch (this.dependent.EmployeeRelation) {
                case 1:
                    return "Spouse Or Common Law Spouse";
                case 2:
                    return "Child";
                case 3:
                    return "Domestic Partner";
                case 4:
                    return "Other";
                case 5:
                    return "Estate";
                case 0:
                    return "Unknown";
                default:
                    return "";
            }
        });
        this.dependentConditions = params.dependentConditions().orderBy(dc => this.dependentConditionDisplayText(dc));
        this.refreshCallback = params.refreshCallback;

    }

    dependentConditionDisplayText(dc) {
        const condition = this.conditions.find((c) =>
            dc.DependentId === this.dependent.DependentId && dc.ConditionId === c.Uuid);
        return !!condition ? condition.Display : '';
    }

    expandClick() {
        this.expand(!this.expand());
        this.widgetInfo.updateHeight();
    }
}
    