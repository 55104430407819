import  './investment-portal.scss';
import template from './investment-portal.html';

import {ComponentBase} from "../../Common/componentBase";
import {RegisterDefaultComponent} from "../../Common/componentsHelpers";
import {AccountsService} from "../../Http/Accounts/AccountsService";
import {InvestmentPortalService} from "../../Http/InvestmentPortal/InvestmentPortalService";

function getAccountId() {
    const hashContents = decodeURIComponent(document.location.hash);
    const matches = hashContents.match(/account\/([0-9]+)/);
    return matches ? matches[matches.length - 1] : null;
}

export class InvestmentPortalViewModel extends ComponentBase {
    constructor(params) {
        super(params);

        this.reloginRequired = ko.observable(false);
        this.accountId = getAccountId();
        this.accountLoaded = ko.observable(false);
        this.externalModel = null;

        if (!this.accountId) {
            document.location.href = $.cdh.routes.predefined.investments.portalAccountSelector;
        } else {
            this.init();
        }
    }

    async init() {
        this.loading(true);
        this.error(false);

        const accountsService = new AccountsService();
        const investmentPortalService = new InvestmentPortalService();
        
        const result = await Promise.all([accountsService.byAccountIdAsync(this.accountId), investmentPortalService.getSettingsAsync()]);
        const account = result[0];
        const settingsResponse = result[1];
        
        this.externalModel = {
            cacheBustInFileName: true,
            settings: settingsResponse?.data(),
            reloginRequired: this.reloginRequired,
            externalUrl: $.cdh.investmentPortalPath,
            payloadParams: {
                WcpUrl: `${document.location.protocol}//${document.location.host}`,
                AccountId: account.AccountId,
                FlexAccountId: account.FlexAcctId,
            },
            onError: () => {
                this.error(true);
            }
        };

        this.loading(false);
        this.accountLoaded(true);
    }
}

RegisterDefaultComponent('investment', template, InvestmentPortalViewModel);