import template from "./account-activity-item.html"

(function ($, ko) {
    ko.components.register('account-activity-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityItem(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityItem(params, updateHeight) {
        const self = this;

        const urlParams = {
            page: $.cdh.helper.urlParam('page')
        }
        self.isAddReceiptParam = urlParams.page === 'AddReceipt';
        
        params.accountsLoaded = params.accountsLoaded || true;
        params.reloadListCallback = params.reloadListCallback || function() {};
        let model = params.model;
        
        model.Status = ko.observable(model.Status);
        model.StatusWithAmount = ko.observable(model.StatusWithAmount);
        model.Receipts = ko.observableArray(model.Receipts);
        model.CanUploadReceipt = ko.observable(model.CanUploadReceipt);
        model.Disable = ko.observable(false);
        
        self.headerModel = model;
        self.detailsModel = model;
        self.accountsLoaded = params.accountsLoaded;
        self.reloadListCallback = params.reloadListCallback;
        self.showCurrentMarkingForPaidCheckbox = params.paidMarkingOption();
        self.currentlySelectedForMarking = ko.observable(false);

        self.currentlySelectedForMarking.subscribe(function (newValue) {
            let claimsForSubmission = params.claimsListForMarking();
            let isClaimAlreadyInTheList = claimsForSubmission.indexOf(self.detailsModel.ClaimId);

            if (newValue) {
                if (isClaimAlreadyInTheList == -1) {
                    params.claimsListForMarking().push(self.detailsModel.ClaimId);
                    params.enableSubmit(true);
                }
            }
            else {
                if (isClaimAlreadyInTheList != -1) {
                    params.claimsListForMarking().splice(isClaimAlreadyInTheList, 1);
                    if (params.claimsListForMarking().length == 0)
                        params.enableSubmit(false);
                }
            }
        });

        self.opened = ko.observable(params.expand());
        
        self.onToggle = function () {
            self.opened(!self.opened());

            let claimId = model.TrackingNumber || model.ClaimId;

            if (self.opened() && claimId) {
                $.cdh.helper.setUrlParam('claimId', claimId);
                if (self.isAddReceiptParam) {
                    $.cdh.helper.deleteUrlParam('page');
                    $.cdh.helper.deleteUrlParam('claimKey');
                    $.cdh.helper.deleteUrlParam('extClaimId');
                    $.cdh.helper.deleteUrlParam('transactionKey');
                    $.cdh.helper.deleteUrlParam('settlementDate');
                    $.cdh.helper.deleteUrlParam('expenseKeys');
                    self.isAddReceiptParam = false;
                }
            } else {
                $.cdh.helper.deleteUrlParam('claimId');
            }

            updateHeight();
        };
        self.itemHeaderKeyDown = function(data, e) {
            if (e.keyCode === 32 || e.keyCode === 13) {
                self.onToggle();
                return false;
            }
            return true;
        };
        self.color = ko.observable(getColor(model.SimplifiedStatus));
        self.disable = model.Disable;

        function getColor(status) {
            switch (status) {
                case $.cdh.enums.employeeActivitySimplifiedStatus.pending:
                    return 5;
                case $.cdh.enums.employeeActivitySimplifiedStatus.denied:
                    return 3;
                case $.cdh.enums.employeeActivitySimplifiedStatus.actionRequired:
                    return 1;
                case $.cdh.enums.employeeActivitySimplifiedStatus.noAction:
                    return 2;
                case $.cdh.enums.employeeActivitySimplifiedStatus.approved:
                default:
                    return '';
            }
        }
    }

})(jQuery, ko);