import template from "./bill-pay.html";
import {PaymentsService} from "../../Http/Payments/PaymentsService";

ko.components.register('bill-pay', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new BillPayModel(widgetInfo.updateHeight);
        }
    }
});

class BillPayModel {
    constructor(updateHeight) {
        const paramAccountId = $.cdh.helper.urlParam('accountId');

        this.updateHeight = updateHeight;
        this.paymentsService = new PaymentsService();
        
        this.profileRequest = new $.cdh.get.profile.get();
        this.profile = ko.observable();
        this.isDirectDepositEnabled = ko.computed(() => 
            $.cdh.employee.allowedToViewDirectDepositInformation() && 
            this.profile() && 
            this.profile().ReimbursementMethodList.some(m => m.ReimbursementMethod === $.cdh.enums.reimbursementMethod.directDeposit));

        this.paginatorModel = {
            pageNo: ko.observable(),
            totalPages: ko.observable(),
            showAllAllowed: true,
            defaultPageSize: 10,
            currentPageSize: 10,
            showAll: function(callback) {},
            onChanged: (currentPage, pageSize) => {
                if(pageSize) {
                    this.paginatorModel.currentPageSize = pageSize;
                }
                this.loadData(currentPage);
            }
        };

        this.accounts = $.cdh.accounts.initAccounts();
        this.anyHsa = ko.pureComputed(() => this.accounts.data().some(a => a.BillPayEnabled));

        this.selectedAccount = ko.observable(paramAccountId).syncWith($.cdh.topics.accounts.listFilter);

        this.accountsFilterOptions = ko.observable({
            filterFunction: a => a.BillPayEnabled,
            selectedAccountId: paramAccountId || null
        }).syncWith($.cdh.topics.accounts.listFilterOptions);

        this.selectedAccount.subscribe(account => {
            let statuses = account ? [this.statuses.pending] : [this.statuses.pending, this.statuses.failed];
            this.selectedStatuses(statuses);
            this.loadData();
        });

        this.statuses = {
            pending: $.cdh.enums.paymentStatusFilter.pending,
            failed: $.cdh.enums.paymentStatusFilter.failed
        };
        this.selectedStatuses =  ko.observableArray(paramAccountId ? [this.statuses.pending] : [this.statuses.pending, this.statuses.failed]);

        this.filterSetters = [
            { text: $.cdh.languageConstant.getConstant('filter.all'), setter: () => { this.selectedStatuses([this.statuses.pending, this.statuses.failed]); this.loadData(); } },
            { text: $.cdh.languageConstant.getConstant('filter.planned'), setter: () => { this.selectedStatuses([this.statuses.pending]); this.loadData(); } },
            { text: $.cdh.languageConstant.getConstant('filter.failed'), setter: () => { this.selectedStatuses([this.statuses.failed]); this.loadData(); } }
        ];

        this.payments = ko.observableArray([]);
        this.filterDisabled = ko.pureComputed(() => this.selectedAccount() || this.paymentsService.loading());

        this.editReimbursementModel = ko.observable();

        this.addPayment = ko.observable();

        this.onPaymentCancel = () => {
            this.loadProfile();
        };

        this.paymentRemoved = () => {
            this.loadData();
        };

        this.init();
    }

    init() {
        this.loadProfile();
        this.loadData();
        let model = this.addPaymentModelBuilder();
        this.addPayment(model);
    }

    clickStatusFilter() {
        this.loadData();
        return true;
    }
    
    addPaymentModelBuilder() {
        let model = new $.cdh.models.PaymentsAddPopup(null, this.init);
        model.onPaymentCancel = this.onPaymentCancel;
        return model;
    }
    
    loadProfile() {
        this.profileRequest.load().done(data => {
            this.profile(data);
            let model = ko.observable({viewModel: ko.observable({})})();
            model.viewModel(data);
            this.editReimbursementModel(new $.cdh.models.ProfileReimbursementMethodEditPopup(model, true, this.updateHeight));
        });
    }

    async loadData(currentPage) {
        if (this.paymentsService.loading()) {
            return;
        }

        this.paginatorModel.pageNo(currentPage || 1);

        let filter = {
            statuses: this.selectedStatuses(),
            accountId: this.selectedAccount() ? this.selectedAccount().AccountId : null
        };

        let pageParams = {
            pageNo: this.paginatorModel.pageNo(),
            pageSize: this.paginatorModel.currentPageSize
        };

        const result = await this.paymentsService.getPageAsync(filter, pageParams);
        const data = result.data();

        this.payments(data.Data);
        this.paginatorModel.totalPages(data.TotalPages);
        this.updateHeight();
    };
}
