import template from './external-app.html';

import {ComponentBase} from "../Common/componentBase";
import {RegisterDefaultComponent} from "../Common/componentsHelpers";
import {ExternalAuthService} from "../Http/Auth/ExternalAuthService";
import {CacheService} from "../Http/Cache/CacheService";

export class ExternalAppViewModel extends ComponentBase {
    constructor(params) {
        super(params);
        
        let model = params.model;
        
        this.settings = model.settings;
        this.cacheBustInFileName = model.cacheBustInFileName;
        this.reloginRequired = model.reloginRequired;
        this.externalUrl = model.externalUrl;
        this.externalAppComponentName = ko.observable();
        this.payloadParams = model.payloadParams;
        this.onError = model.onError;
        this.cssUrl = ko.observable();
        
        this.loading(true);
        
        this.init();
    }

    init() {
        window.addEventListener('message', async (event) => {
            if (!event.data.type) {
                return;
            }
            switch (event.data.type) {
                case 'ComponentLoaded':
                    this.externalAppComponentName(event.data.payload.componentName);
                    break;
                case 'Redirect':
                    switch (event.data.payload.to) {
                        case 'ScheduledPayments':
                            window.location.href = $.cdh.routes.predefined.billPay;
                            break;
                        case 'Home':
                            window.location.href = $.cdh.routes.predefined.dashboard;
                            break;
                        case 'EditDirectDeposit':
                            let selectedMethod = $.cdh.enums.reimbursementMethod.directDeposit;
                            window.location.href = `${$.cdh.routes.predefined.profile.profileFull}?page=ReimbursementMethod&reimbursementMethod=${selectedMethod}`;
                            break;
                    }
                    break;
                case 'ClearCache':
                    new CacheService().clearAsync(event.data.payload);
                    break;
                case 'ClearAccountsCache':
                    new CacheService().clearAsync(5, event.data.payload);
                    break;
                case 'Logout':
                    $.cdh.helper.logoutWithMessage(event.data.payload.message);
                    break;
                case 'AuthRequired':
                    const externalAuthService = new ExternalAuthService();

                    const authResult = await externalAuthService.getAsync();
                    const authData = authResult.data();

                    const now = new Date();
                    const expires = new Date(authData.Expires * 1000);

                    if (now >= expires) {
                        this.reloginRequired(true);
                        return;
                    }
                    
                    let payload = {
                        WcaApiHost: authData.WcaApiHost,
                        UserName: authData.UserName,
                        ApiBaseUrl: this.externalUrl, 
                        Expires: authData.Expires,
                        Token: authData.Token,
                        HandshakeToken: authData.HandshakeToken,
                        TokenType: authData.TokenType,
                        TpaId: authData.TpaId,
                        EmployerId: authData.EmployerId,
                        EmployeeId: authData.EmployeeId
                    };
                    
                    if(this.payloadParams) {
                        Object.assign(payload, this.payloadParams);
                    }

                    window.postMessage({
                        type: 'AuthDataReceived', 
                        payload: payload
                    }, "*");
                    break;
            }
        });
        const cacheBust = this.settings?.BuildNumber ?? $.cdh.version;
        let cssUrl, jsUrl;
        
        if(this.cacheBustInFileName && this.settings) {
            cssUrl = `${this.externalUrl}/app.${cacheBust}.css`;
            jsUrl = `${this.externalUrl}/app.${cacheBust}.js`;
        } else {
            cssUrl = `${this.externalUrl}/app.css?v=${cacheBust}`;
            jsUrl = `${this.externalUrl}/app.js?v=${cacheBust}`;
        }

        this.cssUrl(cssUrl) // 'http://localhost:5000'
        
        const appScript = document.createElement('script');
        appScript.src = jsUrl; // 'http://localhost:5000'
        appScript.onerror = () => {
            this.loading(false);
            this.error(true);
            this.onError();
        };
        appScript.onload = () => {
            this.loading(false);
            if(this.settings?.ComponentName) {
                this.externalAppComponentName(this.settings.ComponentName);
            }
        }
        document.body.appendChild(appScript);
    }
}

RegisterDefaultComponent('external-app', template, ExternalAppViewModel);