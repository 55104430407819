import template from "./opportunities-count.html"
import {ParticipantOpportunitiesService} from "../../../Http/SmartAccount/OpportunitiesService";

ko.components.register('opportunities-count', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new OpportunitiesCount(params, widgetInfo);
        }
    }
});

export class OpportunitiesCount {
    constructor(params, widgetInfo) {
        this.opportunitiesService = new ParticipantOpportunitiesService();

        this.count = ko.observable().subscribeTo($.cdh.topics.opportunities.countValue);
        
        this.load();
    }
    
    async load() {
        let result = await this.opportunitiesService.getCountAsync();
        
        this.count(result.data());
    }
}