import template from "./transactions-filter.html"

(function ($, ko) {
    ko.components.register('transactions-filter', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new TransactionsFilter(params, widgetInfo.updateHeight);
            }
        }
    });
    
    function TransactionsFilter(params, updateHeight) {
        const self = this;
        
        self.typeEnumFilter = ko.observable('');
        self.accounts = ko.observableArray([]);
        self.reassociatedAccounts = ko.observable([]);
        self.reassociatedOriginalAccountsCount = ko.observable(0);
        self.selectedAccount = ko.observable();
        self.selectedHistoricalAccount = ko.observable();
        self.years = ko.observableArray([]);
        self.selectedYear = ko.observable();
        self.showZeroTransactions = ko.observable(true);
        self.historical = ko.observable(false);
        self.yearsWithAccounts = null;
        self.allowSubscribe = ko.observable(false);

        // Advanced filter
        self.advancedVisible = ko.observable(false);
        self.fromDateFilter = ko.observable('');
        self.toDateFilter = ko.observable('');
        self.fromAmountFilter = ko.observable('');
        self.toAmountFilter = ko.observable('');
        self.statusFilter = ko.observable('');
        self.claimNumberFilter = ko.observable('');

        self.statuses = {
            approved: 'Approved/Posted',
            pending: 'Pending/Processing',
            authorized: 'Authorized',
            denied: 'Denied'
        };
        self.allStatuses = [self.statuses.approved, self.statuses.pending, self.statuses.authorized, self.statuses.denied];
        self.defaultStatuses = [];
        self.selectedStatuses = ko.observableArray(self.defaultStatuses);

        self.resetFilter = function () {
            self.fromDateFilter('');
            self.toDateFilter('');
            self.fromAmountFilter('');
            self.toAmountFilter('');
            self.statusFilter('');
            self.typeEnumFilter('');
            self.selectedStatuses(self.defaultStatuses);
        };

        self.displayHistoricalData = function (display) {
            self.allowSubscribe(false);
            self.historical(display);
            self.allowSubscribe(true);

            $.cdh.helper.titlePage(self.historical()
                ? 'Historical HSA Transactions'
                : 'Transactions');

            self.resetFilter();

            params.model.selectedHistoricalAccount(self.historical() ? self.selectedHistoricalAccount() : null);

            self.onSearch(true);
        };

        self.setYearsWithAccount = function (model) {
            self.yearsWithAccounts = model.YearsWithAccounts;

            self.allowSubscribe(false);
            let years = Object.getOwnPropertyNames(self.yearsWithAccounts)
                .map(function (year) { return parseInt(year); })
                .sort(function (a, b) { return b - a; });
            self.years(years);
            self.selectedYear(model.CurrentYear ? model.CurrentYear : years[0]);

            self.accounts(self.yearsWithAccounts[self.selectedYear()]);
            if (model.CurrentAccountId) {
                self.selectedAccount(model.CurrentAccountId);
            }
            self.allowSubscribe(true);

            self.onSearch(true);
        };

        self.setReassociatedAccounts = function (data) {
            let accounts = data.Accounts;
            if (!accounts || accounts.length < 1)
                return;

            self.reassociatedAccounts(accounts);
            self.reassociatedOriginalAccountsCount(data.OriginalAccountsCount);
            self.selectedHistoricalAccount(accounts[0].AccountId);
            self.selectedHistoricalAccount.subscribe(function (accountId) {
                if (!params.model.disable() && self.allowSubscribe()) {
                    self.resetFilter();
                    params.model.selectedHistoricalAccount(accountId);

                    setTimeout(function () { self.onSearch(true); }, 20);
                }
            });
        };

        self.reassociatedAccountNameFormatter = function (item) {
            return self.reassociatedOriginalAccountsCount() > 1 ? item.ExtendedName : item.SimpleName;
        };

        self.anyReassociatedAccount = ko.pureComputed(function () {
            return self.reassociatedAccounts().length > 0;
        });

        self.typeEnumFilter.subscribe(function () {
            self.onSearch();
        });

        self.showZeroTransactions.subscribe(function () {
            self.onSearch();
        });

        self.selectedYear.subscribe(function (year) {
            if (self.allowSubscribe()) {
                self.allowSubscribe(false);
                self.accounts(self.yearsWithAccounts[year]);

                let selectedAccount = self.selectedAccount();
                if (selectedAccount && !self.accounts().some(function (account) {
                    return account.id === selectedAccount
                })) {
                    self.selectedAccount(null);
                }
                self.allowSubscribe(true);

                self.onSearch(true);
            }
        });

        self.selectedAccount.subscribe(function () {
            if (self.allowSubscribe()) {
                self.onSearch(true);
            }
        });

        self.clickStatus = function () {
            self.onSearch();
            return true;
        };

        let buildFilterSetter = function (statuses) {
            return function () {
                self.selectedStatuses(statuses);
                self.onSearch();
            };
        };

        self.filterSetters = [
            { text: $.cdh.languageConstant.getConstant('filter.all'), setter: buildFilterSetter(self.allStatuses)},
            { text: $.cdh.languageConstant.getConstant('link.approved/posted'), setter: buildFilterSetter([self.statuses.approved])},
            { text: $.cdh.languageConstant.getConstant('link.pending/processing'), setter: buildFilterSetter([self.statuses.pending])},
            { text: $.cdh.languageConstant.getConstant('link.authorized'), setter: buildFilterSetter([self.statuses.authorized])},
            { text: $.cdh.languageConstant.getConstant('link.denied'), setter: buildFilterSetter([self.statuses.denied])}
        ];

        self.clickExport = function () {
            params.model.onExport(self.getSearchModel());
        };

        self.print = function () {
            params.model.onPrint();
        };

        self.getSearchModel = function () {
            let model = {
                statuses: self.allStatuses,
                showZeroTransactions: self.showZeroTransactions()
            };

            let statuses = self.selectedStatuses();
            if (statuses)
                model.statuses = statuses;

            let type = self.typeEnumFilter();
            if (type)
                model.type = type;

            if (self.historical()) {
                let reassociatedAccountId = self.selectedHistoricalAccount();
                if (reassociatedAccountId)
                    model.reassociatedAccountId = reassociatedAccountId;
            } else {
                let accountId = self.selectedAccount();
                if (accountId && accountId > 0)
                    model.accountId = accountId;

                let year = self.selectedYear();
                if (year)
                    model.year = year;
            }

            if (self.advancedVisible()) {
                let claimNumber = self.claimNumberFilter();
                if (claimNumber)
                    model.claimNumber = claimNumber;

                
                let fromDate = moment(self.fromDateFilter());
                if (fromDate.isValid())
                    model.fromDateS = fromDate.local().format('L');
                
                let toDate = moment(self.toDateFilter());
                if (toDate.isValid())
                    model.toDateS = toDate.local().format('L');

                let fromAmount = parseFloat(self.fromAmountFilter());
                if ($.isNumeric(fromAmount) && !isNaN(fromAmount))
                    model.fromAmount = fromAmount;

                let toAmount = parseFloat(self.toAmountFilter());
                if ($.isNumeric(toAmount) && !isNaN(toAmount))
                    model.toAmount = toAmount;
            }

            return model;
        };

        self.onSearch = function (refreshPageSize) {
            let model = self.getSearchModel();
            params.model.loadData(model, refreshPageSize || false);
        };

        self.clickFind = function () {
            self.selectedStatuses(self.statusFilter() ? [self.statusFilter()] : self.allStatuses);
            self.onSearch();
        };

        self.toggleFilter = function () {
            self.advancedVisible(!self.advancedVisible());
        };

        self.close = function () {
            if (params.model.disable()) {
                return;
            }

            self.advancedVisible(false);

            self.resetFilter ();

            self.onSearch();
        };

        self.advancedVisible.subscribe(updateHeight);

        self.disable = params.model.disable;
        self.hasData = params.model.hasData;

        self.setReassociatedAccounts(params.model.accountsReassociated());
        self.setYearsWithAccount(params.model.transactionsYearsWithAccounts());
    }
})(jQuery, ko);