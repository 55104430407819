(function ($, ko) {

    var claimsAutoPaymentPrefs = function (account) {

        this.accountId = account.accountId;
        this.options = account.options;

        this.entry = {
            PlanAutoPaymentPreferenceEnabled: ko.observable(false),
            PlanAutoReimbursementPreferenceEnabled: ko.observable(false),
            AutoPaymentPreference: ko.observable(0),
            AutoReimbursementPreference: ko.observable(0),
            AllowAutoPaymentPreference: ko.observable($.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateClaimCrossoverAutoPay)),
            AllowAutoReimbursementPreference: ko.observable($.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateAutoReimbursementSettings))
        }

        this.preference = new $.cdh.get.accounts.claimsAutoPaymentPref(this.accountId, this.options);
        this.preference.data.subscribe(function (p) {
            if (p != null) {
                this.entry.PlanAutoPaymentPreferenceEnabled(p.PlanAutoPaymentPreferenceEnabled);
                this.entry.PlanAutoReimbursementPreferenceEnabled(p.PlanAutoReimbursementPreferenceEnabled);
                this.entry.AutoPaymentPreference(p.AutoPaymentPreference);
                this.entry.AutoReimbursementPreference(p.AutoReimbursementPreference);
            }
        }.bind(this));

        this.setClose = function (closer) {
            this.close = closer;
        }.bind(this);

        this.preRender = function () {
            this.preference.load();
        }.bind(this);

        this.submitPreference = new $.cdh.post.accounts.claimsAutoPaymentPref();
        this.canSubmit = ko.pureComputed(function () {
            return !this.submitPreference.isLoading();
        }, this);

        this.onSubmit = function () {
            this.submitPreference
                .load(ko.toJS({
                    Id: this.accountId,
                    Options: this.options,
                    AutoPaymentPreference: this.entry.AutoPaymentPreference,
                    AutoReimbursementPreference: this.entry.AutoReimbursementPreference
                }))
                .done(this.close);
        }.bind(this);
    }

    $.extend(true, $, {
        cdh: {
            models: {
                ClaimsAutoPaymentPrefs: claimsAutoPaymentPrefs
            }
        }
    });
})(jQuery, ko);