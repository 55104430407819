import {CustomLabels} from "../../Common/customLabels";
import template from "./statements-list.html"

ko.components.register('statements-list', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo) {
            return new StatementsPageModel(params, updateContainerHeight(componentInfo.element));
        }
    }
});

function StatementsPageModel(params, updateHeight) {
    this.updateHeight = updateHeight;
    this.accountId = parseInt($.cdh.helper.urlParam('accountId'));
    this.streamline = $.cdh.helper.urlParam('streamline');
    this.title = $.cdh.helper.titlePage();
    this.count = ko.observable(0);

    this.historical = ko.observable(false);
    this.allowHistorical = ko.pureComputed(function () {
        return this.itemsRequest.hasData() && this.itemsRequest.data().AllowHistorical;
    }, this);

    this.currentStepModel = ko.observable(null);
    this.currentStepModel.subscribe(function (val) {
        $.cdh.helper.titleStepModelHandler(val, this.title);
        setTimeout(updateHeight, 600);
    }, this);

    this.years = ko.observableArray([]);
    this.selectedYear = ko.observable();
    this.selectedYear.subscribe(function () {
        if (this.allowToLoadHistoricalData() && this.historical()) {
            this.displayHistoricalData();
        }
    }, this);

    this.yearsItems = ko.pureComputed(function() {
        return $.map(this.years(),
            function (year) {
                return {
                    text: year,
                    setter: function() { this.selectedYear(year); }.bind(this)
                }
            }.bind(this));
    }, this);

    this.itemsRequest = new $.cdh.get.statements.list(this.accountId);
    this.itemsRequest.load();
    this.itemsRequest.data.subscribe(function(response) {

        var items = response.Items;
        //get years
        $.each(items, function (i, obj) {
            this.years.push(obj.Year);
        }.bind(this));

        if (this.years().length > 0)
            this.selectedYear(this.years()[0]);
    }, this);

    this.itemsReassociatedRequest = new $.cdh.get.statements.listReassociated();

    this.gridItems = ko.pureComputed(function () {
        var year = this.selectedYear();

        var yearItems = $.grep(this.itemsRequest.data().Items, function (item) {
            return item.Year === year;
        });

        var result = yearItems.length > 0
            ? yearItems[0].Items
            : [];

        return result;
    }, this);

    this.deliveryOption = ko.observable();
    this.deliveryMethod = ko.observable();
    this.feeAmt = ko.observable();
    this.pinAlreadyValidated = ko.observable(false);
    this.deliveryOptionRequest = new $.cdh.get.statements.getDeliveryOption(this.accountId);
    this.deliveryOptionRequest.load();
    this.deliveryOptionRequest.data.subscribe(function (val) {
        this.deliveryOption(val);
        this.deliveryMethod(val.Method);
        this.feeAmt(val.FeeAmt);
    }, this);
    
    this.additionalFeeText = ko.computed(() => {
        return this.feeAmt() > 0 ? CustomLabels.documentDeliveryPreferences.statements(this.feeAmt()) : null;
    });

    this.pdfPinRequest = new $.cdh.get.taxforms.pdfpin(this.accountId);
    this.pdfPinRequest.load();

    this.accountsRequest = $.cdh.accounts.initAccounts();
    this.accountsReassociatedRequest = new $.cdh.get.accounts.listReassociated();
    this.accountsReassociatedRequest.load({ id: this.accountId});

    this.accountsHsa = ko.observable([]);
    this.selectedHistoricalAccount = ko.observable(null);

    this.aggregatedStatus = new $.cdh.models.aggregatedLoadStatus([
        this.accountsRequest,
        this.accountsReassociatedRequest,
        this.itemsRequest,
        this.deliveryOptionRequest,
        this.pdfPinRequest
    ]);

    this.allowToLoadHistoricalData = ko.pureComputed(function () {
        if (!this.accountsReassociatedRequest.hasData)
            return false;

        if (!this.allowHistorical())
            return false;

        if (this.itemsRequest.data().Count > 0) {
            if (this.itemsRequest.data().HistoricalYear <= 0)
                return false;

            if (this.selectedYear() != this.itemsRequest.data().HistoricalYear)
                return false;
        }

        return this.accountsReassociatedRequest.data().Accounts.length > 0;
    }, this);

    this.reassociatedAccountNameFormatter = function (item) {
        if (this.accountsReassociatedRequest.data().OriginalAccountsCount > 1)
            return item.ExtendedName;
        else
            return item.SimpleName;
    }.bind(this);

    this.aggregatedStatus.isLoading.subscribe(function (isLoading) {
        if (isLoading === false) {
            this.accountsHsa($.cdh.accounts.listHsa());
            this.selectedHistoricalAccount(this.accountsHsa()[0].AccountId);
        }
    }, this);

    this.displayHistoricalData = function () {
        this.historical(true);

        var accountId = this.selectedHistoricalAccount();
        var maxCount = this.itemsRequest.data().HistoricalMaxCount;
        this.itemsReassociatedRequest.load({ id: accountId, count: maxCount});

        this.selectedHistoricalAccount.subscribe(function (accountId) {
            this.itemsReassociatedRequest.load({ id: accountId, count: maxCount });
        }, this);

    }.bind(this);

    this.deliveryMethodName = ko.pureComputed(function () {
        if (this.deliveryOptionRequest.hasData() === false)
            return null;
        
        if (this.deliveryMethod() === $.cdh.enums.statementDeliveryOption.electronicOnly)
            return 'Electronic Only';
        else
            return 'Electronic & Paper';
    }, this);

    this.editPreferences = function () {
        this.currentStepModel(new DeliveryPreferenceStepModel(this));
    }.bind(this);

    this.isMainView = ko.pureComputed(function () {
        return this.currentStepModel() === null;
    }, this);

    this.isDeliveryPreferenceView = ko.pureComputed(function () {
        return this.currentStepModel() !== null && this.currentStepModel().isDeliveryPreferenceStep;
    }, this);

    if (this.streamline == 'true') {
        this.currentStepModel(new DeliveryPreferenceStepModel(this));
    }
}

function DeliveryPreferenceStepModel(model) {
    this.isDeliveryPreferenceStep = true;
    this.model = model;
    this.title = 'E-Statements Opt-In Settings';
}