import template from "./change-password-confirmation-questions.html";
import {ChangePasswordService} from "../Http/Auth/ChangePasswordService";

ko.components.register('change-password-confirmation-questions', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new ConfirmationQuestions(params, widgetInfo);
        }
    }
});

export class ConfirmationQuestions {
    constructor(params, widgetInfo) {
        this.widgetInfo = widgetInfo;
        this.ticket = params.ticket();
        this.cancelCallback = params.cancelCallback;
        this.completeCallback = params.completeCallback;

        this.titleElem = $('title');
        this.oldTitle = this.titleElem.text();
        this.setStandardTitle = () => this.titleElem.text(this.oldTitle);
        this.setErrorTitle = () => this.titleElem.text(`Submission Error | ${this.oldTitle}`);
        
        this.isLogin = params.isLogin;
        this.isLocked = ko.observable(false);
        
        this.questions = ko.observable();
        this.firstQuestionLabel = ko.pureComputed(() => this.questions()?.FirstQuestion.Content);
        this.secondQuestionLabel = ko.pureComputed(() => this.questions()?.SecondQuestion.Content);
        
        this.modelAnswers = {
            firstAnswer: ko.observable('').extend({required: true}),
            secondAnswer: ko.observable('').extend({required: true})
        };

        this.validationAnswers = new $.cdh.validation(this.modelAnswers);
        this.validationAnswers.hasErrors.subscribe(widgetInfo.updateHeight);

        this.changePasswordService = new ChangePasswordService();

        this.nextEnabled = ko.pureComputed(() => !this.changePasswordService.loading());

        this.loadQuestions();
    }
    
    async loadQuestions() {
        let result = null;
        try {
            result = await this.changePasswordService.getQuestionsAsync(this.ticket);
        } catch (e) {                       
            this.setErrorTitle();
            return;
        }

        this.questions(result.data());

        this.widgetInfo.updateHeight();
    }

    async submit() {
        this.validationAnswers.activate();
        if (this.validationAnswers.hasErrors())
            return;

        const model = {
            Ticket: this.ticket,
            FirstQuestion: this.questions().FirstQuestion,
            FirstAnswer: this.modelAnswers.firstAnswer(),
            SecondQuestion: this.questions().SecondQuestion,
            SecondAnswer: this.modelAnswers.secondAnswer()
        };

        this.setStandardTitle();
        try {
            await this.changePasswordService.validateAnswersAsync(model);
        } catch (e) {
            this.changePasswordService.error({message: 'Wrong security question answers.'});
            let message = 'User account is locked out.';
            var contactPhone = $.cdh.brandData.ContactPhone;
            var contactEmail = $.cdh.brandData.ContactEmail;
            var contactNum = `<a href="tel:${contactPhone}  class="h - contacts_phone">${contactPhone}</a>`;
            var contactmail = `<a href="mailto:${contactEmail}  class="h - contacts_email">${contactEmail}</a>`;
            let errorMessage = " ";
            if (contactEmail == "" || contactPhone == "") {
                errorMessage = 'Login Unsuccessful. Please contact us for further assistance ' + contactPhone + contactEmail;
            }
            else {
                errorMessage = 'Login Unsuccessful. Please contact us for further assistance ' + contactPhone + " or " + contactEmail;

            }
            if (e.xhr.responseText.includes(message)) {
                if (this.isLogin) {
                    this.changePasswordService.error(null);
                    this.isLocked(true);
                } else {
                    this.changePasswordService.error({ message: errorMessage });
                    $.cdh.helper.logoutWithMessage(errorMessage);
                }
            }
            this.setErrorTitle();
            return;
        }

        this.completeCallback({
            questionsAnswers: {
                firstQuestion: this.questions().FirstQuestion,
                firstAnswer: this.modelAnswers.firstAnswer(),
                secondQuestion: this.questions().SecondQuestion,
                secondAnswer: this.modelAnswers.secondAnswer()
            }
        });
    }
}