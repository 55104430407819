(function($, ko) {
    var entollmentList = function(updateHeight) {

        var enrollmentViewModel = function(data) {
            $.extend(this, data);

            this.ListLabel = {
                lblEnroll: $.cdh.languageConstant.getConstant('button.enroll'),
                lblWaive: $.cdh.languageConstant.getConstant('button.waive'),
                lblStart: $.cdh.languageConstant.getConstant('button.start'),
                lblContinue: $.cdh.languageConstant.getConstant('button.continue'),
                lblEdit: $.cdh.languageConstant.getConstant('button.edit'),
                lblEnrollmentDates: $.cdh.languageConstant.getConstant('field.enrollment-dates'),
                lblAnnualElectionAmount: $.cdh.languageConstant.getConstant('field.annual-election-amount')
            }

            this.planOptions = new planOptionsModel(data);

            this.rollingEnrollment = !!(data.PlanExtOptions & $.cdh.enums.planExtOptions.allowRollingEnrollment);
            this.enrollmentPeriod = this.rollingEnrollment
                ? "Rolling Enrollment"
                : data.EnrollmentStartDate + " - " + data.EnrollmentEndDate;

            this.enrollVisible = ko.pureComputed(function() {
                var isNew = ko.unwrap(this.IsNew),
                    isWaived = ko.unwrap(this.IsWaived),
                    isReapply = ko.unwrap(this.IsReapply);
                return isNew || isWaived || isReapply;
            }, this);

            this.continueVisible = ko.pureComputed(function() {
                return ko.unwrap(this.IsIncomplete);
            }, this);

            this.waiveVisible = ko.pureComputed(function() {
                return !this.IsWaived;
            }, this);

            this.editVisible = ko.pureComputed(function() {
                var isCompleted = ko.unwrap(this.IsCompleted);
                return isCompleted;
            }, this);

            this.startVisible = ko.pureComputed(function () { 
                return this.enrollVisible() && this.IsHsa;
            }, this);
            
            this.electionVisible = !this.IsHsa 
                && this.AnnualElectMin > 0 
                || this.planOptions.allowEnterElection 
                || this.planOptions.allowEnterPayPeriod;
        };

        function planOptionsModel(plan) {
            var opt = plan.PlanExtOptions;
            var enumOpt = $.cdh.enums.planExtOptions;
            this.allowEnterElection = $.cdh.helper.hasFlag(enumOpt.allowEnterElection, opt);
            this.allowEnterPayPeriod = $.cdh.helper.hasFlag(enumOpt.allowEnterPerPayPeriod, opt);
            this.allowAssociatedDependent = $.cdh.helper.hasFlag(enumOpt.allowAssociatedDependent, opt);
            this.allowAddBrokerId = $.cdh.helper.hasFlag(enumOpt.allowAddBrokerID, opt);
            this.allowChooseCoverageTier = $.cdh.helper.hasFlag(enumOpt.allowChooseCoverageTier, opt);
            this.allowClaimCrossoverAutoPay = $.cdh.helper.hasFlag(enumOpt.allowClaimCrossoverAutoPay, opt);

            this.isCompleted = plan.IsCompleted;
        }
        
        this.hsaTemplate = ko.observable();

        this.enrollments = new $.cdh.get.enrollments.getWithHsaTemplate(
            {single: $.cdh.helper.urlParam('single')},
            function(data) {
                this.hsaTemplate(data.HsaTemplate);
                this.singleView(data.SingleView);
                this.isAnonymousUser(data.IsAnonymousUser);
                return $.map(data.Plans, function(d) { return new enrollmentViewModel(d); });
            }.bind(this));

        this.enrollments.state.subscribe(updateHeight);
        this.enrollments.load();
        
        this.isAnnouncementAvailable = ko.pureComputed(function () {
            return this.enrollments.hasData() && this.hsaTemplate().Announcement !== null &&
                this.hsaTemplate().Announcement.IsAnnouncementAvailable &&
                this.hsaTemplate().Announcement.DisplayAnnouncement;
        }, this);
        
        this.announcementText = ko.pureComputed(function () {
            if (!this.isAnnouncementAvailable())
                return "";

            return this.hsaTemplate().Announcement.AnnouncementText;
        }, this);
        
        this.announcementTitle = ko.pureComputed(function () {
            if (!this.isAnnouncementAvailable()) return "";
            return this.hsaTemplate().Announcement.AnnouncementTitle;
        }, this);
        
        this.includePlanInAnnouncement = ko.pureComputed(function () {
            if (!this.isAnnouncementAvailable()) return "";
            return this.hsaTemplate().Announcement.IncludePlanInAnnouncement;
        }, this);
                
        this.footer = ko.pureComputed(function () {
            if (!this.isAnnouncementAvailable()) return "";
            return this.hsaTemplate().HeaderFooter.PageFooterText.Value;
        }, this);

        this.skipSecondPage =  ko.pureComputed(function () {
           return this.enrollments.hasData() && this.enrollments.data().length === 1
        }, this);

        this.allRequests = new $.cdh.models.aggregatedLoadStatus([
            this.enrollments
            ]);
         
        this.singleView = ko.observable(false);
        this.isAnonymousUser = ko.observable(false);

        this.ListLabels = {
            lblEnrollmentSummary: $.cdh.languageConstant.getConstant('title.enrollment-summary'),
            lblPreText: $.cdh.languageConstant.getConstant('messages.below-are-benefit-plans'),
            lblNoPlan: $.cdh.languageConstant.getConstant('messages.no-plans-available')
        }
    };

    ko.components.register("enrollment-list",
    {
        template: { element: "template-enrollment-list" },
        viewModel: {
            createViewModel: function(params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new entollmentList(updateHeight);
            }
        }
    });

})(jQuery, ko);