import template from "./notifications-count.html"
import {NotificationsService} from "../../Http/NotificationCenter/NotificationsService";

ko.components.register('notifications-count', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new NotificationsCount(params, widgetInfo);
        }
    }
});

export class NotificationsCount {
    constructor(params, widgetInfo) {
        this.notificationsService = new NotificationsService();

        this.count = ko.observable().publishOn($.cdh.topics.notifications.countValue);
        this.loading = ko.observable(true);
        ko.postbox.subscribe($.cdh.topics.notifications.countRefresh, () => {
            this.loading(true);
            this.load();
        });

        this.load();
    }
    
    async load() {
        let result = await this.notificationsService.getCountAsync();
        this.count(result.data());
        this.loading(false);
    }
}