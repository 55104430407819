import template from './change-password.html';
import {ChangePasswordService} from "../Http/Auth/ChangePasswordService";
import {UnlockUserService} from "../Http/Auth/UnlockUserService";

ko.components.register('change-password', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new ChangePassword(params, widgetInfo);
        }
    }
});

export class ChangePassword {
    constructor(params, widgetInfo) {
        let updateHeight = widgetInfo.updateHeight;
        this.changePasswordToken = params.changePasswordToken;

        this.isLogin = params.isLogin;

        this.titleElem = $('title');
        this.oldTitle = this.titleElem.text();
        this.setStandardTitle = () => this.titleElem.text(this.oldTitle);
        this.setErrorTitle = () => this.titleElem.text(`Submission Error | ${this.oldTitle}`);
        this.isLocked = ko.observable(false);

        this.ticket = ko.observable();
        this.confirmationType = ko.observable();
        this.IsSmsOnly = ko.observable(false);
        this.confirmationCompleted = ko.observable(false);
        this.ticketLoaded = ko.observable(false);
        this.questionsAnswers = null;

        this.otpMethod = ko.observable();
        this.email = ko.observable();
        this.mobileNumber = ko.observable();
        this.isSms = ko.observable();
        this.isEmail = ko.observable();
        this.unlockUserIsSms = ko.observable(false);

        this.isOtp = ko.pureComputed(() => {
            if (this.userStatus === $.cdh.enums.cardholderStatus.tempInactive)
                return this.confirmationType() === $.cdh.enums.authConfirmationType.email || this.confirmationType() === $.cdh.enums.authConfirmationType.sms || this.unlockUserIsSms();
            else
                return this.confirmationType() === $.cdh.enums.authConfirmationType.email || this.confirmationType() === $.cdh.enums.authConfirmationType.sms;
        });

        this.isQuestions = ko.pureComputed(() => {
            if (this.userStatus === $.cdh.enums.cardholderStatus.tempInactive)
                return this.confirmationType() === $.cdh.enums.authConfirmationType.questions && !this.unlockUserIsSms();
            else
                return this.confirmationType() === $.cdh.enums.authConfirmationType.questions;
        });

        this.userInfo = new $.cdh.post.authentication.userInfo();
        this.changePasswordService = new ChangePasswordService();
        this.unlockUserService = new UnlockUserService();
        this.generateCodeRequest = new $.cdh.post.authentication.generateCode();

        this.modelPassword = {
            newPassword: ko.observable('').extend({
                passwordComplexity: {
                    userName: $.cdh.helper.isValueSet($.cdh.user.name) ? ko.observable($.cdh.user.name) : null
                },
                notify: 'always'
            }),
            otp: ko.observable().extend({
                required: {
                    onlyIf: () => this.isOtp()
                },
            })
        };
        this.modelPassword.repeatePassword = ko.observable('').extend({
            equal: {params: this.modelPassword.newPassword, message: $.cdh.languageConstant.getConstant('messages.passwords-not-match') }
        });
        
        this.modelPassword.reset = () => {
            this.modelPassword.otp('');
            this.modelPassword.newPassword('');
            this.modelPassword.repeatePassword('');
        };

        this.validationPassword = new $.cdh.validation(this.modelPassword);
        this.validationPassword.hasErrors.subscribe(updateHeight);

        this.passwordStrength = ko.pureComputed(() => {
            let password = this.modelPassword.newPassword();
            if (!password) {
                return 0;
            }
            const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
            const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

            if (strongPassword.test(password)) {
                return 100;
            } else if (mediumPassword.test(password)) {
                return 66.66;
            } else {
                return 33.33;
            }
        });

        this.canSubmit = ko.pureComputed(() => !this.changePasswordService.loading() && !this.validationPassword.hasErrors());

        this.isValidPassword = ko.pureComputed(() => {
            let isValid = this.validationPassword.activated() && !this.validationPassword.hasErrors();
            let noErrors = this.modelPassword.newPassword.error() == null && this.modelPassword.repeatePassword.error() == null;
            return isValid || noErrors;
        });

        this.otpComplete = data => {
            this.confirmationCompleted(true);
            this.otpMethod(data.method);
            this.email(data.email);
            this.mobileNumber(data.mobileNumber);
            this.isSms(data.isSms);
            this.isEmail(data.isEmail);
        };
        
        this.resetConfirmation = () => {
            this.confirmationCompleted(false);
            this.otpMethod(null);
            this.email(null);
            this.mobileNumber(null);
            this.isSms(null);
            this.isEmail(null);
        }

        this.questionsComplete = data => {
            this.confirmationCompleted(true);
            this.questionsAnswers = data.questionsAnswers;
        };

        this.cancelCallback = () => this.cancel();
        this.reloadTicket = async (xhr) => await this.resetTicketIfExpired(xhr);

        this.loadSettings();
    }

    async loadSettings() {
        let userLockedMessage = $.cdh.languageConstant.getConstant('messages.user-locked');
        var contactPhone = $.cdh.brandData.ContactPhone;
        var contactEmail = $.cdh.brandData.ContactEmail;
        var contactNum = `<a href="tel:${contactPhone}  class="h - contacts_phone">${contactPhone}</a>`;
        var contactmail = `<a href="mailto:${contactEmail}  class="h - contacts_email">${contactEmail}</a>`;
        this.ticketLoaded(false);
        let result = null;
        try {
            result = await this.changePasswordService.getInfoAsync(this.changePasswordToken);
        } catch (e) {
            if (e.xhr.responseText.includes(userLockedMessage)) {
                if (contactEmail == "" || contactPhone == "") {
                    $.cdh.helper.logoutWithMessage( $.cdh.languageConstant.getConstant('messages.unsuccessful-login') + contactPhone + contactEmail);
                } else {
                    $.cdh.helper.logoutWithMessage($.cdh.languageConstant.getConstant('messages.unsuccessful-login') + contactPhone + " or " + contactEmail);
                }
               
            }
            this.setErrorTitle();
            return;
        }
        let data = result.data();
        this.ticket(data.Ticket);
        this.confirmationType(data.ConfirmationType);
        if (this.confirmationType() === $.cdh.enums.authConfirmationType.Blocked) {
            if (contactEmail == "" || contactPhone == "") {
                $.cdh.helper.logoutWithMessage($.cdh.languageConstant.getConstant('messages.unsuccessful-login') + contactPhone + contactEmail);
            } else {
                $.cdh.helper.logoutWithMessage($.cdh.languageConstant.getConstant('messages.unsuccessful-login') + contactPhone + " or " + contactEmail);
            }
        }
        this.IsSmsOnly(data.IsSmsOnly);
        this.userStatus = ko.observable(null);
        this.hasContactInfo = ko.observable();
        this.userInfoResult = null;

        await this.userInfo.load({ ticket: this.ticket }).done(function (data) {
            this.userInfoResult = data;
            this.userStatus(data.Status);
            const phoneAvailable = data.MobileNumber || data.MobileNum;
            var continueFlag = this.IsSmsOnly() ? phoneAvailable : (phoneAvailable || data.Email);

            this.hasContactInfo(continueFlag);
        }.bind(this));

        if (this.userInfoResult.Status === $.cdh.enums.cardStatus.permInactive) {
            this.isLocked(true);
        }

        let unlockUserResult = null;
        if (this.userStatus() === $.cdh.enums.cardholderStatus.tempInactive || this.confirmationType() === $.cdh.enums.authConfirmationType.none) {

                unlockUserResult = await this.unlockUserService.getInfoAsync(this.changePasswordToken);
                let unlockUserdata = unlockUserResult.data();
                this.ticket(unlockUserdata.Ticket);
                this.confirmationType(unlockUserdata.ConfirmationType);
                this.unlockUserIsSms(true);
                this.IsSmsOnly(unlockUserdata.IsSmsOnly);
        }

        this.ticketLoaded(true);
    }
    
    async resendOtp() {
        this.setStandardTitle();
        this.generateCodeRequest.load({
            type: this.otpMethod(),
            ticket: this.ticket()
        }).done(data => {
            let settings = {
                title: $.cdh.languageConstant.getConstant('title.code-resent'),
                titleVisible: true,
                message: $.cdh.languageConstant.getConstant('messages.confirmation-code-resent'),
                okVisible: false,
                cancelVisible: false
            };

            $.cdh.helper.alert2(settings);
        }).fail((xhr) => {
            this.resetTicketIfExpired(xhr);
        });
    }

    async submit() {
        this.validationPassword.activate();

        if (!this.canSubmit())
            return;

        if (!this.validationPassword.hasErrors()) {
            let model = {
                Ticket: this.ticket(),
                NewPassword: this.modelPassword.newPassword()
            };

            this.setStandardTitle();
            if (this.isQuestions()) {
                this.changePasswordService.error(null);
                model.FirstQuestion = this.questionsAnswers.firstQuestion;
                model.FirstAnswer = this.questionsAnswers.firstAnswer;
                model.SecondQuestion = this.questionsAnswers.secondQuestion;
                model.SecondAnswer = this.questionsAnswers.secondAnswer;

                try {
                    await this.changePasswordService.changeByQuestionsAsync(model);
                } catch(e) {
                    this.logoutIfUserLocked(e.xhr);
                    this.setErrorTitle();
                    return;
                }

                this.redirectToCallerPage(false);
            }

            if (this.isOtp()) {
                model.Otp = this.modelPassword.otp();
                model.IsSms = this.isSms();
                
                try {
                    await this.changePasswordService.changeByOtpAsync(model);
                } catch(e) {
                    this.changePasswordService.error({ message: e.xhr.responseJSON?.message });
                    this.logoutIfUserLocked(e.xhr);
                    await this.resetTicketIfExpired(e.xhr);
                    return;
                }
                
                this.redirectToCallerPage(false);
            }
        }
    }
    
    logoutIfUserLocked(xhr){
        let message = $.cdh.languageConstant.getConstant('messages.user-locked');
        var contactPhone = $.cdh.brandData.ContactPhone;
        var contactEmail = $.cdh.brandData.ContactEmail;
        var contactNum = `<a href="tel:${contactPhone}  class="h - contacts_phone">${contactPhone}</a>`;
        var contactmail = `<a href="mailto:${contactEmail}  class="h - contacts_email">${contactEmail}</a>`;
        let errorMessage = "";
        if (contactEmail == "" || contactPhone == "") {
            errorMessage = $.cdh.languageConstant.getConstant('messages.unsuccessful-login') + contactPhone + contactEmail;
        } else {
            errorMessage = $.cdh.languageConstant.getConstant('messages.unsuccessful-login') + contactPhone + " or " + contactEmail;
        }
        if(xhr.responseText.includes(message)){
            if(this.isLogin) {
                this.changePasswordService.error(null);
                this.isLocked(true);
            } else {
                this.changePasswordService.error({ message: errorMessage });
                $.cdh.helper.logoutWithMessage(errorMessage);
            }
        }
    }

    isTicketExpired(xhr) {
        return xhr.responseJSON && xhr.responseJSON.data && xhr.responseJSON.data.IsTicketExpired;
    }

    async resetTicketIfExpired(xhr) {
        if (this.isTicketExpired(xhr)) {
            this.changePasswordService.error('');
            alert($.cdh.languageConstant.getConstant('messages.timed-out-message'));
            await this.loadSettings();
            this.resetConfirmation();
            this.modelPassword.reset();
        } else {
            this.setErrorTitle();
        }
    }
    
    didNotReceiveCode() {
        $.cdh.helper.alert2({
            title: $.cdh.languageConstant.getConstant('title.not-receive-code'),
            titleVisible: true,
            message: $.cdh.languageConstant.getConstant('messages.contact-administrator'),
            okVisible: true,
            okCallback: () => this.cancel(),
            cancelVisible: false
        });
    }

    redirectToCallerPage(cancelling) {
        if (cancelling === false) {
            let message = $.cdh.languageConstant.getConstant('messages.password-change-message');
            if (this.changePasswordToken)
                message += $.cdh.languageConstant.getConstant('messages.sign-in-new-password');

            alert(message);
        }
        window.location.href = this.changePasswordToken
            ? $.cdh.routes.authentication.handshake()
            : $.cdh.routes.predefined.profile.profileFull;
    }

    cancel() {
        this.redirectToCallerPage(true);
    }
}