(function ($, ko) {

    function DataAggregatedService() {
        const self = this;
        $.cdh.http.DataRequestHandlerBase.call(self);

        self.all = function (requests) {
            return self.handle(Promise.all(requests));
        };
    }

    $.extend(true, $.cdh, {
        http: {
            DataAggregatedService: DataAggregatedService
        }
    });
})(jQuery, ko);