import template from "./account-activity-edit-expense.html";

(function ($, ko) {
    ko.components.register('account-activity-edit-expense', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityEditExpense(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityEditExpense(params, updateHeight) {
        const self = this;
        const urlParams = {
            page: $.cdh.helper.urlParam('page')
        }
        self.force = urlParams.page === 'AddReceipt';
        
        let model = params.model;
        
        self.isMobile = params.isMobile;
        
        self.isManuallyAdded = model.IsManuallyAdded;
        self.isReceivedViaFile = model.IsReceivedViaFile;

        let totals = model.TotalsExpensesDetails;
        
        self.deleteExpenseModel = {
            EOBType: model.EOBType,
            ExpenseKey: model.ExpenseKey,
            OriginCde: model.OriginCde,
            Amount: model.Amount,
            ServiceStartDate: model.ServiceStartDate,
            ServiceEndDate: model.ServiceEndDate,
            Claimant: model.Claimant,
            EOBExtra: {
                Billed: totals.ProviderChargedAmount,
                InsuranceAllowed: totals.Allowed,
                InsurancePaid: totals.Covered,
                MyResposibility: totals.TotalResponsibility,
                ReimbursedFromMyAccount: totals.Benefit,
                PaidNonReimbursable: totals.OutOfPocket,
                MyRemainingResposibility: model.RemainingResponsibilityAmount
            },
            reloadListCallback: params.reloadListCallback
        };
        
        self.editManualModel = {
            Receipts: model.Receipts,
            ExpenseKey: model.ExpenseKey,
            OriginCde: model.OriginCde,
            reloadListCallback: params.reloadListCallback
        };

        self.editTpsModel = {
            EOBExtra: {
                ExpenseKeys: model.ExpenseKeys,
            },
            reloadListCallback: params.reloadListCallback
        };
    }

})(jQuery, ko);