import {DataServiceBase} from "../DataServiceBase";

export class CacheService extends DataServiceBase {
    constructor() {
        super('/Cache');
    }

    clearAsync(cacheType: number, payload) {
        return this.postAsync(payload, `Clear/${cacheType}`);
    }
}