import template from './claims-portal.html';

import {ComponentBase} from "../Common/componentBase";
import {RegisterDefaultComponent} from "../Common/componentsHelpers";
import {ClaimsPortalService} from "../Http/ClaimPortal/ClaimsPortalService";

export class ClaimsPortalViewModel extends ComponentBase {
    constructor(params) {
        super(params);
        
        this.reloginRequired = ko.observable(false);
        this.externalModel = null;
        this.isSettingsLoaded = ko.observable(false);
        
        this.init();
    }

    async init() {
        this.error(false);
        
        let claimsPortalService = new ClaimsPortalService();
        let settings = await claimsPortalService.getSettingsAsync();
        
        this.externalModel = {
            settings: settings.data(),
            reloginRequired: this.reloginRequired,
            externalUrl: $.cdh.global.claimsPortalPath,
            payloadParams: {
                WcpUrl: `${document.location.protocol}//${document.location.host}`,
                Brand: {
                    LogoUrl: $.cdh.logoUrl,
                    BaseFontColor: $.cdh.brandData.BaseFontColor,
                    MainButtonColor: $.cdh.brandData.MainButtonColor,
                    CancelButtonColor: $.cdh.brandData.CancelButtonColor
                }
            },
            onError: () => {
                this.error(true);
            }
        };

        this.isSettingsLoaded(true);
    }
}

RegisterDefaultComponent('claims-portal', template, ClaimsPortalViewModel);