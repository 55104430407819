(function ($, ko) {
    var uploadReceiptModel = function (item, onSuccessCallback) {
        this.item = item;
        this.files = ko.observableArray([]);
        this.displayHeader = ko.observable(true);
        
        this.uploadErrors = ko.observableArray([]);
        this.uploadingInProgress = ko.observable(false);

        this.isClaim = (this.item.isClaim !== undefined && this.item.isClaim === true && item.ClaimKey) ||
            (this.item.Type === "Claim" &&
            (this.item.TrnKey === undefined || this.item.TrnKey == null) &&
            (this.item.EOBType !== "MedicalClaim") &&
            (this.item.EOBType !== "Transaction"));
               
        if (!this.isClaim && (this.item.TrnKey === undefined || this.item.TrnKey == null)) {
            this.item.TrnKey = this.item.EOBExtra;
        }

        this.request = this.isClaim
            ? new $.cdh.post.receipts.image.addToClaim()
            : new $.cdh.post.receipts.image.addToTransaction();
       
        this.clickSubmit = function() {
            if (this.uploadingInProgress())
                return;

            this.uploadErrors([]);
            this.uploadingInProgress(true);

            var finished = $.Deferred();
            var uploadFile = function(fileIndex) {
                if (fileIndex < 0) {
                    finished.resolve();
                    return;
                }
                var file = this.files()[fileIndex];
                var splittedDataUrl = file.dataUrl.split(/[,:;]/);
                var item = this.item;
                var data = {};
                if (this.isClaim) {
                    data = {
                        claimKey: item.ClaimKey,
                        receipt: {
                            FileName: file.fileName,
                            ContentType: splittedDataUrl[1],
                            Base64: splittedDataUrl[3]
                        }
                    };
                } else {
                    var key = item.TrnKey;
                    if (Array.isArray(key) && key[0])
                        key = key[0];

                    data = {
                        transactionKey: key,
                        file: {
                            FileName: file.fileName,
                            ContentType: splittedDataUrl[1],
                            Base64: splittedDataUrl[3]
                        }
                    };
                }
                
                data.sourceTranDesc = item.sourceTranDesc;

                this.request.isLoading(false);
                this.request.load(data)
                    .done(function (upload) {
                        var item = this.item;
                        var tp = item.EOBType;
                        this.files.remove(file);
                        item.ReceiptsCount(item.ReceiptsCount() + 1);
                        if (item.Status() !== 'Denied') {
                            item.Status(item.SubmittedStatusText ? item.SubmittedStatusText : 'Submitted');
                        }
                        
                        if (upload.Receipts.length > item.Receipts().length) {
                            item.Receipts(upload.Receipts);
                        }
                        
                        if(item.successCallback) {
                            item.successCallback();
                        }
                        
                        var syncItem = this.isClaim 
                        ? { claimKey : item.ClaimKey, itemStatus: item.Status(), receiptsCount : item.ReceiptsCount(), receipts : item.Receipts() , isClaim : true }
                        : { transactionId: item.TransactionId,  itemStatus: item.Status(), receiptsCount : item.ReceiptsCount(), receipts : item.Receipts(),  isClaim : false }

                        this.syncItem = ko.observable(syncItem).syncWith($.cdh.topics.claims.requiredAttentionItems);
                    }.bind(this))
                    .always(uploadFile.bind(this, fileIndex - 1))
                    .fail(this.uploadErrors.push.bind(this.uploadErrors,
                        "Can't upload '" + file.fileName + "'. Please try again."));
            }.bind(this);

            this.errorMessage = function(data) {
                if (!data)
                    return "";

                if (typeof data === 'string')
                    return data;

                var json = JSON.parse(data.responseText);
                return json.message;
            }.bind(this);

            finished.done(function() {
                this.uploadingInProgress(false);
                if (this.files().length === 0) {
                    this.close();
                    if ($.isFunction(onSuccessCallback))
                        onSuccessCallback();
                }
            }.bind(this));

            uploadFile(this.files().length - 1);
        };

        this.clickRemoveImage = function (image, e) {
            this.files.remove(image);
        }.bind(this);

        this.setClose = function (closer) {
            this.close = closer;
        }.bind(this);

    };

    $.extend(true, $, {
        cdh: {
            models: {
                ItemsUploadReceipt: uploadReceiptModel
            }
        }
    });
})(jQuery, ko);
