import template from "./medicine-cabinet-prescription-item.html"

(function ($, ko) {
    ko.components.register('medicine-cabinet-prescription-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new PrescriptionItem(params, widgetInfo.updateHeight);
            }
        }
    });

    function PrescriptionItem(params, updateHeight) {
        const self = this;

        const prescriptionsService = new $.cdh.http.smartAccount.PrescriptionsService();

        let prescription = params.prescription;
        self.prescription = params.prescription;
        self.refreshCallback = params.refreshCallback;

        self.title = prescription.Drug.Title;
        self.price = prescription.Price;

        self.strength = prescription.Drug.Strength;
        self.days = prescription.Days;
        self.packageSize = prescription.Drug.PackageSize;
        self.userName = params.fullName;

        self.opened = ko.observable(false);

        self.onToggle = function (data, e) {
            if (e) {
                e.stopPropagation();
            }            
            self.opened(!self.opened());
            updateHeight();
        };

        self.keyboardToggle = function (data, e) {
            if (e.keyCode === 32 || e.keyCode === 13) {
                self.opened(!self.opened());
                updateHeight();
                return false;
            }
            return true;
        };

        self.deleteDrug = function () {
            $.cdh.helper.delete({
                title: "Delete Drug",
                message: "Are you sure you want to delete drug?",
                okCallback: function () {
                    $.cdh.helper.waitAlert("Drug is being deleted...","Please-Wait");

                    prescriptionsService.deleteAsync(prescription.Id).then(function (result) {
                        self.refreshCallback();
                    }).finally(function () {
                        $.cdh.helper.closePopup();
                    });
                }
            });

            return false;
        };
    }

})(jQuery, ko);