import {DataServiceBase} from "../DataServiceBase";

export class SiteMapService extends DataServiceBase {
    constructor() {
        super('/sitemap');
    }

    getUrlsAsync() {
        return this.getAsync(null, 'urls');
    };
}