import template from "./smart-registration-review.html"

(function ($, ko) {
    ko.components.register('smart-registration-review', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ReviewQuestionsModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ReviewQuestionsModel(params, updateHeight) {
        
        const userData = params.userData;
        this.firstName = userData.firstName();
        this.lastName = userData.lastName(); 
        this.confirmEmail = ko.observable(userData.email()).extend({ required: true, email: true });
        this.confirmEmailValidation = new $.cdh.validation(this.confirmEmail);
        this.confirmEmailValidation.activate();
        this.clickCancel = params.model.onCancel;
        
        // Pre-populate and format the mobile number
        const formatPhoneNumber = function (value) {
            if (!value) return '';
            let digits = value.replace(/\D/g, ''); // Remove non-digit characters
            if (digits.length >= 10) {
                return "(" + digits.substring(0, 3) + ") " + digits.substring(3, 6) + "-" + digits.substring(6, 10);
            }
            return digits;
        };
        const formattedMobileNumber = formatPhoneNumber(userData.mobileNumber());
        this.confirmMobileNumber = ko.observable(formattedMobileNumber);

        this.mobileNumberExists = ko.computed(() => {
            return this.confirmMobileNumber() && this.confirmMobileNumber().trim() !== '';
        });
        
        this.showResultSetup = function () {
            if (!this.confirmEmailValidation.hasErrors()) {
                params.model.onNext({ Success: true, ConfirmEmail: this.confirmEmail, ConfirmMobileNumber: this.confirmMobileNumber });
            }
        }.bind(this);
    }

})(jQuery, ko);