import template from './deductible-status-item.html'

ko.components.register('deductible-status-item', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new DeductibleStatusItem(params, widgetInfo.updateHeight);
        }
    }
});

function DeductibleStatusItem(params, updateHeight) {
    const self = this;
    const model = params.model;

    self.id = model.id;
    self.startDate = model.startDate;
    self.endDate = model.endDate;
    self.status = model.status;
    self.deductible = model.deductible;
    self.oldMetDate = model.metDate;
    self.canSet = model.canSet;
    
    self.isChangingDetails = model.isChanging;

    self.maxDate = moment() > self.endDate ? self.endDate : moment();

    self.changeStatus = function () {
        self.isChangingDetails(true);
    };
    
    self.isSubmitting = model.isSubmitting;
    
    self.changeStatusModel = {
        id: self.id,
        status: self.status,
        oldMetDate: self.oldMetDate,
        startMetDate: self.startDate,
        endMetDate: self.maxDate,
        isParentSubmitting: self.isSubmitting
    };

    self.changeStatusModel.submit = function (isMet, metDate) {
        model.submit({
            isStatusMet: isMet,
            metDate: metDate
        });
    };

    self.changeStatusModel.cancel = function() {
        self.isChangingDetails(false);
    };

    updateHeight();
}