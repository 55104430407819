(function($, ko) { 
    function step1Model(details, parent, updateHeight) {
        $.extend(true, this, details);
        this.planOptions = new planOptionsModel(details.Plan);
        
        function patchTemplateLabels(model) {
            $.each(model, function(i, v) {
                if (i.indexOf("Label") === -1 && model[i+"Label"]) {
                    model[i].Label = model[i + "Label"];
                }
            });
        }

        this.Step1Labels = {
            lblGeneralInfo: $.cdh.languageConstant.getConstant('profile.general-info'),
        };

        this.template = details.Template;
        patchTemplateLabels(this.template.Demographics);

        this.demographicModel = new $.cdh.models.EnrollmentDemographic(details.Demographic, details.Template, details.Plan.IsHsa);
        this.dependentList = parent.dependentList;

        this.authorizedSignerList = new $.cdh.models.AuthorizedSignerList(
            parent.countries, parent.states, parent.readonlyDemographicModel, 
            this.planOptions, this.template, details.Plan.IsHsa, details.Plan.FlexAcctId,
            updateHeight);
        if(!details.CreateEmployee)
            this.authorizedSignerList.load();

        this.attributes = $.extend({},
            details.Attributes,
            {
                coverageTiers: $.map(details.Attributes.CoverageTiers, function(value) {
                    return { key: value.CoverageTierKey, id: value.CoverageTierId, value: value.CoverageTierDesc };
                })
            });
    }

    function step2Model(plan, template, demographic, staticModels, pdfPin, attributes, account, crossoverAutoPay, updateHeight) {
        this.template = template;
        this.attributes = attributes;
        var self = this;
        this.account = account;
        this.plan = plan;
        this.accountId = ko.observable(plan.FlexAcctId);
        this.accepted = ko.observable(false);
        window.addEventListener('acceptEvent', function (event) {
            self.accepted(event.detail.accepted);
        });

        window.addEventListener('rejectEvent', function (event) {
            self.accepted(event.detail.accepted);
        });
        var crossoverAutoPayEnable = $.cdh.helper.hasFlag($.cdh.enums.mccFlags.claimsCrossover, account.MccFlags);
        crossoverAutoPay(crossoverAutoPayEnable);

        this.Step2Labels = {
            lblAccountDetails: $.cdh.languageConstant.getConstant('button.account-details'),
            lblPlanId: $.cdh.languageConstant.getConstant('field.plan-id'),
            lblPlanDesc: $.cdh.languageConstant.getConstant('field.plan-desc'),
            lblPlanStartDate: $.cdh.languageConstant.getConstant('field.plan-start-date'),
            lblPlanEndDate: $.cdh.languageConstant.getConstant('field.plan-end-date'),
            lblPerPayPeriod: $.cdh.languageConstant.getConstant('field.per-pay-period'),
            lblBrokerId: $.cdh.languageConstant.getConstant('field.broker-id'),
            lblCoverageTier: $.cdh.languageConstant.getConstant('field.coverage-tier'),
            lblElection: $.cdh.languageConstant.getConstant('field.election'),
            lblPerPeriodContribution: $.cdh.languageConstant.getConstant('field.per-period-contribution'),
            lblRemainingContribution: $.cdh.languageConstant.getConstant('field.remaining.contribution'),
            lblClaimCrossover: $.cdh.languageConstant.getConstant('field.claims-crossover'),
            lblManualClaims: $.cdh.languageConstant.getConstant('field.manual-claims'),
            lblHsaisAdvantagedAccount: $.cdh.languageConstant.getConstant('messages.hsa-is-tax-advantaged'),
            lblStatementsForms: $.cdh.languageConstant.getConstant('title.statements-forms'),
            lblStatements: $.cdh.languageConstant.getConstant('link.statements'),
            lblTaxForms: $.cdh.languageConstant.getConstant('link.tax-forms'),
            lblElectronicOnly: $.cdh.languageConstant.getConstant('nonhsa.electronic-only'),
            lblElectronicPaper: $.cdh.languageConstant.getConstant('nonhsa.electronic-paper'),
            lblPaperWillIncurAdditionalFees: $.cdh.languageConstant.getConstant('nonhsa.paper-will-incur-additional-fees'),
            lblPaper: $.cdh.languageConstant.getConstant('nonhsa.paper'),
            lblAdditionalFeesForPaperTaxForms: $.cdh.languageConstant.getConstant('nonhsa.additional-fees-paper-tax-forms'),
            lblPrivacyPolicy: $.cdh.languageConstant.getConstant('nonhsa.privacy-policy'),
            lblRecievePrivacyPolicy: $.cdh.languageConstant.getConstant('nonhsa.receive-copy-of-privacy-policy'),
            lblProxyStatements: $.cdh.languageConstant.getConstant('link.proxy-statements'),
            lblElectronicDocumetAccess: $.cdh.languageConstant.getConstant('nonhsa.electronic-document-access'),
            lblOrderToElect: $.cdh.languageConstant.getConstant('nonhsa.order-to-elect'),
            lblPdfPin: $.cdh.languageConstant.getConstant('nonhsa.pdf-pin'),
            lblSpecialInfo: $.cdh.languageConstant.getConstant('field.special-info'),
            lblOtherInfo: $.cdh.languageConstant.getConstant('link.other-info')
        };
        this.beneficiaryEnabled = template.Beneficiary.DisplayPrimBeneficiarySec.Value ||
            template.Beneficiary.DisplayContBeneficiarySec.Value;
        
        this.beneficiaries = {
            displayPrimary: ko.pureComputed(function() {
                return template.Beneficiary.DisplayPrimBeneficiarySec.Value;
            }),
            displayContingent: ko.pureComputed(function() {
                return template.Beneficiary.DisplayContBeneficiarySec.Value;
            }),
            group: new $.cdh.models.BeneficiaryGroupList(
                this.plan.FlexAcctId, demographic, staticModels, updateHeight, template.Beneficiary
            )
        };

        this.planOptions = new planOptionsModel(plan);
        this.allowElectionCalculation = this.plan.ContributionPeriod !== 0;
        this.allowOnlyEditPerPayPeriod = ko.pureComputed(function() {
            if (this.allowElectionCalculation)
                return false;
            if (this.planOptions.allowEnterElection)
                return false;
            return this.planOptions.allowEnterPayPeriod;
        }, this);
        this.allowOnlyEditElection = ko.pureComputed(function() {
            if (this.allowElectionCalculation)
                return false;
            return this.planOptions.allowEnterElection;
        }, this);

        this.aggregatedStatus = new $.cdh.models.aggregatedLoadStatus([
            this.beneficiaries.group.primaryBeneficiaryList.beneficiaries,
            this.beneficiaries.group.contingentBeneficiaryList.beneficiaries
        ]);

        this.trustPaper = ko.observable(getTrustPaperValue(this.plan.TrustPaper, this.template));
        this.paperElectronicTaxForm = ko.observable(this.plan.PaperElectronicTaxForm);
        if (this.paperElectronicTaxForm() != $.cdh.enums.taxFormsPaperOrElectronic.electronic &&
            this.paperElectronicTaxForm() != $.cdh.enums.taxFormsPaperOrElectronic.paper) {
            this.paperElectronicTaxForm($.cdh.enums.taxFormsPaperOrElectronic.electronic);
        }
        this.proxyStatements = {
            value: ko.observable(this.account.ProxyCde),
            description: this.template.ProxyStatements.ProxyStatementDesc,
            visible: this.template.ProxyStatements.DisplayProxyStatements,
            prefix: "proxyStatements",
            options: [
                {
                    key: "N",
                    value: "NO",
                    tooltip: this.template.ProxyStatements.ProxyStatementNoDesc,
                    visible: this.template.ProxyStatements.ProxyStatementNoOption,
                },
                {
                    key: "Y",
                    value: "YES",
                    tooltip: this.template.ProxyStatements.ProxyStatementYesDesc,
                    visible: this.template.ProxyStatements.ProxyStatementYesOption,
                }
            ]
        }

        this.trustPaperDescription = function(trustPaper) {
            switch(trustPaper) {
                case "B":
                    return $.cdh.languageConstant.getConstant("nonhsa.both");
                case "E":
                    return $.cdh.languageConstant.getConstant("nonhsa.electronic-only");
                case "P":
                    return $.cdh.languageConstant.getConstant("nonhsa.paper");
                default:
                    return "";
            }
        }.bind(this);

        this.needToCheckPin = ko.pureComputed(function () {
            var checkForTrustPaper = this.template.PaperEStatementTaxForm.DisplayPaperStatement && this.trustPaper() === 'E';
            var checkForTaxForms = this.template.PaperEStatementTaxForm.DisplayTaxForms && this.paperElectronicTaxForm() === $.cdh.enums.taxFormsPaperOrElectronic.electronic;
            return plan.IsHsa && (checkForTaxForms || checkForTrustPaper) && pdfPin;
        }, this);

        this.pin = {
            value: ko.observable('') 
        };

        this.pin.value.extend({
            equal: {
                message: $.cdh.languageConstant.getConstant("nonhsa.invalid-pin"),
                params: pdfPin
            }
        });

        this.pinValidation = new $.cdh.validation(this.pin);

        this.trustPaper.subscribe((value) => {
            this.togglePinValidation();
        });

        this.electionVisible = this.plan.AnnualElectMin > 0 ||
            this.planOptions.allowEnterElection ||
            this.planOptions.allowEnterPayPeriod;

        this.electionValidation = new $.cdh.validation({});

        this.paperElectronicTaxForm.subscribe((value) => {
            this.togglePinValidation();
        });

        this.togglePinValidation = function () {
            let enableValidate = false;
            if (this.paperElectronicTaxForm() === $.cdh.enums.taxFormsPaperOrElectronic.electronic) {
                enableValidate = true;
            }
            if (this.trustPaper() === 'E') {
                enableValidate = true;
            }

            if (enableValidate) {
                this.pinValidation.activate();
            } else {
                this.pinValidation.deactivate();
            }
        };

        this.isFloat = function(n){
            var num = parseFloat(n);
            if(!num)
                return false;
            return Number(num) === num;
        };

        this.election = {
            min: ko.observable(this.plan.AnnualElectMin),
            max: ko.observable(this.plan.AnnualElectMax),
            contributionPeriod: ko.observable(this.plan.ContributionPeriod),
            _perPeriod: ko.observable((this.plan.AnnualElectAmt !== 0 && this.plan.ContributionPeriod !== 0) ? this.plan.AnnualElectAmt/this.plan.ContributionPeriod : this.account.EmpeElectAmtPp),
            perPeriod: ko.pureComputed({
                read: function () {
                    return this.election._perPeriod();
                },
                write: function (value) {
                    if (!value || !this.isFloat(value))
                        value = 0;
                    this.election._perPeriod(value);
                    var amount = parseFloat(this.election.contributionPeriod()) * value;
                    this.election._amount(Math.round(amount * 100) / 100);
                },
                owner: this
            }),
            _amount: ko.observable(this.plan.AnnualElectAmt !== 0 ? this.plan.AnnualElectAmt : this.account.ElectAmt),
            amount: ko.pureComputed({
                read: function () {
                    return this.election._amount();
                },
                write: function (value) {
                    if(!value || !this.isFloat(value))
                        value = 0;
                    this.election._amount(value);
                    var perPeriod = parseFloat(value) / this.election.contributionPeriod();
                    this.election._perPeriod(Math.round(perPeriod * 100) / 100);
                },
                owner: this
            })
        };

        if (this.plan.IsHsa) {
            this.electionValidation = new $.cdh.validation(this.election);
            this.election.perPeriod.subscribe(function() {
                    this.electionValidation.activate();
                },
                this);
            this.election.perPeriod.extend({
                min: { params: this.election.min(), message: $.cdh.languageConstant.getConstant("nonhsa.min-dollar", { '0': this.election.min() }) },
            });
            this.election.amount = ko.pureComputed(function() {
                return this.election.perPeriod();
            }, this);
        } else {
            if (this.election.max() == 0) {
                this.electionValidation = new $.cdh.validation(this.election);
                this.election.anyMax = ko.observable(false);
                this.election.amount.subscribe(function() {
                        this.electionValidation.activate();
                    },
                    this);
                this.election.amount.extend({
                    min: { params: this.election.min(), message: $.cdh.languageConstant.getConstant("nonhsa.min-dollar", { '0': this.election.min() }) }
                });
            } else {
                this.electionValidation = new $.cdh.validation(this.election);
                this.election.anyMax = ko.observable(true);
                this.election.amount.subscribe(function() {
                        this.electionValidation.activate();
                    },
                    this);
                this.election.amount.extend({
                    max: { params: this.election.max(), message: $.cdh.languageConstant.getConstant("nonhsa.max-dollar", { '0': this.election.max() }) },
                    min: { params: this.election.min(), message: $.cdh.languageConstant.getConstant("nonhsa.min-dollar", { '0': this.election.min() }) }
                });
            }
        }
        
        this.electionValidation.hasErrors.subscribe(updateHeight);

        this.isElectionValid = ko.pureComputed(function () {
            return this.electionValidation && this.electionValidation.activated() ? !this.electionValidation.hasErrors() : true;
        }, this);

        if (this.election.perPeriod() > 0 && this.election.amount() < 0.01) {
            this.election.perPeriod(this.election.perPeriod());
        }
        
        this.accountModel = {
            brokerId: ko.observable(this.account.EmpeBrokerId),
            coverageTierId: ko.observable(this.account.CoverageTierId),
            coverageTierKey: ko.observable(this.account.CoverageTierKey),
            coverageTier: ko.pureComputed({
                read: function() {
                    return this.accountModel.coverageTierKey();
                },
                write: function(value) {
                    $.each(this.attributes.coverageTiers,
                        function(i, v) {
                            if (v.key == value) {
                                this.accountModel.coverageTierKey(v.key);
                                this.accountModel.coverageTierId(v.id);
                            }
                        }.bind(this));
                },
                owner: this
            })
        }

        this.accountDetailsModel = new $.cdh.models.EnrollmentAccountDetails(this.account.AccountDetails);

        this.specialInfoList = new $.cdh.models.SpecialInfoList(this.template.SpecialInfo, this.accountDetailsModel,
            staticModels, updateHeight);
        
        this.isSpecialInfoVisible = ko.pureComputed(function () {
            return this.template.SpecialInfo.IsVisible;
        }, this);

        this.otherInfoList = new $.cdh.models.OtherInfoList(this.template.OtherInfo, this.accountDetailsModel, staticModels, updateHeight);
        this.otherInfoAdfList = new $.cdh.models.OtherInfoAdfList(this.template.OtherInfo, this.accountDetailsModel, staticModels, updateHeight);
        this.otherInfoNdfList = new $.cdh.models.OtherInfoNdfList(this.template.OtherInfo, this.accountDetailsModel, staticModels, updateHeight);
        this.otherSpecialInfoList = new $.cdh.models.OtherSpecialInfoList(this.template.OtherInfo, this.accountDetailsModel, staticModels, updateHeight);
        
        this.otherInfoVisible = ko.pureComputed(function () {
            return !this.otherInfoList.isEmpty() || !this.otherInfoAdfList.isEmpty() || !this.otherInfoNdfList.isEmpty()
                 || this.otherSpecialInfoList.isEmpty(); 
        }, this);

        this.isEnrolled = ko.observable(false);
        this.otherSectionVisibile = ko.pureComputed(function() {
            return this.otherInfoAdfList.nonGroupItems().length > 0 ||
                this.otherInfoNdfList.items().length > 0 ||
                this.otherInfoList.items().length > 0 ||
                this.otherSpecialInfoList.items().length;
        }, this);

        this.activateValidation = function () {
            if (this.electionValidation)
                this.electionValidation.activate();

            if (this.needToCheckPin())
                this.pinValidation.activate();

            this.otherInfoAdfList.activateValidation();
        }.bind(this);

        this.isValid = ko.pureComputed(function () {
            
            var election = this.isElectionValid(),
                beneficiary = this.beneficiaries.group.isValid(),
                otherInfo = this.otherInfoAdfList.isValid() || !this.otherInfoVisible();
            return beneficiary && election && pin && otherInfo;
        }, this);
        
        this.isLoading = ko.pureComputed(function () {
            return  this.beneficiaries.group.primaryBeneficiaryList.beneficiaries.isLoading() ||
                    this.beneficiaries.group.contingentBeneficiaryList.beneficiaries.isLoading();
        }, this);

        document.querySelector('main').focus();
    }

    function getTrustPaperValue(value, template) {
        var trustPaperFirstVisible = function () {
            // if (template.PaperEStatementTaxForm.PaperStatementPaperOption)
            //     return 'P';
            if (template.PaperEStatementTaxForm.PaperStatementElectronicOption)
                return 'E';
            if (template.PaperEStatementTaxForm.PaperStatementBothOption)
                return 'B';

            return '';
        }

        var getTrusPaperOrFirstVisible = function (option, defaultValue) {
            if (option)
                return defaultValue;
            return trustPaperFirstVisible();
        }

        var result = '';
        switch (value) {
            case 'B':
                result = getTrusPaperOrFirstVisible(template.PaperEStatementTaxForm.PaperStatementBothOption, 'B');
                break;
            case 'P':
                result = getTrusPaperOrFirstVisible(template.PaperEStatementTaxForm.PaperStatementPaperOption, 'P');
                break;
            case 'E':
                result = getTrusPaperOrFirstVisible(template.PaperEStatementTaxForm.PaperStatementElectronicOption, 'E');
                break;
            default:
                result = trustPaperFirstVisible();
                break;
        }

        return result;
    }

    function step3Model(plan, template, demographic, authorizedSignerList, step2Model, currentStep, selfDelete, updateHeight) {
        this.plan = plan;
        this.template = template;
        this.fakeValidation = new $.cdh.validation({alwaysRequired: ko.observable(null).extend({required: true})});
        this.fakeValidation.activate();
        this.demographic = $.extend(true,{ }, demographic);
        this.demographic.fullName = ko.pureComputed(function() {
            return this.FirstName + " " + this.LastName;
        }, this.demographic);
        this.demographic.attributes = this.template.Demographics;
        this.demographic.hasGender = ko.pureComputed(function() {
            return this.Gender != null && this.Gender != $.cdh.enums.gender.none;
        }, this.demographic);
        this.demographic.editDemographic = function() {
            selfDelete();
            currentStep('step1');
        };

        this.demographic.hasPermissionsToEditDemographic = true;
        if (!$.cdh.user.isAnonymousEnrollmentUser) {
            this.demographic.hasPermissionsToEditDemographic = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateProfileDemographicInformation);
        }

        this.editElection = function() {
            currentStep('step2');
        };
        
        this.Step3Labels = {
            lblStatements: $.cdh.languageConstant.getConstant('link.statements'),
            lblTaxForms: $.cdh.languageConstant.getConstant('link.tax-forms'),
            lblProxyStatements: $.cdh.languageConstant.getConstant('link.proxy-statements'),
            lblPlanStartDate: $.cdh.languageConstant.getConstant('field.plan-start-date'),
            lblPerPayPeriod: $.cdh.languageConstant.getConstant('field.per-pay-period'),
            lblPlanEndDate: $.cdh.languageConstant.getConstant('field.plan-end-date'),
            lblHsaisAdvantagedAccount: $.cdh.languageConstant.getConstant('messages.hsa-is-tax-advantaged'),
            lblParticipantDemo: $.cdh.languageConstant.getConstant('title.participant-demographics'),
            lblDateOfBirth: $.cdh.languageConstant.getConstant('profile.date-of-birth'),
            lblSsn: $.cdh.languageConstant.getConstant('profile.ssn'),
            lblPhone: $.cdh.languageConstant.getConstant('profile.phone'),
            lblEmail: $.cdh.languageConstant.getConstant('profile.email'),
            lblHomeAddres: $.cdh.languageConstant.getConstant('profile.home-address'),
            lblMailingAddress: $.cdh.languageConstant.getConstant('profile.mailing-address'),
            lblDisclosures: $.cdh.languageConstant.getConstant('title.disclosures'),
            lblAccountDetails: $.cdh.languageConstant.getConstant('button.account-details'),
            lblAnnualElection: $.cdh.languageConstant.getConstant('field.annual-election-1'),
            lblClaimCrossover: $.cdh.languageConstant.getConstant('field.claims-crossover'),
            lblBrokerId: $.cdh.languageConstant.getConstant('field.broker-id'),
            lblManualClaims: $.cdh.languageConstant.getConstant('field.manual-claims'),
            lblAgreements: $.cdh.languageConstant.getConstant('field.agreements'),
            lblSpecialInfo: $.cdh.languageConstant.getConstant('field.special-info'),
            lblEditInfo: $.cdh.languageConstant.getConstant('link.edit-info'),
            lblOtherInfo: $.cdh.languageConstant.getConstant('link.other-info'),
            lblElectronicSignature: $.cdh.languageConstant.getConstant('title.electronic-signature'),
            lblFirstName: $.cdh.languageConstant.getConstant('field.first-name'),
            lblConfirmFirstName: $.cdh.languageConstant.getConstant('nonhsa.confirm-first-name'),
            lblDateSigned: $.cdh.languageConstant.getConstant('nonhsa.date-signed'),
            lblLastName: $.cdh.languageConstant.getConstant('registration.last-name'),
            lblConfirmLastName: $.cdh.languageConstant.getConstant('nonhsa.confirm-last-name'),
            lblEditAccount: $.cdh.languageConstant.getConstant('link.edit-account-details')
        };

        this.annualElectionVisible = step2Model.planOptions.allowEnterElection;
        this.onlyPerPeriodVisible = step2Model.planOptions.allowEnterPayPeriod && !this.annualElectionVisible;
        this.crossoverAutoPayVisible = step2Model.planOptions.allowClaimCrossoverAutoPay;
        this.manualOptinVisible = step2Model.planOptions.allowManualClaim;
        this.authorizedSignerList = authorizedSignerList;
        this.step2Model = step2Model;

        this.disclosures = new $.cdh.models.DisclosureList(this.template.Disclosures);
        this.agreements = new $.cdh.models.AgreementList(this.template.Agreements);

        this.adaCompliantDisclosureCustomEndText = () => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = this.template.Disclosures.DisclosureCustomEndText.DescText;
            const strongEle = tempDiv.querySelector('strong')
            if (strongEle) {
                strongEle.setAttribute("aria-level", 3);
                strongEle.setAttribute("role", "heading");
            }
            return tempDiv.innerHTML;
        }

        this.disclosuresAgree = ko.observable(false);
        if (this.template.Disclosures.DisplayDisclosures.Value)
            this.disclosuresAgree = ko.observable(false).extend({ checked: {} });

        this.allDisclosureAgree = ko.pureComputed(function() {
            var items = $.grep(this.disclosures.items, function(item) {
                return item.accepted();    
          }.bind(this));
            return items.length === this.disclosures.items.length;
        }, this);

        this.allAgree = ko.pureComputed(function() {
            var discl = this.disclosures.allAgree() || !this.template.Disclosures.DisplayDisclosures.Value,
                agreem = this.agreements.allAgree() || !this.template.Agreements.DisplayAgreements.Value,
                disclFinal = this.disclosuresAgree() || !this.template.Disclosures.DisplayDisclosures.Value;
            return discl && agreem && disclFinal;
        }, this);
        
        this.signModel = {
            firstName: ko.observable().extend({ required: true, equalIgnoreCase: { params: demographic.FirstName, 
                message: $.cdh.languageConstant.getConstant("nonhsa.first-name-differs") }}),
            confirmFirstName: ko.observable(),
            lastName: ko.observable().extend({ required: true, equalIgnoreCase: { params: demographic.LastName, 
                message: $.cdh.languageConstant.getConstant("nonhsa.last-name-differs") }}),
            confirmLastName: ko.observable(),
            dateSigned: moment(),
            disclosuresAgree: this.disclosuresAgree
        };

       
        this.signModel.confirmFirstName.extend({ required: true, equal: { params: this.signModel.firstName, 
            message: $.cdh.languageConstant.getConstant("nonhsa.first-name-not-match") }});
        this.signModel.confirmLastName.extend({ required: true, equal: { params: this.signModel.lastName, 
            message: $.cdh.languageConstant.getConstant("nonhsa.last-name-not-match") }});
       
        this.signValidation = new $.cdh.validation(this.signModel);
        this.documentsValidation = new $.cdh.validation(this.agreements.items);

        this.signed = ko.pureComputed(function () {
            var firstName = this.signModel.firstName();
            if (!firstName)
                firstName = "";

            var demographicFirstName = demographic.FirstName;
            if (!demographicFirstName)
                demographicFirstName = "";

            var lastName = this.signModel.lastName();
            if (!lastName)
                lastName = "";

            var demographicLastName = demographic.LastName;
            if (!demographicLastName)
                demographicLastName = "";

            var fisrt = firstName.toUpperCase() == demographicFirstName.toUpperCase(),
                second = lastName.toUpperCase() == demographicLastName.toUpperCase(),
                valid = !this.signValidation.hasErrors();
            return fisrt && second && valid || !this.plan.IsHsa;
        }, this);

        this.signModel.firstName.subscribe(updateHeight);
        this.signModel.lastName.subscribe(updateHeight);
        this.signValidation.hasErrors.subscribe(updateHeight);
        this.validationActivated = ko.observable(true);

        this.activateValidation = function() {
            this.signValidation.activate();
            this.documentsValidation.activate();
            this.validationActivated(true);
        }.bind(this);

        this.isValid = function () {
            
            if (this.plan.IsHsa && this.template.ESignature.DisplayEsignature && this.signValidation.hasErrors())
                return false;
            if (!this.allAgree())
                return false;
            if (!step2Model.beneficiaries.group.isValid())
                return false;

            return true;
        }.bind(this);

        document.querySelector('main').focus();
    }

    function submittedModel(template) {
        $.cdh.helper.replacePageTitle($.cdh.languageConstant.getConstant("nonhsa.thank-you"));
        this.displayTexts = ko.pureComputed(function() {
            var text = template.Introduction.EnrollCompleteText.Value;
            var lines = text.split(".");
            $.each(lines, function(i) { lines[i] += "." });
            return $.grep(lines, function(line) { return line.length > 1});
        });
        this.displayText = ko.pureComputed(function () {
            return template.Introduction.EnrollCompleteText.Value;
        });
        this.done = function() {
            window.location.href = $.cdh.routes.predefined.enrollments.pageList;
        }

        document.querySelector('main').focus();
    }

    function planOptionsModel(plan) {
        var opt = plan.PlanExtOptions;
        var enumOpt = $.cdh.enums.planExtOptions;
        this.allowEnterElection = $.cdh.helper.hasFlag(enumOpt.allowEnterElection, opt);
        this.allowEnterPayPeriod = $.cdh.helper.hasFlag(enumOpt.allowEnterPerPayPeriod, opt);
        this.allowAssociatedDependent = $.cdh.helper.hasFlag(enumOpt.allowAssociatedDependent, opt);
        this.allowAddBrokerId = $.cdh.helper.hasFlag(enumOpt.allowAddBrokerID, opt);
        this.allowChooseCoverageTier = $.cdh.helper.hasFlag(enumOpt.allowChooseCoverageTier, opt);
        this.allowClaimCrossoverAutoPay = $.cdh.helper.hasFlag(enumOpt.allowClaimCrossoverAutoPay, opt);
        this.allowManualClaim = $.cdh.helper.hasFlag(enumOpt.allowManualClaims, opt);

        this.isCompleted = plan.IsCompleted;
    }

    function demographicsAddressModel(model) {
        this.AddressLine1 = model.addressLine1();
        this.AddressLine2 = model.addressLine2();
        this.City = model.city();
        this.State = model.state();
        this.Country = model.country();
        this.Zip = model.zip();
    }
    
    function enrollAccount(updateHeight) {
        this.enrollmentId = ko.observable();
        this.needCreateEmployer = ko.observable(true);
        this.needCreateEmployer.extend({ notify: 'always' });
        this.callComputed = ko.observable();

        this.AccountLabels = {
            lblSaveForLater: $.cdh.languageConstant.getConstant('button.save-for-later'),
            lblCancel: $.cdh.languageConstant.getConstant('button.cancel')
        }

        this.steps = [
            { id: "step1", name: "Step 1", className: "step-1", isActive: ko.observable(true) },
            { id: "step2", name: "Step 2", className: "step-2", isActive: ko.observable(false) },
            { id: "step3", name: "Step 3", className: "step-3", isActive: ko.observable(false) },
        ];

        this.currentStep = ko.observable("step1");
        this.currentStep.subscribe(function(value) {
                $.each(this.steps, function() { this.isActive(this.id === value); });
            },
            this);
        this.finished = ko.pureComputed(function() { return this.currentStep() === "submitted" }, this);

        this.textActiveStep = ko.pureComputed(function () {
            let currentStep = this.steps.filter(step => ko.unwrap(step.isActive));
            let text = '';
            if (currentStep.length) {
                text = $.cdh.languageConstant.getConstant('title.you-are-on-step', { 0: currentStep[0].name.toLowerCase(), 1: this.steps.length });
            }

            return text;
        }, this);

        this.details = new $.cdh.get.enrollments.details(function(data) {
            return new step1Model(data, this, updateHeight);
        }.bind(this));
        this.details.state.subscribe(updateHeight);

        this.step2Model = null;
        this.step3Model = null;
        this.submittedModel = null;

        this.headerTitleLabel = "";
        this.headerTitle = ko.pureComputed(function() {
            if (this.details.hasData()) {
                this.headerTitleLabel = $.cdh.languageConstant.getConstant("title.online-enrollment", { '0': this.details.data().Plan.Id });
                }
                var result = this.headerTitleLabel;
                if (result) {
                    $.cdh.helper.titlePage(result);
                }
                return result;
            },
            this);

        this.saveDemographicRequest = new $.cdh.post.enrollments.saveDemographic();
        this.cancelEnrollmentRequest = new $.cdh.post.enrollments.cancel();

        this.enrollmentId.subscribe(function(key) {
            if (key) {
                this.details.load({ enrollmentKey: key });
            }
        }.bind(this));

        this.states = new $.cdh.get.scontent.states(function(result) {
            var states = $.map(result,
                function(value, key) {
                    return { key: key, value: value };
                });
            return states;
        });

        this.countries = new $.cdh.get.scontent.countries(function(result) {
            var countries = $.map(result,
                function(value, key) {
                    return { key: key, value: value };
                });
            return countries;
        });

        this.enrollmentsCountries = new $.cdh.get.scontent.enrollmentsCountries(function(result) {
            var countries = $.map(result,
                function(value, key) {
                    return { key: key, value: value };
                });
            return countries;
        });

        this.staticModels = {
            states: this.states,
            countries: this.enrollmentsCountries
        };
        
        this.crossoverAutoPay = ko.observable(false);
        this.saveForLaterClicked = ko.observable(false);

        this.saveDemographic = function() {
            let model = this.details.data().demographicModel;
            model.validation.activate();
            if (!model.validation.hasErrors()) {
                var sendModel = model.toServerModel();
                return this.saveDemographicRequest.load(sendModel);
            }
            return { done: $.noop };
        }.bind(this);

        this.submitFlexAccount = new $.cdh.post.enrollments.submitFlexAccount();

        this.submitAccount = function(step, electCodeOverride) {
            let plan = this.details.data().Plan;

            var submitModel = {
                PlanKey: plan.Key,
                FlexAcctId: plan.FlexAcctId,
                AcctTypeCde: plan.AccountType,
                ContributionPeriod: plan.ContributionPeriod,
                CoverageTierKey: -1
            };
            switch (step) {
            case "step1":
            {
                $.extend(submitModel,
                    {
                        InitializePlan: true,
                        ElectCode: electCodeOverride || $.cdh.enums.empeElectCde.didNotEnroll,
                        ElectAmt: plan.AnnualElectMin > 0 ? plan.AnnualElectMin : 0
                    });
                break;
            }
            case "step2":
            case "step3":
            {
                var model = this.step2Model;
                $.extend(submitModel,
                    {
                        ElectCode: electCodeOverride ||
                        (
                            step == "step3"
                                ? $.cdh.enums.empeElectCde.participate
                                : $.cdh.enums.empeElectCde.didNotEnroll),
                        TrustPaper: model.trustPaper,
                        PaperElectronicTaxForm: model.paperElectronicTaxForm,
                        ProxyCde: model.proxyStatements.value(),
                        ElectAmt: model.election.amount(),
                        EmpeElectAmtPp: model.election.perPeriod(),
                        ContributionPeriod: plan.ContributionPeriod,
                        EsigCardCde: step == "step3" ? this.stepModel().signed() : "",
                        EmpeBrokerId: model.accountModel.brokerId(),
                        CoverageTierKey: model.accountModel.coverageTierKey(),
                        CoverageTierId: model.accountModel.coverageTierId(),
                        AccountDetails: ko.toJS(model.accountDetailsModel),
                        CrossoverAutoPayEnable: this.crossoverAutoPay(),
                        AllowManualClaims: model.account.AllowManualClaims
                    });
                break;
            }
            }
            return this.submitFlexAccount.load(submitModel);
        }.bind(this);

        this.nextStepEnabled = ko.computed(function() {
                switch (this.currentStep()) {
                case "step1":
                {
                    var step1 = this.details.data();
                    if (!step1) return false;
                    var demographic = step1.demographicModel;
                    return (demographic && demographic.validation.activated())
                        ? !demographic.validation.hasErrors()
                        : true;
                }
                case "step2":
                {
                            var stepModel = this.stepModel();
                            if (stepModel.needToCheckPin()) {
                                return stepModel.accepted() && stepModel.isEnrolled();
                            }
                           
                             return stepModel.isEnrolled();
                            
                            
                }
                case "step3":
                {
                    return true;
                }
                };
                return true;
            },
            this);
        this.nextStepEnabled.subscribe(updateHeight);

        this.saveForLateEnabled = ko.pureComputed(function() {
                switch (this.currentStep()) {
                case "step2":
                {
                    var stepModel = this.stepModel();
                    return true;
                }
                case "step3":
                {
                    return true;
                }
                }
                return this.nextStepEnabled();
            },
            this);

        this.stepModel = function() {
            switch (this.currentStep()) {
            case "step1":
                return this.details.data();
            case "step2":
                if (this.step2Model == null) {
                    var data = this.details.data();
                    try {
                        this.step2Model = new step2Model(
                            data.Plan,
                            data.Template,
                            this.readonlyDemographicModel,
                            this.staticModels,
                            data.PdfPin,
                            data.attributes,
                            data.Account,
                            this.crossoverAutoPay,
                            updateHeight);
                        this.aggregatedStatus.add(this.step2Model.aggregatedStatus);
                    } catch (ex) {
                        console.error(ex);
                        this.step2Model = {}; // prevent cycling reload
                    }
                }
                return this.step2Model;
            case "step3":
                if (this.step3Model == null) {
                    var details = this.details.data();
                    this.step3Model = new step3Model(details.Plan,
                        details.Template,
                        details.Demographic,
                        details.authorizedSignerList,
                        this.step2Model,
                        this.currentStep,
                        function() {
                            this.step3Model = null;
                        }.bind(this),
                        updateHeight);
                }
                return this.step3Model;
            case "submitted":
                if (this.submittedModel == null) {
                    this.submittedModel = new submittedModel(this.details.data().Template);
                }
                return this.submittedModel;
            }
        }.bind(this);

        this.cancel = function () {
            this.saveForLaterClicked (false);

            $.cdh.helper.alert($.cdh.languageConstant.getConstant("alerts.cancel-enrollment-progress"),
                function () {
                    var flexAccountId = this.details.data().Plan.FlexAcctId;
                    var isNewHsa = this.details.data().Plan.IsHsa &&  this.details.data().Plan.IsNew && this.details.data().Plan.IsIncomplete;
                    if (flexAccountId && !this.details.data().Plan.IsReapply &&
                        this.details.data().Account.AcctStatusCde == $.cdh.enums.cardholderAccountStatus.enrollment && !isNewHsa) {
                        var model = {
                            flexAccountId: flexAccountId
                        };

                        this.cancelEnrollmentRequest.load(model)
                            .done(function () {
                                this.redirectToHome();
                            }.bind(this));
                    } else {
                        this.redirectToHome();
                    }
                }.bind(this));
        }.bind(this);

        this.redirectToHome = function() {
            window.location.href = $.cdh.routes.predefined.enrollments.pageList;
        }

        this.saveForLater = function() {
            this.saveForLaterClicked (true);
            switch (this.currentStep()) {
            case "step1":
                this.saveDemographic().done(function() {
                    this.submitAccount(this.currentStep(), $.cdh.enums.empeElectCde.didNotEnroll).done(function() {
                        this.redirectToHome();
                    }.bind(this));
                }.bind(this));
                break;
            case "step2":
            case "step3":
                this.submitAccount(this.currentStep(), $.cdh.enums.empeElectCde.didNotEnroll).done(function() {
                    this.redirectToHome();
                }.bind(this));
                break;
            }
        }.bind(this);

        this.saveForLaterKeyDown = function(data, e) {
            if (e.keyCode === 13 || e.keyCode === 32) {
                this.saveForLater();
            }
            return true;
        }.bind(this);

        this.nextStep = function() {
            this.saveForLaterClicked (false);
            switch (this.currentStep()) {
            case "step1":
                this.saveDemographic().done(function(data) {
                    this.submitAccount(this.currentStep()).done(function(account) {
                        // Reload Details
                        this.details.load({ enrollmentKey: this.enrollmentId() }).done(function() {
                            this.currentStep("step2");
                        }.bind(this));
                    }.bind(this));
                }.bind(this));
                break;
            case "step2":
                
                this.stepModel().activateValidation();
                
                this.submitAccount(this.currentStep()).done(function(account) {
                    this.currentStep("step3");
                    $(window).scrollTop(0);
                }.bind(this));
                break;
            case "step3":
                if (this.isSubmitting())
                    return;
                
                this.stepModel().activateValidation();
                if (!this.stepModel().isValid()) {
                    $(window).scrollTop(0);
                    return;
                }
                
                this.submitAccount(this.currentStep()).done(function() {
                    this.currentStep("submitted");
                }.bind(this));
                break;
            }
        }.bind(this);

        this.nextButtonText = ko.pureComputed(function() {
                if (this.isSubmitting() && !this.cancelEnrollmentRequest.isLoading() && !this.saveForLaterClicked())
                    return $.cdh.languageConstant.getConstant("button.submitting");

                switch (this.currentStep()) {
                case "step1":
                case "step2":
                        return $.cdh.languageConstant.getConstant('button.next');
                case "step3":
                        return $.cdh.languageConstant.getConstant('button.submit');
                }
            }.bind(this),
            this);

        this.readonlyDemographicModel = ko.pureComputed(function() {
                const data = this.details.data();
                return data ? new demographicsAddressModel(data.demographicModel) : null;
            },
            this);

        this.aggregatedStatus = new $.cdh.models.aggregatedLoadStatus([
            this.details,
            this.states,
            this.countries,
            this.enrollmentsCountries
        ]);

        this.isPopupDefine = ko.observable(false);
        this.canCallDependentPopup = ko.pureComputed({
            read: function() {
                this.callComputed();
                if (this.details.hasData() && !this.details.isLoading() && !this.isPopupDefine()) {
                    this.isPopupDefine(true);
                    return !this.details.data().CreateEmployee;
                }

                return !this.needCreateEmployer();
            }
        }, this);
    
        this.clickAddDependent = function() {
            this.saveDemographic().done(function() {
                this.needCreateEmployer(false);
                this.callComputed.notifySubscribers();
                $("#addDependent").click();
            }.bind(this));
        }.bind(this);

        this.isNotReady = ko.pureComputed(function() {
            const detailsReady = !this.details.hasData();
            switch(this.currentStep()) {
            case "step1":
                return detailsReady;
            case "step2":
                return this.stepModel().isLoading() || detailsReady;
            }
        }, this);

        this.isSubmitting = ko.pureComputed(function() {
            const demographicSaving = this.saveDemographicRequest.isLoading();
            const flexAccountSaving = this.submitFlexAccount.isLoading();
            const cancelLoading = this.cancelEnrollmentRequest.isLoading();
            
            return demographicSaving || flexAccountSaving || cancelLoading;
        }, this);

        this.isNotReady.subscribe(updateHeight);
        this.isSubmitting.subscribe(updateHeight);

        this.states.load();
        this.countries.load();
        this.enrollmentsCountries.load();
        this.enrollmentId($.cdh.helper.urlParam("enrollmentKey"));
    }

    ko.components.register("enrollment-account", {
        template: {element: "template-enrollment-account"},
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new enrollAccount(updateHeight);
            }
        }
    });


})(jQuery, ko);
