(function (module, ko) {

    var getOptionName = function(option, specifics) {
        switch (option) {
        case specifics.optionSettings.electronic.value:
            return specifics.optionSettings.electronic.text;
        case specifics.optionSettings.paper.value:
            return specifics.optionSettings.paper.text;
        }

        return null;
    }

    module.ListFiltersViewModel = function (accountId, specifics, navigator) {
        var self = this;

        self.yearsVisible = ko.observable(false);

        self.ListLabels = {
            lblButtonDeliveryPreferences: $.cdh.languageConstant.getConstant('button.delivery-preferences'),
            lblCurrentDeliveryMethod: $.cdh.languageConstant.getConstant('field.current-delivery-method-1'),
            lblChooseAccount: $.cdh.languageConstant.getConstant("filter.choose-your-account"),
            lblChooseHsaAccount: $.cdh.languageConstant.getConstant("filter.choose-your-hsa-account")
        };

        // accounts
        self.accountId = accountId;
        self.account = ko.observable(null);
        self.accounts = ko.observableArray([]);
        self.selectedAccountName = ko.observable();
        self.accountsVisible = ko.pureComputed(function() {
            return self.accounts().length > 1;
        });

        self.chooseAccountTitle = ko.pureComputed(function () {
            if (self.accountVeba()) {
                return self.ListLabels.lblChooseAccount;
            }

            return self.ListLabels.lblChooseHsaAccount;
        });

        self.accountVeba = ko.pureComputed(function () {
            if (self.account() != null && self.account().AccountTypeClass == $.cdh.enums.accountTypeClass.veba) {
                return true;
            }
            return false;
        });


        module.accountsService.loadSettersForSelect(accountId, specifics.routes.pageList, function (result) {
            self.account(result.account);
            self.accounts(result.accounts);
            self.selectedAccountName(result.account ? result.account.AccountName : null);
        });

        // delivery preferences
        self.deliveryOptionName = ko.observable();
        self.editPreferences = navigator.goToPreferences;

        module.postbox.subscribe(function (option) {
                self.deliveryOptionName(getOptionName(option, specifics));
            },
            self,
            module.topics.preferencesLoaded
        );
    }

})(window.docDelivery, window.ko);