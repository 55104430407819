import template from "./smart-registration-unable-registration.html";

ko.components.register('smart-registration-unable-registration', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo) {
            return new UnableRegistration(params);
        }
    }
});

export class UnableRegistration {
    constructor(params) {
        this.model = params.model;
        this.errorMessage = this.model.errorMessage;
    }
    
    back() {
        this.model.onNext();
    }
}