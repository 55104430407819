(function ($) {
    var bankAccountModel = function (bankAccount, onUpdate) {
        $.extend(true, this, bankAccount);
        this.editModel = new $.cdh.models.BankAccountsEditPopup(bankAccount, onUpdate);
        this.validationModel = new $.cdh.models.BankAccountsValidationPopup(bankAccount, onUpdate);
        this.isDetailsVisible = ko.observable(false);
        this.deleteRequest = new $.cdh.post.bankAccounts.delete(bankAccount.BankAccountId);
        this.deletable = ko.observable(
            bankAccount.Status === $.cdh.enums.bankAccountStatus.validationRequired || 
            bankAccount.Status === $.cdh.enums.bankAccountStatus.pendingValidation || 
            bankAccount.Status === $.cdh.enums.bankAccountStatus.confirmed ||
            bankAccount.Status === $.cdh.enums.bankAccountStatus.fixAccount
            );
        this.validatable = ko.observable(bankAccount.Status === $.cdh.enums.bankAccountStatus.validationRequired);
        this.isAccountNumberOpened = ko.observable(false);

        var isTouchScreen = ('ontouchend' in window);

        this.deleteClick = function () {
            if ($.cdh.user.impersonated || this.deleteRequest.isLoading() || !this.deletable())
                return;

            $.cdh.helper.delete({
                title: $.cdh.languageConstant.getConstant('title.delete-bank-account'),
                message: $.cdh.languageConstant.getConstant('messages.delete-bank-account-1'),
                okCallback: function () {
                    this.deleteRequest.load().done(onUpdate).fail(function (resp) {
                        $.cdh.helper.closePopup();
                        $.cdh.helper.alert(resp.responseJSON.message, null, null, "Failed to delete account");
                    })
                }.bind(this)
            });
        };

        this.toggleAccountNumber = function (data, event) {
            this.isAccountNumberOpened(!this.isAccountNumberOpened());
            event.preventDefault();
            event.stopPropagation();
            return false;
        };

        this.onIconViewTextMouseOver = function() {
            if (isTouchScreen) {
                return;
            }
            this.isAccountNumberOpened(true);
        };

        this.onIconViewTextMouseOut = function() {
            if (isTouchScreen) {
                return;
            }
            this.isAccountNumberOpened(false);
        };

        this.onCardKeyDown = function(card, e) {
            if (e.keyCode === 32 || e.keyCode === 13) {
                this.isDetailsVisible(!this.isDetailsVisible());
                return false;
            }
            return true;
        }.bind(this);
    }

    var bankAccountsListModel = function (updateHeight) {
        var dataPartnerId = $.cdh.helper.urlParam('dataPartnerId');

        this.addBankAccountModelBuilder = function () {
            return new $.cdh.models.BankAccountsAddPopup(dataPartnerId, this.init);
        }.bind(this);

        this.bankAccounts = new $.cdh.get.bankAcount.get(dataPartnerId,
            function (json) {
                var models = $.map(json, function (ba) {
                    var bankAccount = new bankAccountModel(ba, this.bankAccounts.load);
                    bankAccount.isDetailsVisible.subscribe(updateHeight);
                    bankAccount.setClose = function (closeFunc) {
                        bankAccount.close = closeFunc;
                    };
                    return bankAccount;
                }.bind(this));
                return models;
            }.bind(this));

        this.init = function () {
            this.bankAccounts.load();
        }.bind(this);

        this.updateHeight = updateHeight;
        this.bankAccounts.state.subscribe(updateHeight);

        this.init();
    }

    ko.components.register('bank-accounts-list', {
        template: { element: 'template-bank-accounts-list' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new bankAccountsListModel(updateHeight);
            }
        }
    });
})(jQuery);