import { DataServiceBase } from "../DataServiceBase";

(function ($, ko) {
    class MerchantLocatorService extends DataServiceBase {
        constructor() {
            super('/MerchantLocator');
        }


        getStoreLocations(distance, zip) {
            let urlParams = {
                distance: distance,
                zip: zip,
            }
            return this.getAsync(urlParams, 'GetStoreLocations');
        }

    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                MerchantLocatorService: MerchantLocatorService
            }
        }
    });
})(jQuery, ko);