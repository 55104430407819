(function ($, ko) {
    var uploadDocumentQueuePopupModel = function (flexAcctId, updateHeight) {

        this.flexAcctId = ko.observable(flexAcctId);
        this.uploadMessages = ko.observableArray([]);
        this.files = ko.observableArray([]).extend({ required: true });
        this.uploadingInProgress = ko.observable(false);

        this.preRender = function() {
            this.files([]);
        }.bind(this);

        this.files.subscribe(function() {
            if (this.uploadingInProgress())
                return;

            this.uploadMessages([]);
        }, this);

        this.clickSubmit = function() {
            if (this.uploadingInProgress())
                return;

            this.uploadMessages([]);
            this.uploadingInProgress(true);

            var finished = $.Deferred();
            var uploadFile = function (fileIndex) {
                updateHeight();

                if (fileIndex < 0) {
                    finished.resolve();
                    return;
                }
                var file = this.files()[fileIndex];
                var splittedDataUrl = file.dataUrl.split(/[,:;]/);
                var data =
                {
                    flexAcctId: this.flexAcctId(),
                    file: {
                        FileName: file.fileName,
                        ContentType: splittedDataUrl[1],
                        Base64: splittedDataUrl[3]
                    }
                };

                this.request = new $.cdh.post.documentqueue.upload();

                this.request.load(data)
                    .done(function(upload) {
                        this.uploadMessages.push("File " + file.fileName + " uploaded successfully");
                        this.files.remove(file);
                    }.bind(this))
                    .always(uploadFile.bind(this, fileIndex - 1))
                    .fail(this.uploadMessages.push.bind(this.uploadMessages,
                        "Can't upload '" + file.fileName + "'. Please try again."));
            }.bind(this);

            this.errorMessage = function (data) {
                if (!data)
                    return "";

                if (typeof data === 'string')
                    return data;

                var json = JSON.parse(data.responseText);
                return json.message;
            }.bind(this);

            finished.done(function () {
                this.uploadingInProgress(false);
                if (this.files().length === 0) {
                    //Add success message
                }
            }.bind(this));

            uploadFile(this.files().length - 1);
        }.bind(this);

        this.clickRemoveImage = function (doc, e) {
            this.files.remove(doc);
        }.bind(this);

        this.clickCancel = function () {
            if ($.isFunction(this.cancel)) {
                this.cancel();
                return;
            }
            if ($.isFunction(this.close)) {
                this.close();
            }
        }.bind(this);

        this.setCancel = function (cancelFunc) {
            if ($.isFunction(cancelFunc)) {
                this.cancel = function () {
                    cancelFunc.call(this);
                };
            }
        };

        this.setClose = function (closeFunc) {
            this.close = function () {
                closeFunc.call(this);
            }.bind(this);
        }.bind(this);
    }

    $.extend(true, $, {
        cdh: {
            models: {
                UploadDocumentQueuePopupModel: uploadDocumentQueuePopupModel
            }
        }
    });
})(jQuery, ko);
