import templateDetailsCard from "./transactions-details-card.html"
import templateDetailsClaims from "./transactions-details-claim.html"
import templateDetailsDeposit from "./transactions-details-deposit.html"
import templateDetailsOther from "./transactions-details-other.html"

(function ($, ko) {
    ko.components.register('transactions-details-card', {
        template: templateDetailsCard,
    });

    ko.components.register('transactions-details-claim', {
        template: templateDetailsClaims,
    });

    ko.components.register('transactions-details-deposit', {
        template: templateDetailsDeposit,
    });

    ko.components.register('transactions-details-other', {
        template: templateDetailsOther,
    });

})(jQuery, ko);