(function ($, ko) {
    var faqMiniModel = function (updateHeight) {
        this.mini = ko.observable(true);
        this.faq = new $.cdh.get.resources.faq(function(json) {
           return json.slice(0,5);
        });
       
        this.faq.load();
        this.faq.state.subscribe(updateHeight);

        this.afterRender = function () {
            var item = $(".faq-item .title");
            item.prop('onclick', null).off('click');
            item.click(function () {
                $(this).parent('.faq-item').toggleClass('open');
                updateHeight();
                return false;
            });
        };
    };

    ko.components.register('faq-mini', {
        template: { element: 'template-resources-faq' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new faqMiniModel(updateHeight);
            }
        }
    });
})(jQuery, ko);
