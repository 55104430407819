import template from "./bill-pay-list-item.html";

ko.components.register('bill-pay-list-item', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new BillPayItemModel(params, widgetInfo.updateHeight);
        }
    }
});
    
function BillPayItemModel(params, updateHeight) {
    const self = this;
    
    let payment = params.payment;
    
    self.payment = payment;
    self.paymentRemoved = params.paymentRemoved;
    self.amount = payment.Amount;
    self.recurrenceFrequency = payment.RecurrenceFrequency;

    self.isFailed = payment.IsFailed;
    self.status = self.isFailed ? 'Failed' : 'Planned';
    self.nearestPaymentDate = payment.NearestPaymentDate;
    self.accountDescription = payment.AccountDescription;
    
    self.description = payment.Description;
    self.payee = payment.Payee;
    self.paymentDate = payment.PaymentDate;
    self.once = payment.Once;
    self.nextPaymentDate = payment.NextPaymentDate;
    self.nearestNextPaymentDate = payment.ExistFirstPayment ? payment.NextPaymentDate : payment.PaymentDate;
    self.errorCode = payment.ErrorCode;
    self.denialReason = payment.DenialReason;

    self.isOpened = ko.observable(false);
    self.onToggle = function () {
        self.isOpened(!self.isOpened());
    };

    self.itemHeaderKeyDown = function (data, e) {
        if (e.keyCode === 32 || e.keyCode === 13) {
            self.onToggle();
        } else {
            return true;
        }
    };
    
    self.isOpened.subscribe(updateHeight);

    self.close = params.closeFunc;
}