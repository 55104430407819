import template from "./urgent-care-detail.html"

(function ($, ko) {
    function UrgentCareDetail(params) {
        const self = this;
        self.disabled = false;

        this.urgentCareDetails = JSON.parse(localStorage.getItem('urgent-care-detail'));
        this.locationInfo = JSON.parse(localStorage.getItem('locationInfo'));


        this.pageNo = $.cdh.helper.urlParam('pageNo');
        this.filteredRating = $.cdh.helper.urlParam('filteredRating');

        this.filteredDistance = $.cdh.helper.urlParam('filteredDistance');
        this.isFilterAvailable = $.cdh.helper.urlParam('filterAvailable');
        this.backToSearchUrl = $.cdh.routes.predefined.findcare.urgentcareList + '?zipcode=' + this.locationInfo.zipcode + '&distance=' + this.locationInfo.distance + '&pageNo=' + this.pageNo;

        if (this.filteredRating) {
            this.backToSearchUrl += '&filteredRating=' + this.filteredRating;
        }
        if (this.filteredDistance) {
            this.backToSearchUrl += '&filteredDistance=' + this.filteredDistance;
        }
        if (this.isFilterAvailable) {
            this.backToSearchUrl += '&filterAvailable=' + this.isFilterAvailable;
        }

        self.participantService = new $.cdh.http.smartAccount.ParticipantService();
        function load() {

            self.participantService.getDetailsAsync().then(function (result) {
                let data = result.data();
                self.disabled = data.FindCareDisabled;

            });

        }
        load();


    }

    ko.components.register('urgent-care-detail', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new UrgentCareDetail(updateHeight, params.template);
            }
        }
    })

})(jQuery, ko);