import template from "./account-activity-payment.html"

(function ($, ko) {
    ko.components.register('account-activity-payment', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityPayment(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityPayment(params, updateHeight) {
        const self = this;
        let model = params.model;
        
        self.isTcClaim = model.IsTcClaim;
        self.isFxClaim = model.IsFxClaim;

        self.paymentDetails = model.ReimbursementDetails.map(function(item) {
            return {
                line: item.ClaimLineNumber > 0 ? item.ClaimLineNumber : "",
                amount: item.Amount,
                prevPaidAmount: item.PrevPaidAmount,
                account: item.CustomDescription,
                paidTo: item.PaidTo,
                method: item.Method,
                checkNumber: $.cdh.helper.isValueSet(item.CheckNumber) ? item.CheckNumber : "-",
                date: item.Date,
                paymentId: item.PaymentId,
                payoutMethod: item.PayoutMethod
            };
        });
    }

})(jQuery, ko);