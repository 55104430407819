import { DataServiceBase } from "../DataServiceBase";

(function ($, ko) {
    class AdministratorFeaturesService extends DataServiceBase {
        constructor() {
            super('/Administrator');
        }

        GetAdministratorFeature() {  
            return this.getAsync(null,'GetAdministratorFeature');
        }

    }

    $.extend(true, $.cdh, {
        http: {
            administrator: {
                AdministratorFeaturesService: AdministratorFeaturesService
            }
        }
    });
})(jQuery, ko);