import template from "./authentication-profile-warning.html"

(function ($, ko) {
    ko.components.register('authentication-profile-warning', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AuthenticationProfileWarning(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AuthenticationProfileWarning(params, updateHeight) {

        this.profile = ko.unwrap(params.profile);
        let profileViewModel = this.profile?.viewModel();
        this.IsSmsOnly = params.IsSmsOnly;

        this.onContinue = params.onContinue;
        this.onCancel = params.onCancel;

        this.isWarningView = true;
        this.warningMessage = ko.observable(null);
        this.allowContinue = ko.observable(false);

        this.clickContinue = function () {
            this.onContinue();
        }.bind(this);

        this.clickClose = function () {
            this.onCancel();
        }.bind(this);

        const emailAvailable = !!profileViewModel?.Email;
        const phoneAvailable = !!profileViewModel?.MobileNumber;

        var continueFlag = this.IsSmsOnly() ? phoneAvailable : (phoneAvailable || emailAvailable);

        if (!continueFlag) {
            var contactPhone = $.cdh.brandData.ContactPhone;
            var contactEmail = $.cdh.brandData.ContactEmail;
            var contactNum = `<p><a href="tel:${contactPhone} �class="h - contacts_phone">${contactPhone}</a></p>`;
            var contactmail = `<p><a href="mailto:${contactEmail} �class="h - contacts_email">${contactEmail}</a></p>`;
            var message = "<p> </p>";
            if (contactEmail == "" || contactPhone == "") {
                message = "<p> Can't Perform this operation. <br/>For security purposes, a valid contact method is needed in order to proceed. Please contact us for further assistance " + contactNum + contactmail + "</p>";
            }
            else {
                message = "<p> Can't Perform this operation. <br/>For security purposes, a valid contact method is needed in order to proceed. Please contact us for further assistance<br/>" + contactNum + " or " + contactmail + "</p>";

            }
            this.warningMessage(message);
        }
        this.allowContinue(continueFlag);
    };
})(jQuery, ko);