import template from "./documents-grid.html"

(function ($, ko) {
    ko.components.register('documents-grid', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new DocumentsGrid(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function DocumentsGrid(params, updateHeight) {
	    this.updateHeight = updateHeight;
        this.items = params.items;
        this.pageSize = params.pageSize || 10;
        this.displayedDataSubscription = updateHeight();
        this.isDataLoading = params.isDataLoading;
        this.messageIfEmpty = params.messageIfEmpty;
        this.keyGetter = params.keyGetter; // should be like function(item) {return item.Key};
        this.nameGetter = params.nameGetter; // should be like function(item) {return item.Name};
        this.urlBuilder = params.urlBuilder; //should be like function(key, name) { return builder url};

        this.gridItems = ko.pureComputed(function() {
	        return adaptItems(this.items);
        }, this);

        var GridItem = function (title, downloadUrl) {
	        this.title = title;
	        this.downloadUrl = downloadUrl;
        }

        var addItemToGrid = function (gridItems, pageIndex, item, toLeft) {

            var key = this.keyGetter(item);
            var name = this.nameGetter(item);

            var gridItem = new GridItem(name, this.urlBuilder(key, name));

	        var items = gridItems[pageIndex];
	        if (!items)
		        items = { left: [], right: [] };
	        if (toLeft) {
		        items.left.push(gridItem);
	        } else {
		        items.right.push(gridItem);
	        }

	        gridItems[pageIndex] = items;
        }.bind(this);

        var adaptItems = function (data) {
	        var gridItems = [];

	        for (var i = 0; i < data.length; i++) {
		        var item = data[i];
		        var pageIndex = Math.floor(i / 2);

		        addItemToGrid(gridItems, pageIndex, item, i % 2 === 0);
	        }

	        return gridItems;
        }.bind(this);
    }


})(jQuery, ko);