import template from './merchant-locator-item.html';

(function ($, ko) {

    function MerchantLocatorItem(params, updateHeight) {

        this.storeResult = params.model.model;
        this.distanceInMiles = this.storeResult.Distance + 'm';

    }
    ko.components.register('merchant-locator-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new MerchantLocatorItem(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
})(jQuery, ko);