import template from "./claim-submit-summary.html"

(function ($, ko) {
    ko.components.register('claim-submit-summary', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ClaimSubmitSummary(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ClaimSubmitSummary(params, updateHeight){
        this.widget = params.widget;
        this.entry = params.entry;

        this.ClaimSubmitSummaryLabel = {
            lblAmount: $.cdh.languageConstant.getConstant('claim.amount.value'),
            lblCopay: $.cdh.languageConstant.getConstant('claim.amount.copay'),
            lblCoinsurance: $.cdh.languageConstant.getConstant('claim.amount.coinsurance'),
            lblDeductible: $.cdh.languageConstant.getConstant('claim.amount.deductible'),
            lblType: $.cdh.languageConstant.getConstant('claim.type.name'),
            lblClaimAnt: $.cdh.languageConstant.getConstant('field.claimant'),
            lblTrackingNumber: $.cdh.languageConstant.getConstant('field.trackingnumber'),
            lblAccountType: $.cdh.languageConstant.getConstant('claim.type.account'),
            lblServiceType: $.cdh.languageConstant.getConstant('claim.type.service'),
            lblServiceStartDate: $.cdh.languageConstant.getConstant('field.service-startdate'),
            lblServiceEndDate: $.cdh.languageConstant.getConstant('field.service-enddate'),
            lblRecurrence: $.cdh.languageConstant.getConstant('claim.recurrence'),
            lblComment: $.cdh.languageConstant.getConstant('field.comment'),
            lblProvider: $.cdh.languageConstant.getConstant('field.providers')
        };

        this.isCopayVisible = ko.pureComputed(function(){
            return this.widget.data().IsCopayVisible && this.entry.scc();
        }, this);

        this.isCoinsuranceVisible = ko.pureComputed(function(){
            return this.widget.data().IsCoinsuranceVisible && this.entry.scc();
        }, this);

        this.isDeductibleVisible = ko.pureComputed(function(){
            return this.widget.data().IsDeductibleVisible && this.entry.scc();
        }, this);

        this.isRecurringVisible = ko.pureComputed(() => this.entry.useRecuring() && (this.entry.scc() && this.entry.scc().IsRecurringVisible !== null ? this.entry.scc().IsRecurringVisible : this.widget.data().IsRecuringVisible));
        
        this.providerFullName = ko.pureComputed(function(){
            return this.entry.useProvider()
                ? this.entry.providerChoice().PayeeName
                : this.entry.providerName();
        }, this);
    }
})(jQuery, ko);