(function ($) {
    $.extend(true, $, {
        cdh: {
            models: {
                changeLanguage: function () {

                    this.steps = {
                        languageNotSelected: 1,
                        languageSelected: 2
                    }
                    this.changeLanguageApi = new $.cdh.post.authentication.changeLanguage();
                    this.updateRequest = new $.cdh.post.profile.updateLanguage();
                    this.currentStep = ko.observable(this.steps.languageNotSelected);
                    this.selectedLanguage = ko.observable($.cdh?.languageConstant?.getLanguage().id);
                    this.supportedLanguage = $.cdh.user.supportedLanguage;
                    this.allowLanguageDefault = $.cdh.user.allowDefaultLanguage;

                    this.availableLanguages = ko.observableArray([]);
                    this.closeText = ko.pureComputed(()=>$.cdh.languageConstant.getResource('button.close-esc', this.selectedLanguage()));
                    this.saveText = ko.pureComputed(() => $.cdh.languageConstant.getResource('button.save', this.selectedLanguage()));

                    var englishOption = { name: 'English', id: 'en', displayText: 'English is my preferred language.' };
                    var frenchOption = { name: 'Français', id: 'fr', displayText: 'Le français est ma langue préférée.' };
                    var spanishOption = { name: 'Español', id: 'es', displayText: 'El español es mi idioma preferido.' };

                    if (this.supportedLanguage().includes('en')) {
                        this.availableLanguages.push(englishOption);
                    }

                    if (this.supportedLanguage().includes('fr')) {
                        this.availableLanguages.push(frenchOption);
                    }

                    if (this.supportedLanguage().includes('es')) {
                        this.availableLanguages.push(spanishOption);
                    }
                    this.clickCancel = function () {
                        this.currentStep(this.steps.languageNotSelected)
                        this.selectedLanguage($.cdh?.languageConstant?.getLanguage().id)
                        this.close();
                    }
                    this.isMobile = $.cdh.isMobile;

                    this.clickNext = function () {
                         
                        this.currentStep(this.steps.languageSelected);

                    }.bind(this);
                    this.clickSave = function () {
                        this.changeLanguageApi.load({ language: this.selectedLanguage() }).done((data) => {
                            this.close();
                            $.cdh.languageConstant.changeLanguage(this.selectedLanguage());
                            window.location.reload();
                        })

                    }.bind(this);

                    this.setClose = function (closeFunc) {
                        this.currentStep(this.steps.languageNotSelected)
                        this.selectedLanguage($.cdh?.languageConstant?.getLanguage().id)
                        this.close = closeFunc;
                    }.bind(this);
                    this.clickSubmit = function () {
                        $.cdh.languageConstant.changeLanguage(this.selectedLanguage());
                        this.changeLanguageApi.load({ language: this.selectedLanguage() })
                            .done((data) => {
                                this.updateProfile();
                            })                                               
                    };
                    this.updateProfile = function () {
                        this.updateRequest.load({ language: this.selectedLanguage() })
                            .done(() => {
                                this.close();
                                //$.cdh.helper.setCookie("LanguagePreference", this.selectedLanguage(), 0, 24, 0, true);
                                $.cdh.languageConstant.changeLanguage(this.selectedLanguage());
                                window.location.reload();
                            })                         
                    };

                    this.clickNo = function () {
                        //Language Preference is changed, but not set to default
                        this.changeLanguageApi.load({ language: this.selectedLanguage() }).done((data) => {
                            this.close();
                            window.location.reload();
                        })
                    }

                }
            }
        }
    });
}(jQuery))