import template from "./account-activity-hsa-deposit-details.html"
ko.components.register('account-activity-hsa-deposit-details', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new PaymentDetails(params, widgetInfo);
        }
    }
});

export class PaymentDetails {
    constructor(params, widgetInfo) {
        let model = params.model;

        this.total = model.ResponsibilityAmount;
        this.appliedAmount = model.DepositDetails.AppliedAmount;
        this.forfeitedAmount = model.DepositDetails.ForfeitedAmount;
    }
}