import template from "./smart-registration-create-user.html"

(function ($, ko) {
    ko.components.register('smart-registration-create-user', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new CreateUserModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function CreateUserModel(params, updateHeight) {
        const self = this;
        self.prevMobileSelectionIndex = 0;
        self.isMobileOrPasswordFocused = ko.observable(false);

        self.createUserRequest = new $.cdh.post.smartRegistration.createUser();

        const { MinUserIdLength, MaxUserIdLength } = params.validationArguments();
        const userNameValidationMessage = `Should be between ${MinUserIdLength} and ${MaxUserIdLength} chars`

        self.MinUserIdLength = ko.observable(MinUserIdLength);
        self.MaxUserIdLength = ko.observable(MaxUserIdLength);

        self.userMessageContent = `A valid userId must be between ${MinUserIdLength} and ${MaxUserIdLength} characters.<br/>Can contain Alphanumeric character.<br/>Can contain following special characters, @ (at sign) . (period) - (hyphen or dash)_ (underscore).`

        self.model = {
            userName: ko.observable().extend({
                required: true,
                betweenLengthString: { params: [MinUserIdLength, MaxUserIdLength], message: userNameValidationMessage },
                usernamevalidation: { params: true, message: 'Username contains invalid characters.' }
            }),
            email: ko.observable(params.model.maskedData.email()),
            mobileNumber: ko.observable(params.model.maskedData.mobileNumber()),
            password: ko.observable(''),
            confirmPassword: ko.observable('').extend({required: true})
        };

        self.isEmailDirty = function () {
            var initialEmailId = params.model.maskedData.email();
            var userInputEmailId = self.model.email();

            if (initialEmailId.length > 0 && initialEmailId === userInputEmailId) {
                return false;
            }

            return true;
        };

        ko.extenders.clearOnEdit = function (target, maskValue) {
            let cleared = false;
            target.subscribe(function (newValue) {
                // If the user modifies the masked email (e.g., deletes/add a character), clear the entire field
                if (!cleared && maskValue.length > 0 && newValue !== maskValue) {
                    cleared = true;
                    target('');
                }
            });
            return target;
        };

        // Dynamically apply email validation only if isEmailDirty is true
        self.model.email.extend({
            required: { onlyIf: function () { return self.isEmailDirty(); } },
            email: { onlyIf: function () { return self.isEmailDirty(); } },
            clearOnEdit: params.model.maskedData.email()
        });

        // Return false if the initial number exists and matches the user input else return true
        self.isMobileNumberDirty = function () {
            var initialMobileNumber = params.model.maskedData.mobileNumber();
            var userInputMobileNumber = self.model.mobileNumber();

            if (initialMobileNumber === userInputMobileNumber) {
                return false;
            }

            return true;
        };

        self.model.mobileNumber.extend({ phoneUS: { message: "Please specify a valid mobile number. Phone numbers must be 10 digits.", params: true, onlyIf: self.isMobileNumberDirty } });

        ko.bindingHandlers.mobileNumberInputWithValueProcessing = {
            update: function (element, valueAccessor) {
                var isPasteEvent = false; // Flag to indicate if a paste event has occurred
                function formatPhoneNumber(value) {
                    //when the mobile number is auto-populated with countrycode, the country code will be neglected and then the mobile number is formatted.
                    if (value[0] == '1') {
                        return "(" + value.substring(1, 4) + ") " + value.substring(4, 7) + "-" + value.substring(7, 11);
                    }
                    return "(" + value.substring(0, 3) + ") " + value.substring(3, 6) + "-" + value.substring(6, 10);
                }
                function applyFormatting(newValue) {
                    var formattedNumber = formatPhoneNumber(newValue);
                    $(element).val(formattedNumber);
                }

                // Handle paste event
                $(element).on('paste', function (event) {
                    isPasteEvent = true;
                });

                $(element).on('input', function () {
                    var newValue = $(element).val();

                    // Is user made any changes in pre-populated masked number, clear the MobileNumber text field
                    if (newValue.includes("**") || (newValue[0] == '1' && newValue.length == 1)) {
                        $(element).val('');
                        self.prevMobileSelectionIndex = 0;
                        return;
                    }
                    
                    var digits = newValue.replace(/\D/g, '');

                    if (digits.length > 0) {
                        var placeHolderDigitsLength = 10 - digits.length;
                        var currentSelectionIndex = ($(element).get(0)).selectionStart;

                        if (placeHolderDigitsLength > 0) {
                            digits = digits + ("_").repeat(placeHolderDigitsLength)
                        }
                        applyFormatting(digits);
                       
                        // If user paste any MobileNumber, ensure cursor points to the end
                        var correctPosition = isPasteEvent ? $(element).val().length : currentSelectionIndex;

                        if (!isPasteEvent) {
                            if (currentSelectionIndex <= 4) {
                                // Only change correctPosition if at the very start (index 1)
                                if (currentSelectionIndex == 1) {
                                    correctPosition = self.prevMobileSelectionIndex < 2 ? 2 : 1;
                                }
                            } else if (currentSelectionIndex <= 9) {
                                // Adjust correctPosition around the ')' & 'space' position
                                if (currentSelectionIndex == 5 || currentSelectionIndex == 6) {
                                    correctPosition = self.prevMobileSelectionIndex < 6 ? 7 : 4;
                                }
                            } else if (currentSelectionIndex <= 14) {
                                // Adjust correctPosition around the hyphen position
                                if (currentSelectionIndex == 10) {
                                    correctPosition = self.prevMobileSelectionIndex < 10 ? 11 : 9;
                                }
                            }
                        } else {
                            isPasteEvent = false;
                        }

                        self.prevMobileSelectionIndex = correctPosition;
                        ($(element).get(0)).setSelectionRange(correctPosition, correctPosition);

                    } else {
                        // If there are no digits, clear the MobileNumber text field
                        $(element).val('');
                        self.prevMobileSelectionIndex = 0;
                    }
                });
                ko.bindingHandlers.textInput.init.apply(null, arguments);
                // Initial setup
                $(element).val();
            }
        };

        self.shouldShowMobileNumberWarning = ko.pureComputed(function () {
            var initialMobileNumber = params.model.maskedData.mobileNumber();
            var userInputMobileNumber = self.model.mobileNumber();

            var isInitialNumberExist = !!initialMobileNumber && initialMobileNumber.length > 0;
            var isSameAsInitialNumber = isInitialNumberExist && userInputMobileNumber === initialMobileNumber;

            if (isSameAsInitialNumber) {
                return false;
            }

            var shouldShowWarning = (self.isMobileOrPasswordFocused() || isInitialNumberExist) &&
                (!userInputMobileNumber || !self.model.mobileNumber.isValid());

            return shouldShowWarning;
        });

        self.isMobileErrorMessageDisplayed = ko.pureComputed(function () {
            return !self.model.mobileNumber.isValid() && !!self.model.mobileNumber.error();
        });

        self.model.password.extend({
            passwordComplexity: { 
                userName: self.model.userName
            },
            notify: 'always'
        });

        self.model.confirmPassword.extend({
            equal: { params: self.model.password, message: 'Passwords do not match.' }
        });

        self.model.passwordStrength = ko.pureComputed(function () {
            // var password = self.model.password();
            // var result = 0;
            // if (/[a-z]/.test(password)) { result += 25; }
            // if (/[A-Z]/.test(password)) { result += 25; }
            // if (/\d/.test(password)) { result += 25; }
            // if (/[^a-zA-Z0-9 ]+/.test(password)) { result += 25; }
            // return result;

            function checkPassStrength(password){
                if (!password) {
                    return 0;
                }
                var strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
                var mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

                if(strongPassword.test(password)) {
                    return 100;
                } else if(mediumPassword.test(password)){
                    return 66.66;
                } else{
                    return 33.33;
                }
            }
            var password = self.model.password();
            return checkPassStrength(password);
        });

        self.validation = new $.cdh.validation(self.model);

        self.isDisabled = ko.observable(false);
        self.isLoading = ko.pureComputed(function() {
            return self.createUserRequest.isLoading();
        });
        
        self.isErrorShowed = ko.observable(false);

        self.back = params.model.onBack;
        self.cancel = params.model.onCancel;
        self.submit = function() {
            if (self.isLoading())
                return;

            self.validation.activate();

            if(!self.validation.hasErrors()) {
                self.isErrorShowed(false);

                var finalModel = {
                    userName: self.model.userName(),
                    email: self.model.email(),
                    mobileNumber: self.model.mobileNumber(),
                    password: self.model.password(),
                    confirmPassword: self.model.confirmPassword()
                };
                // If the user updated the mobileNumber, then send only the digits to WCA. If it's null/undefined, send an empty string.
                var mobileNumberValue = finalModel.mobileNumber;
                if (mobileNumberValue) {
                    if (self.isMobileNumberDirty()) {
                        finalModel.mobileNumber = mobileNumberValue.replace(/\D/g, '') ?? "";
                    }
                } else {
                    finalModel.mobileNumber = "";
                }
                self.createUserRequest.load(finalModel).done(function (data) {
                    var userData = {};
                    userData.username = self.model.userName;
                    userData.password = self.model.password;
                    params.model.onNext(userData);
                }).fail(function (xhr) {
                    if(xhr.responseJSON && xhr.responseJSON.data && xhr.responseJSON.data.AlreadyCreated) {
                        showAlertForExistAccount(xhr.responseJSON.message);
                    } else {
                        self.isErrorShowed(true);
                        updateHeight();
                    }
                })
            }
        };
        
        function showAlertForExistAccount(message) {
            function closeCallback() {
                document.getElementById('reg__step-3-next-btn').focus();
            }

            $.cdh.helper.alert2({
                message: message,
                okCallback: function () {
                    self.cancel();
                    closeCallback();
                }.bind(this),
                cancelCallback: function () {
                    closeCallback();
                }.bind(this),
                okText: "Sign in",
                okAttrs: {
                    role: 'link'
                },
                cancelText: "Close"
            });
        }
        
        params.model.disable = function() {
           self.isDisabled(true); 
        };

        params.model.clear = function() {
            self.isDisabled(false);
            self.isErrorShowed(false);
            self.model.password('');
            self.model.confirmPassword('');
            
            self.model.password.isModified(false);
            self.model.confirmPassword.isModified(false);

            self.createUserRequest.setData(null);
        };

        const mobileInputField = document.getElementById('mobileNumber');
        const passwordField = document.getElementById('password');
        const confirmPasswordField = document.getElementById('password_confirm');

        if (mobileInputField && passwordField && confirmPasswordField) {
            function updatePrevMobileSelectionIndex() {
                self.prevMobileSelectionIndex = mobileInputField ? mobileInputField.selectionStart : 0;
            }

            self.setMobileOrPasswordFocused = function () {
                self.isMobileOrPasswordFocused(true);
            };

            mobileInputField.addEventListener('keydown', updatePrevMobileSelectionIndex);
            mobileInputField.addEventListener('click', updatePrevMobileSelectionIndex);

            mobileInputField.addEventListener('focus', self.setMobileOrPasswordFocused);
            passwordField.addEventListener('focus', self.setMobileOrPasswordFocused);
            confirmPasswordField.addEventListener('focus', self.setMobileOrPasswordFocused);
        }
    }

})(jQuery, ko);