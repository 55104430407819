import template from "./reimbursement-list-mini.html";

ko.components.register('reimbursement-list-mini', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new ReimbursementsModel(params, widgetInfo);
        }
    }
});

class ReimbursementsModel {
    constructor(params, widgetInfo) {
        this.templateExtractor = new $.cdh.services.templateExtractor(params.template);
        this.rowsNumber = ko.observable(5);
        this.reimbursements = new $.cdh.get.reimbursements.list();
        this.reimbursements.load();

        this.pendingList = ko.pureComputed(() => {
            if (!this.reimbursements.isLoading() && this.reimbursements.hasData()) {
                return this.reimbursements.data().Pending.slice(0, this.rowsNumber());
            }
        });

        this.currentList = ko.pureComputed(() => {
            if (!this.reimbursements.isLoading() && this.reimbursements.hasData()) {
                return this.reimbursements.data().Current.slice(0, this.rowsNumber());
            }
        });

        this.pendingList.subscribe(widgetInfo.updateHeight);
        this.currentList.subscribe(widgetInfo.updateHeight);
    }
}