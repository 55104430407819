export class SmartAccountBaseViewModel {
    constructor(params, componentInfo, widgetInfo) {

        this.componentInfo = componentInfo;
        this.widgetInfo = widgetInfo;
        this.params = params;
        
        this.error = params.error || ko.observable(null);
        this.loading = params.loading || ko.observable(false);
        this.canSubmit = params.canSubmit || ko.observable(true);
        this.canDisplay = ko.pureComputed(() => !this.error() && !this.loading())
    }

    async apiAsyncCall(act: function) {
        try {
            this.canSubmit(false);
            return await act();
        } catch (e) {
            this.error(e.error())
        } finally {
            this.canSubmit(true);
        }
    }
}