import template from "./scheduled-payments-item.html";

ko.components.register('scheduled-payments-item', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new ScheduledPaymentsItem(params, widgetInfo);
        }
    }
});

class ScheduledPaymentsItem {
    constructor(params, widgetInfo) {
        let payment = params.payment;

        this.payment = payment;
        this.refreshCallback = params.refreshCallback;
        this.amount = payment.Amount;
        this.date = payment.NearestPaymentDate;
        this.description = payment.AccountDescription;
        this.recurrenceFrequency = payment.RecurrenceFrequency;

        widgetInfo.updateHeight();
    }
}