(function ($, ko) {
    var contributionsAddEditPopup = function (contribution, dataPartnerId, onSuccess) {
        this.validDates = new $.cdh.get.contribution.getValidDates(dataPartnerId);
        this.bankAccounts = new $.cdh.get.bankAcount.getConfirmed(dataPartnerId);
        this.preview = ko.observable(false);
        this.success = ko.observable(false);

        this.AddEditBankAccountRequiredLabels = {
            lblVerifyAccountRequired: $.cdh.languageConstant.getResource('contributions.verified-account-required', 'en'),
            lblCloseThisWindow: $.cdh.languageConstant.getResource('contributions.close-this-window', 'en'),
            lblAddBankAccount: $.cdh.languageConstant.getResource('contributions.add-bank-acccount', 'en'),
            lblAccountVerifiedReturnHere: $.cdh.languageConstant.getResource('contributions.account-verified-return-here', 'en'),
            lblButtonClose: $.cdh.languageConstant.getResource('button.close', 'en')
        };

        this.AddEditPopupLabels = {
            lblFundHsa: $.cdh.languageConstant.getResource('title.fund-my-hsa','en'),
            lblContributionYear: $.cdh.languageConstant.getResource('field.contribution-year','en'),
            lblContributionCountTaxYear: $.cdh.languageConstant.getResource('contributions.contribution-count-tax-year','en'),
            lblContributionsMadeAfter: $.cdh.languageConstant.getResource('contributions.contributions-made-after','en'),
            lblAppliedToCurrentYear: $.cdh.languageConstant.getResource('contributions.applied-current-year','en'),
            lblContributionDate: $.cdh.languageConstant.getResource('field.contribution-date','en'),
            lblAmount: $.cdh.languageConstant.getResource('field.amount','en'),
            lblContributionsMayTake: $.cdh.languageConstant.getResource('contributions.contributions-may-take','en'),
            lblBusinessDaysAfterContribution: $.cdh.languageConstant.getResource('contributions.business-days-after-contribution','en'),
            lblBankAccount: $.cdh.languageConstant.getResource('field.bank-account','en'),
            lblButtonCancel: $.cdh.languageConstant.getResource('button.cancel','en'),
            lblButtonSubmit: $.cdh.languageConstant.getResource('button.submit','en')
        };

        this.AddEditPreviewLabels = {
            lblThankyou: $.cdh.languageConstant.getConstant('communication.thank-you'),
            lblContributionSubmitted: $.cdh.languageConstant.getConstant('messages.contribution-submitted'),
            lblContributionsMayTake: $.cdh.languageConstant.getConstant('contributions.contributions-may-take'),
            lblBusinessDaysAfterContribution: $.cdh.languageConstant.getConstant('contributions.business-days-after-contribution'),
            lblCurrentYear: $.cdh.languageConstant.getConstant('field.current-year'),
            lblContributionDetails: $.cdh.languageConstant.getConstant('communication.contribution-details'),
            lblAmount: $.cdh.languageConstant.getConstant('field.amount'),
            lblContributionDate: $.cdh.languageConstant.getConstant('field.contribution-date'),
            lblBankAccount: $.cdh.languageConstant.getConstant('field.bank-account'),
            lblButtonLoading: $.cdh.languageConstant.getConstant('button.loading'),
            lblButtonSubmit: $.cdh.languageConstant.getConstant('button.submit'),
            lblWhatNext: $.cdh.languageConstant.getConstant('payment.what-next'),
            lblSubmitAnother: $.cdh.languageConstant.getConstant('link.submit-another'),
            lblButtonClose: $.cdh.languageConstant.getConstant('button.close')
        };

        this.preRender = function () {
            this.validDates.load();
            this.bankAccounts.load();
        }.bind(this);

        var c = ko.toJS(contribution);

        // CDH.ApiServices.Models.Contribution.EditPendingContribution
        this.contribution = {
            ContributionId: c.ContributionId,
            AccountId: c.AccountId,
            BankAccountId: ko.observable(c.BankAccountId).extend({ required: true }),
            ContributionYear: ko.observable(c.ContributionYear || $.cdh.enums.contributionYear.current),
            ContributionDate: ko.observable(c.ContributionDate).extend({ required: true }),
            ContributionAmount: ko.observable(c.ContributionAmount).extend({ min: 1.01, required: true })
        };

        this.validation = new $.cdh.validation(this.contribution);

        this.canPickPrior = ko.pureComputed(function () {            
            if (this.validDates.hasData()) {
                return moment() >= moment().startOf('year') &&
                    moment() <= moment(this.validDates.data().LatestPriorContributionDate);
            }
            return false;
        }, this);

        this.LatestPriorContributionDate = ko.pureComputed(function () {
            if (this.validDates.hasData()) {
                return this.validDates.data().LatestPriorContributionDate;
            }
            return null;
        }, this);

        this.minDate = ko.pureComputed(function () {
            if (this.validDates.hasData()) {
                return  this.validDates.data().EarliestContributionDate;
             }  
            return null;
        }, this);

        this.maxDate = ko.pureComputed(function () {
            return this.validDates.hasData() && this.contribution.ContributionYear() == $.cdh.enums.contributionYear.prior
                ? this.validDates.data().LatestPriorContributionDate
                : this.validDates.data().LatestCurrentContributionDate;
        }, this);

        this.ExternalAccountTransferDaysToDelay = ko.pureComputed(function () {
            return this.validDates.hasData()
                ? this.validDates.data().ExternalAccountTransferDaysToDelay
                : null;
        }, this);

        var model = this;
        this.currentYearSelected = ko.observable(this.contribution.ContributionYear || $.cdh.enums.contributionYear.current);
        this.priorYearSelected = ko.observable(!this.currentYearSelected());

        this.contribution.ContributionYear.subscribe(function (newValue) {
            model.currentYearSelected(newValue === $.cdh.enums.contributionYear.current);
            model.priorYearSelected(!model.currentYearSelected());
        });

        this.selectedBankAccountName = ko.pureComputed(function () {
            var bankAccountId = this.contribution.BankAccountId();
            if (!bankAccountId || !this.bankAccounts.data) {
                return "";
            }

            var bankAccount = $.grep(this.bankAccounts.data(),
                function(ba) {
                    return ba.BankAccountId == bankAccountId;
                })[0];
            if (bankAccount) {
                return bankAccount.BankAccountName;
            } else {
                return "";
            }
            
        }.bind(this));

        this.addOrEditRequest = c.ContributionId
            ? new $.cdh.post.contributions.edit(c.ContributionId)
            : new $.cdh.post.contributions.add();

        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.clickReset = function () {
            this.validation.activated(false);

            this.contribution.ContributionId = 0;
            this.contribution.ContributionAmount('');

            this.preview(false);
            this.success(false);
            return false;
        }.bind(this);

        this.isBankAccountAvailable = ko.pureComputed(function () {
            return this.bankAccounts.hasData() && this.bankAccounts.data().length > 0;
        }, this);

        this.canSubmit = ko.pureComputed(function () {
            return !this.addOrEditRequest.isLoading() && !this.validation.hasErrors();
        }, this);

        this.clickSubmit = function () {
            this.validation.activate();

            if (!this.canSubmit())
                return;

            this.addOrEditRequest
                  .load(ko.toJS(this.contribution))
                  .done(function () {
                      this.success(true);
                  }.bind(this));
        }

        this.clickPreview = function () {
            this.validation.activate();

            if (!this.canSubmit())
                return;

            this.preview(true);
        }.bind(this);

        this.clickEdit = function () {
            this.preview(false);
        }.bind(this);

        this.clickOk = function () {
            if ($.isFunction(this.close))
                this.close();
            if ($.isFunction(onSuccess))
                onSuccess();
            return false;
        }.bind(this);
    }


    $.extend(true, $, {
        cdh: {
            models: {
                ContributionsAddEditPopup: contributionsAddEditPopup
            }
        }
    });
})(jQuery, ko);