import template from './site-map.html';
import {ComponentBase} from "../Common/componentBase";
import {RegisterDefaultComponent} from "../Common/componentsHelpers";
import {SiteMapService} from "../Http/SiteMap/SiteMapService";

export class SiteMapViewModel extends ComponentBase {
    constructor(params) {
        super(params);

        this.urls = ko.observableArray();
        this.siteMapService = new SiteMapService();

        this.init();
    }

    async init() {
        let result = await this.siteMapService.getUrlsAsync();
        this.urls(result.data());
        console.log(this.urls());
    }
}

RegisterDefaultComponent('site-map', template, SiteMapViewModel);