(function ($, ko) {
    
    function beneficiaryListMini(updateHeight) {
        var demographics = ko.observable();
        var accountId = $.cdh.helper.urlParam('accountId');

        var staticModels = {
            states: new $.cdh.get.scontent.states(function(result) {
                return $.map(result,
                    function(value, key) {
                        return { key: key, value: value };
                    });
            }),
            countries: new $.cdh.get.scontent.countries(function(result) {
                return $.map(result,
                    function (value, key) {
                        return {key: key, value: value};
                    });
            })
        };
        var profile = new $.cdh.get.profile.get();
        
        staticModels.states.load();
        staticModels.countries.load();

        var maxPrimary = 3;
        var maxContingent = 3;

        profile.load().done(function(data) {
            demographics({
                AddressLine1: data.Address1,
                AddressLine2: data.Address2,
                City: data.City,
                State: data.State,
                Zip: data.Zip
            });
        });
        
        $.cdh.models.BeneficiaryGroupListMini.call(this,
            accountId, demographics, staticModels, updateHeight
        );
        
        this.account = ko.observable();
        this.extendedDetails = new $.cdh.get.accounts.detailsHsa(accountId);
        
        this.accountsRequest = $.cdh.accounts.initAccounts(function () {
            this.account($.cdh.accounts.byAccountId(accountId));
        }.bind(this));
        
        this.aggregatedRequestStatus = new $.cdh.models.aggregatedLoadStatus([
            this.primaryBeneficiaryList.beneficiaries,
            this.contingentBeneficiaryList.beneficiaries,
            this.accountsRequest,
            this.extendedDetails
        ]);

        this.allowToAddPrimary = ko.pureComputed(function() {
            return this.primaryBeneficiaryList.count() < maxPrimary;
        }, this);

        this.allowToAddContingent = ko.pureComputed(function() {
            return this.contingentBeneficiaryList.count() < maxContingent;
        }, this);
        
        this.anyBenenificaries = ko.pureComputed(function () {
            var anyPrimary = !!this.primaryBeneficiaryList.count(),
                anyContingent = !!this.contingentBeneficiaryList.count();
            return anyPrimary || anyContingent;
        }, this);

        this.allocationState = ko.pureComputed(function () {
            return this.primaryBeneficiaryList.allocationState() ||
                this.contingentBeneficiaryList.allocationState();
        }, this);

        this.allocate = function () {
            this.primaryBeneficiaryList.allocationState(true);
            this.contingentBeneficiaryList.allocationState(true);
        }.bind(this);
        
        this.isPrimaryPercentageValid = ko.pureComputed(function() {
            if(this.primaryBeneficiaryList.count() === 0)
                return true;
            return this.total.primarySum() === 100;
        }, this);

        this.isContingentPercentageValid = ko.pureComputed(function () {
            if(this.contingentBeneficiaryList.count() === 0)
                return true;
            return this.total.contingentSum() === 100;
        }, this);
        
        this.reload = function () {
            this.primaryBeneficiaryList.reload();
            this.contingentBeneficiaryList.reload();
        }.bind(this);

        this.allowToSaveAllocation = ko.pureComputed(function() {
            if(this.aggregatedUpdateAllocationRequests.isLoading())
                return false;
            return this.isContingentPercentageValid() && this.isPrimaryPercentageValid();
        }, this);

        this.request = this.contingentBeneficiaryList.updateBeneficiariesRequest;
        
        this.aggregatedUpdateAllocationRequests = new $.cdh.models.aggregatedLoadStatus([
            this.primaryBeneficiaryList.updateBeneficiariesRequest,
            this.contingentBeneficiaryList.updateBeneficiariesRequest
        ]);
        
        this.cancel = function() {
            $.each(this.primaryBeneficiaryList.list(),
                function(i, item) {
                    item.viewModel.Percentage(item.viewModel.CurrentPercentage());
                });

            $.each(this.contingentBeneficiaryList.list(),
                function(i, item) {
                    item.viewModel.Percentage(item.viewModel.CurrentPercentage());
                });

            this.primaryBeneficiaryList.allocationState(false);
            this.contingentBeneficiaryList.allocationState(false);
        }.bind(this);
        
        this.submit = function() {
            if (!this.allowToSaveAllocation())
                return;

            this.primaryBeneficiaryList.submitAllocation()
                .done(function() {
                    this.primaryBeneficiaryList.allocationState(false);
                }.bind(this));

            this.contingentBeneficiaryList.submitAllocation()
                .done(function() {
                    this.contingentBeneficiaryList.allocationState(false);
                }.bind(this));
        }.bind(this);
        
        this.extendedDetails.load();
        this.extendedDetails.data.subscribe(updateHeight);
    }

    ko.components.register('accounts-beneficiary-list-mini', {
        template: { element: 'template-enrollment-beneficiary-list-mini' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new beneficiaryListMini(updateHeight);
            }
        }
    });
})(jQuery, ko);

