(function (module) {

    var transformItemInternal = function(item) {
        return {
            key: item.TaxFormKey,
            name: item.FormName
        };
    };

    module.ListTaxFormsViewModel = function(accountId, specifics, navigator, updateHeight) {
        module.ListViewModelBase.call(this, specifics, updateHeight);

        var self = this;

        self.filters = new module.ListFiltersViewModel(accountId, specifics, navigator);

        module.postbox.subscribe(function(data) {
                var options = {
                    downloadUrl: specifics.routes.download(),
                    transformItem: transformItemInternal
                };
                self.gridItems(module.gridDataAdapter.invoke(accountId, data, options));
            },
            self,
            module.topics.documentsLoaded
        );
    }

})(window.docDelivery);