import template from "./notification-item-opportunity.html"
import { ParticipantOpportunitiesService } from "../../Http/SmartAccount/OpportunitiesService";

ko.components.register('notification-item-opportunity', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new NotificationItemOpportunity(params);
        }
    }
});

export class NotificationItemOpportunity {
    constructor(params) {
        let model = params.model;
        console.log(model);
        const urlParams = {
            opportunityId: $.cdh.helper.urlParam('opportunityId'),
        };

        this.title = model.Title;
        this.refreshCallBack = params.refreshCallBack;
        this.typeId = "";
        this.points = 0;
        this.isExternalUrl = false;
        this.redirectUrl = "";
        this.hasRead = false;
        if (model.Opportunity) {
            this.typeId = model.Opportunity.TypeId;
            this.points = model.Opportunity.Points;
            this.isExternalUrl = model.Opportunity.IsExternalUrl;
            this.redirectUrl = model.Opportunity.RedirectUrl;
            this.hasRead = model.Opportunity.HasRead;
        }

        this.iconCss = this.getIconCss(this.typeId);

        this.toggleRead = async function () {
            if (!this.hasRead) {
                await this.markAsRead();
            }
        };
        this.toggleOptions = async function (data, e) {
            const optionsContainer = $(e.currentTarget).find('.notifications__item-kebab_options');
            optionsContainer.toggle();
            e.stopPropagation();
            $('.notifications__item-kebab_options').not(optionsContainer).hide();
        };
        $(document.body).on('click', function (e) {
            var $kebabMenus = $('.notifications__item-kebab_div');
            var optionsContainers = $('.notifications__item-kebab_options');
            if (!$kebabMenus.is(e.target) && !$kebabMenus.has(e.target).length &&
                !optionsContainers.is(e.target) && !optionsContainers.has(e.target).length) {
                optionsContainers.hide();
            }
        });
        this.markAsRead = async function () {
            const opportunitiesService = new ParticipantOpportunitiesService();
            await opportunitiesService.markAsReadAsync({ Id: model.Id, HasRead: !model.Opportunity.HasRead });
            this.hasRead = true;
        };
        this.handleLinkClick = async function () {
            if (!this.hasRead) {

                await this.markAsRead();
            }
            window.location.href = this.redirectUrl;
        };

        this.updateOpportunity =async function (data, e) {
            var data = {
                Id: model.Id,
                HasRead: !model.Opportunity.HasRead
            };
           
            let opportunitiesService = new ParticipantOpportunitiesService();
            await opportunitiesService.markAsReadAsync(data);
            const opportunityUpdatedEvent = new Event('opportunityrefreshed');
            document.dispatchEvent(opportunityUpdatedEvent);
            ko.postbox.publish($.cdh.topics.alerts.countRefresh, {});
            ko.postbox.publish($.cdh.topics.notifications.countRefresh, {});
        }

        this.dismissOpportunity = async function (data, e) {
            var data = {
                Id: model.Id,
                Status:4
            };
            let opportunitiesService = new ParticipantOpportunitiesService();
            await opportunitiesService.dismissAsync(data.Id);
            const opportunityDismissedEvent = new Event('opportunityrefreshed');
            document.dispatchEvent(opportunityDismissedEvent);
            ko.postbox.publish($.cdh.topics.alerts.countRefresh, {});
            ko.postbox.publish($.cdh.topics.notifications.countRefresh, {});
        }
    }
    
    getIconCss(typeId) {
        let typePart = ""
        switch (typeId) {
            case "LongTermSavings":
            case "MaxOutContributions":
                typePart = "longterm-savings";
                break;
            case "PrescriptionSavings":
            case "SetUpMedicineCabinet":
                typePart = "prescription-savings";
                break;
            case "SwitchToDirectDeposit":
                typePart = "switch-to-dd";
                break;
            case "ChangeElectronicDelivery":
            case "SignUpForElectronicTaxForms":
            case "AddEmailAddress":
                typePart = "email";
                break;
            case "AttachInsuranceCarrier":
                typePart = "plus-only";
                break;
            case "AddMobilePhone":
            case "SignUpForAlerts":
                typePart = "phone";
                break;
            case "AddReceiptToClaim":
                typePart = "add-receipt";
                break;
            case "GeoLocation":
                typePart = "location";
                break;
            case "ProviderSearch":
                typePart = "location-add";
                break
            case "PayIneligibleBalance":
                typePart = "balance-due"
                break;
            case "SpendFsaBalance":
                typePart = "spend-fsa-balance-opportunity"
                break;
        }

        // NOTE: remove this code if different icons are needed.
        typePart = "lamp";

        return "app-icon--" + typePart;
    }

}