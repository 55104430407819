import template from "./account-activity-expense-info.html"

(function ($, ko) {
    ko.components.register('account-activity-expense-info', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityExpenseInfo(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityExpenseInfo(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.model = model;
        self.denialReason = model.DenialReason;
        self.serviceStartDate = model.ServiceStartDate;
        self.serviceEndDate = model.ServiceEndDate;
        
        self.claimant = model.Claimant;
        self.provider = model.Provider;
        self.comments = model.Description;
        self.notes = model.ExpensesDetails[0].Notes;
        self.ilaCategoryValue = model.IlaCategoryDescription;

        if (model.ReimbursementDetails && model.ReimbursementDetails.length > 0) {
            let reimbursementDetail = model.ReimbursementDetails[0];
            self.reimbursementMethod = reimbursementDetail.Method;
            self.paymentId = reimbursementDetail.PaymentId;
            self.payoutMethod = reimbursementDetail.PayoutMethod;
        } else {
            self.reimbursementMethod = null;
            self.paymentId = null;
            self.payoutMethod = null;
        }

        self.ilaCategory = ko.observable();
        if (this.ilaCategoryValue) {
            let ilaCategoryDescription = model.IlaCategoryDescription.replace(/\s*\|\s*/g, '|');
            let parts = ilaCategoryDescription.split('|');
            let formattedPairs = [];
            for (let i = 0; i < parts.length; i += 2) {
                let part1 = parts[i].trim();
                let part2 = parts[i + 1] !== undefined ? parts[i + 1].trim() : '';
                formattedPairs.push(`<tr><td>${part1}</td><td style="padding-left: 3px;">${part2}</td></tr>`);
            }
            let formattedIlaCategory = formattedPairs.join('');
            self.ilaCategory = ko.observable(formattedIlaCategory);
        }


        let totals = model.TotalsExpensesDetails;
        
        self.totals = {
            providerCharged: totals.ProviderChargedAmount,
            allowed: totals.Allowed,
            covered: totals.Covered,
            totalResponsibility: totals.TotalResponsibility,
            outOfPocket: totals.OutOfPocket,
            benefit: totals.Benefit,
            remainingResponsibility: totals.RemainingResponsibility
        };
        
        self.EOBNotes = null;
    }

})(jQuery, ko);