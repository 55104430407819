import template from "./claim-expense-group-details.html"

(function ($, ko) {
    ko.components.register('claim-expense-group-details', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ExpenseGroupDetails(params, widgetInfo.updateHeight);
            }
        }
    });

    function ExpenseGroupDetails(params, updateHeight) {
        const self = this;
        
        let eobSummary = params.model;
        self.eobSummary = eobSummary;

        let total = eobSummary.EOBExtra.Total;
        self.chartTotal = total.TotalPatientResposibility;

        let oopocket = total.PaidNonReimbursable;
        let reimb = total.ReimbursedFromMyAccount;
        let rem = total.Remaining;

        if (oopocket == 0.0 && reimb == 0.0 && rem == 0.0)
            reimb = 0.001;

        //  Rollback wcp-4044
        /*  this.isYourResponsibilityZero = total.DeductibleAmount === 0
              && total.CoinsuranceAmount === 0
              && total.CopayAmount === 0;*/

        self.chartData = [{ value: oopocket, label: $.cdh.languageConstant.getConstant('ClaimDetail.AmountPaidOutOfPacket') },
            { value: reimb, label: $.cdh.languageConstant.getConstant('ClaimDetail.YourBenefitAccountPaid') },
            { value: rem, label: $.cdh.languageConstant.getConstant('field.responsibility') }];

        self.close = params.closeFunc || function () {};
    }
    
})(jQuery, ko);