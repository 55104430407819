import template from "./medicine-cabinet-pharmacy.html"

(function ($, ko) {

    ko.components.register('medicine-cabinet-pharmacy', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new Pharmacy(params, widgetInfo)
            }
        }
    });

    function Pharmacy(params, widgetInfo) {
        const self = this;
        
        self.pharmacyService = new $.cdh.http.smartAccount.PharmacyService();
        self.pharmacyService.completed(true);
        
        self.pharmacy = ko.observable(params.pharmacy);
        
        self.refreshCallback = function() {
            self.pharmacyService.getAsync().then(function (pharmacy) {
                self.pharmacy(pharmacy.data())
                widgetInfo.updateHeight();
            });
        };
        
    }
})(jQuery, ko);