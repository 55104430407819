(function ($, ko) {
    var alertsFilterModel = function (alertsRequest) {
        this.fromDateFilter = ko.observable('');
        this.toDateFilter = ko.observable('');
        this.textFilter = ko.observable('');
        this.sms = ko.observable(true);
        this.email = ko.observable(true);
        this.letter = ko.observable(true);
        this.alertsRequest = alertsRequest;

        this.visible = ko.observable(false);
        this.filterApplied = ko.observable(false);

        var filterValues = ko.observable({
            fromDate: null,
            toDate: null,
            text: '',
            sms: true,
            email: true,
            letter : true
        });

        function filterBySearch(s, a) {
            return (!s.fromDate || moment(a.AlertDateTime).isSameOrAfter(s.fromDate)) &&
                (!s.toDate || moment(a.AlertDateTime).isSameOrBefore(s.toDate)) &&
                (!s.text || (a.AlertSubject + ' ' + a.AlertTypeName).toLowerCase().indexOf(s.text.toLowerCase()) > -1);
        }

        function filterByType(s, a) {
            return ((a.CommunicationType === $.cdh.enums.messageType.sms && s.sms) ||
                (a.CommunicationType === $.cdh.enums.messageType.email && s.email) ||
                (a.CommunicationType === $.cdh.enums.messageType.letter && s.letter));
        }

        this.filteredAlerts = ko.pureComputed(function () {
            if (this.alertsRequest.isLoading())
                return [];
            
            var s = filterValues();
            var result = $.grep(this.alertsRequest.data(), function (a) {
                if (this.visible() && this.filterApplied()) {
                    return filterBySearch(s, a) && filterByType(s, a);
                }
                return filterByType(s, a);
            }.bind(this));
            return result;
        }, this);

        this.clickFind = function () {
            this.filterApplied(true);

            var fromDate = moment(this.fromDateFilter());
            var toDate = moment(this.toDateFilter());

            var filter = {
                fromDate: fromDate.isValid() ? fromDate : null,
                toDate: toDate.isValid() ? toDate.endOf('day') : null,
                text: this.textFilter().trim(),
                sms: this.sms(),
                email: this.email(),
                letter: this.letter()
            };

            filterValues(filter);
        }.bind(this);

        this.clickSms = function() {
            this.sms(!this.sms());
            this.clickFind();
        }.bind(this);

        this.clickEmail = function() {
            this.email(!this.email());
            this.clickFind();
        }.bind(this);

        this.clickLetter = function() {
            this.letter(!this.letter());
            this.clickFind();
        }.bind(this);

        this.toggleFilter = function () {
            this.visible(!this.visible());
            if (!this.visible())
                this.filterApplied(false);
        }

        this.close = function() {
            this.visible(false);
            this.filterApplied(false);
            //this.close(false);
        }
    }


    $.extend(true, $, {
        cdh: {
            models: {
                AlertsFilter: alertsFilterModel
            }
        }
    });
})(jQuery, ko);