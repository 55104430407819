(function ($, ko) {
    var bankAccountsAddPopup = function (dataPartnerId, onSuccessAdd) {
        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.directDepositAccount =
        {
            display : ko.observable(false),
            message : ko.observable(),
            bankAccountName: ko.observable(),
            routingNumber: ko.observable(),
            accountNumber: ko.observable(),
            accountType: ko.observable()
        };

        this.profileRequest = new $.cdh.get.profile.get(function (data) {

            this.directDepositAccount.message($.cdh.languageConstant.getConstant('messages.would-like-use-account', { '0': data.Bank }));
            this.directDepositAccount.display(data.ReimbursementMethod === $.cdh.enums.reimbursementMethod.directDeposit);

            this.directDepositAccount.bankAccountName(data.Bank);
            this.directDepositAccount.routingNumber(data.BankRoutingNumber);
            this.directDepositAccount.accountNumber(data.BankAccountNumber);


            if (data.BankAccountTypeCode === $.cdh.enums.bankAccountType.checking) {
                this.directDepositAccount.accountType($.cdh.enums.bankAccountType.checking);
            }
            else if (data.BankAccountTypeCode === $.cdh.enums.bankAccountType.savings) {
                this.directDepositAccount.accountType($.cdh.enums.bankAccountType.savings);
            }
        }.bind(this));

        this.useParticipantBankAcct = ko.observable(false);

        this.directDepositAccountYes = function () {
            this.validation.deactivate();
            var maskedRoutingNumber = $.cdh.helper.maskAccountRoutingNumbers(this.directDepositAccount.routingNumber());
            var maskedAccountNumber = $.cdh.helper.maskAccountRoutingNumbers(this.directDepositAccount.accountNumber());
            this.bankAccount.BankAccountName(this.directDepositAccount.bankAccountName ());
            this.bankAccount.RoutingNumber(maskedRoutingNumber);
            this.bankAccount.RoutingNumber2(maskedRoutingNumber);
            this.bankAccount.AccountNumber(maskedAccountNumber);
            this.bankAccount.AccountNumber2(maskedAccountNumber);
            this.bankAccount.AccountType(this.directDepositAccount.accountType());
            this.useParticipantBankAcct(true);

            this.directDepositAccount.display(false);
        };

        this.directDepositAccountNo = function () {
            this.directDepositAccount.display(false);
        };

        this.profileRequest.load();

        this.bankAccount = {
            DataPartnerId: dataPartnerId,
            InstitutionName: ko.observable().extend({ required: true }),
            BankAccountName: ko.observable().extend({ required: true }),
            RoutingNumber: ko.observable().extend({ required: true, routingNumber: true }),
            AccountNumber: ko.observable().extend({ required: true, betweenLength: [5, 17] }),
            AccountType: ko.observable($.cdh.enums.bankAccountType.checking)
        };
        this.bankAccount.RoutingNumber2 = ko.observable().extend({
            required: true,
            equal: { message: $.cdh.languageConstant.getConstant('messages.routing-numbers-not-match'), params: this.bankAccount.RoutingNumber }
        });
        this.bankAccount.AccountNumber2 = ko.observable().extend({
            required: true,
            equal: { message: $.cdh.languageConstant.getConstant('messages.account-numbers-not-match'), params: this.bankAccount.AccountNumber }
        });

        this.addRequest = new $.cdh.post.bankAccounts.add();

        this.validation = new $.cdh.validation(this.bankAccount);
        this.useParticipantBankAcct.subscribe(function (newValue) {
            if (newValue) {
                this.bankAccount.RoutingNumber.extend({ validatable: false });
                this.bankAccount.AccountNumber.extend({ validatable: false });
            }
        }, this)

        this.canSubmit = ko.pureComputed(function () {
            return !this.addRequest.isLoading() && !this.validation.hasErrors();
        }, this);

        this.clickSubmit = function () {
            this.validation.activate();

            if (!this.canSubmit())
                return;

            var addBankAccountModel = {
                DataPartnerId: this.bankAccount.DataPartnerId,
                InstitutionName: this.bankAccount.InstitutionName(),
                BankAccountName: this.bankAccount.BankAccountName(),
                RoutingNumber: this.useParticipantBankAcct() ? this.directDepositAccount.routingNumber() : this.bankAccount.RoutingNumber(),
                AccountNumber: this.useParticipantBankAcct() ? this.directDepositAccount.accountNumber() : this.bankAccount.AccountNumber(),
                AccountType: this.bankAccount.AccountType(),
                UseParticipantBankAcct: this.useParticipantBankAcct()
            };

            this.addRequest
                .load(ko.toJS(addBankAccountModel))
                .done(function () {
                    this.close();
                    onSuccessAdd();
                }.bind(this));
        }
    }


    $.extend(true, $, {
        cdh: {
            models: {
                BankAccountsAddPopup: bankAccountsAddPopup
            }
        }
    });
})(jQuery, ko);