import template from "./claim-validation-attach-receipt.html"

(function ($, ko) {
    ko.components.register('claim-validation-attach-receipt', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new UploadReceiptComponent(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
    
    function UploadReceiptComponent(params, updateHeight){
        this.widget = params.widget;
        this.entry = params.entry;
        this.updateHeight = updateHeight;
        this.receipts = ko.observableArray([]);

        this.ClaimValidationAttachReceiptLabel = {
            lblUplaodReceipt: $.cdh.languageConstant.getConstant('title.upload-receipt'),
            lblBrowse: $.cdh.languageConstant.getConstant('button.browse'),
            lblDragDrop: $.cdh.languageConstant.getConstant('field.drag-drop'),
            lblReceipts: $.cdh.languageConstant.getConstant('field.receipts-here'),
            lblCancel: $.cdh.languageConstant.getConstant('button.cancel'),
            lblNext: $.cdh.languageConstant.getConstant('button.next'),
            lblReceiptRequired: $.cdh.languageConstant.getConstant('claim.receipt-required')
        };

        if (this.widget.data().IsReceiptsRequired) {
            this.receipts.extend({
                minArrayLength: {
                    params: { minLength: 1 },
                    message: this.ClaimValidationAttachReceiptLabel.lblReceiptRequired
                }
            });
        }
        if(this.widget.data().ExpenseReceipt !== null){
            this.widget.data().ExpenseReceipt.isExpenseReceipt = true;
            this.receipts.push(this.widget.data().ExpenseReceipt);
        }
        this.receipts.subscribe(updateHeight);
        
        this.validation = new $.cdh.validation(this.receipts);

        this.onRemoveReceipt = function (r) {
            this.receipts.remove(r);
        }.bind(this);
        
        this.onCancel = function(){
            if(typeof params.onCancel === "function")
                params.onCancel();
        }.bind(this);

        this.onNext = function () {
            if (this.widget.data().IsReceiptsRequired) {
                this.validation.activate();
                if (this.validation.hasErrors())
                    return;
            }

            if (typeof params.onNext === "function") {
                params.onNext(this.receipts());
                setTimeout(updateHeight, 600);
            }                
        }.bind(this);
    }
})(jQuery, ko);