export class ComponentBase {

    constructor() {
        this.error = ko.observable(null);
        this.canSubmit = ko.observable(true);
        this.loading = ko.observable(false);
    }

    async init() {
        // do nothing, to be overriden in derived class
    }
}

ComponentBase.prototype.apiCallAsync = async function(apiCall: function) : void {
    this.error(null);
    this.canSubmit(false);
    try {
        await apiCall();
    } catch (e) {
        this.error(e);
    } finally {
        this.canSubmit(true);
    }
}

ComponentBase.prototype.apiInitCallAsync = async function(apiCall: function) : void {
    this.error(null);
    this.canSubmit(false);
    this.loading(true);
    try {
        await apiCall();
    } catch (e) {
        this.error(e);
    } finally {
        this.canSubmit(true);
        this.loading(false);
    }
}