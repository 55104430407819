import template from "./medicine-cabinet-prescriptions.html"

(function ($, ko) {
    ko.components.register('medicine-cabinet-prescriptions', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new Prescriptions(params, widgetInfo);
            }
        }
    });

    function Prescriptions(params, widgetInfo) {
        const self = this;
        
        self.prescriptionsService = new $.cdh.http.smartAccount.PrescriptionsService();
        self.prescriptionsService.completed(true);
        self.fullName = params.fullName;
        
        self.prescriptionsList = ko.observableArray(params.prescriptions);
        
        self.refreshCallback = function () {
            self.prescriptionsList([]);
            self.prescriptionsService.getAsync().then(function (result) {
                let items = result.data();
                self.prescriptionsList(items);
                widgetInfo.updateHeight();
                
                params.hasPrescriptions(items.length > 0);
            });
        };
    }

})(jQuery, ko);