import template from "./claim-validation-signature.html"

(function ($, ko) {
    ko.components.register('claim-validation-signature', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new SignatureSignModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function SignatureSignModel(params, updateHeight){
        this.widget = params.widget;
        this.entry = params.entry;
        this.updateHeight = updateHeight;
        this.currentStepModel = ko.observable(new providerSignStepModel(this));

        this.ClaimValidationSignatureLabel = {
            lblClaimDetails: $.cdh.languageConstant.getConstant('field.claim-details'),
            lblClaimDocumentation: $.cdh.languageConstant.getConstant('claim.documentation'),
            lblClaimSubmission: $.cdh.languageConstant.getConstant('claim.confirm-submission'),
            lblProviderSign: $.cdh.languageConstant.getConstant('claim.provider-sign'),
            lblCancel: $.cdh.languageConstant.getConstant('button.cancel'),
            lblClear: $.cdh.languageConstant.getConstant('button.clear'),
            lblNext: $.cdh.languageConstant.getConstant('button.next'),
        };

        this.onNext = function(signModel){
            if (typeof params.onNext === "function") {
                params.onNext(signModel);
                setTimeout(this.updateHeight, 600);
            }                
        }.bind(this);
        
        this.onCancel = function(){
            if(typeof params.onCancel === "function")
                params.onCancel();
            setTimeout(this.updateHeight, 600);
        }.bind(this);
        
        this.isProviderSignView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isProviderSignView;
        }, this);
    }
    
    function providerSignStepModel(model){
        this.isProviderSignView = true;
        this.widget = model.widget;
        this.entry = model.entry;

        this.signEmpty = ko.observable(true);
        this.signData = ko.observable();
        this.signDate = moment();
        this.signed = ko.observable(null).extend({
            required: {
                onlyIf: function() {
                    return this.signEmpty();
                }.bind(this)
            }
        });
        this.signValidation = new $.cdh.validation(this.signed);
        this.eSignatureAgreement = this.widget.data().Template.ESignatureAgreement ||
            'I, as the service provider, certify that the charges listed above are accurate and were incurred during the services dates specified. I hereby provide my electronic signature as acknowledgement.';

        this.message = ko.observable('');
        
        this.onNext = function(){
            this.signed(this.signEmpty() ? null : true);

            this.signValidation.activate();
            if(this.signValidation.hasErrors())
                return;

            this.signData($("#signature").jSignature('getData', "image"));

            model.onNext(this);
            setTimeout(model.updateHeight, 600);
        }.bind(this);

        this.onSign = function(){
            $("#signature").show();
            $("#signature_message").hide();
            $("#signature").jSignature();
            $("#signature").bind('change', function(e){
                this.signEmpty(false);
            }.bind(this));
            setTimeout(model.updateHeight, 600);
        }.bind(this);

        this.onReset = function(){
            if(!this.signEmpty()) {
                $('#signature').jSignature('reset');
                this.signEmpty(true);
            }
        }.bind(this);

        this.onCancel = function(){
            model.onCancel();
        }.bind(this);
    }
})(jQuery, ko);