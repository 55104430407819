import template from "./account-activity-hsa-deposit-info.html"

ko.components.register('account-activity-hsa-deposit-info', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new TransactionInfo(params, widgetInfo);
        }
    }
});

export class TransactionInfo {
    constructor(params, widgetInfo) {
        let model = params.model;

        this.model = model;
        this.date = model.FullDate;
        this.customDescription = model.CustomDescription;
        this.description = model.Description;
        this.serviceStartDate = model.ServiceStartDate;
    }
}