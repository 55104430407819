import template from "./account-activity-card-info.html"

(function ($, ko) {
    ko.components.register('account-activity-card-info', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityCardInfo(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityCardInfo(params, updateHeight) {
        const self = this;
        let model = params.model;
        let isValueSet = $.cdh.helper.isValueSet;

        self.model = model;
        self.transactionDate = model.FullDate;
        self.claimant = model.Claimant;
        self.merchantName = model.CardTransactionDetails.MerchantName;
        self.merchantType = model.CardTransactionDetails.MerchantType;
        self.total = model.Amount;
        self.totalApproved = model.CardTransactionDetails.ApprovedAmount;
        self.ineligibleAmount = model.CardTransactionDetails.IneligibleAmount;
        self.denialReason = !!(model?.DenialReason) ? model?.DenialReason: model?.CardTransactionDetails?.DenialReason;
        self.customDescription = isValueSet(model.CustomDescription) ? model.CustomDescription : model.CardTransactionDetails.CustomDescription;
        self.serviceStartDate = model.ServiceStartDate;
        self.isIneligibleAmountNotZero = self.ineligibleAmount !== 0;
        self.ilaCategoryValue = model.IlaCategoryDescription;
        self.ilaCategory = ko.observable();
        if (this.ilaCategoryValue) {
            let ilaCategoryDescription = model.IlaCategoryDescription.replace(/\s*\|\s*/g, '|');
            let parts = ilaCategoryDescription.split('|');
            let formattedPairs = [];
            for (let i = 0; i < parts.length; i += 2) {
                let part1 = parts[i].trim();
                let part2 = parts[i + 1] !== undefined ? parts[i + 1].trim() : '';
                formattedPairs.push(`<tr><td>${part1}</td><td style="padding-left: 3px;">${part2}</td></tr>`);
            }
            let formattedIlaCategory = formattedPairs.join('');
            self.ilaCategory = ko.observable(formattedIlaCategory);
        }
    }

})(jQuery, ko);