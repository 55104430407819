import { DataServiceBase } from "../../DataServiceBase";

(function ($, ko) {

    class ProvidersService extends DataServiceBase {
        constructor() {
            super('/SmartAccount/Vendors/FindCare');
        }

        providers(ProviderPageParams) {
            let SearchParams = {
              
                Distance: ProviderPageParams.distance,
                Address: ProviderPageParams.zipCode,
                InsuranceIds: ProviderPageParams.selectedInsuranceUuid,
                SpecialityIds: ProviderPageParams.selecteSpecialistUUid,
                ProcedureIds: ProviderPageParams.selectedProcedureUUid,
                Rating: ProviderPageParams.rating,
                MinCostIndex: ProviderPageParams.cost,
                MinConfidence: ProviderPageParams.MinConfidence
            }
            let PageParams = {
                PageNo: ProviderPageParams.pageNo,
                PageSize: ProviderPageParams.pageSize,
            }
            let Params = {
                PageParams: PageParams,
                SearchParams : SearchParams
                }
            return this.getAsync(Params, "Providers");
        };

      
    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                vendors: {
                    ProvidersService: ProvidersService
                }
            }
        }
    });

})(jQuery, ko);