import template from "./claim-submit-entry.html"

(function ($, ko) {
    ko.components.register('claim-submit-entry', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ClaimEntryModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ClaimEntryModel(params, updateHeight){
        this.updateHeight = updateHeight;
        this.widget = params.widget;
        this.data = params.entry;
        this.expense = this.widget.data().Claim;

        this.ClaimSubmitEntryLabel = {
            lblServiceType: $.cdh.languageConstant.getConstant('field.service-type'),
            lblReimburseMethod: $.cdh.languageConstant.getConstant('profile.reimbursement-method'),
            lblAccountType: $.cdh.languageConstant.getConstant('field.account-type'),
            lblDeductibleAmount: $.cdh.languageConstant.getConstant('field.deductible-amount'),
            lblCopayAmount: $.cdh.languageConstant.getConstant('field.copay-amount'),
            lblCoinsuranceAmount: $.cdh.languageConstant.getConstant('field.coinsurance-amount'),
            lblOnce: $.cdh.languageConstant.getConstant('filter.once'),
            lblWeekly: $.cdh.languageConstant.getConstant('filter.weekly'),
            lblMonthly: $.cdh.languageConstant.getConstant('filter.monthly'),
            lblComments: $.cdh.languageConstant.getConstant('field.comments'),
            lblProviderName: $.cdh.languageConstant.getConstant('field.provider-name'),
            lblClaimDetails: $.cdh.languageConstant.getConstant('field.claim-details'),
            lblClaimDocumentation: $.cdh.languageConstant.getConstant('claim.documentation'),
            lblClaimSubmission: $.cdh.languageConstant.getConstant('claim.confirm-submission'),
            lblRequiredField: $.cdh.languageConstant.getConstant('claim.required-field')
        };
        

        if(this.expense){
            this.expense.EOBExtra.Transactions = ko.observableArray([]);
        }
        
        this.allowedServiceCategoryCodes = this.widget.data().Template.ServiceCategoryCodes
            .filter(function (s, index) {
               return s.IsDirectSubmit || index === this.widget.data().SelectedSccCode
            }.bind(this));

        this.title = $.cdh.helper.titlePage();
        if (!this.title || this.title.length === 0)
            this.title = 'Add claim for immediate reimbursement';

        this.minStartDefault = ko.observable(null);
        this.minEndDefault = ko.observable(null);
        this.maxStartDefault = ko.observable(null);
        this.maxEndDefault = ko.observable(null);
        
        this.AccountStartDate = ko.observable(moment().toDate());
        this.AccountEndDate = ko.observable(moment().add(1, 'year').toDate());

        this.payProviderUpdateModel = null;

        this.priorReimMethod = ko.observable();
        this.claimantEditable = ko.observable(true);

        this.reimbursementMethodList = ko.observableArray();
        
        this.currentStepModel = ko.observable(null);

        this.EligibilityDate = null;

        this.reimbursementSettings = this.widget.data().ReimbursementSettings;

        //contains logic to work with providers 
        this.providers = new addProviderStepModel(this);
        this.providers.setupList(this.widget.data().Payees);

        //contain logic to work with claimants
        this.claimants = new addClaimantStepModel(this);
        this.claimants.setupList(this.widget.data().Template.Claimants);
        
        this.entry = {
            start: ko.observable(null).extend({ required: true }),
            minStart: ko.observable(this.minStartDefault()),
            maxStart: ko.observable(this.maxStartDefault()),
            end: ko.observable(null),
            minEnd: ko.observable(this.minEndDefault()),
            maxEnd: ko.observable(this.maxEndDefault()),
            useProvider: ko.observable(this.widget.data().UseProviderForFx),
            useRecuring: ko.observable(false),
            PaymentRecurrenceFrequency: ko.observable($.cdh.enums.paymentRecurrenceFrequency.once),
            PaymentRecurrenceType: ko.observable($.cdh.enums.paymentRecurrenceType.totalCount),
            claimant: ko.observable(null),
            reimbursementMethod: ko.observable(null),
            providerName: ko.observable(null),
            providerChoice: ko.observable(),
            account: ko.observable(null),
            accountNumber: ko.observable(null),
            comments: ko.observable(null),
            scc: ko.observable(null),
            amount: ko.observable("0.00").extend({ required: true, min: 0.01 }),
            originalAmount: ko.observable(0),
            copay: ko.observable().extend({ min: 0.00 }),
            coinsurance: ko.observable().extend({ min: 0.00 }),
            deductible: ko.observable().extend({ min: 0.00 }),
            allowPosOffset: ko.observable(true),
            PaymentDate: ko.observable('')
        };
        
        this.entry.sccAllowPayProvider = ko.pureComputed(function () {
            return !this.entry.scc() || this.entry.scc().AllowPayProvider;
        }, this);

        this.entry.sccAllowFutureDatedClaims = ko.pureComputed(function () {
            return !this.entry.scc() || this.entry.scc().AllowFutureDatedClaims;
        }, this);
        
        this.entry.start.subscribe(function(value){
            this.entry.minEnd(moment(value));
            
            var start = moment(value);
            if(!this.entry.end()) {
                this.entry.end(value);
                return;
            }
            
            var end = moment(this.entry.end());
            if(end < start) 
                this.entry.end(value);
        }, this);
        
        this.entry.end.subscribe(function(value){
            if(!value)
                this.entry.end(this.entry.start());
        }, this);

        this.totalPayments = ko.pureComputed(function () {
            var amount = this.entry.amount() ? this.entry.amount() : 0;

            if (this.entry.PaymentRecurrenceType() === $.cdh.enums.paymentRecurrenceType.totalCount)
                return (amount * this.entry.RecurrenceTotalCount()).toFixed(2);

            if (this.entry.PaymentRecurrenceType() === $.cdh.enums.paymentRecurrenceType.untilDate) {
                if (!this.entry.PaymentDate() || !this.entry.RepeatUntilDate())
                    return;

                var start = moment(this.entry.PaymentDate());
                var end = moment(this.entry.RepeatUntilDate());
              
                switch (this.entry.PaymentRecurrenceFrequency()) {

                    case $.cdh.enums.paymentRecurrenceFrequency.weekly :
                        var weeks = end.diff(start, 'weeks');
                        var weekMultiplier = Math.floor(weeks) + 1;
                        return (amount * weekMultiplier).toFixed(2);
                    case $.cdh.enums.paymentRecurrenceFrequency.monthly :
                        var months = end.diff(start, 'months');
                        var monthMultiplier = Math.floor(months) + 1;
                        return (amount * monthMultiplier).toFixed(2);
                    default:
                        return 0;
                }
            }
        }, this);
        
        this.isCopayVisible = ko.pureComputed(function(){
            return this.widget.data().IsCopayVisible && this.entry.scc();
        }, this);
        
        this.isCopayRequired = ko.pureComputed(function(){
            return this.widget.data().IsCopayRequired && this.entry.scc();
        }, this);
        
        this.isCoinsuranceVisible = ko.pureComputed(function(){
            return this.widget.data().IsCoinsuranceVisible && this.entry.scc();
        }, this);
        
        this.isCoinsuranceRequired = ko.pureComputed(function(){
            return this.widget.data().IsCoinsuranceRequired && this.entry.scc();
        }, this);
        
        this.isDeductibleVisible = ko.pureComputed(function(){
            return this.widget.data().IsDeductibleVisible && this.entry.scc();
        }, this);
        
        this.isDeductibleRequired = ko.pureComputed(function(){
            return this.widget.data().IsDeductibleRequired && this.entry.scc();
        }, this);
        
        this.isRecurringVisible = ko.pureComputed(() => !this.expense && (this.entry.scc() && this.entry.scc().IsRecurringVisible !== null ? this.entry.scc().IsRecurringVisible : this.widget.data().IsRecuringVisible));
        
        this.isRecurringAllowWeekly = ko.pureComputed(() => !this.expense && (this.entry.scc() && this.entry.scc().IsRecurringAllowWeekly !== null ? this.entry.scc().IsRecurringAllowWeekly : this.widget.data().Template.IsRecurringAllowWeekly));
        this.isRecurringAllowMonthly = ko.pureComputed(() => !this.expense && (this.entry.scc() && this.entry.scc().IsRecurringAllowMonthly !== null ? this.entry.scc().IsRecurringAllowMonthly : this.widget.data().Template.IsRecurringAllowMonthly));

        this.validation = new $.cdh.validation(this.entry);

        var e = this.entry;
        if (this.expense) {
            e.amount(this.widget.data().IsFx ? "0.00" : this.expense.Amount.toFixed(2));
            e.originalAmount(this.expense.Amount.toFixed(2));
            e.start(this.expense.ServiceStartDate);
            e.end(this.expense.ServiceEndDate);
            e.providerName(this.widget.data().ProviderName);
            e.comments(this.expense.EOBNotes);

            this.claimantEditable(this.widget.data().ClaimantEditable);
        }
        
        this.entry.end.extend({emptyValidation:'nop'});

        if(this.widget.data().IsFx) {
            var total = this.widget.data().FxDetails.Total;

            this.expense.EOBExtra.Transactions(this.widget.data().FxDetails.Transactions);
            this.expense.EOBExtra.Total = total;
            this.entry.amount(total.RemainingResponsibilityAmount);
            this.entry.end(total.ServiceEndDate);
            this.entry.useProvider(total.IsPayProvider);

            if (total.ServiceEndDate == null)
                this.entry.end.extend({dateGreaterThen: this.entry.start});
        }

        if(this.widget.data().SelectedSccCode >= 0) {
            this.entry.scc(this.widget.data().Template.ServiceCategoryCodes[this.widget.data().SelectedSccCode]);
        }

        if(!this.widget.data().IsFx){
            this.entry.end.extend({ dateGreaterThen: this.entry.start });
        }

        if (this.widget.data().SelectedClaimant >= 0) {
            var claimant = this.widget.data().Template.Claimants[this.widget.data().SelectedClaimant];
            this.claimants.selected(claimant);
        }

        this.entry.scc.extend({required: this.widget.data().SccRequired && this.widget.data().SccVisible});

        this.entry.reimbursementMethod.extend({ reimbRequired: this.widget.data().IsReimbursementMethodRequired });
        this.entry.comments.extend({ required: this.widget.data().IsCommentsRequired });
        
        this.entry.useRecuring.subscribe(function (val) {
            if (!this.entry.sccAllowFutureDatedClaims()) {
                if (val) {
                    this.entry.maxStart(this.maxStartDefault());
                    this.entry.maxEnd(this.maxEndDefault());
                } else {
                    this.entry.maxStart(moment($.cdh.helper.dateNowString()));
                    this.entry.maxEnd(moment($.cdh.helper.dateNowString()));
                }
            }
        }, this);
        
        this.maxDateRestriction = ko.pureComputed(function() {
            if (this.entry.useRecuring()) {
                return this.widget.data().MaxDate;
            } else {
                return this.widget.data().FutureDate;
            }
        }, this);
        
        if(!this.entry.useRecuring()) {
            this.entry.start.extend({
                dateLessThen: {
                    params: this.maxDateRestriction,
                    message: "Future date is not allowed",
                    onlyIf: function () {
                        return !this.entry.sccAllowFutureDatedClaims();
                    }.bind(this)
                }
            }, this);

            this.entry.end.extend({
                dateLessThen: {
                    params: this.maxDateRestriction,
                    message: "Future date is not allowed",
                    onlyIf: function () {
                        return !this.entry.sccAllowFutureDatedClaims();
                    }.bind(this)
                }
            }, this);
        }

        if(this.widget.data().EligibilityDate) {
            this.entry.start.extend({
                dateGreaterThen: {
                    params: this.widget.data().EligibilityDate,
                    message: "Service date must be within eligibility period"
                }
            });
        }
        
        if(this.widget.data().MinStartDefault){
            this.entry.minStart(this.widget.data().MinStartDefault)
        }

        if(this.widget.data().SelectedAccount){
            this.entry.account(this.widget.data().Template.Accounts[this.widget.data().SelectedAccount]);
        }

        this.entry.PaymentDate.extend({
            required: {
                onlyIf: function() {
                    return this.entry.useRecuring();
                }.bind(this)
            },
            betWeenDates: { params: [this.AccountStartDate, this.AccountEndDate], message: "Service dates must be within plan start and end dates." }
        });

        this.entry.claimant.extend({
            required: {
                onlyIf: function() {
                    return !this.entry.claimant() || this.entry.claimant() == this.claimants.selectListOptionsCaption;
                }.bind(this)
            }
        });

        this.entry.copay.extend({
            required: {
                onlyIf: function () {
                    return this.widget.data().IsCopayRequired && this.entry.scc();
                }.bind(this)
            }
        });

        this.entry.coinsurance.extend({
            required: {
                onlyIf: function() {
                    return this.widget.data().IsCoinsuranceRequired && this.entry.scc();
                }.bind(this)
            }
        });

        this.entry.deductible.extend({
            required: {
                onlyIf: function () {
                    return this.widget.data().IsDeductibleRequired && this.entry.scc();
                }.bind(this)
            }
        });

        this.entry.providerChoice.subscribe(function (choice) {
            if (choice == this.providers.addNewProviderOption) {
                this.entry.providerChoice(null);
                this.currentStepModel(this.providers);
                $(window).scrollTop(0);
            }
            
            updateHeight();
        }, this);

        this.entry.providerChoice.extend({
            required: { onlyIf: function () {
                    return !$.cdh.user.impersonated && this.useProvider(); }.bind(this.entry)
            }
        });

        this.entry.useProvider.subscribe(function (value) {
            this.setReimbursementMethodList();

            //if ($.cdh.user.impersonated) {
            //    return;
            //}

            if(value){
                this.priorReimMethod(this.entry.reimbursementMethod());
                this.bindReimbursementMethod();

                if (this.providers.addProviderModel.createAutoPopulatedProvider()) {
                    this.entry.providerChoice(this.providers.addNewProviderOption);
                }
            }else{
                this.bindReimbursementMethod(this.priorReimMethod());
            }
            
            updateHeight();
        }.bind(this));

        var recurrenceCountOnlyIf = function () {
            return this.PaymentRecurrenceFrequency() !== $.cdh.enums.paymentRecurrenceFrequency.once &&
                this.PaymentRecurrenceType() === $.cdh.enums.paymentRecurrenceType.totalCount;
        }.bind(this.entry);

        this.entry.RecurrenceTotalCount = ko.observable(1).extend({
            required: { onlyIf: recurrenceCountOnlyIf },
            min: { params: 1, onlyIf: recurrenceCountOnlyIf }
        });

        this.entry.RepeatUntilDate = ko.observable('').extend({
            required: {
                onlyIf: function () {
                    return this.PaymentRecurrenceFrequency() !== $.cdh.enums.paymentRecurrenceFrequency.once &&
                        this.PaymentRecurrenceType() === $.cdh.enums.paymentRecurrenceType.untilDate;
                }.bind(this.entry)
            }
        });

        this.minPaymentDate = ko.observable(moment().add(1, 'day').toDate());
        this.minUntilPaymentDate = ko.pureComputed(function () {
            if (!this.entry.PaymentDate()) {
                return moment().add(1, 'day').toDate();
            }
            return moment(this.entry.PaymentDate()).add(1, 'day').toDate();
        }, this);

        this.bindReimbursementMethod = function (method) {
            if (method) {
                this.entry.reimbursementMethod(method);
                return;
            }
            var payProviderReimbursementMethod = this.reimbursementSettings.PayProviderReimbursementMethod;
            var payToSelfReimbursementMethod = this.reimbursementSettings.PayToSelfReimbursementMethod;

            if (this.widget.data().IsReimbursementMethodListVisible) {
                payProviderReimbursementMethod = this.reimbursementMethodList().filter(function (method) {
                    return method.Id == this.reimbursementSettings.PayProviderReimbursementMethod.Id;
                }.bind(this))[0];

                payToSelfReimbursementMethod = this.reimbursementMethodList().filter(function (method) {
                    return method.Id == this.reimbursementSettings.PayToSelfReimbursementMethod.Id;
                }.bind(this))[0];
            }
            
            this.entry.reimbursementMethod(this.entry.useProvider()
                ? payProviderReimbursementMethod
                : payToSelfReimbursementMethod)
        }.bind(this);

        this.setReimbursementMethodList = function() {
            this.reimbursementMethodList(this.reimbursementSettings.ValidReimbursementMethodList);
        }.bind(this);

        this.entry.account.subscribe(function(acc){
            if(acc && (acc.PlanEndDate || acc.PlanStartDate)){
                this.AccountEndDate(acc.PlanEndDate);
                this.AccountStartDate(acc.PlanStartDate);
            }else{
                this.AccountStartDate(moment().toDate());
                this.AccountEndDate(moment().add(1, 'year').toDate());
            }
        }, this);

        this.notAllowPosOffset = ko.observable(!this.entry.allowPosOffset());
        this.entry.allowPosOffset.subscribe(function(value) {
            this.notAllowPosOffset(!value);
        }, this);
        this.notAllowPosOffset.subscribe(function(value) {
            this.entry.allowPosOffset(!value);
        }, this);
        
        this.providers.initializeDefaultProviderInfo();

        this.setReimbursementMethodList();
        this.bindReimbursementMethod();

        //if data is not empty, set the entry state from data
        if(this.data) {
            this.entry.start(this.data.start());
            this.entry.end(this.data.end());
            this.entry.maxStart(this.data.maxStart());
            this.entry.maxEnd(this.data.maxEnd());
            this.entry.useProvider(this.data.useProvider());
            this.entry.useRecuring(this.data.useRecuring());
            this.entry.PaymentRecurrenceFrequency(this.data.PaymentRecurrenceFrequency());
            this.entry.PaymentRecurrenceType(this.data.PaymentRecurrenceType());
            this.entry.claimant(this.data.claimant());
            this.entry.reimbursementMethod(this.data.reimbursementMethod());
            this.entry.providerName(this.data.providerName());
            this.entry.providerChoice(this.data.providerChoice());
            this.entry.account(this.data.account());
            this.entry.accountNumber(this.data.accountNumber());
            this.entry.comments(this.data.comments());
            this.entry.scc(this.data.scc());
            this.entry.amount(this.data.amount());
            this.entry.originalAmount(this.data.originalAmount());
            this.entry.copay(this.data.copay());
            this.entry.coinsurance(this.data.coinsurance());
            this.entry.deductible(this.data.deductible());
            this.entry.allowPosOffset(this.data.allowPosOffset());
            this.entry.PaymentDate(this.data.PaymentDate());
        }

        this.entry.useRecuring.subscribe(updateHeight);
        this.entry.end.subscribe(updateHeight);
        this.entry.amount.subscribe(updateHeight);
        this.entry.PaymentRecurrenceType.subscribe(updateHeight);
        this.entry.scc.subscribe(function(scc) {
            if(scc && !scc.AllowPayProvider) {
                this.entry.useProvider(false);
            }
            
            if(scc && !scc.AllowFutureDatedClaims) {
                if(!this.expense) {
                    this.entry.maxStart(moment($.cdh.helper.dateNowString()));
                    this.entry.maxEnd(moment($.cdh.helper.dateNowString()));
                }

                if(!this.data) {
                    this.entry.useRecuring(false);
                } else{
                    this.entry.useRecuring(this.data.useRecuring());
                }
            }

            if(!scc || scc.AllowFutureDatedClaims) {
                if(!this.expense) {
                    this.entry.maxStart(this.maxStartDefault());
                    this.entry.maxEnd(this.maxEndDefault());
                }

                this.entry.useRecuring(false);
            }
            
            updateHeight();
        }, this);

        this.entry.PaymentRecurrenceFrequency.subscribe(updateHeight);

        this.cleanUpErrors = function () {
            this.providers.deleteProviderRequest.error('');
        }.bind(this);
        
        this.displayInstructions = function(){
            this.currentStepModel(new instructionsStepModel(this));
            setTimeout(this.updateHeight, 600);
        }.bind(this);

        this.allowToNext = ko.pureComputed(function() {
            return !this.validation.hasErrors() &&
                this.widget.data().AllowToSubmitByScc;
        }, this);

        this.displayTransactionList = ko.pureComputed(function(){
            return this.widget.data().HasLines && this.expense && this.expense.EOBExtra.Transactions().length > 0;
        }, this);
        
        this.displayFxHsaOffset = ko.pureComputed(function(){
            var useProviderWithExternal = this.entry.useProvider() && this.entry.reimbursementMethod() &&
                (this.entry.reimbursementMethod().IsExternalCheck || this.entry.reimbursementMethod().IsExternalDirectDeposit);
            return this.widget.data().FxClaimOffsetAvailable && !useProviderWithExternal;
        }, this);
        
        this.onNext = function(){
            this.cleanUpErrors();
            this.validation.activate();
            if (!this.allowToNext()) {
                updateHeight();
                return;
            }
            
            if(this.entry.useProvider()) {
                this.entry.allowPosOffset(false);
            }
            
            if($.isFunction(params.onNext)){
                params.onNext(this.entry);
            }

            setTimeout(updateHeight, 600);
            $(window).scrollTop(0);
        }.bind(this);
        
        this.onCancel = function(){
            if($.isFunction(params.onCancel))
                params.onCancel();
        }.bind(this);
        
        this.currentStepModel.subscribe(function(value){
            if($.isFunction(params.onViewChanged)){
                if(value == null) params.onViewChanged("MainView");
                else if(value.isInstructionsView) params.onViewChanged("InstructionsView");
                else if(value.isAddProviderView) params.onViewChanged("AddProviderView");
                else if(value.isAddClaimantView) params.onViewChanged("AddClaimantView");
            }
        }, this);

        this.currentStepModel.subscribe(function(val){
            $.cdh.helper.titleStepModelHandler(val, this.title);
        }, this);
        
        this.isMainView = ko.pureComputed(function(){
            return this.currentStepModel() == null;
        }, this);
        
        this.isInstructionsView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isInstructionsView;
        }, this);
        
        this.isAddProviderView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isAddProviderView;
        }, this);
        
        this.isAddClaimantView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isAddClaimantView;
        }, this);
    }
    
    function instructionsStepModel(model){
        this.isInstructionsView = true;
        this.widget = model.widget;
        
        this.onNext = function(){
            model.currentStepModel(null);
            setTimeout(model.updateHeight, 600);
        }.bind(this);
    }

    function addProviderStepModel(model) {
        this.self = this;
        this.widget = model.widget;
        this.isAddProviderView = true;
        this.list = ko.observableArray([]);
        this.title = $.cdh.languageConstant.getConstant('payment.enter-provider-information');
        this.deleteProviderRequest = new $.cdh.post.payments.deleteProvider();
        this.addNewProviderOption = { PayeeId: -1, PayeeName: $.cdh.languageConstant.getConstant('payment.add-new-provider') };

        this.setupList = function(providers) {
            var payees = providers.slice();
            $.each(payees,
                function(i, payee) {
                    if(payee.isDeleting) return;
                
                    payee.isDeleting = ko.observable(false);
                    payee.clickDelete = function(p) {
                        return this.clickDeleteProvider(p);
                    }.bind(this);
                }.bind(this));
            
            payees.splice(0, 0, this.addNewProviderOption);
            
            this.list(payees);
        }.bind(this);

        this.addProviderModel = new $.cdh.models.AddProviderModel(
            function(provider) {
                model.widget.data().Payees.splice(1, 0, provider);
                this.setupList(model.widget.data().Payees);
                
                model.entry.providerChoice(provider);
                model.currentStepModel(null);

                this.addProviderModel.createAutoPopulatedProvider(false);
                model.cleanUpErrors();
            }.bind(this),
            function() {
                this.closeAddProvider();
                if (this.addProviderModel.createAutoPopulatedProvider()) {
                    model.entry.useProvider(false);
                }
            }.bind(this)
        );
        this.addProviderModel.load();

        this.closeAddProvider = function () {
            this.resetNewProviderFields();
            model.currentStepModel(null);
            setTimeout(model.updateHeight, 600);
        }.bind(this);

        this.resetNewProviderFields = function () {
            if (this.addProviderModel.createAutoPopulatedProvider()) {
                this.populateProviderModel(this.widget.data().Details);
                this.addProviderModel.validation.activate();
                this.addProviderModel.payProviderReadonly(!this.addProviderModel.validation.hasErrors());
            } else {
                this.addProviderModel.reset();
            }
        };

        this.populateProviderModel = function (expenseDetails) {
            var data = {
                ProviderName: expenseDetails.ProviderName,
                Addr1: expenseDetails.ProviderAddress1,
                Addr2: expenseDetails.ProviderAddress2,
                City: expenseDetails.ProviderCity,
                State: expenseDetails.ProviderState,
                Zip: expenseDetails.ProviderZip
            };

            this.addProviderModel.reset(data);
        }.bind(this);

        this.afterRender = function () {
            if ($.cdh.isMobile) {
                $.cdh.helper.scrollPageToTop();
            }
        }.bind(this);

        this.payProviderUpdateModel = null;

        this.initializeDefaultProviderInfo = function() {

            var details = model.widget.data().Details;

            var selectedProviderIndex = model.widget.data().SelectedProvider;
            if(selectedProviderIndex >= 0){
                model.entry.providerChoice(model.widget.data().Payees[selectedProviderIndex]);

                if(!details)
                    return;

                if(model.widget.data().IsExpense57){
                    this.addProviderModel.payProviderReadonly(true);
                }

                this.payProviderUpdateModel = {
                    ProviderName: details.ExpenseDetails.ProviderName,
                    Addr1: details.ExpenseDetails.ProviderAddress1,
                    Addr2: details.ExpenseDetails.ProviderAddress2,
                    City: details.ExpenseDetails.ProviderCity,
                    State: details.ExpenseDetails.ProviderState,
                    Zip: details.ExpenseDetails.ProviderZip,
                    Phone: details.ExpenseDetails.ProviderPhone,
                    PayProviderKey: model.entry.providerChoice().PayeeKey,
                    ProviderId: model.entry.providerChoice().PayeeId
                };
            }
            else {
                if(!details)
                    return;

                this.populateProviderModel(details.ExpenseDetails);
                this.addProviderModel.validation.activate();
                var readonly = !this.addProviderModel.validation.hasErrors();
                this.addProviderModel.validation.activated(false);
                this.addProviderModel.payProviderReadonly(readonly);
                this.addProviderModel.createAutoPopulatedProvider(true);
            }
        }.bind(this);

        this.clickDeleteProvider = function (payee) {
            if (!payee)
                return false;

            if (confirm("Are you sure you want to delete provider " + payee.PayeeName + " ?")) {
                payee.isDeleting(true);

                this.deleteProviderRequest.isLoading(false);
                this.deleteProviderRequest.load({ payProviderKey: payee.PayeeKey })
                    .done(function () {
                        var newPayees = $.grep(model.widget.data().Payees,
                            function (p) {
                                return p.PayeeKey != payee.PayeeKey;
                            });
                        model.widget.data().Payees = newPayees;
                        this.setupList(model.widget.data().Payees);

                        if (model.entry.providerChoice().PayeeKey == payee.PayeeKey) {
                            model.entry.providerChoice(null);
                        }

                        payee.isDeleting(false);
                    }.bind(this))
                    .fail(function () {
                        payee.isDeleting(false);
                        model.updateHeight();
                    });
                return false;
            };
        }.bind(this);
    };

    function addClaimantStepModel(model) {
        this.isAddClaimantView = true;
        this.list = ko.observableArray([]);
        this.prevSelected = ko.observable();
        this.selected = ko.observable();

        this.enabled = ko.observable(true);
        this.title = $.cdh.languageConstant.getConstant('field.enter-family-member');
        this.selectListOptionsCaption = $.cdh.languageConstant.getConstant('field.select-claimant');
        this.addNewClaimantOption = { LastName: $.cdh.languageConstant.getConstant('field.add-new-dependent')};
        this.addDependentModel = null;
        this.profileFullModel = new $.cdh.models.ProfileFull(model.updateHeight);

        this.onDependentAdded = function (claimant) {
            if(!claimant)
                return;
            
            var indexOfAddOption = this.list().indexOf(this.addNewClaimantOption);
            if(indexOfAddOption === 0){
                this.list().push(claimant);
            }
            else{
                this.list().splice(indexOfAddOption - 1, 0, claimant);
            }

            model.widget.data().Template.Claimants.push(claimant);
            this.selected(claimant);
        }.bind(this);

        this.setupList = function (dependents) {
            var list = dependents.slice();
            this.list(list);

            if (model.widget.data().AllowToAddDependent) {
                this.list.push(this.addNewClaimantOption);
            }
        }.bind(this);

        this.getDisplayName = function (dependent) {
            var displayName = dependent.LastName;
            if (!displayName) {
                displayName = dependent.FirstName;
            }
            else if (dependent.FirstName) {
                displayName = displayName + ', ' + dependent.FirstName;
            }

            return displayName;
        };

        this.add = function (dependent) {
            this.list.splice(0, 0, dependent);
        }.bind(this);

        this.clear = function() {
            this.list([]);
            this.selected(null);
            this.prevSelected(null);
        }.bind(this);

        this.selected.subscribe(function(value) {
            if (value == this.addNewClaimantOption && model.widget.data().AllowToAddDependent) {
                model.currentStepModel(this.withInitialization());
            }

            if (value != this.addNewClaimantOption)
                this.prevSelected(this.selected());
            
            model.entry.claimant(this.selected());
            
        }.bind(this));
        
        this.withInitialization = function () {

            this.addDependentModel = {
                model: {
                    profile: this.profileFullModel.profile.data(),
                    data: {},
                    details: {}
                },
                isEdit: false,
                onSuccessCallback: this.onDependentAdded,
                displayHeader: false,
                asClaimant: true,
                needFilterByAccountsOnSuccess: true,
                closeFunc: function () {
                    model.currentStepModel(null);
                    this.selected(this.prevSelected());

                    setTimeout(model.updateHeight, 600);
                }.bind(this)
            };

            return this;
        }.bind(this);
    }
    
})(jQuery, ko);