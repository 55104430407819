(function ($) {
    // bankAccount is CDH.ApiServices.Models.BankAccount.BankAccountInfo
    var bankAccountEditPopup = function (bankAccount, onSuccessEdit) {
        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.bankAccount = bankAccount;

        this.editModel = {
            InstitutionName: ko.observable(bankAccount.InstitutionName).extend({ required: true }),
            BankAccountName: ko.observable(bankAccount.BankAccountName).extend({ required: true })
        }

        this.validation = new $.cdh.validation(this.editModel);

        this.editRequest = new $.cdh.post.bankAccounts.edit(bankAccount.BankAccountId);

        this.canSubmit = ko.pureComputed(function () {
            return !this.editRequest.isLoading() && !this.validation.hasErrors();
        }, this);

        this.clickSubmit = function () {
            this.validation.activate();

            if (!this.canSubmit())
                return;

            this.editRequest
                .load(ko.toJS(this.editModel))
                .done(function () {
                    this.close();
                    onSuccessEdit();
                }.bind(this));
        }
    }


    $.extend(true, $, {
        cdh: {
            models: {
                BankAccountsEditPopup: bankAccountEditPopup
            }
        }
    });
})(jQuery);