(function ($) {
    var communicationsSummaryModel = function(updateHeight) {
        let openParam = $.cdh.helper.urlParam('openParam');
        const self = this;
        this.forceAddMobileNumber = ko.observable(openParam === 'addNumber');
        this.forceAddEmail = openParam === 'addEmail';
        
        this.hasPermissionsToUpdateCommunication = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateParticipantCommunication);
        this.settings = new $.cdh.get.communications.getSettings(function (settings) {
            $.each(settings, function (i, setting) {
                setting.DeliveryMethod = ko.observable(setting.DeliveryMethod.toString());
            });
            return settings;
        });

        this.notDeliveryMethod = ko.pureComputed(function () {
            return this.email.data().length === 0 && this.mobileNumbers.data().length === 0;
        }.bind(this));
        
        this.deliveryEmailOptionWarning = ko.pureComputed(function () {
            updateHeight();
            return this.anyEmailDeliverySet() && this.email.data().length == 0 ;
        }, this);

        this.deliveryMobileOptionWarning = ko.pureComputed(function () {
            updateHeight();
            return this.anyMobileDeliverySet() && !this.anyPhoneNumber();
        }, this);

        this.anyPhoneNumber = ko.observable(true);
        this.allowMultiplePhoneNumbers = ko.observable(false);

        this.isMobile = ko.observable(window.innerWidth < 768);
        window.addEventListener('resize', function () {
            self.isMobile(window.innerWidth < 768);
        });

        this.anyEmailDeliverySet = function () {
            if (!this.settings.hasData())
                return false;

            var result = $.grep(this.settings.data(), function(s) {
                if (s.DeliveryMethod() == $.cdh.enums.deliveryMethod.email ||
                    s.DeliveryMethod() == $.cdh.enums.deliveryMethod.both) {
                    return true;
                }
                return false;
            });
            return result.length > 0;
        }.bind(this);

        this.anyMobileDeliverySet = function() {
            if (!this.settings.hasData())
                return false;

            var result = $.grep(this.settings.data(), function(s) {
                if (s.DeliveryMethod() == $.cdh.enums.deliveryMethod.mobile ||
                    s.DeliveryMethod() == $.cdh.enums.deliveryMethod.both) {
                    return true;
                }
                return false;
            });
            return result.length > 0;
        }.bind(this);

        this.hasPermissionsToEditEmail = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateEmailAddress);

        this.mobileNumbersUpdated = function () {
            this.forceAddMobileNumber (false);
            this.mobileNumbers.load();
            this.settings.load();
        }.bind(this);

        this.mobileNumbers = new $.cdh.get.communications.getMobileNumbers(function (numbers) {
            var models = $.map(numbers, function (number) {
                return new $.cdh.models.CommunicationsMobileNumber(number, this.mobileNumbersUpdated);
            }.bind(this));
            this.anyPhoneNumber(models.length > 0);
            return models;
        }.bind(this));

        this.email = new $.cdh.get.communications.getEmail();
        this.getCampaignDetails = new $.cdh.get.communications.getCampaignDetails();

        
        this.pendingLabel = ko.pureComputed(() => { return $.cdh.languageConstant.getConstant("communication.mobile-status-pending-label", { tollFreePhoneNumber: $.cdh.helper.spiltMobileNumber(this.getCampaignDetails.data()?.CampaignPhoneNumber) }) }, this);
        this.registeredLabel = ko.pureComputed(() => { return $.cdh.languageConstant.getConstant("communication.mobile-status-registered-label", { tollFreePhoneNumber: $.cdh.helper.spiltMobileNumber(this.getCampaignDetails.data()?.CampaignPhoneNumber) }) }, this);
        this.optedoutLabel = ko.pureComputed(() => { return $.cdh.languageConstant.getConstant("communication.mobile-status-optedout-label", { tollFreePhoneNumber: $.cdh.helper.spiltMobileNumber(this.getCampaignDetails.data()?.CampaignPhoneNumber) }) }, this);
        this.editRequest = new $.cdh.post.communications.editSettings();

        this.editEmailModel = {};
        this.email.state.subscribe(function () {
            this.editEmailModel = new $.cdh.models.editEmailModel(this.email.data,
                function (newEmail) {
                    this.settings.load();
                    this.email.load();
                }.bind(this));
            updateHeight();
        }.bind(this));

        this.addMobileNumber = ko.observable();

        this.saveClick = function () {
            if (this.editRequest.isLoading())
                return;
            var newSettings = ko.toJS(this.settings.data());
            this.editRequest.load({ settings: newSettings }).done(function () {
                this.settings.load();
                updateHeight();
            }.bind(this)).fail(function () {
                updateHeight();
            }.bind(this));
        }.bind(this);


        this.isEmailMenuOpen = ko.observable(false);

        this.toggleEmailMenu = function() {
            this.isEmailMenuOpen(!this.isEmailMenuOpen());
        }

        this.kayboardHandler = function (data, event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                this.toggleEmailMenu();

                return false;
            }

            return true;
        }

        this.settings.state.subscribe(function() {
            $.cdh.helper.addKeyboardNavForCheckboxesWithHint();
            updateHeight();
        });
        this.mobileNumbers.state.subscribe(function () {
            updateHeight();
        }.bind(this));

        this.initMobileNumber = function () {
            this.settings.load();
            this.getCampaignDetails.load();
            this.mobileNumbers.load();
        }.bind(this);
        
        this.init = function () {            
            this.email.load();
            this.initMobileNumber();
        }.bind(this);

        this.init();
    }

    ko.components.register('communications-summary', {
        template: { element: 'template-communications-summary' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new communicationsSummaryModel(updateHeight);
            }
        }
    });
})(jQuery);
