import template from "./medicine-cabinet-goodbuy-rx.html"

(function ($, ko) {

    ko.components.register('medicine-cabinet-goodbuy-rx', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new GoodbuyRx(params, widgetInfo)
            }
        }
    });

    function GoodbuyRx(params, widgetInfo) {
        const self = this;
        
        self.rxCardId = params.rxCardId;
        self.fullName = params.fullName;
        self.email = params.email;
        
        self.printVisible = params.hasPrescriptions;
        
        self.printRxCard = function() {
            $(".rx-card-print-area").printArea();
        }
        
        widgetInfo.updateHeight();
    }
})(jQuery, ko);