import template from "./registration.html"

(function ($, ko) {
    ko.components.register('registration', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new Registration(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
    
    function Registration(params, updateHeight) {
        const self = this;
        
        let securitySetupParam = $.cdh.helper.urlParam("securitysetup");
        let isSecurityQuestions = $.cdh.user.name && securitySetupParam && securitySetupParam.length > 0;
        
        self.useSmartRegistration = $.cdh.tpa.useUpdatedRegistration && !isSecurityQuestions;
    }
    
})(jQuery, ko);
