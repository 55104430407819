import template from "./account-activity-tax-info.html";

ko.components.register('account-activity-tax-info', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AccountActivityTaxInfo(params, widgetInfo);
        }
    }
});

export class AccountActivityTaxInfo {
   constructor(params, widgetInfo) {
       let model = params.model;

       this.taxYear = model.TaxYear;
       this.preTaxAmount = model.PreTaxAmount;
       this.postTaxAmount = model.PostTaxAmount;
       
       this.valueClass = params.valueClass;
   }
}