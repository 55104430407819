import template from "./account-activity-upload-receipt.html"

(function ($, ko) {
    ko.components.register('account-activity-upload-receipt', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityUploadReceipt(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityUploadReceipt(params, updateHeight) {
        const self = this;
        const urlParams = {
            page: $.cdh.helper.urlParam('page')
        }
        self.force = urlParams.page === 'AddReceipt';
        
        let model = params.model;

        self.isMobile = params.isMobile || false;

        self.popupModel = {
            isClaim: model.IsFxClaim || model.IsTcClaim || model.IsExpense || model.IsMemberClaim,
            ClaimKey: (model.IsMemberClaim || model.IsExpense) ? model.ClaimKey : model.ClaimId,
            TrnKey: {
                Id: model.TransactionKey,
                SettlementDate: model.SettlementDate,
                SeqNumber: model.SeqNumber
            },
            sourceTranDesc: `Account Activity. ItemId: ${model.ItemId}, Type: ${model.Type}`,
            Receipts: model.Receipts,
            ReceiptsCount: ko.observable(model.Receipts().length),
            Status: model.StatusWithAmount,
            SubmittedStatusText: 'Submitted - Under Review',
            successCallback: function () {
                //function triggered when submitting in upload receipt popup
            }
        };
    }

})(jQuery, ko);