import template from "./medicine-cabinet-prescription-drug-search.html"

(function ($, ko) {
    ko.components.register('medicine-cabinet-prescription-drug-search', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new PrescriptionDrugSearch(params, widgetInfo);
            }
        }
    });

    function PrescriptionDrugSearch(params, widgetInfo) {     
        const self = this;
        const storageKey = $.cdh.medicineCabinet.searchKey;        
        self.drugsService = new $.cdh.http.smartAccount.vendors.DrugsService();     

        
        self.drugName = params.drug.DrugName;

        let savedModel = $.cdh.localStorage.getObject(storageKey) || {};
        
        self.model = {
            zipCode: ko.observable(savedModel.zipCode).extend({
                required: true,
                zipCode: true
            }),
            distance: ko.observable(savedModel.distance || 3).extend({
                required: true
            })
        };
        
        self.validation = new $.cdh.validation(self.model);
        
        self.pharmacies = ko.observableArray();
        self.forceHideEmptyMessage = ko.observable(true);

        self.disable = ko.pureComputed(function () {
            return self.drugsService.loading();
        });

        self.onSearch = function () {    
            var searchdrug = $("#searchdrug");
            searchdrug.html("");
            self.validation.activate();
            if(self.validation.hasErrors()) return false;

            self.forceHideEmptyMessage(false);
            
            let drug = params.drug;

            $.cdh.localStorage.setObject(storageKey, {
                zipCode: self.model.zipCode(),
                distance: self.model.distance()
            });
            
            self.drugsService.searchPricingAsync(self.drugName, drug.Ndc, drug.EquivalentNdc, self.model.zipCode(), self.model.distance())
                .then(function (result) {                
                    let data = result.data();               
                    searchdrug.html(data.length.toString() + " " + $.cdh.languageConstant.getConstant('button.results-found'));

                self.pharmacies(data.map(function (item) {
                    return {
                        name: item.Pharmacy.Name,
                        brandPrice: item.BrandPrice,
                        genericPrice: item.GenericPrice,
                        distance: parseFloat(item.Pharmacy.Distance).toPrecision(2),
                        address1: item.Pharmacy.Address1,
                        address2: item.Pharmacy.Address2,
                        zipCode: item.Pharmacy.ZipCode,
                        state: item.Pharmacy.State,
                        city: item.Pharmacy.City,
                        phone: item.Pharmacy.Phone
                    };
                }));

                widgetInfo.updateHeight();
            });
        };

        self.onClose = function () {
            params.closeFunc();
        };
        
        if(params.force) {
            let zipCodeParam = $.cdh.helper.urlParam('zipCode');
            let distanceParam = $.cdh.helper.urlParam('distance');
            self.model.zipCode(zipCodeParam);
            self.model.distance(distanceParam);
            self.onSearch();
        }
    }

})(jQuery, ko);