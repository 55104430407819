import { DataServiceBase } from "../../DataServiceBase";

(function ($, ko) {
    class ProcedureService extends DataServiceBase {
        constructor() {
            super('/SmartAccount/Vendors/FindCare');
        }

        searchAsync(page, pageSize, search) {
            let urlParams = {
                Page: page,
                PageSize: pageSize,
                Search: search,
            }
            return this.getAsync(urlParams, 'Procedure');
        }

    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                vendors: {
                    ProcedureService: ProcedureService
                }
            }
        }
    });
})(jQuery, ko);