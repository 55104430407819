(function($, ko) {

    function waiveModel(updateHeight, template) {

        this.templateExtractor = new $.cdh.services.templateExtractor(template);

        function waiveEnrollmentModel(data) {
            $.extend(this, data);
            this.waiveEnrollment = ko.observable(false);
        }

        this.waive = new $.cdh.get.enrollments.waive(function(data) {
            return new waiveEnrollmentModel(data);
        });

        this.WaiveLabels = {
            lblPlanId: $.cdh.languageConstant.getConstant('field.plan-id'),
            lblPlanDesc: $.cdh.languageConstant.getConstant('field.plan-desc'),
            lblPlanStartDate: $.cdh.languageConstant.getConstant('field.plan-start-date'),
            lblPlanEndDate: $.cdh.languageConstant.getConstant('field.plan-end-date'),
            lblWaiveEnrollment: $.cdh.languageConstant.getConstant('title.waive-enrollment'),
            lblButtonSubmitting: $.cdh.languageConstant.getConstant('button.submitting'),
            lblButtonWaive: $.cdh.languageConstant.getConstant('button.waive'),
            lblButtonCancel: $.cdh.languageConstant.getConstant('button.cancel')
        }

        this.submitWaive = new $.cdh.post.enrollments.submitWaive();
        this.waive.state.subscribe(updateHeight);

        this.enrollmentKey = $.cdh.helper.urlParam("enrollmentKey");

        this.submitWaiveClick = function() {
            this.submitWaive.load({ enrollmentKey: this.enrollmentKey })
                .done(function() {
                    window.location.href = $.cdh.routes.predefined.enrollments.pageList;
                });
        }.bind(this);

        this.waive.load({enrollmentKey: this.enrollmentKey }); 
        
    }

    ko.components.register('enrollment-waive',
    {
        template: { element: 'template-enrollment-waive' },
        viewModel: {
            createViewModel: function(params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new waiveModel(updateHeight, params.template);
            }
        }
    });
})(jQuery, ko);