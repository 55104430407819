import template from "./alerts-count.html";
import {AlertsService} from "../Http/Alerts/AlertsService";

ko.components.register('alerts-count', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AlertsCountModel();
        }
    }
});

export class AlertsCountModel {
    constructor() {
        this.alertsService = new AlertsService();
        this.count = ko.observable();

        ko.postbox.subscribe($.cdh.topics.alerts.countRefresh, () => {
            this.loadCount();
        });

        this.countValue = ko.pureComputed(function () {
            let requestValue = this.count();

            if ($.isNumeric(requestValue))
                return requestValue;

            return 0;
        }, this);

        this.loadCount();
    }

    loadCount() {
        this.alertsService.getCountAsync().then(result => {
            let count = result.data();
            this.count(count);
        });
    }
}