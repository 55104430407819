(function ($, ko) {
    var documentsModel = function (updateHeight) {
        this.documents = new $.cdh.get.resources.documents();

        this.documents.load();
        this.documents.state.subscribe(updateHeight);

        this.afterRender = function () {
            updateHeight();
            $.cdh.helper.setAllVideoIframesRatio();
        };
    };

    ko.components.register('documents', {
        template: { element: 'template-resources-documents' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new documentsModel(updateHeight);
            }
        }
    });
})(jQuery, ko);
