import template from "./claims-list.html"

(function ($, ko) {
    ko.components.register('claims-list', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ClaimsList(widgetInfo.updateHeight);
            }
        }
    });

    function ClaimsList(updateHeight) {
        const self = this;

        const urlParams = {
            claimId: $.cdh.helper.urlParam('claimId'),
            page: $.cdh.helper.urlParam('page'),
            extClaimId: $.cdh.helper.urlParam('extClaimId'),
            claimKey: $.cdh.helper.urlParam('claimKey'),
            expenseKeys: $.cdh.helper.urlParam('expenseKeys'),
            transactionKey: $.cdh.helper.urlParam('transactionKey'),
            settlementDate: $.cdh.helper.urlParam('settlementDate'),
        };

        self.ClaimListLabels = {
            lblAddExpense: $.cdh.languageConstant.getConstant('button.add-expense')
        }
        if(urlParams.expenseKeys) {
            urlParams.expenseKeys = JSON.parse(decodeURIComponent(urlParams.expenseKeys));
        }
        
        self.forceAddReceipt = ko.observable(urlParams.page === 'AddReceipt' && (urlParams.extClaimId || urlParams.claimKey 
            || urlParams.expenseKeys || urlParams.transactionKey || urlParams.settlementDate));

        self.expandItem = urlParams.claimId;
        
        self.accountsSettings = {
            anyHsaOnly: ko.observable(false),
            anyNonHsaOnly: ko.observable(false),
            isDirectRequest: ko.pureComputed(function () {
                return self.accountsSettings.anyHsaOnly() || self.accountsSettings.anyNonHsaOnly();
            })
        };

        self.currentClaims = ko.observableArray();

        self.addExpenseAllowed = ko.observable($.cdh.brandData.DisplayAddExpense);
        self.addExpenseUrl = ko.pureComputed(function () {
            if (self.isLoaded())
                return $.cdh.tpa.useExpensePortal && $.cdh.employee.submitClaimAllowed() ? $.cdh.routes.predefined.claims.portal : $.cdh.routes.predefined.expenses.add;
            return "#";
        });

        self.claimSubmitText = ko.observable($.cdh.languageConstant.getConstant('button.submit-claim'));
        self.claimSubmitUrl = ko.pureComputed(function () {
            if (self.isLoaded())
                return $.cdh.routes.predefined.claims.submit;
            return "#";
        });


        self.accountsRequest = $.cdh.accounts.initAccounts(function() {
            let anyHsaCurrent = $.cdh.accounts.anyHsaCurrent();
            let anyNonHsaCurrent = $.cdh.accounts.anyNonHsaCurrent();
            let anyManualClaimsEnableCurrent = $.cdh.accounts.anyManualClaimsEnabled();

            if (anyHsaCurrent && !anyManualClaimsEnableCurrent) {
                self.claimSubmitText($.cdh.languageConstant.getConstant('button.hsa-bill-pay'));
            }

            if (anyHsaCurrent && !anyNonHsaCurrent) {
                self.accountsSettings.anyHsaOnly(true);
            }

            if (!anyHsaCurrent && anyNonHsaCurrent) {
                self.accountsSettings.anyNonHsaOnly(true);
            }

            self.rebuildClaims();
        });

        self.paginatorModel = {
            pageNo: ko.observable(),
            totalPages: ko.observable(),
            onChanged: function (currentPage) {
               self.refresh(currentPage);
            }
        };
        
        self.statusFilterModel = {
            enable: ko.pureComputed(function () {
                return self.isLoaded()
            }),
            current: ko.observable({
                actionNeeded: true,
                accountHistory: true,
                denied: false
            }),
            onSearch: function (data) {
                self.statusFilterModel.current(data);
                self.refresh();
            }
        };

        self.isActionNeeded = ko.pureComputed(function () {
            return self.statusFilterModel.current().actionNeeded;
        });
        self.isApproved = ko.observable(function () {
            return self.statusFilterModel.current().accountHistory;
        });
        self.isDenied = ko.observable(function () {
            return self.statusFilterModel.current().denied;
        });
        
        self.advancedFilterModel = {
            enable: ko.pureComputed(function () {
                return self.isLoaded()
            }),
            current: urlParams.claimId ? { trackingNumber: urlParams.claimId } : null,
            onToggle: function () {},
            setDates: function (from, to) {},
            onSearch: function (data) {
                self.advancedFilterModel.current = data;
                self.refresh();
            }
        };

        self.refreshArgs = function() {
            let requestArgs = {
                pageNo: self.paginatorModel.pageNo(),
                pageSize: 10,
            };

            $.extend(requestArgs, self.statusFilterModel.current());

            if(self.forceAddReceipt()) {
                if(urlParams.claimKey) {
                    requestArgs.claimKey = urlParams.claimKey
                }
                if (urlParams.extClaimId) {
                    requestArgs.claimId = urlParams.extClaimId;
                }
                if(urlParams.expenseKeys) {
                    requestArgs.expenseKeys = urlParams.expenseKeys
                }
                if(urlParams.transactionKey) {
                    requestArgs.transactionKey = urlParams.transactionKey
                }
                if(urlParams.settlementDate) {
                    requestArgs.settlementDate = urlParams.settlementDate
                }
            } else {
                if (self.advancedFilterModel.current) {
                    $.extend(requestArgs, self.advancedFilterModel.current);
                }
            }

            return requestArgs;
        };

        let removeUrlParams = false;

        self.refresh = function (currentPage) {
            self.paginatorModel.pageNo(currentPage || 1);
            
            if(self.currentClaims().length > 0) {
                $.cdh.helper.deleteUrlParam('claimId');
            }

            if(removeUrlParams) {
                $.cdh.helper.deleteUrlParam('page');
                $.cdh.helper.deleteUrlParam('claimKey');
                $.cdh.helper.deleteUrlParam('extClaimId');
                $.cdh.helper.deleteUrlParam('transactionKey');
                $.cdh.helper.deleteUrlParam('settlementDate');
                $.cdh.helper.deleteUrlParam('expenseKeys');
                $.cdh.helper.deleteUrlParam('typeCode');
                removeUrlParams = false;
            }

            self.claims.load(self.refreshArgs())
                .done(function() {
                    self.rebuildClaims();
                })
                .fail(function (e) {
                    self.currentClaims.removeAll();
                    self.paginatorModel.totalPages(0);
                });
        };

        self.claims = new $.cdh.get.claims.getPage();
        
        self.rebuildClaims = function () {
            if (self.isLoading())
                return;

            let claimsResponse = self.claims.data();
            let claimsData = claimsResponse.Claims;

            if (claimsData.length === 1 && claimsData[0].TrackingNumber && !self.forceAddReceipt()) {
                self.expandItem = true;
                $.cdh.helper.setUrlParam('claimId', claimsData[0].TrackingNumber);
            } else {
                self.expandItem = false;
                $.cdh.helper.deleteUrlParam('claimId');
            }
            
            let claims = claimsData.map(function (claim) {
                claim.Status = ko.observable(claim.Status);
                claim.Actionable = ko.observable(claim.Actionable);

                return claim;
            });
            
            if (urlParams.claimId && claims.length === 1 && !self.forceAddReceipt()) {
                let singleClaim = claims[0];
                if (singleClaim.ServiceStartDate) {
                    let year = moment(singleClaim.ServiceStartDate).year();
                    let format = 'MMM D, YYYY';
                    let from = moment([year, 0, 1]).format(format);
                    let to = moment([year, 11, 31]).format(format);
                    self.advancedFilterModel.setDates(from, to);
                }
            }

            self.currentClaims.removeAll();
            self.currentClaims.pushAll(claims);

            self.paginatorModel.totalPages(claimsResponse.TotalPages);

            if (self.forceAddReceipt()) {
                self.forceAddReceipt(false);
                removeUrlParams = true;
            }

            updateHeight();
        };

        self.empty = ko.pureComputed(function () {
            return self.currentClaims().length === 0 ;
        });

        self.isLoading = ko.pureComputed(function () {
            return self.claims.isLoading() || self.accountsRequest.isLoading();
        });

        self.isLoaded = ko.pureComputed(function () {
            return !self.claims.isLoading() && self.claims.error().length == 0;
        });

        self.loadingFailed = ko.pureComputed(function () {
            return !self.claims.isLoading() && self.claims.error().length > 0;
        });

        self.emptyFilteredActionClaims = ko.pureComputed(function () {
            return self.filteredActionClaims().length == 0;
        });

        self.emptyFilteredHistoryClaims = ko.pureComputed(function () {
            return self.filteredClaims().length == 0;
        });

        self.emptyFilteredDeniedClaims = ko.pureComputed(function () {
            return self.filteredDeniedClaims().length == 0;
        });

        self.filteredActionClaims = ko.pureComputed(function () {
            return self.currentClaims().filter(function (c) {
                return c.Actionable();
            });
        });

        self.filteredClaims = ko.pureComputed(function () {
            return self.currentClaims().filter(function (c) {
                return !c.Actionable() && !c.Status().startsWith("Denied");
            });
        });

        self.filteredDeniedClaims = ko.pureComputed(function () {
            return self.currentClaims().filter(function (c) {
                return !c.Actionable() && c.Status().startsWith("Denied");
            });
        });

        self.clickExport = function () {
            if (!self.isLoaded())
                return;
            
            let args = self.refreshArgs();
            args.pageNo = null;
            args.pageSize = null;

            window.location.href = $.cdh.routes.claims.export(args);             
        };

        self.onSearch = function () {
            self.refresh();
        };

        self.refresh();
    }
})(jQuery, ko);