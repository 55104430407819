import template from "./account-activity-fx-claim-info.html"

(function ($, ko) {
    ko.components.register('account-activity-fx-claim-info', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityFxClaimInfo(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityFxClaimInfo(params, updateHeight) {
        const self = this;
        let model = params.model;
        let isValueSet = $.cdh.helper.isValueSet;

        self.model = model;
        self.transactionDate = model.SettlementDate;
        self.description = model.Description;
        self.claimant = model.Claimant;
        self.serviceCode = model.ServiceCategoryCode;
        self.serviceDescription = model.ServiceCategoryName;
        self.trackingNumber = model.TrackingNumber;
        self.claimId = model.ClaimId;
        self.serviceStartDate = model.ServiceStartDate;
        self.denialReason = model.DenialReason;
        self.excludedReason = model.ExcludedReason;
        self.pendedReason = model.PendedReason;
        if (model.ReimbursementDetails && model.ReimbursementDetails.length > 0) {
            let reimbursementDetail = model.ReimbursementDetails[0];
            self.paymentId = reimbursementDetail.PaymentId;
        } else {
            self.paymentId = null;
        }
        
        self.isReason = isValueSet(self.denialReason) || isValueSet(self.excludedReason) || isValueSet(self.pendedReason);
    }

})(jQuery, ko);