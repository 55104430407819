(function(module, $) {

    var getAccount = function (accounts, id) {
        var found = $.grep(accounts, function (account) { return account.AccountId === id; });

        return found.length > 0
            ? found[0]
            : null;
    }

    var loadSettersForSelectInternal = function (accountId, url, callback) {
        $.cdh.accounts.initAccounts(function(data) {
            var accounts = $.grep(data,
                function(a) {
                    if (a.AccountType === $.cdh.enums.accountType.hsa) {
                        return true;
                    }
                    return false;
                });

            var account = getAccount(accounts, parseInt(accountId));
            
            var setters = $.map(accounts,
                function(a) {
                    return {
                        text: a.AccountName,
                        setter: function() { window.location.href = url + "?accountId=" + a.AccountId; }
                    }
                });

            module.postbox.notifySubscribers(account, module.topics.accountLoaded);

            callback({
                account: account,
                accounts: setters
            });
        });
    };

    module.accountsService = {
        loadSettersForSelect: loadSettersForSelectInternal
    };

})(window.docDelivery, window.jQuery);