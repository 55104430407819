import template from "./popup-dropdown.html"


ko.components.register('popup-dropdown', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new PopupDropdown(params, updateContainerHeight(componentInfo.element));
        }
    }
});

ko.bindingHandlers.numeric = {
    init: function (element, valueAccessor) {
        $(element).on("keydown", function (event) {
            
            if (event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 || event.keyCode == 13 ||               
                (event.keyCode == 65 && event.ctrlKey === true) ||                
                 (event.keyCode >= 35 && event.keyCode <= 39)) {                
                return;
            }
            else {
            if (event.shiftKey || (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
                    event.preventDefault();
                }
            }
        });
    }
};

function PopupDropdown(params, updateHeight) {


    this.careTypes = ko.observable(["Primary care provider", "Urgent Care Facility"])
    this.distanceOptions = ko.observable([5, 10, 15, 20, 25, 50, 75, 100])

    this.typeOfcare = ko.observable();
    this.zipCode = ko.observable(params.model.zipCode()).extend({ required: true, zipCode: true });
    this.zipCodeValidation = new $.cdh.validation(this.zipCode);
    this.distance = ko.observable(params.model.distance());

    this.clickSubmit = function () {

        this.zipCodeValidation.activate();
        if (!this.zipCodeValidation.hasErrors()) {
            params.model.typeOfCare(this.typeOfcare);
            params.model.zipCode(this.zipCode());
            params.model.distance(this.distance());
            $.cdh.helper.setUrlParam('zipcode', this.zipCode());
            $.cdh.helper.setUrlParam('distance', this.distance());
            this.storeLocationData({
                zipcode: this.zipCode(),
                distance: this.distance()
            });
           
            if (params.type == 'ProviderListModel' || params.type =='UrgentCareListModel') {
                
                params.model.loadData(1);
            }
            params.closeFunc();
        }
    };
    this.storeLocationData = function (location) {
        localStorage.setItem('locationInfo', JSON.stringify(location));
    }
    this.clickCancel = function () {
        params.closeFunc();
    };
}