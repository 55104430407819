import {DataServiceBase} from "../DataServiceBase";

export class PaymentsService extends DataServiceBase{
    constructor() {
        super('/Payments');
    }
    
    getPaymentsAsync(statusFilter){
        return this.getAsync({ statusFilter: statusFilter }, 'GetPayments');
    }
    
    getPageAsync(filter, pageParams) {
        return this.getAsync({ PageParams: pageParams, Filter: filter }, 'GetPage');
    }
    
    cancelPaymentAsync(payment) {
        return this.postAsync({ taskScheduleKey: payment.TaskScheduleKey } , 'CancelPayment');
    }

    getFutureRecurringPaymentsAsync(paymentsType){
        return this.getAsync({ paymentsType: paymentsType }, 'GetFutureRecurringPayments');
    }
}