(function (module) {

    var Item = function(title, downloadUrl) {
        this.title = title;
        this.downloadUrl = downloadUrl;
    }

    var addItemToGrid = function (accountId, gridItems, pageIndex, item, options, toLeft) {
        var transformed = options.transformItem(item);

        var adapted = new Item(transformed.name,
            options.downloadUrl + "/" + accountId + "?docKey=" + transformed.key + "&name=" + transformed.name
        );

        var items = gridItems[pageIndex];
        if (!items)
            items = { left: [], right: [] };
        if (toLeft) {
            items.left.push(adapted);
        } else {
            items.right.push(adapted);
        }

        gridItems[pageIndex] = items;
    };

    var adaptItems = function (accountId, data, options) {
        var gridItems = [];

        for (var i = 0; i < data.length; i++) {
            var item = data[i];
            var pageIndex = Math.floor(i / (10 * 2));

            addItemToGrid(accountId, gridItems, pageIndex, item, options, i % 2 === 0);
        }

        return gridItems;
    };

    module.gridDataAdapter = {
        invoke: adaptItems
    };

})(window.docDelivery);