import template from "./dependent-editor-dependent-details.html"

(function ($, ko) {
    ko.components.register('dependent-editor-dependent-details', {
        template: template,
        viewModel: {
            createViewModel: function (params) {
                return new DependentDetails(params);
            }
        }
    });
    
    const utils = {
        fillObservable: function (observable, object) {
            const keys = Object.keys(observable);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (typeof observable[key] === "function") {
                    observable[key](object[key]);
                }
            }
        },
        fillObject: function (observable, object) {
            const keys = Object.keys(observable);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if (typeof observable[key] === "function") {
                    object[key] = observable[key]();
                }
            }
        }
    };
    
    function DependentDetails(params) {
        const self = this;

        const isEdit = params.model.isEdit;
        const profile = params.model.profile;
        const dependent = params.model.dependent;
        const dependentOrigin = params.model.dependentOrigin;
        
        this.enums = params.model.enums;
        this.disableFields = ko.observable(isEdit);
        
        this.canLinkToAccounts = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionAllowParticipantLinkDependentAccount);
        this.hasPermissionsToEditDemographic = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateProfileDemographicInformation);
        this.requireDependentDOBSSN = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionAllowParticipantRequireDependentDOBSSN);
        
        this.model = {
            DateOfBirth: ko.observable().extend({ required: this.requireDependentDOBSSN }),
            Ssn: ko.observable().extend({ required: this.requireDependentDOBSSN, ssn: !isEdit }),
            Phone: ko.observable().extend({ phoneUS: true }),
            Address1: ko.observable().extend({ required: true, specialAddress: true }),
            Address2: ko.observable().extend({ specialAddress: true }),
            City: ko.observable().extend({ required: true, extendedSymbolSet: true }),
            State: ko.observable().extend({ required: true }),
            Zip: ko.observable().extend({ required: true, zipCode: true }),
            Country: ko.observable().extend({ required: true }),
            Initial: ko.observable(),
            Gender: ko.observable().extend({ required: true }),
            FullTimeStudent: ko.observable().extend({ required: false }),
            FirstName: ko.observable().extend({ required: true, dependentName: true }),
            LastName: ko.observable().extend({ required: true, dependentName: true }),
            EmployeeRelation: ko.observable().extend({ required: false }),
            DependentId: ko.observable()
        };
        
        if (isEdit){
            this.model.Initial.extend({ alphanumeric: true});
        }else{
            this.model.Initial.extend({  required: false, alphanumeric: true, maxLength: 1 });
        }

        utils.fillObservable(this.model, dependent);
        
        this.usePrimaryAddress = ko.observable(isPrimaryAddress());
        this.usePrimaryAddress.subscribe(function (data) {
            if(data){
                setAddressFrom(profile);
            }else{
                setAddressFrom(dependentOrigin);
            }
        });
        
        this.isSsnChecking = params.model.isSsnChecking;
        
        this.validation = new $.cdh.validation(this.model);

        this.ssnView = ko.observable(this.model.Ssn());
        
        this.maskSsn = function () {
            self.model.Ssn(self.ssnView());
            if (self.ssnView()) {
                const maskedSsn = self.ssnView().replace(/[0-9]/g, "*");
                self.ssnView(maskedSsn);
            }
        };

        this.unMaskSsn = function () {
            self.ssnView(self.model.Ssn());
        };

        this.deleteOptionUnknown = function (option, item) {
            if (!item || !option)
                return;

            if (item.key.toLowerCase() === $.cdh.enums.employeeRelation.unknown.toString()) {
                if (!self.model || self.model.EmployeeRelation() !== $.cdh.enums.employeeRelation.unknown) {
                    $(option).remove()
                }
            }
            if (item.key.toLowerCase() === $.cdh.enums.employeeRelation.estate.toString()) {
                $(option).remove(); 
            }
        };
        
        function setAddressFrom(obj) {
            self.model.Address1(obj.Address1);
            self.model.Address2(obj.Address2);
            self.model.City(obj.City);
            self.model.State(obj.State);
            self.model.Zip(obj.Zip);
            self.model.Country(obj.Country);
        }
        
        function isPrimaryAddress() {
            return self.model.Address1() === profile.Address1 &&
                self.model.Address2() === profile.Address2 &&
                self.model.City() === profile.City &&
                self.model.State() === profile.State &&
                self.model.Zip() === profile.Zip &&
                self.model.Country() === profile.Country;
        }
        
        params.model.onNext = function () {
            self.validation.activate();
            if(self.validation.hasErrors()) return false;
            
            utils.fillObject(self.model, dependent);
            return true;
        };
    }
})(jQuery, ko);