(function ($, ko) {
    var registrationModel = function (updateHeight) {
        var employerIdParam = $.cdh.helper.urlParam('employerId');
        var securityUpdateParam = $.cdh.helper.urlParam("securitysetup");
        var skipIntroduction = $.cdh.helper.urlParam("skipintroduction");
        var smartSteps = $.cdh.helper.urlParam("smartSteps");
        var userName = $.cdh.user.name;
        var defaultRegistrationId = $.cdh.tpa.defaultRegistrationId;
        this.securitysetup = ko.observable(userName && securityUpdateParam && securityUpdateParam.length > 0);
        this.skipSecurityIntroduction = ko.observable(skipIntroduction && skipIntroduction.length > 0);
        this.smartSteps = $.cdh.tpa.useUpdatedRegistration && smartSteps && smartSteps.length > 0;

        this.userInfoRequest = new $.cdh.get.registration.userInfo();
        this.employerListRequest = new $.cdh.get.registration.employerList();
        this.employeeIdNotesRequest = new $.cdh.get.registration.employeeIdNotes();
        this.widget = new $.cdh.get.registration.registrationWidgetData();
        this.widget.load();
        
        this.widget.data.subscribe(function(data){
            this.UserNameValidationMessage = $.cdh.languageConstant.getConstant('messages.username-must-between', { '0': data.MinUserIdLength, '1': data.MaxUserIdLength });
            var message = `Should be between ${data.MinUserIdLength} and ${data.MaxUserIdLength} chars`; 
            this.userData().userName.extend({ required: true, betweenLengthString: { params: [data.MinUserIdLength, data.MaxUserIdLength], message }, alphanumeric: { params: true, message: 'should be alphanumeric' } });
            updateHeight();
        }, this);
        
        this.employeeIdNotes = ko.observable(null);
        this.employeeIdLabel = ko.pureComputed(function () {
            if (!this.isCardNumberOption()) {
                var notes = this.employeeIdNotes();
                if (notes && notes.EmployeeIDLabel)
                    return notes.EmployeeIDLabel + " <em>*</em>";                
            }
            return $.cdh.languageConstant.getConstant("profile.employee-id") + " <em>*</em>";
        }, this);

        this.employeeIdHelpText = ko.pureComputed(function () {
            if (!this.isCardNumberOption()) {
                var notes = this.employeeIdNotes();
                if (notes && notes.EmployeeIDHelpText) {
                    var employeeIDHelpTextCorrected = notes.EmployeeIDHelpText.replace(/(?:\r\n|\r|\n)/g, '<br>');
                    return employeeIDHelpTextCorrected;
                }
            }
            return $.cdh.languageConstant.getConstant("registration.register-site");
        }, this);
        
        this.onAfterRender = function(){
            if(this.widget.data().AllowSelectEmployerByName) {
                var settings = {
                    load: function (query, callback) {
                        var name = this.userData().userName || userName;
                        if(this.employerListRequest.isLoading()) {
                            callback();
                            return;
                        }
                        
                        this.employerListRequest.load({userName: name, searchText: query})
                            .done(function (result) {
                                callback(result);
                            }.bind(this));
                        updateHeight();
                    }.bind(this),
                    getOptionsFromResponse: function(data) {
                        if (!data) {
                            return [];
                        }

                        var options = [];
                        for (var i = 0, len = data.length; i < len; i++) {
                            var item = data[i];
                            options.push({
                                'value': item.Id,
                                'text': item.Text
                            });
                        }
                        return options;
                    },
                    onChange: function (id, updateValueForErrorMessage) {

                        // update employerId after first field blur to show field error message
                        if (updateValueForErrorMessage && id === '') {
                            this.userData().employerId('ThisStringWasAddedForFirstDataUpdateToShowErrorMessage');
                        }

                        this.userData().employerId(id);
                        
                        var name = this.userData().userName || userName;
                        if(this.employeeIdNotesRequest.isLoading())
                            return;
                        
                        this.employeeIdNotes(null);

                        if (!id) {
                            return;
                        }

                        this.employeeIdNotesRequest.load({userName: name, employerId: id})
                            .done(function (result) {
                                this.employeeIdNotes(result);
                            }.bind(this));
                        updateHeight();
                    }.bind(this)
                };

                autocompleteFieldInit('.employer-name-select', settings);
            }
        }.bind(this);

        this.currentTabId = 0;
        this.templateName = ko.observable('template-registration-step1');
        this.h3_title = ko.observable('');
        this.currentStep = ko.observable(1);
        
        if(smartSteps) {
            this.steps = ko.observableArray([
                {name: "Step 1", className: "step-1", isActive: ko.observable(false), index: 0},
                {name: "Step 2", className: "step-2", isActive: ko.observable(false), index: 0},
                {name: "Step 3", className: "step-3", isActive: ko.observable(false), index: 0},
                {name: "Step 4", className: "step-4", isActive: ko.observable(true), index: 2},
                {name: "Step 5", className: "step-5", isActive: ko.observable(false), index: 3},
                {name: "Step 6", className: "step-6", isActive: ko.observable(false), index: 4}
            ]);
        } else {
            this.steps = ko.observableArray([
                {name: "Step 1", className: "step-1", isActive: ko.observable(true), index: 1},
                {name: "Step 2", className: "step-2", isActive: ko.observable(false), index: 2},
                {name: "Step 3", className: "step-3", isActive: ko.observable(false), index: 3},
                {name: "Step 4", className: "step-4", isActive: ko.observable(false), index: 4}
            ]);
        }
        

        //Create user
        this.createUserRequest = new $.cdh.post.registration.create();

        this.setupQuestionsStep = function (userName) {
            if (!this.questionsLoader.isLoading()) {
                this.questionsLoader.load({ userName: userName });
            }
            this.data.extend(ko.utils.extend(this.data(), {
                questions: this.questionsLoader,
                continueSetup: this.continueSetup,
                showResultSetup: this.showResultSetup,
                submitSetup: this.submitSetup,
                goToQuestion: this.goToQuestion,
                goLogin: this.goLogin,
                aggregatedLoadStatus: this.aggregatedLoadStatus
            }));
            this.goToQuestion();
            this.h3_title($.cdh.languageConstant.getConstant('title.register-secure-authentication'));
        }.bind(this);

        this.refreshPage = function () {
            window.location.search += '?securitysetup=true&skipintroduction=true';
        }.bind(this);
        
        this.createUser = function () {
            if (this.createUserRequest.isLoading())
                return;
            this.validationUser.activate();
            
            if (!this.validationUser.hasErrors()) {
                let userData = this.userData();
                let requestModel = {
                    userName: userData.userName(),
                    password: userData.password(),
                    repeatPassword: userData.repeatPassword(),
                    firstName: userData.firstName(),
                    initial: userData.initial(),
                    lastName: userData.lastName(),
                    email: userData.email(),
                    employeeId: userData.employeeId()
                };
                
                if(this.isEmployerNameOption() || this.isEmployerIdOption()) {
                    requestModel.employerId = userData.employerId();
                }
                
                if(this.isCardNumberOption()) {
                    requestModel.cardNumber = userData.cardNumber();
                }
                
                this.createUserRequest.load(requestModel).done(function (result) {
                    if (result.Authenticated) {
                        this.refreshPage();
                    } else {
                        this.setupQuestionsStep(this.userData().userName());
                    }
                }.bind(this));
            }
        }.bind(this);

        this.addRequiredValidation = function (field) {
            return ko.observable(ko.unwrap(field)).extend({ required: true });
        };
        
        this.registerOption = ko.observable();
        
        this.isEmployerName = function (value) {
            var employerName = ["Employer Name", "Nom de l'employeur", "Nombre del empleador"];
            return employerName.includes(value);
        };

        this.isEmployerId = function (value) {
            var employerID = ["Employer ID", "ID employeur", "Identificación del empleador"];
            return employerID.includes(value);
        };
        
        this.isCardNumber = function (value) {
            var cardNumber = ["Card Number", "Numéro de carte", "Número de tarjeta"];
            return cardNumber.includes(value);
        };
        
        this.isEmployerNameOption = ko.pureComputed(function(){
            return this.isEmployerName(this.registerOption());
        }, this);
        
        this.isEmployerIdOption = ko.pureComputed(function(){
            return this.isEmployerId(this.registerOption());
        }, this);
        
        this.isCardNumberOption = ko.pureComputed(function(){
            return this.isCardNumber(this.registerOption());
        }, this);
        
        this.registrationOptions = ko.pureComputed(function(){
            var result = [];
            
            if(this.widget.data().AllowSelectEmployerByName)
                result.push($.cdh.languageConstant.getConstant('registration.employer-name'));
            else
                result.push($.cdh.languageConstant.getConstant('registration.employer-id'));
            
            result.push($.cdh.languageConstant.getConstant('registration.card-number'));
            return result;
        }, this);

        this.isRegistrationFieldRequired = ko.pureComputed(function(){
            if(!this.widget.data().AllowSelectEmployerByName)
                return true;

            var isCardSelected = parseInt(this.userData().registrationMethod[0]);
            if(isCardSelected)
                return true;

            return !this.userData().employerId();
        }, this);

        this.userData = ko.observable({
            userName: ko.observable(''),
            password: ko.observable(''),
            repeatPassword: ko.observable(''),
            passwordStrength: ko.observable(0),
            firstName: this.addRequiredValidation(''),
            initial: ko.observable(''),
            lastName: this.addRequiredValidation(''),
            email: ko.observable('').extend({ required: true, email: true }),
            employeeId: ko.observable('').extend({
                required: {
                    onlyIf: function () {
                        return (this.employeeIdNotes() != null && !this.employerListRequest.isLoading()) ||
                            (!this.widget.data().AllowSelectEmployerByName || this.isCardNumberOption());
                    }.bind(this)
                }
            }),
            cardNumber: ko.observable(''),           
            employerId: ko.observable('').extend({
                required: {
                    onlyIf: function () {
                        return this.isEmployerNameOption() || this.isEmployerIdOption();
                    }.bind(this)
                }
            }),
            registrationId: ko.observable(ko.unwrap(employerIdParam)).extend({
                required: {
                    onlyIf: function(){
                        return this.isRegistrationFieldRequired();
                    }.bind(this)
                }
            }),
            agreeWithTerms: ko.observable(false),
            registrationMethod: ["0"],
            mobileNumber: ko.observable('')
        }).bind(this);

        const formatPhoneNumber = function (value) {
            if (!value) return '';
            let digits = value.replace(/\D/g, ''); // Remove non-digit characters
            if (digits.length >= 10) {
                return "(" + digits.substring(0, 3) + ") " + digits.substring(3, 6) + "-" + digits.substring(6, 10);
            }
            return digits;
        };

        this.mobileNumberExists = ko.computed(() => {
            return this.userData().mobileNumber() && this.userData().mobileNumber().trim() !== '';
        });

        this.userData().password.extend({passwordComplexity: { userName: this.userData().userName }});
        
        this.userData().password.extend({ notify: 'always' }).subscribe(function (newValue) {
            // var result = 0;
            // if (/[a-z]/.test(newValue)) { result += 25; }
            // if (/[A-Z]/.test(newValue)) { result += 25; }
            // if (/\d/.test(newValue)) { result += 25; }
            // if (/[^a-zA-Z0-9 ]+/.test(newValue)) { result += 25; }
            function checkPassStrength(password){
                if (!password) {
                    return 0;
                }
                var strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
                var mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

                if(strongPassword.test(password)) {
                    return 100;
                } else if(mediumPassword.test(password)){
                    return 66.66;
                } else{
                    return 33.33;
                }
            }
            var result = checkPassStrength(newValue);
            this.userData().passwordStrength(result);
        }.bind(this));

        this.userData().repeatPassword.extend({
            equal: { params: this.userData().password, message: $.cdh.languageConstant.getConstant('messages.passwords-not-match') }
        });
        
        this.passRegistrationId = function(registerOption, value) {
            if (this.isCardNumber(registerOption)) {
                this.userData().cardNumber(value);
            } else if (this.isEmployerId(registerOption)) {
                this.userData().employerId(value);
            }
        }.bind(this);

        this.registrationIdLbl;

        this.registrationIdChange = function (data,event) {
            if (this.widget.data().AllowSelectEmployerByName) {
                if (event.target.value == $.cdh.languageConstant.getConstant("registration.employer-name")) {
                    $('#employername').text(this.registrationIdLbl).attr('data-i18n-key', 'registration.employer-name');
                }
                else {
                    $('#registrationemployerid').text(this.registrationIdLbl).attr('data-i18n-key', 'registration.card-number');
                }
            }

            else {
                if (event.target.value == $.cdh.languageConstant.getConstant("registration.employer-id")) {
                    $('#registrationemployerid').text(this.registrationIdLbl).attr('data-i18n-key', 'registration.employer-id');
                }
                else {
                    $('#registrationemployerid').text(this.registrationIdLbl).attr('data-i18n-key', 'registration.card-number');
                }
            }
        }.bind(this);

        this.userData().registrationId.subscribe(function(value) { 
            this.passRegistrationId(this.registerOption(), value);
        }, this);

        this.registerOption.subscribe(function (value) {
            let registrationId = this.userData().registrationId();
            this.passRegistrationId(value, registrationId);
        }, this);

        this.termsOfUsePopup = new $.cdh.models.TermsOfUsePopup(
            this.userData().agreeWithTerms.bind(this.userData().agreeWithTerms, true));

        this.goLogin = function () {
            window.location.href = $.cdh.routes.base();
        };
        
        this.employeeIdView = ko.observable('');
        
        this.maskSsn = function () {
            this.userData().employeeId(this.employeeIdView());
            if(this.employeeIdView()){
                var maskedSsn = this.employeeIdView().replace(/\w(?=\w{4})/g, "*");
                this.employeeIdView(maskedSsn);
            }
        }.bind(this);

        this.unMaskSsn = function () {
            this.employeeIdView(this.userData().employeeId());
        }.bind(this);
        
        this.validationUser = new $.cdh.validation(this.userData());
        this.userData().createUser = this.createUser;
        this.data = ko.observable({});
        this.data(this.userData());

        this.confirmEmail = ko.observable('').extend({required: true, email: true});
        this.confirmEmailValidation = new $.cdh.validation(this.confirmEmail);

        this.beginSetup = function () {
            this.setupQuestionsStep(userName);

            var loadUserInfoRequest = function () {
                this.userInfoRequest.load({ userName: userName }).done(function (result) {
                    this.userData().firstName = ko.observable(result.FirstName);
                    this.userData().lastName = ko.observable(result.LastName);
                    this.userData().userName = ko.observable(userName);
                    this.userData().email = ko.observable(result.Email);
                    this.userData().mobileNumber(formatPhoneNumber(result.MobileNum));
                    this.confirmEmail(result.Email);
                }.bind(this));
            }.bind(this);

            if (this.questionsLoader.isLoading()) {
                this.questionsLoader.data.subscribe(function() {
                    loadUserInfoRequest();
                });
            } else {
                loadUserInfoRequest();
            }

        }.bind(this);
        
        this.goToQuestion = function () {
            this.goToStep(2);
        }.bind(this);

        this.questionsLoader = new $.cdh.get.registration.questions(function (data) {
            var result = [];
            if (data.length > 0) {
                for (var i = 0; i < 4; i++) {
                    result.push({
                        index: (i+1),
                        questions: ko.observableArray(data),
                        selectedQuestion: ko.observable(data[i].QuestionId),
                        UserAnswer: this.addRequiredValidation(''),
                        questionsChanged: this.questionsChanged
                    });
                }
            }
            setTimeout(function() {
                var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                if (!(isSafari && $.cdh.isMobile)) {
                    return;
                }

                var questionSelects = $('select.question-select');
                var optgroup = $('<optgroup>');
                optgroup.attr('label', '');
                questionSelects.append(optgroup);
                questionSelects[0].selectedIndex = 0;
            }, 200);
            return result;
        }.bind(this));        

        this.questionsChanged = function (scope, event) {
        }.bind(this);

        this.validateAnswers = ko.computed(function() {
            var result = true;
            ko.utils.arrayForEach(this.questionsLoader.data(), function (question, index) {
                ko.utils.arrayForEach(this.questionsLoader.data(), function (innerQuestion, index2) {
                    if (innerQuestion.index != question.index && innerQuestion.selectedQuestion() == question.selectedQuestion())
                        result = false;
                }.bind(this));
            }.bind(this));
            return result;
        }.bind(this)).extend({
            required: true,
            equal: {
                params: true,
                message: $.cdh.languageConstant.getConstant("registration.not-choose-same-question")
            }
        });

        this.validationAnswers = new $.cdh.validation(this.validateAnswers);
        this.validationQuestions = new $.cdh.validation(this.questionsLoader.data);
        this.shouldTranslate = ko.observable(false);

        this.continueSetup = function () {
            this.confirmEmail(this.data().email());
            this.validationQuestions.activate();
            this.validationAnswers.activate();
            if (!this.validationQuestions.hasErrors() && !this.validationAnswers.hasErrors()) {
                this.goToStep(3);
                this.confirmEmailValidation.activate();
            }
        }.bind(this);

        this.showResultSetup = function () {
            // this.confirmEmailValidation.activate();
            if (!this.confirmEmailValidation.hasErrors()) {
                this.goToStep(4);
            }
        }.bind(this);

        this.enrollUserRequest = new $.cdh.post.registration.enroll();

        this.submitSetup = function () {
            if (this.enrollUserRequest.isLoading())
                return;
            this.enrollUserRequest.load({
                ChallengeQs: $.map(this.data().questions.data(),
                    function (question) {
                        return {QuestionId: question.selectedQuestion(), UserAnswer: question.UserAnswer};
                    }),
                Email: this.confirmEmail(),
                UserId: this.data().userName()
            })
                .done(function (result) {
                    if (result.IsForcePasswordChange === true) {
                        $.cdh.helper.logoutWithRedirect($.cdh.routes.authentication.forcePasswordChange() + "?userName=" + this.data().userName());
                    } else {
                        this.goToStep(5);
                    }
                }.bind(this));
        }.bind(this);

        this.goToStep = function (stepNumber) {
            ko.utils.arrayForEach(this.steps(), function (step) {
                if (step.index == stepNumber)
                    step.isActive(true);
                else
                    step.isActive(false);
            });
            this.currentStep(smartSteps ? (stepNumber + 2) : stepNumber);
            this.templateName('template-registration-step' + stepNumber);
            updateHeight();
        };

        this.clickCancel = function() {
            if (this.securitysetup()) {
                $.cdh.helper.logout();
            } else {
                this.goLogin();
            }
        }.bind(this);

        this.aggregatedLoadStatus = new $.cdh.models.aggregatedLoadStatus([
            this.userInfoRequest,
            this.questionsLoader
        ]);

        if (this.securitysetup() && this.skipSecurityIntroduction()) {
            this.beginSetup();
        }

        this.createUserRequest.state.subscribe(updateHeight);
        this.enrollUserRequest.state.subscribe(updateHeight);
        this.questionsLoader.state.subscribe(updateHeight);

        this.validationUser.hasErrors.subscribe(updateHeight);
        this.validationAnswers.hasErrors.subscribe(updateHeight);
        this.validationQuestions.hasErrors.subscribe(updateHeight);
    };

    ko.components.register('standard-registration', {
        template: { element: 'template-registration' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new registrationModel(updateHeight);
            }
        }
    });
})(jQuery, ko);
