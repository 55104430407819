import { DataServiceBase } from "../DataServiceBase";

(function ($, ko) {
    class ProductLookUpService extends DataServiceBase {
        constructor() {
            super('/ProductLookUp');
        }

        ProductSearch(data) {  
            return this.postAsync(data,'GetPage');
        }

    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                ProductLookUpService: ProductLookUpService
            }
        }
    });
})(jQuery, ko);