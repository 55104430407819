(function ($) {
    const profileModel = function (profile, $vm, updateHeight, updateDependentList, loadProfile, forceReimbursementPopup) {

        $.extend(true, this, initProfile(profile));

        function initProfile(data) {
            const profile = ko.observable({ viewModel: ko.observable({}), isSameAddress: ko.observable(false), isSameOrEmptyAddress: ko.observable(false) })();
            
            profile.forceReimbursementPopup = forceReimbursementPopup || false;

            //Permissions
            profile.hasPermissionsToEditProfile = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateProfileDemographicInformation);
            profile.hasPermissionsToEditEmail = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateEmailAddress);
            
            profile.vm = $vm;
            profile.viewModel(data);
            profile.isSameAddress(isAddressesSame(data));
            profile.isSameOrEmptyAddress(profile.isSameAddress() || isShippingAddressEmpty(data));
            profile.email = ko.observable(profile.viewModel().Email);

            const computed = ko.observable({});
            computed.FullName = ko.computed(function () {
                return profile.viewModel().FirstName + " " + profile.viewModel().LastName;
            });
            profile.viewModel(ko.utils.extend(profile.viewModel(), computed));

            profile.updateProfile = updateProfile;
            profile.editProfileModel = new $.cdh.models.editProfileModel(profile, profile.updateProfile);
            profile.editUserPictureModel = new $.cdh.models.editUserPictureModel(profile,function(){});
            profile.editEmailModel = new $.cdh.models.editEmailModel(profile.viewModel().Email, function (newEmail) {
                loadProfile(); // 	WCP-2462
                profile.email(newEmail);
            });
            
            profile.profileDeleteAccountModel = new $.cdh.models.profileDeleteAccountModel(function () { });

            profile.changeLanguage = new $.cdh.models.changeLanguage(function () { });
            
            profile.editReimbursementModel = new $.cdh.models.ProfileReimbursementMethodEditPopup(profile, false, updateHeight, loadProfile);
            profile.editReimbursementModel.isProfile = true;

            profile.takeLast4 = takeLast4;

            profile.editLinkText = $.cdh.extensions.profile.ConnectedCacheAvailable(data) ? $.cdh.languageConstant.getConstant('profile.edit-Eligible-for-Connected-Cash') : $.cdh.languageConstant.getConstant('profile.edit')
            
            return profile;
        }

        function updateProfile(profile, newProfile, onSuccess) {
            if (profile.updateProfileRequest.isLoading())
                return;

            let _isMobileNumberSame = isMobileNumberSame(profile, newProfile);

            profile.updateProfileRequest.load(newProfile.viewModel()).done(function (result) {
                var isSameAddress = isAddressesSame(result);
                var isEmptyAddress = isShippingAddressEmpty(result);

                profile.isSameAddress(isSameAddress);
                profile.isSameOrEmptyAddress(isSameAddress || isEmptyAddress);

                profile.viewModel(ko.utils.extend(newProfile.viewModel(), result));
                if (onSuccess)
                    onSuccess();
                updateHeight();
                if (ko.isObservable(updateDependentList)) {
                    updateDependentList(true);
                }
                if (!_isMobileNumberSame) {
                    var updateMobileNumberPopup = $.cdh.languageConstant.getConstant('profile.update-mobile-number');
                    var communicationSettingLink = $.cdh.languageConstant.getConstant('profile.communication-settings');
                    let settings = {
                        title: $.cdh.languageConstant.getConstant('title.mobile-phone-update'),
                        titleVisible: true,
                        message: `<div class="popup-form_hint big-font text-left" >${updateMobileNumberPopup}<a href="/Page/CommunicationsSummary">${communicationSettingLink}</a></div>`,
                        okVisible: false,
                        cancelVisible: true,
                        cancelText: $.cdh.languageConstant.getConstant('button.close')
                    };
                    
                    $.cdh.helper.alert2(settings);
                }
            });
        }

        function isAddressesSame(data) {
            return data.ShippingAddress1 === data.Address1 &&
                data.ShippingAddress2 === data.Address2 &&
                data.ShippingAddressCity === data.City &&
                data.ShippingAddressState === data.State &&
                data.ShippingAddressZip === data.Zip &&
                data.ShippingAddressCountry === data.Country;
        }

        function isMobileNumberSame(profile, newProfile) {
            return profile.viewModel().MobileNumber === newProfile.viewModel().MobileNumber();
        }

        function isShippingAddressEmpty(data) {
            return !data.ShippingAddress1 &&
                !data.ShippingAddress2  &&
                !data.ShippingAddressCity &&
                !data.ShippingAddressState &&
                !data.ShippingAddressZip;
        }

        function takeLast4(text) {
            return text ? ko.unwrap(text).slice(-4) : "";
        }
    }

    $.extend(true, $, {
        cdh: {
            models: {
                Profile: profileModel
            }
        }
    });

})(jQuery);