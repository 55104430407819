import {DataServiceBase} from "../DataServiceBase";

export class InvestmentPortalService extends DataServiceBase {
    constructor() {
        super(`${$.cdh.investmentPortalPath}/api`);
    }

    async getSettingsAsync() {
        try {
            return await this.getAsync(null, 'info/settings');
        }
        catch {
            return null;
        }
    }
}