(function ($, ko) {
    var alertsListModel = function (updateHeight) {
        this.alerts = new $.cdh.get.alert.get(
            function (json) {
                return $.map(json, function (alert) {
                    alert.details = new $.cdh.models.AlertDetailsPopup(alert);
                    alert.isViewed = ko.observable(alert.IsViewed);
                    alert.selected = ko.observable(false);
                    alert.deleteRequest = new $.cdh.post.alerts.delete(alert.AlertId);
                    alert.markAsViewedRequest = new $.cdh.post.alerts.markAsViewed(alert.AlertId);
                    return alert;
                });
            });

        this.filter = new $.cdh.models.AlertsFilter(this.alerts);

        this.alertsCount = ko.pureComputed(function () {
            var notViewedAlerts = $.grep(this.alerts.data(),
                function (alert) { return !alert.isViewed() });
            return notViewedAlerts.length;
        }, this);

        this.displayedAlerts = ko.observable([]);

        this.pageIndex = ko.observable(0);

        this.isEmpty = ko.pureComputed(function() {
            return !this.alerts.isLoading() && this.alerts.data().length === 0;
        }, this);

        this.viewedAlerts = ko.pureComputed(function() {
            return $.grep(this.displayedAlerts(),
                function(alert) { return alert.isViewed() });
        }, this);

        this.selectedViewedAlerts = ko.pureComputed(function() {
            return $.grep(this.viewedAlerts(),
                function(alert) { return alert.selected() });
        }, this);

        this.notViewedAlerts = ko.pureComputed(function() {
            return $.grep(this.displayedAlerts(),
                function(alert) { return !alert.isViewed() });
        }, this);

        this.selectedNotViewedAlerts = ko.pureComputed(function() {
            return $.grep(this.notViewedAlerts(),
                function(alert) { return alert.selected() });
        }, this);

        this.selectedAlerts = ko.pureComputed(function () {
            return $.grep(this.displayedAlerts(),
               function (alert) { return alert.selected() });
        }, this);

        this.allSelected = ko.pureComputed({
            read: function () {
                if (this.selectedAlerts().length === 0)
                    return false;
                return this.displayedAlerts().length === this.selectedAlerts().length;
            },
            write: function (selected) {
                $.each(this.displayedAlerts(), function (i, alert) {
                    alert.selected(selected);
                });
            },
            owner: this
        });

        this.displayedAlerts.subscribe(function () {
            this.allSelected(false);
        }, this, "beforeChange");

        this.deleteInProgress = ko.observable(false);
        this.markAsViewedInProgress = ko.observable(false);
        this.operationInProgress = function() {
            return this.deleteInProgress() || this.markAsViewedInProgress();
        };

        // http://173.255.243.51/jtrac/app/item/WCP-474/
        //this.clickDelete = function () {
        //    if (this.selectedViewedAlerts().length === 0 || this.operationInProgress())
        //        return;

        //    this.deleteInProgress(true);

        //    var deletePromises = $.map(this.selectedViewedAlerts(),
        //        function (alert) { return alert.deleteRequest.load(); });

        //    $.when.apply($, deletePromises).always(function () {
        //        this.alerts.load();
        //        this.deleteInProgress(false);
        //    }.bind(this));
        //}

        this.clickMarkAsViewed = function () {
            if (this.selectedNotViewedAlerts().length === 0 || this.operationInProgress() || $.cdh.user.impersonated)
                return;

            this.markAsViewedInProgress(true);

            var markAsViewedPromises = $.map(this.selectedNotViewedAlerts(),
                function (alert) { return alert.markAsViewedRequest.load(); });

            $.when.apply($, markAsViewedPromises).always(function() {
                var index = this.pageIndex();
                this.alerts.load().done(function() {
                    this.pageIndex(index);
                    this.markAsViewedInProgress(false);
                    ko.postbox.publish($.cdh.topics.alerts.countRefresh, {});
                    ko.postbox.publish($.cdh.topics.notifications.countRefresh, {});
                }.bind(this));
            }.bind(this));
        };

        this.toggleFilter = function() {
            this.filter.visible(!this.filter.visible());
            if (!this.filter.visible())
                this.filter.filterApplied(false);
        }.bind(this);

        this.filter.visible.subscribe(updateHeight);
        this.displayedAlerts.subscribe(updateHeight);
        this.alerts.state.subscribe(updateHeight);

        this.alerts.load();
    };

    ko.components.register('alerts-list', {
        template: { element: 'template-alerts-list' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new alertsListModel(updateHeight);
            }
        }
    });
})(jQuery, ko);
