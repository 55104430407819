import template from "./cms-custom-label-item.html"

(function ($, ko) {
    ko.components.register('cms-custom-label-item', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new CustomLabelItem(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
    
    function CustomLabelItem(params, updateHeight) {
        var self = this;
        
        self.id = params.model.PrimaryId;
        self.isReadOnly = params.isReadOnly;
        self.label = ko.observable(params.model.Label);
        self.previousLabel = ko.observable(self.label());
        self.defaultLabel = params.model.DefaultLabel;
        self.description = params.model.Description;
        self.maxLength = params.model.MaxLength;
        self.required = params.model.Required;
        
        self.isDisabled = ko.pureComputed(function () {
            return self.isReadOnly;
        });
        
        self.error = ko.pureComputed(function () {
            const label = self.label() ? self.label().trim() : null;
            if (!$.cdh.helper.isValueSet(label) && self.required) return "required";

            const maxLength = params.model.MaxLength;

            if (label && label.length > maxLength) return "max length is " + maxLength;

            return null;
        });
        
        params.model.error = self.error;
        
        self.isChanged = ko.pureComputed(function() {
           return self.label() !== self.previousLabel(); 
        });
        
        self.isNotDefault = ko.pureComputed(function() {
            return self.label() !== self.defaultLabel;
        });
        
        self.setDefault = function() {
            self.label(self.defaultLabel);
        };
        
        self.reset = function() {
            self.label(self.previousLabel());
        };

        params.model.isError = self.isError;
        params.model.setDefault = self.setDefault;
        params.model.get = function() {
           return {
               PrimaryId: self.id,
               Label: self.label()
           };
        };
        
        params.model.setAsSaved = function () {
           self.previousLabel(self.label()); 
        };
    }
})(jQuery, ko);