import template from "./scheduled-payment-list-item.html";

ko.components.register('scheduled-payment-list-item', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new BillPayItemModel(params, widgetInfo.updateHeight);
        }
    }
});
    
function BillPayItemModel(params, updateHeight) {
    const self = this;
    
    let payment = params.payment;
    self.paymentRemoved = params.paymentRemoved;
    self.payment = payment;
    self.amount = payment.Amount;
    self.accountLabel = payment.IsScc ? $.cdh.languageConstant.getConstant("field.service-type") : $.cdh.languageConstant.getConstant("field.account");
    self.recurrenceFrequency = payment.FrequencyTypeCode;
    self.remainingNumRecurrences = payment.RemainingNumRecurrences;
    self.status = $.cdh.languageConstant.getConstant("filter.planned");
    self.nearestPaymentDate = payment.NearestPaymentDate;
    self.accountDescription = payment.AccountDescription;
    
    self.paymentType = getPaymentTypeFromCode(payment.Type)  
    self.description = payment.Description;
    self.payee = payment.Payee;
    self.account = payment.Account;

    self.isOpened = ko.observable(false);
    self.onToggle = function () {
        self.isOpened(!self.isOpened());
    };
    
    self.isOpened.subscribe(updateHeight);

    self.close = params.closeFunc;
}

function getPaymentTypeFromCode(type){
    if(type === $.cdh.enums.futurePaymentRecurringClaimType.hsaonlinePayment){
        return $.cdh.languageConstant.getConstant("field.hsa-payment");
    }

    if(type === $.cdh.enums.futurePaymentRecurringClaimType.recurringClaim){
        return $.cdh.languageConstant.getConstant("field.recurring-claim");
    }

    if(type === $.cdh.enums.futurePaymentRecurringClaimType.recurringExpenses){
        return $.cdh.languageConstant.getConstant("field.recurring-expenses");
    }
    
    return $.cdh.languageConstant.getConstant("field.none");
}