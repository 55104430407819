import template from "./account-activity-fx-claim-totals.html"

(function ($, ko) {
    ko.components.register('account-activity-fx-claim-totals', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityFxClaimTotals(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityFxClaimTotals(params, updateHeight) {
        const self = this;

        let model = params.model;

        self.providerCharged = model.BilledAmount;
        self.allowed = model.AllowedAmount;
        self.covered = model.CoveredAmount;
        self.totalResponsibility = model.ResponsibilityAmount;
        self.outOfPocket = model.OutOfPocketAmount;
        self.offset = model.OffsetAmount;
        self.benefit = model.AccountsPaidAmount;
        self.remainingResponsibility = model.RemainingResponsibilityAmount;
        
        self.summaryModel = {
            claimId: model.ClaimId,
            showed: ko.observable(false)
        };
        
        self.toggleSummary = function () {
            self.summaryModel.showed(!self.summaryModel.showed());
            updateHeight();
        }
    }

})(jQuery, ko);