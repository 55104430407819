import template from "./claims-status-filter.html"

(function ($, ko) {
    ko.components.register('claims-status-filter', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ClaimsStatusFilter(params, widgetInfo.updateHeight);
            }
        }
    });

    function ClaimsStatusFilter(params, updateHeight) {
        const self = this;
        
        self.enable = params.model.enable;

        self.statuses = {
            actionNeeded: 1,
            approved: 2,
            denied: 3
        };
        
        self.allStatuses = [self.statuses.actionNeeded, self.statuses.approved, self.statuses.denied];
        self.defaultStatuses = [self.statuses.actionNeeded, self.statuses.approved];
        self.selectedStatuses = ko.observableArray(self.defaultStatuses);

        self.filterSetters = [
            { text: $.cdh.languageConstant.getConstant('filter.all'), setter:  function() { self.selectedStatuses(self.allStatuses); } },
            { text: $.cdh.languageConstant.getConstant('filter.action-needed'), setter: function() { self.selectedStatuses([self.statuses.actionNeeded]); } },
            { text: $.cdh.languageConstant.getConstant('filter.approved-paid-submitted'), setter: function() { self.selectedStatuses([self.statuses.approved]); } },
            { text: $.cdh.languageConstant.getConstant('link.denied'), setter: function() { self.selectedStatuses([self.statuses.denied]); } }
        ];
        
        self.selectedStatuses.subscribe(function () {
            let data = {
                actionNeeded: checkIncludedStatus(self.statuses.actionNeeded),
                accountHistory: checkIncludedStatus(self.statuses.approved),
                denied: checkIncludedStatus(self.statuses.denied)
            };
            
            params.model.onSearch(data);
        });

        function checkIncludedStatus(status) {
            return self.selectedStatuses().includes(status);
        }
    }
})(jQuery, ko);