(function ($) {
    var investmentsTransferPopup = function (accountId, investmentStatus, onSuccessTransfer) {
        this.investmentsStatus = investmentStatus;
        this.transferInfo = new $.cdh.get.investments.getTransferInfo();
        
        this.preRender =  function (){
            this.transferInfo.load({id: accountId , dataPartnerId: this.investmentsStatus.data().DataPartnerId, ProductId: this.investmentsStatus.data().ProductId});
            this.transferData = {
                AccountId: accountId,
                TransferAmount: ko.observable().extend({
                    required: true,
                    min: ko.pureComputed(function () { return this.transferInfo.data().MinInvestmentAmount || 0; }, this),
                    max: ko.pureComputed(function () { return this.transferInfo.data().MaxInvestmentAmount || 0; }, this)
                })
            };

            this.validation = new $.cdh.validation(this.transferData);
        }.bind(this);
   
        
        this.transferRequest = new $.cdh.post.investments.transfer();

        this.canSubmit = ko.pureComputed(function () {
            return !this.validation.hasErrors() &&
                !this.transferRequest.isLoading() &&
                !this.transferInfo.isLoading();
        }, this);

        this.transferInstructionText = ko.pureComputed(function () {
            if (this.transferInfo.data().TransferInstructionText) {
                var min = $.cdh.helper.htmlFormatAmount(this.transferInfo.data().MinInvestmentAmount);
                var max = $.cdh.helper.htmlFormatAmount(this.transferInfo.data().MaxInvestmentAmount);
                var result = this.transferInfo.data().TransferInstructionText
                    .replace(/\[HsaMinimumInvestAmt\]/gi, min)
                    .replace(/\[HsaMaximumInvestAmt\]/gi, max);
                return result;
            }
            return null;
        }, this);
  
        this.clickSubmit = function () {
            this.validation.activate();

            if (!this.canSubmit())
                return;

            this.transferRequest
                .load(ko.toJS(this.transferData))
                .done(function () {
                    this.close();
                    var transferAmount = $.cdh.helper.htmlFormatAmount(this.transferData.TransferAmount());
                    var text = this.transferInfo.data().TransferSuccessText.replace(/\[Insert Transfer Amount\]/gi, transferAmount);
                    $.cdh.helper.alert(text, 
                        null,
                        function () { onSuccessTransfer(); }.bind(this),
                        "Transferred Successfully");
                }.bind(this));
        }.bind(this);

        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);
    };

    var applyForAccountPopup = function (accountId, investmentsStatusObservable, onSuccessApply) {
        this.applyRequest = new $.cdh.post.investments.applyForAccount();
        this.trustPlanRequest = new $.cdh.get.investments.getTrustPlanData();

        this.InvestmentsStatus = investmentsStatusObservable;
        this.isAgreementTextAvailable = ko.observable(true);
        this.isAgree = ko.observable(false);

        this.clickUmbAgree = function (clickedObservable) {
            clickedObservable(true);
            return true;
        }
        this.documents = ko.pureComputed(function () {
            if (!this.trustPlanRequest.hasData())
                return [];

            var model = $.grep(this.trustPlanRequest.data().DisclosureDocuments,
                  function (t) {
                      t.isUmbAgreed = ko.observable(false);
                      return t;
                  });
            return model;
        }, this);

        this.isUmbAgreedFull = ko.pureComputed(function () {
            var model = $.grep(this.documents(),
                function (t) {
                    return t.isUmbAgreed() === true;
                });
            return !investmentsStatusObservable().IsUMB || model.length === this.documents().length;
        }, this);

        this.canSubmit = ko.pureComputed(function () {
            return !isImpersonated && this.isUmbAgreedFull() && (this.isAgree() || !this.isAgreementTextAvailable()) && !this.applyRequest.isLoading();
        }, this);

        this.clickSubmit = function () {
            if (!this.canSubmit())
                return;

            this.applyRequest
                .load({ FlexAccountId: accountId, TrustPlanId: investmentsStatusObservable().TrustPlanId })
                .done(function () {
                    this.close();
                    onSuccessApply();
                }.bind(this));

        }.bind(this);

        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        investmentsStatusObservable.subscribe(function () {
            if (this.InvestmentsStatus().DataPartnerId != null && this.InvestmentsStatus().IsExistTrustPlanId) {
                var self = this;
                this.trustPlanRequest.load({
                    dataPartnerId: this.InvestmentsStatus().DataPartnerId,
                    trustPlanId: this.InvestmentsStatus().TrustPlanId,
                    isStatusApplied: this.InvestmentsStatus().AccountStatus === $.cdh.enums.investmentAccountStatusEnum.applied
                }).done(function (data) {
                    self.isAgreementTextAvailable(data?.AgreementText?.length > 0);
                });
            }
        }, this);

        this.ApplyPopup = {
            lblApplyForInvestment: $.cdh.languageConstant.getConstant('title.apply-for-investment'),
            lblTrustPlanNotConfigured: $.cdh.languageConstant.getConstant('messages.trust-plan-not-configured'),
            lblButtonCancel: $.cdh.languageConstant.getConstant('button.cancel'),
            lblButtonSubmit: $.cdh.languageConstant.getConstant('button.submit'),
            lblButtonLoading: $.cdh.languageConstant.getConstant('button.loading')
        };
    }

    var openInvestmentPartnerSitePopup = function (bumpText, requestBumpText, openSiteFunc) {
        this.bumpText = bumpText;
        this.requestBumpText = requestBumpText;
        this.openInvestmentPartnerSite = function () {
            this.close();
            openSiteFunc();
        }.bind(this);
        this.isImpersonated = isImpersonated;
        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.OpenPartnerSitePopupLabel = {
            lblInvestmentAccountAccess: $.cdh.languageConstant.getResource('title.investment-account-access','en'),
            lblImportant: $.cdh.languageConstant.getResource('messages.important','en'),
            lblRedirectedViaNewWindow: $.cdh.languageConstant.getResource('messages.redirected-via-new-window','en'),
            lblButtonCancel: $.cdh.languageConstant.getResource('button.cancel','en'),
            lblButtonProceed: $.cdh.languageConstant.getResource('button.proceed','en')
        };
    }

    var openChangeAllocationPopup = function (realignRequest, realignOptions, realignmentCurrent, cancelPendingRequest, showUpdateModel, updateHeight) {
        this.realignRequest = realignRequest;
        this.realignOptions = realignOptions;
        this.realignmentCurrent = realignmentCurrent;
        this.cancelPendingRequest = cancelPendingRequest;
        this.isAllowCancel = ko.observable(true);

       this.preRender = function () {
            this.realignRequest.load().done(function(result) { // result = has pending transactions
               if(!result) {
                   this.processRealignment();
               }
            }.bind(this));
        }.bind(this);

        this.cancelPendingTransactions = function () {
            this.cancelPendingRequest.load().done(function (result) { // result = success
                if (result) {
                    this.processRealignment();
                } else {
                    this.isAllowCancel(false);
                }
            }.bind(this));
        }.bind(this);

        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.processRealignment = function () {
            this.realignOptions.load().done(function (result) { // result = is allowed realign transactions
                if (result) {
                    this.realignmentCurrent.load().done(function(){   //TODO: clarify implementation
                        showUpdateModel(true);
                        this.close();
                        updateHeight();
                    }.bind(this));
                   
                } else {
                    this.isAllowCancel(false);
                }
            }.bind(this));
        }
    }

    var automaticTransfer = function (investmentModel, accountId) {
        this.investmentModel = investmentModel;
        this.accountId = accountId;
        this.updateAutoTransfer = new $.cdh.post.investments.updateAutoTransfer();
        this.confirmAutomaticTransfer = ko.observable(true);
        this.reachesNotify = ko.observable();
        this.currnetReachesNotify = ko.observable();
        this.reachesNotify.extend({ notify: 'always' });
        this.currnetReachesNotify.extend({ notify: 'always' });
        
        this.model = {
            MinAutoTransferAmount: ko.observable(),
            TransferAmountsAbove: ko.observable(),
            Agree: ko.observable()
        };

        this.preRender = function () {
            var isEnable = this.investmentModel.data().IsAutoTransferEnable;
            this.confirmAutomaticTransfer(!isEnable);

            this.AutomaticInvestmentTransferLabel = {
                lblButtonEdit: $.cdh.languageConstant.getResource('profile.edit','en'),
                lblPageTitle: $.cdh.languageConstant.getResource('title.automatic-transfer-settings', 'en'),
                msgBelowMinimum: $.cdh.languageConstant.getResource('messages.amount-below-minimum', 'en'),
                msgAgreeToTerms: $.cdh.languageConstant.getResource('messages.agree-to-terms', 'en')
            };

            this.pageTitle = this.AutomaticInvestmentTransferLabel.lblPageTitle;
            
            this.model.MinAutoTransferAmount = ko.observable(this.investmentModel.data().DefaultMinAutoTransferAmount);
            this.model.MinAutoTransferAmount.extend({
                required: true,
                min: {
                    params: this.investmentModel.data().DefaultMinAutoTransferAmount,
                    message: this.AutomaticInvestmentTransferLabel.msgBelowMinimum
                }
            });

            this.model.TransferAmountsAbove = ko.observable(this.investmentModel.data().DefaultTransferAmountsAbove);
            this.model.TransferAmountsAbove.extend({
                required: true,
                min: {
                    params: this.investmentModel.data().DefaultTransferAmountsAbove,
                    message: this.AutomaticInvestmentTransferLabel.msgBelowMinimum
                }
            });

            this.model.Agree(false);
            this.model.Agree.extend({
                checked: { message: this.AutomaticInvestmentTransferLabel.msgAgreeToTerms }
            });

            this.model.MinAutoTransferAmount.extend({ notify: 'always' });
            this.model.TransferAmountsAbove.extend({ notify: 'always' });
            this.validation = new $.cdh.validation(this.model);
            this.reachesNotify.notifySubscribers();
            this.canSubmit = ko.pureComputed(function () {
                return !this.updateAutoTransfer.isLoading() && !this.validation.hasErrors();
            },
                this);
        }.bind(this);

        this.currentMinAutoTransferAmount = ko.pureComputed(function () {
                if (this.investmentModel.isLoading())
                    return;

                return this.investmentModel.data().MinAutoTransferAmount;
            },
            this);

        this.currentTransferAmountsAbove = ko.pureComputed(function () {
                if (this.investmentModel.isLoading())
                    return;

                return this.investmentModel.data().TransferAmountsAbove;
            },
            this);
        
        this.currentReaches = ko.pureComputed(function () {
            this.currnetReachesNotify();
            var minAuto = parseFloat(this.model.MinAutoTransferAmount());
            var transAbove = parseFloat(this.model.TransferAmountsAbove());
            return (minAuto + transAbove);
        },
            this);

        this.reaches = ko.pureComputed(function () {
            this.reachesNotify();
            return this.currentMinAutoTransferAmount() + this.currentTransferAmountsAbove();
        },
            this);

        this.investmentModel.state.subscribe(function () {
            if (this.investmentModel.isLoading())
                return;
        }.bind(this));

        this.clickConfirm = function () {
            if ($.cdh.isMobile)
                $(".title-page:visible").text($.cdh.languageConstant.getConstant('title.automatic-transfer-settings'));

            this.pageTitle=$.cdh.languageConstant.getConstant('title.automatic-transfer-settings');
            this.confirmAutomaticTransfer(true);
            this.currnetReachesNotify.notifySubscribers();
        }.bind(this);


        this.clickSubmit = function () {
            this.validation.activate();
            if (this.validation.hasErrors()) {
                return;
            }
            this.updateAutoTransfer.load({
                id: this.accountId,
                transferAmountsAbove: this.model.TransferAmountsAbove,
                minAutoTransferAmount: this.model.MinAutoTransferAmount,
                resetAutoTransfer: false
            }).done(function () {
                investmentModel.load();
                this.close();
            }.bind(this));
        }.bind(this);

        this.clickTurnOff = function () {
            this.updateAutoTransfer.load({
                id: this.accountId,
                transferAmountsAbove: this.investmentModel.data().DefaultTransferAmountsAbove,
                minAutoTransferAmount: this.investmentModel.data().DefaultMinAutoTransferAmount,
                resetAutoTransfer: true
            }).done(function () {
                investmentModel.load();
                this.close();
            }.bind(this));
        }.bind(this);

        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);
    }

    var chartViewMode = {
        Donut: 1,
        Bar: 2
    };

    var isImpersonated = $.cdh.user.impersonated;

    var investmentsForAccountModel = function (updateHeight) {

        var accountId = $.cdh.helper.urlParam('accountId');

        this.showUpdateModel = ko.observable(false);
        this.vebaApplied = ko.observable(false);

        this.viewMode = ko.observable(chartViewMode.Donut);
        this.devenirSidRequest = new $.cdh.get.investments.getDevenirSid(accountId);
        this.investments = new $.cdh.get.investments.getForAccount(accountId);
        this.investmentsStatus = new $.cdh.get.investments.getInvestmentsStatus(accountId);
        this.pendingTransfers = new $.cdh.get.investments.getPendingTransfers(accountId);

        this.requestBumpText = new $.cdh.get.investments.getInvestmentsCustomText();
        this.requestNotEnoughText = new $.cdh.get.investments.getInvestmentsCustomText();
        this.requestEligibleToApplyText = new $.cdh.get.investments.getInvestmentsCustomText();
        this.trustPlanRequest = new $.cdh.get.investments.getTrustPlanData();
        this.devenirPendingElection = new $.cdh.get.investments.getDevenirPendingElection(accountId);
        this.devenirPendingRealignment = new $.cdh.get.investments.getDevenirPendingRealignment(accountId);
        this.devenirRealignmentOptions = new $.cdh.get.investments.getDevenirRealignmentOptions(accountId);
        this.devenirRealignmentCurrent = new $.cdh.get.investments.getDevenirRealignmentCurrent(accountId);
        this.devenirCancelRealignmentPending = new $.cdh.get.investments.getDevenirCancelRealignmentPending(accountId);
        this.updateDevenirAllocation = new $.cdh.post.investments.updateDevenirAllocation();
        this.isRequestBumpTextVerified = ko.observable(false);

        this.RulesLabel = {
            lblApplyForInvestment: $.cdh.languageConstant.getResource('title.apply-for-investment','en'),
            lblWidgetTitle: $.cdh.languageConstant.getResource('title.investment-rules-hsa','en'),
            lblWidgetBlock1Row1: $.cdh.languageConstant.getResource('field.widget-block-1-row-1','en'),
            lblWidgetBlock1Row2: $.cdh.languageConstant.getResource('field.widget-block-1-row-2','en'),
            lblWidgetBlock2: $.cdh.languageConstant.getResource('field.widget-block-2','en'),
            lblWidgetBlock3: $.cdh.languageConstant.getResource('field.widget-block-3','en'),
        };

        this.widgetTitle = this.RulesLabel.lblWidgetTitle;
        this.widgetBlock1Row1 = this.RulesLabel.lblWidgetBlock1Row1;
        this.widgetBlock1Row2 = this.RulesLabel.lblWidgetBlock1Row2;
        this.widgetBlock2 = this.RulesLabel.lblWidgetBlock2;
        this.widgetBlock3 = this.RulesLabel.lblWidgetBlock3;

        this.SummaryLabel = {
            lblTransferInvestments: $.cdh.languageConstant.getResource('button.transfer-investments', 'en'),
            lblViewTrade: $.cdh.languageConstant.getResource('button.view-trade', 'en'),
            lblInvestmentPortfolio: $.cdh.languageConstant.getResource('title.investment-portfolio', 'en'),
            lblInvestmentAllocation: $.cdh.languageConstant.getResource('title.investment-allocation', 'en'),
            lblDateRequested: $.cdh.languageConstant.getResource('field.date-requested', 'en'),
            lblDateAvailableInvestmentAccount: $.cdh.languageConstant.getResource('field.date-available-investment-account', 'en'),
            lblTotalPortfolio: $.cdh.languageConstant.getResource('field.total-portfolio', 'en')
        };

        this.ChangeAllocationLabel = {
            lblChangeAllocation: $.cdh.languageConstant.getResource('title.change-allocation', 'en'),
            lblPendingTransactions: $.cdh.languageConstant.getResource('messages.pending-transactions', 'en'),
            lblButtonClose: $.cdh.languageConstant.getResource('button.close', 'en'),
            lblCancelPendingTransactions: $.cdh.languageConstant.getResource('button.cancel-pending-transactions', 'en'),
            lblNotAllowedCancelTransactions: $.cdh.languageConstant.getResource('messages.not-allowed-pending-transactions', 'en')
        };


        this.account = ko.observable(null);
        $.cdh.accounts.initAccounts(function () {
            var foundAccount = $.cdh.accounts.byAccountId(accountId);
            if (foundAccount && foundAccount.IsVeba) {                
                this.applyVeba ();
            }
        }.bind(this));

        this.applyVeba = function () {

            $.cdh.helper.title("Investments");
            $.cdh.helper.titlePage("Investments");

            this.widgetTitle($.cdh.languageConstant.getResource('title.investment-rules-account', 'en'));
            this.widgetBlock1Row1($.cdh.languageConstant.getResource('title.maintain-balance-to-invest', 'en'));
            this.widgetBlock1Row2($.cdh.languageConstant.getResource('title.current-account-balance', 'en'));
            this.widgetBlock2($.cdh.languageConstant.getResource('title.transfers-account-investment-account', 'en'));
            this.widgetBlock3($.cdh.languageConstant.getResource('title.balance-reaches-amount', 'en'));

            this.vebaApplied(true);

        }.bind(this);

        this.investmentsModel = ko.observableArray([]);

        this.investAccountStatus = ko.pureComputed(
            function () { return this.investmentsStatus.data().AccountStatus }, this);

        this.enoughBalanceToTrasfer = ko.pureComputed(function () {
            return this.investmentsStatus.data().CurrentHsaBalance >= this.investmentsStatus.data().MinBalanceToInvest;
        }, this);

        this.partnerData = ko.pureComputed(function () {
            if (this.showApplyForInvestmentAccount() && !this.trustPlanRequest.isLoading() && !this.trustPlanRequest.hasData()) {
                this.trustPlanRequest.load({
                    dataPartnerId: this.investmentsStatus.data().DataPartnerId,
                    trustPlanId: this.investmentsStatus.data().TrustPlanId,
                    isStatusApplied: this.investmentsStatus.data().AccountStatus === $.cdh.enums.investmentAccountStatusEnum.applied
                });
            }
            updateHeight();
            return this.trustPlanRequest.data();
        }, this);

        this.displayDonutChart = ko.pureComputed(function () {
            return this.viewMode() === chartViewMode.Donut;
        }, this);

        this.bumpText = ko.pureComputed(function () {
            if (this.showInvestmentsSummary() && !this.requestBumpText.isLoading() && !this.requestBumpText.hasData() && !this.isRequestBumpTextVerified()) {
                this.requestBumpText.load({
                    dataPartnerId: this.investmentsStatus.data().DataPartnerId,
                    resourceKey: $.cdh.enums.dataPartnerCustomTextResourceEnum.invBumpText,
                    ProductId: this.investmentsStatus.data().ProductId
                });
            }
            this.isRequestBumpTextVerified(true)
            return this.requestBumpText.data();
        }, this);

        this.notEnoughText = ko.pureComputed(function () {
            if (this.showInvestmentsRules() && !this.requestNotEnoughText.isLoading() && !this.requestNotEnoughText.hasData()) {
                this.requestNotEnoughText.load({
                    dataPartnerId: this.investmentsStatus.data().DataPartnerId,
                    resourceKey: $.cdh.enums.dataPartnerCustomTextResourceEnum.invNotEnoughToInvestText,
                    ProductId: this.investmentsStatus.data().ProductId
                });
            }
            var result = '';
            var htmlText = this.requestNotEnoughText.data();
            if (htmlText.length > 0) {
                result = htmlText.replace(/\[HsaMaintainBal\]/gi, this.investmentsStatus.data().MaintainBalance)
                    .replace(/\[HsaMinimumInvestAmt\]/gi, this.investmentsStatus.data().MinTransferAmount)
                    .replace(/\[HsaMinimumInvestBal\]/gi, this.investmentsStatus.data().MinBalanceToInvest);

                if (this.vebaApplied()) {
                    result = htmlText.replace(/ HSA /gi, ' account ');
                }                    
            }
            return result;
        }, this);

        this.showNotEnoughText = ko.pureComputed(function () {
            return !this.enoughBalanceToTrasfer() && this.requestNotEnoughText.hasData() && this.requestNotEnoughText.data().length > 0;
        }, this);

        this.eligibleToApplyText = ko.pureComputed(function () {
            if (this.showApplyForInvestmentAccount() && !this.requestEligibleToApplyText.isLoading() && !this.requestEligibleToApplyText.hasData() && this.showInvestmentsRules()) {
                this.requestEligibleToApplyText.load({
                    dataPartnerId: this.investmentsStatus.data().DataPartnerId,
                    resourceKey: $.cdh.enums.dataPartnerCustomTextResourceEnum.invEligibleToApplyText,
                    ProductId: this.investmentsStatus.data().ProductId
                });
            }

            var result = '';
            var htmlText = this.requestEligibleToApplyText.data();
            if (htmlText.length > 0) {
                result = htmlText.replace(/\[HsaMaintainBal\]/gi, this.investmentsStatus.data().MaintainBalance)
                    .replace(/\[HsaMinimumInvestAmt\]/gi, this.investmentsStatus.data().MinTransferAmount)
                    .replace(/\[HsaMinimumInvestBal\]/gi, this.investmentsStatus.data().MinBalanceToInvest);
            }
            return result;
        }, this);

        this.isAnyRequestDone = ko.pureComputed(function () {
            return this.requestEligibleToApplyText.hasData() ||
                this.trustPlanRequest.hasData() ||
                this.requestNotEnoughText.hasData();
        }, this);

        this.showInvestmentsRules = ko.pureComputed(function () {
            if (!this.investmentsStatus.hasData()) return false;
            return !(this.investAccountStatus() === $.cdh.enums.investmentAccountStatusEnum.active && this.enoughBalanceToTrasfer());
        }, this);

        this.showApplyForInvestmentAccount = ko.pureComputed(function () {
            return this.investmentsStatus.data().IsExistTrustPlanId && (this.investAccountStatus() === $.cdh.enums.investmentAccountStatusEnum.canApply ||
                this.investAccountStatus() === $.cdh.enums.investmentAccountStatusEnum.applied ||
                !this.enoughBalanceToTrasfer());
        }, this);

        this.showInvestmentsSummary = ko.pureComputed(function () {
            if (!this.investmentsStatus.hasData()) return false;
            return this.investAccountStatus() === $.cdh.enums.investmentAccountStatusEnum.active;
        }, this);

        this.IsAutoTransferEnable = ko.pureComputed(function () {
            return !this.investmentsStatus.isLoading() && this.investmentsStatus.data().IsAutoTransferEnable;
        }, this);

        this.investmentsChartData = ko.pureComputed(function () {
            var count = 5; // display only 
            var nonZero = $.grep(this.investments.data().InvestmentsPortfolioItems, function (i) { return i.ItemMarketValue > 0 });
            if (nonZero.length > count) {
                var exceeding = nonZero.slice(count).reduce(function (p, c) { return p + c.ItemMarketValue; }, 0);
                nonZero = nonZero.slice(0, count);
                //nonZero.push({
                //    ItemDescription: 'Other',
                //    ItemName: 'Other',
                //    ItemMarketValue: exceeding
                //});
            }
            return nonZero;
        }, this);

        var onSuccessTransfer = function () {
            var popupModel = new investmentsTransferPopup(accountId, this.investmentsStatus, onSuccessTransfer);
            this.investmentsTransferPopup(popupModel);
            this.pendingTransfers.isLoading(true);
            setTimeout(function () {
                this.pendingTransfers.isLoading(false);
                this.pendingTransfers.load();
            }.bind(this), 5000);

        }.bind(this);

        this.investmentsTransferPopup = ko.observable(
            new investmentsTransferPopup(accountId, this.investmentsStatus, onSuccessTransfer));

        this.applyForAccountPopup = new applyForAccountPopup(accountId, this.investmentsStatus.data, this.investmentsStatus.load);
        this.automaticTransfer = new automaticTransfer(this.investmentsStatus, accountId);

        this.ssoUrl = ko.observable();
        this.openInvestmentPartnerSitePopup = new openInvestmentPartnerSitePopup(this.bumpText, this.requestBumpText,
            function () {
                new $.cdh.get.investments.getInvestmentPartnerSsoUrl(accountId).load()
                    .done(function (url) {
                        this.ssoUrl(url);
                        // $("#partnerSitePopupLink")[0].click();
                        setTimeout(function() {
                            $("#partnerSitePopupLink")[0].click();
                        }, 300);
                        
                        updateHeight();
                    }.bind(this));
            }.bind(this)
        );
        this.openChangeAllocationPopup = new openChangeAllocationPopup(this.devenirPendingRealignment, this.devenirRealignmentOptions, this.devenirRealignmentCurrent, this.devenirCancelRealignmentPending, this.showUpdateModel, updateHeight);

        this.updateAllocation = function () {
            if (this.validateAllocation()) {
                this.updateDevenirAllocation.load({ items: this.investmentsModel(), id: accountId }).done(function (result) {
                    if (result) {
                        window.location = window.location.href;
                    } else {
                        $.cdh.helper.alert("No Changes Have Been Made", function () { window.location = window.location.href; }, function () { window.location = window.location.href; });
                    }
                }.bind(this));
            } else {
                $.cdh.helper.alert("<span class='error'>Allocation amounts must = 100%</span>");
            }
                
        }.bind(this);

        this.validateAllocation = function () {
            var total = 100;
            var sum = 0;
            $.grep(this.investmentsModel(),
                function(t) {
                    sum += parseInt(t.PortfolioPercent());
                }.bind(this));
            return sum === total;
        }.bind(this);

        this.updateHeight = updateHeight;
        this.investments.state.subscribe(updateHeight);
        this.investmentsStatus.state.subscribe(updateHeight);
        this.pendingTransfers.state.subscribe(updateHeight);
        this.eligibleToApplyText.subscribe(updateHeight);

        this.investments.load();
        this.pendingTransfers.load();
        this.investmentsStatus.load().done(function(result) {
        /*
              if (result.AccountStatus === $.cdh.enums.investmentAccountStatusEnum.active) {
                  this.devenirSidRequest.load().done(function (result) {
                      if (result) {
                          this.investments.load().done(function (result) {
                              $.grep(result.InvestmentsPortfolioItems,
                                  function (t) {
                                      var model = {};
                                      model.PortfolioPercent = ko.observable(t.PortfolioPercent);
                                      model.Id = ko.observable(t.Id);
                                      model.ItemName = ko.observable(t.ItemName);
                                      model.ItemMarketValue = ko.observable(t.ItemMarketValue);
                                      this.investmentsModel.push(model);
                                  }.bind(this));

                          }.bind(this));
                          this.devenirPendingElection.load();
                      } else {
                          this.investments.data([]);
                      }
                  }.bind(this));
              }*/
        }.bind(this));
    };

    ko.components.register('investments-for-account', {
        template: { element: 'template-investments-for-account' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new investmentsForAccountModel(updateHeight);
            }
        }
    });
})(jQuery);

