import template from "./smart-registration-enter-code.html";

(function ($, ko) {
    ko.components.register('smart-registration-enter-code', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new EnterCodeModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function EnterCodeModel(params, updateHeight) {
        const self = this;

        self.codeMaxLength = 6;

        self.selectedMethod = params.model.selectedMethod;

        self.sendCodeRequest = new $.cdh.post.smartRegistration.sendCode();
        self.validateCodeRequest = new $.cdh.post.smartRegistration.validateCode();

        self.isEmail = ko.pureComputed(function () {
            return self.selectedMethod() === $.cdh.enums.registrationSendCodeMethod.email;
        });

        self.isSms = ko.pureComputed(function () {
            return [$.cdh.enums.registrationSendCodeMethod.smsToMobileNumber,
            $.cdh.enums.registrationSendCodeMethod.smsToPhone].includes(self.selectedMethod());
        });

        self.maskedEmail = params.model.maskedData.email;
        self.maskedNumber = ko.pureComputed(function() {
            switch (self.selectedMethod()) {
                case $.cdh.enums.registrationSendCodeMethod.smsToMobileNumber:
                    return params.model.maskedData.mobileNumber();
                case $.cdh.enums.registrationSendCodeMethod.smsToPhone:
                    return params.model.maskedData.phone();
            }
        });

        self.isLoading = ko.pureComputed(function() {
            return self.sendCodeRequest.isLoading() || self.validateCodeRequest.isLoading();
        });

        self.otp1 = ko.observable('');
        self.otp2 = ko.observable('');
        self.otp3 = ko.observable('');
        self.otp4 = ko.observable('');
        self.otp5 = ko.observable('');
        self.otp6 = ko.observable('');

        self.moveNext = function (data,event) {
                var value = event.target.value;
                var element = event.target;
            const keys = ['Shift', 'ArrowUp', 'ArrowDown', 'Tab', 'Enter', 'Delete', 'End', 'Home', 'Insert', 'ArrowLeft', 'ArrowRight','PrintScreen',' '];

                if (keys.includes(event.key)) {
                    event.preventDefault();
                    return;
                }

                if (value.length === 1) {
                    var nextElement = element.nextElementSibling;
                    if (nextElement && nextElement.tagName === "INPUT") {
                        nextElement.focus();
                    }
                } else if (value.length === 0) {
                    var prevElement = element.previousElementSibling;
                    if (prevElement && prevElement.tagName === "INPUT") {
                        prevElement.focus();
                    }
                }
        };
        self.validateInput = function (data, event) {
            var input = event.target.value;
            if (!/^\d$/.test(input)) {
                event.target.value = '';
            }
            return true;
        };

        document.addEventListener('paste', function (event) {
            var pasteData = event.clipboardData.getData('Text');
            if (/^\d{6}$/.test(pasteData) && pasteData.length === 6) {
                self.otp1(pasteData[0]);
                self.otp2(pasteData[1]);
                self.otp3(pasteData[2]);
                self.otp4(pasteData[3]);
                self.otp5(pasteData[4]);
                self.otp6(pasteData[5]);
                setTimeout(function () {
                    document.querySelector('.otp-input:last-child').focus();
                }, 0);
            }
        });

        self.code = ko.computed(function () {
            return self.otp1() + self.otp2() + self.otp3() + self.otp4() + self.otp5() + self.otp6();
        });

        self.isCodeEntered = ko.pureComputed(function () {
            let code = self.code();
            return code && code.length === self.codeMaxLength;
        });

        self.isErrorShowed = ko.observable(false);
        self.timerDuration = 900; // 15 minutes in seconds
        self.timer = ko.observable(self.timerDuration);
        self.interval = null;

        self.formattedTimer = ko.pureComputed(function () {
            var minutes = Math.floor(self.timer() / 60);
            var seconds = self.timer() % 60;
            return minutes + "m " + (seconds < 10 ? "0" : "") + seconds + "s";
        });

        self.startTimer = function () {
            clearInterval(self.interval);
            self.timer(self.timerDuration);
            self.interval = setInterval(function () {
                if (self.timer() > 0) {
                    self.timer(self.timer() - 1);
                } else {
                    clearInterval(self.interval);
                }
            }, 1000);
        };

        self.sendCode = function() {
            self.isErrorShowed(false);
            self.validateCodeRequest.load({code: self.code()}).done(function (result) {
                params.model.onNext(result);
            }).fail(function(xhr) {
                if (xhr.responseJSON && xhr.responseJSON.data && xhr.responseJSON.data.IsTicketExpired) {
                    params.model.onNext({ Success: false, IsTicketExpired: true });
                } else {
                    updateHeight();
                }
            });
        };

        self.resend = function () {
            if (self.isLoading()) {
                return;
            }
            params.model.clear();
            self.sendCodeRequest.load({ method: self.selectedMethod() }).done(function (result) {
                self.startTimer();
            }).fail(function () {
                self.isErrorShowed(true);
            });
        };

        self.cancel = params.model.onCancel;
        self.noCodeReceived = params.model.onNoCodeReceived;

        self.back = function() {
            self.timer(self.timerDuration); 
            self.startTimer();
            params.model.onBack();
        };
        params.model.clear = function() {
            self.otp1('');
            self.otp2('');
            self.otp3('');
            self.otp4('');
            self.otp5('');
            self.otp6('');
            self.isErrorShowed(false);

            self.sendCodeRequest.setData(null);
            self.validateCodeRequest.setData(null);
        };

        self.startTimer(); 
        updateHeight();
    }
})(jQuery, ko);
