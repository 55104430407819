import template from "./ineligible-balance-payment-popup.html"

(function ($, ko) {
    ko.components.register('ineligible-balance-payment-popup', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new PaymentPopup(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
    
    function PaymentPopup(params, updateHeight) {
        const self = this;
        
        self.isSuccess = ko.observable(false);
        
        self.paymentEntryModel = {
            balanceDue: params.balanceDue,
            directDeposit: params.directDeposit,
            closeFunc: params.closeFunc,
            onSubmit: function (data) {
                self.successModel.confirmationText(data.confirmationText);
                self.successModel.amount(data.amount);
                if (data.activities) {
                    self.successModel.activities(data.activities);
                }
                self.isSuccess(true);
            }
        };
        
        self.successModel = {
            confirmationText: ko.observable(),
            amount: ko.observable(),
            activities: ko.observableArray(),
            directDeposit: params.directDeposit
        };
    }
})(jQuery, ko);