import template from "./claim-submit-confirmation.html"

(function ($, ko) {
    ko.components.register('claim-submit-confirmation', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ClaimSubmitConfirmation(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ClaimSubmitConfirmation(params, updateHeight) {

        this.ClaimSubmitConfirmationLabel = {
            lblClaimDetails: $.cdh.languageConstant.getConstant('field.claim-details'),
            lblClaimDocumentation: $.cdh.languageConstant.getConstant('claim.documentation'),
            lblClaimSubmission: $.cdh.languageConstant.getConstant('claim.confirm-submission'),
            lblProviderSignature: $.cdh.languageConstant.getConstant('claim.provider-signature'),
            lblCancel: $.cdh.languageConstant.getConstant('button.cancel'),
            lblLoading: $.cdh.languageConstant.getConstant('button.loading'),
            lblSubmit: $.cdh.languageConstant.getConstant('button.submit'),
            lblAcceptCondition: $.cdh.languageConstant.getConstant('claim.accept-conditions'),
            lblThankYou: $.cdh.languageConstant.getConstant('communication.thank-you'),
            lblSubmitAnother: $.cdh.languageConstant.getConstant('claim.submit-another'),
            lblPrintClaim: $.cdh.languageConstant.getConstant('link.print-form-claim'),
            lblValidExpenseSubmit: $.cdh.languageConstant.getConstant('claim.valid-expenses-submitted'),
            lblSupplyEligibleDependent: $.cdh.languageConstant.getConstant('claim.supplies-eligible-dependents'),
            lblSupplyEffectAfter: $.cdh.languageConstant.getConstant('claim.supplies-effect-after'),
            lblFutureReimbursement: $.cdh.languageConstant.getConstant('claim.future-reimbursement-not-sent'),
            lblDontIncludeAmount: $.cdh.languageConstant.getConstant('claim.do-not-include-amount'),
            lblOk: $.cdh.languageConstant.getConstant('button.ok'),
            lblOpenAccountActivity: $.cdh.languageConstant.getConstant('button.open-account-activity'),
            lblOpenClaimList: $.cdh.languageConstant.getConstant('button.open-claims-list'),
            lblAcceptCondition: $.cdh.languageConstant.getConstant('claim.accept-conditions')
        };

        this.widget = params.widget;
        this.entry = params.entry;
        this.expense = params.expense;
        this.onSuccess = params.onSuccess;
        this.summary = null;
        this.validationSelected = params.validationSelected;
        this.claimsListText = params.isAccountActivity ? this.ClaimSubmitConfirmationLabel.lblOpenAccountActivity : this.ClaimSubmitConfirmationLabel.lblOpenClaimList;
        this.claimsListUrl = params.isAccountActivity
            ? $.cdh.routes.predefined.accountActivity
            : $.cdh.routes.predefined.claims.pageList;
        this.signatureFile = ko.observable(null);
        this.validatedByReceipt = this.validationSelected.name === "receipts";
        this.validatedBySignature = this.validationSelected.name === "signature";
        this.validateLater = this.validationSelected.name === "validateLater";
        this.isExistExpenseReceipt = this.widget.data().ExpenseReceipt !== null;
       
        this.currentStepModel = ko.observable(null);
        
        this.drawSignatureIfRequired = function(){
            if(this.validatedBySignature){
                setTimeout(function(){
                    var datapair = this.validationSelected.data.signData();
                    var i = new Image();
                    i.src = "data:" + datapair[0] + "," + datapair[1];
                    $(i).appendTo($("#signature_summary"));

                    html2canvas($("#claim")[0]).then(function(canvas) {
                        var image = canvas.toDataURL();
                        this.signatureFile(image);
                    }. bind(this));

                    updateHeight();
                }.bind(this), 50);
            }
        }.bind(this);

        this.addRequest = new $.cdh.post.claims.add();

        this.certificationText = ko.pureComputed(function() {
            let scc = this.entry.scc();
            if (scc && $.cdh.helper.isValueSet(scc.CertificationText)) {
                return scc.CertificationText;
            }

            if (!this.widget.data().Template.IsDefaultCertificateTextEmpty) {
                return this.widget.data().Template.DefaultCertificationText;
            }

            return null;
        }, this);
        
        this.agree = ko.observable().extend({
            validation: {
                validator: function (val) {
                    return !this.certificationText() || val;
                }.bind(this),
                message: this.ClaimSubmitConfirmationLabel.lblAcceptCondition
            }
        });

        this.validation = new $.cdh.validation(this.agree);
        
        this.displayConditions = function(){
            this.currentStepModel(new conditionsStepModel(this));
        }.bind(this);

        this.onCancel = function(){
            if($.isFunction(params.onCancel))
                params.onCancel();
        }.bind(this);

        this.onSubmit = function(){
            var e = this.entry;

            if (this.addRequest.isLoading()) {
                return;
            }

            this.validation.activate();
            if (this.validation.hasErrors()) {
                return;
            }
            
            var submitModel = {
                ServiceStartDate: e.start(),
                ServiceEndDate: e.end(),
                Comments: e.comments(),
                Amount: e.amount(),
                Copay: e.copay(),
                Coinsurance: e.coinsurance(),
                Deductible: e.deductible(),
                Claimant: e.claimant(),
                ServiceCategoryCode: e.scc(),
                BulkSubmit: false,
                IsESignatureClaim: this.validatedBySignature,
                ReimbursementModeCode: e.reimbursementMethod()?.Id
            };
            
            if (this.widget.data().Claim)
                submitModel.ClaimDescription = this.widget.data().Claim.ClaimDescription;

            if (this.expense != null) {
                if (this.widget.data().IsFx) {
                    submitModel.ExternalClaimId = this.expense.EOBExtra.ExternalClaimId;
                } else {
                    submitModel.ExpenseKeys = this.expense.EOBExtra.ExpenseKeys;
                    submitModel.BulkSubmit = this.widget.data().IsExpenseGroup;
                    submitModel.UpdatePayProvider = this.payProviderUpdateModel;
                }

                submitModel.EobType = this.expense.EOBType;
            }

            if (this.widget.data().IsFx) {
                submitModel.AllowPosOffset = e.allowPosOffset();
            }

            var acc = e.account();
            if (acc != null) {
                submitModel.AccountType = acc.AccountType;
                submitModel.FlexAccountKey = acc.FlexAccountKey;
            }

            if (e.useProvider()) {
                var p = e.providerChoice();

                if ($.cdh.user.impersonated &&
                    this.widget.data().Template !== null &&
                    this.widget.data().Template.Payees !== undefined &&
                    this.widget.data().Template.Payees !== null) {
                    if (this.widget.data().Template.Payees.length > 0) {
                        p = this.widget.data().Template.Payees[0];                    
                    }
                }

                submitModel.HasProvider = true;
                submitModel.PayProviderKey = p.PayeeKey;
                submitModel.ProviderName = p.PayeeName;
                submitModel.ProviderId = p.PayeeId;
                submitModel.AccountNumber = e.accountNumber();
            } else {
                submitModel.ProviderName = e.providerName();
            }

            if (this.entry.useRecuring()) {
                submitModel.UseRecuring = true;
                submitModel.PaymentDate = this.entry.PaymentDate();
                submitModel.PaymentRecurrenceFrequency = this.entry.PaymentRecurrenceFrequency();
                submitModel.PaymentRecurrenceType = this.entry.PaymentRecurrenceType();
                submitModel.RecurrenceTotalCount = this.entry.RecurrenceTotalCount();
                submitModel.RepeatUntilDate = this.entry.RepeatUntilDate();
            } else {
                submitModel.UseRecuring = false;
            }

            if(this.validatedByReceipt) {
                var files = this.validationSelected.data;
                if (files.length > 0) {
                    var submitReceipts = [];
                    for (var i = 0; i < files.length; i++) {
                        var file = files[i];
                        var splittedDataUrl = file.dataUrl.split(/[,:;]/);
                        submitReceipts.push({
                            FileName: file.fileName,
                            ContentType: splittedDataUrl[1],
                            Base64: splittedDataUrl[3]
                        });
                    }
                    submitModel.Receipts = submitReceipts;
                }
            } if(this.validatedBySignature){
                var data = this.signatureFile();
                var dataParts = data.split(/[,:;]/);
                var receipts = [];
                
                receipts.push({
                    FileName: 'ESignature receipt.png',
                    ContentType: dataParts[1],
                    Base64: dataParts[3]
                });

                submitModel.Receipts = receipts;
            }
            
            if(this.validateLater && this.isExistExpenseReceipt){
                submitModel.Receipts = [];
                submitModel.Receipts.push(this.widget.data().ExpenseReceipt);
            }

            this.addRequest
                .load(submitModel)
                .done(function (summary) {
                    this.summary = summary;
                    this.currentStepModel(new submittedStepModel(this, summary));
                    if (this.onSuccess)
                        this.onSuccess();
                    updateHeight();
                    $(window).scrollTop(0);
                }.bind(this))
                .fail(function() {
                    updateHeight();
                }.bind(this));
        };
        
        this.isMainView = ko.pureComputed(function(){
            return this.currentStepModel() == null;
        }, this);
        
        this.isConditionsView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isConditionsView;
        }, this);
        
        this.isSubmittedView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isSubmittedView;
        }, this);
        
        this.isFaxCoverView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isFaxCoverView;
        }, this);

        if(this.validatedBySignature) {
            var settings = {
                titleVisible: false,
                message: `<h3 class="ta-center"> ${$.cdh.languageConstant.getConstant('messages.e-signing')}</h3>\n
                    <p class="ta-center">${$.cdh.languageConstant.getConstant('messages.e-signing-return')}</p>`,
                cancelVisible: false,
                okCallback: function () {
                    this.drawSignatureIfRequired();
                    updateHeight();
                }.bind(this)
            };

            $.cdh.helper.alert2(settings);
        }
    }
    
    function conditionsStepModel(model){
        this.isConditionsView = true;
        this.onNext = function(){
            model.currentStepModel(null);
            model.drawSignatureIfRequired();
        }.bind(this);
    }
    
    function submittedStepModel(model, summary){
        this.isSubmittedView = true;
        this.widget = model.widget;
        this.summary = summary;
        this.claimsListText = model.claimsListText;
        this.claimsListUrl = model.claimsListUrl;
        
        this.faxCoverUrl = $.cdh.routes.claims.downloadFaxCover({trackingNumber: summary.TrackingNumber, pdf: true});
        this.getPdf = () => getPdfFileByUrl(this.faxCoverUrl);
    }
    
    function faxCoverStepModel(model, summary, submitted){
        this.isFaxCoverView = true;
        this.summary = summary;
        this.trackingNumber = summary.TrackingNumber;
        
        this.onContinue = function(){
            model.currentStepModel(submitted);
        }.bind(this);
        
        this.onBack = function(){
            model.currentStepModel(submitted);
        }.bind(this);
    }
})(jQuery, ko);