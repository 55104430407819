(function(ko, $) {
    var ViewModel = function(updateHeight, template) {
        this.template = new $.cdh.services.templateExtractor(template);
        this.banks = [];
        this.hsaAccounts = ko.observableArray([]);
        this.selectedAcc = ko.observable();
        this.selectedBank = ko.observable();
        this.currentStep = ko.observable("step1");
        this.instrText = ko.observable("");
        this.choice = ko.observable();
        this.eConsentRegistered = ko.observable(false);
        this.eConsentAllowed = ko.observable(false);

        this.eConsentStatusRequest = new $.cdh.get.profile.eConsentStatus();
        this.eConsentStatusRequest.load().done(function (status) {
            this.eConsentAllowed(status.AllowToRegister);
            this.eConsentRegistered(status.Registered);
            if (!status.Registered && status.AllowToRegister) {
                this.hsaAccounts(status.AccountsToTransfer);
                this.banksRequest.load();
            }

            updateHeight();
        }.bind(this));
        
        this.bankRequest = new $.cdh.get.profile.getBank();
        this.submitRequest = new $.cdh.post.profile.submitEConsent();
        this.banksRequest = new $.cdh.get.profile.getEConsentBanks(function (data) {
            this.banks = data;
            if(this.banks.length === 1){
                this.selectedBank(this.banks[0]);
                this.goNext();
            }
        }.bind(this));
        
        this.steps = [
            { name: "Select your bank", className: "step-1", isActive: ko.observable(true), id:"step1" },
            { name: "Select your account", className: "step-2", isActive: ko.observable(false), id: "step2" },
            { name: "Authorize Transfer", className: "step-3", isActive: ko.observable(false), id: "step3" },
            { name: "Confirmation", className: "step-4", isActive: ko.observable(false), id: "step4" }
        ];
        
               
        this.goNext = function () {
            switch (this.currentStep()) {
                case "step1":
                    this.currentStep("step2");
                    updateHeight();
                    $.cdh.helper.scrollPageToTop();
                    break;
                case "step2":
                    this.bankRequest.load({
                        bankId : this.selectedBank().BankId,
                        accName: this.selectedAcc().AccountFullName}).done(function (data) {
                            this.selectedBank(data);
                            updateHeight();
                            $.cdh.helper.scrollPageToTop();
                    }.bind(this));
                    this.currentStep("step3");
                    break;
                case "step3":
                    this.submitRequest.load({
                        BankId: this.selectedBank().BankId,
                        FlexAccountKey: this.selectedAcc().AccountId,
                        Choice: this.choice()
                    }).done(function () {
                        this.currentStep("step4");
                        updateHeight();
                        $.cdh.helper.scrollPageToTop();
                    }.bind(this));
                    break;
            }
        }.bind(this);

        this.currentStep.subscribe(function(value) {
                $.each(this.steps, function() { this.isActive(this.id === value); });
            },
            this);
        
    };
    ko.components.register("easy-hsa-transfer", {
        template: { element: "template-easy-hsa-transfer" },
        viewModel: {
            createViewModel: function(params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new ViewModel(updateHeight, params.template);
            }
        }
    });
})(window.ko, window.jQuery);
