import template from "./claim-action-delete-expense.html"

(function ($, ko) {
    ko.components.register('claim-action-delete-expense', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ClaimActionDeleteExpense(params, widgetInfo.updateHeight);
            }
        }
    });

    function ClaimActionDeleteExpense(params, updateHeight) {
        let eobSummary = params.model;
        this.eobSummary = eobSummary;
        if (eobSummary.EOBType === "ExpenseGroup") {
            this.deleteRequest = new $.cdh.post.expenses.groupDelete();
        } else {
            this.deleteRequest = new $.cdh.post.expenses.delete();
        }

        this.clickDelete = function () {
            if (this.eobSummary.EOBType === "ExpenseGroup") {
                this.deleteRequest.load({claimId: this.eobSummary.TrackingNumber})
                    .done(function (result) {
                        if (result) {
                            if (this.eobSummary.reloadListCallback) {
                                this.eobSummary.reloadListCallback();
                            }
                            this.close();
                        }
                    }.bind(this));
            } else {
                this.deleteRequest.load({expenseKey: this.eobSummary.ExpenseKey, originCde: this.eobSummary.OriginCde})
                    .done(function (result) {
                        if (result) {
                            if (this.eobSummary.reloadListCallback) {
                                this.eobSummary.reloadListCallback();
                            }
                            this.close();
                        }
                    }.bind(this));
            }
        }.bind(this);

        this.close = params.closeFunc || function () {};
    }
    
})(jQuery, ko);