import template from "./cancel-payment-popup.html";
import {PaymentsService} from "../../Http/Payments/PaymentsService";

ko.components.register('cancel-payment-popup', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new PaymentCancelPopup(params);
        }
    }
});

class PaymentCancelPopup {
    constructor(params) {
        this.onRemoveCallback = params.onRemoveCallback;
        this.payment = params.payment;
        this.paymentsService = new PaymentsService();
        this.cancelled = ko.observable(false);

        this.close = params.closeFunc;
    }

    clickCancel() {
        this.close();
    }

    async clickSubmit() {
        if (this.paymentsService.loading())
            return;

        let result = await this.paymentsService.cancelPaymentAsync(this.payment);
        let data = result.data();

        if (data) {
            this.cancelled(true);
        }
    }

    clickOk() {
        if (this.onRemoveCallback) {
            this.onRemoveCallback();
        }
        this.close();
    }
}