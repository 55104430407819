(function ($, ko) {
    var attentionItemsModel = function (updateHeight, template) {
        
        this.templateExtractor = new $.cdh.services.templateExtractor(template);
        this.itemsRequest = new $.cdh.get.claims.getRequiredAttentionItems();
        this.itemsRequestLoading = ko.observable(true);
        this.syncItem = ko.observable().syncWith($.cdh.topics.claims.requiredAttentionItems);
        this.items = [];

        this.itemsRequest.load().done(function(result) {
            this.items = $.grep(result,
                function(t) {
                    t.Actionable = ko.observable(true);
                    t.Status = ko.observable(t.Status);
                    t.ReceiptsCount = ko.observable(t.ReceiptsCount);
                    t.Receipts = ko.observable(t.Receipts);
                    t.faxCoverUrl = ko.observable();
                    return true;
                }.bind(this));
            var requiredAttentionItemsWidget = $('div.required-attention-items-block');
            var horizontalSwitchingWidth = requiredAttentionItemsWidget.data('horizontal-switching-width');

            function setStructure() {
                if (requiredAttentionItemsWidget.outerWidth() >= horizontalSwitchingWidth) {
                    requiredAttentionItemsWidget.addClass('horizontal');
                } else {
                    requiredAttentionItemsWidget.removeClass('horizontal');
                }
            }

            $(window).on('resize',
                _.debounce(function() {
                        setStructure();
                    },
                    300));
            setStructure();

            this.itemsRequestLoading(false);
        }.bind(this));

        this.filteredItems = ko.pureComputed(function () {

            if (this.itemsRequestLoading ())
                return [];

            if (this.syncItem() !== undefined) {
                $.each(this.items,
                    function (i, c) {
                        c.Actionable(true);
                        if (this.syncItem().isClaim && c.ClaimKey === this.syncItem().claimKey) {
                            c.Actionable(false);
                        }
                        if (!this.syncItem().isClaim && c.TransactionId === this.syncItem().transactionId) {
                            c.Actionable(false);
                        }
                    }.bind(this));
            }

            var items =  $.grep(this.items,
                function(t) {
                    return t.Actionable();
                }.bind(this));

            if (items.length === 0) {
                var widget = $("required-attention-items");
                $.cdh.helper.removeWidget(widget);
            }
            
            $.each(items, function(i, item){
                if(!item.AllowFaxCover)
                    return;
                
                item.faxCoverUrl($.cdh.routes.claims.downloadFaxCover({trackingNumber: item.TrackingNumber, pdf: true}));
            }.bind(this));

            return items;
        }, this);
        
        this.filteredItems.subscribe(updateHeight);
    };

    ko.components.register('required-attention-items', {
        template: { element: 'template-required-attention-items' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new attentionItemsModel(updateHeight, params.template);
            }
        }
    });
})(jQuery, ko);