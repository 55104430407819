(function ($) {
    var contributionsSummaryModel = function (updateHeight, template) {

        this.templateExtractor = new $.cdh.services.templateExtractor(template);

        var accountId = $.cdh.helper.urlParam('accountId');
        var dataPartnerId = $.cdh.helper.urlParam('dataPartnerId');
        var pageUrlParam = $.cdh.helper.urlParam('page');
        
        this.forceAddContribution = pageUrlParam === 'AddContribution';

        this.account = ko.observable(null);
        this.isAllowedCatchUp = parseFloat($.cdh.user.age) ? parseFloat($.cdh.user.age) > 55 : false;
        this.accountVeba = ko.pureComputed(function () {
            if (this.account() != null && this.account().IsVeba) {
                return true;
            }
            return false;
        }, this);
        $.cdh.accounts.initAccounts(function (data) {
            var accounts = $.grep(data,
                function (a) {
                    if (a.AccountId == accountId) {
                        return true;
                    }
                    return false;
                });

            if (accounts && accounts.length > 0)
                this.account(accounts[0]);

        }.bind(this));


        this.dataPartnerId = dataPartnerId;

        this.accountsFilterOptions = ko.observable({
                filterFunction: function (a) { return (a.AccountType === $.cdh.enums.accountType.hsa) },
                selectedAccountId: accountId
            })
            .syncWith($.cdh.topics.accounts.listFilterOptions);

        ko.postbox.subscribe($.cdh.topics.accounts.listFilter, function (account) {
            if (account && account.AccountId && account.AccountId != accountId) {
            window.location = $.cdh.routes.predefined.contributions.list +
                '?accountId=' + account.AccountId +
                '&dataPartnerId=' + account.DataPartnerId;
            }
        });
        
        this.summary = new $.cdh.get.contribution.getSummary(accountId);
        this.bankAccounts = new $.cdh.get.bankAcount.get(dataPartnerId);

        this.addContributionModelBuilder = function() {
            return new $.cdh.models.ContributionsAddEditPopup(
                { AccountId: accountId }, dataPartnerId);
        }.bind(this);

        this.addBankAccountModelBuilder = function () {
            return new $.cdh.models.BankAccountsAddPopup(dataPartnerId, this.initAddBankAccount);
        }.bind(this);

        this.initAddBankAccount = function () {
            this.bankAccounts.load();
        }.bind(this);

        this.summary.state.subscribe(updateHeight);
        this.bankAccounts.state.subscribe(updateHeight);

        this.init = function () {
            this.summary.load();
            this.initAddBankAccount();
        }.bind(this);

        this.init();
    }

    ko.components.register('contributions-summary', {
        template: { element: 'template-contributions-summary' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new contributionsSummaryModel(updateHeight, params.template);
            }
        }
    });
})(jQuery);