import template from "./claims-advanced-filter.html"

(function ($, ko) {
    ko.components.register('claims-advanced-filter', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new ClaimsAdvancedFilter(params, widgetInfo.updateHeight);
            }
        }
    });

    function ClaimsAdvancedFilter(params, updateHeight) {
        const self = this;

        self.enable = params.model.enable;

        self.visible = ko.observable(params.model.current && params.model.current.trackingNumber);
        self.dateFrom = ko.observable('');
        self.dateTo = ko.observable('');
        self.amount = new $.cdh.models.amountRange();
        self.claimNumber = ko.observable(params.model.current ? params.model.current.trackingNumber : '');

        self.resetFilter = function () {
            self.dateFrom('');
            self.dateTo('');
            self.amount.from(null);
            self.amount.to(null);
            self.claimNumber('');
        };

        
        self.onToggle = function () {
            let visible = !self.visible();
            self.visible(visible);

            
            if (!visible) {
                self.resetFilter ();
                self.onSearch();
            }
        };
        
        self.setDates = function (from, to) {
            self.dateFrom(from);
            self.dateTo(to);
        };
        
        self.onSearch = function() {
            let data = null;
            if (self.visible()) {
                data = {};
                let dateFrom = moment(self.dateFrom());
                if (dateFrom.isValid()) {
                    data.dateFrom = dateFrom.format("YYYY-MM-DD");
                }

                let dateTo = moment(self.dateTo());
                if (dateTo.isValid()) {
                    data.dateTo = dateTo.format("YYYY-MM-DD");
                }

                if (self.amount.fromValue() != null) {
                    data.amountFrom = self.amount.fromValue();
                }

                if (self.amount.toValue() != null) {
                    data.amountTo = self.amount.toValue();
                }

                if (self.claimNumber()) {
                    data.trackingNumber = self.claimNumber();
                }
            }
            
            params.model.onSearch(data);
        };

        self.visible.subscribe(updateHeight);
        
        params.model.onToggle = self.onToggle;
        params.model.setDates = self.setDates;
    }
})(jQuery, ko);