import template from "./account-activity-fx-claim-summary.html"

(function ($, ko) {
    ko.components.register('account-activity-fx-claim-summary', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityFxClaimSummary(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityFxClaimSummary(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.fxDetailsRequest = new $.cdh.get.claims.fxDetails();
        
        self.claimId = model.claimId;
        self.transactions = ko.observableArray();
        self.total = ko.observable();
        
        function load() {
            self.fxDetailsRequest.load({ externalClaimId: self.claimId }).done(function(data) {
                self.transactions(data.Transactions);
                self.total(data.Total);
                updateHeight();
            });
        }
        
        model.showed.subscribe(function(value) {
           if (value === true && !self.fxDetailsRequest.isLoading() && !self.fxDetailsRequest.hasData()) {
               load();
           }
        });
    }

})(jQuery, ko);