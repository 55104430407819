import template from "./eligible-expense-list.html"

(function ($, ko) {
    ko.components.register('eligible-expense-list', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new EligibleExpenseList(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function EligibleExpenseList(params, updateHeight) {
        this.noAccountSelected = ko.observable(true);
        this.nonPlanAvailable = ko.observable(false);
        this.updateHeight = updateHeight;
        this.accounts = ko.observableArray();
        this.eligibleExpenseRequest = new $.cdh.get.expenses.getEligibleExpenseList();
        this.eligibleAccountsRequest = new $.cdh.get.expenses.getEligibleAccounts(function (accs) {
            $.grep(accs, function (a) {
                this.accounts.push({value: a, text: a.EligibleName});
            }.bind(this));
            this.nonPlanAvailable(accs.length === 0);
            return accs;
        }.bind(this));
        
        this.selectedPlan = ko.observable();

        this.selectedPlan.subscribe(function (acc) {
            if (typeof acc === 'undefined'){
                this.noAccountSelected(true);
                return this.eligibleExpenseRequest.data([]);
            }
            this.noAccountSelected(false);
            
            this.eligibleExpenseRequest.load({
                planId: acc.PlanId,
                planStartDate: acc.PlanStartDate,
                planEndDate: acc.PlanEndDate
            })
        }.bind(this));

        this.eligible = ko.observable(true);
        this.potentially = ko.observable(true);
        this.eligibleWithDoc = ko.observable(true);
        this.ineligible = ko.observable(true);
        this.query = ko.observable('');

        this.eligibleExpenseList = ko.pureComputed(function () {
            this.emptyMessage = $.cdh.languageConstant.getConstant("eligible.no-expense-list-contact-admin");
            var expenses = this.eligibleExpenseRequest.data();
            if (expenses.length === 0)
                return [];
            var filteredExpense = this.appliedFilters(expenses);
            return this.searchFilter(filteredExpense);
        }, this);

        this.appliedFilters = function (expenses) {
            this.emptyMessage = $.cdh.languageConstant.getConstant("eligible.no-match-found");
            if (this.nonFiltersSelected())
                return [];
            var filteredExpenses = [];

            for (var i = 0; i < expenses.length; i++) {
                var expense = expenses[i];
                if (this.eligible() && expense.Eligible)
                    filteredExpenses.push(expense);
                if (this.potentially() && expense.PotentiallyEligible)
                    filteredExpenses.push(expense);
                if (this.eligibleWithDoc() && (expense.EligibleWithLMN || expense.EligibleWithRx))
                    filteredExpenses.push(expense);
                if (this.ineligible() && expense.InEligible)
                    filteredExpenses.push(expense);
            }
            return filteredExpenses;
        }.bind(this);

        this.searchFilter = function (filteredExpenses) {
            return filteredExpenses.filter(function (expense) {
                var searchQuery = this.query().toLowerCase();
                return expense.ExpenseName.toLowerCase().indexOf(searchQuery) >= 0;
            }.bind(this));
        }.bind(this);

        this.nonFiltersSelected = ko.pureComputed(function () {
            return !this.eligible() && !this.potentially() && !this.eligibleWithDoc() && !this.ineligible();
        }, this)

        this.eligibleAccountsRequest.load();
    }
})(jQuery, ko);