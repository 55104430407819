import template from "./cms-custom-labels.html"

(function ($, ko) {
    ko.components.register('cms-custom-labels', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new CustomLabelsModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });
    
    function CustomLabelsModel(params, updateHeight) {
        var self = this;
        
        self.isReadOnly = !$.cdh.cmsuser.canUpdate();
        
        self.getRequest = new $.cdh.get.cms.customLabels.get();
        self.saveRequest = new $.cdh.post.cms.customLabels.save();
        
        self.items = ko.observableArray();
        self.isError = ko.observable(false);
        
        self.resetAll = function() {
            $.cdh.helper.alert2({
                message: "Are you sure you want to reset all labels?",
                okCallback: function () {
                    const all = allItems();
                    all.forEach(function(item) {
                        item.setDefault();
                    });
                    self.save();
                },
                okText: "Reset all"
            });
        };
        
        self.isLoading = ko.pureComputed(function () {
           return self.getRequest.isLoading() || self.saveRequest.isLoading(); 
        });
        
        self.isDisabled = ko.pureComputed(function() {
           return self.isReadOnly || self.isLoading();
        });
        
        self.save = function() {
            
            const all = allItems();
            
           self.isError(all.some(function(item) {
               return !!item.error();
           }));
           
           if(!self.isError()) {
               let items = all.map(function (item) {
                   return item.get();
               });

               self.saveRequest.load({items: items}).done(function() {
                   all.forEach(function (item) {
                       item.setAsSaved();
                   })
               });
           }
        };
        
        self.getRequest.load().done(function(items) {
            var models = items.map(function(item) {
                return {
                    data: item,
                    isReadOnly: self.isReadOnly,
                    setDefault: function() {},
                    get: function() {},
                    setAsSaved: function () {}
                };
            });
            self.items(models);
        });

        function allItems() {
            return arrayFlat(self.items().map(function (item) {
                return item.data.CustomLabels;
            }));
        }
        
        function arrayFlat(array) {
            let result = [];
            for (let i = 0; i < array.length; i++) {
                if (Array.isArray(array[i])) {
                    result = result.concat(arrayFlat(array[i]));
                } else {
                    result.push(array[i]);
                }
            }
            return result;
        }
    }

})(jQuery, ko);