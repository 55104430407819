(function($, ko, captcha) {

    function anonymousEnrollment(updateHeight, template) {

        this.templateExtractor = new $.cdh.services.templateExtractor(template);

        this.modes = {
            short: 1,
            complete: 2
        };

        this.mode = ko.observable(this.modes.short);

        this.allRequired = ko.pureComputed(function() {
                return this.mode() === this.modes.complete;
            },
            this);
        
        this.model = {
            employerId: ko.observable().extend({ required: true }),
            employeeId: ko.observable().extend({ required: { onlyIf: this.allRequired, params: true }}),
            lastName: ko.observable().extend({ required: { onlyIf: this.allRequired, params: true }})
        };

        this.validation = new $.cdh.validation(this.model);
        
        this.checkAnonymous = new $.cdh.get.enrollments.checkAnonymous();
        this.anonymousEnrollment = new $.cdh.post.enrollments.anonymous();

        this.mode.subscribe(updateHeight);
        this.checkAnonymous.isLoading.subscribe(updateHeight);
        this.anonymousEnrollment.isLoading.subscribe(updateHeight);
        this.validation.hasErrors.subscribe(updateHeight);

        this.captchaToken = ko.observable();

        this.onCaptchaSuccess = function(token) {
            this.captchaToken(token);
        }.bind(this);

        this.isValid = ko.pureComputed(function() {
            if (this.mode() == this.modes.short) return !this.validation.hasErrors();
            return !!this.captchaToken() && !this.validation.hasErrors();
        }, this);

        this.captchaApi = {
            reset: $.noop
        };
        
        this.click = function() {
            this.validation.activate();
            if (!this.isValid()) return;
            this.validation.activated(false);
            switch (this.mode()) {
            case this.modes.short:
                this.checkAnonymous.load({
                        employerId: this.model.employerId()
                    }
                ).done(function(data) {
                    if (data.IsValid) {
                        this.mode(this.modes.complete);
                    }
                }.bind(this));
                break;
            case this.modes.complete:
                this.anonymousEnrollment.load({
                        EmployerId: this.model.employerId(),
                        EmployeeId: this.model.employeeId(),
                        LastName: this.model.lastName(),
                        CaptchaToken: this.captchaToken()
                    }
                ).done(function(data) {
                    if (data.IsValid) {
                        window.location.href = $.cdh.routes.predefined.enrollments.pageList;
                    }
                }).always(function() {
                    this.captchaApi.reset();
                }.bind(this));
                break;
            }
        }.bind(this);

    }

    ko.components.register("anonymous-enrollment", {
        template: {element: "template-enrollment-anonymous"},
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new anonymousEnrollment(updateHeight, params.template);
            }
        }
    });

})(jQuery, ko, window.grecaptcha);