import template from "./account-activity-tc-eob.html"

(function ($, ko) {
    ko.components.register('account-activity-tc-eob', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityTcEob(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityTcEob(params, updateHeight) {
        const self = this;

        let model = params.model;

        self.eobDetails = model.ExpensesDetails.map(function(item) {
            return {
                line: item.LineNumber,
                status: item.StatusName,
                providerCharged: item.ProviderChargedAmount,
                allowed: item.Allowed,
                covered: item.Covered,
                totalResponsibility: item.TotalResponsibility,
                outOfPocket: item.OutOfPocket,
                offset: item.Offset,
                benefit: item.Benefit,
                remainingResponsibility: item.RemainingResponsibility
            };
        });
        
        let totals = model.TotalsExpensesDetails;

        self.totals = {
            providerCharged: totals.ProviderChargedAmount,
            allowed: totals.Allowed,
            covered: totals.Covered,
            totalResponsibility: totals.TotalResponsibility,
            outOfPocket: totals.OutOfPocket,
            offset: totals.Offset,
            benefit: totals.Benefit,
            remainingResponsibility: totals.RemainingResponsibility
        }
    }

})(jQuery, ko);