import {DataServiceBase} from "../../DataServiceBase";

(function ($, ko) {
    class PharmacyService extends DataServiceBase {
        constructor() {
            super('/SmartAccount/Vendors/Pharmacy');
        }

        searchAsync(name, zipCode, distance) {
            let urlParams = {
                PharmacyName: name,
                ZipCode: zipCode,
                Distance: distance
            }

            return this.getAsync(urlParams, 'Search');
        }
    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                vendors: {
                    PharmacyService: PharmacyService
                }
            }
        }
    });
})(jQuery, ko);