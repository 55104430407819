import {PageUrlModes} from "../Common/pageUrlModes";
import template from "./delivery-preference.html"

(function ($, ko) {
    ko.components.register('delivery-preference', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new DeliveryPreference(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function DeliveryPreference(params, updateHeight) {
        var self = this;
        let model = params;
        self.model = model;
        this.existOpportunityBlock = PageUrlModes.isOpportunity();
        if(this.existOpportunityBlock) {
            PageUrlModes.removeMode();
        }
        
        this.updateHeight = updateHeight;

        this.onSubmitted = params.onSubmitted || function(op) {};
        this.onCancel = params.onCancel || function () {};

        this.accountId = params.accountId;
        this.updateRequest = params.updateRequest;
        this.header = params.header;
        this.additionalFeeText = params.additionalFeeText || '';
        this.electronicOption = params.electronicOption;
        this.electronicOptionText = params.electronicOptionText;
        this.paperOption = params.paperOption;
        this.paperOptionText = params.paperOptionText;
        this.opportunityText = params.opportunityText;
        this.validPin = ko.observable(params.validPdfPin);
        this.pinValidated = ko.observable(params.pinValidated);
        this.pdfSwitchedOff = ko.observable(true);
        this.deliveryMethod = ko.observable();
        this.deliveryMethodText = ko.observable();
        this.instrText = ko.observable();

        this.pin = ko.observable('');
        this.pinValid = ko.pureComputed(function() {
            return this.pinValidated() || this.validPin() == this.pin();
        }, this);

        this.showPdf = function (data, event) {
	        $.cdh.helper.showPdfViwer(event.target.href);
        };
        this.acceptEvent = ko.observable(false);

        window.addEventListener('acceptEvent', function (event) {
            self.acceptEvent(event.detail.accepted);
        });

        window.addEventListener('rejectEvent', function (event) {
            self.acceptEvent(event.detail.accepted);
        });

        this.submitEnabled = ko.pureComputed(function () {
            return this.acceptEvent() &&
                this.deliveryMethod() === this.electronicOption &&
                this.deliveryMethod() !== params.currentDeliveryMethod &&
                !this.updateRequest.isLoading();
        }, this);

        this.paperSubmitEnabled = ko.pureComputed(function () {
            return this.deliveryMethod() === this.paperOption &&
                params.currentDeliveryMethod === this.electronicOption &&
                !this.updateRequest.isLoading();
        }, this);

        this.clickEnter = this.clickSubmit = function() {
            if(self.submitEnabled()){
                self.submit();
            }
        }

        this.deliveryMethod.subscribe(function (method) {
            if(!method) return;

            if(method === this.electronicOption){
                this.instrText("If you would like to change your delivery method, please complete the electronic documents access form above and click SUBMIT, otherwise click CANCEL.");
            } else {
                this.instrText("If you would like to change your delivery method, please click SUBMIT, otherwise click CANCEL.");
            }
        }.bind(this))

        if (params.currentDeliveryMethod === this.electronicOption){
            this.deliveryMethod(this.paperOption);
            this.deliveryMethodText('Your current delivery method is: ' + this.electronicOptionText)
        }

        else
        {
            this.deliveryMethod(this.electronicOption);
            this.deliveryMethodText('Your current delivery method is: ' + this.paperOptionText);
            this.pdfSwitchedOff(false);
        }

        this.submit = function () {
            this.updateRequest.load({ id: this.accountId, deliveryOption: this.deliveryMethod() })
		        .done(function (result) {
			        if (result) {
				        this.onSubmitted(this.deliveryMethod(), this.pinValid());
			        }
		        }.bind(this));
        }.bind(this);

        this.cancel = function() {
	        this.onCancel();
        }.bind(this);
        
    }


})(jQuery, ko);
