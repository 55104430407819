import template from './forgot-password.html';

ko.components.register('forgot-password', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new ForgotPassword(params, widgetInfo);
        }
    }
});

export class ForgotPassword {
    constructor(params, widgetInfo) {
        this.changePasswordToken = localStorage.getItem('changePasswordToken');
        localStorage.removeItem('changePasswordToken');
    }
}