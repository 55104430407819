import template from "./medicine-cabinet-pharmacy-edit.html"

(function ($, ko) {

    ko.components.register('medicine-cabinet-pharmacy-edit', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new PharmacyEdit(params, widgetInfo)
            }
        }
    });

    function PharmacyEdit(params, widgetInfo) {
        const self = this;
        const storageKey = $.cdh.medicineCabinet.searchKey;

        self.verndorPharmacyService = new $.cdh.http.smartAccount.vendors.PharmacyService();
        self.pharmacyService = new $.cdh.http.smartAccount.PharmacyService();

        self.forceHideEmptyMessage = ko.observable(true);
        
        let savedModel = $.cdh.localStorage.getObject(storageKey) || {};
        
        self.searchModel = {
            zipCode: ko.observable(savedModel.zipCode).extend({
                required: true,
                zipCode: true
            }),
            distance: ko.observable(savedModel.distance || 3).extend({
                required: true,
                distance: true
            }),
            pharmacyName: ko.observable(null)
        };

        self.validation = new $.cdh.validation(self.searchModel);

        self.pharmacies = ko.observableArray([]);
        
        self.disable = ko.pureComputed(function () {
            return self.pharmacyService.loading() || self.verndorPharmacyService.loading();
        });

        self.searchClick = function () {
            var searchpharamacy = $("#searchpharamacy");
            searchpharamacy.html(" ");
            self.validation.activate();
            if (self.validation.hasErrors()) return;

            self.forceHideEmptyMessage(false);

            let model = self.searchModel;

            $.cdh.localStorage.setObject(storageKey, {
                zipCode: model.zipCode(),
                distance: model.distance()
            });
            
            self.verndorPharmacyService.searchAsync(model.pharmacyName(), model.zipCode(), model.distance())
                .then(function (pharmacies) {                   
                    searchpharamacy.html(pharmacies.data().length.toString() + " " + $.cdh.languageConstant.getConstant('button.results-found'));
                    self.pharmacies(pharmacies.data());
                    widgetInfo.updateHeight();
                });
        };

        self.submitClick = function (pharmacy) {
            self.pharmacyService.setAsync(pharmacy)
                .then(function (result) {
                    params.closeFunc();
                    params.refreshCallback();
                });
        };

        self.close = function () {
            params.closeFunc();
        };
    }
})(jQuery, ko);