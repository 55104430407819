import {ParticipantConditionsService} from "../../../../Http/SmartAccount/ConditionsService";
import {SmartAccountBaseViewModel} from "../../../SmartAccountBaseViewModel";
import template from "./funding-calculator-family-member-conditions-edit.html"

ko.components.register('funding-calculator-family-member-conditions-edit', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new FundingCalculatorFamilyMemberConditionsEdit(params, componentInfo, widgetInfo)
        }
    }
});

export class FundingCalculatorFamilyMemberConditionsEdit extends SmartAccountBaseViewModel {
    constructor(params, componentInfo, widgetInfo) {
        super(params, componentInfo, widgetInfo);

        this.dependentConditions = params.dependentConditions;
        this.dependent = params.dependent;
        const conditions = params.conditions.map((c) => {
            const dependentConditionFind = (dc) => dc.DependentId === this.dependent.DependentId && dc.ConditionId === c.Uuid;
            c.checked = ko.observable(!!this.dependentConditions.find(dependentConditionFind));
            c.checked.subscribe(() => {
                const dc = this.dependentConditions.find(dependentConditionFind);
                const index = this.dependentConditions.indexOf(dc);
                if (index >= 0) {
                    this.dependentConditions.splice(index, 1);
                } else {
                    this.dependentConditions.push({
                        DependentId: this.dependent.DependentId,
                        ConditionId: c.Uuid
                    });
                }
            })
            return c;
        }).orderBy(c => c.Display);

        this.participantConditionsService = new ParticipantConditionsService();

        this.search = ko.observable('');

        this.conditions = ko.pureComputed(() => {
            const searchValue = this.search();
            if (!searchValue) return conditions;
            return conditions.filter(c => c.Display.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0);
        });
    }

    async save() {
        await this.apiAsyncCall(async () => await this.participantConditionsService.putAsync(this.dependentConditions));
        this.params.closeFunc();
        this.params.refreshCallback();
    }
    
    cancel(){
        this.params.closeFunc();
    }
}
    