import template from "./deductible-change-status.html";

ko.components.register('deductible-change-status', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new DeductibleSetNotMet(params, widgetInfo.updateHeight);
        }
    }
});

export class DeductibleSetNotMet {
    constructor(params, updateHeight) {
        this.agree = ko.observable();
        this.isSubmitting = params.model.isParentSubmitting;
        
        this.id = params.model.id;
        this.startMetDate = params.model.startMetDate;
        this.endMetDate = params.model.endMetDate;
        
        this.statuses = [$.cdh.languageConstant.getConstant('DeductibleStatus.lblDeductibleMet'), $.cdh.languageConstant.getConstant('DeductibleStatus.lblDeductibleNotMet')];
        
        let isCurrentMet = this.isMetStatus(params.model.status);
        
        this.model = {
            status: ko.observable(isCurrentMet ? params.model.status : null).extend({required: true })
        };

        this.isMet = ko.computed(() => this.isMetStatus(this.model.status()));
        this.isNotMet = ko.computed(() => this.isNotMetStatus(this.model.status()));
        this.isMetOrNull = ko.computed(() => !this.model.status() || this.isMet());
        
        this.model.metDate = ko.observable(this.isMet() ? this.getCurrentOrDefaultMetDate(params.model.oldMetDate) : null).extend({required: { onlyIf: () => this.isMet() }});

        this.validation = new $.cdh.validation(this.model);

        this.canSubmit = ko.pureComputed(() => {
            return (this.agree() || this.isNotMet()) && !this.isSubmitting();
        });

        this.parentSubmit = params.model.submit;
        this.cancel = params.model.cancel;
    }
    
    submit() {
        this.validation.activate();
        if (this.validation.hasErrors()) {
            return;
        }
        
        this.parentSubmit(this.isMet(), this.isMet() ? this.model.metDate() : null);
    }

    getCurrentOrDefaultMetDate(metDate) {
        if (!metDate) {
            let now = moment();
            if (now < this.startMetDate) {
                return this.startMetDate.formatWCP();
            } else if (now > this.endMetDate) {
                return this.endMetDate.formatWCP();
            } else {
                return now.formatWCP();
            }
        }
        
        return metDate;
    }
    
    isMetStatus(status) {
        return status === $.cdh.languageConstant.getConstant('DeductibleStatus.lblDeductibleMet');
    }

    isNotMetStatus(status) {
        return status === $.cdh.languageConstant.getConstant('DeductibleStatus.lblDeductibleNotMet');
    }
}