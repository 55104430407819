(function ($, ko) {
    $.extend(true, $, {
        cdh: {
            models: {
                CardsReportLostPopup: function (model, submitFunc, issueNewVisible, template) {
                   
                    this.isIssueNewVisible = ko.observable(false);                  
                    this.card = model;
                    
                    this.preRender = function () {
                        var allowIssueNew = issueNewVisible(this.card);
                        var reissueCard = this.card.reissueCard();
                        this.isIssueNewVisible(allowIssueNew && reissueCard);
                    }.bind(this);
                    
                    this.IssueNew = ko.observable("no");
                    
                    this.setClose = function (closeFunc) {
                        this.close = closeFunc;
                    }.bind(this);

                    this.clickCancel = function () {
                        this.close();
                    };

                    this.clickSubmit = function () {
                        submitFunc(this.card, this.close);
                    };
                }
            }
        }
    });
}(jQuery, ko));