(function($, ko) {

    function elementModel(element, options) {
        $.extend(true, this, element);

        this.accepted = ko.observable(false).extend({
            checked : {}
        });
        
        this.setClose = function(close) {
            this.close = close;
        }.bind(this);

        this.cancel = function() {
            this.accepted(false);
            this.close()
        }.bind(this);

        this.accept = function() {
            this.accepted(true);
            this.close();
        }
        
        if (options.fixUrl) {
            this.url = this.Value;
            if (this.url.indexOf("http:") > -1) {
                this.url = this.url.replace("http:", "https:")
            }
        }
    }

    function templateGenericListModel(elements, count, fieldName) {
        this.items = [];

        for (var i = 1; i <= count; i++) {
            var item = elements[fieldName + i];
            if (!item.Value && !item.DescText && !item.LabelText) continue;
            this.items.push(new elementModel(item, {fixUrl: true}));
        }

        this.allAgree = ko.pureComputed(function() {
            var accepted = $.grep(this.items, function(item) {
                return item.accepted();
            });
            return accepted && accepted.length == this.items.length;
        }, this);
    }

    $.extend(true, $, {
        cdh: {
            models: {
                DisclosureList: function(disclosures) {
                    templateGenericListModel.call(this, disclosures, 5, "Disclosure")
                },
                AgreementList: function(agreements) {
                    templateGenericListModel.call(this, agreements, 5, "Agreement")
                }
            }
        }
    });
})(jQuery, ko);