import template from "./account-activity-manual-claim-info.html"

(function ($, ko) {
    ko.components.register('account-activity-manual-claim-info', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityManualClaimInfo(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityManualClaimInfo(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.model = model;
        self.denialReason = model.DenialReason;
        self.claimant = model.Claimant;
        self.serviceStartDate = model.ServiceStartDate;
        self.serviceEndDate = model.ServiceEndDate;
        self.description = model.Description;
        self.ilaCategoryValue = model.IlaCategoryDescription;
        self.ilaCategory = ko.observable();
        if (this.ilaCategoryValue) {
            let ilaCategoryDescription = model.IlaCategoryDescription.replace(/\s*\|\s*/g, '|');
            let parts = ilaCategoryDescription.split('|');
            let formattedPairs = [];
            for (let i = 0; i < parts.length; i += 2) {
                let part1 = parts[i].trim();
                let part2 = parts[i + 1] !== undefined ? parts[i + 1].trim() : '';
                formattedPairs.push(`<tr><td>${part1}</td><td style="padding-left: 3px;">${part2}</td></tr>`);
            }
            let formattedIlaCategory = formattedPairs.join('');
            self.ilaCategory = ko.observable(formattedIlaCategory);
        }
        


        if (model.ReimbursementDetails && model.ReimbursementDetails.length > 0) {
            let reimbursementDetail = model.ReimbursementDetails[0];
            self.reimbursementMethod = reimbursementDetail.Method;
            self.reimbursementDate = reimbursementDetail.Date;
            self.isProviderPaid = (/^Provider$/i).test(reimbursementDetail.PaidTo);
            self.checkNumber = reimbursementDetail.CheckNumber;
            self.paymentClearedDate = reimbursementDetail.CheckClearedDate;
            self.paymentId = reimbursementDetail.PaymentId;
            self.payoutMethod = reimbursementDetail.PayoutMethod;
        } else {
            self.reimbursementMethod = null;
            self.reimbursementDate = null;
            self.isProviderPaid = null;
            self.checkNumber = null;
            self.paymentClearedDate = null;
            self.paymentId = null;
            self.payoutMethod = null;
        }
    }

})(jQuery, ko);