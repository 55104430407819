(function($, ko) {
    
    function beneficiaryViewModel(beneficiary, maxPercentage, parent) {
        this.BeneficiaryNumber = ko.observable(beneficiary.BeneficiaryNumber);
        this.FlexAcctId = ko.observable(beneficiary.FlexAcctId);
        this.BeneficiaryFirstName = ko.observable(beneficiary.BeneficiaryFirstName).extend({ required: true });
        this.BeneficiaryLastName = ko.observable(beneficiary.BeneficiaryLastName).extend({ required: true });
        this.BeneficiaryName = ko.observable((this.BeneficiaryLastName() == "") ? this.BeneficiaryFirstName() : (this.BeneficiaryLastName() + ", " + this.BeneficiaryFirstName()));
        this.BeneficiaryTypeCde = ko.observable(beneficiary.BeneficiaryTypeCde);
        this.BeneficiaryTypeName = ko.pureComputed(function () {
            switch(this.BeneficiaryTypeCde()) {
                case $.cdh.enums.beneficiaryTypeCde.primary:
                    return "Primary";
                case $.cdh.enums.beneficiaryTypeCde.contigent:
                    return "Contingent";
            }
        }, this);


        this.IsNew = ko.observable(beneficiary.IsNew);
        this.Addr1 = ko.observable(beneficiary.Addr1).extend({ required: true });
        this.Addr2 = ko.observable(beneficiary.Addr2);
        this.City = ko.observable(beneficiary.City).extend({ required: true });
        this.State = ko.observable(beneficiary.State).extend({ required: true });
        this.Zip = ko.observable(beneficiary.Zip).extend({required: true, zipCode: true});
        this.BeneficiarySsn = ko.observable(beneficiary.BeneficiarySsn);
                
        this.EmployeeRelation = ko.observable(beneficiary.EmployeeRelation).extend({required: true});
        
        this.BirthDte = ko.observable(beneficiary.BirthDte || '').extend({ required: true });
        this.LastUpdateDte = ko.observable(beneficiary.LastUpdateDte);

        this.CurrentPercentage = ko.observable(beneficiary.Percentage);
        this.Percentage = ko.observable(beneficiary.Percentage);

        if (this.IsNew()) {
            this.BeneficiarySsn.extend({required: true, number: true, exactLength: 9});
        }

        this.allowEditPercentage = ko.observable (parent.settings.allowEditPercentage);
        if (this.allowEditPercentage())
        {
            this.Percentage.extend({
                required: true,
                digit: { value: true, message: $.cdh.languageConstant.getConstant("messages.please-enter-digits") },
                number: true, min: 0, max: ko.pureComputed(function () {
                    if (parent && parent.currentSettings().readonlyPercentage) return 100;
                    try {
                        return parent && parent.editMode()
                            ? maxPercentage() * 1.0 + this.Percentage() * 1.0
                            : maxPercentage();
                    } catch (ex) {
                        console.log(ex);
                    }
                    return 100;
                }, this)
            });
        }
    }

    var defaultBeneficiarySettings = {
        onCreate: {
            readonlyPercentage: false, 
            defaultPercentage: function() { return null; },
            canCopyParentAddress: true,
            canDelete: function () { return true; }
        },
        onEdit: {
            readonlyPercentage: false,
            canCopyParentAddress: true,
            canDelete: function () { return true; }
        }
    };
    
    function beneficiaryModel(beneficiary, getNextId, demographic, states, flexAccountId, 
                              type, max, addCallback, removeCallback, settings, beneficiaryCount) {
        const self = this;
        this.editMode = ko.observable(false);
        this.BeneficiarySsnView = ko.observable();
        this.hasPermissionsToViewDependents = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateDependentDemographicInformation);
        this.unknownOption = {};
        this.settings = $.extend(true, {}, defaultBeneficiarySettings, settings);
        this.currentSettings = ko.pureComputed(function () {
            return this.editMode() ? this.settings.onEdit : this.settings.onCreate;
        }, this);


        function setIsDesktop() {
            self.isDesktop(window.innerWidth > breakPoint);
        }

        const breakPoint = 767;
        this.isDesktop = ko.observable(true);
        this.IsOpened = ko.observable(false);
        this.openCard = function(card) {
            if (!this.isDesktop()) {
                card.IsOpened(!card.IsOpened());
            }
        };
        this.cardRowKeyDown = function(card, e) {
            if (!this.isDesktop() && (e.keyCode === 32 || e.keyCode === 13)) {
                card.IsOpened(!card.IsOpened());
                return false;
            }
            return true;
        };
        $(window).on('resize', _.debounce(function() {
            setIsDesktop();
        }, 200));
        setIsDesktop();


        this.dependents = [];

        this.dependentRequest = new $.cdh.get.profile.dependents(function (result) {
            this.dependents = $.map(result, function (dependent) {
                return {value: dependent.DependentId, text: dependent.FirstName + " " + dependent.LastName}
            });
        }.bind(this));
        
        this.selectedDependent = ko.observable();
        
        this.selectedDependent.subscribe(function (dependentId) {

            this.detailsRequest = new $.cdh.get.profile.dependentDetails(dependentId, true);

            if (!dependentId || dependentId === -1) {
                this.validation.deactivate();
                this.viewModel.BeneficiaryFirstName("");
                this.viewModel.BeneficiaryLastName("");
                this.viewModel.EmployeeRelation("");
                this.viewModel.BirthDte("");
                this.viewModel.Addr1("");
                this.viewModel.Addr2 ("");
                this.viewModel.City("");
                this.viewModel.State("");
                this.viewModel.Zip("");
                this.viewModel.BeneficiarySsn("");
                this.BeneficiarySsnView("");
            }
            else {
                
                this.detailsRequest.load().done(function (dependentDetails) {
                    this.viewModel.BeneficiaryFirstName(dependentDetails.FirstName);
                    this.viewModel.BeneficiaryLastName(dependentDetails.LastName);
                    this.viewModel.EmployeeRelation(dependentDetails.EmployeeRelation);
                    this.viewModel.BirthDte(dependentDetails.DateOfBirth);
                    this.viewModel.Addr1(dependentDetails.Address1);
                    this.viewModel.Addr2(dependentDetails.Address2);
                    this.viewModel.City(dependentDetails.City);
                    this.viewModel.State(dependentDetails.State);
                    this.viewModel.Zip(dependentDetails.Zip);
                    this.viewModel.BeneficiarySsn(dependentDetails.Ssn);
                    this.BeneficiarySsnView(dependentDetails.Ssn ? dependentDetails.Ssn.replace(/\d(?=\d{4})/g, "*") : "" );
                    
                    if(dependentDetails.EmployeeRelation ===  $.cdh.enums.employeeRelation.unknown){
                        $("[name = EmployeeRelation]").append(this.unknownOption);
                        $("[name = EmployeeRelation] option[value='0']").attr('selected','selected');
                    }
                    
                }.bind(this));
                
                
            }
        }.bind(this));

        this.displayDependents = ko.pureComputed(function () {
            return !this.editMode() && ($.cdh.user.isAnonymousEnrollmentUser || this.hasPermissionsToViewDependents);
        }.bind(this));
 
        this.states = states;
     
        this.title = ko.pureComputed(function(){
            return this.editMode() ? $.cdh.languageConstant.getConstant("button.edit-beneficiary") : $.cdh.languageConstant.getConstant("title.add-beneficiary") 
        }, this);

        this.beneficiarySaveRequest = new $.cdh.post.enrollments.saveBeneficiary();

        beneficiary = beneficiary || {
            FlexAcctId: flexAccountId,
            IsNew: true,
            BeneficiaryTypeCde: type,
            Percentage: this.settings.onCreate.defaultPercentage()
        };
        
        this.viewModel = new beneficiaryViewModel(beneficiary, max, this);
        this.readonlyModel = new beneficiaryViewModel(beneficiary, max, this); 
        this.id = getNextId();

        var applyModelValidation = function () {
            this.validation = new $.cdh.validation(this.viewModel);
        }.bind(this);

        this.beneficiaryLoadRequest = new $.cdh.get.enrollments.beneficiary();

        applyModelValidation();
        
        this.preRender = function() {
            this.beneficiarySaveRequest.setData(null);
            if (!beneficiary.IsNew) {
                this.editMode(true);
                this.beneficiaryLoadRequest.load({
                        flexAccountId: beneficiary.FlexAcctId,
                        type: beneficiary.BeneficiaryTypeCde,
                        id: beneficiary.BeneficiaryNumber
                    })
                    .done(function(data) {
                        $.each(data, function (i, item) {
                            if (this.viewModel[i]) this.viewModel[i](item);
                            if (this.readonlyModel[i]) this.readonlyModel[i](item);
                        }.bind(this));
                        this.viewModelCopy = data;
                        
                        if(data.EmployeeRelation ===  $.cdh.enums.employeeRelation.unknown){
                            $("[name = EmployeeRelation]").append(this.unknownOption);
                            $("[name = EmployeeRelation] option[value='0']").attr('selected','selected');
                        }
                        if (data.EmployeeRelation !== $.cdh.enums.employeeRelation.estate) 
                            $("[name = EmployeeRelation] option[value='5']").remove();
                        else
                            $("[name = EmployeeRelation]")[0].disabled = true;
                        
                           
                    }.bind(this));
            } else {
                if(this.detailsRequest) {
                    this.detailsRequest.cancel();
                }
                // reset model for popup dialogs
                this.viewModel = new beneficiaryViewModel(beneficiary, max, this);
                this.id = getNextId();
                this.selectedDependent(null);
                applyModelValidation();
            }

            if (this.displayDependents()) {
                if (!this.dependentRequest.hasData()) {
                    this.dependentRequest.load({});
                }
            }

        }.bind(this);

        this.viewModelCopy = null;

        this.setClose = function(close) {
            this.close = function () {
                this.selectedDependent(null);
                if(this.detailsRequest) {
                    this.detailsRequest.cancel();
                }
                close();
            }
        }.bind(this);

        this.save = function() {
            this.validation.activate();
            if (this.validation.hasErrors())
                return;
            const saveModel = {
                beneficiary: ko.toJS(this.viewModel),
                dependentId: this.selectedDependent()
            };
            this.beneficiarySaveRequest.load(saveModel).done(function(updatedModel) {
                if (!this.editMode()) {
                    if (!beneficiaryCount)
                        beneficiaryCount = 0;

                    addCallback(new beneficiaryModel(updatedModel,
                        getNextId,
                        demographic,
                        states,
                        flexAccountId,
                        type,
                        max,
                        $.noop,
                        removeCallback,
                        settings,
                        beneficiaryCount + 1));
                }
                this.viewModelCopy = updatedModel;
                $.each(updatedModel, function (i, item) {
                    if (this.readonlyModel[i]) this.readonlyModel[i](item);
                }.bind(this));
                this.close();
            }.bind(this));
        }.bind(this);

        this.cancel = function () {
            $.each(this.viewModelCopy, function (i, item) {
                if (this.viewModel[i]) this.viewModel[i](item);
            }.bind(this));
            this.close();
        }.bind(this);

        this.copyAddress = function() {
            var model = demographic();
            this.viewModel.Addr1(model.AddressLine1);
            this.viewModel.Addr2(model.AddressLine2);
            this.viewModel.City(model.City);
            this.viewModel.State(model.State);
            this.viewModel.Zip(model.Zip);
        }.bind(this);

        this.deleteBeneficiary = function(){
            $.cdh.helper.confirm($.cdh.languageConstant.getConstant("messages.delete-beneficiary-1", { '0': this.viewModel.BeneficiaryName() }),
                function() {
                    var deleteRequest = new $.cdh.post.enrollments.deleteBeneficiary();
                    deleteRequest.load({
                        flexAccountId: this.viewModel.FlexAcctId(),
                        type: this.viewModel.BeneficiaryTypeCde(),
                        beneficiaryNumber: this.viewModel.BeneficiaryNumber()
                    }).fail(function(err) {
                        $.cdh.helper.alert(err, $.noop);
                    }).done(function() {
                        removeCallback(this.id);
                    }.bind(this));
                }.bind(this),
                $.noop,
                "Delete Beneficiary");
        }.bind(this);
        
        this.deletePopup = {
            beneficiaryCount: beneficiaryCount,
            percentage: this.viewModel.Percentage(),
            allowedToBeDeleted: function() {
                if (this.percentage == 0)
                    return true;
                if (this.beneficiaryCount == 1)
                    return true;
                return false;
            },
            deleteRequest: new $.cdh.post.enrollments.deleteBeneficiary(),
            deleteBeneficiary: function () {
                var deleteRequest = this.deletePopup.deleteRequest;
                deleteRequest.load({
                    flexAccountId: this.viewModel.FlexAcctId(),
                    type: this.viewModel.BeneficiaryTypeCde(),
                    beneficiaryNumber: this.viewModel.BeneficiaryNumber()
                }).done(function() {
                    removeCallback(this.id);
                    this.deletePopup.cancel();
                }.bind(this));
            }.bind(this),
            setClose: function (f) {
                this.deletePopup.cancel = f;
            }.bind(this)
        };

        this.maskSsn = function () {
            this.viewModel.BeneficiarySsn(this.BeneficiarySsnView());
            if(this.BeneficiarySsnView()){
                var maskedSsn = this.BeneficiarySsnView().replace(/\d(?=\d{4})/g, "*");
                this.BeneficiarySsnView(maskedSsn);
            }
        }.bind(this);

        this.unMaskSsn = function () {
            this.BeneficiarySsnView(this.viewModel.BeneficiarySsn());
        }.bind(this);

        this.PercentageName = ko.pureComputed(function () {
            return this.Percentage () + '%';
        }, this);

        this.deleteOptionUnknown = function (option, item) {
            if (!item || !option)
                return;

            if (item.key.toLowerCase() === $.cdh.enums.employeeRelation.unknown.toString()){
                this.unknownOption = option;
                $(option).remove()
            }
            if (item.key.toLowerCase() === $.cdh.enums.employeeRelation.estate.toString()) {                
                if (!this.editMode())
                    $(option).remove();
                else
                    option.disabled = true;
            }
            
        }.bind(this);


        this.canDelete = ko.pureComputed(function () {
            return this.currentSettings().canDelete();
        }, this);
        
        this.toJS = function() {
            return ko.toJS(this.viewModel);
        }.bind(this);

        this.percentageFieldClick = function(data, e) {
            e.stopPropagation();
        }.bind(this);

        this.percentageFieldKeydown = function(data, e) {
            if (e.keyCode === 13 || e.keyCode === 32) {
                e.stopPropagation();
            }
            return true;
        }.bind(this);

    }

    $.extend(true, $, {
        cdh: {
            models: {
                Beneficiary: beneficiaryModel
            }
        }
    });
})(jQuery, ko);