(function (module, $, ko) {
    const MsgTaxForms = {
        msgEmpty: "Your Tax Forms list is empty",
        msgDeliveryPreference: "Tax Forms Delivery Preference",
        lblHeaderQuestion: "How would you like to receive your tax forms (1099-SA and 5498-SA)?",
        lblElectronicOnly: "Electronic Only",
        lblPaper: "Paper"
    };
    var specifics = {

        messages: function (viewName) {
            switch (viewName) {
                case "empty":
                    return MsgTaxForms.msgEmpty;
                case "preferences":
                    return MsgTaxForms.msgDeliveryPreference;
            }
            return "Tax Forms";
        },

        routes: $.cdh.routes.taxforms,
        requests: {
            get: $.cdh.get.taxforms,
            post: $.cdh.post.taxforms
        },
       
        ListViewModel: module.ListTaxFormsViewModel,

        optionSettings: {
            additionalFeeText: "",
            headerQuestion: MsgTaxForms.lblHeaderQuestion,
            electronic: {
                text: MsgTaxForms.lblElectronicOnly,
                value: $.cdh.enums.taxFormsPaperOrElectronic.electronic
            },
            paper: {
                text: MsgTaxForms.lblPaper,
                value: $.cdh.enums.taxFormsPaperOrElectronic.paper
            }
        }
    };

    ko.components.register("tax-forms1", {
        template: { element: "template-document-delivery" },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new module.MainViewModel(specifics, updateHeight);
            }
        }
    });

})(window.docDelivery, window.jQuery, window.ko);