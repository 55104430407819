(function ($, ko) {
    var cardsListModel = function (updateHeight, template) {
        this.updateHeight = updateHeight;
        this.template = new $.cdh.services.templateExtractor(template);
        this.cards = ko.observableArray();
        this.dependents = ko.observableArray();

        this.cardsAllowed = ko.observable(!$.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionEmployerNoDebitCard));

        this.cardsRequest = new $.cdh.get.cards.list(function (data) {
            this.pager.currentPageIndex(data.PageNo);
            this.pager.lastPageIndex(data.TotalPages);

            var cards = $.map(data.Cards, function (card) {
                return new $.cdh.models.CardsDetails(card,  data.ReissueText, this.allowIssueNew, this.template, this.onUpdateCallback);
            }.bind(this));
            this.cards(cards);
        }.bind(this));
        
        this.dependentsCardsRequest = new $.cdh.get.cards.dependents(function (data) {
            var dependents = $.map(data.Dependents, function (dependent) {
                return new $.cdh.models.CardDependent(dependent, this.updateHeight, this.onUpdateCallback);
            }.bind(this));
            this.dependents(dependents);
        }.bind(this));

        this.load = function () {
            if (this.cardsAllowed()) {
                this.cardsRequest.load().done(function () {
                    this.dependentsCardsRequest.load();
                }.bind(this));
            }
        }.bind(this);

        this.refreshCards = function () {
            if (this.cardsAllowed()) {
                this.cardsRequest.load({pageNo: this.pager.currentPageIndex(), pageSize: 10});
            }
        }.bind(this);

        this.onUpdateCallback = function () {
            this.load();
        }.bind(this);
        
        this.issueNewRequest = new $.cdh.post.cards.new();
        
        this.hasNew = function () {
            return $.grep(this.cards(), function (card) {
                return card.CardStatus() == $.cdh.enums.cardStatus.new;
            }).length > 0;
        };
        this.allowIssueNew = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionRequestNewCard);
        this.issueNewVisible = ko.computed(function () {
            return this.allowIssueNew && !this.hasNew() && this.cardsAllowed();
        }.bind(this));

        this.issueCard = function () {
            //Check cards with status New
            var hasNew = this.hasNew();
            if (hasNew) {
                $.cdh.helper.alert("Cannot create a new card. Participant already has a NEW card");
                return;
            }
            if (this.issueNewRequest.isLoading())
                return;
            this.issueNewRequest.load({ id: '' }).done(function (result) {                
                //var model = new $.cdh.models.CardsDetails(result, this.allowIssueNew);
                //this.cards.push(model);
                this.load();

                $.cdh.helper.alert($.cdh.languageConstant.getConstant("cards.card-issued_successfully"));
            }.bind(this));
        }.bind(this);
        
        this.isEmpty = ko.computed(function () {
            return !this.cardsRequest.isLoading() && this.cards().length === 0;
        }, this);

        this.cardsRequest.state.subscribe(updateHeight);
        //this.cards.data.subscribe(function (newItems) {
        //    $.each(newItems, function (i, item) {
        //        item.value.IsOpened.subscribe(updateHeight);
        //    });
        //}, null, "arrayChange");

       var cardsPagerModel = function () {

            this.currentPageIndex = ko.observable(1);
            this.lastPageIndex = ko.observable(1);

           this.nextPage = function () {
                if (this.lastPageIndex() > this.currentPageIndex()) {
                    this.currentPageIndex(this.currentPageIndex() + 1);

                    if (this.pageChanged != null)
                        this.pageChanged();
                }
            };

           this.prevPage = function () {
                if (this.currentPageIndex() > 1) {
                    this.currentPageIndex(this.currentPageIndex() - 1);

                    if (this.pageChanged != null)
                        this.pageChanged();
                }
            };

            this.pageChanged = null;

           this.noop = function() {};
        };
       
        this.pager = new cardsPagerModel();
        this.pager.pageChanged = function () {
            this.refreshCards();
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }.bind(this);

        this.load();
    };

    ko.components.register('cards-list', {
        template: { element: 'template-cards-list' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new cardsListModel(updateHeight, params.template);
            }
        }
    });
})(jQuery, ko);