(function (module) {
    
    module.ListStatementsViewModel = function(accountId, specifics, navigator, updateHeight) {
        module.ListViewModelBase.call(this, specifics, updateHeight);

        var dataSource = new module.StatementsDataSource(accountId, specifics);
        var self = this;

        self.filters = new module.ListFiltersStatementsViewModel(accountId, specifics, navigator);
        self.filters.selectedYearChanged = function (year) {
            self.gridItems(dataSource.getForYear(year));
        }

        module.postbox.subscribe(function (data) {
                dataSource.setData(data);
                self.filters.initialize(dataSource.getYears());
            },
            self,
            module.topics.documentsLoaded
        );
    }

})(window.docDelivery);