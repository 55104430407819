(function ($, ko) {
    var expenseEditManual = function (eobSummary) {
        this.eobSummary = eobSummary;

        this.files = ko.observableArray([]);
        this.Receipts = ko.observableArray(eobSummary.Receipts());

        this.initialized = ko.observable(false);

        this.detailsRequest = new $.cdh.post.expenses.details();
        this.editRequest = new $.cdh.post.expenses.editManual();
        this.addReceiptRequest = new $.cdh.post.receipts.image.addToExpense();
        this.deleteReceipt = false;

        this.claimants = ko.observableArray([]);

        this.detailsRequest.load({ expenseKey: eobSummary.ExpenseKey, originCde: eobSummary.OriginCde })
            .done(function(data) {
                var details = data.ExpenseDetails;
                var claimants = data.Claimants;
                this.claimants(claimants);
                this.expense = {
                    ServiceStartDate: ko.observable(details.ServiceStartDate).extend({ required: true }),
                    ServiceMinStartDate: ko.observable(null),
                    ServiceEndDate: ko.observable(details.ServiceEndDate).extend({ required: true }),
                    ServiceMinEndDate: ko.observable(null),
                    ProviderName: ko.observable(details.ProviderName).extend({ required: false, maxLength: 50 }),
                    Description: ko.observable(details.Description).extend({required: false, maxLength: 255}),
                    Billed: ko.observable(details.Billed).extend({ required: false, min: { params: 0.00, message: $.cdh.languageConstant.getConstant('messages.provider-amount-less-0') }  }),
                    Allowed: ko.observable(details.Allowed).extend({ required: true, min: { params: 0.00, message: $.cdh.languageConstant.getConstant('messages.insurance-amount-less-0') }  }),
                    Paid: ko.observable(details.Paid).extend({ required: false }),
                    PaidNonReimbursable: ko.observable(details.PaidNonReimbursable).extend({ required: false }),
                    Claimant: ko.observable(details.Claimant).extend({ required: true }),
                    Notes: ko.observable(details.Notes),
                    ExpenseKey: details.ExpenseKey,
                    OriginCde: details.OriginCde,
                    EmpeKey: ko.pureComputed(function() {
                            if (!claimants)
                                return null;
                            var baseCardholder = $.grep(claimants,
                                function(c) { return c.CardholderTypeCde == 1 })[0];
                            return baseCardholder.CardholderKey;
                        }, this)
                };

                this.expense.ServiceEndDate.extend({ dateGreaterThen: this.expense.ServiceStartDate });

                if (data.ShowEligibilityDate) {
                    this.expense.ServiceMinStartDate(data.EligibilityDate);
                    this.expense.ServiceMinEndDate(data.EligibilityDate);
                    this.expense.ServiceStartDate.extend({
                        dateGreaterThen: {
                            params: data.EligibilityDate,
                            message: $.cdh.languageConstant.getConstant('messages.service-equal-eligibility')
                        }
                    });
                } 

                this.validation = new $.cdh.validation(this.expense);

                this.expense.Responsibility = ko.pureComputed(function() {
                    if (typeof this.expense.Allowed() == 'undefined' && typeof this.expense.Paid() == 'undefined' && typeof this.expense.Billed() == 'undefined')
                        return NaN;
                    if (getDoubleValue(this.expense.Allowed()) > 0)
                        return (getDoubleValue(this.expense.Allowed()) - getDoubleValue(this.expense.Paid())).toFixed(2);
                    else
                        return (getDoubleValue(this.expense.Billed()) - getDoubleValue(this.expense.Paid())).toFixed(2);
                }, this).extend({ min: { params: 0.01, message: $.cdh.languageConstant.getConstant('messages.responsibility-greater-0') } });

                this.Reimbursed = ko.pureComputed(function() {
                    if (!isNaN(this.expense.Responsibility()))
                        return (0).toFixed(2);//???
                }, this);

                this.expense.RemainingResponsibility = ko.pureComputed(function() {
                    if (this.expense.PaidNonReimbursable() == 0 && this.expense.Responsibility() == NaN)
                        return NaN;
                    return (this.expense.Responsibility() - getDoubleValue(this.expense.PaidNonReimbursable()) - this.Reimbursed()).toFixed(2);
                }, this).extend({ min: { params: 0.01, message: $.cdh.languageConstant.getConstant('messages.remaining-responsibility-less-1') } });

                this.initialized(true);

            }.bind(this));

        function getDoubleValue(source) {
            var result = parseFloat(source);
            return isNaN(result) ? 0 : result;
        }

        this.setClose = function(closer) {
            this.close = closer;
        }.bind(this);

        this.clickClose = function() {
            this.close();
        }.bind(this);

        this.receipt = function (r) {
            return $.cdh.routes.receipts.image.get(r.FileKey);
        }.bind(this);

        this.onReceipt = function (r) {
            window.open(this.receipt(r));
        }.bind(this);

        this.canSubmit = ko.pureComputed(function() {
            return !this.validation.hasErrors() &&
                !this.editRequest.isLoading() &&
                !this.addReceiptRequest.isLoading()
        }, this);

        this.clickRemoveImage = function (image, e) {
            this.files.remove(image);
        }.bind(this);

        this.clickDeleteReceipt = function() {
            this.deleteReceipt = true;
            this.files([]);
            this.Receipts([]);
        }.bind(this);

        this.clickSave = function() {
            this.validation.activate();
            if (this.validation.hasErrors() || this.editRequest.isLoading())
                return;

            let model = {
                expense: this.expense,
                deleteReceipt: this.deleteReceipt
            };
            
            this.editRequest.load(model)
                .done(function () {
                    this.addReceipt(this.expense.ExpenseKey, function () {
                        if (this.eobSummary.reloadListCallback)
                            this.eobSummary.reloadListCallback();

                        this.close();
                    }.bind(this));
                }.bind(this));

        }.bind(this);

        this.allowedToAddReceipt = ko.pureComputed(function() {
            return this.Receipts().length == 0;
        }.bind(this));

        this.addReceipt = function (key, onDoneCallback) {

            if (this.files().length < 1) {
                if (onDoneCallback)
                    onDoneCallback();
                return;
            }

            var file = this.files()[0];
            var splittedDataUrl = file.dataUrl.split(/[,:;]/);
            var fileData = {
                FileName: file.fileName,
                ContentType: splittedDataUrl[1],
                Base64: splittedDataUrl[3]
            };

            this.addReceiptRequest.setData(null);
            this.addReceiptRequest.load({ file: fileData, expKey: key })
                .done(function () {
                    if (onDoneCallback)
                        onDoneCallback();
                }.bind(this));

        }.bind(this);
    }

    $.extend(true, $, {
        cdh: {
            models: {
                ExpenseEditManual: expenseEditManual
            }
        }
    });
})(jQuery, ko);
