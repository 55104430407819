import template from "./medicine-cabinet-prescription-editor-popup.html"

(function ($, ko) {
    ko.components.register('medicine-cabinet-prescription-editor-popup', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new PrescriptionEditorPopup(params, widgetInfo.updateHeight);
            }
        }
    });
    
    const steps = {
        search: 1,
        set: 2
    };

    function PrescriptionEditorPopup(params, updateHeight) {
        const self = this;
        
        self.currentStep = ko.observable();
        
        self.isSearchStep = ko.pureComputed(function () {
            return self.currentStep() === steps.search;
        });
        
        self.searchStepModel = {
            closeFunc: params.closeFunc,
            allPrescriptions: params.allPrescriptions,
            onNext: function (drug) {
                let prescription = {
                    Id: null,
                    Days: 0,
                    Price: 0,
                    Drug: drug
                };
                self.setStepModel.prescription(prescription);
                self.currentStep(steps.set);
            }
        }
        
        self.isSetStep = ko.pureComputed(function () {
            return self.currentStep() === steps.set;
        });

        self.setStepModel = {
            closeFunc: params.closeFunc,
            prescription: ko.observable(),
            isEdit: ko.observable(false),
            onBack: function() {
                self.currentStep(steps.search);  
            },
            onSubmit: function () {
                params.refreshCallback();
                params.closeFunc();
            }
        }
        
        let prescription = params.prescription;
        
        if($.cdh.helper.isValueSet(prescription)) {
            self.setStepModel.prescription(prescription);
            self.setStepModel.isEdit(true);
            self.currentStep(steps.set);
        } else {
            self.currentStep(steps.search);
        }
    }

})(jQuery, ko);