(function ($, ko) {
    ko.components.register('error', {
        template: '<!-- ko if: error() && !maintenance() --><div class="error-box">\n' +
            '        <p data-bind="html: error().message"></p>\n' +
            '        <p class="text-with-indent">\n' +
            '        <!-- ko if: $("#logout-form").length == 0 -->\n' +
            '        <a href ="/Authentication/Handshake"> Return to Login Page </a>\n' +
            '        <!-- /ko -->\n' +
            '        \n' +
            '        <!-- ko if: $.cdh.user.logoutVisible && $("#logout-form").length > 0-->\n' +
            '        Try to\n' +
            '        <a href="#" data-bind="click: $.cdh.helper.logout, clickBubble: false">relogin</a>\n' +
            '        or\n' +
            '        <!-- /ko -->\n' +
            '        <a href="#" data-bind="visible: $(`#logout-form`).length > 0, click: function(){ window.location.reload(true); }, clickBubble: false">reload</a> <span data-bind="visible: $(`#logout-form`).length > 0">page\n</span>' +
            '        </p>\n' +
            '       <!-- ko if: $.cdh.brandData.ContactPhone && $.cdh.brandData.ContactEmail -->\n' +
            '        <p data-bind="text: `If the problem remains, please contact us for further assistance at ${$.cdh.brandData.ContactPhone} or ${$.cdh.brandData.ContactEmail}`"></p>\n' +
            '        <!-- /ko -->\n' +
            '        <!-- ko if: $.cdh.brandData.ContactPhone && !$.cdh.brandData.ContactEmail -->\n' +
            '        <p data-bind="text: `If the problem remains, please contact us for further assistance at ${$.cdh.brandData.ContactPhone}`"></p>\n' +
            '        <!-- /ko -->\n' +
            '        <!-- ko if: !$.cdh.brandData.ContactPhone && $.cdh.brandData.ContactEmail -->\n' +
            '        <p data-bind="text: `If the problem remains, please contact us for further assistance at ${$.cdh.brandData.ContactEmail}`"></p>\n' +
            '        <!-- /ko -->\n' +
        '            </div><!-- /ko --> ',
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new Error(params, componentInfo, widgetInfo)
            }
        }
    });

    ko.components.register('popup-error', {
        template: '<!-- ko if: error() --><div style="word-break: break-word;" class="popup-form_error-title" role="alert"><span data-bind="html: resultErrorMessage" aria-live="assertive"></span></div><!-- /ko --> ',
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new Error(params, componentInfo, widgetInfo)
            }
        }
    });

    function Error(params, componentInfo, widgetInfo) {
        const self = this;
        self.error = params.error || params.dataService.error;
        self.error.subscribe(function () {
            widgetInfo.updateHeight();
            window.scrollTo(0, 0);
        });

        self.maintenance = $.cdh.global.maintenance;
        self.resultErrorMessage = ko.pureComputed(function () {
            return self.maintenance() ? $.cdh.customLabels.maintenanceErrorMessage : self.error().message;
        });
    }

})(jQuery, ko);