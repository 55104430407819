(function() {
    var iframes = window.frames["iframe_external_page"];//can be an array or a single object
    if (iframes) {
        Array.prototype.forEach.call(window.document.querySelectorAll("link[rel=stylesheet]"), function(link) {

            

            if (link.href.indexOf("CustomCss") > 0) {
                //if more than one iframe on the page we just subscribe them all
                if (iframes.length) {
                    Array.prototype.forEach.call(iframes, function(f) {
                        f.contentWindow.onload = function() {
                            var linkCustom = f.contentDocument.createElement("link");
                            linkCustom.type = 'text/css';
                            linkCustom.rel = link.rel;
                            linkCustom.href = link.href;
                            f.contentDocument.head.appendChild(linkCustom);

                            var linkFix = f.contentDocument.createElement('link');
                            linkFix.type = 'text/css';
                            linkFix.rel = 'stylesheet';
                            linkFix.href = '/Styles/external-fixes.css';
                            f.contentDocument.head.appendChild(linkFix);
                        }
                    });
                } else {
                    iframes.contentWindow.onload = function() {

                        var linkCustom = iframes.contentDocument.createElement("link");
                        linkCustom.type = 'text/css';
                        linkCustom.rel = link.rel;
                        linkCustom.href = link.href;
                        iframes.contentDocument.head.appendChild(linkCustom);

                        var linkFix = iframes.contentDocument.createElement('link');
                        linkFix.type = 'text/css';
                        linkFix.rel = 'stylesheet';
                        linkFix.href = '/Styles/external-fixes.css';
                        iframes.contentDocument.head.appendChild(linkFix);
                    };
                }
            }
        });
    }
})();