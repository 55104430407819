import template from "./accounts-list-filter.html"

(function ($, ko) {
    ko.components.register('accounts-list-filter', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountsListFilter(params);
            }
        }
    });
    
    function AccountsListFilter(params) {
        const self = this;
        var id = $.cdh.helper.getUniqueID('accounts_list_filter_');

        this.id = id;

        self.allAccountsLabel = ko.observable($.cdh.languageConstant.getConstant("AccountListFilter.lblAllAccount"));
        self.selectedAccount = ko.observable();
        self.submittedAccount = ko.observable().syncWith($.cdh.topics.accounts.listFilter, true);
        self.accountsFilterOptions = ko.observable().syncWith($.cdh.topics.accounts.listFilterOptions, true);
        self.label = ko.observable(self.allAccountsLabel());
        self.open = ko.observable(false);
        self.hideAllAccountsItem = (params.hideAllAccountsItem !== undefined) ? params.hideAllAccountsItem : false;

        let isTouchScreen = ('ontouchend' in window);

        this.onChange = function (account) {
            self.setAccount(account);
            
        };
        this.onSubmit = function () {
            self.submittedAccount(self.selectedAccount());
        };
        self.onParentClick = function () {
            self.open(!self.open());

            setTimeout(function() {
                var $menu = $('#' + id + '__menu');
                var $elToFocus;
                var $activeOption = $menu.find('li[aria-selected="true"]');
                var $fallbackOption = $menu.find('li').not('[aria-role="presentation"]').first();
                var $needleOption = $activeOption.length > 0 ? $activeOption : $fallbackOption;
                if(self.open()) {
                    $elToFocus = $needleOption.find('button').first();
                } else {
                    $elToFocus = $('#' + id + '__value_btn');
                }

                $elToFocus.trigger('focus');
            }, 0);
        };

        self.onParentMouseOver = function() {
            if (isTouchScreen) {
				return;
			}
            self.open(true);
        };

        self.onParentMouseOut = function() {
            if (isTouchScreen) {
				return;
			}
            self.open(false);
        };

        self.setAccount = function(account) {
            self.selectedAccount(account);
            self.label(self.formatName(account));
        };

        self.reset = function() {
            self.selectedAccount(null);
            self.label(self.allAccountsLabel());
        };

        self.accounts = $.cdh.accounts.initAccounts();

        self.any = ko.pureComputed(function() {
            return self.filteredAccounts().length > 0;
        });

        self.filteredAccounts = ko.pureComputed(function () {
            return $.grep(self.accounts.data(),
                function (a) {
                    if (!self.accountsFilterOptions()) {
                        return true;
                    }

                    let filterFunction = self.accountsFilterOptions().filterFunction;
                    if (!filterFunction) {
                        filterFunction = function () {
                            return true;
                        }
                    }
                    let selectedAccountId = self.accountsFilterOptions().selectedAccountId;

                    if ($.isFunction(filterFunction)) {
                        let isOk = filterFunction(a);
                        if (isOk && selectedAccountId && a.AccountId == selectedAccountId) {
                            self.setAccount(a);
                        }
                        return isOk;
                    }

                    return true;
                });
        });

        self.accountsCurrent = ko.pureComputed(function() {
            var currentAccounts = $.grep(self.filteredAccounts(),
                function(a) {
                    return a.AccountPlanYear === $.cdh.enums.planYear.current;
                });

            return sortPlanOnPriority(currentAccounts);
        });

        self.accountsPrevious = ko.pureComputed(function() {
            var previousAccount = $.grep(self.filteredAccounts(),
                function(a) {
                    return a.AccountPlanYear === $.cdh.enums.planYear.previous;
                });

            return sortPlanOnPriority(previousAccount);
        });

        self.accountsFuture = ko.pureComputed(function() {
            var futureAccounts = $.grep(self.filteredAccounts(),
                function(a) {
                    return a.AccountPlanYear === $.cdh.enums.planYear.future;
                });

            return sortPlanOnPriority(futureAccounts);
        });

        function sortPlanOnPriority(accounts) {
            accounts.sort(function (a, b) {
                if ((a.ProductPartnerAcctStatus != 1 || a.ProductPartnerAcctStatus != 4) && (b.ProductPartnerAcctStatus != 1 && b.ProductPartnerAcctStatus !=4))
                    if(a.Priority < b.Priority)
                        return -1;
                    else
                        return 1;

                else if ((a.ProductPartnerAcctStatus == 1 || a.ProductPartnerAcctStatus == 4) && (b.ProductPartnerAcctStatus != 1 && b.ProductPartnerAcctStatus != 4)) {
                    return 1;
                }
                else if ((b.ProductPartnerAcctStatus == 1 && b.ProductPartnerAcctStatus == 4) && (a.ProductPartnerAcctStatus != 1 || a.ProductPartnerAcctStatus != 4)) {
                    return -1;
                }

                else if (a.ProductPartnerAcctStatus == b.ProductPartnerAcctStatus) {
                    return a.Priority - b.Priority;
                }

                else {
                    if (a.ProductPartnerAcctStatus < b.ProductPartnerAcctStatus)
                        return -1;
                    else
                        return 1;
                }
            });

            return accounts;
        }

        self.formatName = function(a) {
            return a.AccountFullName + self.planText(a);
        };

        self.planText = function(a) {
            if (a.AccountPlanYear === $.cdh.enums.planYear.current)
                return String.prototype.concat(' (', $.cdh.languageConstant.getConstant('AccountListFilter.lblCurrentPlan'),')');
            else if (a.AccountPlanYear === $.cdh.enums.planYear.previous)
                return tring.prototype.concat(' (', $.cdh.languageConstant.getConstant('AccountListFilter.lblPriorPlan'), ')');
            else if (a.AccountPlanYear === $.cdh.enums.planYear.future)
                return tring.prototype.concat(' (', $.cdh.languageConstant.getConstant('AccountListFilter.lblFuturePlan'), ')');
        }

        self.afterRender = function(){
            $.cdh.helper.addKeyboardNavToMenu('#' + id + '__menu', 'li>button');
            var $menu = $('#' + id + '__menu');

            // self.open.subscribe(function (isOpened) {
            //     var $elToFocus;
            //     var $activeOption = $menu.find('li[aria-selected="true"]');
            //     var $fallbackOption = $menu.find('li').not('[aria-role="presentation"]').first();
            //     var $needleOption = $activeOption.length > 0 ? $activeOption : $fallbackOption;
            //     if(isOpened) {
            //         $elToFocus = $needleOption.find('button').first();
            //     } else {
            //         $elToFocus = $('#' + id + '__value_btn');
            //     }

            //     $elToFocus.trigger('focus');
            // });

            $menu.on('exit', () => {
                self.open(!self.open());
            });
            
        }
    }
})(jQuery, ko);