import template from './accounts-details-high-yield-hsa.html';
import { ParticipantOpportunitiesService } from "../../Http/SmartAccount/OpportunitiesService";

(function ($, ko) {

    function highYieldHsaModel(params, updateHeight, template) {
        let model = params.model;
        this.model = model;
        this.isTraditional = ko.observable(false);

        this.accountId = parseInt($.cdh.helper.urlParam('accountId'));
        this.options = parseInt($.cdh.helper.urlParam('options'));
        this.extendedDetails = new $.cdh.get.accounts.detailsHsa(this.accountId);
        this.highYieldOptIn = $.cdh.helper.urlParam('highYieldOptIn') === "true";
        const urlParams = {
            opportunityId: $.cdh.helper.urlParam('opportunityId'),
        };
        this.isHyOptIn = ko.observable(this.highYieldOptIn);
        this.isHyOptInSelected = ko.observable(false);
        this.updateAccountRequest = new $.cdh.post.accounts.updateHighYieldStatus();
        this.hsahyagreement = ko.observable(false);
        this.redirectUrl = $.cdh.routes.predefined.accounts.detailsHsa + '?accountId=' + this.accountId;
        this.isSwitched = ko.observable(false);

        this.canSignup = ko.pureComputed(function () {
            return !this.updateAccountRequest.isLoading() && this.isSwitched() && (this.isHyOptInSelected() ? this.hsahyagreement() : true);
        }, this);


        this.updateAccount = function () {
            this.updateAccountRequest.load({
                id: this.accountId,
                options: this.options,
                highYieldOptIn: this.isHyOptIn()
            }).done(function () {
                if (urlParams.opportunityId) {
                    let opportunitiesService = new ParticipantOpportunitiesService();
                    opportunitiesService.hideAsync(urlParams.opportunityId).then(() => {
                        window.location.href = this.redirectUrl;
                    });
                }
                else {
                    window.location.href = this.redirectUrl;
                }
            }.bind(this));
        };

        this.extendedDetails.load();
    }

    self.selectInterestRates = function (self, status) {

        self.isSwitched(!(self.highYieldOptIn === status));

        self.isHyOptIn(status);

        if (self.isSwitched()) {
            self.isHyOptInSelected(status);
        }
    }


    ko.components.register('accounts-details-high-yield-hsa',
        {
            template: template,
            viewModel: {
                createViewModel: function (params, componentInfo) {
                    var updateHeight = updateContainerHeight(componentInfo.element);
                    return new highYieldHsaModel(updateHeight, params.template);
                }
            }
        });
})(jQuery, ko);