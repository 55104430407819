import template from "./transactions-list-mini.html"

(function ($, ko) {
    ko.components.register('transactions-list-mini', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new TransactionsListMiniModel(widgetInfo.updateHeight, params.template);
            }
        }
    });
    
     function TransactionsListMiniModel(updateHeight, template) {
         const self = this;

         self.templateExtractor = new $.cdh.services.templateExtractor(template);

         self.transactions = new $.cdh.get.transaction.recent();
         self.transactions.state.subscribe(updateHeight);

         self.transactions.load({recordCount: 10});
     }
})(jQuery, ko);