(function ($, ko) {
    function HsaAdvance(params, componentInfo, updateHeight) {
        const self = this;

        const templateExtractor = new $.cdh.services.templateExtractor(params.template);
        this.infoLabel = templateExtractor.getLabel('InfoLabel', '');

        this.displayMessageNoEligible  = ko.observable(false);
        this.displayHsaAdvance  = ko.observable(false);
        this.accountId = $.cdh.helper.urlParam('accountId');
        this.options = 0;

        this.model = {
            Initial: ko.observable(0),
            DepositOffsets: ko.observable(0),
            OutstandingAmount: ko.observable(0),
            AvailableAmount: ko.observable(0),
            FutureYearInitial: ko.observable(0),
            Used: ko.observable(0),
            PendingAmount: ko.observable(0),
            Recovered: ko.observable(0),
            PendingAuthorizations: ko.observable(0)
        };

        this.basicDetails = new $.cdh.get.accounts.single(self.accountId);
        this.extendedDetails = new $.cdh.get.accounts.detailsHsa(self.accountId);

        this.aggregatedStatus = new $.cdh.models.aggregatedLoadStatus([
            this.basicDetails,
            this.extendedDetails
        ]);

        this.basicDetails.load().done(function () {
            applyModel(self.showCurrentYearData());

            const data = self.basicDetails.data();
            self.displayMessageNoEligible(!data.HsaAdvAccessibilityFlag);
            self.model.FutureYearInitial(data.FutureYearHsaAdvInitialAmt);
            self.displayHsaAdvance(data.HsaAdvEligibility);
        });
        this.extendedDetails.load().done(function (data) {
            applyModel(self.showCurrentYearData());
        });

        this.basicDetails.state.subscribe(updateHeight);
        this.extendedDetails.state.subscribe(updateHeight);

        this.addPaymentModelBuilder = function () {
            return new $.cdh.models.PaymentsAddPopup(self.accountId);
        };

        this.preferenceModelBuilder = function () {
            return new $.cdh.models.ClaimsAutoPaymentPrefs(self);
        };

        this.showCurrentYearData = ko.observable(true);
        this.showCurrentYearData.subscribe(function () {
            applyModel(self.showCurrentYearData());
            self.showPriorYearData(!self.showCurrentYearData());
        });

        this.showPriorYearData = ko.observable(false);
        this.showPriorYearData.subscribe(function () {
            applyModel(self.showCurrentYearData());
            self.showCurrentYearData(!self.showPriorYearData());
        });

        this.showPreference = ko.pureComputed(function () {
            const autoPaymentPrefAvailable = self.planAutoPaymentPreferenceEnabled() && $.cdh.employee.allowAutoPaymentPreference();
            const autoReimbursementPrefAvailable = self.planAutoReimbursementPreferenceEnabled() && $.cdh.employee.allowAutoReimbursementPreference();

            return autoPaymentPrefAvailable || autoReimbursementPrefAvailable;
        });

        this.planAutoPaymentPreferenceEnabled = ko.pureComputed(function () {
            if (!self.basicDetails.hasData())
                return false;
            return $.cdh.helper.hasFlag($.cdh.enums.mccFlags.claimsCrossover, self.basicDetails.data().PlanMccFlags);
        });

        this.planAutoReimbursementPreferenceEnabled = ko.pureComputed(function () {
            if (!self.basicDetails.hasData())
                return false;
            return $.cdh.helper.hasFlag($.cdh.enums.planExtOptions.allowAutoReimbSettings, self.basicDetails.data().PlanExtOptions);
        });

        function applyModel(currentYear) {

            if (!self.basicDetails.hasData() || !self.extendedDetails.hasData())
                return;

            if (typeof currentYear === 'undefined' || currentYear === null)
                currentYear = true;

            if (currentYear) {

                const exdata = self.extendedDetails.data();
                
                self.model.AvailableAmount(exdata.HsaAdvAvailBal);
                self.model.DepositOffsets(exdata.HsaAdvDepositOffsetYtd);
                self.model.Recovered(exdata.HsaAdvRecoveredYtd);
                self.model.Initial(exdata.HsaAdvInitialAmt);
                self.model.OutstandingAmount(exdata.HsaAdvOutstandingBal);
                self.model.Used(exdata.HsaAdvUsedYtd);
                self.model.PendingAuthorizations(exdata.PendingAuthorizations);
            } else {
                const data = self.basicDetails.data();
                
                self.model.AvailableAmount(data.PriorYearHsaAdvAvailBal);
                self.model.DepositOffsets(data.PriorYearHsaAdvDepositOffsetYtd);
                self.model.Recovered(data.PriorYearHsaAdvRecoveredYtd);
                self.model.Initial(data.PriorYearHsaAdvInitialAmt);
                self.model.OutstandingAmount(data.PriorYearHsaAdvOutstandingBal);
                self.model.Used(data.PriorYearHsaAdvUsedYtd);
                self.model.PendingAuthorizations(0);
            }
        }
    }

    ko.components.register('hsa-advance', {
        template: { element: 'template-accounts-hsa-advance' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new HsaAdvance(params, componentInfo, updateHeight);
            }
        }
    });
})(jQuery, ko);