import {DataServiceBase} from "../DataServiceBase";

let singleInstance;

export class AccountsService extends DataServiceBase {
    constructor() {
        if (singleInstance) {
            return singleInstance;
        }
        
        super('/Accounts');
        this.getListPromise = null;
        
        singleInstance = this;
    }
    
    clearCache() {
        this.getListPromise = null;
    };

    async getListAsync(planYear) {
        if (!this.getListPromise) {
            this.getListPromise = this.getAsync({planYear: $.cdh.enums.planYear.all}, 'List');
        }
        let result = await this.getListPromise;
        let accounts = result.data();

        return planYear && planYear !== $.cdh.enums.planYear.all ? 
            accounts.filter(a => a.AccountPlanYear === planYear) : 
            accounts;
    };

    async getListHsaAsync(planYear) {
        let accounts = await this.getListAsync(planYear);
        
        return accounts.filter(a => a.AccountType === $.cdh.enums.accountType.hsa);
    };

    async getListInvestmentAsync() {
        let accounts = await this.getListAsync();

        return accounts.filter(a => a.InvestmentState !== $.cdh.enums.investmentSolutionStates.none);
    };

    getListHsaCurrentAsync() {
        return this.getListHsaAsync($.cdh.enums.planYear.current);
    };

    async anyHsaCurrentAsync() {
        let accounts = await this.getListHsaCurrentAsync();
        
        return accounts.length > 0;
    };

    async byAccountIdAsync(accountId) {
        let accounts = await this.getListAsync();
        
        let validAccountId = (typeof accountId === 'string' || accountId instanceof String) ? parseInt(accountId) : accountId;   
        return accounts.find(a => a.AccountId === validAccountId);
    };

    async countCurrentAsync() {
        let accounts = await this.getListAsync();
        
        return accounts.filter(a => this.isCurrentAccountByDates(a)).length;
    };

    isCurrentAccountByDates(account) {
        let start = moment.utc(account.PlanStartDate);
        let end = moment.utc(account.PlanEndDate);
        let date = moment.utc();
        
        return start < date && date < end;
    };
}