import template from "./account-activity-tc-claim-info.html"

(function ($, ko) {
    ko.components.register('account-activity-tc-claim-info', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityTcClaimInfo(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityTcClaimInfo(params, updateHeight) {
        const self = this;
        let model = params.model;

        self.model = model;
        //Bug - 265904: Changing the Mapping from SettlementDate to Date field from getEmployeeActivity
        self.transactionDate = model.Date;
        self.serviceDate = model.ServiceStartDate;
        self.provider = model.Provider;
        self.claimant = model.Claimant;
        self.claimId = model.ClaimId;
        self.serviceCode = model.ServiceCategoryCode;
        self.description = model.ServiceCategoryName;
        self.patientAccount = model.PatientAccountNumber;
        self.trackingNumber = model.TrackingNumber;
        if (model.ReimbursementDetails && model.ReimbursementDetails.length > 0) {
            let reimbursementDetail = model.ReimbursementDetails[0];
            self.paymentId = reimbursementDetail.PaymentId;
        } else {
            self.paymentId = null;
        }
    }

})(jQuery, ko);