(function ($, ko) {
    $.cdh.userPic = (function () {
        const self = {};

        self.update = function (url) {
            if (url == null || url.length === 0)
                url = $.cdh.defaultUserPic;

            $.cdh.user.avatar(url);
        };

        self.isEmpty = function () {
            return $.cdh.user.avatar() === $.cdh.defaultUserPic;
        };

        return self;
    })();
    
})(window.jQuery, window.ko);