import template from "./claim-validation-list.html"

(function ($, ko) {
    ko.components.register('claim-validation-list', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new ClaimsValidationListModel(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function ClaimsValidationListModel(params, updateHeight){
        this.updateHeight = updateHeight;
        this.widget = params.widget;
        this.entry = params.entry;
        this.title = $.cdh.helper.titlePage();

        this.ClaimValidationLabel = {
            lblClaimDetails: $.cdh.languageConstant.getConstant('field.claim-details'),
            lblClaimDocumentation: $.cdh.languageConstant.getConstant('claim.documentation'),
            lblClaimSubmission: $.cdh.languageConstant.getConstant('claim.confirm-submission'),
            lblValidationMethod: $.cdh.languageConstant.getConstant('claim.choose-validation-method'),
            lblAttachReceipt: $.cdh.languageConstant.getConstant('claim.attach-receipt'),
            lblAttachExistingDoc: $.cdh.languageConstant.getConstant('claim.take-photo-or-attach-existing-document'),
            lblValidateLater: $.cdh.languageConstant.getConstant('claim.validate-later'),
            lblSubmitWithoutReceipt: $.cdh.languageConstant.getConstant('claim.submit-without-receipt'),
            lblCancel: $.cdh.languageConstant.getConstant('button.cancel'),
            lblProviderESignature: $.cdh.languageConstant.getConstant('claim.ProviderESignature'),
            lblESignatureDescription: $.cdh.languageConstant.getConstant('claim.ESignatureDescription'),
        };

        this.eSignatureOptionLabel = this.widget.data().Template.ESignatureOptionLabel;
        this.eSignatureDescription = this.widget.data().Template.ESignatureDescription;
        this.eSignatureAgreement = this.widget.data().Template.ESignatureAgreement ||
            'I, as the service provider, certify that the charges listed above are accurate and were incurred during the services dates specified. I hereby provide my electronic signature as acknowledgement.';
        
        this.currentStepModel = ko.observable(null).extend({ required: {params: true, message: 'You should select a validation method'}});
        
        this.allowReceipt = ko.pureComputed(function(){
            return this.widget.data().ReceiptsCanBeAttached;
        }, this);

        this.allowESignature = ko.pureComputed(function () {
            if(this.entry.useRecuring())
                return false;

            var code = this.entry.scc();
            if(!code)
                return false;
            return this.widget.data().Template.AllowESignature && code.AllowESignature;
        }, this);

        this.allowToValidateLater = ko.pureComputed(function(){
            return !this.widget.data().IsReceiptsRequired;
        }, this);
        
        this.onAttachReceiptClicked = function(){
            this.currentStepModel(new attachReceiptStepModel(this));
        }.bind(this);

        this.onESignatureClicked = function(){
            this.currentStepModel(new signatureStepModel(this));
        }.bind(this);

        this.onValidateLaterClicked = function(){
            this.currentStepModel(new validateLaterStepModel(this));
            this.onNext();
        }.bind(this);

        this.onNext = function(){
            if($.isFunction(params.onNext)) {
                if(this.currentStepModel().isAttachReceiptView){
                    params.onNext("receipts", this.currentStepModel().receipts);
                }
                else if(this.currentStepModel().isSignatureView){
                    params.onNext("signature", this.currentStepModel().signModel());
                }
                else{
                    params.onNext("validateLater", null);
                }

                setTimeout(this.updateHeight, 600);                
            }
        }.bind(this);

        this.onCancel = function(){
            if($.isFunction(params.onCancel))
                params.onCancel();
        };
        
        this.currentStepModel.subscribe(function(val){
            $.cdh.helper.titleStepModelHandler(val, this.title);
            setTimeout(this.updateHeight, 600);
        }, this);
        
        this.isMainView = ko.pureComputed(function(){
            return this.currentStepModel() === null;
        }, this);
        
        this.isAttachReceiptView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isAttachReceiptView;
        }, this);
        
        this.isSignatureView = ko.pureComputed(function(){
            return this.currentStepModel() != null && this.currentStepModel().isSignatureView;
        }, this);
        
        if(this.widget.data().SkipValidation){
            this.currentStepModel(new validateLaterStepModel(this));
            this.onNext();
        }
    }

    function attachReceiptStepModel(model){
        this.isAttachReceiptView = true;
        this.widget = model.widget;
        this.title = 'Add Receipt';
        
        this.onCancel = function(){
            model.currentStepModel(null);
        }.bind(this);

        this.onNext = function(receipts){
            this.receipts = receipts;
            model.onNext();
        }.bind(this);
    }
    
    function signatureStepModel(model){
        this.isSignatureView = true;
        this.widget = model.widget;
        this.entry = model.entry;
        this.signModel = ko.observable(null);
        
        this.onNext = function(signModel){
            this.signModel(signModel);
            model.onNext();
        }.bind(this);
        
        this.onCancel = function(){
            model.currentStepModel(null);
        };
    }

    function validateLaterStepModel(model){
        this.isValidateLaterView = true;
        this.widget = model.widget;
        this.entry = model.entry;
        
        this.onNext = function(){
            model.onNext();
        };
        this.onCancel = function(){ 
            model.onCancel;
        }
    }
})(jQuery, ko);