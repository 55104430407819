(function ($, ko) {
    var accountsHsaApplication = function (updateHeight, template) {        

        this.templateExtractor = new $.cdh.services.templateExtractor(template);

        this.uploadDocumentQueuePopupModel = new $.cdh.models
            .UploadDocumentQueuePopupModel(null, updateHeight);
    }

    ko.components.register('hsa-application', {
        template: { element: 'template-hsa-application' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new accountsHsaApplication(updateHeight, params.template);
            }
        }
    });
})(jQuery, ko);