(function ($) {

    var dependentWrapper = function(countries, states, addressModel) {
        this.viewModel = function() {
            return this.data;
        }.bind(this);

        this.vm = {
            states: states,
            countries: countries
        }

        this.data = {};

        addressModel.subscribe(function(data) {
            $.extend(this.data, data);
            this.data.Address1 = this.data.AddressLine1;
            this.data.Address2 = this.data.AddressLine2;
        }, this);
    }
    
    var authorizedSignerListModel = function (countries, states, addressModel, planOptions, template, isHsa, flexAcctId, updateHeight) {

        this.profile = new dependentWrapper(countries, states, addressModel);

        this.template = template;
        this.hasPermissionsToViewDependents = planOptions.allowAssociatedDependent;
        this.hasPermissionsToAddDependent = planOptions.allowAssociatedDependent && 
            ($.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionAllowParticipantAddDependent) 
            || ($.cdh.user.isAnonymousEnrollmentUser && $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionDemographicSelfCreationViaOnlineEnrollment)));
        this.isHsa = isHsa;
        this.isPlanCompleted = planOptions.isCompleted;
        this.dependents = new $.cdh.get.profile.dependents(function (data) {
            var dependents = $.map(data, function (dependent) {
                var model = new $.cdh.models.AuthorizedSignerItem(dependent, this.profile,
                    this.template,
                    this.isPlanCompleted,
                    updateHeight,
                    this.onSuccessCallback, this.onSuccessCallback);
                model.dependentTitle(template.Demographics.DepSectionHeader.Value);
                    return model;
            }.bind(this));
            return { array: ko.observableArray(dependents) };
        }.bind(this));

        this.onSuccessCallback = function() {
            this.load();
        }.bind(this);

        this.addModel = new $.cdh.models.addEditEnrollmentDependentModel({ profile: this.profile, data: {}, details: {} },
            updateHeight, false, false, template, this.onSuccessCallback);

        this.load = function () {
            if (this.hasPermissionsToViewDependents) {
                var data = isHsa
                    ? { ageRestrict: 18 }
                    : {};
                this.dependents.load(data);
            }
        }.bind(this);

        this.dependents.state.subscribe(updateHeight);
    }

    $.extend(true, $, {
        cdh: {
            models: {
                AuthorizedSignerList: authorizedSignerListModel
            }
        }
    });

})(jQuery);