import template from "./account-activity-card-payment.html"

(function ($, ko) {
    ko.components.register('account-activity-card-payment', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new AccountActivityCardPayment(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function AccountActivityCardPayment(params, updateHeight) {
        const self = this;
        
        let model = params.model;
        
        self.total = model.Amount;
        self.posted = model.CardTransactionDetails.PostedAmount;
        self.approved = model.CardTransactionDetails.ApprovedAmount;
    }

})(jQuery, ko);