import template from "./account-activity-hsa-transaction-info.html"

ko.components.register('account-activity-hsa-transaction-info', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new TransactionInfo(params, widgetInfo);
        }
    }
});

export class TransactionInfo {
    constructor(params, widgetInfo) {
        let model = params.model;

        this.model = model;
        this.date = model.FullDate;
        this.customDescription = model.CustomDescription;
        this.hsaTransactionType = model.HsaTransactionDetail?.HsaTransactionType;
        this.transactionType = model.HsaTransactionDetail?.TransactionType;
        this.serviceStartDate = model.ServiceStartDate;
        if (model.ReimbursementDetails && model.ReimbursementDetails.length > 0) {
            let reimbursementDetail = model.ReimbursementDetails[0];
            self.reimbursementMethod = reimbursementDetail.Method;
            self.paymentId = reimbursementDetail.PaymentId;
            self.payoutMethod = reimbursementDetail.PayoutMethod;
        } else {
            self.reimbursementMethod = null;
            self.paymentId = null;
            self.payoutMethod = null;
        }
    }
}