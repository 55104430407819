(function ($, ko) {

    /**
     * @constructor
     * @param {number} accountId - preselected account
     * @param {function} onSuccessAdd
     */
    var paymentsAddPopup = function (accountId, onSuccessAdd, expense, updateHeight, fromClaimList) {

        if (!updateHeight) {
            updateHeight = function(){};
        }

        this.onPaymentCancel = null;
       
        this.fromClaimList = fromClaimList;

        this.addPaymentLabels = {
            lbladdPayment: $.cdh.languageConstant.getConstant('title.add-payment'),
            lblEnterProviderInfo: $.cdh.languageConstant.getConstant('payment.enter-provider-information'),
            lblWarningAmountExceeds: $.cdh.languageConstant.getConstant('payment.warning-amount-exceeds'),
            lblHsaBillPayDeposit: $.cdh.languageConstant.getConstant('title.hsa-bill-pay-deposit')
        };

        this.expense = expense;
        this.isExpense57 = function () {
            return this.expense != null && this.expense.OriginCde == 57;
        }.bind(this);

        this.resetAfterClose = true;

        this.closeAddProvider = function () {
            this.showAdd(true);
            this.showAddProvider(false);
            this.exceedAmount(false);
            this.showAddDepositAccount(false);
            this.resetNewProviderFields();
            updateHeight();
        }.bind(this);

        this.scrollPageToTop = function () {
            if ($.cdh.isMobile) {
                $.cdh.helper.scrollPageToTop();
            }
        }.bind(this);
        
        this.addProviderModel = new $.cdh.models.AddProviderModel(
            function (provider) {
                this.cleanUpErrors();
                this.payment.PaymentPayee(provider);
                this.payees.load().done(function () {
                    this.payment.PaymentPayee(provider);
                }.bind(this));
                this.closeAddProvider();
                this.addProviderModel.createAutoPopulatedProvider(false);
            }.bind(this),
            function () {
                this.closeAddProvider();
                this.payment.PayTo($.cdh.enums.payTo.payMe);
            }.bind(this)
        );

        this.resetNewProviderFields = function () {
            if (this.addProviderModel.createAutoPopulatedProvider()) {
                this.populateProviderModel(this.detailsRequest.data().ExpenseDetails);
                this.addProviderModel.validation.activate();
                this.addProviderModel.payProviderReadonly(!this.addProviderModel.validation.hasErrors());
                this.addProviderModel.validation.activated(false);
            } else {
                this.addProviderModel.reset();
            }
        };

        this.addRequest = new $.cdh.post.payments.add();
        this.addRequest.state.subscribe(function () {
            updateHeight();
        }.bind(this));
        this.deleteProviderRequest = new $.cdh.post.payments.deleteProvider();

        this.preview = ko.observable(false);
        this.success = ko.observable(false);

        this.addProvider = { PayeeKey: -1, PayeeName: $.cdh.languageConstant.getConstant('payment.add-new-provider') };
        this.payees = new $.cdh.get.payments.getPayees(function (data) {
            data.unshift(this.addProvider);
            $.each(data, function(i, payee) {
                payee.isDeleting = ko.observable(false);
                payee.clickDelete = function (p) {
                    return this.clickDeleteProvider(p);
                }.bind(this);
            }.bind(this));
            return data;
        }.bind(this));

        this.accounts = $.cdh.accounts.initAccounts();

        this.dataPartnerCustomText = new $.cdh.get.payments.getText();
        this.dataPartnerCustomText.data.subscribe(function() {
            updateHeight();
        });
        this.close = function () { };
        this.exceedAmount = ko.observable(false);       
        this.showAddProvider = ko.observable(false);        
        this.showAddDepositAccount = ko.observable(false);
        this.showMessageForDirectDeposit = ko.observable(false);

        this.showAddProvider.subscribe(function () {
            updateHeight();
        });
        this.showMessageForDirectDeposit.subscribe(function () {
            updateHeight();
        });

        this.showAdd = ko.observable(true);
        this.pageTitle = ko.pureComputed(function () {
            var title = this.showAdd() ? this.addPaymentLabels.lbladdPayment 
                : this.showAddProvider() ? this.addPaymentLabels.lblEnterProviderInfo
                    : this.exceedAmount() ? this.addPaymentLabels.lblWarningAmountExceeds
                        : this.showAddDepositAccount() ? this.addPaymentLabels.lblHsaBillPayDeposit
                        : '';
            $.cdh.helper.titlePage(title);
            return title;
        }, this);
     
        this.init = function () {
            this.payees.loadIfNoData();
            this.addProviderModel.load();

            var e = this.expense;
            if (e != null) {
                var pm = this.payment;
                var dt = expense.ServiceStartDate;

                pm.BulkSubmit(e.EOBType == 'ExpenseGroup');
                pm.ExpenseKeys(e.EOBExtra.ExpenseKeys);
                pm.PaymentAmount(e.Amount);
                pm.PaymentComment(expense.EOBNotes);
                pm.PaymentDate(dt);
                pm.RepeatUntilDate(dt);
                pm.ReimbSub(e.OriginCde === 56 || e.OriginCde === 57);
            }

            updateHeight();
        }.bind(this);

        this.preRender = this.init;

        this.enabledAccounts = ko.pureComputed(function () {
            return $.grep(this.accounts.data(),
                function (a) { return a.BillPayEnabled && a.AccountPlanYear === $.cdh.enums.planYear.current; });
        }, this);

        this.selectedAccount = ko.observable({});

        var setAccount = function (accounts, accId) {
            var suitable = $.grep(accounts,
                function (a) { return a.AccountId == accId; });
            if (suitable.length > 0) {
                this.selectedAccount(suitable[0]);
            } else if (accId) {
                this.accounts.error($.cdh.languageConstant.getConstant('messages.account-not-found-1', { '0': accId }));
            }
        }.bind(this);

        this.enabledAccounts.subscribe(function (accounts) {
            var preselectedId = this.selectedAccount().AccountId || accountId;
            setAccount(accounts, preselectedId);
            updateHeight();
        }, this);

        this.AccountEndDate = ko.observable('');
        this.AccountStartDate = ko.observable('');

        this.selectedAccount.subscribe(function (value) {
            if (value) this.dataPartnerCustomText.load({dataPartnerId : value.DataPartnerId});
            this.AccountEndDate(value.PlanEndDate);
            this.AccountStartDate(value.PlanStartDate);
            updateHeight();
        }, this);

        this.payment = {
            BulkSubmit: ko.observable(false),
            ReimbSub: ko.observable(false),
            ExpenseKeys: ko.observableArray(null),
            AccountId: ko.pureComputed(function () { return this.selectedAccount().AccountId }, this),
            AccountTypeCode: ko.pureComputed(function () { return this.selectedAccount().AccountName }, this),
            IsAccountHsa: ko.pureComputed(function() { return this.selectedAccount().IsHsa; }, this),
            DataPartnerId: ko.pureComputed(function () { return this.selectedAccount().DataPartnerId }, this),
            PayTo: ko.observable($.cdh.enums.payTo.payMe),
            PaymentAmount: ko.observable('').extend({ required: true, min: 0.01 }),
            PaymentPayee: ko.observable(),
            PayeeAccount: ko.observable(),
            PaymentDate: ko.observable('').extend({
                required: true,
                betWeenDates: { params: [this.AccountStartDate, this.AccountEndDate], message: $.cdh.languageConstant.getConstant("messages.service-dates-plan-start-end") }
            }),
            PaymentComment: ko.observable(''),
            PaymentRecurrenceFrequency: ko.observable($.cdh.enums.paymentRecurrenceFrequency.once),
            PaymentRecurrenceType: ko.observable($.cdh.enums.paymentRecurrenceType.totalCount),
            BankAccountAllowed: ko.observable(false),
            Agree : ko.observable(false).extend({ checked: {} })
        };
        
        this.payment.ReimbursementMethod = ko.observable().extend({
            required: {
                onlyIf: function () {
                    return this.payment && this.payment.PayTo() == $.cdh.enums.payTo.payMe;
                }.bind(this)
            }});

        this.payment.PaymentPayee = ko.observable().extend({
            required: {
                onlyIf: function () { return !$.cdh.user.impersonated && this.PayTo() === $.cdh.enums.payTo.paySomeoneElse; }.bind(this.payment)
            }
        });

        this.payment.BankAccountAllowed = ko.observable().extend({
            required: {
                onlyIf: function () {
                    if (this.payment.PayTo() != $.cdh.enums.payTo.payMe)
                        return false;

                    var method = this.payment.ReimbursementMethod();

                    if (this.editReimbursementModel){
                        return method === $.cdh.enums.reimbursementMethod.directDeposit &&
                            this.editReimbursementModel().profile.viewModel().ReimbursementMethod != $.cdh.enums.reimbursementMethod.directDeposit;
                    }                   
                }.bind(this),
                message: $.cdh.languageConstant.getConstant("messages.add-deposit-account")
            }
        });

        this.payment.PaymentPayee.subscribe(function (newPayee) {
            if (newPayee && newPayee === this.addProvider) {
                this.payment.PaymentPayee(undefined);
                this.showAddProvider(true);
                this.showAdd(false);
                this.scrollPageToTop();
            }
        }.bind(this));

        this.payment.PayTo.subscribe(function (newPayee) {
            if (newPayee === $.cdh.enums.payTo.paySomeoneElse
                && this.addProviderModel.createAutoPopulatedProvider()) {
                this.payment.PaymentPayee(this.addProvider);
                this.showAddProvider(true);
                this.showAdd(false);
                this.scrollPageToTop();
            }
            updateHeight();
        }.bind(this));

        this.payment.ReimbursementMethod.subscribe(function () {
            updateHeight();
        }.bind(this));

        this.payment.PaymentRecurrenceFrequency.subscribe(function () {
            updateHeight();
        }.bind(this));
        
        this.displaySomeOneElseNote = ko.pureComputed(function () {
            return this.payment.PayTo() === $.cdh.enums.payTo.paySomeoneElse && this.payment.PaymentPayee();
        }, this);

        this.someoneElseNote = ko.pureComputed(function () {
            if(!this.displaySomeOneElseNote()) {
                return null;
            }

            return $.cdh.customLabels.hsaBillPaySomeoneElseNote.replace(/{PayeeName}/g, this.payment.PaymentPayee().PayeeName)
        }, this);
        
        this.displayMeDepositNote = ko.pureComputed(function () {
            return this.payment.PayTo() === $.cdh.enums.payTo.payMe && 
                this.payment.ReimbursementMethod() === $.cdh.enums.reimbursementMethod.directDeposit;
        }, this);

        this.displayMeCheck = ko.pureComputed(function () {
            return this.payment.PayTo() === $.cdh.enums.payTo.payMe &&
                this.payment.ReimbursementMethod() === $.cdh.enums.reimbursementMethod.check;
        }, this);
        
        this.displayNote = ko.pureComputed(function () {
            return this.displaySomeOneElseNote() || this.displayMeDepositNote() || this.displayMeCheck();
        }, this);

        this.minPaymentDate = ko.observable(moment().add(1, 'day').toDate());
        this.minUntilPaymentDate = ko.pureComputed(function () {
            if (!this.payment.PaymentDate()) {
                return moment().add(1, 'day').toDate();
            }
            return moment(this.payment.PaymentDate()).add(1, 'day').toDate();
        }, this);
        
        this.allowedDirectDepositInProfile = ko.pureComputed(() => 
            this.editReimbursementModel()?.profile.viewModel().ReimbursementMethodList.some(m => m.ReimbursementMethod === $.cdh.enums.reimbursementMethod.directDeposit));

        this.disabledDirectDepositMessage = ko.pureComputed(() => 
            this.allowedDirectDepositInProfile() ? $.cdh.languageConstant.getConstant('claim.has-no-right') : $.cdh.languageConstant.getConstant('messages.direct-deposit-valid-reimbursement'));
        
        this.editReimbursementModel = ko.observable();
        this.enabledDirectDeposit = ko.observable(true);
        
        this.setDefaultReimbursementMethod = function() {
            var status = this.editReimbursementModel().profile.viewModel().BankAccountValidationStatus;
            
            var isDisabledDirectDeposit = this.payment.IsAccountHsa() &&
                this.payment.PayTo() === $.cdh.enums.payTo.payMe &&
                (status === $.cdh.enums.bankAccountValidationStatus.validationRequired || 
                    status === $.cdh.enums.bankAccountValidationStatus.pendingValidation ||
                    status === $.cdh.enums.bankAccountValidationStatus.validationFailed) ||
                (this.isAddDepositEnabled() && !this.allowedDirectDepositInProfile());

            if (isDisabledDirectDeposit) {
                this.enabledDirectDeposit(false);
                this.payment.ReimbursementMethod($.cdh.enums.reimbursementMethod.check);
            } else {
                var userReimbursementMethod = this.editReimbursementModel().profile.viewModel().ReimbursementMethod;

                if (userReimbursementMethod !== $.cdh.enums.reimbursementMethod.none)
                    this.payment.ReimbursementMethod(userReimbursementMethod);
            }
        }.bind(this);
        
        this.editReimbursement = new $.cdh.get.profile.get(function (data) {
            var profile = ko.observable({viewModel: ko.observable({})})();
            profile.viewModel(data);

            // update showMessageForDirectDeposit
            // check for empty bank account && reimbursementMethod
            if (data.ReimbursementMethod !== $.cdh.enums.reimbursementMethod.directDeposit
                || !data.Bank
                || !data.BankRoutingNumber
                || !data.BankAccountNumber) {

                // check for permission
                if ($.cdh.employee.allowedToUpdateDirectDepositInformation() &&
                    $.cdh.employee.allowedToViewDirectDepositInformation()) {
                    this.showMessageForDirectDeposit(true);
                }
            }

            this.editReimbursementModel(new $.cdh.models.ProfileReimbursementMethodEditPopup(profile, true, updateHeight, updateHeight));
            this.editReimbursementModel().preRender();
            this.editReimbursementModel().updateUserReimbursementMethod(false);
            this.editReimbursementModel().setClose(function () {
                this.showAdd(true);
                this.showAddDepositAccount(false);
                this.editReimbursementModel().method.Agree(false);
                updateHeight();
            }.bind(this));

        }.bind(this));

        this.editReimbursement.load();

        var notLoadedProfileAndAccounts = true;
        ko.computed(function() {
            if (notLoadedProfileAndAccounts &&
                this.editReimbursementModel() &&
                this.editReimbursementModel().profile &&
                this.payment.IsAccountHsa() !== undefined) {

                notLoadedProfileAndAccounts = false;
                this.setDefaultReimbursementMethod();
            }
        }, this);

        var recurrenceCountOnlyIf = function () {
            return this.PaymentRecurrenceFrequency() !== $.cdh.enums.paymentRecurrenceFrequency.once &&
                this.PaymentRecurrenceType() === $.cdh.enums.paymentRecurrenceType.totalCount;
        }.bind(this.payment);

        this.payment.RecurrenceTotalCount = ko.observable(1).extend({
            required: { onlyIf: recurrenceCountOnlyIf },
            min: { params: 1, onlyIf: recurrenceCountOnlyIf }
        });
        this.payment.RepeatUntilDate = ko.observable('').extend({
            required: {
                onlyIf: function () {
                    return this.PaymentRecurrenceFrequency() !== $.cdh.enums.paymentRecurrenceFrequency.once &&
                        this.PaymentRecurrenceType() === $.cdh.enums.paymentRecurrenceType.untilDate;
                }.bind(this.payment)
            }
        });

        this.validation = new $.cdh.validation(this.payment);

        if (this.isExpense57()) {
            this.payment.UpdatePayProvider = null;
            this.populateProviderModel = function(edtls) {
                this.addProviderModel.reset({
                    ProviderName: edtls.ProviderName,
                    Addr1: edtls.ProviderAddress1,
                    Addr2: edtls.ProviderAddress2,
                    City: edtls.ProviderCity,
                    State: edtls.ProviderState,
                    Zip: edtls.ProviderZip
                });
            }.bind(this);

            var initializeDefaultProviderInfo = function() {
                if ($.cdh.user.impersonated) {
                    return;
                }

                var details = this.detailsRequest.data();
                var payees = this.payees.data();
                if (!details || !details.ExpenseDetails || !payees || payees.length === 0) {
                    return;
                } else {
                    var edtls = details.ExpenseDetails;
                    if (edtls.ProviderName === "PRIVATE" || edtls.ProviderName === "Provider Not Found") {
                        return;
                    }
                    this.addProviderModel.payProviderReadonly(true);
                    var foundProviders = $.grep(payees,
                        function(pro) {
                            return edtls.ProviderName === pro.PayeeName;
                        });
                    if (foundProviders.length > 0) {
                        var provider = foundProviders[0];
                        this.payment.PaymentPayee(provider);
                        this.payment.UpdatePayProvider = {
                            ProviderName: edtls.ProviderName,
                            Addr1: edtls.ProviderAddress1,
                            Addr2: edtls.ProviderAddress2,
                            City: edtls.ProviderCity,
                            State: edtls.ProviderState,
                            Zip: edtls.ProviderZip,
                            Phone: edtls.ProviderPhone,
                            PayProviderKey: provider.PayeeKey,
                            ProviderId: provider.PayeeId
                        };
                    } else {
                        this.populateProviderModel(edtls);
                        this.addProviderModel.validation.activate();
                        this.addProviderModel.payProviderReadonly(!this.addProviderModel.validation.hasErrors());
                        this.addProviderModel.validation.activated(false);
                        this.addProviderModel.createAutoPopulatedProvider(true);

                        if (this.payment.PayTo() == $.cdh.enums.payTo.paySomeoneElse) {
                            if (this.payment.PaymentPayee() === undefined) {
                                this.payment.PaymentPayee(this.addProvider);
                                this.showAddProvider(true);
                                this.showAdd(false);
                                this.scrollPageToTop();
                            }
                        }
                    }
                }
            }.bind(this);

            var detailsRequest = new $.cdh.post.expenses.details();
            detailsRequest.load({
                expenseKey: expense.ExpenseKey,
                originCde: expense.OriginCde
            });
            this.detailsRequest = detailsRequest;
            this.detailsRequest.data.subscribe(initializeDefaultProviderInfo);
            this.payees.data.subscribe(initializeDefaultProviderInfo);

            this.payeesWithDetailsRequests = new $.cdh.models.aggregatedLoadStatus([
                this.detailsRequest, this.payees, this.editReimbursement
            ]);
        } else {
            //TODO: No details request for new bill pay
            this.payeesWithDetailsRequests = new $.cdh.models.aggregatedLoadStatus([
                this.payees, this.editReimbursement
            ]);
        }

        this.canSubmit = ko.pureComputed(function () {
            return !this.addRequest.isLoading() &&
                !this.payees.isLoading() &&
                !this.accounts.isLoading() &&
                !this.validation.hasErrors() &&
                !this.addProviderModel.addProviderRequest.isLoading() &&
                !this.editReimbursement.isLoading();
        }, this);

        this.reset = function () {
            this.validation.activated(false);
            setAccount(this.enabledAccounts(), accountId);
            this.payment.PaymentAmount('');
            this.payment.PaymentDate('');
            this.payment.PayTo($.cdh.enums.payTo.payMe);
            this.payment.PaymentComment('');
            this.payment.PaymentRecurrenceFrequency($.cdh.enums.paymentRecurrenceFrequency.once);
            this.payment.PaymentRecurrenceType($.cdh.enums.paymentRecurrenceType.totalCount);
            this.payment.PayeeAccount('');
            this.payment.RepeatUntilDate('');
            this.payment.RecurrenceTotalCount(1);
            this.payment.PaymentPayee(undefined);
            this.payment.Agree(false);
            this.setDefaultReimbursementMethod();
            
            this.preview(false);
            this.success(false);
        }.bind(this);

        this.clickReset = function () {
            this.cleanUpErrors();
            this.reset();
            updateHeight();
            return false;
        }.bind(this);

        this.clickAddDepositAccount = function() {
            this.showAdd(false);
            this.editReimbursementModel().showSaveButton(true)
            this.showAddDepositAccount(true);
            $(window).scrollTop(0);
        }.bind(this);

        this.clickEditDepositAccount = function() {
            this.showAdd(false);
            this.showAddDepositAccount(true);
            $(window).scrollTop(0);
        }.bind(this);

        this.clickPreview = function () {
            this.cleanUpErrors();
            this.validation.activate();
            var showPreview = true;
            if (!this.canSubmit()) {
                updateHeight();
                return;
            }
            if (this.selectedAccount().AvailableBalance < parseFloat(this.payment.PaymentAmount())) {
                this.showAdd(false);
                this.exceedAmount(true);
                showPreview = false;
            }
            this.preview(showPreview);
            updateHeight();
        }.bind(this);

        this.returnToAdd = function() {
            this.exceedAmount(false);
            this.showAdd(true);
            updateHeight();
        }.bind(this);
        
        this.goToPreview = function () {
            this.exceedAmount(false);
            this.showAdd(true);
            this.preview(true);
            updateHeight();
        }.bind(this);

        this.clickEdit = function () {
            this.preview(false);
            updateHeight();
        }.bind(this);

        this.cleanUpErrors = function() {
            this.deleteProviderRequest.error('');
        }.bind(this);

        this.totalPayments = ko.pureComputed(function () {
            var amount = this.payment.PaymentAmount() ? this.payment.PaymentAmount() : 0;

            if (this.payment.PaymentRecurrenceType() === $.cdh.enums.paymentRecurrenceType.totalCount)
                return (amount * this.payment.RecurrenceTotalCount()).toFixed(2);

            if (this.payment.PaymentRecurrenceType() === $.cdh.enums.paymentRecurrenceType.untilDate) {
                if (!this.payment.PaymentDate() || !this.payment.RepeatUntilDate())
                    return;

                var start = moment(this.payment.PaymentDate());
                var end = moment(this.payment.RepeatUntilDate());

                switch (this.payment.PaymentRecurrenceFrequency()) {

                    case $.cdh.enums.paymentRecurrenceFrequency.weekly :
                        var weeks = end.diff(start, 'weeks');
                        var weekMultiplier = Math.floor(weeks) + 1;
                        return (amount * weekMultiplier).toFixed(2);
                    case $.cdh.enums.paymentRecurrenceFrequency.monthly :
                        var months = end.diff(start, 'months');
                        var monthMultiplier = Math.floor(months) + 1;
                        return (amount * monthMultiplier).toFixed(2);
                    default:
                        return 0;
                }
            }
        }, this);

        this.clickDeleteProvider = function (payee) {
            if (!payee)
                return false;

            this.cleanUpErrors();
            if (confirm($.cdh.languageConstant.getConstant("messages.want-delete-provider", { '0': payee.PayeeName }))) {
                payee.isDeleting(true);

                this.deleteProviderRequest.isLoading(false);
                this.deleteProviderRequest.load({payProviderKey: payee.PayeeKey})
                    .done(function() {
                        var newPayees = $.grep(this.payees.data(),
                            function (p) { return p.PayeeKey != payee.PayeeKey; });
                        this.payees.data(newPayees);
                        payee.isDeleting(false);

                        if (this.payment.PaymentPayee().PayeeKey == payee.PayeeKey) {
                            this.payment.PaymentPayee(undefined);
                        }

                    }.bind(this))
                    .fail(function() {
                        payee.isDeleting(false);
                    });
                return false;
            };
        }.bind(this);

        this.clickSubmit = function () {
            this.cleanUpErrors();
            this.validation.activate();

            if (!this.canSubmit())
                return;
          
            if (this.payment.PayTo() === $.cdh.enums.payTo.paySomeoneElse ) {
                this.payment.ReimbursementMethod($.cdh.enums.reimbursementMethod.check);
            }

            this.addRequest
                .load(ko.toJS(this.payment))
                .done(function () {
                    this.success(true);
                    if ($.isFunction(onSuccessAdd))
                        onSuccessAdd();
                }.bind(this));

            updateHeight();
        };

        this.clickOk = function () {
            if ($.isFunction(this.close))
                this.close();
        }.bind(this);

        this.clickCancel = function () {
            if ($.isFunction(this.cancel)) {
                this.cancel();
                return;
            }
            if ($.isFunction(this.close)) {
                this.close();
            }
            if ($.isFunction(this.onPaymentCancel)) {
                this.onPaymentCancel();
            }
        }.bind(this);

        this.setCancel = function (cancelFunc) {
            if ($.isFunction(cancelFunc)) {
                this.cancel = function() {
                    cancelFunc.call(this);
                };
            }
        };
        this.setClose = function (closeFunc) {
            var needReset = function() { return this.resetAfterClose; }.bind(this);
            this.close = function () {
                closeFunc.call(this);
                if (needReset()) {
                    this.reset();
                }
            }.bind(this);
        }.bind(this);

        this.isAddDepositEnabled = ko.computed(() => {
            if (!$.cdh.employee.allowedToUpdateDirectDepositInformation() || !$.cdh.employee.allowedToViewDirectDepositInformation())
                return false;

            return this.editReimbursementModel()?.profile.viewModel().ReimbursementMethod !== $.cdh.enums.reimbursementMethod.directDeposit;
        });
        
        //visibility
        this.isAddDepositBtnVisible = ko.computed(function() {
            if (this.payment.ReimbursementMethod() != $.cdh.enums.reimbursementMethod.directDeposit)
                return false;

            return this.isAddDepositEnabled();
        }.bind(this));

        this.isEditDepositBtnVisible = ko.computed(function() {
            if (this.payment.ReimbursementMethod() != $.cdh.enums.reimbursementMethod.directDeposit)
                return false;

            if (!$.cdh.employee.allowedToViewDirectDepositInformation())
                return false;

            if (this.editReimbursementModel().profile.viewModel().ReimbursementMethod != $.cdh.enums.reimbursementMethod.directDeposit)
                return false;

            return this.allowedDirectDepositInProfile();
        }.bind(this));
    };
    $.extend(true, $, {
        cdh: {
            models: {
                PaymentsAddPopup: paymentsAddPopup
            }
        }
    });
})(jQuery, ko);