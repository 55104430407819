import {DataServiceBase} from "../DataServiceBase";

export class ChangePasswordService extends DataServiceBase{
    constructor() {
        super('/change-password');
    }
    
    getInfoAsync(userName = null){
        let urlQuery = userName ? `?userName=${userName}` : '';
        return this.getAsync(null, 'info' + urlQuery);
    }
    
    getQuestionsAsync(ticket) {
        return this.postAsync({ticket: ticket}, 'questions');
    }
    
    validateAnswersAsync(model) {
        return this.postAsync(model, 'validate-answers');
    }
    
    changeByQuestionsAsync(model) {
        return this.postAsync(model, 'change-by-questions');
    }

    changeByOtpAsync(model) {
        return this.postAsync(model, 'change-by-otp');
    }
}