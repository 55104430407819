import template from "./scheduled-payments.html";
import {PaymentsService} from "../../Http/Payments/PaymentsService";

ko.components.register('scheduled-payments', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new ScheduledPayments(params, widgetInfo);
        }
    }
});

class ScheduledPayments {
    constructor(params, widgetInfo) {
        this.widgetInfo = widgetInfo;

        this.paymentsService = new PaymentsService();
        this.template = new $.cdh.services.templateExtractor(params.template);

        this.isVisible = ko.observable(false);
        this.title = ko.observable();
        this.payments = ko.observableArray();

        this.refreshCallback = () => this.load();

        this.load();

        widgetInfo.updateHeight();
    }

    setTitle() {
        let titleLabel = $.cdh.widgetLabels.scheduledPayments.title;
        let title = this.template.getLabel(titleLabel.name, titleLabel.default);
        this.title(title);
    }
    
    async load() {
        let result = await this.paymentsService.getPaymentsAsync($.cdh.enums.paymentStatusFilter.pending);

        let payments = result.data();

        if (payments.length > 0) {
            this.setTitle();
            this.isVisible(true);
            this.payments(payments);

            this.widgetInfo.updateHeight();
        } else {
            this.widgetInfo.remove();
        }
    }
}