import template from "./fax-cover-page.html"

(function ($, ko) {
    ko.components.register('fax-cover-page', {
        template: template,
        viewModel: {
            createViewModel: function (params, componentInfo) {
                return new FaxCoverPage(params, updateContainerHeight(componentInfo.element));
            }
        }
    });

    function FaxCoverPage(params, updateHeight){
        this.trackingNumber = params.trackingNumber;
        this.updateHeight = updateHeight;
        
        this.downloadLink = ko.pureComputed(function(){
            return "/Claims/DownloadFaxCoverPage?trackingNumber=" + this.trackingNumber + "&pdf=true";
        }, this);

        this.showPdf = function(data, event) {
            $.cdh.helper.showPdfViwer(event.target.href);
        }.bind(this);
        
        this.onContinue = function(){
            if($.isFunction(params.onContinue))
                params.onContinue();
        }.bind(this);
        
        this.onBack = function(){
            if($.isFunction(params.onBack))
                params.onBack();
        }.bind(this);
    }
})(jQuery, ko);