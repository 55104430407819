(function ($, ko) {
    var expensesSummaryModel = function (updateHeight, template) {

        this.templateExtractor = new $.cdh.services.templateExtractor(template);

        this.expensesSummary = new $.cdh.get.expenses.getSummary();
        this.containsPreviousYearData = ko.pureComputed(function() {
            if (!this.expensesSummary.hasData() || this.expensesSummary.data().length == 0)
                return false;

            if (this.expensesSummary.data().length == 1) {
                return !(this.expensesSummary.data().slice(-1)[0].ExpensesYear == new Date().getFullYear());
            }

            return true;

        }, this);
        this.lastYearSummary = ko.pureComputed(function () {
            if (!this.expensesSummary.hasData() || this.expensesSummary.data().length == 0)
                return null;

            // expects that summaries are ordered by year
            var lastYear = this.expensesSummary.data().slice(-1)[0];
            if (lastYear.ExpensesYear != new Date().getFullYear())
                return null;

            return lastYear;
        }, this);

        this.empty = ko.pureComputed(function() {
            return this.expensesSummary.data().length == 0;
        }.bind(this));

        this.expensesSummary.state.subscribe(updateHeight);

        this.expensesSummary.load();
    }

    ko.components.register('expenses-summary', {
        template: { element: 'template-expenses-summary' },
        viewModel: {
            createViewModel: function (params, componentInfo) {
                var updateHeight = updateContainerHeight(componentInfo.element);
                return new expensesSummaryModel(updateHeight, params.template);
            }
        }
    });
})(jQuery, ko);