(function ($) {
    var communicationsSettingsPopup = function(settingRequest, updateHeight, successCallback) {
        this.hasPermissionsToUpdateCommunication = $.cdh.helper.hasPermission($.cdh.enums.userPermission.userPermissionUpdateParticipantCommunication);
        this.settingRequest = settingRequest;
        this.settings = ko.observableArray([]);
        this.email = new $.cdh.get.communications.getEmail();
        this.mobileNumbers = new $.cdh.get.communications.getMobileNumbers(function (numbers) {
            this.anyPhoneNumber(numbers.length > 0);
        }.bind(this));

        this.preRender = function() {
            this.settingRequest.load().done(function (settings) {
                this.settings(settings);
            }.bind(this));
            this.email.load();
            this.mobileNumbers.load();
        }.bind(this);

        this.deliveryEmailOptionWarning = ko.pureComputed(function () {
            updateHeight();
            return this.anyEmailDeliverySet() && this.email.data().length == 0;
        }, this);

        this.deliveryMobileOptionWarning = ko.pureComputed(function () {
            updateHeight();
            return this.anyMobileDeliverySet() && !this.anyPhoneNumber();
        }, this);

        this.anyPhoneNumber = ko.observable(true);
        this.allowMultiplePhoneNumbers = ko.observable(false);

        this.anyEmailDeliverySet = function () {
            if (this.email.isLoading())
                return false;

          var result = $.grep(this.settings, function (s) {
                if (s.DeliveryMethod() == $.cdh.enums.deliveryMethod.email ||
                    s.DeliveryMethod() == $.cdh.enums.deliveryMethod.both) {
                    return true;
                }
                return false;
            });
            return result.length > 0;
        }.bind(this);

        this.anyMobileDeliverySet = function () {
            if(this.mobileNumbers.isLoading())
                 return false;

            var result = $.grep(this.settings, function (s) {
                if (s.DeliveryMethod() == $.cdh.enums.deliveryMethod.mobile ||
                    s.DeliveryMethod() == $.cdh.enums.deliveryMethod.both) {
                    return true;
                }
                return false;
            });
            return result.length > 0;
        }.bind(this);
        
        this.editRequest = new $.cdh.post.communications.editSettings();

        this.setClose = function (closeFunc) {
            this.close = closeFunc;
        }.bind(this);

        this.saveClick = function () {
            if (this.editRequest.isLoading())
                return;
            var newSettings = ko.toJS(this.settings());
            this.editRequest.load({ settings: newSettings }).done(function() {
                successCallback(this.settings());
                this.close();
            }.bind(this));
        }.bind(this);

        this.allLoaded = ko.pureComputed(function() {
             return !this.email.isLoading()
                 && !this.mobileNumbers.isLoading() 
                 && !this.settingRequest.isLoading()
        }, this);

    }

    $.extend(true, $, {
        cdh: {
            models: {
                CommunicationsSettingsPopup: communicationsSettingsPopup
            }
        }
    });

})(jQuery);
