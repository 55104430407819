import template from "./notification-center.html";
import {NotificationsService} from "../Http/NotificationCenter/NotificationsService";

ko.components.register('notification-center', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new NotificationCenter(params, widgetInfo);
        }
    }
});

export class NotificationCenter {
    constructor(params, widgetInfo) {
        this.widgetInfo = widgetInfo;
        this.notificationsService = new NotificationsService();
        
        this.notifications = ko.observableArray();
        this.isEmpty = ko.observable(false);
        this.count = ko.observable().subscribeTo($.cdh.topics.notifications.countValue);
        
        this.close = params.closeFunc;

        document.addEventListener('opportunityrefreshed', async () => {
            await this.loadList();
            this.widgetInfo.updateHeight();
        });
        this.getComponentName = function (notification) {
            if (notification.Type === $.cdh.enums.notificationType.message)
                return "notification-item-message";

            return "notification-item-opportunity";
        };
        this.setFilterValue = function(e, val, data) {
            if (e.keyCode === 13 || e.keyCode === 32) {
                this.filterModel.selectedType(val);
            }
        }.bind(this);
        
        this.filterModel = {
            selectedType: ko.observable(0),
        };

        let mode = $.cdh.helper.urlParam('mode');
        if (mode === 'messages'){
            this.filterModel.selectedType( $.cdh.enums.notificationType.message);    
        }else if (mode === 'opportunities'){
            this.filterModel.selectedType( $.cdh.enums.notificationType.opportunity);
        }
        
        this.filteredNotifications = ko.pureComputed(() => {
            switch (this.filterModel.selectedType ()) {
                case $.cdh.enums.notificationType.message:
                    return this.notifications().filter(function (notification) {
                        return notification.Type === $.cdh.enums.notificationType.message;
                    });
                case $.cdh.enums.notificationType.opportunity:
                    return this.notifications().filter(function (notification) {
                        return notification.Type === $.cdh.enums.notificationType.opportunity;
                    });
            }
            return this.notifications();
        });

        this.filteredNotifications.extend({ rateLimit: { method: "notifyWhenChangesStop" } });

        this.filteredNotifications.subscribe(function (newValue) {
            newValue?.length > 0 ? this.isEmpty(false) : this.isEmpty(true);
        }, this);

        this.loadList();
    }
    
    async loadList() {
        
        let result = await this.notificationsService.getListAsync();
        let notifications = result.data();

        this.notifications(notifications.Data);
        this.count(notifications.Total);
        
        this.widgetInfo.updateHeight();
    }
}