(function ($, ko) {
    ko.components.register('loading', {
        template: '<!-- ko if: loading() --> <div class="loading-box" ><span class="spinner"><span></span> <span></span> <span></span></span></div> <!-- /ko --> ',
        viewModel: {
            createViewModel: function (params, componentInfo, widgetInfo) {
                return new Loading(params, componentInfo, widgetInfo)
            }
        }
    });

    function Loading(params, componentInfo, widgetInfo) {
        this.loading = params.loading || params.dataService.loading;
        this.loading.subscribe(function () {
            widgetInfo.updateHeight();
        })
    }

})(jQuery, ko);