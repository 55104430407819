import template from './provider-list.html';

ko.components.register('provider-list', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new ProviderListModel(widgetInfo.updateHeight);
        }
    }
});

   export class ProviderListModel {
       constructor(updateHeight) {
           const self = this;
           self.disabled = false;
           self.showData = ko.observable(false);
           self.typeOfCare = ko.observable("");
           self.distance = ko.observable("");
           self.selectedInsuranceUuid = ko.observable("");
           self.isProvidersEmpty = ko.observable(false);
           self.zipCode = ko.observable($.cdh.helper.urlParam('zipcode'));
           self.distance = ko.observable(parseInt($.cdh.helper.urlParam('distance')));
           self.defaultDistance = ko.observable(parseInt($.cdh.helper.urlParam('distance')));
           self.selecteSpecialistUUid = ko.observable($.cdh.helper.urlParam('specialityId'));
           self.selectedProcedureUUid = ko.observable($.cdh.helper.urlParam('procedureId'));
           self.selectedSpecilistName = ko.observable($.cdh.helper.urlParam('speciality'));
           self.selectedProcedureName = ko.observable($.cdh.helper.urlParam('procedure'));
           self.isFilterAvailable = ko.observable($.cdh.helper.urlParam('filterAvailable'));
           self.ratingFilter = ko.observable($.cdh.helper.urlParam('filteredRating'));
           self.costFilter = ko.observable($.cdh.helper.urlParam('filteredCost'));
           self.distanceFilter = ko.observable($.cdh.helper.urlParam('filteredDistance'));
           self.currentPage = Number($.cdh.helper.urlParam('pageNo')) || 1;
           self.selectedPlan = ko.observable()
           self.carrierName = ko.observable();
           self.totalCount = ko.observable();
           self.costEstimates = ko.observableArray();
           self.pageStartIndex = ko.observable();
           self.pageEndIndex = ko.observable();
           self.isRatingFilterApplied = ko.observable(false);
           self.isDistanceilterApplied = ko.observable(false);
           self.isCostFilterApplied = ko.observable(false);
           self.defaultCostEstimate = ko.observable({
               "maximum": 0,
               "minimum": 0,
               "median": 0
           });
          

           self.ratings = ko.observableArray([
             1,2,3,4,5
           ]);
           self.distances = ko.observableArray([
               5, 10, 15, 20, 25, 50, 75, 100
           ]);
           self.costIndexes = ko.observableArray([
               1,2,3,4,5
           ]);

           if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
               this.isFilterAvailable(false);
               self.ratingFilter(null);
               self.distanceFilter(null);
               self.costFilter(null);
               $.cdh.helper.deleteUrlParam('filteredCost');
               $.cdh.helper.deleteUrlParam('filteredRating');
               $.cdh.helper.deleteUrlParam('filteredDistance');
           }
          
           self.saveRatingFilter = () => {
               this.isFilterAvailable(true);
               this.isRatingFilterApplied(true);
               this.paginatorModel.pageNo(1);
               $.cdh.helper.setUrlParam('filteredRating', this.ratingFilter());
               self.loadData(self.currentPage);
               self.closeAllDropdowns();
           };
           self.saveDistanceFilter = () => {
               this.isFilterAvailable(true);
               this.isDistanceilterApplied(true);
               this.paginatorModel.pageNo(1);
               $.cdh.helper.setUrlParam('filteredDistance', this.distanceFilter());
               self.loadData(self.currentPage);
               self.closeAllDropdowns();
           };
           self.saveCostFilter = () => {
               this.isFilterAvailable(true);
               this.isCostFilterApplied(true);
               this.paginatorModel.pageNo(1);
                $.cdh.helper.setUrlParam('filteredCost', this.costFilter());
               self.loadData(self.currentPage);
               self.closeAllDropdowns();
           };
           self.clear = () => {
               this.isFilterAvailable(false);
               self.ratingFilter(null);
               self.distanceFilter(null);
               self.costFilter(null);
               $.cdh.helper.deleteUrlParam('filteredCost');
               $.cdh.helper.deleteUrlParam('filteredRating');
               $.cdh.helper.deleteUrlParam('filteredDistance');
               self.loadData(self.currentPage);
           }
           self.clearRatingFilter = () => {
               self.ratingFilter(null);
               $.cdh.helper.deleteUrlParam('filteredRating');
               self.loadData(self.currentPage);
               self.closeAllDropdowns();
           };
           self.clearDistanceFilter = () => {
               self.distanceFilter(null);
               $.cdh.helper.deleteUrlParam('filteredDistance');
               self.loadData(self.currentPage);
               self.closeAllDropdowns();
           };
           self.clearCostFilter = () => {
               self.costFilter(null);
               $.cdh.helper.deleteUrlParam('filteredCost');
               self.loadData(self.currentPage);
               self.closeAllDropdowns();
           };
           self.currentPlanList = ko.observableArray();
           self.insuranceList = ko.observableArray();
           self.costEstimates = ko.observableArray();
           self.procedure_names = self.selectedProcedureName();
           self.updateHeight = updateHeight;

           self.displayPlanText = ko.pureComputed(
               () => self.selectedPlan() || $.cdh.languageConstant.getConstant("find-care.display-plantext")
           );

        self.displayZipCode = ko.pureComputed(
            () => self.zipCode() || $.cdh.languageConstant.getConstant("find-care.enter-zipcode")
        );
           self.displayZipCode = ko.pureComputed(
               () => self.zipCode() || $.cdh.languageConstant.getConstant("find-care.enter-zipcode")
           );
           self.closeAllDropdowns = function () {
               $('div.data-filter__item--opened').removeClass('data-filter__item--opened').removeClass('data-filter__item--opened--left');
           };
           self.dropdownMenuItemRendered = function (elOrArr, data, itemsKey, options = {}) {
               const element = Array.isArray(elOrArr) ? elOrArr.filter(el => el && el.nodeType && el.nodeType === 1)[0] : element;
               if (!element || !itemsKey || !this[itemsKey]) return;
               const $menuList = $(element).closest('ul');
               const $items = $menuList.find('> li');
               const allItems = this[itemsKey]();
               if ($items.length === allItems.length) {
                   $.cdh.helper.initMenuAccesibility($menuList[0], options);
                   $.cdh.helper.initMenuNavigation($menuList[0], options);
               }
           }
           self.headerText = ko.pureComputed(
               () => {
                   let headerTextArg = 'Primary Care Providers';

                if (self.selectedSpecilistName()) {
                    headerTextArg = self.selectedSpecilistName();
                }

                if (self.selectedProcedureName()) {
                    headerTextArg = self.selectedProcedureName();
                }
                return 'Results for ' + headerTextArg + ' near';
            }
        );

        self.participantService = new $.cdh.http.smartAccount.ParticipantService();
        self.InsurancesService = new $.cdh.http.smartAccount.vendors.InsurancesService;
        self.providersService = new $.cdh.http.smartAccount.vendors.ProvidersService();
        self.CostEstimateService = new $.cdh.http.smartAccount.vendors.CostEstimateService();

        if (self.selectedProcedureUUid()) {
            self.CostEstimateService.searchAsync(self.selectedProcedureUUid, self.zipCode).then(function (result) {
                let costEstimates = result.xhr.responseJSON;
                self.costEstimates(costEstimates);
            }).catch(function (err) {
                self.costEstimates(self.defaultCostEstimate());
            });
        }


        self.providers = ko.observableArray();

        self.searchURL = ko.pureComputed(function () {
            return $.cdh.routes.predefined.findCare.pageList + '?zipcode=' + self.zipCode() + '&distance=' + self.distance();
        });
        function load() {
            self.participantService.getDetailsAsync().then(function (result) {

                let data = result.data();
                let InsuranceInformation = data.Details.InsuranceInformation;

                if (InsuranceInformation) {
                    self.selectedInsuranceUuid(InsuranceInformation.uuid);
                    self.carrierName(InsuranceInformation.Details?.carrierName);
                    self.selectedPlan(InsuranceInformation.Details?.displayName);
                }
                if (data.FindCareDisabled) {
                    self.disabled = true;
                }
                else {
                    self.showData(true);
                }
                self.insuranceInfo = {
                    insuranceIds: self.selectedInsuranceUuid(),
                    selectedPlan: self.selectedPlan()
                }
                self.storeInsuranceData(self.insuranceInfo);

                if (!self.disabled) {
                    self.loadData(self.currentPage);
                }

            }).then(function (data) {

                if (self.carrierName()) {
                    self.InsurancesService.searchAsync(1, 1000, self.carrierName).then(function (result) {

                        let planListSet = new Set();
                        let filteredInsurances = result.data().Data.filter(insurance => insurance.carrierName === self.carrierName());

                        self.insuranceList(filteredInsurances);
                        self.currentPlanList([]);

                        for (var item in filteredInsurances) {
                            if (!planListSet.has(filteredInsurances[item].displayName)) {
                                self.currentPlanList.push(filteredInsurances[item].displayName);
                                planListSet.add(filteredInsurances[item].displayName);
                            }
                        }

                        if (self.currentPlanList().length > 0) {
                            self.currentPlanList(self.currentPlanList().sort(function (left, right) {
                                return left == right ? 0 : (left < right ? -1 : 1);
                            }));
                        }
                    });
                    
                }
                else {
                    self.InsurancesService.completed(true);
                }

            });
        }
        load();
          
           self.paginatorModel = {
               pageNo: ko.observable(),
               totalPages: ko.observable(),
               onChanged: (currentPage) => {

                self.loadData(currentPage, self.distance);
            }
        };
    }

    storeInsuranceData(insurance) {
        self.insuranceIds = insurance.insuranceIds;
        localStorage.setItem('insuranceInfo', JSON.stringify(insurance));
    }

       async loadData(currentPage) {
         
        this.paginatorModel.pageNo(currentPage || 1);
            
           $.cdh.helper.setUrlParam('pageNo', this.paginatorModel.pageNo());
           let providerPageParams = {
               pageNo: this.paginatorModel.pageNo(),
               pageSize: 4,
               zipCode: this.zipCode,
               distance: this.distance,
               selectedInsuranceUuid: this.selectedInsuranceUuid,
               selectedSpecialistUUid: this.selecteSpecialistUUid,
               selectedProcedureUUid: this.selectedProcedureUUid,
               MinConfidence: 3,
           }
           if (this.isFilterAvailable()) {
               providerPageParams.rating = this.ratingFilter;
               providerPageParams.cost = this.costFilter;
               this.distanceFilter() ? providerPageParams.distance = this.distanceFilter : providerPageParams.distance = this.distance();
           }
           const result = await this.providersService.providers(providerPageParams);
            let providerList = result.xhr.responseJSON.Page.Data;
            this.providers(providerList);
            providerList.length == 0 ? this.isProvidersEmpty(true) : this.isProvidersEmpty(false);
           $.cdh.helper.deleteUrlParam('filterAvailable');
           let totalPages = result.xhr.responseJSON.Page.TotalPages;
           this.totalCount(result.xhr.responseJSON.Page.Total);
           this.pageStartIndex(((result.xhr.responseJSON.Page.Offset) * (result.xhr.responseJSON.Page.Limit)) + 1);
           this.providers().length < 4 ? this.pageEndIndex(this.providers().length) : this.pageEndIndex(this.pageStartIndex() + 3);
            this.paginatorModel.totalPages(totalPages);
       }
      
}

