import {DataServiceBase} from "../DataServiceBase";

(function ($, ko) {

    class PrescriptionsService extends DataServiceBase {
        constructor() {
            super('/SmartAccount/Participant/Prescriptions');
        }

        addAsync(data) {
            return this.postAsync(data, 'Add')
        };

        updateAsync(data) {
            return this.putAsync(data, "Update");
        }
    }

    $.extend(true, $.cdh, {
        http: {
            smartAccount: {
                PrescriptionsService: PrescriptionsService
            }
        }
    });

})(jQuery, ko);