import template from "./account-activity-mini.html"

ko.components.register('account-activity-mini', {
    template: template,
    viewModel: {
        createViewModel: function (params, componentInfo, widgetInfo) {
            return new AccountActivityMini(widgetInfo.updateHeight, params.template);
        }
    }
});

export class AccountActivityMini {
    constructor(updateHeight, template) {
        this.updateHeight = updateHeight;
        this.templateExtractor = new $.cdh.services.templateExtractor(template);
        
        this.getPageRequest = new $.cdh.get.accountActivity.getRecent();
        this.items = ko.observableArray();

        this.init();
    }

    init() {
        this.getPageRequest.load({recordCount: 10}).done(items => {
            this.items(items);
            this.updateHeight();
        })
    }
    
    getCssClass(status) {
        switch (status) {
            case $.cdh.enums.employeeActivitySimplifiedStatus.pending:
                return 5;
            case $.cdh.enums.employeeActivitySimplifiedStatus.denied:
                return 3;
            case $.cdh.enums.employeeActivitySimplifiedStatus.actionRequired:
                return 1;
            case $.cdh.enums.employeeActivitySimplifiedStatus.noAction:
                return 2;
            case $.cdh.enums.employeeActivitySimplifiedStatus.approved:
            default:
                return '';
        }
    }
}