(function ($) {
    // number is CDH.ApiServices.Models.Communication.MobileNumber
    var mobileNumberModel = function (number, onUpdate) {
        $.extend(true, this, number);
        const self = this;

        this.mobileNumbersListLabels = {
            lblRegisterNumber: $.cdh.languageConstant.getConstant('communication.register-number'),
            lblUnregisterNumber: $.cdh.languageConstant.getConstant('communication.unregister-number'),
            lblClickTo: $.cdh.languageConstant.getConstant('communication.click-to')
        };

        this.isPending = ko.pureComputed(function () {
            return this.MobileStatus == $.cdh.enums.mobileStatus.pending;
        }.bind(this));

        this.isUnregistered = ko.pureComputed(function () {
            return this.MobileStatus == $.cdh.enums.mobileStatus.optOut;
        }.bind(this));

        this.isRegistred = ko.pureComputed(function () {
            return this.MobileStatus == $.cdh.enums.mobileStatus.optIn;
        }.bind(this));

        this.isMobile = ko.observable(window.innerWidth < 768);
        window.addEventListener('resize', function () {
            self.isMobile(window.innerWidth < 768);
        });

        this.canBeDeleted = ko.pureComputed(function () {
            return !$.cdh.global.maintenance() && !$.cdh.user.impersonated && (this.isPending() || this.isUnregistered() || this.isRegistred());
        }.bind(this));

        this.toggleRegistrationRequest = this.isUnregistered()
            ? new $.cdh.post.communications.registerMobileNumber()
            : new $.cdh.post.communications.unregisterMobileNumber();

        this.deleteRequest = new $.cdh.post.communications.deleteMobileNumber();

        this.showRegisterPopup = function () {
            if ($.cdh.user.impersonated || $.cdh.global.maintenance())
                return;

            $.cdh.helper.confirm(
                $.cdh.languageConstant.getConstant('communication.are-you-sure-want-to') +
                (this.isUnregistered() ? $.cdh.languageConstant.getConstant('button.register') + " " : $.cdh.languageConstant.getConstant('button.unregister')) + " " +
                this.PhoneNumber + $.cdh.languageConstant.getConstant('communication.press-ok-to-continue'),
                function () {
                    this.toggleRegistrationRequest
                        .load({ number: this.PhoneNumber.substring(1) })
                        .done(onUpdate);
                }.bind(this),
                null,
                (this.isUnregistered() ? $.cdh.languageConstant.getConstant('button.register') + " " : $.cdh.languageConstant.getConstant('button.unregister')) + " " + $.cdh.languageConstant.getConstant('profile.phone-number')
            );
        }.bind(this);

        this.registerCheckboxClick = function (data, event) {
            this.showRegisterPopup();
            event.preventDefault();
            event.stopPropagation();
            return false;
        }.bind(this);

        this.registerCheckboxKeyDown = function (data, event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                this.showRegisterPopup();
            }
            return true;
        }.bind(this);

        this.deleteClick = function (data, event) {
            if (!this.canBeDeleted()) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }

            $.cdh.helper.delete({
                title: $.cdh.languageConstant.getConstant('communication.delete-phone-number'), 
                hasCloseBtn: true,
                message: $.cdh.languageConstant.getConstant('communication.delete-confirmation-messaage') +" " + $.cdh.helper.maskPhoneNumber(this.PhoneNumber) + ' ?',
                okCallback: function () {
                    this.deleteRequest
                        .load({ number: this.PhoneNumber.substring(1) })
                        .done(onUpdate);
                }.bind(this)
            });
            event.preventDefault();
            event.stopPropagation();
            return false;
        }.bind(this);

        this.deleteButtonKeyDown = function (data, event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                $.cdh.helper.delete({
                    title: $.cdh.languageConstant.getConstant('communication.delete-phone-number'),
                    hasCloseBtn: true,
                    message: $.cdh.languageConstant.getConstant('communication.delete-confirmation-messaage') + this.PhoneNumber + '?',
                    okCallback: function () {
                        this.deleteRequest
                            .load({ number: this.PhoneNumber.substring(1) })
                            .done(onUpdate);
                    }.bind(this)
                });
            }
            return true;
        }.bind(this);

        this.isNumberMenuOpen = ko.observable(false);

        this.toggleNumberMenu = function () {
            this.isNumberMenuOpen(!this.isNumberMenuOpen());
        }

        this.kayboardHandler = function (data, event) {
            if (event.keyCode === 13 || event.keyCode === 32) {
                this.toggleNumberMenu();

                return false;
            }

            return true;
        }
    };
    addEventListener('mouseover', (event) => {
        $('#registredNumber').show();
    });   

    window.addEventListener('keyup', function (event) {
        if (event.keyCode === 27) {
            $('#registredNumber').hide();
        }
        else {
            $('#registredNumber').show();
        }
    });

    $.extend(true, $, {
        cdh: {
            models: {
                CommunicationsMobileNumber: mobileNumberModel
            }
        }
    });
})(jQuery);